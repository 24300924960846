import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { IServerAccount } from "../../../Interfaces/IServerAccount";
import FacebookAccountsApiService from "../../../Services/FacebookAccountsApiService";
import ServerAccountsApiService from "../../../Services/ServerAccountsApiService";
import { SectionTitle } from "../../Others/SectionTitle";

interface IProps {
    serverAccount: IServerAccount,
    onHide(): void,
}

const serverAccountsApiService = new ServerAccountsApiService();
const facebookAccountsApiService = new FacebookAccountsApiService();

export const FacebookAccountCookiesLoginModal = (props: IProps) => {

    const { serverAccount, onHide } = props;

    useEffect(() => {
        return () => {
            serverAccountsApiService.cancelRequests();
            facebookAccountsApiService.cancelRequests();
        }
    });

    function handleDrop(files: File[]) {
        serverAccountsApiService.uploadCookies(serverAccount.id, files[0])
            .then(data => {
                if (data.success === true) {
                    // verify login

                    facebookAccountsApiService.verifyLogin(serverAccount.id)
                        .then(data => {
                            const acc = data.records.serverAccounts.items[0];

                            if (acc.signInRequired===false) {
                                onHide();
                            }
                        })
                }
            });
    }

    return (
        <>
            <Modal centered={true} show={true} onHide={() => { }}>
                <Modal.Header>
                    <Modal.Title>Přihlášení pomocí cookies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SectionTitle className="mt-0" title="VIDEONÁVOD" />
                    <iframe
                        className="w-100"
                        height="315"
                        src="https://www.youtube.com/embed/pSlZ4krpUmc?start=40"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen>
                    </iframe>

                    <SectionTitle title="POSTUP" />

                    <ol>
                        <li>Otevřete si <b>Google Chrome</b></li>
                        <li><b>Nainstalujte si doplněk</b>&nbsp;
                            <a
                                target="_blank"
                                href="https://chrome.google.com/webstore/detail/get-cookiestxt/bgaddhkoddajcdgocldbbfleckgcbcid?hl=en">
                                Get cookies.txt
                            </a>
                        </li>
                        <li>Přejděte na <a target="_blank" href="https://www.facebook.com/">Facebook.com</a></li>
                        <li>Jazyk Facebooku je potřeba mít <b>trvale nastaveno na Češtinu</b></li>
                        <li>Pokud nejste přihlášení, tak se přihlaste</li>
                        <li>Pomocí nainstalovaného pluginu, v horní liště, kliknutím na ikonku sušenky, <b>klikněte na tlačítko Export</b>. <b>Během tohoto kroku je potřeba mít otevřený Facebook.com.</b></li>
                        <li><b>Stažený .txt soubor nahrajte níže</b></li>

                        <Dropzone onDrop={(files) => handleDrop(files)}>
                            {({ getRootProps, getInputProps, }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} accept="ext/plain" />
                                        <div className="dropzone-area my-4 mr-5">
                                            <h3>Vybrat stažený soubor</h3>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </ol>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => onHide()} variant="secondary" size="lg">
                        Zavřít
                    </Button>           
                </Modal.Footer>
            </Modal>
        </>
    )
}