import IProductImage from "../Interfaces/IProductImage";

export class ProductImageHelper {
    public static getImagePath(productImage: IProductImage): string {
        return `https://images.inzerobot.cz/${productImage.id}.jpg`;
    }

    public static getImagePathS3NoCache(productImage: IProductImage): string {
        return `https://s3.eu-central-1.amazonaws.com/images.inzerobot.cz/${productImage.id}.jpg?time=${new Date(productImage.dateEdit).toISOString()}`;
    }
}