import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { Accordion } from '../../UI/Elements/Accordion';
import { PageTitleBox } from '../../Others/PageTitleBox';
import { Link } from 'react-router-dom';

export const FaqPage = () =>  {
        return (
            <CustomDocumentTitle title="FAQ - často kladené otázky">
                    <PageTitleBox title="FAQ - často kladené otázky" />

                    <div className="card">
                        <div className="card-body">
                            <Accordion cardClassName="mt-4 mb-2" headingText={"Můžu své inzeráty topovat?"}>
                                <p>
                                    Své inzeráty můžete topovat, jestliže máte správně nastavený interval obnovování. Při novém nahrávání by se inzerát smazal.
                                </p>
                            </Accordion>

                            <Accordion cardClassName="mb-2" headingText={"Můžu mít více kolekcí?"}>
                                <p>
                                    Počet kolekcí není limitován. Omezení počtu inzerátů se vztahuje na celý váš účet bez ohlednu na kolekce.
                                </p>
                            </Accordion>

                            <Accordion cardClassName="mb-2" headingText={"Kolik můžu mít inzerátů?"}>
                                <p>
                                    Jde o max. počet inzerátů, který může být v jeden moment pod vaším účtem. Dle zaplacené licence.
                                </p>
                            </Accordion>

                            <div className="d-flex justify-content-center mt-3">
                                <p>V případě dalších dotazů prosím <Link to="/kontakt">kontaktujte podporu</Link>.</p>
                            </div>
                        </div>
                    </div>
            </CustomDocumentTitle>
        )
}