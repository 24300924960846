import { ChartData, ChartOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IMonthlySalesReport } from '../../../Interfaces/IMonthlySalesReport';
import StringHelper from '../../../Helpers/StringHelper';
import { max, round } from 'lodash';
interface IProps {
    sales: IMonthlySalesReport[],
}

export const SalesCountChart = (props: IProps) => {
    const { sales } = props;

    const monthNames = [
        'Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen',
        'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'
    ];

    // Get the current date
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Get month returns 0-based index
    const currentYear = currentDate.getFullYear();
    // Create a Set of unique months and years from the sales data
    const salesMonths = new Set(sales.map((sale) => `${sale.year}-${sale.month}`));

    // Generate the labels and datasets based on the sales data
    const labels = [];
    const dataTotalSoldCount = [];

    let foundNonEmptyMonth = false; // Track if a non-empty month has been found

    for (let i = 11; i >= 0; i--) {
        let month = currentMonth - i;
        let year = currentYear;
        if (month <= 0) {
            month += 12;
            year -= 1;
        }
        const salesKey = `${year}-${month}`;

        if (salesMonths.has(salesKey)) {
            const matchingSale = sales.find((sale) => sale.month === month && sale.year === year);

            if (matchingSale.totalGrossMargin || matchingSale.totalTurnover) {
                // If either grossMargin or turnover is not empty, add the data to the chart
                labels.push(monthNames[month - 1] + ' ' + year);
                dataTotalSoldCount.push(matchingSale.soldItemsCount);

                foundNonEmptyMonth = true; // Set the flag to indicate a non-empty month has been found
            } else if (foundNonEmptyMonth) {
                // If a non-empty month has been found previously, but the current month is empty,
                // add empty data to maintain the empty month spacing between non-empty months
                labels.push(monthNames[month - 1] + ' ' + year);
                dataTotalSoldCount.push(0);
            }
        } else if (foundNonEmptyMonth) {
            // If a non-empty month has been found previously, but the current month is not in the sales data,
            // add empty data to maintain the empty month spacing between non-empty months
            labels.push(monthNames[month - 1] + ' ' + year);
            dataTotalSoldCount.push(0);
        }
    }

    if (labels.length === 0) {
        labels.push(monthNames[currentMonth - 1] + ' ' + currentYear);
        dataTotalSoldCount.push(0);
    }

    const chartData: ChartData<'line'> = {
        labels: labels,
        datasets: [
            {
                label: 'Počet prodaných položek',
                data: dataTotalSoldCount,
                backgroundColor: ['rgba(37, 113, 234, 0.8)'],
                borderColor: ['rgba(37, 113, 234, 1)'],
                borderWidth: 1,
            },
        ],
    };

    const maxSoldCount = max(dataTotalSoldCount);
    const options: ChartOptions<'line'> = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += StringHelper.separateThousandsNoHtml(context.parsed.y);
                        }
                        return label;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return StringHelper.separateThousandsNoHtml(value);
                    },
                    precision: 0,
                },
                suggestedMax: maxSoldCount + round((maxSoldCount / 100) * 25, 0)
            },
        },
    };

    return (
        <>
            <Line data={chartData} options={options} />
        </>
    );
};