import React, { useRef } from "react";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import IProduct from "../../../Interfaces/IProduct";
import IUser from "../../../Interfaces/IUser";
import IInputField from "../../../Interfaces/IInputField";
import { SectionTitle } from "../../Others/SectionTitle";
import { OnlineServer } from "../../../Enums/OnlineServer";
import { ServerBazosCzSettingsAccordion } from "./Servers/ServerBazosCzSettingsAccordion";
import { concat } from "lodash";
import { ServerBazosSkSettingsAccordion } from "./Servers/ServerBazosSkSettingsAccordion";
import { ServerSbazarSettingsAccordion } from "./Servers/ServerSbazarSettingsAccordion";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import { IFacebookCategory } from "../../../Interfaces/IFacebookCategory";
import { IGoogleCategory } from "../../../Interfaces/IGoogleCategory";
import { ServerGoogleCategoriesAccordion } from "./Servers/ServerGoogleCategoriesAccordion";
import { ServerFacebookCategoriesAccordion } from "./Servers/ServerFacebookCategoriesAccordion";
import IOptionData from "../../../Interfaces/IOptionData";

interface IProps {
    product: IProduct,
    user: IUser,
    onChange(key: string, value: any): void,
    requiredFields: string[],
    fbCategories: IFacebookCategory[],
    googleCategories: IGoogleCategory[],
    subcategories: IOptionData[],
}

export interface IServersFormRef extends IValidationFormRef{

}

export const ServersFormCard = React.forwardRef<IServersFormRef, IProps>((props, ref) => {

    const {
        product,
        onChange,
        user,
        fbCategories,
        googleCategories,
    } = props;

    React.useImperativeHandle(ref, () => ({
        getInputs,
    }));

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        var inputs: React.MutableRefObject<IInputField>[] = [];

        return concat(
            inputs,
            ValidationHelper.safeGetInputs(serverSbazarSettingsRef),
            ValidationHelper.safeGetInputs(serverFacebookCategoriesRef),
            ValidationHelper.safeGetInputs(serverGoogleCategoriesRef),
            ValidationHelper.safeGetInputs(serverBazosCzSettingsRef),
            ValidationHelper.safeGetInputs(serverBazosSkSettingsRef),
        );
    }

    const serverBazosCzSettingsRef = useRef<IValidationFormRef>(null);
    const serverBazosSkSettingsRef = useRef<IValidationFormRef>(null);
    const serverSbazarSettingsRef = useRef<IValidationFormRef>(null);
    const serverFacebookCategoriesRef = useRef<IValidationFormRef>(null);
    const serverGoogleCategoriesRef = useRef<IValidationFormRef>(null);

    return <>
        <div className="card mb-3">
            <div className="card-header">
                <SectionTitle title="ZAŘAZENÍ" className="my-0" />
            </div>
            <div className="card-body">
                <div className="pb-2">
                    <div className="row">
                        {user.uploaderServers.includes(OnlineServer.Sbazar)
                            && <div className="col-12">
                                <ServerSbazarSettingsAccordion
                                    ref={serverSbazarSettingsRef}
                                    onChange={onChange}
                                    product={product}
                                />
                            </div>}

                        {user.uploaderServers.includes(OnlineServer.FacebookShop)
                            && <div className="col-12">
                                <ServerFacebookCategoriesAccordion
                                    ref={serverFacebookCategoriesRef}
                                    onChange={onChange}
                                    product={product}
                                    categories={fbCategories}
                                />
                            </div>}

                        {user.uploaderServers.includes(OnlineServer.GoogleMerchant) &&
                            <div className="col-12">
                                <ServerGoogleCategoriesAccordion
                                    ref={serverGoogleCategoriesRef}
                                    onChange={onChange}
                                    product={product}
                                    categories={googleCategories}
                                />
                            </div>}

                        {user.uploaderServers.includes(OnlineServer.BazosCz)
                            && <div className="col-12 col-md-6">
                                <ServerBazosCzSettingsAccordion
                                    ref={serverBazosCzSettingsRef}
                                    product={product}
                                    onChange={(key, value) => onChange(key, value)}
                                />
                            </div>}

                        {user.uploaderServers.includes(OnlineServer.BazosSk)
                            && <div className="col-12 col-md-6">
                                <ServerBazosSkSettingsAccordion
                                    ref={serverBazosSkSettingsRef}
                                    product={product}
                                    onChange={(key, value) => onChange(key, value)}
                                />
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    </>
})