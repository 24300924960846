import { observer } from "mobx-react";
import React from "react"
import IFacebookCatalog from "../../Interfaces/IFacebookCatalog"
import { FacebookCatalogRow } from "./FacebookCatalogRow";

interface IProps {
    facebookCatalogs: IFacebookCatalog[],
}

export const FacebookCatalogTable = observer((props: IProps) => {

    const facebookCatalogs = props.facebookCatalogs;

    return (
        <div className="table-responsive">
            <table className="table table-stripped">
                <thead>
                    <tr>
                        <th>Název</th>

                        <th>Počet inzerátů</th>

                        <th>Měna</th>

                        <th>URL zdroje</th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {facebookCatalogs.map(catalog => {
                        return <FacebookCatalogRow key={catalog.id} facebookCatalog={catalog} />
                    })}
                </tbody>
            </table>
        </div>)
})