import IProduct from "../../../Interfaces/IProduct";
import IProductCollection from "../../../Interfaces/IProductCollection";
import IProductLog from "../../../Interfaces/IProductLog";
import IProductServerInfo from "../../../Interfaces/IProductServerInfo"
import { SectionTitle } from "../../Others/SectionTitle";
import { ShowDate } from "../../Others/ShowDate";
import { ShowDateTime } from "../../Others/ShowDateTime";
import { ProductServerStateAlert } from "../ProductServerStateAlert";
import { ProductLogTable } from "./ProductLogTable";
import { ServerActionButtons } from "./ServerActionButtons";
import { SuccessServerLinkAlert } from "./SuccessServerLinkAlert";

interface IProps {
    info: IProductServerInfo,
    logs: IProductLog[],
    product: IProduct,
    collection: IProductCollection,
}

export const ManagedServerTabContent = (props: IProps) => {

    const { info, logs, product, collection } = props;

    return (
        <>
            {info.isVisible && <SuccessServerLinkAlert url={info.url} />}
            <ProductServerStateAlert collection={collection} info={info} />
            <ServerActionButtons info={info} product={product} />

            <ul>
                {info.visibleCheck && <li>
                    <small className="text-secondary">
                        Poslední kontrola <ShowDateTime date={info.visibleCheck} />
                    </small>
                </li>}

                {info.nextRenewDateUtc &&
                    <li>
                        <small className="text-secondary">Obnovení: <ShowDate date={info.nextRenewDateUtc} /></small>
                    </li>}

                {info.password &&
                    <li>
                        <small className="text-secondary">Heslo k editaci/smazání: {info.password}</small>
                    </li>}
            </ul>

            {logs.length > 0 &&
                <>
                    <SectionTitle title="Log" />
                    <hr />
                    <ProductLogTable
                        productLogs={logs}
                    />
                </>}
        </>)
}