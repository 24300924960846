import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class ProformaInvoicesApiService extends BaseApiService {

    readonly baseUrl: string = "proforma-invoices";

    public getRelativeUrlForDownloadProforma(id: string): string {
        return `${this.baseUrl}/${id}/download`;
    }

    public getProforma(id: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public getList(): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}`, {})
            .then(response => {
                return response.data;
            })
    }

    public getProformaPdf(id: string): Promise<any> {
        return this.getRequest(`${this.getRelativeUrlForDownloadProforma(id)}`, {})
            .then(response => {
                return response.data as any;
            })
    }

    public getRelativeUrlForDownloadInvoice(id: string): string {
        return `${this.baseUrl}/${id}/download-invoice`;
    }
}