import * as React from 'react';
import InputText from './InputText';


interface IProps {
    nameKey: string,
    value: string,
    onChange(value: any, nameKey: string): void,
    onDelete(nameKey: string): void,
}

interface IState {
    errors: string[],
}

export default class DictionaryTableRow extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            errors: [],
        };
    }

    render() {
        const { nameKey, value } = this.props;

        return (
            <tr>
                <td>
                    <InputText
                        key={nameKey}
                        value={nameKey}
                        label=""
                        readonly
                    />
                </td>

                <td>
                    <InputText
                        key={nameKey}
                        value={value}
                        label=""
                        onChange={(value) => this.props.onChange(value, nameKey)}
                    />
                </td>

                <td>
                    <a                 
                        className="clickable link-danger"
                        onClick={this.props.onDelete.bind(this, this.props.nameKey)}
                    >
                        Odstranit
                    </a>
                </td>
            </tr>
        )
    }
}