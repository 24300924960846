import { AutoImporterSourceType } from "../../Enums/AutoImporterSourceType";
import IVariable from "../../Interfaces/IVariable";

export default class AutoimporterHelper {

    public static getVariables(sourceType: AutoImporterSourceType): IVariable[] {
        if (sourceType === AutoImporterSourceType.Sauto) {
            return [
                { phrase: '{nadpis}', tooltip: 'Sauto titulek' },
                { phrase: '{popis}', tooltip: 'Sauto popisek' },
                { phrase: '{cena}', tooltip: '199 000 Kč' },
                { phrase: '{rokVyroby}', tooltip: '2006' },
                { phrase: '{tachometr}', tooltip: '126 366 km' },
                { phrase: '{znacka}', tooltip: 'Škoda' },
                { phrase: '{oznaceni}', tooltip: 'Dodatečné označení' },
                { phrase: '{model}', tooltip: 'Fabia' },
                { phrase: '{palivo}', tooltip: 'benzín' },
                { phrase: '{motorObjem}', tooltip: '1 422 ccm' },
                { phrase: '{motorVykon}', tooltip: '110 kw' },
                { phrase: '{vin}', tooltip: 'TMBJJ6NJXHZ094269 ' },
                { phrase: '{prevodovka}', tooltip: 'manuální' },
                { phrase: '{prevodovkaKratce}', tooltip: 'manuál' },
                { phrase: '{pocetAirbagu}', tooltip: '6' },
                { phrase: '{karoserie}', tooltip: 'Hatchback' },
                { phrase: '{odpocetDPH}', tooltip: 'Možný odpočet DPH. Cena bez DPH je 249 000 Kč', conditional: true },
                { phrase: '{bezDPH}', tooltip: 'Uvedená cena je bez DPH.', conditional: true },
                { phrase: '{vcetneDPH}', tooltip: 'Uvedená cena je včetně DPH.', conditional: true },
                { phrase: '{datumStk}', tooltip: '6/2024' },
                { phrase: '{vybava-vse}', tooltip: 'ABS, Centrální zamykání, Dálkové centrální zamykání, Deaktivace airbagu spolujezdce, Dělená zadní sedadla, El. ovládání zrcátek, ESP, Imobilizér, Isofix, Mlhovky, Nastavitelná sedadla, Originální autorádio, Palubní počítač, Posilovač řízení, Protiprokluzový systém kol (ASR), USB, Venkovní teploměr, Vstup paměťové karty, Vyhřívaná zrcátka, Výškově nastavitelné sedadlo řidiče, Zadní stěrač' },
                { phrase: '{vybava-asistenti}', tooltip: '360° monitorovací systém (AVM), Parkovací asistent, Parkovací kamera, Parkovací senzory, Tempomat, Parkovací senzory přední, Parkovací senzory zadní' },
                { phrase: '{vybava-drive}', tooltip: 'Sportovní podvozek' },
                { phrase: '{vybava-bezpecnost}', tooltip: 'ABS, ESP, Hlídání mrtvého úhlu, Protiprokluzový systém kol (ASR), Sledování jízdního pruhu' },
                { phrase: '{vybava-svetla}', tooltip: 'Automatické přepínání dálkových světel, Bi-xenony, LED denní svícení, Mlhovky, Natáčecí světlomety, Ostřikovače světlometů' },
                { phrase: '{vybava-systemy}', tooltip: 'Bluetooth, CD přehrávač, DVD přehrávač, Head-up display, Originální autorádio, Palubní počítač, Satelitní navigace' },
                { phrase: '{vybava-sedadla}', tooltip: 'El. seřiditelná sedadla, Isofix, Kožená sedadla, Kožené potahy, Pamět nastavení sedadla řidiče, Sportovní sedadla, Vyhřívaná sedadla, Výškově nastavitelná sedadla' },
                { phrase: '{vybava-interier}', tooltip: 'Bezklíčkové ovládání, Deaktivace airbagu spolujezdce, El. ovládání oken, El. ovládání zrcátek, El. sklopná zrcátka, Multifunkční volant, Nastavitelný volant, Nezávislé topení, Pádla řazení na volantu, Posilovač řízení, Senzor opotřebení brzd. destiček, Senzor stěračů, Senzor tlaku v pneumatikách, Venkovní teploměr, Vyhřívaná zrcátka, Vyhřívaný volant' },
                { phrase: '{vybava-exterier}', tooltip: 'Funkce plynulého dovírání dveří, Litá kola, Tónovaná skla' },
                { phrase: '{vybava-ostatni}', tooltip: 'Záruka' },
            ];
        }
        else if (sourceType === AutoImporterSourceType.Tipcars) {
            return [
                { phrase: '{popis}', tooltip: 'Tipcars popisek' },
                { phrase: '{cena}', tooltip: '199 000 Kč' },
                { phrase: '{rokVyroby}', tooltip: '2006' },
                { phrase: '{tachometr}', tooltip: '126 366 km' },
                { phrase: '{znacka}', tooltip: 'Škoda' },
                { phrase: '{oznaceni}', tooltip: 'Dodatečné označení' },
                { phrase: '{model}', tooltip: 'Fabia' },
                { phrase: '{palivo}', tooltip: 'benzín' },
                { phrase: '{motorObjem}', tooltip: '1 422 ccm' },
                { phrase: '{motorVykon}', tooltip: '110 kw' },
                { phrase: '{vin}', tooltip: 'TMBJJ6NJXHZ094269 ' },
                { phrase: '{prevodovka}', tooltip: 'manuální' },
                { phrase: '{prevodovkaKratce}', tooltip: 'manuál' },
                { phrase: '{datumStk}', tooltip: '6/2024' },
                { phrase: '{karoserie}', tooltip: 'Hatchback' },
                { phrase: '{vybava-vse}', tooltip: 'ABS, Centrální zamykání, Dálkové centrální zamykání, Deaktivace airbagu spolujezdce, Dělená zadní sedadla, El. ovládání zrcátek, ESP, Imobilizér, Isofix, Mlhovky, Nastavitelná sedadla, Originální autorádio, Palubní počítač, Posilovač řízení, Protiprokluzový systém kol (ASR), USB, Venkovní teploměr, Vstup paměťové karty, Vyhřívaná zrcátka, Výškově nastavitelné sedadlo řidiče, Zadní stěrač' },

                // conditional

                { phrase: '{odpocetDPH}', tooltip: 'Možný odpočet DPH. Cena bez DPH je 249 000 Kč', conditional: true },
                { phrase: '{bezDPH}', tooltip: 'Uvedená cena je bez DPH.', conditional: true },
            ];
        }
        else if (sourceType === AutoImporterSourceType.AutobazarEu) {
            return [
                { phrase: '{popis}', tooltip: 'Tipcars popisek' },
                { phrase: '{cena}', tooltip: '199 000 Kč' },
                { phrase: '{rokVyroby}', tooltip: '2006' },
                { phrase: '{tachometr}', tooltip: '126 366 km' },
                { phrase: '{znacka}', tooltip: 'Škoda' },
                { phrase: '{model}', tooltip: 'Fabia' },
                { phrase: '{palivo}', tooltip: 'benzín' },
                { phrase: '{motorObjem}', tooltip: '1 422 ccm' },
                { phrase: '{motorVykon}', tooltip: '110 kw' },
                { phrase: '{vin}', tooltip: 'TMBJJ6NJXHZ094269 ' },
                { phrase: '{prevodovka}', tooltip: 'manuální' },
                { phrase: '{prevodovkaKratce}', tooltip: 'manuál' },
                { phrase: '{datumStk}', tooltip: '6/2024' },
                { phrase: '{barva}', tooltip: 'Černá' },
                { phrase: '{karoserie}', tooltip: 'Hatchback' },
                { phrase: '{vybava-vse}', tooltip: 'ABS, Centrální zamykání, Dálkové centrální zamykání, Deaktivace airbagu spolujezdce, Dělená zadní sedadla, El. ovládání zrcátek, ESP, Imobilizér, Isofix, Mlhovky, Nastavitelná sedadla, Originální autorádio, Palubní počítač, Posilovač řízení, Protiprokluzový systém kol (ASR), USB, Venkovní teploměr, Vstup paměťové karty, Vyhřívaná zrcátka, Výškově nastavitelné sedadlo řidiče, Zadní stěrač' },
                { phrase: '{odpocetDPH}', tooltip: 'Možný odpočet DPH. Cena bez DPH je 249 000 Kč', conditional: true },
                { phrase: '{bezDPH}', tooltip: 'Uvedená cena je bez DPH.', conditional: true },
                { phrase: '{poznamka}', tooltip: 'Poznámka či dodatečný text' },
                { phrase: '{poznamka2}', tooltip: 'Poznámka či dodatečný text' },
                { phrase: '{moznyUver}', tooltip: 'Ano/Ne' },
                { phrase: '{moznyLeasing}', tooltip: 'Ano/Ne' },
                { phrase: '{prvniRegistraceMesic}', tooltip: '5' },
                { phrase: '{prvniRegistraceRok}', tooltip: '2016' },
                { phrase: '{pohon}', tooltip: 'Přední' },
                { phrase: '{odpocetDPH-text}', tooltip: 'Přední' },

                //   { phrase: '{dph}', tooltip: 'Ano/Ne' },
            ]
        }
        else if (sourceType === AutoImporterSourceType.GoogleShopping) {
            return [
                { phrase: '{nadpis}', tooltip: 'Název produktu' },
                { phrase: '{popis}', tooltip: 'Popis produktu' },
            ]
        }
        return [];
    }
}