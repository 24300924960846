import { inject, observer } from "mobx-react"
import { PageTitleBox } from "../../Others/PageTitleBox"
import { SalesChart } from "../../Products/Charts/SalesChart"
import CurrentUserStore from "../../Stores/CurrentUserStore"
import { PurchasesChart } from "../../Products/Charts/PurchasesChart"
import { BusinessOverviewWidgets } from "../../MyBusiness/BusinessOverviewWidgets"
import { SeparateThousands } from "../../Others/SeparateThousands"
import CurrencyHelper from "../../../Helpers/CurrencyHelper"
import UserApiService from "../../../Services/UserApiService"
import { useEffect, useState } from "react"
import { IMonthlySalesReport } from "../../../Interfaces/IMonthlySalesReport"
import LoadingScreen from "../../Screens/LoadingScreen"
import { IMonthlyPurchasesReport } from "../../../Interfaces/IMonthlyPurchasesReport"
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle"
import { SalesCountChart } from "../../Products/Charts/SalesCountChart"

interface IProps {
    currentUserStore?: CurrentUserStore,
}

const userApiService = new UserApiService();

export const ShopOverviewPage = inject('currentUserStore')(observer((props: IProps) => {

    const [loading, setLoading] = useState(true);
    const [monthlySalesReport, setMonthlySalesReport] = useState<IMonthlySalesReport[]>([])
    const [monthlyPurchasesReport, setPurchasesReport] = useState<IMonthlyPurchasesReport[]>([])
    const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        userApiService.getDashboard()
            .then(data => {
                setMonthlySalesReport(data.others.get("sales"));
                setPurchasesReport(data.others.get("purchases"));
                setTotalQuantity(data.others.get("totalQuantity"))
                setLoading(false);
            })

        return () => {
            userApiService.cancelRequests();
        }
    }, [])


    const user = props.currentUserStore.user;
    const currencySymbol = CurrencyHelper.getSymbol(user.accountCurrency);

    return (
        <>
            <CustomDocumentTitle title="Obchodní přehled">
                <PageTitleBox title="Obchodní přehled" />

                {loading && <LoadingScreen />}

                {!loading &&
                    <>
                        <BusinessOverviewWidgets
                            user={user}
                        />

                        <div className="row no-gutters">
                            <div className="col-12 col-lg-9 order-2 order-lg-1 pr-1">
                                <div className="card d-flex flex-column mb-1">
                                    <div className="card-header" style={{ zIndex: 1 }}>
                                        Prodeje
                                    </div>
                                    <div className="card-body">
                                        <SalesChart sales={monthlySalesReport} currency={user.accountCurrency} />
                                    </div>
                                </div>

                                <div className="card d-flex flex-column mb-1">
                                    <div className="card-header" style={{ zIndex: 1 }}>
                                        Počet prodaných položek
                                    </div>
                                    <div className="card-body">
                                        <SalesCountChart sales={monthlySalesReport} />
                                    </div>
                                </div>

                                <div className="card d-flex flex-column mb-1">
                                    <div className="card-header" style={{ zIndex: 1 }}>
                                        Nákupy
                                    </div>
                                    <div className="card-body">
                                        <PurchasesChart purchases={monthlyPurchasesReport} currency={user.accountCurrency} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-3 order-1 order-lg-2">
                                <div className="row no-gutters">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-12 pr-1">
                                        <div className="card" style={{ marginBottom: '5px' }}>
                                            <div className="card-body">
                                                <div className="float-left">
                                                    <span className="widget-title">
                                                        <SeparateThousands number={user.marginThisYear} />&nbsp;{currencySymbol}
                                                    </span>
                                                    <p className="subtitle">Letošní hrubá marže</p>
                                                </div>
                                                <div className="float-right">
                                                    <i className="bi bi-cash widget-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-12 pr-1">
                                        <div className="card" style={{ marginBottom: '5px' }}>
                                            <div className="card-body">
                                                <div className="float-left">
                                                    <span className="widget-title">
                                                        <SeparateThousands number={user.percentageAverageMargin} />&nbsp;%
                                                    </span>
                                                    <p className="subtitle">Prům. hrubá marže</p>
                                                </div>
                                                <div className="float-right">
                                                    <i className="bi bi-wallet2 widget-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-12 pr-1">
                                        <div className="card" style={{ marginBottom: '5px' }}>
                                            <div className="card-body">
                                                <div className="float-left">
                                                    <span className="widget-title">
                                                        <SeparateThousands number={user.averageMonthlyMargin} />&nbsp;{currencySymbol}
                                                    </span>
                                                    <p className="subtitle">Prům. měsíční hrubá marže</p>
                                                </div>
                                                <div className="float-right">
                                                    <i className="bi bi-wallet2 widget-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-12 pr-1">
                                        <div className="card" style={{ marginBottom: '5px' }}>
                                            <div className="card-body">
                                                <div className="float-left">
                                                    <span className="widget-title">
                                                        <SeparateThousands number={user.averageSalesTimeInDays} />&nbsp;dní
                                                    </span>
                                                    <p className="subtitle">Prům. doba prodeje</p>
                                                </div>
                                                <div className="float-right">
                                                    <i className="bi bi-calendar4-event widget-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                {(user.id === "dfdffc28-ce7e-40e0-9a75-e24ca471f474" ||
                                    user.email == "adam.gajdecka@gmail.com") &&
                                        // moravec
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 pr-1">
                                            <div className="card" style={{ marginBottom: '5px' }}>
                                                <div className="card-body">
                                                    <div className="float-left">
                                                        <span className="widget-title">
                                                            <SeparateThousands number={totalQuantity} />
                                                        </span>
                                                        <p className="subtitle">Počet skladem</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-12 pr-1">
                                        <div className="card" style={{ marginBottom: '5px' }}>
                                            <div className="card-body">
                                                <div className="float-left">
                                                    <span className="widget-title">
                                                        <SeparateThousands number={user.averagePurchasePrice} />&nbsp;{currencySymbol}
                                                    </span>
                                                    <p className="subtitle">Prům. nákupní cena</p>
                                                </div>
                                                <div className="float-right">
                                                    <i className="bi bi-cash widget-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </>}
            </CustomDocumentTitle>
        </>
    )
}))