import React from "react"

interface IProps {
    onCreateWebsite(): void,
}

export const NoWebsitesCard = (props: IProps) => {

    return (
        <div className="card">
            <div className="card-body">
                <div className="py-3">
                    <div className="d-flex justify-content-center mb-1 mt-2">
                        <i className="fas fa-globe fa-3x"></i>
                    </div>

                    <br />
                    <div className="text-center">
                        <h3>Vytvořte si prodejní web</h3>
                    </div>
                    <div className="text-center">
                        <p>Vygenerujeme vám prodejní web z vašich inzerátů. Zcela automaticky a bezúdržbově.</p>

                        <button onClick={props.onCreateWebsite} className="btn btn-primary btn-lg mt-3">
                            Vytvořit prodejní web
                        </button>
                    </div>
                </div>
            </div>
        </div>)
}