import { round } from "lodash";
import IAutoImporter from "../../../Interfaces/IAutoImporter";

interface IProps {
    autoImporter: IAutoImporter
}

export const AutoImporterProgressBar = (props: IProps) => {

    const { autoImporter } = props;

    let p = (autoImporter.processedOffersCount / autoImporter.totalOffersCount) * 100;
    let percentageProgress: number = round(p, 0);

    let bgClass = "bg-success";

    if (autoImporter.pause) {
        bgClass = "bg-secondary";
    }

    return <div className="progress my-3" style={{ height: '30px' }}>
        <div className={`progress-bar ${bgClass}`}
            role="progressbar"
            style={{ width: `${percentageProgress}%` }}
            aria-valuenow={percentageProgress}
            aria-valuemin={0}
            aria-valuemax={100}>
            Importováno {autoImporter.processedOffersCount} z {autoImporter.totalOffersCount} - {percentageProgress} %
        </div>
    </div>
}