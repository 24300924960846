import { Link } from "react-router-dom"
import { currentFrontEndVersion } from '../../App';

export const Footer = () => {

    return (<footer className="footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 position-relative">
                    <Link to='/kontakt'>Zákaznická podpora</Link> | <Link to='/cenik'>Ceník služeb</Link> | <a href="/documents/vop.pdf">Obchodní podmínky</a>
                    <br />
                    <small> 2016 - {new Date().getFullYear()} © Inzerobot.cz</small>
                    <small style={{ right: '0px' }} className="position-absolute">v.{currentFrontEndVersion}</small>
                </div>
            </div>
        </div>
    </footer>)
}