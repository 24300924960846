import React from "react"
import IProductLog from "../../../Interfaces/IProductLog"
import { ShowDateTime } from "../../Others/ShowDateTime";
import Linkify from 'react-linkify';
import { ProductLogHelper } from "../../Utils/ProductLogHelper";
import { ProductLogIcon } from "./ProductLogIcon";

interface IProps {
    productLogs: IProductLog[],
}

export const ProductLogTable = (props: IProps) => {

    const { productLogs } = props;

    return (
        <>
            <div className="table-responsive" style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                <table className="table table-striped mg-b-0">
                    <tbody>
                        {productLogs.map((log: IProductLog) => {
                            return (
                                <tr key={log.id}>
                                    <td>
                                        <ProductLogIcon productLog={log} />
                                    </td>

                                    <td style={{ width: '170px' }}>
                                        <small><ShowDateTime date={log.dateUtc} /></small>
                                    </td>

                                    <td>
                                        <small>
                                            {ProductLogHelper.getActionLabel(log.action)}
                                        </small>
                                    </td>

                                    <td>
                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}>
                                            <small>{log.message}</small>
                                        </Linkify>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>)
}