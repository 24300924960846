import { useRef } from "react";
import SmartSelectBox from "../../UI/Input/SmartSelectBox";
import IProduct from "../../../Interfaces/IProduct";
import ListsStore from "../../Stores/ListsStore";
import { inject, observer } from "mobx-react";
import { ListType } from "../../../Enums/ListType";
import { SectionTitle } from "../../Others/SectionTitle";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import React from "react";
import IInputField from "../../../Interfaces/IInputField";

interface IProps {
    product: IProduct,
    onChange(key: string, value: any): void,
    listsStore?: ListsStore,
    requiredFields: string[],
}

export const TiresFormCard = inject('listsStore')(observer(React.forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const { requiredFields, product, onChange, listsStore } = props;

    const width = useRef<SmartSelectBox>(null);
    const height = useRef<SmartSelectBox>(null);
    const rimDiameter = useRef<SmartSelectBox>(null);
    const loadIndex = useRef<SmartSelectBox>(null);
    const speedRating = useRef<SmartSelectBox>(null);
    const vehicleType = useRef<SmartSelectBox>(null);
    const season = useRef<SmartSelectBox>(null);
    const profile = useRef<SmartSelectBox>(null);

    React.useImperativeHandle(ref, () => ({
        getInputs,
    }));

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        return [
            width,
            height,
            rimDiameter,
            loadIndex,
            speedRating,
            vehicleType,
            season,
            profile
        ]
    }

    {/*   <div className="col-12 col-sm-6 col-md-4">
                                <SmartSelectBox
                                    ref={this.inputBrand}
                                    label="Značka"
                                    options={Tire.getTireBrandOptions()}
                                    required
                                    value={product.brand}
                                    onChange={onChange.bind(this, 'brand')}
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-4">
                                <SelectBox
                                    ref={this.inputQuantity}
                                    label="Počet pneumatik"
                                    onChange={onChange.bind(this, 'quantity')}
                                    options={Tire.getTireQuantityOptions()}
                                    required
                                    defaultValue="Vyberte"
                                    value={product.quantity}
                                />
                            </div>*/}

    return (
        <div className="card mb-3">
            <div className="card-header">
                <SectionTitle title="Technické údaje" className="my-0" />
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4">
                        <SmartSelectBox
                            ref={vehicleType}
                            label="Typ vozidla"
                            onChange={(value, key) => onChange(key, parseInt(value))}
                            value={(product.tireVehicleType || '').toString()}
                            options={listsStore.getOptionsBy(ListType.TireVehicleType)}
                            uid="tireVehicleType"
                            required={requiredFields.includes("tireVehicleType")}
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <SmartSelectBox
                            ref={season}
                            label="Roční období"
                            onChange={(value, key) => onChange(key, parseInt(value))}
                            value={(product.tireSeason || '').toString()}
                            options={listsStore.getOptionsBy(ListType.TireSeason)}
                            uid="tireSeason"
                            required={requiredFields.includes("tireSeason")}
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <SmartSelectBox
                            ref={width}
                            label="Šířka"
                            onChange={(value, key) => onChange(key, parseInt(value))}
                            value={(product.tireWidth || '').toString()}
                            options={listsStore.getOptionsBy(ListType.TireWidth)}
                            uid="tireWidth"
                            required={requiredFields.includes("tireWidth")}
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <SmartSelectBox
                            ref={height}
                            label="Výška"
                            onChange={(value, key) => onChange(key, parseInt(value))}
                            value={(product.tireHeight || '').toString()}
                            options={listsStore.getOptionsBy(ListType.TireHeight)}
                            uid="tireHeight"
                            required={requiredFields.includes("tireHeight")}
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <SmartSelectBox
                            ref={profile}
                            label="Profil"
                            onChange={(value, key) => onChange(key, parseInt(value))}
                            value={(product.tireProfile || '').toString()}
                            options={listsStore.getOptionsBy(ListType.TireProfile)}
                            uid="tireProfile"
                            required={requiredFields.includes("tireProfile")}
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <SmartSelectBox
                            ref={rimDiameter}
                            label="Průměr"
                            onChange={(value, key) => onChange(key, parseInt(value))}
                            value={(product.tireRimDiameter || '').toString()}
                            options={listsStore.getOptionsBy(ListType.TireRimDiameter)}
                            uid="tireRimDiameter"
                            required={requiredFields.includes("tireRimDiameter")}
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <SmartSelectBox
                            ref={loadIndex}
                            label="Index zatížení"
                            onChange={(value, key) => onChange(key, parseInt(value))}
                            value={(product.tireLoadIndex || '').toString()}
                            options={listsStore.getOptionsBy(ListType.TireLoadIndex)}
                            uid="tireLoadIndex"
                            required={requiredFields.includes("tireLoadIndex")}
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <SmartSelectBox
                            ref={speedRating}
                            label="Rychlostní index"
                            onChange={(value, key) => onChange(key, parseInt(value))}
                            value={(product.tireSpeedRating || '').toString()}
                            options={listsStore.getOptionsBy(ListType.TireSpeedRating)}
                            uid="tireSpeedRating"
                            required={requiredFields.includes("tireSpeedRating")}
                        />
                    </div>
                </div>
            </div>
        </div>)
})))