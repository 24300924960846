import React from "react"
import { Modal } from "react-bootstrap"
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import BaseComponent from "../BaseComponent";
import InputText from "../UI/Input/InputText";
import SelectBox from "../UI/Input/SelectBox";
import ICreateFacebookCatalogRequest from "../../Interfaces/Requests/FacebookCatalogs/ICreateFacebookCatalogRequest";

interface IState {
    form: ICreateFacebookCatalogRequest,
}

interface IProps {
    onHide(): void,
}

export default class FacebookCatalogCreateModal extends BaseComponent<IProps, IState> {

    private inputName = React.createRef<InputText>();
    private inputCurrency = React.createRef<SelectBox>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            form: {
                currency: null,
                name: '',
            }
        }

        this.inputName = React.createRef();
        this.inputCurrency = React.createRef();
    }

    handleSubmit() {
        const inputs = [this.inputName, this.inputCurrency];

        const isValid = this.validateInputs(inputs);

        if (isValid) {
            this.facebookCatalogsApiService
                .createCatalog(this.state.form)
                .then(data => {
                    if (data.success) {
                        this.props.onHide();
                    }
                })
        } 
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    render() {
        const form = this.state.form;

        return (
            <Modal centered={true} show={true} onHide={() => this.props.onHide()}>
                <Modal.Header closeButton>
                    <Modal.Title>Vytvoření zdroje</Modal.Title>
                </Modal.Header>
                <Modal.Body>         
                    <InputText
                        ref={this.inputName}
                        label="Název"
                        value={form.name}
                        onChange={value => this.handleChange(value, 'name')}
                        required
                    />

                    <SelectBox
                        ref={this.inputCurrency}
                        label="Měna"
                        onChange={value => this.handleChange(value, 'currency')}
                        options={CurrencyHelper.getOptions()}
                        value={form.currency}
                        defaultValue="Vyberte"
                        required
                    />

                    <button
                        className="btn btn-primary"
                        onClick={() => this.handleSubmit()}
                    >
                        Vytvořit
                    </button>
                </Modal.Body>
            </Modal>)
    }
}