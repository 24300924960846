import React from "react"
import { Link } from "react-router-dom"
import { SectionTitle } from "../Others/SectionTitle"

interface IProps {
    title: string,
    description: string,
    linkTo: string,
}

export const IntegrationCard = (props: IProps) => {

    return (
        <div className="card mb-2">
            <div className="card-body">
                <SectionTitle className="mt-0" title={props.title} />
                <hr />
                <p>{props.description}</p>

                <Link to={props.linkTo}>
                    <button className={`btn btn-info btn-block`}><i className="fas fa-cog mr-1"></i>Spravovat</button>
                </Link>
            </div>
        </div>)
}