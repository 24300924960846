import React from "react"
import { Currency } from "../../Enums/Currency"
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import { PricingHelper } from "../Utils/PricingHelper";

interface IProps {
    currency: Currency,
}

export const ExtraChargePricing = (props: IProps) => {
    const { currency } = props;

    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead className="text-center">
                        <tr>
                            <th>Položka</th>

                            <th>Cena za měsíc</th>

                        </tr>
                    </thead>

                    <tbody className="text-center">

                        <tr>
                            <td>1x IP adresa</td>

                            <td>
                                {PricingHelper.getIpPrice(currency)} {CurrencyHelper.getSymbol(currency)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}