import React from "react"
import IProductServerInfo from "../../../Interfaces/IProductServerInfo"
import { IWebsite } from "../../../Interfaces/IWebsite";
import { InfoAlert } from "../../Others/Alerts/InfoAlert";
import { WarningAlert } from "../../Others/Alerts/WarningAlert";
import { NotSetupedAlert } from "./NotSetupedAlert";
import { SuccessServerLinkAlert } from "./SuccessServerLinkAlert";

interface IProps {
    info: IProductServerInfo,
    website: IWebsite,
}

export const WebTabContent = (props: IProps) => {
    const { info, website } = props;

    if (website) {
        return (
            <>
                {website.customDomain && info.enabled && info.isVisible &&
                    <SuccessServerLinkAlert url={info.url} />}

                {website.isCustomDomain ?
                    <InfoAlert
                        text={"Vytvoření a editace inzerátu na webu se může projevit se zpožděním"}
                    />
                    :
                    <WarningAlert
                        text={"Pouze weby na vlastní doméně jsou aktualizovány. Nastavte si vlastní doménu."}
                    />
                }
            </>)
    }
    else {
        return (<NotSetupedAlert />)
    }
}