import IInputField from "../../Interfaces/IInputField";
import { IValidationFormRef } from "../../Interfaces/IValidationFormRef";

export class ValidationHelper {

    public static validateInputs(inputs: React.RefObject<IInputField>[]) {
        let firstInvalid: IInputField;
        for (var i = 0; i < inputs.length; i++) {
            const input: IInputField = inputs[i].current!;

            if (input) {
                if (input.validate) {
                    input.validate();
                }

                if (!input.isValid() && !firstInvalid!) {
                    firstInvalid = input;
                    input.focus!();
                }

            }
        }

        if (firstInvalid!) {
            return false;
        }
        return true;
    }

   
    public static validateInputsWithReturnInvalids(inputs: React.RefObject<IInputField>[], focus: boolean = true): IInputField[] {
        let invalids: IInputField[] = [];

        for (var i = 0; i < inputs.length; i++) {
            const input: IInputField = inputs[i].current!;

            if (input) {
                if (input.validate) {
                    input.validate();
                }

                if (!input.isValid()) {
                    invalids.push(input);
                }
            }
        }

        if (invalids.length > 0 && focus) {
            invalids[0].focus!();
        }
        return invalids;
    }


    public static isValid(inputs: React.RefObject<IInputField>[]) {
        for (var i = 0; i < inputs.length; i++) {
            const input: IInputField = inputs[i].current!;

            if (input) {
                if (input.isValid() != null) {
                    if (!input.isValid()) {
                        return false;
                    }
                }

            }
        }
        return true;
    }

    public static validate(input: React.RefObject<IInputField>) {
        if (input && input.current) {
            input.current.validate();
        }
    }

    public static safeGetInputs = (ref: React.MutableRefObject<IValidationFormRef>): React.MutableRefObject<IInputField>[] => {
        if (!ref || !ref.current || !ref?.current?.getInputs()) {
            return [];
        }
        else {
            return ref?.current?.getInputs();
        }
    }


}