import BaseComponent from './../../BaseComponent';
import React from 'react';
import IProductCollection from '../../../Interfaces/IProductCollection';
import SbazarSettingsModal from './Create/SbazarSettingsModal';
import { inject, observer } from 'mobx-react';
import AppIpAddressStore from '../../Stores/AppIpAddressStore';
import IAppIpAddress from '../../../Interfaces/IAppIpAddress';
import { filter } from 'lodash';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';

interface IState {
    show: boolean
}

interface IProps {
    collection: IProductCollection,
    appIpAddressStore?: AppIpAddressStore,
    productCollectionStore?: ProductCollectionStore,
}

@inject('appIpAddressStore')
@inject('productCollectionStore')
@observer
export default class SbazarErrorAlert extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            show: false
        }
    }

    handleShow(show: boolean) {
        this.setState({
            show: show
        })
    }

    render() {
        const collection = this.props.collection;

        if (collection.sbazarError == false) {
            return null;
        }
        const ipAddresses = this.props.appIpAddressStore.list;
        let appIps: IAppIpAddress[] = filter(ipAddresses, { userId: collection.userId });
        const collections = filter(this.props.productCollectionStore.list, { userId: collection.userId });

        return (
            <div>
                <SbazarSettingsModal
                    collection={collection}
                    onHide={this.handleShow.bind(this, false)}
                    show={this.state.show}
                    appIpAddresses={appIps}
                    collections={collections}
                />

                {collection.sbazarError &&
                    <div className="alert alert-warning text-center my-2 w-100" style={{
                        border: '1px solid #ced4da'
                    }}>
                        <i className="fas fa-exclamation-triangle text-warning mr-2"></i> Sbazar.cz má problém s přihlášením
                    <button
                            className="btn btn-warning btn-sm ml-2"
                            onClick={this.handleShow.bind(this, true)}
                        >
                            Vyřešit
                        </button>
                    </div>}
            </div>
        )
    }
}