export enum BulkActionType {
    RenewOnline =1,
    UploadOffline = 2,
    UploadError = 3,
    CancelAllActions = 4,
    Check = 5,
    DisableAll = 6,
    EnableAll = 7,
    Edit = 8,
    Top = 9,
    UpdatePricesByExchangeRates = 10,
}