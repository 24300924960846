export enum VehicleColor {
    Beige = 1,
    Gold = 2,
    Purple = 3,
    Yellow = 4,
    Black = 5,
    Green = 6,
    Red = 7,
    Blue = 8,
    Gray = 9,
    Silver = 10,
    Brown = 11,
    Orange = 12,
    White = 13,
    Pink = 14,
    Other = 15
}