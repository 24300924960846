import React from "react"

interface IProps {

}

export const RejectedErrorAlertServer = (props: IProps) => {
    return (<>
        <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-triangle mr-2"/> Inzerát byl odmítnut inzertním serverem. Zkontrolujte, zda vyhovuje podmínkám daného portálu.
        </div>
    </>)
}