import React from "react"

interface IProps {
    text?: string,
    children?: React.JSX.Element,
    className?: string,
}

export const WarningAlert = (props: IProps) => {
    const { text, children } = props;

    return <div className={`alert alert-warning ${props.className}`} role="alert">
        {text && <> <i className="fas fa-exclamation-triangle text-warning mr-2"></i>{text}</>}
        {children}
    </div>
}