import IOptionData from '../Interfaces/IOptionData';
import { filter, replace } from 'lodash';
import { toJS } from "mobx";

export default class StringHelper {

    public static separateThousands(number: any): string {
        if (!number && number !== 0) {
            return null;
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;")
    }

    public static separateThousandsNoHtml(number: any): string {
        if (!number && number !== 0) {
            return null;
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }

    public static escapeHtml(unsafe: string) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    public static getUnorderedListFromText(text: string, classNameUl?: string) {
        let items: JSX.Element[] = [];

        if (!text) {
            return items;
        }

        let rows = text.split("\n");

        for (var i = 0; i < rows.length; i++) {

            let z = rows[i].replace(/\s/g, "");
            if (z.length > 0) {
                items.push(
                    <li className="pd-3" key={i} dangerouslySetInnerHTML={{ __html: this.escapeHtml(rows[i].toString()) }}>

                    </li>
                )
            }
        }
        return <ul className={classNameUl}> {items}</ ul>;
    }

    public static replaceAllSpacesToNonBreakingSpace(text: string) {
        for (var i = 0; i < text.length; i++) {

            text = replace(text, ' ', '&nbsp;');
        }
        return text;
    }

    public static getParagraphs(text: string, maxLength?: number) {
        let paragraphs: JSX.Element[] = [];

        if (text === undefined || text === null) {
            return paragraphs;
        }

        if (maxLength) {
            if (text.length > maxLength) {
                text = text.slice(0, maxLength);
                text = text + "...";
            }
        }

        let rows = text.split("\n");

        for (var i = 0; i < rows.length; i++) {
            paragraphs.push(
                <p key={i} dangerouslySetInnerHTML={{ __html: this.escapeHtml(rows[i].toString()) }}>
                </p>
            )
        }
        return paragraphs;
    }

    public static TruncateLongString(text: string, length: number) {
        if (text.length > length) {
            return text.slice(0, length - 3) + '...';
        }
        return text;
    }

    public static enumerationFromOptions(array: IOptionData[], selectedItemIds?: any[], lowerCase?: boolean) {
        {
            let items: JSX.Element[] = [];

            array = filter(array, (item: IOptionData) => {
                if (selectedItemIds && selectedItemIds.indexOf(item.value) >= 0) {
                    return true;
                }
                if (!selectedItemIds) {
                    return true;
                }
            });

            array.map((item: IOptionData, index) => {
                items.push(<span key={item.value}> {lowerCase ? item.label.toLowerCase() : item.label}{index != array.length - 1 ? "," : ""}
                </span>)

            })
            return items;
        }
    }

    public static getDaysOfWeekOptions(): IOptionData[] {
        return [
            { label: 'Pondělí', value: 'monday' },
            { label: 'Úterý', value: 'tuesday' },
            { label: 'Středa', value: 'wednesday' },
            { label: 'Čtvrtek', value: 'thursday' },
            { label: 'Pátek', value: 'friday' },
            { label: 'Sobota', value: 'saturday' },
            { label: 'Neděle', value: 'sunday' },
        ];
    }

    public static isContainsPhoneNumber(text: string): boolean {
        var reg = /([+]?\d{1,3}[. \s]?)?(\d{9}?)/;

        let isNumber: boolean = reg.test(text);

        return isNumber;
    }

    public static isContainsUrl(text: string): boolean {
        if (new RegExp("[a-zA-Z\d]+://(\w+:\w+@)?([a-zA-Z\d.-]+\.[A-Za-z]{2,4})(:\d+)?(/.*)?").test(text)) {
            return true;
        }

        if (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+").test(text)) {
            return true;
        }
        return false;
    }

    public static isContainsEmail(text: string) {
        var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        return re.test(text);
    }

    public static convertToOptionData(values: number[], list: IOptionData[]): IOptionData[] {
        return filter(list, item => {
            if ((values || []).includes(parseInt(item.value))) {
                return true;
            }
            return false;
        });
    }

    public static mapToOptionDataArray(m: Map<string, string>): IOptionData[] {
        const options: IOptionData[] = [];

        var map: Map<string, any> = toJS(m); // .toJS();

        console.log(map);
        map.forEach((value, key, map) => {
            options.push({ label: value, value: key })
        });
        return options;
    }

    public static capitalizeFirstLetter(str: string) :string {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
}