import { useEffect, useRef } from 'react';
import { IWebsite } from '../../Interfaces/IWebsite';
import WebsitesApiService from '../../Services/WebsitesApiService';
import { TinyMCEEditor } from '../UI/TinyMCEEditor';
import { Tab, Tabs } from 'react-bootstrap';
import { WebsiteType } from '../../Enums/WebsiteType';
import InputText from '../UI/Input/InputText';
import { WebsiteImageControl } from './WebsiteImageControl';
import CheckBox from '../UI/Input/CheckBox';

interface IProps {
    website: IWebsite,
    handleChangeSettings(key: string, value: any): void,
}

const websitesApiService = new WebsitesApiService();

export const WebsiteContentSettings = (props: IProps) => {

    const { website, handleChangeSettings } = props;

    const productsTitleRef = useRef<InputText>(null);
    const productsSlugRef = useRef<InputText>(null);

    const carsTitleRef = useRef<InputText>(null);
    const carsSlugRef = useRef<InputText>(null);

    const aboutUsTitleRef = useRef<InputText>(null);
    const aboutUsSlugRef = useRef<InputText>(null);

    const financingTitleRef = useRef<InputText>(null);
    const financingSlugRef = useRef<InputText>(null);

    const sellCarTitleRef = useRef<InputText>(null);
    const sellCarSlugRef = useRef<InputText>(null);

    const gdprTitleRef = useRef<InputText>(null);
    const gdprSlugRef = useRef<InputText>(null);

    const termsAndConditionsTitleRef = useRef<InputText>(null);
    const termsAndConditionsSlugRef = useRef<InputText>(null);

    useEffect(() => {
        return () => {
            websitesApiService.cancelRequests();
        }
    }, [])

    return (
        <>
           
            <label className="section-title mt-4 mb-3">Stránky</label>
            <p>Texty pro samostatné stránky.</p>

            <div className="nav-tabs-editor">
                <Tabs
                    id="uncontrolled-tab-example"
                    className="nav nav-justified"
                    defaultActiveKey='products'
                >
                    <Tab
                        eventKey="products"
                        title="Obecné produkty"
                        tabClassName="nav-item"
                    >
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <InputText
                                    ref={productsTitleRef}
                                    label="Nadpis"
                                    value={website.settings.productsPageTitle}
                                    onChange={(val) => handleChangeSettings('productsPageTitle', val)}
                                    required
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <InputText
                                    ref={productsSlugRef}
                                    label="Slug"
                                    value={website.settings.productsPageSlug}
                                    onChange={(val) => handleChangeSettings('productsPageSlug', val)}
                                    required
                                />
                            </div>
                        </div>

                        <TinyMCEEditor
                            onChange={(val) => handleChangeSettings('productsPageDescription', val)}
                            value={website.settings.productsPageDescription}
                        />
                    </Tab>

                    {website.type === WebsiteType.Cars &&
                        <Tab
                            eventKey="cars"
                            title="Auta"
                            tabClassName="nav-item"
                        >
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <InputText
                                        ref={carsTitleRef}
                                        label="Nadpis"
                                        value={website.settings.carsPageTitle}
                                        onChange={(val) => handleChangeSettings('carsPageTitle', val)}
                                        required
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <InputText
                                        ref={carsSlugRef}
                                        label="Slug"
                                        value={website.settings.carsPageSlug}
                                        onChange={(val) => handleChangeSettings('carsPageSlug', val)}
                                        required
                                    />
                                </div>
                            </div>

                            <TinyMCEEditor
                                onChange={(val) => handleChangeSettings('carsPageDescription', val)}
                                value={website.settings.carsPageDescription}
                            />
                        </Tab>}

                    {website.type === WebsiteType.Cars &&
                        <Tab
                            eventKey="sellcar"
                            title="Výkup vozidel"
                            tabClassName="nav-item"
                        >
                            <div className="row">
                                <div className="col col-md-6">
                                    <div className="mb-3">
                                        <CheckBox
                                            checked={website.settings.sellCarShowPage}
                                            label="Zobrazovat stránku"
                                            onChange={(v) => handleChangeSettings('sellCarShowPage', v)}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <InputText
                                                ref={sellCarTitleRef}
                                                label="Nadpis"
                                                value={website.settings.sellCarPageTitle}
                                                onChange={(val) => handleChangeSettings('sellCarPageTitle', val)}
                                                required
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <InputText
                                                ref={sellCarSlugRef}
                                                label="Slug"
                                                value={website.settings.sellCarPageSlug}
                                                onChange={(val) => handleChangeSettings('sellCarPageSlug', val)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <TinyMCEEditor
                                        onChange={(val) => handleChangeSettings('sellCarPageDescription', val)}
                                        value={website.settings.sellCarPageDescription}
                                    />

                                </div>

                                <div className="col col-md-6">
                                    <WebsiteImageControl
                                        label="Obrázek (.png nebo .jpeg)"
                                        accept="image/png,image/jpeg"
                                        website={website}
                                        clontyKey="sellcarimage"
                                    />
                                </div>
                            </div>
                        </Tab>
                    }

                    {website.type === WebsiteType.Cars &&
                        <Tab
                            eventKey="financing"
                            title="Financování"
                            tabClassName="nav-item"
                        >
                            <div className="row">
                                <div className="col col-md-6">
                                    <div className="mb-3">
                                        <CheckBox
                                            checked={website.settings.financingShowPage}
                                            label="Zobrazovat stránku"
                                            onChange={(v) => handleChangeSettings('financingShowPage', v)}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <InputText
                                                ref={financingTitleRef}
                                                label="Nadpis"
                                                value={website.settings.financingPageTitle}
                                                onChange={(val) => handleChangeSettings('financingPageTitle', val)}
                                                required
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <InputText
                                                ref={financingSlugRef}
                                                label="Slug"
                                                value={website.settings.financingPageSlug}
                                                onChange={(val) => handleChangeSettings('financingPageSlug', val)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <TinyMCEEditor
                                        onChange={(val) => handleChangeSettings('financingPageDescription', val)}
                                        value={website.settings.financingPageDescription}
                                    />
                                </div>

                                <div className="col col-md-6">
                                    <WebsiteImageControl
                                        label="Obrázek (.png nebo .jpeg)"
                                        accept="image/png,image/jpeg"
                                        website={website}
                                        clontyKey="financingimage"
                                    />
                                </div>
                            </div>
                        </Tab>
                    }

                    <Tab
                        eventKey="aboutus"
                        title="O nás"
                        tabClassName="nav-item"
                    >
                        <div className="row">
                            <div className="col col-md-6">
                                <div className="mb-3">
                                    <CheckBox
                                        checked={website.settings.aboutUsShowPage}
                                        label="Zobrazovat stránku"
                                        onChange={(v) => handleChangeSettings('aboutUsShowPage', v)}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <InputText
                                            ref={aboutUsTitleRef}
                                            label="Nadpis"
                                            value={website.settings.aboutUsPageTitle}
                                            onChange={(val) => handleChangeSettings('aboutUsPageTitle', val)}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <InputText
                                            ref={aboutUsSlugRef}
                                            label="Slug"
                                            value={website.settings.aboutUsPageSlug}
                                            onChange={(val) => handleChangeSettings('aboutUsPageSlug', val)}
                                            required
                                        />
                                    </div>
                                </div>                    

                                <TinyMCEEditor
                                    onChange={(val) => handleChangeSettings('aboutUsPageDescription', val)}
                                    value={website.settings.aboutUsPageDescription}
                                />
                            </div>
                            <div className="col col-md-6">
                                <WebsiteImageControl
                                    label="Obrázek (.png nebo .jpeg)"
                                    accept="image/png,image/jpeg"
                                    website={website}
                                    clontyKey="aboutusimage"
                                />
                            </div>
                        </div>
                    </Tab>

                    <Tab
                        eventKey="contact"
                        title="Kontakt"
                        tabClassName="nav-item"
                    >
                        <TinyMCEEditor
                            onChange={(val) => handleChangeSettings('contactPageDescription', val)}
                            value={website.settings.contactPageDescription}
                        />
                    </Tab>

                    <Tab
                        eventKey="gdpr"
                        title="Zpracování osobních údajů"
                        tabClassName="nav-item"
                    >
                        <InputText
                            label="Nadpis"
                            value={website.settings.gdprPageTitle}
                            onChange={(val) => handleChangeSettings('gdprPageTitle', val)}
                            required
                        />

                        <TinyMCEEditor
                            onChange={(val) => handleChangeSettings('gdpr', val)}
                            value={website.settings.gdpr}
                            height={1000}
                            toolbar={'undo redo | formatselect | bold italic underline | ' +
                                'forecolor backcolor | fontsizeselect | fontsize | ' +
                                'bullist numlist outdent indent | removeformat | ' +
                                'h2 h3 h4 h5 h6'}
                        />
                    </Tab>

                    <Tab
                        eventKey="termsandconditions"
                        title="Obchodní podmínky"
                        tabClassName="nav-item"
                    >
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <InputText
                                    label="Nadpis"
                                    value={website.settings.termsAndConditionsPageTitle}
                                    onChange={(val) => handleChangeSettings('termsAndConditionsPageTitle', val)}
                                    required
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <InputText
                                    label="Slug"
                                    value={website.settings.termsAndConditionsPageSlug}
                                    onChange={(val) => handleChangeSettings('termsAndConditionsPageSlug', val)}
                                    required
                                />
                            </div>
                        </div>                    

                        <TinyMCEEditor
                            onChange={(val) => handleChangeSettings('termsAndConditions', val)}
                            value={website.settings.termsAndConditions}
                            height={1000}
                            toolbar={'undo redo | formatselect | bold italic underline | ' +
                                'forecolor backcolor | fontsizeselect | fontsize |' +
                                'bullist numlist outdent indent | removeformat | ' +
                                'h2 h3 h4 h5 h6'}
                        />
                    </Tab>
                </Tabs>
            </div>

            <hr />

            <div className="mt-3 p-3">
                <label className="section-title mt-4 mb-3">Texty & Obsah</label>
                <p>Přizpůsobte si web svými popisky a texty.</p>

                <div className="row mt-3">
                    <div className="col-lg-6">
                        <TinyMCEEditor
                            label="Krátký popis na hlavní stránku"
                            onChange={(val) => handleChangeSettings('homepageText', val)}
                            value={website.settings.homepageText}
                            maxLength={120}
                            height={200}
                        />
                    </div>

                    <div className="col-lg-6">
                        <TinyMCEEditor
                            label="Text v zápatí webu"
                            onChange={(val) => handleChangeSettings('footerText', val)}
                            value={website.settings.footerText}
                            maxLength={200}
                            height={200}
                        />
                    </div>

                    <div className="col-lg-6">
                        <TinyMCEEditor
                            label="V krátkosti o vaší společnosti"
                            onChange={(val) => handleChangeSettings('companyShortDescription', val)}
                            value={website.settings.companyShortDescription}
                            maxLength={150}
                            height={200}
                        />
                    </div>

                    <div className="col-lg-6">
                        <TinyMCEEditor
                            label="Naše nabídka - popisek"
                            onChange={(val) => handleChangeSettings('ourOffersDescription', val)}
                            value={website.settings.ourOffersDescription}
                            height={200}
                        />
                    </div>

                    <div className="col-lg-6">
                        <TinyMCEEditor
                            label="Hodnocení - popisek"
                            onChange={(val) => handleChangeSettings('reviewsDescription', val)}
                            value={website.settings.reviewsDescription}
                            height={200}
                        />
                    </div>
                </div>
            </div >
        </>
    )
}