import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import IFacebookCatalog from "../../Interfaces/IFacebookCatalog"
import FacebookCatalogsApiService from "../../Services/FacebookCatalogsApiService";
import InputText from "../UI/Input/InputText";
import SelectBox from "../UI/Input/SelectBox";

interface IProps {
    onHide(): void,
    facebookCatalog: IFacebookCatalog,
}

const facebookCatalogsApiService = new FacebookCatalogsApiService();

export const FacebookCatalogEditModal = (props: IProps) => {

    useEffect(() => {
        return () => {
            facebookCatalogsApiService.cancelRequests();
        }
    }, [])

    const [facebookCatalog, setFacebookCatalog] = useState(props.facebookCatalog);

    function handleSave() {
        facebookCatalogsApiService
            .edit(facebookCatalog.id, facebookCatalog)
            .then(data => {
                if (data.success) {
                    props.onHide();
                }
            })
    }

    return (
        <Modal centered={true} show={true} onHide={() => props.onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>Úprava katalogu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputText
                    label="Název"
                    value={facebookCatalog.name}
                    readonly
                />

                <SelectBox
                    label="Měna"
                    onChange={value => setFacebookCatalog({ ...facebookCatalog, currency: value })}
                    options={CurrencyHelper.getOptions()}
                    value={facebookCatalog.currency}
                />

                <button
                    className="btn btn-primary"
                    onClick={() => handleSave()}
                >
                    Uložit změny
                </button>
            </Modal.Body>
        </Modal>)
}