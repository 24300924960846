import CurrencyHelper from '../../../Helpers/CurrencyHelper';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import IUser from '../../../Interfaces/IUser';
import { OnlineServer } from '../../../Enums/OnlineServer';
import { AutoImporterSourceType } from '../../../Enums/AutoImporterSourceType';

interface IProps {
    user: IUser;
}

export const Header = (props: IProps) => {

    const [width, setWidth] = useState(0);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [userMenuShow, setUserMenuShow] = useState(false);

    const { user } = props;

    const handleUserOutsideClick = (event: MouseEvent) => {
        const clickedElementId = (event.target as HTMLElement).id;
        if (clickedElementId !== 'userMenuLink') {
            setUserMenuShow(false);
        }
    };

    const handleUserClick = () => {
        setUserMenuShow(!userMenuShow);
    };

    useEffect(() => {
        if (userMenuShow) {
            document.addEventListener('click', handleUserOutsideClick, true);
        }
        else {
            document.removeEventListener('click', handleUserOutsideClick, true);
        }

    }, [userMenuShow])

    useEffect(() => {
        if (showMobileNav) {
            document.addEventListener('click', handleNavOutsideClick, true);
        }
        else {
            document.removeEventListener('click', handleNavOutsideClick, true);
        }

    }, [showMobileNav])

    const handleNavClick = () => {
        setShowMobileNav(!showMobileNav);
    };


    const handleNavOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        const clickedElementClass = target.className;
        if (!clickedElementClass || ''.includes('mobileMenuLink')) {
            setShowMobileNav(false);
        }
        if ((target.tagName || '') === "A") {
            setShowMobileNav(false);
        }
    }


    const handleLogout = () => {
        axios.post('/user/logout').then((response) => {
            if (response.status === 200) {
                window.location.assign('/ucet/prihlaseni');
            }
        });
    };

    const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);


    const uploaderServers = user.uploaderServers;

    return (
        <header id="topnav">
            <div className="topbar-main">
                <div className="container-fluid">
                    {/* Logo container*/}
                    <div className="logo">
                        <Link to='/' className="logo clickable">
                            <h1 className="text-white">Inzerobot</h1>
                        </Link>
                    </div>
                    {/* End Logo container*/}
                    <div className="menu-extras topbar-custom">
                        <ul className="navbar-right list-inline float-right mb-0">
                            {user.creditSystemEnabled &&
                                <li className="dropdown notification-list list-inline-item">
                                    <Link className="nav-link dropdown-toggle arrow-none clickable" to='/ucet/transakce'>
                                        <span className={`badge badge-pill ${user.accountBalance < 0 ? 'badge-danger' : 'badge-success'}`}>{user.accountBalance} {CurrencyHelper.getSymbol(user.accountCurrency)}</span>
                                    </Link>
                                </li>}

                            <li className="dropdown notification-list list-inline-item">
                                <div className="dropdown notification-list  nav-pro-img mt-3">
                                    <div className="headerEmail">
                                        <a
                                            data-clarity-unmask="True"
                                            onClick={handleUserClick}
                                            className="text-white clickable mb-0 pb-0 lh-0"
                                            data-toggle="dropdown"
                                            role="button"
                                            aria-haspopup="false"
                                            aria-expanded="false"
                                            id="userMenuLink"
                                        >
                                            {user.email}
                                            <br />
                                        </a>
                                    </div>
                                    {/*   <small className="mt-0 float-right">(expiruje {new Date(this.props.user.dateExpiration).toLocaleDateString()})</small> */}
                                    <div className={`dropdown-menu dropdown-menu-right profile-dropdown mt-4 ${userMenuShow ? 'show' : ''}`}>
                                        {/*  <Link className="dropdown-item clickable" to='/nastaveni/prodejce'> <i className="fa fa-cog mr-2" />Nastavení</Link>*/}


                                        <Link className="dropdown-item clickable" to='/nastaveni'> <i className="fas fa-key mr-2" />Změna hesla</Link>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item text-danger clickable" onClick={handleLogout} > <i className="mdi mdi-power text-danger" /> Odhlásit se</a>
                                    </div>
                                </div>
                            </li>
                            <li className="menu-item list-inline-item">
                                {/* Mobile menu toggle*/}
                                <a className="navbar-toggle nav-link mobileMenuLink" onClick={handleNavClick}>
                                    <div className="lines mobileMenuLink">
                                        <span />
                                        <span />
                                        <span />
                                    </div>
                                </a>
                                {/* End mobile menu toggle*/}
                            </li>
                        </ul>
                    </div>
                    {/* end menu-extras */}
                    <div className="clearfix" />
                </div> {/* end container */}
            </div>
            {/* end topbar-main */}
            {/* MENU Start */}
            <div className="navbar-custom">
                <div className="container-fluid">
                    <div id="navigation" style={{ display: showMobileNav === false && width < 990 ? 'none' : 'block' }}>
                        {/* Navigation Menu*/}
                        <ul className="navigation-menu">
                            <li className="has-submenu">
                                <Link className="clickable" to='/'> <i className="fas fa-th-large"></i>Nástěnka</Link>
                            </li>

                            <li className="has-submenu">
                                <Link className="clickable" to='/nahravac/kolekce'> <i className="fas fa-archive" /> Inzerce</Link>

                                <ul className="submenu">

                                    <li>
                                        <Link className="clickable" to='/obchod/prehled'>Obchodní přehled</Link>
                                    </li>

                                    <li>
                                        <Link className="clickable" to='/obchod/kos'>Koš</Link>
                                    </li>
                                </ul>
                            </li>

                            {/* <li className="has-submenu">
                                <Link className="clickable" to='/obchod'> <i className="fas fa-store"></i> Obchod</Link>

                                <ul className="submenu">
                                    <li>
                                        <Link className="clickable" to='/obchod/prehled'>Obchodní přehled</Link>
                                    </li>

                                    <li>
                                        <Link className="clickable" to='/obchod/kos'>Koš</Link>
                                    </li>
                                </ul>
                            </li>*/}

                            <li className="has-submenu">

                                <Link className="clickable" to='/webove-stranky'><i className="fas fa-globe" />Weby</Link>

                                {/* 
                                <a> <i className="fas fa-info-circle" />Ostatní služby</a>

                                <ul className="submenu">
                                    <li>
                                        <Link className="clickable" to='/webove-stranky'>Prodejní weby</Link>
                                    </li>

                                    {/*    <li>
                                        <Link className="clickable" to='/webmail'>Webmail hosting</Link>
                                    </li>
                                </ul> */}
                            </li>

                            <li className="has-submenu">
                                <Link className="clickable" to='/integrations'> <i className="fas fa-puzzle-piece"></i>Integrace</Link>
                            </li>

                            <li className="has-submenu">
                                <a> <i className="fa fa-cog" />Nastavení</a>

                                <ul className="submenu">
                                    <li>
                                        <Link className="clickable" to='/nastaveni/aplikace'>Aplikace</Link>
                                    </li>

                                    <li>
                                        <Link className="clickable" to='/nastaveni/prodejce'>Moje firma</Link>
                                    </li>

                                    <li>
                                        <Link className="clickable" to='/fakturace'>Fakturace</Link>
                                    </li>

                                    <li>
                                        <Link className="clickable" to='/ips'>IP adresy</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="has-submenu">
                                <Link className="clickable" to='/faq'> <i className="fas fa-info-circle" />Nápověda</Link>

                                <ul className="submenu">
                                    {(uploaderServers.includes(OnlineServer.BazosCz) ||
                                        uploaderServers.includes(OnlineServer.BazosSk)) &&
                                        <li>
                                            <Link to='/faq/bazos'>Bazoš</Link>
                                        </li>}

                                    {uploaderServers.includes(OnlineServer.Sbazar) &&
                                        <li>
                                            <Link to='/faq/sbazar'>Sbazar</Link>
                                        </li>
                                    }
                                   
                                    <li>
                                        <Link to='/faq/prodejni-weby'>Prodejní weby</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="has-submenu">
                                <Link className="clickable" to='/kontakt'>  <i className="fas fa-headset"></i>Podpora</Link>
                            </li>
                        </ul>
                        {/* End navigation menu */}
                    </div> {/* end #navigation */}
                </div> {/* end container */}
            </div>
        </header>
    );
}