import React from "react";
import { Alert } from "react-bootstrap";
import StringHelper from "../../../Helpers/StringHelper";
import { IContentRules } from "../../../Interfaces/IContentRules";

interface IProps {
    text: string,
    rules: IContentRules
}

export const ContentRulesAlert = (props: IProps) => {

    const { text, rules } = props;

    let warnings: JSX.Element[] = [];

    if (rules.canContainsEmail === false && StringHelper.isContainsEmail(text)) {
        warnings.push(<li key="email">Obsah <b> nesmí</b > obsahovat emailové adresy</li>)
    }

    if (rules.canContainsPhoneNumber === false && StringHelper.isContainsPhoneNumber(text)) {
        warnings.push(<li key="phone">Obsah <b>nesmí</b> obsahovat tel. číslo</li>)
    }

    if (rules.canContainsUrl === false && StringHelper.isContainsUrl(text)) {
        warnings.push(<li key="url">Obsah <b>nesmí</b> obsahovat url odkazy</li>)
    }

    if (warnings.length > 0) {
        return (
            <Alert variant='warning' className="mt-4">
                <b>Nápověda:</b>
                <ul className="mt-3">
                    {warnings}
                </ul>
            </Alert>)
    }
    return null;
}