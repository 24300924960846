import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import IProduct from '../../../Interfaces/IProduct';
import SelectBox from '../../UI/Input/SelectBox';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import IOptionData from '../../../Interfaces/IOptionData';
import { filter } from 'lodash';
import { ValidationHelper } from '../../Utils/ValidationHelper';
import ProductApiService from '../../../Services/ProductApiService';
import IProductCollection from '../../../Interfaces/IProductCollection';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import { inject, observer } from 'mobx-react';

interface IProps {
    onHide(): void,
    product: IProduct,
    currentCollection: IProductCollection,
    productCollectionStore?: ProductCollectionStore
}

const productApiService = new ProductApiService();

export const MigrateProductToAnotherCollectionModal = inject('productCollectionStore')(observer((props: IProps) => {

    useEffect(() => {
        return () => {
            productApiService.cancelRequests();
        }
    }, [])

    const { onHide, product, currentCollection, productCollectionStore } = props;
    const [newCollectionId, setNewCollectionId] = useState<string>(null);
    const inputNewCollection = useRef<SelectBox>(null);

    const handleSubmit = () => {
        const inputs = [inputNewCollection];

        const isValid = ValidationHelper.validateInputs(inputs);
        if (isValid) {
            productApiService.changeCollection(product, newCollectionId)
                .then(response => {
                    if (response.success) {
                        onHide();
                    }
                })
        }
    }
    
    let collectionsOptions: IOptionData[] = [];
    filter(productCollectionStore.list, collection => {
        if (
            collection.id != currentCollection.id &&
            (collection.productsCount < 50 || collection.unlimited) &&
            collection.unlimited === currentCollection.unlimited) {
            return true;
        }
        return false;
    }).forEach(s => collectionsOptions.push({ label: s.name, value: s.id }));

    return (
        <CustomDocumentTitle title='Změna kolekce'>
            <Modal centered={true} show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Změna kolekce</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!currentCollection.unlimited && <p>
                        <span className="text-danger">
                            Inzerát "{product.name}" bude nejprve stažen ze všech inzertních serverů a následně přesunut pod vybranou kolekci.
                        </span>
                    </p>}

                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <SelectBox
                                ref={inputNewCollection}
                                label="Přesunout do kolekce"
                                required={true}
                                onChange={(val: string) => setNewCollectionId(val)}
                                defaultValue={"Vyberte"}
                                options={collectionsOptions}
                                value={newCollectionId}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide} variant="secondary" >
                        Zavřít
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                         Provést změnu kolekce
                    </Button>
                </Modal.Footer>
            </Modal>
        </CustomDocumentTitle >
    );
}))