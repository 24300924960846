import { useEffect, useRef, useState } from 'react';
import Textarea from '../UI/Input/Textarea';
import SelectBox from '../UI/Input/SelectBox';
import { ContactFormSubject } from '../../Enums/ContactFormSubject';
import ContactHelper from '../../Helpers/ContactHelper';
import { find } from 'lodash';
import { Link } from 'react-router-dom';
import InputEmail from '../UI/Input/InputEmail';
import CurrentUserStore from '../Stores/CurrentUserStore';
import { inject, observer } from 'mobx-react';
import { ValidationHelper } from '../Utils/ValidationHelper';
import ContactApiService from '../../Services/ContactApiService';
import LoadingScreen from '../Screens/LoadingScreen';
import IFormError from '../../Interfaces/IFormError';
import { FormErrorSummary } from '../Others/FormErrorSummary';
import InputText from '../UI/Input/InputText';

interface IProps {
    currentUserStore?: CurrentUserStore,
}

const contactApiService = new ContactApiService();

export const ContactForm = inject('currentUserStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;
    const inputType = useRef<SelectBox>(null);
    const inputSubject = useRef<InputText>(null);
    const inputMessage = useRef<Textarea>(null);
    const inputEmail = useRef<InputEmail>(null);

    const [formErrors, setFormErrors] = useState<IFormError[]>([]); 
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        message: '',
        subject: '',
        type: null,
        email: user.email,
    });

    useEffect(() => {
        return () => {
            contactApiService.cancelRequests();
        }
    })

    const handleChange = (key: string, value: any) => {
        setForm({
            ...form,
            [key]: value
        })
    }

    const handleSubmit = () => {
        const isValid = ValidationHelper.validateInputs(getInputs());
        setFormErrors([]);

        if (isValid) {
            const type = find(ContactHelper.getTypes(), { value: form.type });

            let subject = type.label;
            if (form.type === ContactFormSubject.Question ||
                form.type === ContactFormSubject.ReportError) {
                subject = subject + ": " + form.subject;
            }
            setLoading(true);

            contactApiService.send(subject, form.message, form.email)
                .then(data => {
                    // then clear
                    setForm({
                        message: '',
                        subject: '',
                        email: user.email,
                        type: null,
                    })
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const getInputs =() => {
        return [inputType, inputSubject, inputMessage, inputEmail]
    }

    if (loading) {
        return <LoadingScreen withCard={false} />
    }

    return (
        <>
            <SelectBox
                ref={inputType}
                label="Typ požadavku"
                options={ContactHelper.getTypes()}
                defaultValue="Vyberte"
                onChange={(value) => handleChange('type', value)}
                value={form.type}
                required
            />

            {(form.type === ContactFormSubject.Question ||
                form.type === ContactFormSubject.ReportError) && <>
                <InputText
                    ref={inputSubject}
                    label="Předmět"
                    value={form.subject}
                    onChange={(value) => handleChange('subject', value)}
                    required
                />
            </>}

            {form.type === ContactFormSubject.AddIP ?
                <div className="alert alert-info">
                    <b>Novou IP je možné objednat přímo z vašeho účtu.</b> <Link to="/ips">Pokračujte ZDE</Link>
                </div>
                : <>
                    <Textarea
                        ref={inputMessage}
                        required
                        label="Zpráva"
                        value={form.message}
                        onChange={(value) => handleChange('message', value)}
                        placeholder={ContactHelper.getMessagePlaceholder(form.type)}
                    />

                    <InputEmail
                        ref={inputEmail}
                        label="Email pro odpověď"
                        value={form.email}
                        required
                        onChange={(value) => handleChange('email', value)}
                    />

                    <FormErrorSummary
                        errors={formErrors}
                    />

                    <button
                        className="btn btn-primary btn-lg mt-2"
                        onClick={handleSubmit}
                    >
                        Odeslat zprávu
                    </button>
                </>
            }
        </>
    )
}))