import { inject, observer } from 'mobx-react';
import React from 'react';
import { ListType } from '../../../../Enums/ListType';
import IProduct from '../../../../Interfaces/IProduct';
import Product from '../../../../Models/Product';
import BaseComponent from '../../../BaseComponent';
import { LeavePageWarningPrompt } from '../../../Others/LeavePageWarningPrompt';
import ListsStore from '../../../Stores/ListsStore';
import SelectBox from '../../../UI/Input/SelectBox';
import SmartSelectBox from '../../../UI/Input/SmartSelectBox';
import { ValidationHelper } from '../../../Utils/ValidationHelper';

interface IState { }

interface IProps {
    onChange(key: string, value: any): void,
    product: IProduct,
    listsStore?: ListsStore,
    requiredFields: string[],
}

@inject('listsStore')
@observer
export default class ProductWizardAluminiumWheelsStep extends BaseComponent<IProps, IState> {

    private condition: React.RefObject<SelectBox>;
    private rimDiameter: React.RefObject<SmartSelectBox>;
    private pcd: React.RefObject<SmartSelectBox>;

    constructor(props: IProps) {
        super(props);

        this.condition = React.createRef();
        this.rimDiameter = React.createRef();
        this.pcd = React.createRef();
    }

    validate() : boolean {
        const inputs = [
            this.condition,
            this.rimDiameter,
            this.pcd,
        ];

        return ValidationHelper.validateInputs(inputs);
    }

    render() {
        const { product, listsStore, onChange, requiredFields } = this.props;

        return (
            <div>
                <div className="alert alert-secondary" role="alert">
                    Díky těmto údajům zařadíme váš inzerát do správné kategorie na inzertních serverech.
                </div>

                <LeavePageWarningPrompt when={true} />

                <div className="mx-2 my-3">
                    <div className="row lightForm">
                        <div className="col-12 col-lg-8">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4">
                                    <SelectBox
                                        ref={this.condition}
                                        label="Stav"
                                        onChange={(val) => onChange('condition', val)}
                                        options={Product.getConditionOptions()}
                                        required={requiredFields.includes("condition")}
                                        defaultValue="Vyberte"
                                        value={product.condition}
                                    />
                                </div>

                                <div className="col-12 col-sm-6 col-md-4">
                                    <SmartSelectBox
                                        ref={this.rimDiameter}
                                        label="Průměr"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.tireRimDiameter || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.TireRimDiameter)}
                                        uid="tireRimDiameter"
                                        required={requiredFields.includes("tireRimDiameter")}
                                    />
                                </div>

                                <div className="col-12 col-sm-6 col-md-4">
                                    <SmartSelectBox
                                        ref={this.pcd}
                                        label="Rozteč"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.pcd || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.Pcd)}
                                        uid="pcd"
                                        required={requiredFields.includes("pcd")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}