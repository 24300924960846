import BaseComponent from './../../BaseComponent';
import React from 'react';
import IProductCollection from '../../../Interfaces/IProductCollection';

interface IState {

}

interface IProps {
    collection: IProductCollection,
}

export default class BazosCzErrorAlert extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {

        }
    }

    render() {
        const collection = this.props.collection;

        if (collection.bazosCzError == false) {
            return null;
        }

        return (
            <div>
                {collection.bazosCzError &&
                    <div className="alert alert-warning text-center my-2 w-100" style={{
                        border: '1px solid #ced4da'
                    }}>
                        <i className="fas fa-exclamation-triangle text-warning mr-2"></i><b>Bazos.cz</b> Chyba: <i>{collection.bazosCzErrorMessage}</i>. <b>Přejděte do úpravy kolekce a upravte propojení s Bazoš.cz</b>.
                    </div>}
            </div>
        )
    }
}