import { PageTitleBox } from "../../Others/PageTitleBox"
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle"
import { WebsitesFAQ } from "../../Websites/WebsitesFAQ"

export const FaqWebsitePage = () => {
    return (
        <>
            <CustomDocumentTitle title="Nápověda - Prodejní weby">
                <PageTitleBox title="Nápověda - Prodejní weby" />

                <div className="card">
                    <div className="card-body">
                        <WebsitesFAQ />
                    </div>
                </div>
            </CustomDocumentTitle>
        </>)
}