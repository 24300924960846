import React from "react"

interface IProps {
    text?: string,
    children?: React.ReactChild,
}

export const NotSetupedAlert = (props: IProps) => {
    return <div className="alert alert-secondary" role="alert">
        {props.text && <span><i className="fas fa-times mr-2"></i>{props.text}</span>}{props.children || <span><i className="fas fa-times mr-2"></i>Export není nastaven. Nastavte ho v editaci inzerátu v sekci <b>Marketingové kanály</b></span>}
    </div>
}