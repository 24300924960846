import { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { useNavigate } from 'react-router-dom';
import { InfoAlert } from '../../Others/Alerts/InfoAlert';
import InputText from '../../UI/Input/InputText';
import CheckBox from '../../UI/Input/CheckBox';
import IProductCollection from '../../../Interfaces/IProductCollection';
import ProductCollectionApiService from '../../../Services/ProductCollectionsApiService';
import { ValidationHelper } from '../../Utils/ValidationHelper';
import { toast } from 'react-toastify';
import { PageTitleBox } from '../../Others/PageTitleBox';

interface IProps {
    currentUserStore?: CurrentUserStore,
    productCollectionStore?: ProductCollectionStore,
}

const productCollectionApiService = new ProductCollectionApiService();

export const CreateCollectionPage = inject('currentUserStore', 'productCollectionStore')(observer((props: IProps) => {

    const [collection, setCollection] = useState<IProductCollection>({
        id: '',
        name: '',
        userId: '',
        bazosCZ: false,
        bazosSK: false,
        bazosCzImageSize: 100,
        bazosCzPassword: '',
        bazosCzRenewMinIntervalDays: 0,
        bazosSkImageSize: 100,
        bazosSkRenewMinIntervalDays: 0,
        bazosSkPassword: '',
        bazosSkEmail: '',
        bazosSkName: '',
        bazosSkPhone: '',
        bazosSkZIP: '',
        bazosCzZIP: '',
        bazosCzName: '',
        bazosCzPhone: '',
        bazosCzEmail: '',
        bazosCzVerificationPhone: '',
        bazosSkVerificationPhone: '',
        sbazar: false,
        sbazarEmail: '',
        sbazarLocationName: '',
        sbazarLocationEntityId: 0,
        sbazarLocationEntityType: '',
        sbazarLoginEmail: '',
        sbazarLoginPassword: '',
        sbazarPhone: '',
        sbazarShowPhone: true,
        bazosCzRequiredSignIn: false,
        bazosSkRequiredSignIn: false,
        bazosCzAppIpAddressId: null,
        bazosSkAppIpAddressId: null,
        sbazarAppIpAddressId: null,
        sbazarValid: false,
        bazosSkValid: false,
        bazosCzValid: false,
        sbazarRenewMinIntervalDays: 0,
        bazosCzCurrentDateDescriptionPrefix: false,
        bazosSkCurrentDateDescriptionPrefix: false,
        sbazarCurrentDateDescriptionPrefix: false,
        unlimited: false,
    });

    useEffect(() => {
        return () => {
            productCollectionApiService.cancelRequests();
        }
    })

    const navigate = useNavigate();
    const inputName = useRef<InputText>(null);

    const handleSubmit = () => {
        const inputs = [inputName];
        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            productCollectionApiService.createCollection(collection)
                .then(data => {
                    if (data.success) {
                        toast.success("Kolekce vytvořena");
                        const coll = data.records.productCollections.items[0];
                        if (coll && coll.unlimited) {
                            navigate(`/nahravac/kolekce/${coll.id}/prehled`)
                        }
                        else {
                            navigate(`/nahravac/kolekce/${coll.id}/upravit`);
                        }
                    }
                })
        }
    }

    const handleChange = (key: any, value: any) => {
        setCollection({
            ...collection,
            [key]: value
        })
    }

    const user = props.currentUserStore.user;

    return (
        <CustomDocumentTitle title="Vytvořit kolekci">
            <PageTitleBox title="Vytvořit kolekci" />

            <div className="card col-lg-8 mb-0">
                <div className="card-body">
                    <InfoAlert text="Kolekce slouží k organizaci vašich inzerátů" />

                    <div className="col-lg-12 col-md-12">
                        <InputText
                            ref={inputName}
                            label="Název"
                            value={collection.name}
                            required={true}
                            placeholder="např. Moje první kolekce"
                            onChange={(val) => handleChange('name', val)}
                        />

                        <div className="mb-3">
                            <CheckBox
                                checked={collection.unlimited}
                                label="Neomezená kolekce (nepodporuje server Bazoš)"
                                onChange={(val) => handleChange('unlimited', val)}
                            />
                        </div>

                        <button
                            onClick={handleSubmit}
                            className="btn btn-primary"
                        >
                            Vytvořit kolekci
                        </button>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    )
}))