import { IFacebookCategory } from "../Interfaces/IFacebookCategory";
import BaseApiService from "./BaseApiService";

export default class FacebokApiService extends BaseApiService {

    public getCategories(search?: string): Promise<IFacebookCategory[]> {
        return this.getRequest('facebook/categories', { search })
            .then(response => {
                return response.data as unknown as IFacebookCategory[];
            })
    }
}