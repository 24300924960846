import IProductCollection from "../../Interfaces/IProductCollection"
import { OnlineServer } from "../../Enums/OnlineServer";
import { Link } from "react-router-dom";
import { ProductCollectionWarning } from "./ProductCollectionWarning";
import { OnlineServerHelper } from "../Utils/OnlineServerHelper";

interface IProps {
    collections: IProductCollection[]
}

export const ProductCollectionsTable = (props: IProps) => {

    const { collections } = props;

    return <>
        <div className="table-responsive">
            <table className="table table-striped mg-b-0">
                <thead>
                    <tr>
                        <th>Název</th>

                        <th>Kanály</th>

                        <th>Počet inzerátů</th>

                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {(collections).map((collection: IProductCollection) => {
                        return (
                            <tr key={collection.id} >
                                <td>
                                    {collection.name} <ProductCollectionWarning collection={collection} />
                                </td>

                                <td>
                                    {collection.bazosCZ ? OnlineServerHelper.getBadge(OnlineServer.BazosCz) : null}

                                    {collection.bazosSK ? OnlineServerHelper.getBadge(OnlineServer.BazosSk) : null}

                                    {collection.sbazar ? OnlineServerHelper.getBadge(OnlineServer.Sbazar) : null}
                                </td>

                                <td>
                                    {collection.productsCount} z {collection.unlimited ? "∞" : "50"}
                                </td>

                                <td className="text-center">
                                    {collection.delete ? 
                                        <div className="text-center">
                                            <Link to={`/nahravac/kolekce/${collection.id}/prehled`}>
                                                <b className="text-danger">Kolekce bude smazána</b>
                                            </Link>
                                        </div>
                                    
                                        : <div className="btn-group" role="group">
                                            <Link to={`/nahravac/kolekce/${collection.id}/prehled`}>
                                                <button className="btn btn-outline-primary">
                                                    Přejít do kolekce
                                                    <i className="fas fa-angle-right ml-2"></i>
                                                </button>
                                            </Link>
                                        </div>                 
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </>
}