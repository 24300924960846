import IProduct from '../../../../Interfaces/IProduct';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import SelectBox from '../../../UI/Input/SelectBox';
import IInputField from '../../../../Interfaces/IInputField';
import IOptionData from '../../../../Interfaces/IOptionData';
import { filter, find, isNil, join, last, uniq } from 'lodash';
import sbazarCategories from '../../../../sbazarcategories.json'
import { ServerSettingAccordion } from '../../../Products/ServerSettingsAccordion';
import { ProductType } from '../../../../Enums/ProductType';
import StringHelper from '../../../../Helpers/StringHelper';
import ISbazarCategory from '../../../../Interfaces/ISbazarCategory';
import { IValidationFormRef } from '../../../../Interfaces/IValidationFormRef';
import { ValidationHelper } from '../../../Utils/ValidationHelper';

interface IProps {
    product: IProduct,
    onChange(key: string, value: any): void,
}

export const ServerSbazarSettingsAccordion = forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const [categories, setCategories] = useState<ISbazarCategory[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [categoryCount, setCategoryCount] = useState<number>(1);
    const [isValidAndComplete, setIsValidAndComplete] = useState(false);

    const inputSbazarPrice = useRef<SelectBox>(null);
    const inputSbazarCategoryRefs = useRef<Array<React.RefObject<SelectBox>>>([]);

    const { product, onChange } = props;

    const checkIsValid = () => {
        const inputs = getInputs();

        const isValidAndComplete = ValidationHelper.isValid(inputs) &&
            product.sbazarCategoryId !== 0 &&
            !isNil(product.sbazarCategoryId);

        setIsValidAndComplete(isValidAndComplete);
    }

    useImperativeHandle(ref, () => ({
        getInputs
    }));

    const getInputs = () => {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            inputSbazarPrice,
            ...inputSbazarCategoryRefs.current
        )
        return inputs;
    }

    useEffect(() => {
        if ((!product.sbazarCategoryId || product.sbazarCategoryId === 0) && categoryCount > 0) {
            setIsValidAndComplete(false);
        }
        else {
            setup();
        }     
    }, [product.sbazarCategoryId])

    useEffect(() => {
        setup();
    }, [])

    const setup = () => {
        let categories = sbazarCategories as ISbazarCategory[];
        const product = props.product;

        if (product.type === ProductType.Car) {
            categories = filter(categories, cat => {
                if (cat.parentId || (!cat.parentId && cat.id === 1)) {
                    return true;
                }
                return false;
            });
        }
        else {
            categories = filter(categories, cat => {
                if (cat.id !== 1) {
                    return true;
                }
                return false;
            });
        }

        if (product && categories.length > 0 && product.sbazarCategoryId > 0) {
            //uprava kategorie. Tzn. potreba zpetne zjistit nadkategorie
            let categoryById: ISbazarCategory = find(categories, { id: product.sbazarCategoryId });
            let selCategories = [];
            selCategories.push(product.sbazarCategoryId);
            selCategories.push(categoryById.parentId);
            selCategories = uniq(selCategories);

            while (true) {
                let categoryById2: ISbazarCategory = find(categories, { id: last(selCategories) });

                if (categoryById2) {
                    if (categoryById2.parentId) {
                        if (selCategories.includes(categoryById2.parentId)) {
                            break;
                        }
                        selCategories.push(categoryById2.parentId);
                    }
                    else {
                        break;
                    }
                }
                else {
                    break;
                }
            }

            setSelectedCategories(selCategories.reverse());

            setCategoryCount(selCategories.length);
            setCategories(categories);
        }
        else {
            setCategories(categories);

            if (product.type === ProductType.Car) {
                setSelectedCategories([1]);
                setCategoryCount(2);
            }
        }
          checkIsValid();
    }

    const getCategoriesData = (): any => {
        let result: any = [];

        for (var i = 1; i <= categoryCount; i++) {
            let id = selectedCategories[i - 1]; //pokud tam nic neni, jeste jsem nevybral
            let categoryById = find(categories, { id: id });

            let valId = 0;
            let valParentId = 0;

            if (categoryById) {
                valId = categoryById.id;
                valParentId = categoryById.parentId;
            }

            if (!id && i > 1) { //pokud jsem jeste nevybral, co je predchozi
                let catPrev = find(categories, { id: selectedCategories[i - 2] });
                if (catPrev) {
                    valParentId = catPrev.id;
                }
            }

            result.push({ id: valId, parentId: valParentId });
        }
        return result;
    }

    const getCategoryOptions = (parentId: number): IOptionData[] => {
        let options: IOptionData[] = [];
        if (parentId) {
            filter(categories, { parentId: parentId }).forEach((category: ISbazarCategory) => {
                options.push({ label: category.name, value: category.id });
            });
        }
        else {
            filter(categories, { parentId: null }).forEach((category: ISbazarCategory) => {
                options.push({ label: category.name, value: category.id });
            });
        }
        return options;
    }

    const getFullCategoryText = () => {
        let result: string[] = [];

        var catDatas = getCategoriesData();

        catDatas.forEach((data: any) => {
            var option = find(getCategoryOptions(data.parentId), { value: data.id });

            if (option)
                result.push(option.label);
        })

        return join(result, " > ");
    }

    const categoryText = getFullCategoryText();

    let sbazarPriceOptions: IOptionData[] = [];

    if (product.priceCZK) {
        sbazarPriceOptions.push({ label: `${StringHelper.separateThousands(product.priceCZK)} Kč`, value: 1 })
    }
    else {
        sbazarPriceOptions.push({ label: `Pevná cena`, value: 1 })
    }
    sbazarPriceOptions.push({ label: "Dohodou", value: 2 })

    let sbazarPriceValue = 1;

    if (product.sbazarPriceByAgreement) {
        sbazarPriceValue = 2;
    }

    const getBoxes = () => {
        return getCategoriesData().map((data: any, index: number) => {
            const categoryRef = React.createRef<SelectBox>();
            inputSbazarCategoryRefs.current.push(categoryRef);

            return (
                <div className="col-12 col-sm-6 col-md-3" key={index}>
                    <SelectBox
                        ref={categoryRef}
                        label={`Kategorie ${index + 1}`}
                        options={getCategoryOptions(data.parentId)}
                        defaultValue={"Vyberte"}
                        required={product.sbazar}
                        value={data.id}
                        onChange={(val) => handleChangeCategory(index, val)}
                    />
                </div>
            );
        });
    };

    const boxes = getBoxes();

    const handleChangePrice = (value: number) => {
        if (value === 2) {
            onChange('sbazarPriceByAgreement', true)
        }
        else {
            onChange('sbazarPriceByAgreement', false)
        }
    }

    const handleChangeCategory = (index: number, value: number) => {
        if (!value) {
            onChange('sbazarCategoryId', null);
            setSelectedCategories([]);
            setCategoryCount(1);
            return;
        }

        let selectedCategoriesM = [...selectedCategories];
        var childCategory = filter(categories, { parentId: value });

        let categoryCountM = categoryCount;

        if (childCategory.length > 0 && selectedCategoriesM[index] == null) {
            categoryCountM++;
        }
        else if (childCategory.length === 0) {
            onChange('sbazarCategoryId', value);
        }
        else {
            onChange('sbazarCategoryId', null);
        }

        if (selectedCategoriesM[index] != null) {
            selectedCategoriesM = selectedCategoriesM.splice(0, index);

            if (childCategory.length > 0) {
                categoryCountM = index + 2;
            }
            else {
                categoryCountM = index + 1;
            }
        }
        selectedCategoriesM[index] = value;

        setSelectedCategories(selectedCategoriesM);
        setCategoryCount(categoryCountM);
        checkIsValid();
    }

    return (
        <ServerSettingAccordion categoryText={categoryText} headingText="Sbazar.cz" isValid={isValidAndComplete}>
            <div className="row" >
                <div className="col-12 col-sm-6 col-md-3">
                    <SelectBox
                        ref={inputSbazarPrice}
                        label="Cena"
                        options={sbazarPriceOptions}
                        defaultValue={"Vyberte"}
                        required={product.sbazar}
                        value={sbazarPriceValue}
                        onChange={(val) => handleChangePrice(val)}
                    />
                </div>
                {boxes}
            </div>
        </ServerSettingAccordion>)
})