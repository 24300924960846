import { SectionTitle } from "../../Others/SectionTitle"
import { Accordion } from "../../UI/Elements/Accordion"

export const HowToVerifySbazarContactAccordion = () => {

    return <Accordion cardClassName="mt-4 mb-2" headingText={"Jak ověřit tel. číslo/email"}>
        <p>V případě, že jste si založili nový Sbazar účet nebo máte nové tel. číslo či email, postupujte následovně:</p>

        <ol>
            <li>Na <a target="_blank" href="https://sbazar.cz">Sbazar.cz</a> se přihlaste</li>
            <li>Klikněte na Přidat novou nabídku nebo přejděte přímo na <a target="_blank" href="https://www.sbazar.cz/admin/novy-inzerat">https://www.sbazar.cz/admin/novy-inzerat</a></li>
            <li>Vyplňte sekci <b>Jak Vás zájemce může kontaktovat?</b> a následně dle instrukcí proveďdte ověření</li>
            <li>Připojte Sbazar v kolekcích v Inzerobotovi</li>
        </ol>

        <SectionTitle title="Videonávod" />

        <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/IyvzUyB8jsI?si=YXGskfc2v1V3iwKg"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen>
        </iframe>
    </Accordion>
}