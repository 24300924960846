import { useEffect, useState } from "react";
import { IWebsite } from "../../Interfaces/IWebsite";
import WebsitesApiService from "../../Services/WebsitesApiService";
import { toast } from "react-toastify";
import { find } from "lodash";
import { InputFile } from "../UI/Input/InputFile";

interface IProps {
    clontyKey: string,
    website: IWebsite,
    label: string,
    accept: string,
}

const websitesApiService = new WebsitesApiService();

export const WebsiteImageControl = (props: IProps) => {

    const { website, clontyKey, label, accept } = props;

    const [version, setV] = useState<number>(1);
    const im = find(website.images, { key: clontyKey });
    const [show, setShow] = useState(!!im);

    useEffect(() => {
        return () => {
            websitesApiService.cancelRequests();
        }
    }, [])

    const onUpload = (files: FileList) => {
        websitesApiService.uploadImages(website.id, clontyKey, files, true)
            .then(data => {
                if (data.success) {
                    var v = version + 1;
                    setV(v);
                    setShow(true);
                    toast.info("Úspěšně nahráno");
                }
            })
    }

    const onDelete = () => {
        websitesApiService.deleteImage(website.id, clontyKey)
            .then(data => {
                if (data.success) {
                    setShow(false);
                }
            })
    }

    return <>
        <InputFile
            label={label}
            onChange={(files) => onUpload(files)}
            accept={accept}
        />

        {show && <>
            <img
                className="img-fluid mt-2"
                src={`/api/websites/${website.id}/images/${clontyKey}?v=${version}`}
            />
            <br />
            <button
                onClick={onDelete}
                className="btn btn-outline-danger mt-2"
            >
                Smazat
            </button>
        </>}
    </>
}