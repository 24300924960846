import { BenefitItem } from "../Others/BenefitItem"

export const WebsiteBenefitsScreen = () => {
    return (
        <div className="card mb-1">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <BenefitItem
                            title="Lidé vás najdou - SEO"
                            description="Web jsme optimalizovali pro vyhledávače, aby vás lidé našli. Zároveň váš web propojíme přímo s Googlem skrz Google Search Console."
                        />

                        <BenefitItem
                            title="Neustále aktuální nabídka"
                            description="Web nemusíte nijak spravovat. Několikrát denně se web synchronizuje s vaší nabídkou v Inzerobotovi."
                        />

                        <BenefitItem
                            title="Rychlý & Bezpečný"
                            description="Weby jsme postavili na nejnovějších technologiích, které zaručují bezpečnost, stabilitu a rychlost."
                        />     

                        <BenefitItem
                            title={`WhatsApp integrace`}
                            description="S integrovaným tlačítkem od WhatsApp vás zákazníci kontaktují jednodušeji aniž by museli cokoli vypisovat"
                            icon="bi bi-whatsapp"
                        />        
                    </div>

                    <div className="col-12 col-md-6">
                        <BenefitItem
                            title="Kontaktní formulář & GDPR ready"
                            description="Každý web obsahuje kontaktní stránku, kde návštěvníci naleznou kontaktní údaje, provozovnu s mapou a kontaktní formulář. Včetně souhlasů dle GDPR."
                        />

                        <BenefitItem
                            title="Výkupní formulář"
                            description="Vykupujete auta? Máme pro vás formulář na web, zájemce vám může poslat svou nabídku."
                        />

                        <BenefitItem
                            title="Zaměřen na UX"
                            description="Naším cílem bylo web udělat jednoduchý, přehledný a intuitivní. S důrazem na co nejvyšší konverzní poměr."
                        />

                        <BenefitItem
                            title={`Google hodnocení`}
                            description="Automaticky zobrazíme vaše Google recenze na webu"
                            icon="bi bi-star-fill"
                        />    
                    </div>
                </div>
            </div>
        </div>)
}