import { filter, find, isNil } from 'lodash';
import * as React from 'react';
import IFormError from '../../../Interfaces/IFormError';
import IInputField from '../../../Interfaces/IInputField';
import IOptionData from '../../../Interfaces/IOptionData';
import BaseComponent from '../../BaseComponent';
import Select, { OptionsOrGroups, GroupBase } from 'react-select';

interface IProps {
    uid: string,
    required?: boolean;
    label: string;
    options: OptionsOrGroups<IOptionData, GroupBase<IOptionData>>;
    value: any,
    onChange(selectedId: string | null, key: string): void,
    serverErrors?: IFormError[],
    placeholder?: string,
    isClearable?: boolean,
    isLoading?: boolean,
    className?: string,
    onInputChange?(value: string): void,
}

interface IState {
    errors: string[],
}

export default class SmartSelectBox extends BaseComponent<IProps, IState> implements IInputField {

    private input: React.RefObject<any>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }


    getErrors(value = this.props.value || '') {
        let errors: string[] = [];

        /*  if (this.props.validate) {
              this.props.validate(
                  this.props.uid, value || '',
                  errors,
                  () => this.validateInput(value, errors),
              );
              if (errors.length !== 0) {
                  return errors;
              }
          }
          */
        this.validateInput(value, errors);
        return errors;
    }

    isValid() {
        return this.getErrors().length === 0;
    }

    validate(value = this.props.value || '') {
        this.setState({
            errors: this.getErrors(value),
        })
    }

    validateInput(value: any, errors: string[]) {
        if (this.props.required === false && (this.props.value || '').length === 0) {
            return;
        }

        const serverErrors = filter(this.props.serverErrors, error => {
            if (error.key.toLowerCase() === this.props.uid.toLowerCase()) {
                return true;
            }
            return false;
        })

        serverErrors.map((error) => (errors.push(error.message)));

        if (this.props.required && ((value || '').length === 0 ||  value === null)) {
            errors.push("Toto pole je povinné");
        }
    }

    focus() {
        if (this.input.current) {
            this.input.current.focus();
        }
    }

    handleChange(value: IOptionData | GroupBase<IOptionData>) {
        if (value) {
            this.props.onChange((value as any).value, this.props.uid);
        }
        else {
            this.props.onChange(null, this.props.uid);
        }
    }

    handleOnBlur() {
        this.validate();
    }

    handleOnInputChanged(v: string) {
        if (this.props.onInputChange) {
            this.props.onInputChange(v);
        }
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        const { options, value, placeholder, isClearable } = this.props;
        const currVal = find(options, (item: IOptionData): IOptionData => {
            var x = item.value.toString() === (value || '').toString();
            if (x) {
                return item;
            }
        });

        return (
            <>
                <Select
                    key={`selected-${value}`}
                    ref={this.input}
                    isSearchable={true}
                    options={options}
                    isMulti={false}
                    value={(currVal as IOptionData)}
                    onChange={(value) => this.handleChange(value)}
                    placeholder={placeholder || 'Vyberte'}
                    isClearable={!isNil(isClearable) ? isClearable : true}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    noOptionsMessage={() => "Žádné možnosti"}
                    className={`smartSelectBox ${this.props.className}`}
                    classNamePrefix="smartSelectBox"
                    isLoading={this.props.isLoading}
                    onInputChange={(v) => this.handleOnInputChanged(v)}
                 //   onBlur={this.handleOnBlur.bind(this)}
                  //  onInputChange={this.handleOnBlur.bind(this)}      
                  
                />
            </>
        );
    }

    renderLabel() {
        if ((this.props.label || '').length === 0) {
            return null;
        }

        return (
            <label className="form-control-label">
                <span dangerouslySetInnerHTML={{ __html: this.props.label }}></span> {this.props.required && this.props.label.length !== 0 ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="mb-3">
                <div className={`form-group position-relative mb-0`}>
                    {this.renderLabel()}
                    {this.renderInput()}
                </div>
                {this.renderError()}
            </div>
        );

    }
}