import React from "react"

interface IProps {
    children?: React.ReactNode
}

export const Pricing = (props: IProps) => {

    return (
        <>
            {props.children}
            <p>Fakturační období je 6 měsíců. Ceny jsou uvedeny za měsíc.</p>
            <p><small>Prodejcem je Adam Gajdečka, IČ 05366542 se sídlem Patrice Lumumby 2334/28, 700 30, Ostrava - Zábřeh. Neplátce DPH.</small></p>
        </>)
}