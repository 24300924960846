import React from 'react';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';

export const EmailRemarketingPage = () => {
    return (
        <CustomDocumentTitle title="Email Remarketing">
            <div>
                <div className="page-title-box">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <h4 className="page-title">Email Remarketing</h4>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    )

}