import IUser from '../../Interfaces/IUser';
import { RocketTakeoff } from 'react-bootstrap-icons';
import { SeparateThousands } from '../Others/SeparateThousands';
import CurrencyHelper from '../../Helpers/CurrencyHelper';

interface IProps {
    user: IUser,
}

export const BusinessOverviewWidgets = (props: IProps) => {

    const { user } = props;
    const currencySymbol = CurrencyHelper.getSymbol(user.accountCurrency);

    return (
        <div id="main-wrapper">
            <div className="row no-gutters">
                <div className="col-lg-3 col-sm-6 col-md-6 pr-1">
                    <div className="card" style={{ marginBottom: '5px' }}>
                        <div className="card-body">
                            <div className="float-left">
                                <span className="widget-title">
                                    <SeparateThousands number={user.inventoryMarketValue} />&nbsp;{currencySymbol}
                                </span>
                                <p className="subtitle">Tržní hodnota skladu</p>
                            </div>
                            <div className="float-right">
                                <i className="bi bi-shop widget-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-md-6 pr-1">
                    <div className="card" style={{ marginBottom: '5px' }}>
                        <div className="card-body">
                            <div className="float-left">
                                <span className="widget-title">
                                    <SeparateThousands number={user.inventoryPurchaseValue} />&nbsp;{currencySymbol}
                                </span>
                                <p className="subtitle">Pořizovací hodnota skladu</p>
                            </div>
                            <div className="float-right">
                                <i className="bi bi-cash widget-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-md-6 pr-1">
                    <div className="card" style={{ marginBottom: '5px' }}>
                        <div className="card-body">
                            <div className="float-left">
                                <span className="widget-title">
                                    <SeparateThousands number={user.estimatedMargin} />&nbsp;{currencySymbol}
                                </span>
                                <p style={{ color: "#868ba1" }} className="subtitle">Očekávaná marže</p>
                            </div>
                            <div className="float-right">
                                <i className="bi bi-cash-stack widget-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-md-6 pr-1">
                    <div className="card" style={{ marginBottom: '5px' }}>
                        <div className="card-body">
                            <div className="float-left">
                                <span className={`widget-title`}>
                                    <SeparateThousands number={user.turnoverThisYear} />&nbsp;{currencySymbol}
                                </span>
                                <p className="subtitle">Letošní obrat</p>
                            </div>
                            <div className="float-right">
                                <RocketTakeoff className="widget-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}