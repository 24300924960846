import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import IInputField from '../../../Interfaces/IInputField';
import { IServerAccount } from '../../../Interfaces/IServerAccount';
import InputEmail from '../../UI/Input/InputEmail';
import InputText from '../../UI/Input/InputText';
import SbazarApiService from '../../../Services/SbazarApiService';
import { ValidationHelper } from '../../Utils/ValidationHelper';
import InputTelephone from '../../UI/Input/InputTelephone';
import SbazarAccountsApiService from '../../../Services/SbazarAccountsApiService';
import { toast } from 'react-toastify';
import InputPassword from '../../UI/Input/InputPassword';
import { ISbazarLoginRequest } from '../../../Interfaces/Requests/SbazarAccounts/ISbazarLoginRequest';
import { HowToVerifySbazarContactAccordion } from './HowToVerifySbazarContactAccordion';

interface IProps {
    onHide(): void,
    accountServer: IServerAccount,
}

const sbazarApiService = new SbazarApiService();
const sbazarAccountsApiService = new SbazarAccountsApiService();

export const SbazarAccountLoginModal = (props: IProps) => {

    const { accountServer } = props;

    const [form, setForm] = useState<ISbazarLoginRequest>({
        contactEmail: accountServer.contactEmail,
        contactPhone: accountServer.contactPhone,
        email: accountServer.userName,
        password:'',
    });

    const inputEmail = useRef<InputEmail>(null);
    const inputPass = useRef<InputPassword>(null);

    const inputContactEmail = useRef<InputEmail>(null);
    const inputContactPhone = useRef<InputTelephone>(null);

    useEffect(() => {
        return () => {
            sbazarApiService.cancelRequests();
            sbazarAccountsApiService.cancelRequests();
        }
    }, [])

    const validate = () => {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            inputEmail,
            inputPass,
            inputContactEmail,
            inputContactPhone,
        )
        return ValidationHelper.validateInputs(inputs);
    }

    const onChange = (key: any, value: any) => {
        setForm({
            ...form,
            [key]: value
        })
    }

    const handleSubmitForm = () => {
        if (validate()) {
            sbazarAccountsApiService.login(accountServer.id, form)
                .then(data => {
                    if (data.success) {
                        toast.success("Změny uloženy");
                        const acc = data.records.serverAccounts.items[0];
                        props.onHide();
                    }
                    else {
                        toast.error("Změny nebyly uloženy");
                    }
                })
        }
    }

    return (
        <Modal size="lg" centered={true} show={true} onHide={() => { }}>
            <Modal.Header>
                <Modal.Title>Propojení Sbazar účtu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="alert alert-warning mt-2 m-0 w-100">
                        <p>
                            Budeme se na váš účet přihlašovat za účelem správy inzerátů. <b>Účet nesmí mít zapnuté dvoufázové ověření.</b>
                        </p>
                    </div>


                    <label className="section-title mt-2 mb-4">PŘIHLAŠOVACÍ ÚDAJE</label>
                    <p>Pro nově založené účty nejprve postupujte dle <a target="_blank" href="/faq/sbazar">"Jak připojit nový účet"</a></p>
                    <div className="border p-2 mt-3 bg-light">
                        <InputEmail
                            ref={inputEmail}
                            label="Přihlašovací jméno (email) do sbazaru"
                            value={form.email}
                            required={true}
                            placeholder=""
                            onChange={(val) => onChange('email', val)}
                        />

                        <InputText
                            ref={inputPass}
                            label="Heslo k účtu (do sbazaru)"
                            value={form.password}
                            required={true}
                            onChange={(val) => onChange('password', val)}
                        />
                    </div>

                  
                    <hr />

                    <div className="alert alert-warning mt-2 m-0 w-100">
                        <h6 className="alert-heading">Máte email nebo tel. číslo, které jste dosud nepoužili na Sbazaru?</h6>
                        <hr />
                        <p>Pokračujte dle návodu níže <b>Jak ověřit tel. číslo/email</b></p>
                    </div>
                    <HowToVerifySbazarContactAccordion />

                    <div className="row mt-4">
                        <div className="col">
                            <InputEmail
                                ref={inputContactEmail}
                                label="Kontaktní email"
                                value={form.contactEmail}
                                required={true}
                                placeholder=""
                                onChange={(val) => onChange('contactEmail', val)}
                            />
                        </div>
                        <div className="col">
                            <InputTelephone
                                ref={inputContactPhone}
                                label="Kontaktní tel. číslo"
                                value={form.contactPhone}
                                required={true}
                                onChange={(val) => onChange('contactPhone', val)}
                            />
                        </div>
                    </div>

                    <div className="alert alert-secondary mt-4">
                        <i className="fas fa-info-circle mr-2"></i>Nastavení neovlivní již zveřejněné inzeráty
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="secondary" size="lg">
                    Zavřít
                </Button>
                <Button variant="primary" size="lg" onClick={handleSubmitForm}>
                    Uložit změny
                </Button>
            </Modal.Footer>
        </Modal>
    )
}