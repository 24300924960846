import React from 'react';
import { last } from 'lodash';
import BaseComponent from '../../BaseComponent';
import Product from '../../../Models/Product';
import IWizardStep from '../../../Interfaces/IWizardStep';
import IProduct from '../../../Interfaces/IProduct';

interface IState {

}

interface IProps {
    goToStep: Function,
    goToLastStep: Function,
    goToNextStep: Function
    currentStep: IWizardStep,
    enableGoToBack: boolean,
    product: IProduct,
    children?: React.ReactNode,
}

export default class ProductWizard extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    handleGotoStep(step: IWizardStep) {
        if (this.props.enableGoToBack) {
            if (step.orderNumber < this.props.currentStep.orderNumber) {
                this.props.goToStep(step);
            }
            else if (step.orderNumber === 1) {
                this.props.goToStep(step);
            }
        }
    }

    renderNavigation(steps: IWizardStep[]) {
        const currentStep = this.props.currentStep;
        const lastStep = Product.getLastStep(steps);

        if (currentStep.orderNumber != lastStep.orderNumber) {
            return (
                <div className="wizardStepNavigation mt-3 pb-5">
                    {currentStep.orderNumber > 1 &&
                        <button
                            onClick={this.props.goToLastStep.bind(this)}
                            disabled={this.props.currentStep.orderNumber == 1}
                            type="button"
                            className="btn btn-default float-left"
                        ><i className="fas fa-chevron-left mr-2"></i>Zpět
                        </button>}

                    <button
                        onClick={this.props.goToNextStep.bind(this)}
                        type="button"
                        className="btn btn-primary float-right"
                    >Přejít na další krok<i className="fas fa-chevron-right ml-2"></i>
                        </button>
                </div>
            )
        }
        return null;
    }

    render() {
        const currentStep = this.props.currentStep;
        const product = this.props.product;

        const steps = Product.getSteps(product);
        const lastStep = Product.getLastStep(steps);

        return (
            <div>
                <div className="row">
                    {steps.map((step: IWizardStep, index: number) => {
                        return (
                            <div key={index} className={`col p-0 ${step.orderNumber === 1 ? 'pr-0 pl-3' : ''} ${step === lastStep ? 'pr-3' : ''}`}>
                                <div
                                    onClick={this.handleGotoStep.bind(this, step)}
                                    className={`${(this.props.enableGoToBack && currentStep.orderNumber > step.orderNumber) ? 'clickable' : ''} wizardTopNav py-3 ${currentStep.orderNumber == step.orderNumber ? 'active' : (currentStep.orderNumber > step.orderNumber ? 'completed' : 'inactive')}`}>{step.orderNumber}. {step.title}</div>
                            </div>)
                    })}
                </div>

                <div className="card pb-3">
                    <div className="card-body">
                        <div className="customWizard mx-2">
                            <div className="wizardBody">

                                {this.props.children}

                                {/*currentStep.orderNumber < last(steps).orderNumber ? <small className="tx-danger py-2">* vyžadováno</small> : ""*/}

                                {this.renderNavigation(steps)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}