export enum VehicleType {
    Car = 1,
    Commercial = 2,
    QuadBike = 3,
    Motorcycle = 4,
    Truck = 5,
    SpareParts = 6,
    Other = 7,
    Caravan = 8,
    Trailers = 9,
    Bus = 10,
    WorkingMachines = 11,
}