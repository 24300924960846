import { inject, observer } from "mobx-react";
import { OnlineServer } from "../../../Enums/OnlineServer";
import IProduct from "../../../Interfaces/IProduct"
import IProductCollection from "../../../Interfaces/IProductCollection";
import IUser from "../../../Interfaces/IUser";
import FacebookCatalogStore from "../../Stores/FacebookCatalogStore";
import ServerAccountStore from "../../Stores/ServerAccountStore";
import SelectBox from "../../UI/Input/SelectBox";
import SelectCheckBox from "../../UI/Input/SelectCheckBox";
import { ServerAccountHelper } from "../../Utils/ServerAccountHelper";
import WebsiteStore from "../../Stores/WebsiteStore";
import IOptionData from "../../../Interfaces/IOptionData";
import FacebookCatalogHelper from "../../../Helpers/FacebookCatalogHelper";
import WebsiteHelper from "../../../Helpers/WebsiteHelper";
import { SectionTitle } from "../../Others/SectionTitle";
import React, { useRef } from "react";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import IInputField from "../../../Interfaces/IInputField";
import { ProductType } from "../../../Enums/ProductType";

interface IProps {
    product: IProduct,
    user: IUser,
    onChange(key: string, value: any): void,
    collection: IProductCollection,
    serverAccountStore?: ServerAccountStore,
    facebookCatalogStore?: FacebookCatalogStore,
    websiteStore?: WebsiteStore,
    requiredFields: string[],
}

export const SelectMarketingChannelsCard = inject('serverAccountStore', 'facebookCatalogStore', 'websiteStore')(observer
    (React.forwardRef<IValidationFormRef, IProps>((props, ref) => {
        const {
            product,
            user,
            onChange,
            collection,
            facebookCatalogStore,
            serverAccountStore,
            websiteStore
        } = props;

        const fbCatalogRef = useRef<SelectBox>(null);
        const fbMarketplaceRef = useRef<SelectBox>(null);
        const sbazarV2Ref = useRef<SelectBox>(null);
        const websiteRef = useRef<SelectBox>(null);
        const bazosCzRef = useRef<SelectCheckBox>(null);
        const bazosSkRef = useRef<SelectCheckBox>(null);
        const sbazarRef = useRef<SelectCheckBox>(null);
        const autobazarEuRef = useRef<SelectCheckBox>(null);
        const googleMerchantRef = useRef<SelectCheckBox>(null);

        const catalogs = facebookCatalogStore.getByUserId(collection.userId);
        const websites = websiteStore.getByUserId(collection.userId);

        const catalogOptions: IOptionData[] = FacebookCatalogHelper.getOptions(catalogs);
        const websiteOptions = WebsiteHelper.getOptions(websites);
        const facebookAccounts = serverAccountStore.getByUserIdAndServer(user.id, OnlineServer.FacebookMarketplace);
        const sbazarAccounts = serverAccountStore.getByUserIdAndServer(user.id, OnlineServer.Sbazar);

        React.useImperativeHandle(ref, () => ({
            getInputs,
        }));

        const getInputs = (): React.MutableRefObject<IInputField>[] => {
            return [
                fbCatalogRef,
                fbMarketplaceRef,
                sbazarV2Ref,
                websiteRef,
                bazosCzRef,
                bazosSkRef,
                sbazarRef,
                autobazarEuRef,
                googleMerchantRef
            ]
        }

        return <>
            <div className="card mb-3">
                <div className="card-header">
                    <SectionTitle title="Marketingové kanály" className="my-0" />
                </div>
                <div className="card-body">
                    <div className="row">
                        {user.uploaderServers.includes(OnlineServer.FacebookShop) &&
                            <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                                <SelectBox
                                    ref={fbCatalogRef}
                                    label="FB Katalog"
                                    value={product.facebookCatalogId}
                                    onChange={(v) => onChange('facebookCatalogId', v)}
                                    options={catalogOptions}
                                    defaultValue={"Nevybráno"}
                                />
                            </div>}

                        {user.uploaderServers.includes(OnlineServer.FacebookMarketplace) &&
                            <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                                <SelectBox
                                    ref={fbMarketplaceRef}
                                    label="FB Marketplace"
                                    value={product.facebookAccountId}
                                    onChange={(val) => onChange('facebookAccountId', val)}
                                    options={ServerAccountHelper.getOptions(facebookAccounts)}
                                    defaultValue={"Nevybráno"}
                                />
                            </div>}

                        {user.uploaderServers.includes(OnlineServer.Sbazar) && collection.unlimited &&
                            <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                                <SelectBox
                                    ref={sbazarV2Ref}
                                    label="Sbazar"
                                    value={product.sbazarAccountId}
                                    onChange={(val) => onChange('sbazarAccountId', val)}
                                    options={ServerAccountHelper.getOptions(sbazarAccounts)}
                                    defaultValue={"Nevybráno"}
                                />
                            </div>}

                        <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                            <SelectBox
                                ref={websiteRef}
                                label="Web"
                                value={product.websiteId}
                                onChange={(val) => onChange('websiteId', val)}
                                options={websiteOptions}
                                defaultValue={"Nevybráno"}
                            />
                        </div>

                        {collection.bazosCZ ? <div className="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                            <SelectCheckBox
                                ref={bazosCzRef}
                                value={product.bazosCZ}
                                label="Bazoš.cz"
                                onChange={(val) => onChange('bazosCZ', val)}
                                labelFalse="Ne"
                                labelTrue="Ano"
                                defaultValue="Vyberte"
                                allowNull={false}
                            />
                        </div> : null}

                        {collection.bazosSK ? <div className="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                            <SelectCheckBox
                                ref={bazosSkRef}
                                value={product.bazosSK}
                                label="Bazoš.sk"
                                onChange={(val) => onChange('bazosSK', val)}
                                labelFalse="Ne"
                                labelTrue="Ano"
                                defaultValue="Vyberte"
                                allowNull={false}
                            />
                        </div> : null}

                        {collection.sbazar && !collection.unlimited ? <div className="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                            <SelectCheckBox
                                ref={sbazarRef}
                                value={product.sbazar}
                                label="Sbazar.cz"
                                onChange={(val) => onChange('sbazar', val)}
                                labelFalse="Ne"
                                labelTrue="Ano"
                                defaultValue="Vyberte"
                                allowNull={false}
                            />
                        </div> : null}

                        {user.uploaderServers.includes(OnlineServer.AutobazarEu) && product.type === ProductType.Car &&
                            <div className="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                                <SelectCheckBox
                                    ref={autobazarEuRef}
                                    value={product.autobazarEu}
                                    label="Autobazar.eu"
                                    onChange={(val) => onChange('autobazarEu', val)}
                                    labelFalse="Ne"
                                    labelTrue="Ano"
                                    defaultValue="Vyberte"
                                    allowNull={false}
                                />
                            </div>}

                        {user.uploaderServers.includes(OnlineServer.GoogleMerchant) &&
                            <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                                <SelectCheckBox
                                    ref={googleMerchantRef}
                                    value={product.googleMerchant}
                                    label="Google Merchant"
                                    onChange={(val) => onChange('googleMerchant', val)}
                                    labelFalse="Ne"
                                    labelTrue="Ano"
                                    defaultValue="Vyberte"
                                    allowNull={false}
                                />
                            </div>}
                    </div>
                </div>
            </div>
        </>
    })))