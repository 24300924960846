import { Alert } from "react-bootstrap"
import { AutoImporterState } from "../../../Enums/AutoImporterState"
import IAutoImporter from "../../../Interfaces/IAutoImporter"
import { AutoImporterErrorType } from "../../../Enums/AutoImporterErrorType"
import AutoimportersApiService from "../../../Services/AutoimportersApiService"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { round } from "lodash"
import { AutoImporterProgressBar } from "./AutoImporterProgressBar"

interface IProps {
    autoImporter: IAutoImporter
}

const autoImporterApiService = new AutoimportersApiService();

export const AutoImporterStateBox = (props: IProps) => {

    useEffect(() => {
        return (() => {
            autoImporterApiService.cancelRequests();
        })
    }, [])

    const autoImporter = props.autoImporter;

    const cancelImport = () => {
        autoImporterApiService.cancelAutoImporter(autoImporter)
            .then(data => {
                if (!data.success) {
                    toast.error("Nepodařilo se import zrušit");
                }
            })
    }

    const pauseImport = () => {
        autoImporterApiService.pauseAutoImporter(autoImporter)
            .then(data => {
                if (!data.success) {
                    toast.error("Nepodařilo se import pozastavit");
                }
            })
    }

    const runImport = () => {
        autoImporterApiService.runImport(autoImporter.id)
            .then(data => {
                if (!data.success) {
                    toast.error("Nepodařilo se import spustit");
                }
            })
    }

    if (!autoImporter) {
        return <></>
    }
    else if (autoImporter.state === AutoImporterState.Queue) {
        return (
            <>
                <Alert variant="warning">
                    <Alert.Heading>Import je ve frontě</Alert.Heading>
                    <p>
                        Import je zařazen ve frontě ke zpracování. Není nutné podnikat další akce.
                    </p>
                </Alert>
            </>)
    }
    else if (autoImporter.state === AutoImporterState.Running && autoImporter.pause) {
        return (
            <>
                <Alert variant="secondary">
                    <Alert.Heading>Probíhá pozastavení</Alert.Heading>

                    <AutoImporterProgressBar
                        autoImporter={autoImporter}
                    />

                    <p>
                        Import bude pozastaven.
                    </p>
                </Alert>
            </>)
    }
    else if (autoImporter.state === AutoImporterState.Running) {
        return (
            <>
                <Alert variant="success">
                    <Alert.Heading>Import právě probíhá</Alert.Heading>

                    <AutoImporterProgressBar
                        autoImporter={autoImporter}
                    />

                    <p>
                        Import probíhá. Můžete zavřít tuto stránku. Pro zobrazení aktuálního stavu, načtěte stránku znovu. Import může trvat i velmi dlouho v závislosti na počtu položek.
                    </p>

                    <div className="btn-group" role="group" aria-label="Import buttons">
                        <button
                            className="btn btn-danger"
                            onClick={() => cancelImport()}
                        >
                            Zrušit import
                        </button>

                        <button
                            className="btn btn-secondary ml-2"
                            onClick={() => pauseImport()}
                        >
                            Pozastavit import
                        </button>
                    </div>
                </Alert>
            </>)
    }
    else if (autoImporter.state === AutoImporterState.Cancelling) {
        return (
            <>
                <Alert variant="danger">
                    <Alert.Heading>Probíhá rušení</Alert.Heading>
                    <AutoImporterProgressBar
                        autoImporter={autoImporter}
                    />
                    <p>
                        Import právě probíhá, ale probíhá rušení importu.
                    </p>
                </Alert>
            </>)
    }
    else if (autoImporter.pause) {
        return (
            <>
                <Alert variant="secondary">
                    <Alert.Heading>Import pozastaven</Alert.Heading>
                    <AutoImporterProgressBar
                        autoImporter={autoImporter}
                    />
                    <p>
                        Import byl pozastaven.
                    </p>

                    <div className="btn-group" role="group" aria-label="Import buttons">
                        <button
                            className="btn btn-danger"
                            onClick={() => cancelImport()}
                        >
                            Zrušit import
                        </button>

                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => runImport()}
                        >
                            Spustit import
                        </button>
                    </div>
                </Alert>
            </>)
    }
    else if (autoImporter.error) {

        if (autoImporter.errorType === AutoImporterErrorType.Exception) {
            return (
                <>
                    <Alert variant="danger">
                        <Alert.Heading>Probíhá údržba</Alert.Heading>
                        <p>
                            Import v tuto chvíli nelze spustit. Zkuste se vrátit za pár hodin. Import bude zkontrolován technikem, který byl již informován. Není nutné podnikat další akce.
                        </p>
                    </Alert>
                </>)
        }
        else if (autoImporter.errorType === AutoImporterErrorType.InvalidSetup) {
            return (
                <>
                    <Alert variant="danger">
                        <Alert.Heading>Nastavení importéru je chybné</Alert.Heading>
                        <p>
                            Zkontrolujte nastavení importéru. V tuto chvíli nelze import spustit z důvodu chybného nastavení.
                        </p>
                    </Alert>
                </>)
        }
        else if (autoImporter.errorType === AutoImporterErrorType.Unauthorized) {
            return (
                <>
                    <Alert variant="warning">
                        <Alert.Heading>Účet k importu není přihlášen</Alert.Heading>
                        <p>
                            Zkontrolujte připojený účet a znovu jej přihlaste, aby bylo možné z něj importovat.
                        </p>
                    </Alert>
                </>)
        }
        else if (autoImporter.errorType === AutoImporterErrorType.InvalidInput) {
            return (
                <>
                    <Alert variant="danger">
                        <Alert.Heading>Importér obdržel chybné data.</Alert.Heading>
                        <p>
                            Chyba: {autoImporter.errorMessage}. Opravte vstupní data a spusťte import.
                        </p>
                    </Alert>
                </>)
        }
    }
    return null;
}