import React from 'react';
import IProduct from '../../../../../Interfaces/IProduct';
import IProductCollection from '../../../../../Interfaces/IProductCollection';
import { filter, find, first, isNil, round, sortBy, } from 'lodash';
import SelectBox from '../../../../UI/Input/SelectBox';
import IInputField from '../../../../../Interfaces/IInputField';
import Product from '../../../../../Models/Product';
import IOptionData from '../../../../../Interfaces/IOptionData';
import IGlobalSettings from '../../../../../Interfaces/IGlobalSettings';
import FacebookCatalogStore from '../../../../Stores/FacebookCatalogStore';
import { inject, observer } from 'mobx-react';
import FacebookCatalogHelper from '../../../../../Helpers/FacebookCatalogHelper';
import SelectCheckBox from '../../../../UI/Input/SelectCheckBox';
import InputUrl from '../../../../UI/Input/InputUrl';
import WebsiteHelper from '../../../../../Helpers/WebsiteHelper';
import WebsiteStore from '../../../../Stores/WebsiteStore';
import BaseComponent from '../../../../BaseComponent';
import CurrentUserStore from '../../../../Stores/CurrentUserStore';
import ProductContentEditor from '../../../../Products/ContentEditor/ProductContentEditor';
import ServerAccountStore from '../../../../Stores/ServerAccountStore';
import { OnlineServer } from '../../../../../Enums/OnlineServer';
import { ServerAccountHelper } from '../../../../Utils/ServerAccountHelper';
import { Currency } from '../../../../../Enums/Currency';
import { LeavePageWarningPrompt } from '../../../../Others/LeavePageWarningPrompt';
import { VehicleType } from '../../../../../Enums/VehicleType';
import SmartSelectBox from '../../../../UI/Input/SmartSelectBox';
import ListsStore from '../../../../Stores/ListsStore';
import { ListType } from '../../../../../Enums/ListType';
import { SectionTitle } from '../../../../Others/SectionTitle';
import { CountryHelper } from '../../../../../Helpers/CountryHelper';
import InputText from '../../../../UI/Input/InputText';
import IFormError from '../../../../../Interfaces/IFormError';
import { FormErrorSummary } from '../../../../Others/FormErrorSummary';
import InputPrice from '../../../../UI/Input/InputPrice';
import InputNumber from '../../../../UI/Input/InputNumber';
import { ServerAccountState } from '../../../../../Enums/ServerAccountState';
import { ProductType } from '../../../../../Enums/ProductType';
import GlobalSettingsStore from '../../../../Stores/GlobalSettingsStore';
import { MathHelper } from '../../../../Utils/MathHelper';
import { WarningAlert } from '../../../../Others/Alerts/WarningAlert';
import CurrencyHelper from '../../../../../Helpers/CurrencyHelper';
import { ProductHelper } from '../../../../../Helpers/ProductHelper';

interface IState {
    formErrors: IFormError[],
}

interface IProps {
    onChange(key: string, value: any): void,
    product: IProduct,
    collection: IProductCollection,
    sectionOptions: IOptionData[],
    globalSettings: IGlobalSettings,
    onChangeProduct(product: IProduct): void,
    facebookCatalogStore?: FacebookCatalogStore,
    websiteStore?: WebsiteStore,
    currentUserStore?: CurrentUserStore,
    createNew: boolean,
    serverAccountStore?: ServerAccountStore,
    listsStore?: ListsStore,
    globalSettingsStore?: GlobalSettingsStore,
}

@inject(
    'facebookCatalogStore',
    'websiteStore',
    'currentUserStore',
    'serverAccountStore',
    'listsStore',
    'globalSettingsStore')
@observer
export default class ProductWizardStep1 extends BaseComponent<IProps, IState> {
    //step1
    private vehicleType: React.RefObject<SelectBox>;
    private inputSection: React.RefObject<SelectBox>;
    private inputPriceKc: React.RefObject<InputPrice>;
    private inputPriceEur: React.RefObject<InputPrice>;
    private inputPricePLN: React.RefObject<InputPrice>;
    private inputExternalUrl: React.RefObject<InputUrl>;
    // private inputRealEstateType: React.RefObject<SelectBox>;
    private inputCountry: React.RefObject<SelectBox>;
    private inputZip: React.RefObject<InputText>;
    private inputVatRate: React.RefObject<InputNumber>;

    private isExternalUrlRequired: boolean = true;

    private contentEditor: React.RefObject<ProductContentEditor>;

    constructor(props: IProps) {
        super(props);

        this.vehicleType = React.createRef();
        this.inputSection = React.createRef();

        this.inputPriceEur = React.createRef();
        this.inputPriceKc = React.createRef();
        this.inputPricePLN = React.createRef();

        this.inputExternalUrl = React.createRef();

        this.contentEditor = React.createRef();
        this.inputCountry = React.createRef();
        this.inputZip = React.createRef();
        //    this.inputRealEstateType = React.createRef();
        this.inputVatRate = React.createRef();

        this.state = {
            formErrors: [],
        }
    }

    componentDidMount() {
        const websites = this.props.websiteStore.list;
        const catalogs = this.props.facebookCatalogStore.list;
        const user = this.props.currentUserStore.user;
        const product = this.props.product;
        const serverAccounts = this.props.serverAccountStore.list;
        const collection = this.props.collection;

        if (this.props.createNew) {
            var web = first(websites);
            if (web && isNil(product.websiteId)) {
                product.websiteId = web.id;
            }

            var catalog = first(catalogs);
            if (catalog && isNil(product.facebookCatalogId) && !product.facebookCatalogEnabled) {
                product.facebookCatalogId = catalog.id;
                product.facebookCatalogEnabled = true;
            }

            const sbazarAcc = find(filter(serverAccounts, { id: collection.sbazarServerAccountId }));

            if (sbazarAcc && sbazarAcc.state === ServerAccountState.Enabled && collection.unlimited) {
                product.sbazarAccountId = collection.sbazarServerAccountId;
                product.sbazar = true;
            }

            this.props.onChangeProduct(product);
        }
    }

    async validateAsync(): Promise<boolean> {
        var basicDataResponse = await this.productApiService.validateBasic(this.props.product);
        const formErrors = basicDataResponse.formErrors;

        this.setState({
            formErrors: formErrors
        })

        const inputs: React.RefObject<IInputField>[] = [];
        const product = this.props.product;

        if (product.type === ProductType.Others) {
            inputs.push(this.inputSection);
        }
        else if (product.type === ProductType.Car) {
            inputs.push(this.vehicleType);
        }
        inputs.push(this.inputExternalUrl);

        if (this.props.product.section === 20) {
            //    inputs.push(this.inputRealEstateType);
        }

        const isContentValid = await this.contentEditor.current.isContentValidAsync();

        inputs.push(
            this.inputPriceKc,
            this.inputPriceEur,
            this.inputPricePLN,
            this.inputVatRate,
            this.inputCountry,
            this.inputZip,
        )

        const isValid = this.validateInputs(inputs);

        if (!isValid || !isContentValid || formErrors.length > 0) {
            return false;
        }
        return true;
    }

    handleConvertCurrency() {
        const product = this.props.product;
        const user = this.props.currentUserStore.user;

        let prices = [
            { amount: product.priceCZK, currency: Currency.Czk },
            { amount: product.priceEUR, currency: Currency.Eur, }
        ];

        let foreignPrices = filter(prices, p => p.currency !== user.accountCurrency);
        foreignPrices = filter(foreignPrices, p => !p.amount);

        foreignPrices.forEach(p => {
            let newPrice = CurrencyHelper
                .convertCurrency(this.props.globalSettingsStore.settings.currencyConversions,
                    user.accountCurrency,
                    p.currency,
                    Product.getPriceByCurrency(user.accountCurrency, product)
                )

            if (user.allowSmartPriceRound) {
                newPrice = ProductHelper.roundPriceWithTolerance(newPrice, user.priceExchangeRateDiffPercentageWarning);
            }
            Product.onChangePriceByCurrency(p.currency, newPrice, this.props.onChange);
        });
    }

    convertFromBasePrice() {
        const user = this.props.currentUserStore.user;
        const conversions = this.props.globalSettingsStore.settings.currencyConversions;
        const { product } = this.props;

        ProductHelper.convertFromBasePrice(user, conversions, product, this.props.onChange);
    }

    render() {
        const { product, collection } = this.props;
        const catalogs = this.props.facebookCatalogStore.getByUserId(collection.userId);
        const websites = this.props.websiteStore.getByUserId(collection.userId);

        const catalogOptions: IOptionData[] = FacebookCatalogHelper.getOptions(catalogs);
        const websiteOptions = WebsiteHelper.getOptions(websites);

        const selectedWebsite = find(websites, { id: product.websiteId });
        const user = this.props.currentUserStore.user;
        const facebookAccounts = this.props.serverAccountStore.getByUserIdAndServer(user.id, OnlineServer.FacebookMarketplace);
        const sbazarAccounts = this.props.serverAccountStore.getByUserIdAndServer(user.id, OnlineServer.Sbazar);

        let isExternalUrlReadonly = false;
        this.isExternalUrlRequired = product.facebookCatalogEnabled;
        if (selectedWebsite && selectedWebsite.isCustomDomain) {
            this.isExternalUrlRequired = false;
            isExternalUrlReadonly = true;
        }

        const subcategories = this.props.listsStore.getOptionsBy(ListType.Subcategories);
        const currencyConversions = this.props.globalSettings.currencyConversions;
        const basePrice = ProductHelper.getPriceByCurrency(product, user.accountCurrency);
        const convertedPriceToBase: number = CurrencyHelper.convertForeignPriceToUserBase(user, product, currencyConversions);
        const pricePercentDiff = round(MathHelper
            .calculatePercentageDifference(basePrice, convertedPriceToBase), 1);

        let prices = [
            { amount: product.priceCZK, currency: Currency.Czk },
            { amount: product.priceEUR, currency: Currency.Eur, }
        ];

        // Sorting the prices array by the currency property, with user.accountCurrency coming first
        prices = sortBy(prices, [(obj) => obj.currency !== user.accountCurrency, 'currency']);

        const foreignPrices = filter(prices, p => p.currency !== user.accountCurrency);
        const isLeastOneForeignCurrencyIsNotFilled = filter(foreignPrices, p => !p.amount).length > 0;

        return (
            <div>
                <label className="section-title pb-4">Marketingové kanály</label>

                <LeavePageWarningPrompt when={true} />

                <div className="row">
                    <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                        <SelectBox
                            label="FB Katalog"
                            value={product.facebookCatalogId}
                            onChange={this.props.onChange.bind(this, 'facebookCatalogId')}
                            options={catalogOptions}
                            defaultValue={"Nevybráno"}
                        />
                    </div>

                    {user.uploaderServers.includes(OnlineServer.FacebookMarketplace) &&
                        <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                            <SelectBox
                                label="FB Marketplace"
                                value={product.facebookAccountId}
                                onChange={this.props.onChange.bind(this, 'facebookAccountId')}
                                options={ServerAccountHelper.getOptions(facebookAccounts)}
                                defaultValue={"Nevybráno"}
                            />
                        </div>}

                    {user.uploaderServers.includes(OnlineServer.Sbazar) && collection.unlimited &&
                        <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                            <SelectBox
                                label="Sbazar"
                                value={product.sbazarAccountId}
                                onChange={this.props.onChange.bind(this, 'sbazarAccountId')}
                                options={ServerAccountHelper.getOptions(sbazarAccounts)}
                                defaultValue={"Nevybráno"}
                            />
                        </div>}

                    <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                        <SelectBox
                            label="Web"
                            value={product.websiteId}
                            onChange={this.props.onChange.bind(this, 'websiteId')}
                            options={websiteOptions}
                            defaultValue={"Nevybráno"}
                        />
                    </div>

                    {collection.bazosCZ ? <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                        <SelectCheckBox
                            value={product.bazosCZ}
                            label="Bazoš.cz"
                            onChange={this.props.onChange.bind(this, 'bazosCZ')}
                            labelFalse="Ne"
                            labelTrue="Ano"
                            defaultValue="Vyberte"
                            allowNull={false}
                        />
                    </div> : null}

                    {collection.bazosSK ? <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                        <SelectCheckBox
                            value={product.bazosSK}
                            label="Bazoš.sk"
                            onChange={this.props.onChange.bind(this, 'bazosSK')}
                            labelFalse="Ne"
                            labelTrue="Ano"
                            defaultValue="Vyberte"
                            allowNull={false}
                        />
                    </div> : null}

                    {collection.sbazar && !collection.unlimited ? <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                        <SelectCheckBox
                            value={product.sbazar}
                            label="Sbazar.cz"
                            onChange={this.props.onChange.bind(this, 'sbazar')}
                            labelFalse="Ne"
                            labelTrue="Ano"
                            defaultValue="Vyberte"
                            allowNull={false}
                        />
                    </div> : null}

                    <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                        <SelectCheckBox
                            value={product.autobazarEu}
                            label="Autobazar.eu"
                            onChange={this.props.onChange.bind(this, 'autobazarEu')}
                            labelFalse="Ne"
                            labelTrue="Ano"
                            defaultValue="Vyberte"
                            allowNull={false}
                        />
                    </div>

                    {user.uploaderServers.includes(OnlineServer.GoogleMerchant) &&
                        <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                            <SelectCheckBox
                                value={product.googleMerchant}
                                label="Google Merchant"
                                onChange={this.props.onChange.bind(this, 'googleMerchant')}
                                labelFalse="Ne"
                                labelTrue="Ano"
                                defaultValue="Vyberte"
                                allowNull={false}
                            />
                        </div>}
                </div>

                <hr />

                <div className="row">
                    <div className="col-lg-6 col-lg-12">
                        <label className="section-title pb-3">Základní údaje</label>
                        
                        <div className="row">
                            {product.type === ProductType.Others &&
                                <>  <div className="col-md-6 col-lg-3">
                                    <SelectBox
                                        ref={this.inputSection}
                                        label="Sekce"
                                        options={this.props.sectionOptions}
                                        defaultValue={"Vyberte"}
                                        required={product.autoImporterId === null}
                                        value={product.section}
                                        onChange={this.props.onChange.bind(this, 'section')}
                                        disabled={false}
                                    />
                                </div> </>}

                            {product.type === ProductType.Car &&
                                <div className="col-md-6 col-lg-3">
                                    <SelectBox
                                        ref={this.vehicleType}
                                        defaultValue="- nevybráno -"
                                        label="Kategorie"
                                        onChange={this.props.onChange.bind(this, 'vehicleType')}
                                        value={product.vehicleType}
                                        options={Product.getVehicleTypeOptions()}
                                        required
                                    />
                                </div>}

                            {product.vehicleType === VehicleType.SpareParts &&
                                <div className="col-md-6 col-lg-3">
                                    <SmartSelectBox
                                        label="Podkategorie"
                                        onChange={(value, key) => this.props.onChange(key, parseInt(value))}
                                        value={(product.subcategory || '').toString()}
                                        options={subcategories}
                                        uid="subcategory"
                                        placeholder="Nevybráno / jiná"
                                    />
                                </div>
                            }

                            <div className="col-md-6">
                                <InputUrl
                                    ref={this.inputExternalUrl}
                                    label="Externí url (na vašem webu)"
                                    value={product.externalProductUrl || ''}
                                    onChange={this.props.onChange.bind(this, 'externalProductUrl')}
                                    required={this.isExternalUrlRequired}
                                    placeholder={isExternalUrlReadonly ? "Automaticky" : "https://vas-web.cz/produkt-xy"}
                                    readonly={isExternalUrlReadonly}
                                />
                            </div>



                            {/* reality 
                            {product.section === 20 &&
                                <div className="col-12 col-md-6">
                                    <SelectBox
                                        ref={this.inputRealEstateType}
                                        label="Typ"
                                        value={product.realEstateType}
                                        options={ProductHelper.getRealEstateTypeOptions()}
                                        required
                                        defaultValue="Vyberte"
                                        onChange={(val) => this.props.onChange('realEstateType', val)}
                                    />
                                </div>}*/}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-lg-12 col-xl-12">
                        <ProductContentEditor
                            ref={this.contentEditor}
                            product={product}
                            onChange={(value, key) => this.props.onChange(key, value)}
                            globalSettings={this.props.globalSettings}
                            collection={collection}
                            user={user}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <SectionTitle title="Cena" />

                        <hr />
                        <div className="row">
                            <div className="col-sm-4 col-md-4">
                                <SelectCheckBox
                                    value={product.vatDeduction}
                                    label="Odpočet DPH"
                                    onChange={this.props.onChange.bind(this, 'vatDeduction')}
                                    labelFalse="Ne"
                                    labelTrue="Ano"
                                    defaultValue="Neuvedeno"
                                    allowNull={true}
                                />
                            </div>

                            {prices.map(p => {
                                if (p.currency === Currency.Czk) {
                                    return <div key={p.currency} className="col-sm-4 col-md-4">
                                        <InputPrice
                                            ref={this.inputPriceKc}
                                            label={`Prodejní cena ${product.vatDeduction ? 'vč. DPH ' : ''}`}
                                            value={product.priceCZK}
                                            required={product.bazosCZ || product.sbazar || user.accountCurrency === Currency.Czk}
                                            placeholder=""
                                            onChange={this.props.onChange.bind(this, 'priceCZK')}
                                            minValue={1}
                                            readonly={Product.isPendingCheck(product, collection) && isNil(product.priceCZK) === false}
                                            onBlur={() => {
                                                if (p.currency === user.accountCurrency) {
                                                    this.handleConvertCurrency()
                                                }
                                            }}
                                            currency={Currency.Czk}
                                        />
                                    </div>
                                }
                                else if (p.currency === Currency.Eur) {
                                    return <div key={p.currency} className="col-sm-4 col-md-4">
                                        <InputPrice
                                            ref={this.inputPriceEur}
                                            label={`Prodejní cena ${product.vatDeduction ? 'vč. DPH ' : ''}`}
                                            currency={Currency.Eur}
                                            value={product.priceEUR}
                                            required={product.bazosSK || user.accountCurrency === Currency.Eur}
                                            placeholder=""
                                            onChange={this.props.onChange.bind(this, 'priceEUR')}
                                            minValue={1}
                                            readonly={Product.isPendingCheck(product, collection) && isNil(product.priceEUR) === false}
                                            onBlur={() => {
                                                if (p.currency === user.accountCurrency) {
                                                    this.handleConvertCurrency()
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                return <></>
                            })}


                            {product.vatDeduction && <>
                                <div className="col-sm-4 col-md-4">
                                    <InputNumber
                                        ref={this.inputVatRate}
                                        label="Sazba DPH"
                                        value={product.vatRate}
                                        onChange={(val) => this.props.onChange('vatRate', val)}
                                        suffix="%"
                                        required
                                    />
                                </div>
                            </>}

                            {/*   <div className="col-sm-4 col-md-4">
                                <InputNumber
                                    ref={this.inputPricePLN}
                                    label="Cena zł"
                                    maxLength={60}
                                    value={product.pricePLN}
                                    placeholder=""
                                    onChange={this.props.onChange.bind(this, 'pricePLN')}
                                    minValue={1}
                                    onBlur={() => this.handleConvertCurrency()}
                                />
                            </div>*/}
                        </div>

                        {foreignPrices.map(p => {
                            let newPrice = CurrencyHelper
                                .convertCurrency(currencyConversions,
                                    user.accountCurrency,
                                    p.currency,
                                    Product.getPriceByCurrency(user.accountCurrency, product)
                                )

                            if (user.allowSmartPriceRound) {
                                newPrice = ProductHelper.roundPriceWithTolerance(newPrice, user.priceExchangeRateDiffPercentageWarning);
                            }

                            if (Math.abs(pricePercentDiff) > user.priceExchangeRateDiffPercentageWarning &&
                                p.currency !== user.accountCurrency &&
                                p.amount !== newPrice) {

                                return <WarningAlert key={p.currency}>
                                    <>
                                        <span style={{ lineHeight: '36px' }}>
                                            <i className="fas fa-exclamation-triangle text-warning mr-2"></i>
                                            {`\u00A0`}Cena {CurrencyHelper.getSymbol(p.currency)}{`\u00A0`}
                                            je {pricePercentDiff > 0 ? "nižší" : "vyšší"}
                                            {`\u00A0`}o <b className="mt-2">{Math.abs(pricePercentDiff)} %</b>
                                        </span>

                                        <button
                                            className="btn btn-outline-primary d-none d-sm-block float-sm-right mt-0"
                                            onClick={() => this.convertFromBasePrice()}
                                        >
                                            Přepočítat cenu
                                        </button>
                                        {`\u00A0`}
                                        <a
                                            className="clickable d-sm-none"
                                            style={{ color: 'rgb(0 82 255)', textDecoration: 'underline' }}
                                            onClick={() => this.convertFromBasePrice()}
                                        >
                                            Přepočítat cenu
                                        </a>
                                    </>
                                </WarningAlert>
                            }
                            else {
                                return null;
                            }
                        })}

                        {!isNaN(basePrice) && !isNil(basePrice) && basePrice !== 0 &&
                            isLeastOneForeignCurrencyIsNotFilled && <>
                                <div className="text-center">
                                    <input
                                        onClick={this.handleConvertCurrency.bind(this)}
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        value="Dopočítat ostatní"
                                    />
                                </div>
                            </>}
                    </div>

                    <div className="col-12 col-md-6">
                        <SectionTitle title="Místo prodeje" />
                        <hr />
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <SelectBox
                                    ref={this.inputCountry}
                                    label="Stát"
                                    options={CountryHelper.getCountryOptionsLocationSale()}
                                    onChange={(val) => this.props.onChange('country', val)}
                                    value={product.country}
                                    defaultValue="Vyberte"
                                    required={(product.zip || '').length > 0}
                                />
                            </div>

                            <div className="col-12 col-sm-6">
                                <InputText
                                    ref={this.inputZip}
                                    label="PSČ"
                                    onChange={(val) => this.props.onChange('zip', val)}
                                    value={product.zip}
                                    allowOnlyDigits={true}
                                    length={5}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <FormErrorSummary
                    errors={this.state.formErrors}
                />

                {Product.isPendingCheck(product, collection) && <>
                    <div className="alert alert-info text-center my-2 w-100">
                        Některé pole nelze upravit, jelikož inzerát čeká na kontrolu.
                    </div>
                </>}
            </div>
        )
    }
}