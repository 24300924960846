import { round } from "lodash";
import { Currency } from "../Enums/Currency";
import { ICurrencyConversions } from "../Interfaces/ICurrencyConversions";
import IOptionData from "../Interfaces/IOptionData";
import IProduct from "../Interfaces/IProduct";
import IUser from "../Interfaces/IUser";

export default class CurrencyHelper {

    public static getOptions(): IOptionData[] {

        let options: IOptionData[] = [];
        options.push({ label: this.getLabel(Currency.Czk), value: Currency.Czk });
        options.push({ label: this.getLabel(Currency.Eur), value: Currency.Eur });
        return options;
    }

    public static getLabel(currency: Currency): string {
        switch (currency) {

            case Currency.Czk:
                return 'CZK';

            case Currency.Eur:
                return 'EUR';
        }
    }

    public static getSymbol(currency: Currency): string {
        switch (currency) {

            case Currency.Czk:
                return 'Kč';

            case Currency.Eur:
                return '€';
        }
    }

    public static convertCurrency(conversions: ICurrencyConversions, from: Currency, to: Currency, amount: number): number {
        if (from === Currency.Eur && to === Currency.Czk) {
            return round(amount * conversions.eurToCzkRate, 0);
        } else if (from === Currency.Czk && to === Currency.Eur) {
            return round(amount / conversions.eurToCzkRate, 0);
        }
        // Add other currency conversion logic as needed for different pairs

        // If conversion rates are not available for the given currencies, return NaN or throw an error
        return NaN;
    }

    public static convertForeignPriceToUserBase(user: IUser, product: IProduct, currencyConversions: ICurrencyConversions) {
        let convertedPriceToBase: number = null;
        switch (user.accountCurrency) {

            case Currency.Czk:
                convertedPriceToBase = (product.priceEUR || 0) * currencyConversions.eurToCzkRate;
                break;

            case Currency.Eur:
                convertedPriceToBase = (product.priceCZK || 0) / currencyConversions.eurToCzkRate;
                break;
        }
        return convertedPriceToBase;
    }
}