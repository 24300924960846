import IProduct from "../../Interfaces/IProduct";

interface IProps {
    product: IProduct
}

export const ProductSaleState = (props: IProps) => {
    const { product } = props;

    if (product.isSold) {
        return <span className="badge badge-primary m-0">Prodáno</span>
    }
    else if (product.delete) {
        return <span className="badge badge-danger m-0">Neprodáno</span>
    }
    else if (product.enabled === false) {
        return <span className="badge badge-secondary m-0">Staženo z prodeje</span>
    }
    else if (product.enabled === true) {
        return <span className="badge badge-success m-0">V prodeji</span>
    }
    return <></>
}