import { filter, orderBy } from 'lodash';
import { observable, makeAutoObservable, action, ObservableMap, toJS } from 'mobx'
import { ListType } from '../../Enums/ListType';
import { VehicleBrand } from '../../Enums/VehicleBrand';
import { VehicleType } from '../../Enums/VehicleType';
import IOptionData from '../../Interfaces/IOptionData';
import { IVehicleModelDto } from '../../Interfaces/IVehicleModelDto';

export default class ListsStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable vehicleTruckBodies: ObservableMap<string, any> = new ObservableMap();
    @observable vehicleMotoBodies: ObservableMap<string, any> = new ObservableMap();
    @observable vehicleMotoBrands: ObservableMap<string, any> = new ObservableMap();
    @observable vehicleMotoEquipments: ObservableMap<string, any> = new ObservableMap();
    @observable productEquipments: ObservableMap<string, any> = new ObservableMap();
    @observable vehicleModels: IVehicleModelDto[] =[];

    @observable tireWidths: ObservableMap<string, any> = new ObservableMap();
    @observable tireHeights: ObservableMap<string, any> = new ObservableMap();
    @observable tireProfiles: ObservableMap<string, any> = new ObservableMap();
    @observable tireRimDiameters: ObservableMap<string, any> = new ObservableMap();
    @observable tireLoadIndexes: ObservableMap<string, any> = new ObservableMap();
    @observable tireConstructionTypes: ObservableMap<string, any> = new ObservableMap();
    @observable tireSeason: ObservableMap<string, any> = new ObservableMap();
    @observable tireVehicleTypes: ObservableMap<string, any> = new ObservableMap();
    @observable tireSpeedRatings: ObservableMap<string, any> = new ObservableMap();
    @observable pcd: ObservableMap<string, any> = new ObservableMap();

    @observable subcategories: ObservableMap<string, any> = new ObservableMap();

    getBy(type: ListType): ObservableMap<string, any> {
        switch (type) {

            case ListType.VehicleTruckBody: 
                return this.vehicleTruckBodies;

            case ListType.VehicleMotoBody:
                return this.vehicleMotoBodies;

            case ListType.VehicleMotoBrand:
                return this.vehicleMotoBrands;

            case ListType.ProductEquipment:
                return this.productEquipments;

            case ListType.VehicleMotoEquipment:
                return this.vehicleMotoEquipments;

            case ListType.TireWidth:
                return this.tireWidths;

            case ListType.TireHeight:
                return this.tireHeights;

            case ListType.TireRimDiameter:
                return this.tireRimDiameters;

            case ListType.TireLoadIndex:
                return this.tireLoadIndexes;

            case ListType.TireConstructionType:
                return this.tireConstructionTypes;

            case ListType.TireSeason:
                return this.tireSeason;

            case ListType.TireVehicleType:
                return this.tireVehicleTypes;

            case ListType.TireSpeedRating:
                return this.tireSpeedRatings;

            case ListType.TireProfile:
                return this.tireProfiles;

            case ListType.Subcategories:
                return this.subcategories;

            case ListType.Pcd:
                return this.pcd;

            default:
                return new ObservableMap<string, any>();
        }
    }

    getOptionsBy(type: ListType): IOptionData[] {
        var m = this.getBy(type);

        if (m) {
            var options: IOptionData[] = [];
            var map: Map<string, any> = toJS(m); // .toJS();

            map.forEach((value, key, map) => {
                options.push({ label: value, value: key })
            });

            if (type === ListType.VehicleMotoBrand) {
                options = orderBy(options, o => [o.label, 'asc']);
            }
            return options;
        }
        return [];
    }

    @action
    set(type: ListType, key: any, value: any) {
        switch (type) {
            case ListType.VehicleTruckBody:
                this.vehicleTruckBodies.set(key, value);
                break;

            case ListType.VehicleMotoBody:
                this.vehicleMotoBodies.set(key, value);
                break;

            case ListType.VehicleMotoBrand:
                this.vehicleMotoBrands.set(key, value);
                break;

            case ListType.ProductEquipment:
                this.productEquipments.set(key, value);
                break;

            case ListType.VehicleMotoEquipment:
                this.vehicleMotoEquipments.set(key, value);
                break;

            case ListType.TireWidth:
                this.tireWidths.set(key, value);
                break;

            case ListType.TireHeight:
                this.tireHeights.set(key, value);
                break;

            case ListType.TireRimDiameter:
                this.tireRimDiameters.set(key, value);
                break;

            case ListType.TireLoadIndex:
                this.tireLoadIndexes.set(key, value);
                break;

            case ListType.TireConstructionType:
                this.tireConstructionTypes.set(key, value);
                break;

            case ListType.TireSeason:
                this.tireSeason.set(key, value);
                break;

            case ListType.TireVehicleType:
                this.tireVehicleTypes.set(key, value);
                break;

            case ListType.TireSpeedRating:
                this.tireSpeedRatings.set(key, value);
                break;

            case ListType.TireProfile:
                this.tireProfiles.set(key, value);
                break;

            case ListType.Subcategories:
                this.subcategories.set(key, value);
                break;

            case ListType.Pcd:
                this.pcd.set(key, value);
                break;

            default:
        }  
    }

    @action
    setVehicleModels(models: IVehicleModelDto[]) {
        this.vehicleModels = models;
    }

    getVehicleModelsOptions(brand: VehicleBrand, vehicleType: VehicleType): IOptionData[] {
        var models = filter(this.vehicleModels, { brandId: brand, vehicleType: vehicleType });

        var options: IOptionData[] = [];
     
        models.forEach((model) => {
            options.push({ label: model.modelName, value: model.modelId })
        });
        return options;
    }
}