import IFacebookPage from "../Interfaces/IFacebookPage";
import BaseApiService from "./BaseApiService";

export default class FacebokPagesApiService extends BaseApiService {

    public getPages(): Promise<IFacebookPage[]> {
        return this.getRequest('facebookpages', {})
            .then(response => {
                return response.data.records.facebookPages.items;
            })
    }
}