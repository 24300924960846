import { inject, observer } from 'mobx-react';
import IFacebookCatalog from '../../../../Interfaces/IFacebookCatalog';
import BaseComponent from '../../../BaseComponent';
import FacebookCatalogCreateModal from '../../../FacebookShops/FacebookCatalogCreateModal';
import { FacebookCatalogTable } from '../../../FacebookShops/FacebookCatalogTable';
import { FacebookShopConnectCard } from '../../../FacebookShops/FacebookShopConnectCard';
import { PageTitleBox } from '../../../Others/PageTitleBox';
import LoadingScreen from '../../../Screens/LoadingScreen';
import CurrentUserStore from '../../../Stores/CurrentUserStore';
import FacebookCatalogStore from '../../../Stores/FacebookCatalogStore';
import CustomDocumentTitle from '../../../Utils/CustomDocumentTitle';

interface IState {
    loading: boolean,
    showCreateNewModal: boolean,
}

interface IProps {
    currentUserStore?: CurrentUserStore,
    facebookCatalogStore?: FacebookCatalogStore,
}

@inject('facebookCatalogStore', 'currentUserStore')
@observer
export default class FacebookShopsPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            showCreateNewModal: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.getData('/user');

        this.facebookCatalogsApiService.getCatalogs()
            .then(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    handleShowCreateNewModal() {
        this.setState({
            showCreateNewModal: true
        })
    }

    renderContent() {
        const user = this.props.currentUserStore.user;

        return (
            <>
                <FacebookShopConnectCard user={user} />
                {this.renderCatalogs()}
            </>)
    }

    renderCatalogs() {
        const catalogs: IFacebookCatalog[] = this.props.facebookCatalogStore.list;
    
        return (
            <>
                <PageTitleBox title={"Zdroje"} />
                <div className="card">
                    <div className="card-body">

                        {this.state.showCreateNewModal &&
                            <FacebookCatalogCreateModal
                                onHide={() => this.setState({ showCreateNewModal: false })}
                            />}

                        <button
                            onClick={this.handleShowCreateNewModal.bind(this)}
                            className="btn btn-primary mb-2"
                        >
                            Vytvořit zdroj
                        </button>

                        <FacebookCatalogTable
                            facebookCatalogs={catalogs}
                        />
                    </div>
                </div>
            </>
        )
    }

    render() {
        const { loading } = this.state;

        return (
            <CustomDocumentTitle title="Facebook eCommerce">
                <div>
                    <PageTitleBox title={"Facebook eCommerce"} />

                    {loading ? <LoadingScreen /> : this.renderContent()}
                </div>
            </CustomDocumentTitle>
        )
    }
}