import { action, makeAutoObservable, observable } from 'mobx';
import { filter, find, findIndex } from 'lodash';
import IProformaInvoice from '../../Interfaces/IProformaInvoice';

export default class ProformaInvoiceStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IProformaInvoice[] = [];

    @action
    addRecords(data: IProformaInvoice[]) {
        data.forEach((record: IProformaInvoice) => this.addRecord(record));
    }

    @action
    private addRecord(record: IProformaInvoice) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    getById(id: string): IProformaInvoice {
        return find(this.list, { id: id });
    }

    getByUserId(userId: string): IProformaInvoice[] {
        return filter(this.list, website => {
            if (website.userId === userId) {
                return true;
            }
            return false;
        });
    }

    getByIds(ids: string[]): IProformaInvoice[] {
        return filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
    }
}