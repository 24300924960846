import React from "react";
import IProduct from "../../../Interfaces/IProduct";
import BaseComponent from "../../BaseComponent";
import CheckBox from "../../UI/Input/CheckBox";
import InputText from "../../UI/Input/InputText";
import Textarea from "../../UI/Input/Textarea";

interface IState {

}

interface IProps {
    product: IProduct,
    onChange(value: any, key: string): void,
    validChanged?(isValid: boolean): void,
}

export default class TipMotoEditor extends BaseComponent<IProps, IState>
{
    private inputName = React.createRef<InputText>();
    private inputDescription = React.createRef<Textarea>();

    constructor(props: IProps) {
        super(props);

        this.inputName = React.createRef();
        this.inputDescription = React.createRef();
    }

    getInputs() {
        return [this.inputName, this.inputDescription];
    }

    validate(): boolean {
        const inputs = this.getInputs();
        const isValid = this.validateInputs(inputs);
        this.props.validChanged(isValid);
        return isValid;
    }

    render() {
        const { product, onChange } = this.props;

        return (
            <>
                <div className="pb-3 pt-1">
                    <CheckBox
                        checked={product.tipMotoCustomContent}
                        onChange={(value) => onChange(value, 'tipMotoCustomContent')}
                        label="Nastavit jiný obsah"
                    />
                </div>

                <div className={product.tipMotoCustomContent ? 'd-block' : 'd-none'}>
                    <InputText
                        ref={this.inputName}
                        label="Nadpis"
                        onChange={(value) => onChange(value, 'tipMotoName')}
                        value={product.tipMotoName || ''}
                        required
                        forbidSpecialChars={true}
                        maxLength={60}
                    />

                    <Textarea
                        ref={this.inputDescription}
                        label="Popis"
                        onChange={(value) => onChange(value, 'tipMotoDescription')}
                        required
                        value={product.tipMotoDescription || ''}
                        rows={13}
                        maxLength={3000}
                    />
                </div>
            </>)
    }
}