import { ColorResult, SwatchesPicker } from 'react-color';
import InputText from './InputText';
import IInputField from '../../../Interfaces/IInputField';
import React from 'react';

interface IProps {
    label: string,
    value: string,
    onChange(val: string): void,
}

export const WebColorPicker = React.forwardRef<IInputField, IProps>((props, ref) => {
    const { value, onChange, label } = props;

    const handleChangeComplete = (color: ColorResult) => {
        onChange(color.hex);
    };

    const isValidHexColor = (hex: string): boolean => {
        const regex = /^#([0-9A-F]{3,4}){1,2}$/i;
        return regex.test(hex);
    }

    React.useImperativeHandle(ref, () => ({
        getInputs,
        isValid,
    }));

    const isValid = (): boolean => {
        return isValidHexColor(value);
    }

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        return [
           
        ]
    }

    return (
        <div>
            <label>{label}</label>
            <SwatchesPicker
                color={value}
                onChange={(color) => handleChangeComplete(color)}
            />

            <label className="mt-3">Vybráno:</label>
            <div className="row mb-3">
                <div className="col-12 col-sm-3 col-lg-2">
                    <div style={{ background: value }} className="circle"></div>
                </div>

                <div className="col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
                    <InputText
                        label=""
                        value={value}
                        onChange={val => onChange(val)}
                    />
                </div>
            </div>
        </div>
    );
});