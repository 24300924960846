import { Currency } from "../Enums/Currency";
import { Language } from "../Enums/Language";
import { WebsiteType } from "../Enums/WebsiteType";
import IResponseData from "../Interfaces/IResponseData";
import { IWebsite } from "../Interfaces/IWebsite";
import { IOrderWebsiteRequest } from "../Interfaces/Requests/IOrderWebsiteRequest";
import BaseApiService from "./BaseApiService";

export default class WebsitesApiService extends BaseApiService {

    public getWebsites(): Promise<IWebsite[]> {
        return this.getRequest('websites', {})
            .then(response => {
                return response.data.records.websites.items;
            })
    }

    public getWebsite(id: number): Promise<IWebsite> {
        return this.getRequest(`websites/${id}`, {})
            .then(response => {
                return response.data.records.websites.items[0];
            })
    }

    public editWebsite(id: number, website: IWebsite): Promise<IResponseData> {
        return this.putRequest(`websites/${id}`, website, {})
            .then(response => {
                return response.data;
            })
    }

    public createWebsite(name: string, type: WebsiteType, language: Language, currency: Currency): Promise<IResponseData> {
        return this.postRequest(`websites`, { name, type, language, currency }, {})
            .then(response => {
                return response.data;
            })
    }

    public orderWebsite(website: IWebsite, model: IOrderWebsiteRequest): Promise<IResponseData> {
        return this.postRequest(`websites/${website.id}/order`, model, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteWebsite(id: number): Promise<IResponseData> {
        return this.deleteRequest(`websites/${id}`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public uploadImages(websiteId: number, key: string, files: FileList, replace: boolean): Promise<IResponseData> {
        const formData = new FormData();
        Array.from(files).forEach(file => {
            formData.append('files', file);
        });

        return this.postFile(
            `websites/${websiteId}/images/upload/${key}`,
            { replace: replace.toString() },
            formData)
            .then(response => {
                return response.data;
            })
    }

    public deleteImage(websiteId: number, key: string): Promise<IResponseData> {
        return this.deleteRequest(`websites/${websiteId}/images/${key}`, {}, {})
            .then(response => {
                return response.data;
            })
    }
}