import { Country } from "../Enums/Country";
import IOptionData from "../Interfaces/IOptionData";

export class CountryHelper {

    public static getCountryLabel(country: Country): string {
        switch (country) {

            case Country.Czechia:
                return 'Česko';

            case Country.Slovakia:
                return 'Slovensko';

            case Country.Germany:
                return 'Německo';

            case Country.Italy:
                return 'Itálie';

            case Country.Poland:
                return 'Polsko';

            case Country.Usa:
                return 'USA';

            case Country.Denmark:
                return 'Dánsko';

            case Country.Austria:
                return 'Rakousko';

            case Country.Belgium:
                return 'Belgie';

            case Country.France:
                return 'Francie';

            case Country.Luxembourg:
                return 'Lucembursko';

            case Country.Netherlands:
                return 'Nizozemsko';

            case Country.Spain:
                return 'Španělsko';

            case Country.Switzerland:
                return 'Švýcarsko';

            case Country.Sweden:
                return 'Švédsko';

            case Country.Other:
                return 'Jiná země';
        }
    }

    public static getCountryOptions(): IOptionData[] {
        let options: IOptionData[] = [];
        for (let countryS in Country) {
            if (!isNaN(Number(countryS))) {
                const country = (parseInt(countryS) as Country);
                options.push({ label: CountryHelper.getCountryLabel(country), value: country });
            }
        }
        return options;
    }

    public static getCountryOptionsLocationSale(): IOptionData[] {
        let options: IOptionData[] = [];
        const supported = [Country.Czechia, Country.Slovakia];

        supported.forEach(country => {
            options.push({ label: CountryHelper.getCountryLabel(country), value: country });
        })
        return options;
    }

    public static getCountryOptionsBilling(): IOptionData[] {
        let options: IOptionData[] = [];
        const supported = [Country.Czechia, Country.Slovakia];

        supported.forEach(country => {
            options.push({ label: CountryHelper.getCountryLabel(country), value: country });
        })
        return options;
    }
}