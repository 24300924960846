import { observable, action, makeAutoObservable } from 'mobx'
import { findIndex, remove } from 'lodash';
import { IWebmail } from '../../Interfaces/IWebmail';

export default class WebmailStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IWebmail[] = [];

    @action
    addRecords(data: any): any {
        data.forEach((record: IWebmail) => this.addRecord(record));
    }

    @action
    private addRecord(record: IWebmail) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: string[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }
}