import React, { useRef } from "react";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import IProduct from "../../../Interfaces/IProduct";
import IUser from "../../../Interfaces/IUser";
import InputText from "../../UI/Input/InputText";
import IInputField from "../../../Interfaces/IInputField";
import InputPrice from "../../UI/Input/InputPrice";
import InputDatePicker from "../../UI/Input/InputDatePicker";
import { SectionTitle } from "../../Others/SectionTitle";

interface IProps {
    product: IProduct,
    user: IUser,
    onChange(key: string, value: any): void,
    requiredFields: string[],
}

export const BusinessInfoFormCard = React.forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const {
        product,
        user,
        onChange,
    } = props;

    React.useImperativeHandle(ref, () => ({
        getInputs,
    }));

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        return [
            costPriceRef,
            dateOfPurchaseRef,
            nodeRef
        ]
    }

    const costPriceRef = useRef<InputPrice>(null);
    const dateOfPurchaseRef = useRef<InputDatePicker>(null);
    const nodeRef = useRef<InputText>(null);

    return <>
        <div className="card mb-3">
            <div className="card-header">
                <SectionTitle title="OBCHODNÍ ÚDAJE" className="my-0" />
            </div>
            <div className="card-body">
                <div className="pb-2">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4">
                            <InputPrice
                                ref={costPriceRef}
                                label="Pořizovací cena"
                                value={product.costPrice}
                                currency={user.accountCurrency}
                                onChange={(val) => onChange('costPrice', val)}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-4">
                            <InputDatePicker
                                ref={dateOfPurchaseRef}
                                label="Datum pořízení"
                                value={product.dateOfPurchase ? new Date(product.dateOfPurchase) : null}
                                onChange={(val) => onChange('dateOfPurchase', val)}
                                inputUtcShowLocalOutputUtc={true}
                            />
                        </div>

                        <div className="col-12 col-md-4">
                            <InputText
                                ref={nodeRef}
                                label="Interní poznámka"
                                value={product.note}
                                onChange={(val) => onChange('note', val)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
})