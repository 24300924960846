import BaseComponent from './../../../BaseComponent';
import InputText from '../../../UI/Input/InputText';
import React from 'react';
import InputEmail from '../../../UI/Input/InputEmail';
import InputTelephone from '../../../UI/Input/InputTelephone';
import IProductCollection from '../../../../Interfaces/IProductCollection';
import IInputField from '../../../../Interfaces/IInputField';
import { Button, Modal } from 'react-bootstrap';
import SelectBox from '../../../UI/Input/SelectBox';
import axios from 'axios';
import { BazosCountry } from '../../../../Enums/BazosCountry';
import IAppIpAddress from '../../../../Interfaces/IAppIpAddress';
import CheckBox from '../../../UI/Input/CheckBox';
import { OnlineServer } from '../../../../Enums/OnlineServer';
import { BazosAccountHelpAlert } from '../BazosAccountHelpAlert';
import { Accordion } from '../../../UI/Elements/Accordion';
import { filter, find } from 'lodash';
import AppIpAddressStore from '../../../Stores/AppIpAddressStore';
import ProductCollectionStore from '../../../Stores/ProductCollectionStore';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ProductCollectionHelper from '../../../../Helpers/ProductCollectionHelper';
import { BazosHelper } from '../../../../Helpers/Servers/BazosHelper';
import InputNumber from '../../../UI/Input/InputNumber';

interface IState {
    collection: IProductCollection,
    country: BazosCountry,
}

interface IProps {
    collectionServer: IProductCollection,
    show: boolean
    onHide(): void,
    appIpAddressStore?: AppIpAddressStore,
    productCollectionStore?: ProductCollectionStore,
}

@inject('appIpAddressStore', 'productCollectionStore')
@observer
export default class BazosSkSettingsModal extends BaseComponent<IProps, IState> {

    private inputBazosSkName = React.createRef<InputText>();
    private inputBazosSkEmail = React.createRef<InputEmail>();
    private inputBazosSkPhone = React.createRef<InputTelephone>();
    private inputBazosSkVerificationPhone = React.createRef<InputTelephone>();
    private inputBazosSkZIP = React.createRef<InputText>();
    private inputCountry = React.createRef<SelectBox>();
    private inputBazosPassword = React.createRef<InputText>();
    private inputBazosImageSize = React.createRef<SelectBox>();
    private inputBazosSkIpAddress = React.createRef<SelectBox>();
    private bazosSkMultiTopCountRef = React.createRef<InputNumber>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            collection: this.props.collectionServer,
            country: this.getCountry(this.props.collectionServer),
        }

        this.inputBazosSkName = React.createRef();
        this.inputBazosSkEmail = React.createRef();
        this.inputBazosSkPhone = React.createRef();
        this.inputBazosSkZIP = React.createRef();
        this.inputBazosSkVerificationPhone = React.createRef();
        this.inputCountry = React.createRef();
        this.inputBazosPassword = React.createRef();
        this.inputBazosImageSize = React.createRef();
        this.inputBazosSkIpAddress = React.createRef();
        this.bazosSkMultiTopCountRef = React.createRef();
    }

    getCountry(collection: IProductCollection): BazosCountry {
        if (collection.bazosSkZIP === '11000') {
            return BazosCountry.Czechia;
        }
        else if ((collection.bazosSkZIP || '').length === 5) {
            return BazosCountry.Slovakia;
        }
        return null;
    }

    validate() {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            this.inputBazosSkName,
            this.inputBazosSkEmail,
            this.inputBazosSkPhone,
            this.inputBazosSkVerificationPhone,
            this.inputCountry,
            this.bazosSkMultiTopCountRef
        )

        if (this.state.country === 2) {
            inputs.push(this.inputBazosSkZIP);
        }

        inputs.push(
            this.inputBazosPassword,
            this.inputBazosSkIpAddress
        )
        return this.validateInputs(inputs);
    }

    handleChange(key: any, value: any) {
        let collection = this.state.collection;

        if (key === 'bazosSkRenewMinIntervalDays' && value == null) {
            value = 0;
        }

        collection = {
            ...collection,
            [key]: value
        }

        this.setState({
            collection: collection
        });
    }

    handleChangeCountry(value: any) {
        this.setState({
            country: value,
            collection: {
                ...this.state.collection,
                bazosSkZIP: value === 1 ? '11000' : ''
            },
        })
        // Pre zahraničie vyplňte 12345, Česká republika 11000.
    }

    handleSubmitForm() {
        const collection = this.state.collection;

        if (this.validate()) {
            collection.bazosSK = true;
            axios.put(`/user/product-collections/${collection.id}/bazossk`, collection).then(res => {
                if (res.data.success === true) {
                    this.props.onHide();
                }
            })
        }
    }

    render() {
        const collection = this.state.collection;

        const collectionsForIps = filter(this.props.productCollectionStore.getByUserId(collection.userId), (col => {
            var c: IProductCollection = col;

            if (c.id !== collection.id) {
                return true;
            }
            return false;
        }))
        const appIpAddresses = this.props.appIpAddressStore.getByUserId(collection.userId);
        const selectedIp: IAppIpAddress | null = find(appIpAddresses, { id: collection.bazosSkAppIpAddressId })
        const assignedPhones = ProductCollectionHelper.getAssignedPhones(selectedIp, collectionsForIps, OnlineServer.BazosSk);

        return (
            <Modal size="lg" centered={true} show={this.props.show} onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Propojení Bazos.sk</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <BazosAccountHelpAlert />

                        <label className="section-title mt-0 mb-4">PRODEJCE</label>
                        <hr />

                        <div className="row">
                            <div className="col-lg-6">
                                <InputText
                                    ref={this.inputBazosSkName}
                                    label="Jméno (viditelné)"
                                    value={collection.bazosSkName}
                                    required={true}
                                    placeholder="Jan Novák"
                                    onChange={this.handleChange.bind(this, 'bazosSkName')}
                                />
                            </div>
                            <div className="col-lg-6">
                                <InputEmail
                                    ref={this.inputBazosSkEmail}
                                    label="Email (pro odpovědi)"
                                    value={collection.bazosSkEmail}
                                    required={false}
                                    placeholder=""
                                    onChange={this.handleChange.bind(this, 'bazosSkEmail')}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <InputTelephone
                                    ref={this.inputBazosSkPhone}
                                    label="Telefonní číslo (viditelné)"
                                    value={collection.bazosSkPhone}
                                    required={true}
                                    onChange={this.handleChange.bind(this, 'bazosSkPhone')}
                                />
                            </div>
                            <div className="col-lg-6">
                                <InputTelephone
                                    ref={this.inputBazosSkVerificationPhone}
                                    label="Telefonní číslo (pro ověření)"
                                    value={collection.bazosSkVerificationPhone}
                                    required={true}
                                    onChange={this.handleChange.bind(this, 'bazosSkVerificationPhone')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <SelectBox
                                    ref={this.inputCountry}
                                    defaultValue="Vyberte stát"
                                    label="Stát"
                                    required={true}
                                    value={this.state.country}
                                    options={BazosHelper.getCountries()}
                                    onChange={this.handleChangeCountry.bind(this)}
                                />
                            </div>

                            {this.state.country === 2 ? <div className="col-lg-6">
                                <InputText
                                    ref={this.inputBazosSkZIP}
                                    label="Slovenské PSČ"
                                    value={collection.bazosSkZIP}
                                    required={true}
                                    placeholder=""
                                    length={5}
                                    onChange={this.handleChange.bind(this, 'bazosSkZIP')}
                                />
                            </div> : null}
                        </div>

                        <label className="section-title mt-0 mb-4">NAHRÁVÁNÍ</label>
                        <hr />

                        <div className="row">
                            <div className="col-lg-6">
                                <InputText
                                    ref={this.inputBazosPassword}
                                    label="Zvolte si heslo (k editaci)"
                                    value={collection.bazosSkPassword}
                                    required={true}
                                    placeholder=""
                                    onChange={this.handleChange.bind(this, 'bazosSkPassword')}
                                    maxLength={20}
                                />
                            </div>
                            <div className="col-lg-6">
                                <SelectBox
                                    ref={this.inputBazosSkIpAddress}
                                    defaultValue="Vyberte"
                                    label="IP adresa"
                                    required={true}
                                    value={collection.bazosSkAppIpAddressId}
                                    options={ProductCollectionHelper.getIpAddressOptions(appIpAddresses, collectionsForIps, OnlineServer.BazosSk)}
                                    onChange={this.handleChange.bind(this, 'bazosSkAppIpAddressId')}
                                />

                                {selectedIp && assignedPhones.length > 0 && <>
                                    <span className="text-danger">
                                        <b>Tato IP je již použita pro:</b>

                                        <ul className="list-unstyled">
                                            {assignedPhones.map(p => {
                                                return <li key={p}>{p}</li>
                                            })}
                                        </ul>

                                        <b>Použijte volnou IP. Pokud nemáte, objednejte si další <Link to="/ips">zde</Link></b>
                                    </span>
                                </>}
                            </div>
                        </div>

                        <label className="section-title mt-0 mb-4">Pokročilé</label>
                        <hr />

                        <Accordion headingText="Topování" cardClassName="mb-1">
                            <p>Inzerobot umí za vás inzeráty topovat. Je však nutné nejprve nakoupit poukázky na Bazoši. <a target="_blank" href='/bazos'>Poukázky vložíte zde.</a> </p>

                            <CheckBox
                                checked={collection.bazosSkTopAfterUpload}
                                label="Topovat inzerát po nahrání/obnovení (placené)"
                                onChange={this.handleChange.bind(this, 'bazosSkTopAfterUpload')}
                            />

                            <CheckBox
                                checked={collection.bazosSkEnableUploadTopped}
                                label="Povolit obnovit i topované inzeráty (povede ke ztrátě topování)"
                                onChange={this.handleChange.bind(this, 'bazosSkEnableUploadTopped')}
                            />

                            <div className="row mt-3">
                                {collection.bazosSkTopAfterUpload &&
                                    <div className="col-12 col-md-4">
                                        <InputNumber
                                            ref={this.bazosSkMultiTopCountRef}
                                            label="Kolik topů použít po nahrání?"
                                            onChange={(val) => this.handleChange('bazosSkMultiTopCount', val)}
                                            minValue={1}
                                            maxValue={21}
                                            value={collection.bazosSkMultiTopCount ?? 1}
                                        />
                                    </div>}
                            </div> 
                        </Accordion>

                        <Accordion headingText="Jiná nastavení">
                          <SelectBox
                                label='Obnovování'
                                options={BazosHelper.getAutoRenewOptions()}
                                value={collection.bazosSkRenewMinIntervalDays}
                                defaultValue="Po expiraci"
                                onChange={this.handleChange.bind(this, 'bazosSkRenewMinIntervalDays')}
                            />

                            <CheckBox
                                checked={collection.bazosSkCurrentDateDescriptionPrefix}
                                label="Před text inzerátu vložit aktuální datum"
                                onChange={this.handleChange.bind(this, 'bazosSkCurrentDateDescriptionPrefix')}
                            />
                        </Accordion>

                        {(collection.bazosSkRenewMinIntervalDays > 0) ? <p className="text-danger text-center">Obnovováním před expirací inzerátu se vystavujete blokaci tel. čísla a emailové adresy.</p> : ""}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide.bind(this)} variant="secondary" size="lg">
                        Zavřít
                    </Button>
                    <Button variant="primary" size="lg" onClick={this.handleSubmitForm.bind(this)}>
                        {collection.bazosSK ? "Uložit změny" : "Dokončit propojení"}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}