import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import BaseComponent from '../../BaseComponent';
import { PageTitleBox } from '../../Others/PageTitleBox';
import LoadingScreen from '../../Screens/LoadingScreen';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import WebsiteStore from '../../Stores/WebsiteStore';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import CreateWebsiteModal from '../../Websites/CreateWebsiteModal';
import { NoWebsitesCard } from '../../Websites/NoWebsitesCard';
import { WebsiteBenefitsScreen } from '../../Websites/WebsiteBenefitsScreen';
import { WebsitesTable } from '../../Websites/WebsitesTable';

interface IState {
    loading: boolean,
    showCreateNewModal: boolean,
}

interface IProps {
    websiteStore?: WebsiteStore,
    currentUserStore?: CurrentUserStore,
}

@inject('websiteStore', 'currentUserStore')
@observer
export default class WebsitePage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            showCreateNewModal: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.websitesApiService.getWebsites()
            .then(websites => {
                this.setState({
                    loading: false,
                });
            })
    }

    renderContent() {
        const user = this.props.currentUserStore.user;
        const websites = this.props.websiteStore.getByUserId(user.id);

        if (websites.length === 0) {
            return <NoWebsitesCard onCreateWebsite={() => this.setState({ showCreateNewModal: true })} />
        }

        return (
            <div className="card">
                <div className="card-body">
                    <button
                        className="btn btn-primary"
                        onClick={() => this.setState({ showCreateNewModal: true })}
                    >
                        Vytvořit nezávazně nový web
                    </button>

                    <WebsitesTable websites={websites} className="mt-2" />
                </div>
            </div>)
    }

    render() {
        const loading = this.state.loading;
        const showCreateNewModal = this.state.showCreateNewModal;

        return (
            <CustomDocumentTitle title="Prodejní weby">
                <PageTitleBox title="Prodejní weby" />

                <div className="card">
                    <div className="card-body text-center my-2">
                        <h1>
                            <span className="text-success">Web bez starostí s aktuální nabídkou od 499 Kč měsíčně</span>
                        </h1>

                        <div className="row justify-content-center mt-4">
                            <div className="col-md-10">
                                <div className="d-flex justify-content-around flex-wrap">
                                    <div className="d-flex align-items-center mb-3">
                                        <i className="fas fa-check text-success mr-2"></i>
                                        <span>Neplatíte programátora</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <i className="fas fa-check text-success mr-2"></i>
                                        <span>O údržbu se staráme za Vás</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <i className="fas fa-check text-success mr-2"></i>
                                        <span>Automatická aktualizace nabídky</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <i className="fas fa-check text-success mr-2"></i>
                                        <span>Bez investic</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <WebsiteBenefitsScreen />

                {loading ? <LoadingScreen /> : this.renderContent()}

                <CreateWebsiteModal
                    show={showCreateNewModal}
                    onHide={() => this.setState({ showCreateNewModal: false })}
                />
            </CustomDocumentTitle>
        )
    }
}