import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { filter, orderBy, map, ceil } from 'lodash';
import Pagination from 'react-js-pagination';
import { PageTitleBox } from '../../Others/PageTitleBox';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import ProductStore from '../../Stores/ProductStore';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import LoadingScreen from '../../Screens/LoadingScreen';
import LoadingErrorScreen from '../../Screens/LoadingErrorScreen';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import IProduct from '../../../Interfaces/IProduct';
import { VehicleTableItem } from '../../VehicleManager/VehicleTabIeItem';
import InputText from '../../UI/Input/InputText';
import VehiclesApiService from '../../../Services/VehiclesApiService';
import { Link } from 'react-router-dom';
import SmartSelectBox from '../../UI/Input/SmartSelectBox';
import Product from '../../../Models/Product';
import { VehicleBrand } from '../../../Enums/VehicleBrand';
import { VehicleModel } from '../../../Enums/VehicleModel';


interface IProps {
    currentUserStore?: CurrentUserStore,
    productStore?: ProductStore
    productCollectionStore?: ProductCollectionStore,
}

const countToShow = 25;

const vehicleApiService = new VehiclesApiService();

export const VehicleManagerPage = inject('currentUserStore', 'productStore', 'productCollectionStore')(observer((props: IProps) => {

    const { currentUserStore, productStore } = props;
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [productIds, setProductIds] = useState([]);
    const [totalItems, setTotalItems] = useState(0);

    const [search, setSearch] = useState("");
    const [licencePlateVin, setLicencePlateVin] = useState("");
    const [vehicleBrand, setVehicleBrand] = useState<VehicleBrand>();
    const [vehicleModel, setVehicleModel] = useState<VehicleModel>();
    const [availableBrands, setAvailableBrands] = useState<VehicleBrand[]>([]);
    const [availableModels, setAvailableModels] = useState<VehicleModel[]>([]);

    useEffect(() => {
        onPageChanged(1);

        return () => {
            vehicleApiService.cancelRequests();
        }
    }, [])

    useEffect(() => {
        setVehicleModel(null);
    }, [vehicleBrand])

    const onPageChanged = (pageNumber: number) => {

        setLoading(true);
        setCurrentPage(pageNumber);

        let skipCount = 0;

        if (pageNumber >= 2) {
            skipCount = (pageNumber - 1) * countToShow;
        }

        vehicleApiService.getVehicles(search, licencePlateVin, vehicleBrand, vehicleModel, countToShow, skipCount)
            .then(data => {
                let products: IProduct[] = data.records.products.items;
                const totalItems = data.others.get("totalCount")

                window.scroll(0, 0);
                setProductIds(map(products, s => s.id));
                setTotalItems(totalItems);

                setAvailableBrands(data.others.get("availableBrands"));
                setAvailableModels(data.others.get("availableModels"));
            })
            .catch(response => {
                setLoadingError(true);
            }).finally(() => {
                setLoading(false);
            })
    }

    const user = currentUserStore.user;

    if (loading) {
        return <LoadingScreen />
    }
    if (loadingError) {
        return <LoadingErrorScreen />
    }

    const products = orderBy(filter(productStore.list, product => {
        if (product.delete === false && productIds.includes(product.id)) {
            return true;
        }
        return false;

    }), prod => [prod.dateCreated], ['desc']);

    const onFilter = () => {
        onPageChanged(1);
    }

    const availableBrandsOptions = filter(Product.getVehicleBrandOptions(), brand => {
        if ((availableBrands || []).includes(brand.value)) {
            return true;
        }
        return false;
    })

    const availableModelsOptions = filter(Product.getVehicleModelOptions(vehicleBrand), model => {
        if ((availableModels || []).includes(model.value)) {
            return true;
        }
        return false;
    })

    return (
        <CustomDocumentTitle title="Správa autobazaru - vozidla">
            <PageTitleBox title="Správa autobazaru - vozidla" />


            <div className="card mt-1">
                <div className="card-body">
                    <button className="btn btn-primary">
                        <i className="fas fa-plus mr-2"></i>Přidat vozidlo
                    </button>

                    <div className="float-right">
                        <Link to="/inzeraty" className="btn btn-outline-info mr-1">
                            <i className="bi bi-megaphone mr-2"></i>Správa inzerce
                        </Link>
                        <Link to="/vehicles/archive" className="btn btn-outline-secondary">
                            <i className="bi bi-archive mr-2"></i>Archív vozidel
                        </Link>
                    </div>

                    <div className="table-responsive mt-2">
                        <table className="table table-striped mg-b-0">
                            <thead>
                                <tr>
                                    <th></th>

                                    <th>

                                    </th>

                                    <th>
                                        SPZ / VIN
                                    </th>

                                    <th>
                                        Značka
                                    </th>

                                    <th>
                                        Model
                                    </th>

                                    <th>

                                    </th>

                                    <th></th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td>

                                    </td>

                                    <td>
                                        <InputText
                                            label=""
                                            value={search}
                                            onChange={(val) => setSearch(val)}
                                            placeholder="Hledat..."
                                            onEnter={onFilter}
                                        />
                                    </td>

                                    <td>
                                        <InputText
                                            label=""
                                            value={licencePlateVin}
                                            placeholder="Hledat SPZ / VIN"
                                            onChange={(val) => setLicencePlateVin(val)}
                                            onEnter={onFilter}
                                        />
                                    </td>

                                    <td>
                                        <SmartSelectBox
                                            label=""
                                            onChange={(value, key) => setVehicleBrand(value ? parseInt(value) : null)}
                                            value={vehicleBrand}
                                            options={availableBrandsOptions}
                                            uid="vehicleBrand"
                                            placeholder="Značka"
                                            className="filter-selectbox"
                                        />
                                    </td>

                                    <td colSpan={2}>
                                        <SmartSelectBox
                                            label=""
                                            onChange={(value, key) => setVehicleModel(value ? parseInt(value) : null)}
                                            value={vehicleModel}
                                            options={availableModelsOptions}
                                            uid="model"
                                            placeholder="Model"
                                            className="filter-selectbox"
                                        />
                                    </td>

                                    <td>
                                        <button
                                            className="btn btn-outline-primary btn-block mb-3"
                                            onClick={onFilter}
                                        >
                                            <i className="bi bi-funnel mr-2"></i>Filtrovat
                                        </button>
                                    </td>
                                </tr>

                                {(products).map((product: IProduct) => {
                                    return (
                                        <VehicleTableItem
                                            key={product.id}
                                            product={product}
                                        />
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {productIds.length === 0 &&
                        <div className="py-5">
                            <div className="text-center">
                                <h3>Nebyly nalezeny žádné výsledky</h3>
                            </div>
                            <div className="text-center">
                                <p>Zkuste změnit parametry vyhledávání.</p>
                            </div>
                        </div>}

                    {totalItems > countToShow &&
                        <>
                            <Pagination
                                pageRangeDisplayed={countToShow}
                                activePage={currentPage}
                                itemsCountPerPage={countToShow}
                                totalItemsCount={totalItems}
                                onChange={(page) => onPageChanged(page)}
                                linkClass="page-link"
                                itemClass="page-item"
                                innerClass="pagination justify-content-center mt-3"
                                hideDisabled={true}
                                hideFirstLastPages={true}
                            />
                            <p className="text-center">Procházíte stranu {currentPage} z {ceil(totalItems / countToShow)}. Nalezeno {totalItems} vozidel.</p>
                        </>
                    }
                </div>
            </div>
        </CustomDocumentTitle>
    )
}))
