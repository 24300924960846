import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import CurrencyHelper from '../../../Helpers/CurrencyHelper';
import { PageTitleBox } from '../../Others/PageTitleBox';
import { AutomotoPricing } from '../../Pricing/AutomotoPricing';
import { Pricing } from '../../Pricing/Pricing';
import { TermsAccordion } from '../../Pricing/TermsAccordion';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import SelectBox from '../../UI/Input/SelectBox';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';

interface IProps {
    currentUserStore?: CurrentUserStore
}

export const PricingAutoMotoPage = inject('currentUserStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;

    const [currency, setCurrency] = useState(user.accountCurrency);

    return (
        <CustomDocumentTitle title="Ceník - Autobazary / Motobazary / Pracovní technika">
            <div>
                <PageTitleBox title="Ceník - Autobazary / Motobazary / Pracovní technika" />

                <div className="card">
                    <div className="card-body">

                        <div className="float-right">
                            <SelectBox
                                options={CurrencyHelper.getOptions()}
                                value={currency}
                                label="Měna"
                                onChange={(value) => setCurrency(value)}
                            />
                        </div>
                     
                        <p>Tato licence je určená pro prodejce Auto, Moto, Pracovní techniky...V ceně každé licence je 1 IP adresa. </p>
                        <Pricing>
                            <AutomotoPricing currency={currency} />
                            <p>1x IP navíc stojí 40 Kč.</p>
                        </Pricing>     

                        <TermsAccordion />
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    )
}))