import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import ProformaInvoicesApiService from '../../../Services/ProformaInvoicesApiService';
import { PageTitleBox } from '../../Others/PageTitleBox';
import { ProformaInvoicesTable } from '../../ProformaInvoices/ProformaInvoicesTable';
import LoadingScreen from '../../Screens/LoadingScreen';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import ProformaInvoiceStore from '../../Stores/ProformaInvoiceStore';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import BillingForm from '../../MyBusiness/BillingForm';
import { IBilling } from '../../../Interfaces/IBilling';
import { SectionTitle } from '../../Others/SectionTitle';
import { SubmitButton } from '../../UI/Input/SubmitButton';
import UserApiService from '../../../Services/UserApiService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CheckBox from '../../UI/Input/CheckBox';
import { StandingOrderInstructions } from '../../Billing/StandingOrderInstructions';
import { Currency } from '../../../Enums/Currency';

interface IProps {
    proformaInvoiceStore?: ProformaInvoiceStore,
    currentUserStore?: CurrentUserStore,
}

const userApiService = new UserApiService();
const proformaInvoicesApiService = new ProformaInvoicesApiService();

export const ProformaInvoicesListPage = inject('proformaInvoiceStore', 'currentUserStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;
    const proformaInvoices = props.proformaInvoiceStore.getByUserId(user.id);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        proformaInvoicesApiService.getList().then(response => {
            setLoading(false);
        })

        return () => {
            proformaInvoicesApiService.cancelRequests();
            userApiService.cancelRequests();
        }
    }, [])

    const billingFormRef = useRef<BillingForm>(null);
    const [billing, setBilling] = useState<IBilling>(user.billing);

    const onSubmit = () => {
        const isValid = billingFormRef.current.validate();


        if (isValid) {
            userApiService.saveBilling(billing)
                .then(data => {
                    if (data.success) {
                        toast.success("Uloženo");
                    }
                    else {
                        toast.error("Došlo k chybě při ukládání");
                    }
                })
        }
    }

    const [loadingGetByIc, setLoadingGetByIc] = useState(false);

    const onLoadByIc = () => {
        setLoadingGetByIc(true);

        userApiService.getBillingByIc(billing.ic)
            .then(data => {
                if (data.success) {

                    const preloadedBilling: IBilling = data.others.get("billing");

                    setBilling({
                        ...billing,
                        companyName: preloadedBilling.companyName,
                        dic: preloadedBilling.dic || billing.dic,
                        street: preloadedBilling.street,
                        streetRegistryNumber: preloadedBilling.streetRegistryNumber,
                        city: preloadedBilling.city,
                        zip: preloadedBilling.zip,
                    })

                    Swal.fire({
                        icon: 'success',
                        title: "Přednačetli jsme vaše fakturační údaje",
                        text: "Údaje zkontrolujte a uložte",
                        confirmButtonText: "Ano, rozumím"
                    })
                }
                else {
                    toast.error("Nelze načíst dle IČ");
                }
            }).finally(() => {
                setLoadingGetByIc(false);
            })
    }


    return (
        <CustomDocumentTitle title="Fakturace">
            <div>
                <PageTitleBox title="Fakturace" />

                <div className="card">
                    <div className="card-body">
                        <SectionTitle title="Fakturační údaje" className="mt-2" />
                        <hr />
                        <BillingForm
                            ref={billingFormRef}
                            billing={billing}
                            onChange={(val, key) => setBilling({
                                ...billing,
                                [key]: val
                            })}
                            loadingGetByIc={loadingGetByIc}
                            onLoadByIc={onLoadByIc}
                            user={user}
                        />

                        <SectionTitle title="Pevný VS - Trvalý příkaz" className="mt-2" />
                        <hr />

                        <div className="row">
                            <div className="col-12 col-md-4">
                                <CheckBox
                                    label="Používat pevný variabilní symbol / trvalý příkaz"
                                    checked={billing.useConstantVs}
                                    onChange={(value) => setBilling({
                                        ...billing,
                                        useConstantVs: value
                                    })}
                                />

                                {billing.useConstantVs && <>
                                    <p className="my-2">Váš pevný VS je <b>{user.vs}</b></p>
                                </>}

                                {(user.accountCurrency !== Currency.Czk || user.paymentPeriodMonths !== 1) &&
                                    <>
                                        <div className="alert alert-danger mt-3">
                                            <div className="alert-body">
                                                Pro váš účet není možné nastavit trvalý příkaz k úhradě.
                                            </div>
                                        </div>
                                    </>}
                            </div>

                            {billing.useConstantVs && user.accountCurrency === Currency.Czk && user.paymentPeriodMonths === 1 &&
                                <div className="col-12 col-md-8">
                                    <StandingOrderInstructions user={user} />
                                </div>}
                        </div>

                        <SubmitButton
                            onClick={onSubmit}
                            loading={loading}
                            className="mt-3"
                        >
                            <>Uložit fakturační údaje</>
                        </SubmitButton>
                    </div>
                </div>

                {loading ? <LoadingScreen /> : <div className="card">
                    <div className="card-body">
                        <SectionTitle title="Vystavené doklady" className="mt-2" />

                        <div className="mt-4 mb-5">
                            {proformaInvoices.length > 0 &&
                                <ProformaInvoicesTable proformaInvoices={proformaInvoices} />}

                            {proformaInvoices.length === 0 &&
                                <>
                                    <div className="py-3">

                                        <br />
                                        <div className="text-center">
                                            <h3>Zatím nemáte žádné vystavené doklady</h3>
                                        </div>
                                        <div className="text-center">
                                            <p>Jakmile budou nějaké vystaveny, najdete je na tomto místě.</p>
                                        </div>
                                    </div>
                                </>}
                        </div>
                    </div>
                </div>}
            </div>
        </CustomDocumentTitle>
    )
}))