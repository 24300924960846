import { inject, observer } from "mobx-react"
import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { OnlineServer } from "../../Enums/OnlineServer"
import IProduct from "../../Interfaces/IProduct"
import IProductCollection from "../../Interfaces/IProductCollection"
import IUser from "../../Interfaces/IUser"
import Product from "../../Models/Product"
import ProductServerAccountStore from "../Stores/ProductServerAccountStore"
import { ProductServerLabelContent } from "./ProductServerLabelContent"
import { ProductServerTooltipContent } from "./ProductServerTooltipContent"

interface IProps {
    product: IProduct,
    collection: IProductCollection,
    onlineServer: OnlineServer,
    onClick?(server: OnlineServer): void,
    user: IUser,
    productServerAccountStore?: ProductServerAccountStore,
}

export const ProductServerStateLabel = inject('productServerAccountStore')(observer((props: IProps) => {
    const { product, onlineServer, onClick, user } = props;

    const productServerAccounts = props.productServerAccountStore.getByProductId(product.id);
    const info = Product.getProductServerInfo(product, props.collection, onlineServer, user, productServerAccounts);

    if (info === null) {
        return <></>
    }

    function handleServerClick() {
        onClick(onlineServer);
    }

    return (
        <>
            <OverlayTrigger
                placement={'left'}
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        <ProductServerTooltipContent
                            info={info}
                        />
                            <br /><small>Kliknutím zobrazíte podrobnosti</small>
                    </Tooltip>
                }
            >
                <div style={{ fontSize: '11px' }} className={`clickable`}>
                    <ProductServerLabelContent
                        info={info}
                        onClick={() => handleServerClick()}
                    />
                </div>
            </OverlayTrigger>
        </>)
}));