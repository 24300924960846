import { inject, observer } from 'mobx-react';
import { PageTitleBox } from '../../../Others/PageTitleBox';
import CurrentUserStore from '../../../Stores/CurrentUserStore';
import CustomDocumentTitle from '../../../Utils/CustomDocumentTitle';
import { useEffect, useState } from 'react';
import { SectionTitle } from '../../../Others/SectionTitle';
import InputText from '../../../UI/Input/InputText';
import CheckBox from '../../../UI/Input/CheckBox';
import { OnlineServer } from '../../../../Enums/OnlineServer';
import { ValidationHelper } from '../../../Utils/ValidationHelper';
import GoogleApiService from '../../../../Services/GoogleApiService';
import { toast } from 'react-toastify';

interface IProps {
    currentUserStore?: CurrentUserStore,
}

const googleApiService = new GoogleApiService();

export const GoogleMerchantPage = inject('currentUserStore')(observer((props: IProps) => {

    useEffect(() => {
        window.scrollTo(0, 0);

        return () => {
            googleApiService.cancelRequests();
        }
    });

    const user = props.currentUserStore.user;
    const isEnabledServer = user.uploaderServers.includes(OnlineServer.GoogleMerchant);
    const [enabled, setEnabled] = useState(user.uploaderServers.includes(OnlineServer.GoogleMerchant));

    const submit = () => {
        /*  const inputs = [
            
          ];
  
          const isValid = ValidationHelper.validateInputs(inputs);
  
          if (isValid) {*/
        googleApiService.saveSettings(enabled)
            .then(data => {
                if (data.success) {
                    toast.success("Nastavení uloženo")
                }
            })
        //  }
    }

    return (
        <CustomDocumentTitle title="Google Merchant - EXPORT">
            <PageTitleBox title="Google Merchant - EXPORT" />
            <div className="card mb-0">
                <div className="card-body">

                    <p>Export Google Merchant je realizován prostřednictvím datového feedu.</p>

                    <SectionTitle title="Jak propojit" className="mt-4" />
                    <p className="text-danger">Dodržte přesné pořadí kroků. V momentě propojení, musíte mít již doplněné potřebné údaje a zapnutý Autobazar.eu, aby se inzeráty již vyskytovaly ve feedu.</p>
                    <ol>
                        <li>Povolte export na této stránce</li>
                        <li>Povolte export u jednotlivých inzerátů v Inzerobotovi v prvním kroku "Marketingové kanály"</li>
                        <li>Nastavte si stahování z URL feedu v Google Merchant</li>
                    </ol>

                    {isEnabledServer &&
                        <div className="mt-3">
                            <InputText
                                label="URL feedu"
                                value={`${window.location.protocol}//${window.location.hostname}/api/google/merchant/feed?userId=${user.id}`}
                                readonly
                            />
                        </div>}
                </div>
            </div>

            <PageTitleBox title="Nastavení" />
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <CheckBox
                                checked={enabled}
                                label="Povolit export"
                                onChange={(checked) => setEnabled(checked)}
                            />

                            <button
                                className="btn btn-primary mt-2"
                                onClick={submit}
                            >
                                Uložit změny
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>)
}))