import React from 'react';

interface IState {

}

interface IProps {
    title?: string,
    withCard?: boolean
}

export default class LoadingErrorScreen extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    render() {
        if (this.props.withCard === false) {
            return this.renderContent();
        }

        return (
            <div className="card mt-2">
                <div className="card-body">
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    renderContent() {
        if (navigator.onLine) {
            return (
                <div className="py-3">
                    <div className="d-flex justify-content-center mb-3 mt-2">
                        <i className="far fa-frown tx-secondary fa-3x"></i>
                    </div>

                    <br />
                    <div className="text-center">
                        <h3>{this.props.title || 'Došlo k chybě při načítání'}</h3>
                    </div>
                    <div className="text-center">
                        <p>Chybu jsme zaznamenali.</p>
                    </div>
                    <div className="d-flex justify-content-center py-3">
                        <button onClick={() => window.location.reload()} type="button" className="btn btn-primary"><i className="fas fa-redo mr-2" />Znovu načíst</button>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="py-3">
                    <div className="d-flex justify-content-center mb-3 mt-2">
                        <i className="fas fa-frown tx-secondary fa-3x"></i>
                    </div>

                    <br />
                    <div className="tx-center">
                        <h3>Problém s internetovým připojením</h3>
                    </div>
                    <div className="tx-center">
                        <p>Zkontroluj připojení k internetu.</p>
                    </div>
                    <div className="d-flex justify-content-center py-3">
                        <button onClick={() => window.location.reload()} type="button" className="btn btn-primary">
                            <i className="fas fa-redo mr-2" />Znovu načíst
                        </button>
                    </div>
                </div>
            )
        }

    }

}