import React from "react"

export const NoTransactionsCard = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="py-3">
                    <div className="d-flex justify-content-center mb-1 mt-2">
                        <i className="far fa-money-bill-alt fa-3x"></i>
                    </div>

                    <br />
                    <div className="text-center">
                        <h3>Zatím tu nic není...</h3>
                    </div>
                    <div className="text-center">
                        <p>Zde se zobrazí transakce na vašem kreditním účtu.</p> 
                    </div>
                </div>
            </div>
        </div>)
}