import React from "react";
import { Link } from "react-router-dom";
import IProduct from "../../Interfaces/IProduct";
import Product from "../../Models/Product";
import { VehicleOptionsDropdown } from "./VehicleOptionsDropdown";

interface IProps {
    product: IProduct,
}

export const VehicleActions = (props: IProps) => {
    const { product,} = props;
   
        return (
            <div className="btn-group float-right" role="group">
                <Link to={Product.getEditLink(product)}>
                    <button className="btn btn-warning btn-sm  ">
                        <i className="far fa-edit mr-2 d-none d-md-inline"></i>
                        Upravit
                    </button>
                </Link>

                <VehicleOptionsDropdown
                    product={product}
                />
            </div>
        );
}