import { Link } from "react-router-dom";
import { OnlineServer } from "../../Enums/OnlineServer";
import IProductCollection from "../../Interfaces/IProductCollection";
import { ProductCollectionWarning } from "./ProductCollectionWarning";
import { OnlineServerHelper } from "../Utils/OnlineServerHelper";

interface IProps {
    collection?: IProductCollection
}

export const CollectionCard = (props: IProps) => {

    const { collection } = props;

    return (
        <div className="card mb-2">
            <div className="card-header">
                <div className="float-left">
                    <h4 className="card-title font-16 my-0"><i className="fas fa-archive"></i> {collection.name}</h4>
                </div>
                <div className="float-right">
                    <Link to={`/nahravac/kolekce/${collection.id}/upravit`}>
                        <ProductCollectionWarning collection={collection} /> <i className="fas fa-cog clickable text-secondary"></i>
                    </Link>
                </div>
            </div>

            <div className="card-body">
                {collection.delete && <b className="text-danger">Kolekce bude smazána</b>}         

                <div style={{ clear: 'both' }}>
                    <div className="row no-gutters">
                        <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            {collection.productsCount}&nbsp;{collection.unlimited ? "inzerátů" : <>z&nbsp;50&nbsp;inzerátů</>} 
                        </div>

                        {collection.bazosCZ && <div className="col-2 col-sm-2">
                            {OnlineServerHelper.getBadge(OnlineServer.BazosCz)}
                        </div>}

                        {collection.bazosSK && <div className="col-2 col-sm-2">
                            {OnlineServerHelper.getBadge(OnlineServer.BazosSk)}
                        </div>}

                        {collection.sbazar && <div className="col-2 col-sm-2">
                            {OnlineServerHelper.getBadge(OnlineServer.Sbazar)}
                        </div>}
                    </div>

                    <Link to={`/nahravac/kolekce/${collection.id}/prehled`}>
                        <button className="btn btn-outline-purple btn-block mt-3">
                            Zobrazit inzeráty
                            <i className="fas fa-angle-right ml-2"></i>
                        </button>
                    </Link>

                </div>
            </div>
        </div>
    )
}