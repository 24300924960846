import { useEffect } from "react";
import UserApiService from "../../../Services/UserApiService";
import { toast } from "react-toastify";

interface IProps {

}

const userApiService = new UserApiService();

export const WebembedAllowAlert = (props: IProps) => {

    useEffect(() => {
        return () => {
            userApiService.cancelRequests();
        }
    }, [])

    const handleAllow = () => {
        userApiService.enableWebEmbedExport()
            .then(data => {
                if (data.success) {
                    toast.success("Webové widgety povoleny");
                }
            })
    }

    return (
        <>
            <div className="alert alert-warning">
                <h3>Povolte webové widgety</h3>
                <hr />
                <p>Z bezpečnostních důvodů je potřeba povolit webové widgety.</p>
                <button
                    className="btn btn-warning"
                    onClick={handleAllow}
                >
                    Povolit
                </button>
            </div>
        </>
    )
}