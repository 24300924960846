import { take } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import ProductApiService from '../../../Services/ProductApiService';
import { PageTitleBox } from '../../Others/PageTitleBox';
import LoadingScreen from '../../Screens/LoadingScreen';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import ProductStore from '../../Stores/ProductStore';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import ProductImageStore from '../../Stores/ProductImageStore';
import IProduct from '../../../Interfaces/IProduct';
import { ProductFilterTableItem } from '../../Products/ProductFilterTabIeItem';

interface IProps {
    productStore?: ProductStore,
    currentUserStore?: CurrentUserStore,
    productCollectionStore?: ProductCollectionStore,
    productImageStore?: ProductImageStore,
}

const productApiService = new ProductApiService();

export const ProductsErrorsPage = inject(
    'productStore',
    'currentUserStore',
    'productCollectionStore',
    'productImageStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;
    const collections = props.productCollectionStore.list;
    const productsErrors = take(props.productStore.getWithError(collections), 50);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        productApiService.getErrors().then(response => {
            setLoading(false);
        })

        return () => {
            productApiService.cancelRequests();
        }
    }, [])

    return (
        <CustomDocumentTitle title="Odmítnuté inzeráty">
            <div>
                <PageTitleBox title="Odmítnuté inzeráty" />

                {loading ? <LoadingScreen /> :

                    <div className="card">
                        <div className="card-body">
                            {!loading && productsErrors.length > 0 &&
                                <div className="table-responsive">
                                    <table className="table table-striped mg-b-0">
                                        <tbody>
                                            {(productsErrors).map((product: IProduct) => {
                                                return (
                                                    <ProductFilterTableItem
                                                        key={product.id}
                                                        product={product}
                                                        checked={false}
                                                        onCheckedChange={(value) => { }}
                                                        showGoToCollectionButton
                                                    />
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>}

                            {!loading && productsErrors.length === 0 &&
                                <p className="text-center py-4">Žádné chybové inzeráty :-)</p>
                            }

                            {!loading && user.productsWithErrorsCount > 50 &&
                                <p className="text-center py-4">Zobrazeno prvních 50 záznamů.</p>
                            }
                        </div>
                    </div>} 
            </div>
        </CustomDocumentTitle>
    )
}))