import React from "react"

export const WebmailPricing = () => {
    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th></th>

                            <th>Cena</th>

                            <th></th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td>
                                <b>Cena za zřízení služby vč. nastavení domény</b>
                            </td>

                            <td>
                                1 000 Kč
                            </td>

                            <td>
                               
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <b>Emailová schránka na vlastní doméně</b>
                            </td>

                            <td>
                                <s>89 Kč / měsíc / schránka</s> <span className="text-danger">*1. SCHRÁNKA ZDARMA</span>
                            </td>

                            <td>
                                Prostor pro emaily - 2 GB
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <b>1 Schránka navíc</b>
                            </td>

                            <td>
                                89 Kč / měsíc
                            </td>

                            <td>
                                Prostor pro emaily - 2 GB
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <b>Prostor navíc pro emaily</b>
                            </td>

                            <td>
                                50 Kč / měsíc
                            </td>

                            <td>
                                Prostor pro emaily - 5 GB
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p>* Platí pro zákazníky, kteří si platí produkt správa inzerce nebo webovou stránku</p>
            <p>V ceně služby není doména. Služba se platí na 1 rok dopředu. Nejsme plátci DPH.</p>
        </>)
}