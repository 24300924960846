import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IProduct from "../../../Interfaces/IProduct";
import { MainProductImg } from "../../Products/MainProductImg";

interface IProps {
    product: IProduct,
    onClick?(): void,
}

export const ProductImageCell = (props: IProps) => {

    const { product } = props;

    return (
        <>
            <MainProductImg
                product={product}
            />

            {product.lock &&
                <OverlayTrigger
                    placement={'right'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Inzerát nebude přepsán importérem
                        </Tooltip>
                    }
                >
                    <i className="fas fa-lock overrideLock"></i>
                </OverlayTrigger>
            }
        </>)
}