import axios from "axios";
import IProduct from "../../../Interfaces/IProduct";
import ProductImageApiService from "../../../Services/ProductImagesApiService";
import Resizer from "react-image-file-resizer";
import Swal from "sweetalert2";

const productImageApiService = new ProductImageApiService();

export const uploadImages = async (images: File[], product: IProduct) => {
    {
        for (var i = 0; i < images.length; i++) {

            const image = images[i];

            const imageExtensions = ['jpg', 'jpeg', 'png'];

            // Get the file extension
            const fileName = image.name;
            const fileExtension = fileName.split('.').pop()?.toLowerCase();

            // Check if the file extension is in the imageExtensions array
            if (!imageExtensions.includes(fileExtension)) {
                Swal.fire({
                    icon: 'error',
                    title: `Soubor ${fileName} není podporovaný obrázek`,
                    text: `Podporované formáty jsou ${imageExtensions.join(', ')}`
                });
            }
            else {
                var img = await resizeFile(image, 250) as Blob;

                let width: number = 0;
                let height: number = 0;

                var img2 = new Image;
                img2.onload = () => {
                    width = img2.width;
                    height = img2.height;
                }
                img2.src = URL.createObjectURL(img);

                await productImageApiService.getPreSignedUrlForUpload()
                    .then(data => {
                        const url = data.get("url");
                        const id = data.get("id");

                        axios.put(url, img, { headers: { 'Content-Type': img.type } })
                            .then((response => {
                                if (response.status === 200) {
                                    productImageApiService.createImageCallback(id, product.id, width, height);
                                }
                            }));
                    })
            }
        }
    }
}

/*
const resizeFile: any = (file: File, imageQuality: number) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            imageQuality,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob", 800, 600
        );
    });
    */
const resizeFile: any = (file: File, targetFileSizeKB: number): Promise<Blob> =>
    new Promise((resolve) => {
        const MAX_QUALITY = 100; // Maximum quality
        const MIN_QUALITY = 70; // Minimum quality
        const STEP = 3; // Step size for adjusting quality

        let currentQuality = MAX_QUALITY;
  
        const attemptResize = () => {
            Resizer.imageFileResizer(
                file,
                1920,
                1080,
                "JPEG",
                currentQuality,
                0,
                (blob: any) => {
                    //   const blob = dataURItoBlob(uri);
                    const fileSizeKB = blob.size / 1024; // Calculate file size in KB

                    if (fileSizeKB <= targetFileSizeKB || currentQuality <= MIN_QUALITY) {
                        resolve(blob);
                    } else {
                        // If the file size is still too large, reduce quality and try again
                        currentQuality -= STEP;
                        attemptResize();
                    }
                },
                "blob", 800, 600
            );
        };
        attemptResize();
    });