import IOptionData from "../../Interfaces/IOptionData";
import IProductCollection from "../../Interfaces/IProductCollection";
import { ISbazarLocation } from "../../Interfaces/ISbazarLocation";

export class SbazarHelper {

    static getDescriptionMaxChars(collection: IProductCollection): number {
        let maxChars = 1000;

        if (collection.sbazarCurrentDateDescriptionPrefix) {
            if (maxChars > (maxChars - 13)) { maxChars = maxChars - 13; }
        }
        return maxChars;
    }

    public static getMaxNameCharsCount(): number {
        return 50;
    }

    public static getAutoRenewOptions(): IOptionData[] {
        var autoRenewOptions: IOptionData[] = [];
        autoRenewOptions.push({ label: 'Nikdy', value: -1 });
        for (var i = 30; i > 0; i--) {
            autoRenewOptions.push({ label: 'každých ' + i + ' dní', value: i });
        }
        return autoRenewOptions;
    }

    public static getLocationDefValue(locations: ISbazarLocation[]): string {
        if (locations.length === 0) {
            return "Vyhledejte lokality výše...";
        }
        return "Vyberte ze seznamu";
    }
}