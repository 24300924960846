export enum VehicleModel {

    Ostatni = 1,
    /* Abarth */
    Abarth124Spider = 101,
    Abarth500 = 102,
    Abarth500C = 103,
    Abarth595 = 104,
    Abarth59550TH = 105,
    Abarth595C = 106,
    Abarth695 = 107,
    AbarthPunto = 108,

    /* Acura */
    AcuraCL = 201,
    AcuraEL = 202,
    AcuraIntegra = 203,
    AcuraMDX = 204,
    AcuraNSXT = 205,
    AcuraRDX = 206,
    AcuraRL = 207,
    AcuraRSX = 208,
    AcuraTL = 209,
    AcuraTSX = 210,
    AcuraZDX = 211,

    /* Aixam */
    AixamA400 = 301,
    AixamA500 = 302,
    AixamA721 = 303,
    AixamA741 = 304,
    AixamA751 = 305,
    AixamCITY = 306,
    AixamCoupe = 307,
    AixamCROSSLINE = 308,
    AixamCROSSOVER = 309,
    AixameCity = 310,
    AixameCoupe = 311,
    AixamGTO = 312,
    AixamMinauto = 313,
    AixamROADLINE = 314,
    AixamSCOUTYR = 315,

    /* AlfaRomeo */
    AlfaRomeo145 = 401,
    AlfaRomeo146 = 402,
    AlfaRomeo147 = 403,
    AlfaRomeo155 = 404,
    AlfaRomeo156 = 405,
    AlfaRomeo159 = 406,
    AlfaRomeo164 = 407,
    AlfaRomeo166 = 408,
    AlfaRomeo33 = 409,
    AlfaRomeo4C = 410,
    AlfaRomeo75 = 411,
    AlfaRomeoBrera = 412,
    AlfaRomeoCrosswagonQ4 = 413,
    AlfaRomeoGiulia = 414,
    AlfaRomeoGiulietta = 415,
    AlfaRomeoGT = 416,
    AlfaRomeoGTV = 417,
    AlfaRomeoMiTo = 418,
    AlfaRomeoSpider = 419,
    AlfaRomeoSportwagonQ4 = 420,
    AlfaRomeoStelvio = 421,

    /* Alpina */
    AlpinaB3S = 501,
    AlpinaB5 = 502,
    AlpinaB6 = 503,
    AlpinaB7 = 504,
    AlpinaD3 = 505,
    AlpinaD5 = 506,

    /* ARO */
    ARO4x4 = 601,

    /* AstonMartin */
    AstonMartinDB7 = 701,
    AstonMartinDB9 = 702,
    AstonMartinDB9Volante = 703,
    AstonMartinDBS = 704,
    AstonMartinDBSVolante = 705,
    AstonMartinRapide = 706,
    AstonMartinV12 = 707,
    AstonMartinV12Vantage = 708,
    AstonMartinV8Vantage = 709,
    AstonMartinV8VantageRoadster = 710,
    AstonMartinValhalla = 711,
    AstonMartinValkyrie = 712,
    AstonMartinVanquish = 713,

    /* Audi */
    Audi100 = 801,
    Audi200 = 802,
    Audi80 = 803,
    Audi90 = 804,
    AudiA1 = 805,
    AudiA2 = 806,
    AudiA3 = 807,
    AudiA4 = 808,
    AudiA4allroad = 809,
    AudiA4Avant = 810,
    AudiA5 = 811,
    AudiA6 = 812,
    AudiA6allroad = 813,
    AudiA6Avant = 814,
    AudiA7 = 815,
    AudiA8 = 816,
    Audietron = 817,
    AudiQ2 = 818,
    AudiQ3 = 819,
    AudiQ5 = 820,
    AudiQ7 = 821,
    AudiQ8 = 822,
    AudiR8 = 823,
    AudiRSQ8 = 824,
    AudiRS2 = 825,
    AudiRS3 = 826,
    AudiRS4 = 827,
    AudiRS4Avant = 828,
    AudiRS5 = 829,
    AudiRS6 = 830,
    AudiRS7 = 831,
    AudiS3 = 832,
    AudiS4 = 833,
    AudiS5 = 834,
    AudiS6 = 835,
    AudiS7 = 836,
    AudiS8 = 837,
    AudiSQ5 = 838,
    AudiSQ7 = 839,
    AudiSQ8 = 840,
    AudiTT = 841,
    AudiTTS = 842,
    AudiV8 = 843,

    /* Austin */
    AustinMaestro = 901,
    AustinMetro = 902,
    AustinMini = 903,
    AustinMontego = 904,

    /* AustroFiat */
    AustroFiatDolomitC3 = 1001,

    /* AustroDaimler */
    AustroDaimlerADR = 1101,

    /* Auverland */

    /* Bellier */
    BellierDivane = 1301,
    BellierOpale = 1302,
    BellierVario = 1303,
    BellierVeloto = 1304,
    BellierVX = 1305,

    /* Bentley */
    BentleyArnage = 1401,
    BentleyAzureConvertible = 1402,
    BentleyBentayga = 1403,
    BentleyBrooklands = 1404,
    BentleyContinentalFlyingSpur = 1405,
    BentleyContinentalGT = 1406,
    BentleyContinentalGTC = 1407,
    BentleyContinentalR = 1408,
    BentleyContinentalT = 1409,
    BentleyMulsanne = 1410,
    BentleyTurboR = 1411,

    /* Bitter */
    BitterDiplomatCD = 1501,

    /* BMW */
    BMWAlpina = 1601,
    BMWi3 = 1602,
    BMWi8 = 1603,
    BMWM2 = 1604,
    BMWM3 = 1605,
    BMWM4 = 1606,
    BMWM5 = 1607,
    BMWM6 = 1608,
    BMWM8 = 1609,
    BMWRada1 = 1610,
    BMWRada2 = 1611,
    BMWRada3 = 1612,
    BMWRada4 = 1613,
    BMWRada5 = 1614,
    BMWRada6 = 1615,
    BMWRada7 = 1616,
    BMWRada8 = 1617,
    BMWX1 = 1618,
    BMWX2 = 1619,
    BMWX3 = 1620,
    BMWX4 = 1621,
    BMWX5 = 1622,
    BMWX6 = 1623,
    BMWX7 = 1624,
    BMWZ1 = 1625,
    BMWZ3 = 1626,
    BMWZ4 = 1627,
    BMWZ8 = 1628,
    BMWXM = 1632,

    /* Bugatti */
    BugattiChiron = 1701,
    BugattiVeyron = 1702,

    /* Buick */
    BuickCentury = 1801,
    BuickElectra = 1802,
    BuickEnclave = 1803,
    BuickLaCrosse = 1804,
    BuickLeSabre = 1805,
    BuickParkAvenue = 1806,
    BuickRainer = 1807,
    BuickRainier = 1808,
    BuickRandezvous = 1809,
    BuickRegal = 1810,
    BuickRiviera = 1811,
    BuickSkylark = 1812,

    /* Cadillac */
    CadillacATS = 1901,
    CadillacBLS = 1902,
    CadillacCatera = 1903,
    CadillacCT6 = 1904,
    CadillacCTS = 1905,
    CadillacDeVille = 1906,
    CadillacEldorado = 1907,
    CadillacEscalade = 1908,
    CadillacFleetwood = 1909,
    CadillacSeville = 1910,
    CadillacSRX = 1911,
    CadillacSTS = 1912,
    CadillacXLR = 1913,
    CadillacXT4 = 1914,
    CadillacXT5 = 1915,
    CadillacXTS = 1916,

    /* Casalini */
    CasaliniSulky = 2001,
    CasaliniYdea = 2002,

    /* Caterham */
    Caterham21 = 2101,
    CaterhamSuperSeven = 2102,

    /* Citroen */
    CitroenAX = 2201,
    CitroenBerlingo = 2202,
    CitroenBX = 2203,
    CitroenCCrosser = 2204,
    CitroenCElysee = 2205,
    CitroenCZero = 2206,
    CitroenC1 = 2207,
    CitroenC2 = 2208,
    CitroenC3 = 2209,
    CitroenC3Aircross = 2210,
    CitroenC3Picasso = 2211,
    CitroenC4 = 2212,
    CitroenC4Aircross = 2213,
    CitroenC4Cactus = 2214,
    CitroenC4Picasso = 2215,
    CitroenC4SpaceTourer = 2216,
    CitroenC5 = 2217,
    CitroenC5Aircross = 2218,
    CitroenC6 = 2219,
    CitroenC8 = 2220,
    CitroenCX = 2221,
    CitroenDS3 = 2222,
    CitroenDS3Cabrio = 2223,
    CitroenDS4 = 2224,
    CitroenDS5 = 2225,
    CitroenEvasion = 2226,
    CitroenGrandC4Picasso = 2227,
    CitroenGrandC4SpaceTourer = 2228,
    CitroenJumper = 2229,
    CitroenJumpy = 2230,
    CitroenNemo = 2231,
    CitroenSaxo = 2232,
    CitroenSpaceTourer = 2233,
    CitroenVisa = 2234,
    CitroenXantia = 2235,
    CitroenXM = 2236,
    CitroenXsara = 2237,
    CitroenXsaraPicasso = 2238,
    CitroenZX = 2239,

    /* Cupra */
    CupraArona = 2301,
    CupraAteca = 2302,
    CupraFormentor = 2303,
    CupraIbiza = 2304,
    CupraLeon = 2305,
    CupraTavascan = 2306,

    /* Dacia */
    Dacia1300 = 2401,
    Dacia1310 = 2402,
    Dacia1410 = 2403,
    DaciaDokker = 2404,
    DaciaDouble = 2405,
    DaciaDuster = 2406,
    DaciaLodgy = 2407,
    DaciaLogan = 2408,
    DaciaPickUp = 2409,
    DaciaSandero = 2410,
    DaciaSolenza = 2411,

    /* DAELIM */
    DAELIMXQ1125iABS = 2501,
    DAELIMXQ2250iABS = 2502,

    /* Daewoo */
    DaewooEspero = 2601,
    DaewooEvanda = 2602,
    DaewooChairman = 2603,
    DaewooKalos = 2604,
    DaewooLanos = 2605,
    DaewooLeganza = 2606,
    DaewooMatiz = 2607,
    DaewooNexia = 2608,
    DaewooNubira = 2609,
    DaewooRacer = 2610,
    DaewooTacuma = 2611,
    DaewooTico = 2612,

    /* Daihatsu */
    DaihatsuApplause = 2701,
    DaihatsuCopen = 2702,
    DaihatsuCuore = 2703,
    DaihatsuFeroza = 2704,
    DaihatsuGrandMovie = 2705,
    DaihatsuCharade = 2706,
    DaihatsuMateria = 2707,
    DaihatsuMove = 2708,
    DaihatsuRocky = 2709,
    DaihatsuSirion = 2710,
    DaihatsuTerios = 2711,
    DaihatsuTrevis = 2712,

    /* Dodge */
    DodgeAvenger = 2801,
    DodgeCaliber = 2802,
    DodgeCaravan = 2803,
    DodgeDakota = 2804,
    DodgeDurango = 2805,
    DodgeGrandCaravan = 2806,
    DodgeChallenger = 2807,
    DodgeCharger = 2808,
    DodgeIntrepid = 2809,
    DodgeJourney = 2810,
    DodgeMagnum = 2811,
    DodgeNeon = 2812,
    DodgeNitro = 2813,
    DodgeRam = 2814,
    DodgeRam1500 = 2815,
    DodgeSpirit = 2816,
    DodgeSRT4 = 2817,
    DodgeStealth = 2818,
    DodgeStratus = 2819,
    DodgeSX = 2820,
    DodgeViper = 2821,

    /* DR */
    DRDR5 = 2901,

    /* DS */
    DS3 = 3001,
    DS3Crossback = 3002,
    DS4 = 3003,
    DS4Crossback = 3004,
    DS5 = 3005,
    DS7Crossback = 3006,

    /* Eagle */
    EaglePremier = 3101,
    EagleSummit = 3102,
    EagleTalon = 3103,
    EagleVision = 3104,
    EagleVista = 3105,

    /* Ferrari */
    Ferrari246 = 3201,
    Ferrari250 = 3202,
    Ferrari288 = 3203,
    Ferrari328 = 3204,
    Ferrari330 = 3205,
    Ferrari348 = 3206,
    Ferrari360 = 3207,
    Ferrari456 = 3208,
    Ferrari458 = 3209,
    Ferrari512 = 3210,
    Ferrari550 = 3211,
    Ferrari550Maranello = 3212,
    Ferrari599 = 3213,
    Ferrari612 = 3214,
    Ferrari812 = 3215,
    FerrariCalifornia = 3216,
    FerrariEnzo = 3217,
    FerrariF355 = 3218,
    FerrariF430 = 3219,
    FerrariF12 = 3220,
    FerrariF40 = 3221,
    FerrariF50 = 3222,
    FerrariF8 = 3223,
    FerrariFF = 3224,
    FerrariGTC4Lusso = 3225,
    FerrariLaFerrari = 3226,
    FerrariMondial = 3227,
    FerrariPortofino = 3228,
    FerrariSF90 = 3229,
    FerrariTestarossa = 3230,

    /* Fiat */
    Fiat126 = 3301,
    Fiat127 = 3302,
    Fiat128 = 3303,
    Fiat131 = 3304,
    Fiat500Abarth = 3305,
    Fiat500E = 3306,
    Fiat500L = 3307,
    Fiat500X = 3308,
    Fiat600 = 3309,
    Fiat850 = 3310,
    FiatBarchetta = 3311,
    FiatBrava = 3312,
    FiatBravo = 3313,
    FiatCinquecento = 3314,
    FiatCroma = 3315,
    FiatDoblo = 3316,
    FiatDucato = 3317,
    FiatFiorino = 3318,
    FiatFiorinoCombi = 3319,
    FiatFreemont = 3320,
    FiatFullback = 3321,
    FiatGrandePunto = 3322,
    FiatIdea = 3323,
    FiatLinea = 3324,
    FiatMarea = 3325,
    FiatMultipla = 3326,
    FiatPalio = 3327,
    FiatPanda = 3328,
    FiatPuntoEVO = 3329,
    FiatQubo = 3330,
    FiatRegata = 3331,
    FiatRitmo = 3332,
    FiatScudo = 3333,
    FiatSedici = 3334,
    FiatSeicento = 3335,
    FiatSiena = 3336,
    FiatStilo = 3337,
    FiatTalento = 3338,
    FiatTempra = 3339,
    FiatTipo = 3340,
    FiatUlysse = 3341,
    FiatUno = 3342,
    FiatX19 = 3343,

    /* Fisker */
    FiskerKarma = 3401,

    /* Ford */
    FordBMAX = 3501,
    FordCMAX = 3502,
    FordCapri = 3503,
    FordConnect = 3504,
    FordContour = 3505,
    FordCougar = 3506,
    FordCrownVictoria = 3507,
    FordEcosport = 3508,
    FordEdge = 3509,
    FordEscape = 3510,
    FordEscort = 3511,
    FordExcursion = 3512,
    FordExpedition = 3513,
    FordExplorer = 3514,
    FordF150 = 3515,
    FordFiesta = 3516,
    FordFlex = 3517,
    FordFocus = 3518,
    FordFreestar = 3519,
    FordFreestyle = 3520,
    FordFusion = 3521,
    FordGalaxy = 3522,
    FordGranada = 3523,
    FordGrandCMAX = 3524,
    FordGrandMarquis = 3525,
    FordKa = 3526,
    FordKAplus = 3527,
    FordKuga = 3528,
    FordMarauder = 3529,
    FordMaverick = 3530,
    FordMondeo = 3531,
    FordMustang = 3532,
    FordOrion = 3533,
    FordProbe = 3534,
    FordPuma = 3535,
    FordRanger = 3536,
    FordSMAX = 3537,
    FordScorpio = 3538,
    FordSierra = 3539,
    FordStreetka = 3540,
    FordTaunus = 3541,
    FordTaurus = 3542,
    FordThunderbird = 3543,
    FordTourneoConnect = 3544,
    FordTourneoCourier = 3545,
    FordTourneoCustom = 3546,
    FordTransit = 3547,
    FordTransitConnect = 3548,
    FordTransitCustom = 3549,
    FordWindstar = 3550,

    /* GMC */
    GMCCanyon = 3601,
    GMCEnvoy = 3602,
    GMCJimmy = 3603,
    GMCSafari = 3604,
    GMCSavana = 3605,
    GMCSonoma = 3606,
    GMCSuburban = 3607,
    GMCYukon = 3608,

    /* Gonow */
    GonowGS2 = 3701,
    GonowGX6 = 3702,

    /* Gordon */
    GordonRoadster = 3801,

    /* GreatWall */
    GreatWallH5 = 3901,
    GreatWallHoverH5 = 3902,

    /* Grecav */
    GrecavEke = 4001,
    GrecavSonique = 4002,

    /* Holden */
    HoldenFX = 4101,

    /* Honda */
    HondaAccord = 4201,
    HondaCity = 4202,
    HondaCivic = 4203,
    HondaConcerto = 4204,
    HondaCRV = 4205,
    HondaCRZ = 4206,
    HondaCRX = 4207,
    Hondae = 4208,
    HondaElement = 4209,
    HondaFRV = 4210,
    HondaHRV = 4211,
    HondaInsight = 4212,
    HondaJazz = 4213,
    HondaLegend = 4214,
    HondaNSX = 4215,
    HondaOdyssey = 4216,
    HondaPilot = 4217,
    HondaPrelude = 4218,
    HondaRidgeline = 4219,
    HondaS2000 = 4220,
    HondaShuttle = 4221,
    HondaStream = 4222,

    /* Honker */
    Honker42 = 4301,

    /* Hummer */
    HummerH1 = 4401,
    HummerH1Alpha = 4402,
    HummerH2 = 4403,
    HummerH2SUT = 4404,
    HummerH3 = 4405,
    HummerH3Alpha = 4406,
    HummerH3T = 4407,
    HummerH3x = 4408,

    /* Hurtan */
    HurtanALBAYCIN = 4501,

    /* Hyundai */
    HyundaiAccent = 4601,
    HyundaiAtos = 4602,
    HyundaiElantra = 4603,
    HyundaiEquus = 4604,
    HyundaiGalloper = 4605,
    HyundaiGenesis = 4606,
    HyundaiGetz = 4607,
    HyundaiGrandeur = 4608,
    Hyundaii10 = 4609,
    Hyundaii20 = 4610,
    Hyundaii30 = 4611,
    Hyundaii40 = 4612,
    HyundaiIONIQ = 4613,
    Hyundaiix20 = 4614,
    Hyundaiix35 = 4615,
    Hyundaiix55 = 4616,
    HyundaiKona = 4617,
    HyundaiLantra = 4618,
    HyundaiMatrix = 4619,
    HyundaiNexo = 4620,
    HyundaiPalisade = 4621,
    HyundaiPony = 4622,
    HyundaiSantaFe = 4623,
    HyundaiSantamo = 4624,
    HyundaiScoupe = 4625,
    HyundaiSonata = 4626,
    HyundaiTerracan = 4627,
    HyundaiTrajet = 4628,
    HyundaiTucson = 4629,
    HyundaiVeloster = 4630,
    HyundaiXG = 4631,

    /* Changhe */
    ChangheCoolcar = 4701,
    ChangheFreedom = 4702,

    /* Chatenet */
    ChatenetBarooder = 4801,
    ChatenetCH26 = 4802,
    ChatenetMedia = 4803,
    ChatenetSpeedino = 4804,
    ChatenetStella = 4805,

    /* Chevrolet */
    ChevroletAlero = 4901,
    ChevroletAstro = 4902,
    ChevroletAstroVan = 4903,
    ChevroletAvalanche = 4904,
    ChevroletAveo = 4905,
    ChevroletBeretta = 4906,
    ChevroletBlazer = 4907,
    ChevroletCamaro = 4908,
    ChevroletCaprice = 4909,
    ChevroletCaptiva = 4910,
    ChevroletCavalier = 4911,
    ChevroletCobalt = 4912,
    ChevroletColorado = 4913,
    ChevroletCorvette = 4914,
    ChevroletCruze = 4915,
    ChevroletElCamino = 4916,
    ChevroletEpica = 4917,
    ChevroletEquinox = 4918,
    ChevroletHHR = 4919,
    ChevroletImpala = 4920,
    ChevroletLacetti = 4921,
    ChevroletLumina = 4922,
    ChevroletMalibu = 4923,
    ChevroletMonteCarlo = 4924,
    ChevroletNiva = 4925,
    ChevroletOptra = 4926,
    ChevroletOrlando = 4927,
    ChevroletPrizm = 4928,
    ChevroletRezzo = 4929,
    ChevroletS10 = 4930,
    ChevroletSilverado = 4931,
    ChevroletSpark = 4932,
    ChevroletTahoe = 4933,
    ChevroletTracker = 4934,
    ChevroletTrail = 4935,
    ChevroletTrailblazer = 4936,
    ChevroletTransSport = 4937,
    ChevroletTrax = 4938,
    ChevroletVenture = 4939,

    /* Chrysler */
    Chrysler300C = 5001,
    Chrysler300M = 5002,
    ChryslerCirrus = 5003,
    ChryslerConcorde = 5004,
    ChryslerCrossfire = 5005,
    ChryslerES = 5006,
    ChryslerGrandVoyager = 5007,
    ChryslerGTS = 5008,
    ChryslerLeBaronCabrio = 5009,
    ChryslerLHS = 5010,
    ChryslerNewYorker = 5011,
    ChryslerPacifica = 5012,
    ChryslerPTCruiser = 5013,
    ChryslerSaratoga = 5014,
    ChryslerSebring = 5015,
    ChryslerTownCountry = 5016,
    ChryslerVoyager = 5017,

    /* Infiniti */
    InfinitiEX30 = 5101,
    InfinitiEX35 = 5102,
    InfinitiEX37 = 5103,
    InfinitiFX30 = 5104,
    InfinitiFX35 = 5105,
    InfinitiFX37 = 5106,
    InfinitiFX45 = 5107,
    InfinitiFX50 = 5108,
    InfinitiG = 5109,
    InfinitiG20 = 5110,
    InfinitiG35 = 5111,
    InfinitiG37 = 5112,
    InfinitiI30 = 5113,
    InfinitiI35 = 5114,
    InfinitiM30 = 5115,
    InfinitiM35 = 5116,
    InfinitiM37 = 5117,
    InfinitiM45 = 5118,
    InfinitiQ30 = 5119,
    InfinitiQ45 = 5120,
    InfinitiQ50 = 5121,
    InfinitiQ60 = 5122,
    InfinitiQ70 = 5123,
    InfinitiQX30 = 5124,
    InfinitiQX4 = 5125,
    InfinitiQX50 = 5126,
    InfinitiQX56 = 5127,
    InfinitiQX70 = 5128,
    InfinitiQX80 = 5129,

    /* Isuzu */
    IsuzuDMax = 5201,
    IsuzuGemini = 5202,
    IsuzuPiazza = 5203,
    IsuzuTrooper = 5204,

    /* Italcar */
    ItalcarT3 = 5301,

    /* Iveco */
    IvecoMassif = 5401,

    /* JAC */
    JACIEV7S = 5501,

    /* Jaguar */
    JaguarDaimler = 5601,
    JaguarEPace = 5602,
    JaguarFPace = 5603,
    JaguarFType = 5604,
    JaguarIPace = 5605,
    JaguarSType = 5606,
    JaguarXType = 5607,
    JaguarXE = 5608,
    JaguarXF = 5609,
    JaguarXJ = 5610,
    JaguarXJ6 = 5611,
    JaguarXJ8 = 5612,
    JaguarXJR = 5613,
    JaguarXJS = 5614,
    JaguarXK = 5615,
    JaguarXK8 = 5616,
    JaguarXKR = 5617,

    /* JDM */
    JDMAbaca = 5701,
    JDMAlbizia = 5702,
    JDMAloes = 5703,
    JDMOrane = 5704,
    JDMTitane = 5705,

    /* Jeep */
    JeepCommander = 5801,
    JeepCompass = 5802,
    JeepGladiator = 5803,
    JeepGrandCherokee = 5804,
    JeepCherokee = 5805,
    JeepLiberty = 5806,
    JeepPatriot = 5807,
    JeepRenegade = 5808,
    JeepScrambler = 5809,
    JeepWrangler = 5810,
    JeepWranglerRubicon = 5811,
    JeepWranglerSahara = 5812,

    /* Kaipan */
    Kaipan47 = 5901,

    /* Kia */
    KiaCarens = 6001,
    KiaCarnival = 6002,
    KiaCeed = 6003,
    KiaCerato = 6004,
    KiaClarus = 6005,
    KiaeNiro = 6006,
    KiaeSoul = 6007,
    KiaJoice = 6008,
    KiaMagentis = 6009,
    KiaNiro = 6010,
    KiaOpirus = 6011,
    KiaOptima = 6012,
    KiaPicanto = 6013,
    KiaPride = 6014,
    KiaPro_ceed = 6015,
    KiaProCeed = 6016,
    KiaRetona = 6017,
    KiaRio = 6018,
    KiaSephia = 6019,
    KiaShuma = 6020,
    KiaSorento = 6021,
    KiaSoul = 6022,
    KiaSportage = 6023,
    KiaStinger = 6024,
    KiaStonic = 6025,
    KiaTelluride = 6026,
    KiaVenga = 6027,
    KiaXCEED = 6028,

    /* Koenigsegg */
    KoenigseggJesko = 6101,
    KoenigseggRegera = 6102,

    /* Lada */
    Lada1200 = 6201,
    Lada1500 = 6202,
    Lada2101 = 6203,
    Lada2102 = 6204,
    Lada2103 = 6205,
    Lada2104 = 6206,
    Lada21044 = 6207,
    Lada2105 = 6208,
    Lada2106 = 6209,
    Lada2107 = 6210,
    Lada2110 = 6211,
    Lada2111 = 6212,
    Lada2112 = 6213,
    LadaGranta = 6214,
    LadaKalina = 6215,
    LadaLargus = 6216,
    LadaNova = 6217,
    LadaSamara = 6218,
    LadaVesta = 6219,
    LadaXray = 6220,

    /* Lamborghini */
    LamborghiniAventador = 6301,
    LamborghiniCountach = 6302,
    LamborghiniGallardo = 6303,
    LamborghiniHuracan = 6304,
    LamborghiniMiura = 6305,
    LamborghiniMurcielago = 6306,
    LamborghiniUrus = 6307,

    /* Lancia */
    LanciaDedra = 6401,
    LanciaDelta = 6402,
    LanciaFlavia = 6403,
    LanciaKappa = 6404,
    LanciaLybra = 6405,
    LanciaMusa = 6406,
    LanciaPhedra = 6407,
    LanciaPrisma = 6408,
    LanciaThema = 6409,
    LanciaThesis = 6410,
    LanciaY = 6411,
    LanciaZ = 6412,

    /* LandRover */
    LandRoverDefender = 6501,
    LandRoverDiscovery = 6502,
    LandRoverDiscoverySport = 6503,
    LandRoverFreelander = 6504,
    LandRoverRangeRover = 6505,
    LandRoverRangeRoverEvoque = 6506,
    LandRoverRangeRoverSport = 6507,
    LandRoverRangeRoverVelar = 6508,
    LandRoverSeriesIII = 6509,

    /* Lexus */
    Lexus300 = 6601,
    LexusCT200h = 6602,
    LexusES300h = 6603,
    LexusES330 = 6604,
    LexusGS = 6605,
    LexusGS250 = 6606,
    LexusGS300 = 6607,
    LexusGS300h = 6608,
    LexusGS430 = 6609,
    LexusGS450 = 6610,
    LexusGS450h = 6611,
    LexusGS460 = 6612,
    LexusGSF = 6613,
    LexusGX460 = 6614,
    LexusGX470 = 6615,
    LexusIS = 6616,
    LexusIS200 = 6617,
    LexusIS200t = 6618,
    LexusIS220d = 6619,
    LexusIS250 = 6620,
    LexusIS250C = 6621,
    LexusIS300 = 6622,
    LexusIS300h = 6623,
    LexusIS350 = 6624,
    LexusISF = 6625,
    LexusLC500 = 6626,
    LexusLC500h = 6627,
    LexusLS = 6628,
    LexusLS400 = 6629,
    LexusLS430 = 6630,
    LexusLS460 = 6631,
    LexusLS500 = 6632,
    LexusLS500h = 6633,
    LexusLS600h = 6634,
    LexusLS600hL = 6635,
    LexusLX460 = 6636,
    LexusLX470 = 6637,
    LexusLX570 = 6638,
    LexusNX200t = 6639,
    LexusNX300h = 6640,
    LexusRC = 6641,
    LexusRC200t = 6642,
    LexusRC300h = 6643,
    LexusRCF = 6644,
    LexusRX = 6645,
    LexusRX200t = 6646,
    LexusRX300 = 6647,
    LexusRX330 = 6648,
    LexusRX350 = 6649,
    LexusRX400 = 6650,
    LexusRX400h = 6651,
    LexusRX450h = 6652,
    LexusRX450hL = 6653,
    LexusSC = 6654,
    LexusSC400 = 6655,
    LexusSC430 = 6656,
    LexusUX200 = 6657,
    LexusUX250h = 6658,

    /* Ligier */
    LigierAmbra = 6701,
    LigierJS50 = 6702,
    LigierJS50L = 6703,
    LigierJS60 = 6704,
    LigierJSRC = 6705,
    LigierXTooMax = 6706,

    /* Lincoln */
    LincolnAviator = 6801,
    LincolnContinental = 6802,
    LincolnMKS = 6803,
    LincolnMKT = 6804,
    LincolnMKX = 6805,
    LincolnMKZ = 6806,
    LincolnNavigator = 6807,
    LincolnTownCar = 6808,

    /* Lotus */
    LotusElise = 6901,
    LotusEsprit = 6902,

    /* Marcos */
    MarcosLM = 7001,
    MarcosMantara = 7002,
    MarcosMantaray = 7003,
    MarcosMantis = 7004,

    /* MartinMotors */
    MartinMotors520 = 7101,
    MartinMotors520i = 7102,
    MartinMotorsCEO = 7103,
    MartinMotorsCOOLCAR = 7104,
    MartinMotorsFREEDOM = 7105,

    /* Maruti */
    MarutiMaruti800 = 7201,

    /* Maserati */
    Maserati3200 = 7301,
    MaseratiGhibli = 7302,
    MaseratiGranCabrio = 7303,
    MaseratiGranTurismo = 7304,
    MaseratiLevante = 7305,
    MaseratiQuattroporte = 7306,

    /* Mazda */
    Mazda121 = 7401,
    Mazda2 = 7402,
    Mazda323 = 7403,
    Mazda6 = 7404,
    Mazda626 = 7405,
    Mazda929 = 7406,
    MazdaBT = 7407,
    MazdaBT50 = 7408,
    MazdaCX3 = 7409,
    MazdaCX30 = 7410,
    MazdaCX5 = 7411,
    MazdaCX7 = 7412,
    MazdaCX9 = 7413,
    MazdaDemio = 7414,
    MazdaMPV = 7415,
    MazdaMX = 7416,
    MazdaMX3 = 7417,
    MazdaMX30 = 7418,
    MazdaMX5 = 7419,
    MazdaMX6 = 7420,
    MazdaPremacy = 7421,
    MazdaRX7 = 7422,
    MazdaRX8 = 7423,
    MazdaTribute = 7424,
    MazdaXedos = 7425,

    /* MCC */
    MCCSmart = 7501,

    /* McLaren */
    McLaren12C = 7601,
    McLaren540 = 7602,
    McLaren570 = 7603,
    McLaren600LT = 7604,
    McLaren650SCoupe = 7605,
    McLaren650SSpider = 7606,
    McLaren675LT = 7607,
    McLaren720S = 7608,
    McLarenElva = 7609,
    McLarenF1 = 7610,
    McLarenMP412C = 7611,
    McLarenP1 = 7612,
    McLarenSenna = 7613,

    /* MercedesBenz */
    MercedesBenz115 = 7701,
    MercedesBenz116 = 7702,
    MercedesBenz123 = 7703,
    MercedesBenz124 = 7704,
    MercedesBenz140 = 7705,
    MercedesBenz170 = 7706,
    MercedesBenz190 = 7707,
    MercedesBenz20 = 7708,
    MercedesBenz208 = 7709,
    MercedesBenz209 = 7710,
    MercedesBenz210 = 7711,
    MercedesBenz211 = 7712,
    MercedesBenz215 = 7713,
    MercedesBenz220 = 7714,
    MercedesBenzAMGGT = 7715,
    MercedesBenzAMGOne = 7716,
    MercedesBenzCitan = 7717,
    MercedesBenzCLA = 7718,
    MercedesBenzCLC = 7719,
    MercedesBenzCLK = 7720,
    MercedesBenzCLS = 7721,
    MercedesBenzEQC = 7722,
    MercedesBenzEQV = 7723,
    MercedesBenzGL = 7724,
    MercedesBenzGL320 = 7725,
    MercedesBenzGL350 = 7726,
    MercedesBenzGLA = 7727,
    MercedesBenzGLB = 7728,
    MercedesBenzGLC = 7729,
    MercedesBenzGLE = 7730,
    MercedesBenzGLK = 7731,
    MercedesBenzGLS = 7732,
    MercedesBenzMaybach = 7733,
    MercedesBenzSL = 7734,
    MercedesBenzSLC = 7735,
    MercedesBenzSLK = 7736,
    MercedesBenzSLR = 7737,
    MercedesBenzSLSAMG = 7738,
    MercedesBenzSprinter = 7739,
    MercedesBenzTridyA = 7740,
    MercedesBenzTridyB = 7741,
    MercedesBenzTridyC = 7742,
    MercedesBenzTridyE = 7743,
    MercedesBenzTridyG = 7744,
    MercedesBenzTridyM = 7745,
    MercedesBenzTridyR = 7746,
    MercedesBenzTridyS = 7747,
    MercedesBenzTridyV = 7748,
    MercedesBenzTridyX = 7749,
    MercedesBenzVaneo = 7750,
    MercedesBenzViano = 7751,
    MercedesBenzVito = 7752,

    /* Mercury */
    MercuryMariner = 7801,
    MercuryMoutaineer = 7802,
    MercuryMystique = 7803,
    MercurySable = 7804,
    MercuryVillager = 7805,

    /* MG */
    MGF = 7901,
    MGTF = 7902,
    MGZR = 7903,
    MGZS = 7904,
    MGZT = 7905,

    /* Microcar */
    MicrocarDUE = 8001,
    MicrocarLyra = 8002,
    MicrocarMGO = 8003,
    MicrocarMC1 = 8004,
    MicrocarMC2 = 8005,
    MicrocarVirgo = 8006,

    /* Mini */
    MiniClubman = 8101,
    MiniCooper = 8102,
    MiniCooperS = 8103,
    MiniCountryman = 8104,
    MiniNewMini = 8105,
    MiniOne = 8106,
    MiniPaceman = 8107,

    /* Mitsubishi */
    Mitsubishi3000GT = 8201,
    MitsubishiASX = 8202,
    MitsubishiCarisma = 8203,
    MitsubishiColt = 8204,
    MitsubishiEclipse = 8205,
    MitsubishiEclipseCross = 8206,
    MitsubishiGalant = 8207,
    MitsubishiGrandis = 8208,
    MitsubishiiMiEV = 8209,
    MitsubishiL200 = 8210,
    MitsubishiLancer = 8211,
    MitsubishiOutlander = 8212,
    MitsubishiPajero = 8213,
    MitsubishiPajeroPinin = 8214,
    MitsubishiPajeroSport = 8215,
    MitsubishiSigma = 8216,
    MitsubishiSpace = 8217,
    MitsubishiSpaceStar = 8218,
    MitsubishiSpaceWagon = 8219,

    /* Moskvic */
    Moskvic2140 = 8301,
    Moskvic408 = 8302,
    Moskvic412 = 8303,
    MoskvicAleko = 8304,

    /* MTX */
    MTXCabrio = 8401,

    /* Nissan */
    Nissan100NX = 8501,
    Nissan200SX = 8502,
    Nissan240SX = 8503,
    Nissan280ZX = 8504,
    Nissan300ZX = 8505,
    Nissan350Z = 8506,
    Nissan370Z = 8507,
    NissanAlmera = 8508,
    NissanAlmeraTino = 8509,
    NissanAltima = 8510,
    NissanArmada = 8511,
    NissanBluebird = 8512,
    NissanCube = 8513,
    NissanDoublecab = 8514,
    NissaneNV200 = 8515,
    NissanEvalia = 8516,
    NissanFrontier = 8517,
    NissanGTR = 8518,
    NissanCherry = 8519,
    NissanJuke = 8520,
    NissanKingCab = 8521,
    NissanLaurel = 8522,
    NissanLEAF = 8523,
    NissanMaxima = 8524,
    NissanMicra = 8525,
    NissanMurano = 8526,
    NissanNavara = 8527,
    NissanNote = 8528,
    NissanNV200 = 8529,
    NissanNV300 = 8530,
    NissanPathfinder = 8531,
    NissanPathfinderArmada = 8532,
    NissanPatrol = 8533,
    NissanPixo = 8534,
    NissanPrairie = 8535,
    NissanPrimastar = 8536,
    NissanPrimera = 8537,
    NissanPulsar = 8538,
    NissanQashqai = 8539,
    NissanQuest = 8540,
    NissanRogue = 8541,
    NissanSentra = 8542,
    NissanSerena = 8543,
    NissanSilvia = 8544,
    NissanStanza = 8545,
    NissanSunny = 8546,
    NissanTerrano = 8547,
    NissanTerranoII = 8548,
    NissanTiida = 8549,
    NissanTitan = 8550,
    NissanXTrail = 8551,
    NissanXterra = 8552,

    /* Oldsmobile */
    OldsmobileAurora = 8601,
    OldsmobileBravada = 8602,
    OldsmobileCutlass = 8603,
    OldsmobileIntrique = 8604,
    OldsmobileSilhouette = 8605,

    /* Oltcit */
    OltcitClub = 8701,

    /* Opel */
    OpelAdam = 8801,
    OpelAgila = 8802,
    OpelAmpera = 8803,
    OpelAntara = 8804,
    OpelAscona = 8805,
    OpelAstra = 8806,
    OpelCalibra = 8807,
    OpelCascada = 8808,
    OpelCombo = 8809,
    OpelCorsa = 8810,
    OpelCrossland = 8811,
    OpelCrosslandX = 8812,
    OpelFrontera = 8813,
    OpelGrandlandX = 8814,
    OpelInsignia = 8815,
    OpelKadett = 8816,
    OpelManta = 8817,
    OpelMeriva = 8818,
    OpelMokka = 8819,
    OpelMonterey = 8820,
    OpelMonza = 8821,
    OpelOmega = 8822,
    OpelRekord = 8823,
    OpelSenator = 8824,
    OpelSignum = 8825,
    OpelSintra = 8826,
    OpelSpeedster = 8827,
    OpelTigra = 8828,
    OpelVectra = 8829,
    OpelVivaro = 8830,
    OpelZafira = 8831,

    /* Pagani */
    PaganiHuayra = 8901,
    PaganiZonda = 8902,

    /* Peugeot */
    Peugeot1007 = 9001,
    Peugeot106 = 9002,
    Peugeot107 = 9003,
    Peugeot108 = 9004,
    Peugeot2008 = 9005,
    Peugeot205 = 9006,
    Peugeot206 = 9007,
    Peugeot207 = 9008,
    Peugeot3008 = 9009,
    Peugeot301 = 9010,
    Peugeot305 = 9011,
    Peugeot306 = 9012,
    Peugeot307 = 9013,
    Peugeot308 = 9014,
    Peugeot309 = 9015,
    Peugeot4007 = 9016,
    Peugeot4008 = 9017,
    Peugeot405 = 9018,
    Peugeot406 = 9019,
    Peugeot407 = 9020,
    Peugeot5008 = 9021,
    Peugeot505 = 9022,
    Peugeot508 = 9023,
    Peugeot605 = 9024,
    Peugeot607 = 9025,
    Peugeot608 = 9026,
    Peugeot806 = 9027,
    Peugeot807 = 9028,
    PeugeotBipperTepee = 9029,
    PeugeotBoxer = 9030,
    PeugeotExpert = 9031,
    PeugeotExpertTepee = 9032,
    PeugeotIon = 9033,
    PeugeotPartner = 9034,
    PeugeotPartnerTepee = 9035,
    PeugeotRCZ = 9036,
    PeugeotRifter = 9037,
    PeugeotTraveller = 9038,

    /* Plymouth */
    PlymouthBreeze = 9101,
    PlymouthProwler = 9102,

    /* Polestar */
    Polestar1 = 9201,

    /* PolskiFiat */
    PolskiFiat125P = 9301,
    PolskiFiat126P = 9302,
    PolskiFiat650E = 9303,
    PolskiFiatFSO1500 = 9304,
    PolskiFiatPolonez = 9305,

    /* Pontiac */
    PontiacAztek = 9401,
    PontiacBonneville = 9402,
    PontiacFirebird = 9403,
    PontiacFirefly = 9404,
    PontiacG6 = 9405,
    PontiacGrandAm = 9406,
    PontiacGrandPrix = 9407,
    PontiacMontana = 9408,
    PontiacSunfire = 9409,
    PontiacVibe = 9410,

    /* Porsche */
    Porsche718BoxsterGTS = 9501,
    Porsche911 = 9502,
    Porsche918Spyder = 9503,
    Porsche924 = 9504,
    Porsche928 = 9505,
    Porsche944 = 9506,
    Porsche968 = 9507,
    Porsche981BoxsterS = 9508,
    Porsche986Boxster = 9509,
    Porsche987Boxster = 9510,
    PorscheBoxster = 9511,
    PorscheCarreraGT = 9512,
    PorscheCayenne = 9513,
    PorscheCayman = 9514,
    PorscheMacan = 9515,
    PorschePanamera = 9516,
    PorscheTaycan = 9517,

    /* Proton */
    Proton315 = 9601,
    Proton400 = 9602,
    Proton413 = 9603,
    Proton415 = 9604,
    Proton416 = 9605,
    Proton418 = 9606,
    Proton420 = 9607,

    /* RaytonFissore */

    /* Renault */
    RenaultAlaskan = 9801,
    RenaultAlpine = 9802,
    RenaultAvantime = 9803,
    RenaultCaptur = 9804,
    RenaultClio = 9805,
    RenaultEspace = 9806,
    RenaultFluence = 9807,
    RenaultFuego = 9808,
    RenaultGrandEspace = 9809,
    RenaultGrandScenic = 9810,
    RenaultKadjar = 9811,
    RenaultKangoo = 9812,
    RenaultKoleos = 9813,
    RenaultLaguna = 9814,
    RenaultLatitude = 9815,
    RenaultMaster = 9816,
    RenaultMegane = 9817,
    RenaultModus = 9818,
    RenaultR11 = 9819,
    RenaultR18 = 9820,
    RenaultR19 = 9821,
    RenaultR20 = 9822,
    RenaultR21 = 9823,
    RenaultR25 = 9824,
    RenaultR4 = 9825,
    RenaultR5 = 9826,
    RenaultR9 = 9827,
    RenaultSafrane = 9828,
    RenaultScenic = 9829,
    RenaultTalisman = 9830,
    RenaultThalia = 9831,
    RenaultTrafic = 9832,
    RenaultTwingo = 9833,
    RenaultTwizy = 9834,
    RenaultVelSatis = 9835,
    RenaultWind = 9836,
    RenaultZOE = 9837,

    /* RollsRoyce */
    RollsRoyceCullinan = 9901,
    RollsRoyceDawn = 9902,
    RollsRoyceGhost = 9903,
    RollsRoycePark = 9904,
    RollsRoycePhantom = 9905,
    RollsRoyceSilverDawn = 9906,
    RollsRoyceSilverSpirit = 9907,
    RollsRoyceTouringLimousine = 9908,
    RollsRoyceWraith = 9909,

    /* Rover */
    Rover25 = 10001,
    Rover45 = 10002,
    Rover800 = 10003,
    RoverStreetwise = 10004,

    /* Saab */
    Saab93 = 10101,
    Saab95 = 10102,
    Saab900 = 10103,
    Saab9000 = 10104,
    Saab99 = 10105,

    /* Santana */
    SantanaPS10 = 10201,
    SantanaPS300 = 10202,
    SantanaPS350 = 10203,

    /* Saturn */
    SaturnL300 = 10301,
    SaturnSC1 = 10302,
    SaturnSC2 = 10303,
    SaturnSL1 = 10304,
    SaturnSL2 = 10305,
    SaturnSW1 = 10306,
    SaturnSW2 = 10307,
    SaturnVue = 10308,

    /* Scion */
    ScionbbX = 10401,
    ScionccX = 10402,
    ScioniQ = 10403,
    SciontC = 10404,

    /* Seat */
    SeatAlhambra = 10501,
    SeatAltea = 10502,
    SeatArosa = 10503,
    SeatCordoba = 10504,
    SeatExeo = 10505,
    SeatInca = 10506,
    SeatMalaga = 10507,
    SeatMarbella = 10508,
    SeatMii = 10509,
    SeatTarraco = 10510,
    SeatToledo = 10511,

    /* Shuanghuan */

    /* Smart */
    SmartElectricDrive = 10701,
    SmartForfour = 10702,
    SmartFortwo = 10703,
    SmartMicrocompact = 10704,

    /* SsangYong */
    SsangYongActyon = 10801,
    SsangYongKorando = 10802,
    SsangYongKyron = 10803,
    SsangYongMusso = 10804,
    SsangYongRexton = 10805,
    SsangYongTivoli = 10806,
    SsangYongXLV = 10807,

    /* Subaru */
    Subaru1800 = 10901,
    SubaruAscent = 10902,
    SubaruBaja = 10903,
    SubaruBRZ = 10904,
    SubaruForester = 10905,
    SubaruImpreza = 10906,
    SubaruJusty = 10907,
    SubaruLegacy = 10908,
    SubaruLevorg = 10909,
    SubaruOutback = 10910,
    SubaruStationwagon = 10911,
    SubaruSVX = 10912,
    SubaruTrezia = 10913,
    SubaruTribeca = 10914,
    SubaruVivio = 10915,
    SubaruWRXSTI = 10916,
    SubaruXV = 10917,

    /* Suzuki */
    Suzuki750S = 11001,
    SuzukiAcross = 11002,
    SuzukiAlto = 11003,
    SuzukiBaleno = 11004,
    SuzukiCarry = 11005,
    SuzukiCelerio = 11006,
    SuzukiGrandVitara = 11007,
    SuzukiIgnis = 11008,
    SuzukiJimny = 11009,
    SuzukiKizashi = 11010,
    SuzukiLiana = 11011,
    SuzukiSCross = 11012,
    SuzukiSamurai = 11013,
    SuzukiSantana = 11014,
    SuzukiSplash = 11015,
    SuzukiSupperCarry = 11016,
    SuzukiSwace = 11017,
    SuzukiSwift = 11018,
    SuzukiSX4 = 11019,
    SuzukiSX4SCross = 11020,
    SuzukiVitara = 11021,
    SuzukiWagonR = 11022,

    /* Skoda */
    Skoda1000MB = 11101,
    Skoda105 = 11102,
    Skoda110 = 11103,
    Skoda120 = 11104,
    Skoda125 = 11105,
    Skoda130 = 11106,
    Skoda450Roadster = 11107,
    Skoda860 = 11108,
    SkodaCitigo = 11109,
    SkodaEnyaq = 11110,
    SkodaFabia = 11111,
    SkodaFavorit = 11112,
    SkodaFelicia = 11113,
    SkodaForman = 11114,
    SkodaKamiq = 11115,
    SkodaKaroq = 11116,
    SkodaKodiaq = 11117,
    SkodaOctavia = 11118,
    SkodaRapid = 11119,
    SkodaRoomster = 11120,
    SkodaScala = 11121,
    SkodaSuperb = 11122,
    SkodaYeti = 11123,

    /* Tata */

    /* Tatra */
    TatraT603 = 11301,
    TatraT613 = 11302,
    TatraT700 = 11303,

    /* Tavria */
    TavriaTavria = 11401,

    /* Tesla */
    TeslaModel3 = 11501,
    TeslaModelS = 11502,
    TeslaModelX = 11503,

    /* Toyota */
    Toyota4Runner = 11601,
    ToyotaAuris = 11602,
    ToyotaAvalon = 11603,
    ToyotaAvensis = 11604,
    ToyotaAvensisVerso = 11605,
    ToyotaAygo = 11606,
    ToyotaCHR = 11607,
    ToyotaCamry = 11608,
    ToyotaCarina = 11609,
    ToyotaCelica = 11610,
    ToyotaCorolla = 11611,
    ToyotaCorollaVerso = 11612,
    ToyotaEcho = 11613,
    ToyotaFJCruiser = 11614,
    ToyotaFortuner = 11615,
    ToyotaGT86 = 11616,
    ToyotaHighlander = 11617,
    ToyotaHilux = 11618,
    ToyotaLandCruiser = 11619,
    ToyotaMR2 = 11620,
    ToyotaPaseo = 11621,
    ToyotaPicnic = 11622,
    ToyotaPrevia = 11623,
    ToyotaPrius = 11624,
    ToyotaProace = 11625,
    ToyotaProaceCity = 11626,
    ToyotaProaceCityVerso = 11627,
    ToyotaRav4 = 11628,
    ToyotaRunner = 11629,
    ToyotaSequoia = 11630,
    ToyotaSienna = 11631,
    ToyotaSolara = 11632,
    ToyotaStarlet = 11633,
    ToyotaSupra = 11634,
    ToyotaTacoma = 11635,
    ToyotaTundra = 11636,
    ToyotaUrbanCruiser = 11637,
    ToyotaVenza = 11638,
    ToyotaVerso = 11639,
    ToyotaYaris = 11640,

    /* Trabant */
    Trabant601S = 11701,
    TrabantKombi = 11702,

    /* TVR */
    TVRCerbera = 11801,

    /* UAZ */
    UAZ3160 = 11901,

    /* Ultima */
    UltimaCanAm = 12001,

    /* Volkswagen */
    VolkswagenAmarok = 12101,
    VolkswagenArteon = 12102,
    VolkswagenAtlas = 12103,
    VolkswagenBeetle = 12104,
    VolkswagenBora = 12105,
    VolkswagenCaddy = 12106,
    VolkswagenCaravelle = 12107,
    VolkswagenCarmanGhia = 12108,
    VolkswagenCC = 12109,
    VolkswagenCorrado = 12110,
    VolkswagenEos = 12111,
    VolkswagenFox = 12112,
    VolkswagenGolf = 12113,
    VolkswagenGolfPlus = 12114,
    VolkswagenGolfSportsvan = 12115,
    VolkswagenID3 = 12116,
    VolkswagenID4 = 12117,
    VolkswagenJetta = 12118,
    VolkswagenKarmannGhia = 12119,
    VolkswagenLupo = 12120,
    VolkswagenMultivan = 12121,
    VolkswagenNewBeetle = 12122,
    VolkswagenPassat = 12123,
    VolkswagenPassatCC = 12124,
    VolkswagenPhaeton = 12125,
    VolkswagenPolo = 12126,
    VolkswagenRoutan = 12127,
    VolkswagenScirocco = 12128,
    VolkswagenSharan = 12129,
    VolkswagenTCross = 12130,
    VolkswagenTRoc = 12131,
    VolkswagenTiguan = 12132,
    VolkswagenTiguanAllspace = 12133,
    VolkswagenTouareg = 12134,
    VolkswagenTouran = 12135,
    VolkswagenTransporter = 12136,
    VolkswagenUp = 12137,
    VolkswagenVento = 12138,

    /* Volvo */
    Volvo240 = 12201,
    Volvo241 = 12202,
    Volvo242 = 12203,
    Volvo245 = 12204,
    Volvo440 = 12205,
    Volvo460 = 12206,
    Volvo480 = 12207,
    Volvo740 = 12208,
    Volvo940 = 12209,
    Volvo960 = 12210,
    VolvoC30 = 12211,
    VolvoC70 = 12212,
    VolvoS40 = 12213,
    VolvoS60 = 12214,
    VolvoS70 = 12215,
    VolvoS80 = 12216,
    VolvoS90 = 12217,
    VolvoV40 = 12218,
    VolvoV50 = 12219,
    VolvoV60 = 12220,
    VolvoV70 = 12221,
    VolvoV90 = 12222,
    VolvoXC40 = 12223,
    VolvoXC60 = 12224,
    VolvoXC70 = 12225,
    VolvoXC90 = 12226,

    /* Wartburg */
    Wartburg353 = 12301,

    /* Yugo */
    Yugo55 = 12401,
    YugoFlorida = 12402,
    YugoKoral = 12403,

    /* Zastava */
    Zastava1100 = 12501,

    /* ZhiDou */
    ZhiDouD1 = 12601,
    ZhiDouD2 = 12602,
    ZhiDouD2S = 12603,

    /* Dongfeng */
    DongfengDF6 = 12701,
    DongfengFengon5 = 12702,
    DongfengGlory500 = 12703,
    DongfengShine = 12704,
    DongfengT5EVO = 12705,
    DongfengT5EVOEV = 12706,
    DongfengUTour = 12707,
    DongfengGlory580 = 12708,
    DongfengSeres3 = 12709,
    DongfengVohyahFree = 12710,

}