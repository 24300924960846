import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import UserApiService from "../../../../Services/UserApiService";
import { PageTitleBox } from "../../../Others/PageTitleBox";
import { SectionTitle } from "../../../Others/SectionTitle";
import CurrentUserStore from "../../../Stores/CurrentUserStore";
import InputText from "../../../UI/Input/InputText";
import CustomDocumentTitle from "../../../Utils/CustomDocumentTitle";

interface IProps {
    currentUserStore?: CurrentUserStore,
}

const userApiService = new UserApiService();

export const ExportFeedPage = inject('currentUserStore')(observer((props: IProps) => {

    useEffect(() => {
        return () => {
            userApiService.cancelRequests();
        }
    }, [])

    const user = props.currentUserStore.user;

    return (
        <>
            <CustomDocumentTitle title="Exportní feed">
                <PageTitleBox title="EXPORTNÍ FEED" />

                <div className="card">
                    <div className="card-body">
                        <p>Z bezpečnostních důvodů je potřeba tento export povolit.</p>

                        {!user.enableExport ? < button
                            className="btn btn-success"
                            onClick={() => userApiService.enableExport()}
                        >
                            Povolit export
                        </button> :
                            <button
                                className="btn btn-danger"
                                onClick={() => userApiService.disableExport()}
                            >
                                Zakázat export
                            </button>}

                        <SectionTitle title="K ČEMU SLOUŽÍ" />
                        <hr />
                        <p>Tento export inzerce můžete využít dle své potřeby. Například k plnění vašeho webu či k jinému zpracování.</p>

                        {user.enableExport &&
                            <>
                                <SectionTitle title="URL FEEDU" />
                                <hr />

                                <p>Tento feed je dostupný i bez přihlášení na následující adrese.</p>
                                <InputText
                                    label="URL feedu"
                                    value={`${window.location.protocol}//${window.location.hostname}/api/products/export?userId=${user.id}`}
                                    readonly
                                />
                            </>}
                    </div>
                </div>
            </CustomDocumentTitle>
        </>
    );
}));