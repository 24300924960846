import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import WebsitesApiService from "../../../Services/WebsitesApiService";
import { PageTitleBox } from "../../Others/PageTitleBox";
import LoadingScreen from "../../Screens/LoadingScreen";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle";
import { WebsitesFAQ } from "../../Websites/WebsitesFAQ";
import { NotFound } from "../NotFound";
import { Link, useParams } from "react-router-dom";
import { WebsiteGeneralSettings } from "../../Websites/WebsiteGeneralSettings";
import { WebsiteContentSettings } from "../../Websites/WebsiteContentSettings";
import { WebsiteImagesSettings } from "../../Websites/WebsiteImagesSettings";
import { WebsiteAdvancedSettings } from "../../Websites/WebsiteAdvancedSettings";

interface IProps {
    websiteStore?: WebsiteStore,
    currentUserStore?: CurrentUserStore,
}

const websitesApiService = new WebsitesApiService();

export const WebsiteSettingsPage = inject('websiteStore', 'currentUserStore')(observer((props: IProps) => {

    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const [website, setWebsite] = useState(props.websiteStore.getById(Number(id)));

    useEffect(() => {
        websitesApiService.getWebsite(Number(id)).then(response => {
            setLoading(false);
        })

        return () => {
            websitesApiService.cancelRequests();
        }
    }, [])

    if (loading) {
        return <LoadingScreen />
    }
    else if (!website) {
        return <NotFound />
    }

    const title = `Nastavení webu "${website.name}"`;

    const handleChange = (key: string, value: any) => {
        setWebsite(prevWebsite => ({
            ...prevWebsite,
            [key]: value
        }));
    };

    const handleChangeSettings = (key: string, value: any) => {
        setWebsite(prevWebsite => ({
            ...prevWebsite,
            settings: {
                ...prevWebsite.settings,
                [key]: value
            }
        }));
    };

    const handleSubmit = () => {
        //  const isValid = ValidationHelper.validateInputs(getInputs());

        websitesApiService.editWebsite(website.id, website)
            .then(data => {
                // this.props.onHide();
            })
    }

    return (
        <>
            <CustomDocumentTitle title={title}>
                <PageTitleBox title={title} />

                {loading && <LoadingScreen />}

                {loading === false &&
                    <div>
                        <div className="card">
                            <div className="card-body">
                                <div className="alert alert-warning">
                                    <h4>Starší šablony některá nastavení nepodporují.</h4>
                                </div>

                             Nezapomeňte si nastavit svůj prodejní profil - 
                                <Link to="/nastaveni/prodejce" target="_blank"> Nastavení prodejce <i className="bi bi-box-arrow-up-right"></i></Link>

                                <Tabs
                                    id="uncontrolled-tab-example"
                                    className="nav nav-justified mt-4"
                                    defaultActiveKey='general'
                                >
                                    <Tab
                                        eventKey="general"
                                        title="Základní"
                                        tabClassName="nav-item"
                                    >
                                        <WebsiteGeneralSettings
                                            website={website}
                                            handleChange={handleChange}
                                            handleChangeSettings={handleChangeSettings}
                                        />

                                    </Tab>

                                    <Tab
                                        eventKey="images"
                                        title="Logo & Obrázky"
                                        tabClassName="nav-item"
                                    >
                                        <WebsiteImagesSettings website={website} />
                                    </Tab>

                                    <Tab
                                        eventKey="content"
                                        title="Texty"
                                        tabClassName="nav-item"
                                    >
                                        <WebsiteContentSettings
                                            website={website}
                                            handleChangeSettings={handleChangeSettings}
                                        />
                                    </Tab>

   
                                    <Tab
                                        eventKey="advanced"
                                        title="Pokročilé"
                                        tabClassName="nav-item"
                                    >
                                        <WebsiteAdvancedSettings
                                            website={website}
                                            handleChange={handleChange}
                                            handleChangeSettings={handleChangeSettings}
                                        />
                                    </Tab>

                                    <Tab
                                        eventKey="faq"
                                        title="FAQ"
                                        tabClassName="nav-item"
                                    >
                                        <div className="p-3">
                                            <WebsitesFAQ />
                                        </div>
                                    </Tab>
                                </Tabs>

                                <hr />

                                <div className="text-center">
                                    <button
                                        onClick={handleSubmit}
                                        className="btn btn-primary btn-lg">
                                        <i className="far fa-save mr-2"></i>Uložit nastavení
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </CustomDocumentTitle>
        </>)
}))