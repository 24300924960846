import React from "react";
import IProduct from "../../../Interfaces/IProduct";
import BaseComponent from "../../BaseComponent";
import Textarea from "../../UI/Input/Textarea";

interface IState {

}

interface IProps {
    product: IProduct,
    onChange(value: any, key: string): void,
}

export default class SalesCardEditor extends BaseComponent<IProps, IState>
{
    private inputDescription = React.createRef<Textarea>();

    constructor(props: IProps) {
        super(props);

        this.inputDescription = React.createRef();
    }

    getInputs() {
        return [this.inputDescription];
    }

    render() {
        const { product, onChange, } = this.props;

        return (
            <>
                <Textarea
                    ref={this.inputDescription}
                    label=""
                    maxLength={660}
                    value={product.salesCardDescription}
                    required={false}
                    placeholder=""
                    onChange={(value) => onChange(value, 'salesCardDescription')}
                    rows={5}
                />
            </>)
    }

}