import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import SelectBox from '../../../UI/Input/SelectBox';
import { ServerSettingAccordion } from '../../../Products/ServerSettingsAccordion';
import { find, isNil } from 'lodash';
import StringHelper from '../../../../Helpers/StringHelper';
import { BazosHelper } from '../../../../Helpers/Servers/BazosHelper';
import { IValidationFormRef } from '../../../../Interfaces/IValidationFormRef';
import IProduct from '../../../../Interfaces/IProduct';
import IInputField from '../../../../Interfaces/IInputField';
import IOptionData from '../../../../Interfaces/IOptionData';
import { ValidationHelper } from '../../../Utils/ValidationHelper';

interface IProps {
    product: IProduct,
    onChange(key: string, value: any): void,
}

export const ServerBazosSkSettingsAccordion = forwardRef<IValidationFormRef, IProps>((props, ref) => {
    const [isValidAndComplete, setIsValidAndComplete] = useState(false);

    const inputBazosSkPrice = useRef<SelectBox>(null);
    const inputBazosSkSection = useRef<SelectBox>(null);
    const inputBazosSkCategory = useRef<SelectBox>(null);

    useEffect(() => {
        checkIsValid();

        const product = props.product;
        const categories = BazosHelper.getCategories(product.bazosSkSection);

        const selectedCat = find(categories, { value: product.bazosSkCategory });
        if (!selectedCat) {
            props.onChange('bazosSkCategory', null);
        }
    }, []);

    useEffect(() => {
        checkIsValid();
    }, [props.product]);

    const checkIsValid = () => {
        const inputs = getInputs();
        const isValidAndComplete = ValidationHelper.isValid(inputs) &&
            !isNil(product.bazosSkPriceOption) && product.bazosSkPriceOption !== 0 && 
            !isNil(product.bazosSkSection) && product.bazosSkSection !== 0 &&
            !isNil(product.bazosSkCategory) && product.bazosSkCategory !== 0;

        setIsValidAndComplete(isValidAndComplete);
    };

    const getInputs = () => {
        const inputs: React.MutableRefObject<IInputField>[] = [];

        inputs.push(
            inputBazosSkPrice,
            inputBazosSkSection,
            inputBazosSkCategory
        );

        return inputs;
    };

    useImperativeHandle(ref, () => ({
        getInputs
    }));

    const product = props.product;
    const categoryText = BazosHelper.getFullCategoryText(product.bazosSkCategory, product.bazosSkSection);
    let bazosPriceOptions: IOptionData[] = [];

    if (product.priceEUR) {
        bazosPriceOptions.push({ label: `${StringHelper.separateThousands(product.priceEUR)} EUR`, value: 1 });
    }
    else {
        bazosPriceOptions.push({ label: `Pevná cena`, value: 1 });
    }

    const categories = BazosHelper.getCategories(product.bazosSkSection);

    return (
        <ServerSettingAccordion categoryText={categoryText} headingText="Bazoš.sk" isValid={isValidAndComplete}>
            <div className="row">
                <div className="col-12 col-md-4">
                    <SelectBox
                        ref={inputBazosSkPrice}
                        label="Cena"
                        options={bazosPriceOptions.concat(BazosHelper.getPriceOptions())}
                        defaultValue={"Vyberte"}
                        required={product.bazosSK}
                        value={product.bazosSkPriceOption}
                        onChange={props.onChange.bind(this, 'bazosSkPriceOption')}
                        disabled={false}
                        onBlur={checkIsValid}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <SelectBox
                        ref={inputBazosSkSection}
                        label="Sekce"
                        options={BazosHelper.getSectionsByProduct(product)}
                        defaultValue={"Vyberte"}
                        required={product.bazosSK}
                        value={product.bazosSkSection}
                        onChange={props.onChange.bind(this, 'bazosSkSection')}
                        disabled={product.bazosSkVisible}
                        onBlur={checkIsValid}
                    />
                </div>

                <div className="col-12 col-md-4">
                    <SelectBox
                        ref={inputBazosSkCategory}
                        label="Kategorie"
                        options={categories}
                        defaultValue={"Vyberte"}
                        required={product.bazosSK}
                        value={product.bazosSkCategory}
                        onChange={props.onChange.bind(this, 'bazosSkCategory')}
                        disabled={product.bazosSkVisible}
                        onBlur={checkIsValid}
                    />
                </div>
            </div>

            {product.bazosSkVisible &&
                <p className="text-secondary text-center mt-2 mb-2">
                    <b>Jelikož je inzerát vystaven, zařazení nelze měnit.</b>
                </p>
            }
        </ServerSettingAccordion>
    );
});