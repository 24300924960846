import React from "react";
import { useState, useEffect } from "react";

interface IProps {
    headingText: string,
    isValid: boolean,
    isComplete?: boolean,
    categoryText?: string,
    children?: React.ReactNode,
}

export const ServerSettingAccordion = (props: IProps) => {

    const { isValid, headingText, isComplete, categoryText } = props;
    const [hidden, setHidden] = useState(isValid);
    const opened = (!hidden || !isValid);

    useEffect(() => {
        if (isValid && (isComplete === true || isComplete === undefined)) {
            setHidden(true);
        }
    }, [isValid, isComplete])

    const smartTruncate = (text: string, maxLength: number): string => {
        if (text.length <= maxLength) return text;

        const parts = text.split('>');
        let truncated = parts[parts.length - 1].trim(); // Start with the last segment

        for (let i = parts.length - 2; i >= 0; i--) {
            if ((truncated.length + parts[i].length + 4) > maxLength) break; // +4 for " ... " prefix and spaces
            truncated = parts[i].trim() + " > " + truncated;
        }
        return '... > ' + truncated;
    };

    return (
        <div id="accordion" role="tablist" aria-multiselectable="true">
            <div className={`card mb-2 ${isValid ? 'isValid' : 'notValid'}`}>
                <div onClick={() => setHidden(!hidden)} className="card-header" role="tab" id="headingOne1">
                    <a className="tx-gray-800 transition collapsed">
                        {isValid && <i className="fa fa-check mr-1" />} {headingText}
                    </a>
                    {categoryText && <p className="serverAccordionCategory">{smartTruncate(categoryText,50)}</p>}
                    {isValid && <i className="fas fa-angle-down float-right"></i>}
                </div>
                {opened &&
                    <div>
                        <div className="card-body">
                            {props.children}
                        </div>
                    </div>}
            </div>
        </div>)
}