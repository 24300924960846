import React, { RefObject } from "react"
import BaseComponent from "../../BaseComponent";
import IInputField from "../../../Interfaces/IInputField";
import { OnlineServer } from "../../../Enums/OnlineServer";
import IAutoImporter from "../../../Interfaces/IAutoImporter";
import { AutoImporterVariables } from "./AutoImporterVariables";
import AutoImporterProductTypeContentEditor from "./AutoImporterProductTypeContentEditor";

interface IState {

}

interface IProps {
    autoImporter: IAutoImporter,
    handleChange(server: OnlineServer, value: any, key: any): void,
}

export default class AutoImporterContentEditor extends BaseComponent<IProps, IState> implements IInputField {

    private editor = React.createRef<AutoImporterProductTypeContentEditor>();

    constructor(props: IProps) {
        super(props);
        this.editor = React.createRef();

    }

    validate(value?: any) {
        if (this.isValidEditor(this.editor) === false) {
            return false;
        }
        return true;
    }

    isValidEditor(input: RefObject<IInputField>): boolean {
        return this.validateInputs([input]);
    }

    isValid(): boolean {
        return this.validate();
    }

    focus() { }

    render() {
        const { autoImporter } = this.props;
        return (
            <>
                <div className="bg-white">

                    <div className="card mb-2">
                        <div className="card-body">
                            <p>Nadefinujte, jakým způsobem se bude vytvářet obsah inzerátů.</p>

                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-7">
                                    <AutoImporterProductTypeContentEditor
                                        ref={this.editor}
                                        autoImporter={autoImporter}
                                        handleChange={(server, value, key) => this.props.handleChange( server, value, key)}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-5 mt-4 mt-md-0">
                                    <label className="section-title mt-0 mb-4">Proměnné</label>

                                    <p>
                                        <b>V nadpisu a popisu můžete použít následující proměnné.</b>
                                        &nbsp;Pro zobrazení ilustrační hodnoty najeďte na proměnnou. Kliknutím ji zkopírujete.
                                    </p>

                                    <hr />
                                    <AutoImporterVariables sourceType={autoImporter.sourceType} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
    }
}