import { SectionTitle } from "../Others/SectionTitle"
import { Accordion } from "../UI/Elements/Accordion"

export const TermsAccordion = () => {

    return <>
        <Accordion
            cardClassName="my-1"
            headingText="Podmínky služby"
        >
            <SectionTitle title="Provozovatel" />
            <hr />
            <p>
                Aplikaci Inzerobot provozuje Adam Gajdečka se sídlem Patrice Lumumby 2334/28, Ostrava 70030. Zapsán v živnostenském rejstříku. IČ: 05366542
            </p>

            <SectionTitle title="Popis služby" />
            <hr />
            <ul>
                <li>Aplikace Inzerobot slouží pro hromadnou správu bazarového zboží a přenosu na připojené inzertní portály.</li>
                <li>Zveřejňovat inzeráty na připojených inzertních portálech můžete pouze v souladu s podmínkami daných portálů.</li>
                <li>Za následky porušování podmínek inzertních portálů nenese provozovatel aplikace odpovědnost.</li>
                <li>Některé inzertní portály vyžadují poplatky za vystavení inzerce. Tyto poplatky nejsou v ceně licence a hradí se vždy provozovateli daného inzertního portálu.</li>
                <li>Inzertní portál může být odebrán bez náhrady.</li>
                <li>Aplikace nemusí být dostupná během údržby.</li>
                <li>Technická podpora se poskytuje výhradně e-mailem.</li>
            </ul>

            <SectionTitle title="Fakturace" />
            <hr />
            <p>
                Fakturační období je 6 měsíců. Ceny jsou uvedeny za měsíc.
                Nejsme plátci DPH. Při fakturaci mimo ČR v rámci EU je potřeba mít platné DIČ, faktura bude v režimu přenesené daňové povinnosti.
            </p>

            <p>Úplné obchodní podmínky naleznete <a target="_blank" href="https://app.inzerobot.cz/vop">zde</a>. Podmínky ochrany osobních údajů naleznete <a target="_blank" href="https://app.inzerobot.cz/zoou">zde</a>.</p>
        </Accordion>
    </>
}