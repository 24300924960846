import React from "react"

interface IProps {
    title: string,
}

export const PageTitleBox = (props: IProps) => {

    return (
        <div className="page-title-box">
            <h4 className="page-title">{props.title}</h4>
        </div>)
}