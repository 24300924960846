import { filter, find, first, indexOf } from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { AutoImporterSourceType } from '../../../Enums/AutoImporterSourceType';
import { AutoImporterState } from '../../../Enums/AutoImporterState';
import { OnlineServer } from '../../../Enums/OnlineServer';
import { VehicleType } from '../../../Enums/VehicleType';
import IAutoImporter from '../../../Interfaces/IAutoImporter';
import BaseComponent from '../../BaseComponent';
import { PageTitleBox } from '../../Others/PageTitleBox';
import LoadingScreen from '../../Screens/LoadingScreen';
import FacebookCatalogStore from '../../Stores/FacebookCatalogStore';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import WebsiteStore from '../../Stores/WebsiteStore';
import InputUrl from '../../UI/Input/InputUrl';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { IAutoImporterContent } from '../../../Interfaces/IAutoImporterContent';
import IResponseData from '../../../Interfaces/IResponseData';
import AutoImporterSettings from '../../Integrations/Autoimporters/AutoImporterSettings';
import { AutoImporterStateBox } from '../../Integrations/Autoimporters/AutoImporterStateBox';
import { AutoImporterSubmit } from '../../Integrations/Autoimporters/AutoImporterSubmit';
import AutoImporterVehicleContentEditor from '../../Integrations/Autoimporters/Vehicles/AutoimporterVehicleContentEditor';
interface IState {
    autoImporter: IAutoImporter,
    loading: boolean,
    validateErrors: boolean,
    edited: boolean,
}

interface IProps {
    productCollectionStore?: ProductCollectionStore,
    facebookCatalogStore?: FacebookCatalogStore,
    websiteStore?: WebsiteStore,
}

@inject('productCollectionStore', 'facebookCatalogStore', 'websiteStore')
@observer
export default class AutoImporterAutobazarEuPage extends BaseComponent<IProps, IState> {

    private inputFeedUrl = React.createRef<InputUrl>();
    private contentEditor = React.createRef<AutoImporterVehicleContentEditor>();
    private autoImporterSettings = React.createRef<AutoImporterSettings>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            autoImporter: {
                id: null,
                active: true,
                collectionIds: [],
                sourceUrl: '',
                sourceType: AutoImporterSourceType.AutobazarEu,
                userId: '',
                errorUtc: null,
                lastImportUtc: null,
                defaultEnabledNewProduct: true,
                error: false,
                errorMessage: '',
                isSetuped: false,
                deleteProductAfterNotFoundInList: true,
                updateProductContent: true,
                doNotImportForbiddenKeywords: ['rezervace', 'prodano'],
                state: AutoImporterState.Inactive,
                vehicleTypesToImport: [
                    VehicleType.Car,
                    VehicleType.Commercial,
                    VehicleType.Truck,
                    VehicleType.Motorcycle,
                    VehicleType.QuadBike,
                    VehicleType.Trailers,
                    VehicleType.Caravan
                ],
                contents: [],
            },
            loading: true,
            validateErrors: false,
            edited: false,
        }

        this.inputFeedUrl = React.createRef();
        this.contentEditor = React.createRef();
        this.autoImporterSettings = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.autoimportersApiService.getList()
            .then(data => {
                this.setAutoimporter(data);
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

        this.websitesApiService.getWebsites();
        this.facebookCatalogsApiService.getCatalogs();
    }

    handleChange(key: any, value: any) {
        this.setState({
            autoImporter: {
                ...this.state.autoImporter,
                [key]: value
            },
            edited:true,
        })
    }

    handleChangeContent(type: VehicleType, server: OnlineServer, value: any, key: keyof IAutoImporterContent) {
        let contents = this.state.autoImporter.contents;
        let content = find(contents, { onlineServer: server, vehicleType: type });

        const index = indexOf(contents, content);

        content = {
            ...content,
            [key]: value
        }

        contents[index] = content;

        this.setState({
            autoImporter: {
                ...this.state.autoImporter,
                contents: contents
            },
            edited: true,
        })
    }

    handleChangeVehicleTypes(checked: boolean, type: VehicleType) {
        let types = this.state.autoImporter.vehicleTypesToImport;

        if (checked && types.includes(type) === false) {
            types.push(type);
        }
        else if (checked === false) {
            types = filter(types, vtype => {
                if (vtype != type) {
                    return true;
                }
                return false;
            })
        }

        this.setState({
            autoImporter: {
                ...this.state.autoImporter,
                vehicleTypesToImport: types
            },
            edited: true,
        })
    }

    handleSubmit() {
        const isValid = this.validate();

        this.setState({
            validateErrors: !isValid
        })

        if (isValid) {
            const autoimporter = this.state.autoImporter;

            if (autoimporter.id) {
                // edit
                this.autoimportersApiService.editAutoimporter(autoimporter.id, autoimporter)
                    .then(data => this.setAutoimporter(data));
            }
            else {
                // post
                this.autoimportersApiService.postAutoimporter(autoimporter)
                    .then(data => this.setAutoimporter(data));
            }
        }
    }

    setAutoimporter = (data: IResponseData) => {
        const items: IAutoImporter[] = filter(data.records.autoImporters.items, { sourceType: AutoImporterSourceType.AutobazarEu })

        const autoimporter = first(items);

        if (autoimporter) {
            this.setState({
                autoImporter: autoimporter
            })
        }
    }

    validate() {
        const isValidFeedUrl = this.validateInputs([this.inputFeedUrl]);
        let isValidSettings = true;

        const autoImporter = this.state.autoImporter;

        if (autoImporter.id) {
            isValidSettings = this.autoImporterSettings.current.validate();
        }
        const isValidEditor = this.validateInputs([this.contentEditor]);

        if (isValidFeedUrl && isValidSettings && isValidEditor) {
            return true;
        }
        return false;
    }

    renderSettings() {
        const autoImporter = this.state.autoImporter;

        return (
            <div className="card mb-2" >
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label className="section-title mt-0 mb-4">Nastavení importu</label>

                            <div className="mt-4">
                                <InputUrl
                                    ref={this.inputFeedUrl}
                                    label="Url Autobazar.eu XML feedu"
                                    required
                                    onChange={this.handleChange.bind(this, 'sourceUrl')}
                                    value={autoImporter.sourceUrl}
                                />
                            </div>

                            {autoImporter.isSetuped === false &&
                                <button
                                    className="btn btn-primary btn-lg float-right"
                                    onClick={this.handleSubmit.bind(this)}
                                >
                                    Pokračovat
                                </button>}
                        </div>

                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                           
                        </div>
                    </div>

                    {autoImporter.isSetuped &&
                        <>
                            <hr />
                            <AutoImporterSettings
                                ref={this.autoImporterSettings}
                                autoImporter={autoImporter}
                                handleChange={(value, key) => this.handleChange(key, value)}
                            />
                        </>}
                </div>
            </div>)
    }

    render() {
        const loading = this.state.loading;
        const autoImporter = this.state.autoImporter;

        return (
            <CustomDocumentTitle title="Autobazar.eu - importér">
                <PageTitleBox title="Autobazar.eu - importér" />

                {loading ? <LoadingScreen /> :
                    <>
                        <AutoImporterStateBox
                            autoImporter={autoImporter}
                        />

                        {this.renderSettings()}

                        {autoImporter.isSetuped && <>
                            <PageTitleBox title="Obsah inzerátů" />

                          <AutoImporterVehicleContentEditor
                                ref={this.contentEditor}
                                autoImporter={autoImporter}
                                handleChange={(type, server, val, key) => this.handleChangeContent(type, server, val, key)}
                                supportedVehicleTypes={[
                                    VehicleType.Car,
                                ]}
                            />   

                            <AutoImporterSubmit
                                autoImporter={autoImporter}
                                onChange={(value, key) => this.handleChange(key, value)}
                                onSubmit={() => this.handleSubmit()}
                                validateErrors={this.state.validateErrors}
                                disabled={!this.state.edited}
                            />
                        </>}
                    </>}
            </CustomDocumentTitle >
        )
    }
}