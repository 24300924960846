﻿import { find, includes, orderBy } from "lodash";
import IGlobalSettings from "../../Interfaces/IGlobalSettings";
import IProductCollection from "../../Interfaces/IProductCollection";
import IOptionData from "../../Interfaces/IOptionData";
import { BazosSection } from "../../Enums/BazosSection";
import { ProductType } from "../../Enums/ProductType";
import IProduct from "../../Interfaces/IProduct";

export class BazosHelper {

    public static getBazosDescriptionMaxChars(collection: IProductCollection): number {
        let maxChars = 3000;

        if (collection.bazosCzCurrentDateDescriptionPrefix) {
            if (maxChars > (maxChars - 13)) { maxChars = maxChars - 13; }
        }
        return maxChars;
    }

    public static getDescriptionErrors(settings: IGlobalSettings, description: string): string[] {
        let errors: string[] = [];
        for (var i = 0; i < (settings.bazosSpamList || []).length; i++) {
            const word = settings.bazosSpamList[i];

            if (includes(description.toLowerCase(), word.toLowerCase())) {
                errors.push(`Bazoš nepovoluje slovo/spojení "${word}"`);
            }
        }
        return errors;
    }

    public static getAutoRenewOptions(): IOptionData[] {
        var autoRenewOptions: IOptionData[] = [];
        autoRenewOptions.push({ label: 'Nikdy', value: -1 });

        for (var i = 60; i > 0; i--) {
            autoRenewOptions.push({ label: 'každých ' + i + ' dní', value: i });
        }
        return autoRenewOptions;
    }

    public static getCountries(): IOptionData[] {
        var countries: IOptionData[] = [];
        countries.push({ label: 'Česko', value: 1 });
        countries.push({ label: 'Slovensko', value: 2 });
        return countries;
    }

    public static getPriceOptions(): IOptionData[] {
        const options: IOptionData[] = [
            { label: "Dohodou", value: 2 },
            { label: "Nabídněte", value: 3 },
            { label: "Nerozhoduje", value: 4 },
            { label: "V textu", value: 5 },
            { label: "Zdarma", value: 6 },
        ];
        return options;
    }

    public static getSectionsForOthersUploader(): IOptionData[] {
        let options = this.getSections();

        return options.filter(s =>
            s.value === 4 ||
            s.value === 10 ||
            s.value === 3 ||
            s.value === 8 ||
            s.value === 13 ||
            s.value === 18 ||
            s.value === 6 ||
            s.value === 9 ||
            s.value === 1 ||
            s.value === 17 ||
            s.value === 12 ||
            s.value === 14 ||
            s.value === 19 ||
            s.value === 5 ||
            s.value === 20 ||
            s.value === 11
        );
    }

    public static getSectionsForCarsUploader(): IOptionData[] {
        let options = this.getSections();

        return options.filter(s =>
            s.value === 2 ||
            s.value === 7 ||
            s.value === 12
        );

        //  return options.filter(s => s.value !== 2 && s.value !== 7 && s.value !== 11 && s.value !== 15 && s.value !== 16);
    }

    public static getSectionsByProduct(product: IProduct) {
        if (product.type === ProductType.Car) {
            return this.getSectionsForCarsUploader();
        }
        else {
            return this.getSectionsForOthersUploader();
        }
    }

    public static getSections(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "Auto", value: 2 },
            { label: "Děti", value: 6 },
            { label: "Dům a zahrada", value: 17 },
            { label: "Zvířata", value: 1 },
            { label: "Reality", value: 11 },
            { label: "Práce", value: 16 },
            { label: "Motorky", value: 7 },
            { label: "Stroje", value: 12 },
            { label: "PC", value: 3 },
            { label: "Mobily", value: 8 },
            { label: "Foto", value: 13 },
            { label: "Elektro", value: 18 },
            { label: "Sport", value: 4 },
            { label: "Hudba", value: 9 },
            { label: "Vstupenky", value: 14 },
            { label: "Knihy", value: 19 },
            { label: "Nábytek", value: 5 },
            { label: "Oblečení", value: 10 },
            { label: "Služby", value: 15 },
            { label: "Ostatní", value: 20 },
        ];

        options = orderBy(options, opt => [opt.label], ['asc']);
        return options;
    }

    public static getCategories(section: BazosSection): IOptionData[] {

        let categories: IOptionData[] = [];

        switch (section) {

            case BazosSection.Auto:
                categories.push(
                    { label: 'Alfa Romeo', value: 1 },
                    { label: 'Audi', value: 2 },
                    { label: 'BMW', value: 3 },
                    { label: 'Citroen', value: 4 },
                    { label: 'Dacia', value: 5 },
                    { label: 'Fiat', value: 6 },
                    { label: 'Ford', value: 7 },
                    { label: 'Honda', value: 8 },
                    { label: 'Hyundai', value: 9 },
                    { label: 'Chevrolet', value: 10 },
                    { label: 'Kia', value: 11 },
                    { label: 'Mazda', value: 12 },
                    { label: 'Mercedes', value: 13 },
                    { label: 'Mitsubishi', value: 14 },
                    { label: 'Nissan', value: 15 },
                    { label: 'Opel', value: 16 },
                    { label: 'Peugeot', value: 17 },
                    { label: 'Renault', value: 18 },
                    { label: 'Seat', value: 19 },
                    { label: 'Suzuki', value: 20 },
                    { label: 'Škoda', value: 21 },
                    { label: 'Toyota', value: 22 },
                    { label: 'Volkswagen', value: 23 },
                    { label: 'Volvo', value: 24 },
                    { label: 'Ostatní značky', value: 25 },

                    { label: 'Havarované', value: 28 },
                    { label: 'Náhradní díly', value: 29 },
                    { label: 'Pneumatiky, Kola', value: 30 },
                    { label: 'Příslušenství', value: 31 },
                    { label: 'Tuning', value: 32 },
                    { label: 'Veterání', value: 33 },

                    { label: 'Autobusy', value: 34. },
                    { label: 'Dodávky', value: 35 },
                    { label: 'Mikrobusy', value: 36 },
                    { label: 'Karavany, vozíky', value: 37 },

                    { label: 'Pick-up', value: 38 },
                    { label: 'Nákladní vozidla', value: 39 },
                    { label: 'Ostatní užitková vozidla', value: 40 },
                    { label: 'Havarované užitková vozidla', value: 41 },
                    { label: 'Náhradní díly užitková vozidla', value: 42 },
                )
                break;

            case BazosSection.Reality:
                categories.push(
                    { label: 'Prodej - byty', value: 101 },
                    { label: 'Prodej - domy', value: 102 },
                    { label: 'Prodej - nové projekty', value: 103 },
                    { label: 'Prodej - garáže', value: 104 },
                    { label: 'Prodej - hotely, penziony a restaurace', value: 105 },
                    { label: 'Prodej - chalupy, chaty', value: 106 },
                    { label: 'Prodej - kanceláře', value: 107 },
                    { label: 'Prodej - obchodní prostory', value: 108 },
                    { label: 'Prodej - pozemky', value: 109 },
                    { label: 'Prodej - sklady', value: 110 },
                    { label: 'Prodej - zahrady', value: 111 },
                    { label: 'Prodej - ostatní', value: 112 },
                    { label: 'Pronájem - byty', value: 113 },
                    { label: 'Pronájem - domy', value: 114 },
                    { label: 'Pronájem - nové projekty', value: 115 },
                    { label: 'Pronájem - podnájem, spolubydlící', value: 116 },
                    { label: 'Pronájem - garáže', value: 117 },
                    { label: 'Pronájem - hotely, penziony a restaurace', value: 118 },
                    { label: 'Pronájem - kanceláře', value: 120 },
                    { label: 'Pronájem - obchodní prostory', value: 121 },
                    { label: 'Pronájem - pozemky', value: 122 },
                    { label: 'Pronájem - sklady', value: 123 },
                    { label: 'Pronájem - zahrady', value: 124 },
                    { label: 'Pronájem - ostatní', value: 125 },
                )
                break;

            case BazosSection.Sluzby:
                categories.push(
                    { label: 'Auto Moto', value: 201 },
                    { label: 'Cestování', value: 202 },
                    { label: 'Domácí práce', value: 203 },
                    { label: 'Esoterika', value: 204 },
                    { label: 'Hlídání dětí', value: 205 },
                    { label: 'IT', value: 206 },
                    { label: 'Koně', value: 207 },
                    { label: 'Kurzy a školení', value: 208 },
                    { label: 'Opravy a servis', value: 209 },
                    { label: 'Pořádání akcí', value: 210 },
                    { label: 'Právo a bezpečnost', value: 211 },
                    { label: 'Překladatelství', value: 212 },
                    { label: 'Přeprava stěhování', value: 213 },
                    { label: 'Půjčovny', value: 214 },
                    { label: 'Realitní služby', value: 215 },
                    { label: 'Reklama na auto', value: 216 },
                    { label: 'Ostatní reklamní plochy', value: 217 },
                    { label: 'Řemeslné a stavební práce', value: 218 },
                    { label: 'Pro zvířata', value: 219 },
                    { label: 'Tvůrčí práce', value: 220 },
                    { label: 'Ubytování', value: 221 },
                    { label: 'Učetnictví, poradenství', value: 222 },
                    { label: 'Úklid', value: 223 },
                    { label: 'Výroba', value: 224 },
                    { label: 'Výuka, doučování', value: 225 },
                    { label: 'Výuka hudby', value: 226 },
                    { label: 'Zdraví a krása', value: 227 },
                    { label: 'Zprostředkovatelské', value: 228 },
                    { label: 'Ostatní', value: 229 },
                )
                break;

            case BazosSection.Sport:
                categories.push(
                    { label: 'Fitness, Jogging', value: 301 },
                    { label: 'Golf', value: 302 },
                    { label: 'Fotbal', value: 303 },
                    { label: 'Inlines, Skateboarding', value: 304 },
                    { label: 'Kempink', value: 305 },
                    { label: 'Letectví', value: 306 },
                    { label: 'Míčové hry', value: 307 },
                    { label: 'Myslivnost', value: 308 },
                    { label: 'Paintball', value: 309 },
                    { label: 'Rybaření', value: 310 },
                    { label: 'Společenské hry', value: 311 },
                    { label: 'Tenis, Squash, Badminton', value: 312 },
                    { label: 'Turistika, Horolezectví', value: 313 },
                    { label: 'Vodní sporty, potápění', value: 314 },
                    { label: 'Ostatní', value: 315 },
                    { label: 'Koloběžky', value: 317 },
                    { label: 'Horská kola', value: 318 },
                    { label: 'Silniční kola', value: 319 },
                    { label: 'Cyklistika - součástky, díly', value: 320 },
                    { label: 'Cyklistika - ostatní', value: 321 },
                    { label: 'Běžkování', value: 322 },
                    { label: 'Lyžování', value: 323 },
                    { label: 'Snowboarding', value: 324 },
                    { label: 'Hokej, bruslení', value: 325 },
                    { label: 'Zimní sporty - ostatní', value: 326 },

                )
                break;

            case BazosSection.Motorky:
                categories.push(
                    { label: 'Cestovní motocykly', value: 401 },
                    { label: 'Čtyřkolky', value: 402 },
                    { label: 'Chopper', value: 403 },
                    { label: 'Enduro', value: 404 },
                    { label: 'Minibike', value: 405 },
                    { label: 'Silniční motocykly', value: 406 },
                    { label: 'Skútry', value: 407 },
                    { label: 'Skútry vodní', value: 408 },
                    { label: 'Skútry sněžné', value: 409 },
                    { label: 'Tříkolky', value: 410 },
                    { label: 'Veterání', value: 411 },
                    { label: 'Náhradní díly', value: 412 },
                    { label: 'Oblečení, Obuv, Helmy', value: 413 },
                    { label: 'Ostatní', value: 414 },
                    { label: 'Mopedy', value: 415 },

                )
                break;

            case BazosSection.Obleceni:
                categories.push(
                    { label: 'Bundy, Kabáty', value: 501 },
                    { label: 'Čepice, Šátky', value: 502 },
                    { label: 'Džíny', value: 503 },
                    { label: 'Halenky', value: 504 },
                    { label: 'Kalhoty', value: 505 },
                    { label: 'Košile', value: 506 },
                    { label: 'Kožené oděvy', value: 507 },
                    { label: 'Mikiny', value: 508 },
                    { label: 'Obleky, Saka', value: 509 },
                    { label: 'Plavky', value: 510 },
                    { label: 'Roušky', value: 511 },
                    { label: 'Rukavice, Šály', value: 512 },
                    { label: 'Spodní prádlo', value: 513 },
                    { label: 'Sportovní oblečení', value: 514 },
                    { label: 'Sukně', value: 515 },
                    { label: 'Svatební šaty', value: 516 },
                    { label: 'Svetry', value: 517 },
                    { label: 'Šaty, kostýmky', value: 518 },
                    { label: 'Šortky', value: 519 },
                    { label: 'Těhotenské', value: 520 },
                    { label: 'Termo prádlo', value: 521 },
                    { label: 'Trička, Tílka', value: 522 },
                    { label: 'Batohy, Kufry', value: 523 },
                    { label: 'Boty', value: 524 },
                    { label: 'Doplňky', value: 525 },
                    { label: 'Hodinky', value: 526 },
                    { label: 'Kabelky', value: 527 },
                    { label: 'Šperky', value: 528 },
                    { label: 'Ostatní oblečení', value: 529 },

                )
                break;

            case BazosSection.PC:
                categories.push(
                    { label: 'DVD, Bluray mechaniky', value: 601 },
                    { label: 'GPS navigace', value: 603 },
                    { label: 'Grafické karty', value: 604 },
                    { label: 'HDD, SSD', value: 605 },
                    { label: 'Herní konzole', value: 606 },
                    { label: 'Herní zařízení', value: 607 },
                    { label: 'Hry', value: 608 },
                    { label: 'Chladiče', value: 609 },
                    { label: 'Klávesnice, Myši', value: 610 },
                    { label: 'Kopírovací stroje', value: 611 },
                    { label: 'LCD monitory', value: 612 },
                    { label: 'Modemy', value: 614 },
                    { label: 'MP3 přehrávače', value: 615 },
                    { label: 'Notebooky', value: 616 },
                    { label: 'Paměti', value: 617 },
                    { label: 'PC, Počítače', value: 618 },
                    { label: 'Procesory', value: 619 },
                    { label: 'Síťové prvky', value: 620 },
                    { label: 'Scanery', value: 621 },
                    { label: 'Skříně, Zdroje', value: 622 },
                    { label: 'Software', value: 623 },
                    { label: 'Spotřební materiál', value: 624 },
                    { label: 'Tablety, E-čtečky', value: 625 },
                    { label: 'Tiskárny', value: 626 },
                    { label: 'Wireless, WIFI', value: 627 },
                    { label: 'Základní desky', value: 628 },
                    { label: 'Záložní zdroje', value: 629 },
                    { label: 'Zvukové karty', value: 630 },
                    { label: 'Ostatní', value: 631 },
                )
                break;

            case BazosSection.Mobily:
                categories.push(
                    { label: 'Apple', value: 701 },
                    { label: 'HTC', value: 702 },
                    { label: 'Huawai, Honor', value: 703 },
                    { label: 'LG', value: 704 },
                    { label: 'Motorola', value: 705 },
                    { label: 'Nokia, Microsoft', value: 706 },
                    { label: 'Samsung', value: 707 },
                    { label: 'Sony', value: 708 },
                    { label: 'Xiaomi', value: 709 },
                    { label: 'Ostatní značky', value: 710 },
                    { label: 'Baterie', value: 711 },
                    { label: 'Bezdrátové telefony', value: 712 },
                    { label: 'Datové kabely', value: 713 },
                    { label: 'Faxy', value: 714 },
                    { label: 'Headsety', value: 715 },
                    { label: 'HF sady', value: 716 },
                    { label: 'Chytré hodinky', value: 717 },
                    { label: 'Kryty', value: 718 },
                    { label: 'Nabíječka', value: 719 },
                    { label: 'Paměťové karty', value: 720 },
                    { label: 'Stolní telefony', value: 721 },
                    { label: 'Ostatní', value: 722 },
                )
                break;

            case BazosSection.Foto:
                categories.push(
                    { label: 'Analogové fotoaparáty', value: 801 },
                    { label: 'Digitální fotoaparáty', value: 802 },
                    { label: 'Drony', value: 803 },
                    { label: 'Videokamery', value: 804 },
                    { label: 'Zrcadlovky', value: 805 },
                    { label: 'Baterie', value: 806 },
                    { label: 'Blesky, Osvětlení', value: 807 },
                    { label: 'Brašny, Pouzdra', value: 808 },
                    { label: 'Datové kabely', value: 809 },
                    { label: 'Filtry', value: 810 },
                    { label: 'Nabíječky', value: 811 },
                    { label: 'Objektivy', value: 812 },
                    { label: 'Paměťové karty', value: 813 },
                    { label: 'Stativy', value: 814 },
                    { label: 'Ostatní', value: 815 },
                )
                break;


            case BazosSection.Elektro:
                categories.push(
                    { label: 'Digestoře', value: 901 },
                    { label: 'Ledničky', value: 902 },
                    { label: 'Mikrovlnky', value: 903 },
                    { label: 'Mrazáky', value: 904 },
                    { label: 'Myčky', value: 905 },
                    { label: 'Pračky', value: 906 },
                    { label: 'Sporáky', value: 907 },
                    { label: 'Sušičky', value: 908 },
                    { label: 'Ostatní bílé', value: 909 },
                    { label: 'Autorádia', value: 910 },
                    { label: 'Domácí kina', value: 911 },
                    { label: 'Hifi', value: 912 },
                    { label: 'Repro soustavy', value: 913 },
                    { label: 'Sluchátka', value: 914 },
                    { label: 'Televizory', value: 915 },
                    { label: 'Video, DVD přehrávače', value: 916 },
                    { label: 'Zesilovače', value: 917 },
                    { label: 'Ostatní - Audio, Video', value: 918 },
                    { label: 'Epilátory, Depilátory', value: 919 },
                    { label: 'Fény, Kulmy', value: 920 },
                    { label: 'Holící strojky', value: 921 },
                    { label: 'Kávovary', value: 922 },
                    { label: 'Nabíječky baterií', value: 923 },
                    { label: 'Ruční šlehače, mixéry', value: 924 },
                    { label: 'Svítidla', value: 925 },
                    { label: 'Šicí stroje', value: 926 },
                    { label: 'Vysavače', value: 927 },
                    { label: 'Vysílačky', value: 928 },
                    { label: 'Zvlhčovače vzduchu', value: 929 },
                    { label: 'Žehličky', value: 930 },
                    { label: 'Ostatní - drobné', value: 931 },
                    { label: 'Projektory', value: 932 },
                )
                break;

            case BazosSection.Hudba:
                categories.push(
                    { label: 'Bicí nástroje', value: 1001 },
                    { label: 'Dechové nástroje', value: 1002 },
                    { label: 'Klávesové nástroje', value: 1003 },
                    { label: 'Smyčcové nástroje', value: 1004 },
                    { label: 'Strunné nástroje', value: 1005 },
                    { label: 'Ostatní nástroje', value: 1006 },
                    { label: 'DVD, CD, MC, LP', value: 1007 },
                    { label: 'Hudebníci a skupiny', value: 1008 },
                    { label: 'Koncerty', value: 1009 },
                    { label: 'Noty, Texty', value: 1010 },
                    { label: 'Světelná technika', value: 1011 },
                    { label: 'Zkušebna', value: 1012 },
                    { label: 'Zvuková technika', value: 1013 },
                    { label: 'Ostatní', value: 1014 },
                )
                break;

            case BazosSection.Vstupenky:
                categories.push(
                    { label: 'Dálniční známky', value: 1101 },
                    { label: 'Dárkové poukazy', value: 1102 },
                    { label: 'Jízdenky', value: 1103 },
                    { label: 'Letenky', value: 1104 },
                    { label: 'Permanentky', value: 1105 },
                    { label: 'Divadlo', value: 1106 },
                    { label: 'Festivaly', value: 1107 },
                    { label: 'Koncerty', value: 1108 },
                    { label: 'Společenské akce', value: 1109 },
                    { label: 'Sport', value: 1110 },
                    { label: 'Výstavy', value: 1111 },
                    { label: 'Ostatní', value: 1112 },
                    { label: 'Pro děti', value: 1113 },
                )
                break;


            case BazosSection.Knihy:
                categories.push(
                    { label: 'Beletrie', value: 1201 },
                    { label: 'Cizojazyčná literatura', value: 1202 },
                    { label: 'Časopisy', value: 1203 },
                    { label: 'Detektivky', value: 1204 },
                    { label: 'Dětská literatura', value: 1205 },
                    { label: 'Drama', value: 1206 },
                    { label: 'Encyklopedie', value: 1207 },
                    { label: 'Esoterika', value: 1208 },
                    { label: 'Historické romány', value: 1209 },
                    { label: 'Hobby, Odborné', value: 1210 },
                    { label: 'Kuchařky', value: 1211 },
                    { label: 'Mapy, Cestovní průvodci', value: 1212 },
                    { label: 'Počítačová literatura', value: 1213 },
                    { label: 'Pro mládež', value: 1214 },
                    { label: 'Romány pro ženy', value: 1215 },
                    { label: 'Scifi, Fantasy', value: 1216 },
                    { label: 'Učebnice ZŠ', value: 1217 },
                    { label: 'Učebnice SŠ', value: 1218 },
                    { label: 'Učebnice VŠ', value: 1219 },
                    { label: 'Učebnice - jazykové', value: 1220 },
                    { label: 'Zábavná', value: 1221 },
                    { label: 'Zdravý životní styl', value: 1222 },
                    { label: 'Ostatní', value: 1223 },
                )
                break;

            case BazosSection.Nabytek:
                categories.push(

                    //  { label: 'Dveře, Vrata', value: 1302 },
                    { label: 'Jídelní kouty', value: 1303 },
                    { label: 'Knihovny', value: 1304 },
                    { label: 'Koberce, Podlah. krytiny', value: 1305 },
                    { label: 'Koupelny', value: 1306 },
                    { label: 'Křesla, Gauče', value: 1307 },
                    { label: 'Kuchyně', value: 1308 },
                    { label: 'Lampy, Osvětlení', value: 1309 },
                    { label: 'Ložnice', value: 1310 },
                    { label: 'Matrace', value: 1311 },
                    { label: 'Obývací stěny', value: 1312 },
                    { label: 'Postele', value: 1313 },
                    { label: 'Sedací soupravy', value: 1314 },
                    { label: 'Skříně', value: 1315 },
                    { label: 'Stoly', value: 1316 },
                    { label: 'Zahradní nábytek', value: 1317 },
                    { label: 'Židle', value: 1318 },
                    { label: 'Doplňky', value: 1319 },
                    { label: 'Ostatní', value: 1320 },
                )
                break;

            case BazosSection.Ostatni:
                categories.push(
                    { label: 'Mince, Bankovky', value: 1401 },
                    { label: 'Modelářství', value: 1402 },
                    { label: 'Potraviny', value: 1403 },
                    { label: 'Sběratelství', value: 1404 },
                    { label: 'Sklo, Keramika', value: 1405 },
                    { label: 'Starožitnosti', value: 1406 },
                    { label: 'Umělecké předměty', value: 1408 },
                    { label: 'Zdraví, Krása', value: 1409 },
                    { label: 'Známky, Pohledy', value: 1410 },
                    { label: 'Ostatní', value: 1411 },
                )
                break;

            case BazosSection.Zvirata:
                categories.push(
                    { label: 'Akvarijní rybičky', value: 1502 },
                    { label: 'Drobní savci', value: 1503 },
                    { label: 'Kočky', value: 1504 },
                    { label: 'Koně', value: 1505 },
                    { label: 'Koně - potřeby', value: 1506 },
                    { label: 'Psi', value: 1507 },
                    { label: 'Ptactvo', value: 1508 },
                    { label: 'Terarijní zvířata', value: 1509 },
                    { label: 'Ostatní domácí zvířata', value: 1510 },
                    { label: 'Krytí', value: 1511 },
                    { label: 'Ztracení a nalezení', value: 1512 },
                    { label: 'Chovatelské potřeby', value: 1501 },
                    { label: 'Drůbež', value: 1513 },
                    { label: 'Králíci', value: 1514 },
                    { label: 'Ovce, kozy', value: 1515 },
                    { label: 'Prasata', value: 1516 },
                    { label: 'Skot', value: 1517 },
                    { label: 'Ostatní hospodářské zvířata', value: 1518 },
                )
                break;

            case BazosSection.Stroje:
                categories.push(
                    { label: 'Čerpadla', value: 1601 },
                    { label: 'Čistící stroje', value: 1602 },
                    { label: 'Dřevoobráběcí', value: 1603 },
                    { label: 'Generátory', value: 1604 },
                    { label: 'Historické', value: 1605 },
                    { label: 'Kovoobráběcí', value: 1606 },
                    { label: 'Motory', value: 1607 },
                    { label: 'Potravinářské', value: 1608 },
                    { label: 'Skladové', value: 1609 },
                    { label: 'Stavební', value: 1610 },
                    { label: 'Čerpadla', value: 1611 },
                    { label: 'Tiskařské', value: 1612 },
                    { label: 'Vybavení provozoven', value: 1613 },
                    { label: 'Výrobní linky', value: 1614 },
                    { label: 'Zemědělská technika', value: 1615 },
                    { label: 'Náhradní díly', value: 1616 },
                    { label: 'Ostatní', value: 1617 },
                )
                break;


            case BazosSection.Deti:
                categories.push(
                    { label: 'Autosedačky', value: 1701 },
                    { label: 'Baby monitory, Chůvičky', value: 1702 },
                    { label: 'Hračky', value: 1704 },
                    { label: 'Chodítka', value: 1705 },
                    { label: 'Kočárky', value: 1706 },
                    { label: 'Kojenecké potřeby', value: 1707 },
                    { label: 'Kola', value: 1708 },
                    { label: 'Nábytek', value: 1709 },
                    { label: 'Nosítka', value: 1710 },
                    { label: 'Odrážedla', value: 1711 },
                    { label: 'Sedačky na kolo', value: 1712 },
                    { label: 'Sportovní potřeby', value: 1713 },
                    { label: 'Školní potřeby', value: 1714 },
                    { label: 'Ostatní', value: 1715 },
                    { label: 'Body, dupačky', value: 1716 },
                    { label: 'Bundy', value: 1717 },
                    { label: 'Čepice', value: 1718 },
                    { label: 'Kalhoty', value: 1719 },
                    { label: 'Kombinézy', value: 1720 },
                    { label: 'Komplety', value: 1721 },
                    { label: 'Mikiny, Svetry', value: 1722 },
                    { label: 'Obuv', value: 1723 },
                    { label: 'Plavky', value: 1724 },
                    { label: 'Ponožky', value: 1725 },
                    { label: 'Pyžamka', value: 1726 },
                    { label: 'Rukavice', value: 1727 },
                    { label: 'Spodní prádlo', value: 1728 },
                    { label: 'Sukýňky, Šatičky', value: 1729 },
                    { label: 'Trička, Košile', value: 1730 },
                    { label: 'Ostatní oblečení', value: 1731 },
                )
                break;

            case BazosSection.DumAZahrada:
                categories.push(
                    { label: 'Bazény', value: 1801 },
                    { label: 'Čerpadla', value: 1802 },
                    { label: 'Dveře, Vrata', value: 1803 },
                    { label: 'Klimatizace', value: 1804 },
                    { label: 'Kotle, Kamna a bojlery', value: 1805 },
                    { label: 'Malotraktory, Kultivatory', value: 1806 },
                    { label: 'Míchačky', value: 1807 },
                    { label: 'Nářadí', value: 1808 },
                    { label: 'Okna', value: 1809 },
                    { label: 'Pily', value: 1810 },
                    { label: 'Radiátory', value: 1811 },
                    { label: 'Rostliny', value: 1812 },
                    { label: 'Sekačky', value: 1813 },
                    { label: 'Sněžná technika', value: 1814 },
                    { label: 'Stavební materiál', value: 1815 },
                    { label: 'Vybavení dílen', value: 1816 },
                    { label: 'Vysavače', value: 1817 },
                    { label: 'Zahradní grily', value: 1818 },
                    { label: 'Zahradní technika', value: 1819 },
                    { label: 'Ostatní', value: 1820 },
                )
                break;
        }
        return categories;
    }

    public static getFullCategoryText(id: number, section: BazosSection): string {
        const sectionOption = find(this.getSections(), { value: section });
        const categoryOption = find(this.getCategories(section), { value: id });

        let result = "";

        if (sectionOption) {
            result += sectionOption.label;
        }
        if (categoryOption) {
            result += " > " + categoryOption.label;
        }
        return result;
    }
}