import React from 'react';
import { Link } from 'react-router-dom';

interface IState {

}

interface IProps {
    title: string,
    message: string,
    show?: boolean
    withCard?: boolean,
    ctaShow?: boolean,
    ctaText?: string,
    ctaTo?: string,
    showSadEmoji?: boolean, 
}

export default class NoRecordsScreen extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    render() {
        if (this.props.show === false) {
            return null;
        }

        if (this.props.withCard === false) {
            return this.renderContent();
        }

        return (
            <div className="card">
                <div className="card-body">
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    renderCTA() {
        if (this.props.ctaShow) {
            return (
                <div className="d-flex justify-content-center py-3">
                    <Link to={this.props.ctaTo}>
                        <button autoFocus={true} type="button" className="btn btn-primary">
                            {this.props.ctaText}
                        </button>
                    </Link>
                </div>

            )
        }
        return null;
    }

    renderContent() {
        return (
            <div className="py-3">
                {this.props.showSadEmoji &&
                    <>
                    <div className="d-flex justify-content-center mb-3 mt-2">
                        <i className="far fa-sad-tear fa-3x"></i>
                    </div>

                    <br />
                    </>}
             
                <div className="text-center">
                    <h3>{this.props.title}</h3>
                </div>
                <div className="text-center">
                    <p>{this.props.message}</p>
                </div>
                {this.renderCTA()}
            </div>
        )
    }
}