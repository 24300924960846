import CurrentUserStore from '../../Stores/CurrentUserStore';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import AppIpAddressStore from '../../Stores/AppIpAddressStore';
import ProductCollectionApiService from '../../../Services/ProductCollectionsApiService';
import WebsitesApiService from '../../../Services/WebsitesApiService';
import FacebookCatalogsApiService from '../../../Services/FacebookCatalogsApiService';
import ServerAccountStore from '../../Stores/ServerAccountStore';
import WebsiteStore from '../../Stores/WebsiteStore';
import FacebookCatalogStore from '../../Stores/FacebookCatalogStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CollectionConnectServers from '../../Uploader/Collections/Edit/CollectionConnectServers';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { PageTitleBox } from '../../Others/PageTitleBox';
import { NotFoundScreen } from '../../Screens/NotFoundScreen';
import { filter, find, forEach, isNil } from 'lodash';
import { NotFound } from '../NotFound';
import LoadingScreen from '../../Screens/LoadingScreen';
import IAppIpAddress from '../../../Interfaces/IAppIpAddress';
import IOptionData from '../../../Interfaces/IOptionData';
import IFacebookCatalog from '../../../Interfaces/IFacebookCatalog';
import { IWebsite } from '../../../Interfaces/IWebsite';
import { OnlineServer } from '../../../Enums/OnlineServer';
import { IServerAccount } from '../../../Interfaces/IServerAccount';
import { useEffect, useRef, useState } from 'react';
import SelectBox from '../../UI/Input/SelectBox';
import { ValidationHelper } from '../../Utils/ValidationHelper';
import { ICollectionAdvancedSettingsRequest } from '../../../Interfaces/Requests/ProductCollections/ICollectionAdvancedSettingsRequest';
import { inject, observer } from 'mobx-react';
import { AxiosError } from 'axios';
import InputNumber from '../../UI/Input/InputNumber';
import SelectCheckBox from '../../UI/Input/SelectCheckBox';
import CheckBox from '../../UI/Input/CheckBox';
import { SectionTitle } from '../../Others/SectionTitle';
import InputText from '../../UI/Input/InputText';
import Product from '../../../Models/Product';

export interface IProps {
    currentUserStore?: CurrentUserStore,
    productCollectionStore?: ProductCollectionStore,
    appIpAddressStore?: AppIpAddressStore,
    serverAccountStore?: ServerAccountStore,
    websiteStore?: WebsiteStore,
    facebookCatalogStore?: FacebookCatalogStore,
}

export const productCollectionApiService = new ProductCollectionApiService();
export const websitesApiService = new WebsitesApiService();
export const facebookCatalogsApiService = new FacebookCatalogsApiService();

export const EditCollectionPage = inject('currentUserStore',
    'productCollectionStore',
    'appIpAddressStore',
    'serverAccountStore',
    'websiteStore',
    'facebookCatalogStore')(observer((props: IProps) => {

        const [loading, setLoading] = useState(true);
        const [error, setError] = useState<AxiosError>();

        const { id } = useParams();

        useEffect(() => {
            Promise.all([
                productCollectionApiService.getCollection(id),
                websitesApiService.getWebsites(),
                facebookCatalogsApiService.getCatalogs(),
            ])
                .then(([getCollectionData, websiteData, fbCatalogData]) => {
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });


            return () => {
                productCollectionApiService.cancelRequests();
                websitesApiService.cancelRequests();
                facebookCatalogsApiService.cancelRequests();
            };
        }, []);

        const user = props.currentUserStore.user;
        const ipAddresses = props.appIpAddressStore.list;
        const collections = props.productCollectionStore.list;
        const collectionServer = find(props.productCollectionStore.list, { id: id });

        const [form, setForm] = useState<ICollectionAdvancedSettingsRequest>({
            updatePricesByExchangeRates: collectionServer.updatePricesByExchangeRates,
            updatePricesByExchangeRatesIntervalDays: collectionServer.updatePricesByExchangeRatesIntervalDays || 14,
            sbazarServerAccountId: collectionServer.sbazarServerAccountId,
            autobazarEu: collectionServer.autobazarEu,
            facebookCatalogId: collectionServer.facebookCatalogId,
            googleMerchant: collectionServer.googleMerchant,
            tipMoto: collectionServer.tipMoto,
            websiteId: collectionServer.websiteId,
            defaultVatDeduction: collectionServer.defaultVatDeduction,
            defaultCondition: collectionServer.defaultCondition,
            name: collectionServer.name,
            defaultSection: collectionServer.defaultSection,
            defaultVehicleCondition: collectionServer.defaultVehicleCondition,
            defaultVehicleType: collectionServer.defaultVehicleType,
        });

        const inputInterval = useRef<InputNumber>();
        const inputName = useRef<InputText>();

        const getInputs = () => {
            return [inputName, inputInterval, inputServerAccountId];
        };

        const handleChange = (value: any, key: string) => {
            setForm({
                ...form,
                [key]: value
            });
        };

        const navigate = useNavigate();

        const handleSubmit = () => {
            let isValid = ValidationHelper.validateInputs(getInputs());

            if (isValid) {
                productCollectionApiService
                    .postAdvancedSettings(collectionServer.id, form)
                    .then(data => {
                        if (data.success) {
                            navigate(`/nahravac/kolekce/${collectionServer.id}/prehled`)
                        }
                    })
            }
        };

        const inputServerAccountId = useRef<SelectBox>(null);
        const websiteIdRef = useRef<SelectBox>(null);
        const facebookCatalogIdRef = useRef<SelectBox>(null);

        let sbazarOptions: IOptionData[] = [];
        forEach(props.serverAccountStore.list, (s: IServerAccount) => {
            if (s.onlineServer === OnlineServer.Sbazar) {
                sbazarOptions.push({
                    label: s.name,
                    value: s.id,
                });
            }
        });

        let websiteOptions: IOptionData[] = [];
        forEach(props.websiteStore.list, (s: IWebsite) => {
            websiteOptions.push({
                label: s.name,
                value: s.id,
            });
        });

        let fbOptions: IOptionData[] = [];
        forEach(props.facebookCatalogStore.list, (s: IFacebookCatalog) => {
            fbOptions.push({
                label: s.name,
                value: s.id,
            });
        });

        if (error && error.response?.status === 404) {
            return <NotFound />;
        }
        if (loading) {
            return <LoadingScreen />;
        }

        if (isNil(collectionServer)) {
            return <NotFoundScreen />;
        }

        let appIps: IAppIpAddress[] = filter(ipAddresses, { userId: user.id });

        return (
            <CustomDocumentTitle title={`Nastavení kolekce "${collectionServer.name}"`}>
                <>
                    <PageTitleBox title={`Nastavení kolekce "${collectionServer.name}"`} />

                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-8 ">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <InputText
                                        ref={inputName}
                                        label="Název"
                                        maxLength={30}
                                        value={form.name}
                                        required={true}
                                        placeholder="např. Moje první kolekce"
                                        onChange={(value) => handleChange(value, 'name')}
                                    />

                                    <SectionTitle className="mt-2 mb-3" title="Pravidelná aktualizace cen" />

                                    <hr />
                                    <CheckBox
                                        checked={form.updatePricesByExchangeRates}
                                        label="Aktualizovat ceny dle aktuálních kurzů"
                                        onChange={(value: any) => handleChange(value, 'updatePricesByExchangeRates')}
                                    />

                                    <p>
                                        <small>
                                            Cena se aktualizuje jen pokud došlo ke změně alespoň o {user.priceExchangeRateDiffPercentageWarning} %.
                                            Lze změnit <Link to="/nastaveni/aplikace">v nastavení aplikace.</Link>
                                        </small>
                                    </p>

                                    {form.updatePricesByExchangeRates &&
                                        <>
                                            <div className="row mt-4">
                                                <div className="col-12 col-lg-4">
                                                    <InputNumber
                                                        ref={inputInterval}
                                                        label="Interval aktualizace (dny)"
                                                        value={form.updatePricesByExchangeRatesIntervalDays}
                                                        onChange={(value) => handleChange(value, 'updatePricesByExchangeRatesIntervalDays')}
                                                        required={form.updatePricesByExchangeRates}
                                                        minValue={7}
                                                    />
                                                </div>
                                            </div>
                                        </>}
                                </div>
                            </div>

                            {!collectionServer.unlimited &&
                                <div className="card mt-2 mb-0">
                                    <div className="card-header">
                                        <SectionTitle title="Marketingové kanály" className="my-0" />
                                    </div>

                                    <div className="card-body">
                                        {user.activeUploader ? <CollectionConnectServers
                                            appIps={appIps}
                                            collections={collections}
                                            collectionServer={collectionServer} /> :
                                            <div className="my-5 text-center">
                                                <h6>
                                                    Marketingové kanály nejsou k dispozici, jelikož nemáte aktivovanou službu "Správa inzerce".
                                                </h6>
                                                <p>Kontaktujte <Link to="/kontakt">podporu</Link>.</p>
                                            </div>}
                                    </div>
                                </div>}

                            <div className="card mt-2 mb-0">
                                <div className="card-header">
                                    <SectionTitle title="Výchozí hodnoty nového inzerátu" className="my-0" />
                                </div>

                                <div className="card-body">
                                    <p>Při vytváření nového inzerátu budou tyto možnosti přednastaveny.</p>

                                    <SectionTitle className="mt-2 mb-3" title="Marketingové kanály" />
                                    <hr />

                                    <div className="row">
                                        {collectionServer.unlimited && user.uploaderServers.includes(OnlineServer.Sbazar) &&
                                            <div className="col-12 col-lg-6">
                                                <SelectBox
                                                    ref={inputServerAccountId}
                                                    label="Sbazar účet"
                                                    options={sbazarOptions}
                                                    value={form.sbazarServerAccountId}
                                                    defaultValue="Nevybráno"
                                                    required={false}
                                                    onChange={(v) => handleChange(v, 'sbazarServerAccountId')}
                                                />
                                            </div>}

                                        <div className="col-12 col-lg-6">
                                            <SelectBox
                                                ref={websiteIdRef}
                                                label="Web"
                                                options={websiteOptions}
                                                value={form.websiteId}
                                                defaultValue="Nevybráno"
                                                required={false}
                                                onChange={(v) => handleChange(v, 'websiteId')}
                                            />
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <SelectBox
                                                ref={facebookCatalogIdRef}
                                                label="Facebook Obchod"
                                                options={fbOptions}
                                                value={form.facebookCatalogId}
                                                defaultValue="Nevybráno"
                                                required={false}
                                                onChange={(v) => handleChange(v, 'facebookCatalogId')}
                                            />
                                        </div>

                                        <div className="col-12">
                                            {user.uploaderServers.includes(OnlineServer.TipMoto) && <CheckBox
                                                checked={form.tipMoto}
                                                label="TipMoto"
                                                onChange={(value: any) => handleChange(value, 'tipMoto')}
                                            />}

                                            {user.uploaderServers.includes(OnlineServer.AutobazarEu) && <CheckBox
                                                checked={form.autobazarEu}
                                                label="Autobazar.eu"
                                                onChange={(value: any) => handleChange(value, 'autobazarEu')}
                                            />}

                                            {user.uploaderServers.includes(OnlineServer.GoogleMerchant) && <CheckBox
                                                checked={form.googleMerchant}
                                                label="Google Merchant"
                                                onChange={(value: any) => handleChange(value, 'googleMerchant')}
                                            />}
                                        </div>
                                    </div>

                                    <SectionTitle className="mt-4 mb-3" title="Inzerát - ostatní" />
                                    <hr />

                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <SelectBox
                                                label="Stav"
                                                options={Product.getConditionOptions()}
                                                value={form.defaultCondition}
                                                defaultValue="Nevybráno"
                                                required={false}
                                                onChange={(v) => handleChange(v, 'defaultCondition')}
                                            />
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <SelectCheckBox
                                                allowNull={true}
                                                labelFalse="Ne"
                                                labelTrue="Ano"
                                                label="Odpočet DPH"
                                                value={form.defaultVatDeduction}
                                                defaultValue="Nevybráno"
                                                required={false}
                                                onChange={(v) => handleChange(v, 'defaultVatDeduction')}
                                            />
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <SelectBox
                                                //       ref={sectionRef}
                                                label="Sekce"
                                                options={Product.getOthersSections()}
                                                defaultValue={"Vyberte"}
                                                value={form.defaultSection}
                                                onChange={(val) => handleChange(val, 'defaultSection')}
                                                disabled={false}
                                            />
                                        </div>

                                    </div>


                                    <SectionTitle className="mt-4 mb-3" title="Inzerát - Auto, moto, stroje" />
                                    <hr />

                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <SelectBox
                                                //    ref={vehicleTypeRef}
                                                defaultValue="- nevybráno -"
                                                label="Kategorie"
                                                onChange={(val) => handleChange(val, 'defaultVehicleType')}
                                                value={form.defaultVehicleType}
                                                options={Product.getVehicleTypeOptions()}
                                            />
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <SelectBox
                                                //      ref={vehicleConditionRef}
                                                defaultValue="- nevybráno -"
                                                label="Stav vozidla"
                                                onChange={(val) => handleChange(val, 'defaultVehicleCondition')}
                                                value={form.defaultVehicleCondition}
                                                options={Product.getVehicleConditionOptions()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card mt-2">
                                <div className="card-body">
                                    <button
                                        onClick={handleSubmit}
                                        className="btn btn-primary"
                                    >
                                        Uložit nastavení
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </CustomDocumentTitle>
        );
    }));