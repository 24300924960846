import IProduct from '../../Interfaces/IProduct';
import { inject, observer } from 'mobx-react';
import { ShowDate } from '../Others/ShowDate';
import CurrentUserStore from '../Stores/CurrentUserStore';
import { MainProductImg } from '../Products/MainProductImg';
import { ProductHelper } from '../../Helpers/ProductHelper';
import CurrencyHelper from '../../Helpers/CurrencyHelper';
import { ProductSaleState } from './ProductSaleState';
import { CSSProperties, useEffect } from 'react';
import { SeparateThousands } from '../Others/SeparateThousands';
import moment from 'moment';
import Swal from 'sweetalert2';
import ProductApiService from '../../Services/ProductApiService';
import { toast } from 'react-toastify';

interface IProps {
    product: IProduct,
    currentUserStore?: CurrentUserStore,
    style?: CSSProperties,
}

const productApiService = new ProductApiService();

export const ProductShopTrashTableItem =
    inject('currentUserStore')
        (observer((props: IProps) => {

            const { product } = props;
            const user = props.currentUserStore.user;

            const deletedMoment = moment.utc(new Date(product.deletedUtc));
            const willBePermanentlyDeleted = deletedMoment.clone().add(30, 'days');

            // Calculate the remaining days by adding 30 days to `deletedMoment` and then finding the difference
            const remainingDays = willBePermanentlyDeleted.diff(moment.utc(), 'days');

            useEffect(() => {
                return () => {
                    productApiService.cancelRequests();
                }
            },[])

            const handleRestore = () => {
                Swal.fire({
                    title: `Opravdu chcete obnovit inzerát "${product.name}"?`,
                    icon: 'question',
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: "Obnovit",
                    cancelButtonText: "Ne",
                }).then(result => {
                    if (result.isConfirmed) {
                        productApiService.restore(product.id)
                            .then(data => {
                                if (data.success) {
                                    toast.success("Inzerát bude obnoven");
                                }
                                else {
                                    toast.error("Došlo k chybě");
                                }
                            })
                    }
                })
            }

            return (
                <>
                    <tr key={product.id} style={props.style}>
                        <td>
                            <MainProductImg
                                product={product}
                            />
                        </td>

                        <td style={{ width: '25%' }}>
                            <span className="">{product.name}</span> <br />
                            <small className='d-none d-md-flex text-secondary'>
                                Smazáno&nbsp;<ShowDate date={product.deletedUtc} />
                            </small>
                        </td>

                        <td className="text-center" style={{ width: '20%' }}>
                            <ProductSaleState product={product} />
                        </td>

                        <td style={{ fontSize: '13px', width: '30%' }}>
                            <ul className="list-unstyled mb-0">
                                <li><b><SeparateThousands number={ProductHelper.getPriceByCurrency(product, user.accountCurrency)} />
                                    &nbsp;{CurrencyHelper.getSymbol(user.accountCurrency)}</b>
                                </li>
                                <li><small>prodejní cena</small></li>

                                {product.costPrice &&
                                    <>
                                        <li className="mt-2">
                                            <b><SeparateThousands number={product.costPrice} />
                                                &nbsp;{CurrencyHelper.getSymbol(user.accountCurrency)}</b>
                                        </li>

                                        <li className="mb-0"><small>nákupní cena</small></li>
                                    </>}

                                {/*product.grossMargin &&
                                    <li>oček. marže: <SeparateThousands number={product.grossMargin} />
                                        &nbsp;{CurrencyHelper.getSymbol(user.accountCurrency)}</li>
                                */}
                            </ul>
                        </td>

                        <td className="text-center" style={{ width: '20%' }}>
                            {!product.pendingRestore ?
                                <span className="text-danger"><b>Trvalé smazání za {remainingDays} dní</b></span>
                                :
                                <span className="text-secondary"><b>Probíhá obnovení</b></span>}
                        </td>

                        <td >
                            {!product.pendingRestore && 
                                <button
                                    onClick={handleRestore}
                                    className="btn btn-outline-primary mr-2"
                                >
                                    <i className="fas fa-undo"></i>&nbsp;Obnovit
                                </button>
                            }
                        </td>
                    </tr>
                </>
            )
        }));