import { useEffect, useState } from "react"
import SelectBox from "../../UI/Input/SelectBox"
import CurrentUserStore from "../../Stores/CurrentUserStore";
import { inject, observer } from "mobx-react";
import LanguageHelper from "../../../Helpers/LanguageHelper";
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle";
import { PageTitleBox } from "../../Others/PageTitleBox";
import CheckBox from "../../UI/Input/CheckBox";
import { IAppSettingsForm } from "../../../Interfaces/Requests/Settings/IAppSettingsForm";
import UserApiService from "../../../Services/UserApiService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { SectionTitle } from "../../Others/SectionTitle";
import InputNumber from "../../UI/Input/InputNumber";
import InputPrice from "../../UI/Input/InputPrice";

interface IProps {
    currentUserStore?: CurrentUserStore
}

const userApiService = new UserApiService();

export const AppSettingsPage = inject('currentUserStore')(observer((props: IProps) => {

    useEffect(() => {
        return () => {
            userApiService.cancelRequests();
        }
    }, [])

    const { currentUserStore } = props;
    const user = currentUserStore.user;

    const [form, setForm] = useState<IAppSettingsForm>({
        language: user.language,
        enableWebEmbed: user.enableWebEmbed,
        vatPayer: user.vatPayer,
        vatRate: user.vatRate,
        doNotStoreBusinessData: user.doNotStoreBusinessData,
        defaultCommissionPercentage: user.defaultCommissionPercentage,
        defaultMinCommission: user.defaultMinCommission,
        priceExchangeRateDiffPercentageWarning: user.priceExchangeRateDiffPercentageWarning,
        allowSmartPriceRound: user.allowSmartPriceRound,
        eurToCzkRate: user.eurToCzkRate,
    });

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const onSave = () => {
        setLoading(true);

        userApiService.saveAppSettings(form)
            .then((data) => {
                if (data.success) {
                    toast.success("Změny uloženy")
                    navigate('/');
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onChange = (key: string, value: any) => {
        setForm({
            ...form,
            [key]: value,
        })
    }

    return (
        <>
            <CustomDocumentTitle title={`Nastavení aplikace`}>
                <PageTitleBox title="Nastavení aplikace" />

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <SelectBox
                                    label="Výchozí jazyk pro zadávání / export na web"
                                    onChange={(val) => onChange('language', val)}
                                    value={form.language}
                                    options={LanguageHelper.getOptions()}
                                    defaultValue="Vyberte"
                                    required
                                />
                            </div>
                        </div>

                        <CheckBox
                            checked={form.enableWebEmbed}
                            label="Povolit webové widgety (vložení nabídky, google hodnocení apod)"
                            onChange={(val) => onChange('enableWebEmbed', val)}
                        />

                        <h4 className="mt-5 text-black">Obchod</h4>

                        <hr />

                        <CheckBox
                            checked={form.doNotStoreBusinessData}
                            label="Neukládat obchodní údaje"
                            onChange={(val) => onChange('doNotStoreBusinessData', val)}
                        />

                        <div className="row mt-2">
                            <div className="col-12 col-md-6">
                                <SectionTitle className="mt-4" title="Nastavení cen a DPH" />

                                <hr />

                                <p>Toto nastavení ovlivňuje to, jak pracujete s cenami.</p>

                                <CheckBox
                                    checked={form.vatPayer}
                                    label="Jsem plátce DPH"
                                    onChange={(val) => onChange('vatPayer', val)}
                                />

                                {form.vatPayer &&
                                    <div className="mt-3">
                                        <InputNumber
                                            label="Výchozí sazba DPH"
                                            value={form.vatRate}
                                            onChange={(val) => onChange('vatRate', val)}
                                            suffix="%"
                                        />
                                    </div>
                                }
                            </div>

                            <div className="col-12 col-md-6">
                                <SectionTitle className="mt-4" title="Cizí měny" />

                                <hr />

                                <InputNumber
                                    label="Tolerovaný rozdíl mezi cenou v jednotlivých měnách"
                                    value={form.priceExchangeRateDiffPercentageWarning}
                                    suffix="%"
                                    onChange={(val) => onChange('priceExchangeRateDiffPercentageWarning', val)}
                                    allowDouble={true}
                                    maxValue={100}
                                    minValue={0.5}
                                />
                                <small>Při překročení uvídíte v úpravě inzerátu upozornění na tento rozdíl.</small>

                                {form.priceExchangeRateDiffPercentageWarning > 0 &&
                                    <div className="mt-3">
                                        <CheckBox
                                            checked={form.allowSmartPriceRound}
                                            onChange={(checked) => onChange('allowSmartPriceRound', checked)}
                                            label="Smart zaokrouhlování na tisíce a stovky v rámci tolerance"
                                        />
                                        <p>
                                            <small>Uplatní se jen pro přepočet do cizích měn.</small>
                                            <br />
                                            <small>např. <b>13 666 Kč ={">"} 13 700 Kč</b> nebo <b>1 256 611 Kč ={">"} 1 257 000 Kč</b></small>
                                        </p>
                                    </div>}

                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <InputNumber
                                            label={"Pevný kurz"}
                                            value={form.eurToCzkRate}
                                            onChange={(val) => onChange('eurToCzkRate', val)}
                                            allowDouble={true}
                                            suffix="EUR/CZK"
                                        />
                                        <small>Ponechte prázdné pro použivání automatického kurzu.</small>
                                    </div>
                                </div>      
                            </div>
                        </div>

                        <SectionTitle className="mt-3" title="Komisní prodej" />
                        <hr />

                        <div className="row my-3">
                            <div className="col-12 col-md-3">
                                <InputNumber
                                    label="Výchozí provize"
                                    value={form.defaultCommissionPercentage}
                                    suffix="%"
                                    onChange={(val) => onChange('defaultCommissionPercentage', val)}
                                    allowDouble={true}
                                    maxValue={100}
                                />
                            </div>

                            <div className="col-12 col-md-3">
                                <InputPrice
                                    label="Min. provize"
                                    value={form.defaultMinCommission}
                                    currency={user.accountCurrency}
                                    onChange={(val) => onChange('defaultMinCommission', val)}
                                />
                            </div>
                        </div>

                        <div className="text-center mb-2">
                            <button className="btn btn-primary btn-lg" onClick={onSave}>
                                Uložit nastavení
                            </button>
                        </div>
                    </div>
                </div>
            </CustomDocumentTitle>
        </>
    )
}))