import { ContactForm } from "../Contact/ContactForm"
import { InzerobotEmail } from "../Others/InzerobotEmail"
import { PageTitleBox } from "../Others/PageTitleBox"
import CustomDocumentTitle from "../Utils/CustomDocumentTitle"

export const ContactUsPage = () => {

    return (
        <>
            <CustomDocumentTitle title="Podpora | Kontaktujte nás">
                <PageTitleBox title="Zákaznická podpora" />

                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <p>Máte dotaz, zpětnou vazbu či jste narazili na technickou chybu? Využijte kontaktního formuláře níže. Děkujeme.</p>

                                <ContactForm />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="mt-0">Kontaktní údaje</h6>
                                <hr />

                                <ul style={{ listStyle: 'none', fontSize: '16px' }}>
                                    <li><b>E-mail</b>: <InzerobotEmail /></li>
                                    <li className="mt-3"><b>Tel</b>: po předchozí domluvě</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomDocumentTitle>
        </>)
}