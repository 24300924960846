import DocumentTitle from 'react-document-title';
import React from 'react';

interface IState {
}

interface IProps {
    title: string,
    children: React.ReactNode,
}

export default class CustomDocumentTitle extends React.Component<IProps, IState> {
    render() {
        let title = this.props.title;
        title += ' | Inzerobot';
        return (
            <DocumentTitle title={title}>
                   <div> {this.props.children} </div>
            </DocumentTitle>
        );
    }
}