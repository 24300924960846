import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import WebsiteHelper from "../../Helpers/WebsiteHelper";
import { IWebsite } from "../../Interfaces/IWebsite";
import WebsitesApiService from "../../Services/WebsitesApiService";
import { ShowDate } from "../Others/ShowDate";
import CurrentUserStore from "../Stores/CurrentUserStore";
import WebsiteSetCustomDomainModal from "./WebsiteSetCustomDomainModal";
import { WebsiteState } from "./WebsiteState";

interface IProps {
    website: IWebsite,
    currentUserStore?: CurrentUserStore
}

const websiteApiService = new WebsitesApiService();

export const WebsiteTableRow = inject('currentUserStore')(observer((props: IProps) => {
    const website = props.website;

    const user = props.currentUserStore.user;

    const [showSettings, setShowSettings] = useState(false);
    const [showSetCustomDomain, setShowCustomDomain] = useState(false);

    useEffect(() => {
        return () => {
            websiteApiService.cancelRequests();
        }
    }, [])

    function DeleteWebsite() {
        Swal.fire({
            title: `Opravdu smazat web "${website.name}"?`,
            text: 'Smazání je nevratný proces.',
            confirmButtonText: 'Ano, smazat',
            cancelButtonText: 'Ne',
            icon: 'question',
            showCancelButton: true,
        }).then(result => {
            if (result.value) {
                websiteApiService.deleteWebsite(website.id)
                    .then(data => {

                    })
            }
        })
    }

    return (
        <tr>
            <td>{website.name}

                {website.expiration &&
                    <p>
                        <small>Expirace: <ShowDate date={website.expiration} /></small>
                    </p>}
            </td>

            <td>{WebsiteHelper.getTypeLabel(website.type)}</td>

            <td>
                <WebsiteState website={website} />
            </td>

            <td>
                {website.isAvailable === true && !website.customDomain &&
                    <a target="_blank" href={`https://${website.demoDomain}`}>
                        {website.demoDomain}
                    </a>}

                {website.isAvailable === true && website.customDomain &&
                    <a target="_blank" href={`https://${website.customDomain}`}>
                        {website.customDomain}
                    </a>}
            </td>

            <td>{website.productsCount} z {website.productsLimitCount}</td>

            <td>
                <WebsiteSetCustomDomainModal
                    onHide={() => setShowCustomDomain(false)}
                    show={showSetCustomDomain}
                    website={website}
                    currency={user.accountCurrency}
                />

                <div className="btn-group float-right" role="group">

                    {website.isCustomDomain === false && website.customDomainRequested === false && website.pendingDelete === false &&
                        website.pendingSetup === false &&
                        <button
                            className="btn btn-primary"
                            onClick={() => setShowCustomDomain(true)}
                        >
                            Nastavit vlastní doménu
                        </button>}
                    {website.pendingDelete === false &&

                        <Link to={`/webove-stranky/${website.id}/nastaveni`}>
                            <button
                            className="btn btn-outline-secondary ml-2"
                            onClick={() => setShowCustomDomain(true)}
                        >
                                <i className="fas fa-cog" /> Nastavení
                            </button>
                        </Link>}
                </div>
            </td>
        </tr>
    )
}))