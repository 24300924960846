import { uniqueId } from 'lodash';
import * as React from 'react';

interface IProps {
    label: string;
    checked: boolean;
    disabled?: boolean;
    onChange?(checked: boolean): void,
}

interface IState {
    errors: string[],
}

export default class CheckBox extends React.Component<IProps, IState> {
    private input: React.RefObject<HTMLInputElement>;

    constructor(props: IProps) {
        super(props);

        this.input = React.createRef();

        this.state = {
            errors: [],
        };
    }

    focus() {
        this.input.current.focus();
    }

    handleChange(e: any) {
        this.props.onChange(!this.props.checked);
    }

    renderError() {
        if (this.state.errors.length == 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    render() {
        const unique = uniqueId("chb");

        return (
                <div className="form-check">
                <input
                    type="checkbox"
                    className="form-check-input"
                    ref={this.input}
                    disabled={this.props.disabled}
                    checked={this.props.checked}
                    onChange={this.handleChange.bind(this)}
                    data-clarity-unmask="True"
                    id={unique}
                />
                <label className="form-check-label" htmlFor={unique}>{this.props.label}</label>
                    </div>
            /*
            <label className="ckbox">
                <input
                    ref={this.input}
                    type="checkbox"
                    disabled={this.props.disabled}
                    checked={this.props.checked}
                    onChange={this.handleChange.bind(this)}
                    data-clarity-unmask="True"
                />
                <span style={{ position: 'absolute', top: -2 }}>{this.props.label}</span>
            </label>*/
        );
    }
}