import React from 'react';
import { toast } from 'react-toastify';

interface ClickableWordsCopyProps {
    text: string;
}

const ClickableWordsCopy: React.FC<ClickableWordsCopyProps> = ({ text }) => {

    text = text || '';

    const copyToClipboard = async (word: string) => {
        try {
            await navigator.clipboard.writeText(word);
            toast.info(`Zkopírováno "${word}" do schránky`);
        } catch (err) {
        }
    };

    // Function to handle click event on the word element
    const handleWordClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const word = event.currentTarget.textContent || ""; // Get the text content of the clicked span
        copyToClipboard(word);
    };

    // Split the text only by spaces
    const words = text.split(' ').map((part, index) => (
        <span key={index} onClick={handleWordClick} style={{ cursor: 'pointer' }}>
            {part}&nbsp;
        </span>
    ));

    return <p>{words}</p>;
};

export default ClickableWordsCopy;