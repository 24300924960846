import { useEffect, useRef, useState } from 'react';
import IUser from '../../Interfaces/IUser';
import LocationForm from './LocationForm';
import UserApiService from '../../Services/UserApiService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import InputUrl from '../UI/Input/InputUrl';
import { IContacts } from '../../Interfaces/IContacts';
import { ILocation } from '../../Interfaces/ILocation';
import { ValidationHelper } from '../Utils/ValidationHelper';
import { MyCompanyGooglePlacesCard } from '../Integrations/GooglePlaces/MyCompanyGooglePlacesCard';
import { ContactsForm } from './ContactsForm';
interface IProps {
    user: IUser,
}

const userApiService = new UserApiService();

export const MyBusinessProfile = (props: IProps) => {

    useEffect(() => {
        return () => {
            userApiService.cancelRequests();
        }
    }, [])

    const navigate = useNavigate();
    const locationFormRef = useRef<LocationForm>(null);
    const webUrlRef = useRef<InputUrl>(null);

    const [user, setUser] = useState(props.user);

    const handleChangeLocation = (value: any, key: keyof ILocation) => {
        setUser({
            ...user,
            location: {
                ...user.location,
                [key]: value,
            }
        })
    }

    const handleChangeContacts = (value: any, key: keyof IContacts) => {
        setUser({
            ...user,
            contacts: {
                ...user.contacts,
                [key]: value,
            }
        })
    }

    const handleChangeUser = (value: any, key: keyof IUser) => {
        setUser({
            ...user,
            [key]: value,
        })
    }

    const handleSubmit = () => {
        var locationValid = locationFormRef.current.validate();

        const inputs = [webUrlRef];
        const isValid = ValidationHelper.validateInputs(inputs);

        if (locationValid && isValid) {
            userApiService.editUserProfile({
                contacts: user.contacts,
                location: user.location,
                webUrl: user.webUrl,
            })
                .then(data => {
                    if (data.success) {
                        toast.success('Profil uložen');
                        navigate('/');
                    }
                })
        }
    }

    return (
        <>
            <MyCompanyGooglePlacesCard
                user={user}
                onUserUpdated={(user) => setUser(user)}
            />

            <div className="card">
                <div className="card-body">
                    <label className="section-title pb-3">Webové stránky</label>

                    <div className="row">
                        <div className="col-12 col-md-4">
                            <InputUrl
                                ref={webUrlRef}
                                label="Url webu"
                                onChange={(val) => handleChangeUser(val, 'webUrl')}
                                value={user.webUrl}
                                placeholder="Zadejte url vašeho webu"
                            />
                        </div>

                        <div className="col-12 col-md-4">

                        </div>
                    </div>

                    <label className="section-title pb-3">Údaje o provozovně</label>

                    <div className="pb-1">
                        <LocationForm
                            ref={locationFormRef}
                            location={user.location}
                            onChange={handleChangeLocation}
                        />
                    </div>

                    <hr />

                    <ContactsForm
                        contacts={user.contacts}
                        onChange={handleChangeContacts}
                    />

                    <div className="text-center mb-2">
                        <button className="btn btn-primary btn-lg" onClick={handleSubmit}>
                            Uložit změny
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}