import { Col } from "react-bootstrap";
import IProductCollection from "../../../Interfaces/IProductCollection"
import { HowToVerifySbazarContactAccordion } from "../../Integrations/SbazarAccounts/HowToVerifySbazarContactAccordion";

interface IProps {
    collection: IProductCollection,
}

export const SbazarErrorInfo = (props: IProps) => {
    const { collection } = props;

    if (collection.sbazarBlockedDueToTermsViolation) {
        return <>
            <div className="alert alert-danger">
                <h4 className="alert-heading">Účet byl zablokován Sbazarem za porušení podmínek</h4>
                <hr />

                <p>Nejčastější přičinnou je předčasné obnovování inzerátů nebo nevhodná inzerce porušující pravidla.</p>
            </div>

            <div className="alert alert-success">
                <h4 className="alert-heading">Jak dále postupovat</h4>
                <hr />
                <p>
                    <b>
                        Založte si nový účet na Sbazaru, ověřte tel. číslo a email. adresu a nastavte v Inzerobotovi nový účet. Zajistěte, aby vaše inzerce odpovídala pravidlům Sbazaru.
                    </b>

                </p>

                <HowToVerifySbazarContactAccordion />
            </div>
        </>
    }
    else if (collection.sbazarIncorrectPassword) {
        return <>
            <div className="alert alert-danger">
                <h4 className="alert-heading">Přihlašovací jméno nebo heslo je chybné</h4>
                <hr />

                <p>Prosím zkontrolujte zadávané údaje a pokuste se účet připojit znovu. Zároveň mějte vypnuté dvoufázové ověřování.</p>
            </div>
        </>
    }
    else
        return <>
            <div className="alert alert-danger">
                <h4 className="alert-heading">{collection.sbazarErrorMessage}</h4>
                <hr />

                <p>V případě potřeby kontaktujte podporu aplikace.</p>

                <HowToVerifySbazarContactAccordion />
            </div>
        </>
}