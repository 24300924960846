import { each, filter } from "lodash";
import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { OnlineServer } from "../../../../Enums/OnlineServer";
import IOptionData from "../../../../Interfaces/IOptionData";
import AutobazarEuApiService from "../../../../Services/AutobazarEuApiService";
import { PageTitleBox } from "../../../Others/PageTitleBox";
import { SectionTitle } from "../../../Others/SectionTitle";
import CurrentUserStore from "../../../Stores/CurrentUserStore";
import CheckBox from "../../../UI/Input/CheckBox";
import InputText from "../../../UI/Input/InputText";
import SmartSelectBox from "../../../UI/Input/SmartSelectBox";
import CustomDocumentTitle from "../../../Utils/CustomDocumentTitle";
import { ValidationHelper } from "../../../Utils/ValidationHelper";
import { Link } from "react-router-dom";

interface IProps {
    currentUserStore?: CurrentUserStore
}

const autobazarEuApiService = new AutobazarEuApiService();

export const AutobazarEuExportPage = inject('currentUserStore')(observer((props: IProps) => {
    const user = props.currentUserStore.user;

    const [enabled, setEnabled] = useState(user.uploaderServers.includes(OnlineServer.AutobazarEu));

    const [country, setCountry] = useState<number>(user.autobazarEuCountry);
    const countryRef = useRef<SmartSelectBox>(null);

    const [city, setCity] = useState<number>(user.autobazarEuCity);
    const cityRef = useRef<SmartSelectBox>(null);

    const [search, setSearch] = useState("");
    const isEnabledServer = user.uploaderServers.includes(OnlineServer.AutobazarEu);


    const [countries, setCountries] = useState<IOptionData[]>([]);


    const [cities, setCities] = useState<IOptionData[]>([]);

    const filteredCities = filter(cities, c => {
        if ((c.label.toLowerCase().includes(search) && search.toLowerCase().length > 1) || (city || '').toString() === c.value) {
            return true;
        }
        return false;
    })

    useEffect(() => {

        autobazarEuApiService.getCountries()
            .then(data => {
                var citiesMap = data.others.get("cities") as Map<string, string>;

                let optionsCities: IOptionData[] = [];

                each(citiesMap, (value: any, key: any) => {
                    optionsCities.push({ value: key, label: value })
                });

                setCities(optionsCities)


                var countriesMap = data.others.get("countries") as Map<string, string>;

                let optionsCountries: IOptionData[] = [];

                each(countriesMap, (value: any, key: any) => {
                    optionsCountries.push({ value: key, label: value })
                });

                setCountries(optionsCountries)
            });

        return () => {
            autobazarEuApiService.cancelRequests();
        }

    }, [])

    const submit = () => {
        const inputs = [
            countryRef,
            cityRef
        ];

        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            autobazarEuApiService.postSettings(enabled, city, country)
                .then(data => {

                })
        }
    }

    return (
        <CustomDocumentTitle title="Autobazar.eu - EXPORT">
            <PageTitleBox title="Autobazar.eu - EXPORT" />
            <div className="card mb-0">
                <div className="card-body">
                    <SectionTitle title="Podporované kategorie" />
                    <ul>
                        <li>Hliníkové disky</li>
                        <li>Pneumatiky</li>
                        <li>Hliníkové disky + pneumatiky</li>
                    </ul>

                    <div className="alert  alert-warning">
                        <b>Máte zájem o jinou kategorii? Kontaktuje <Link to="/kontakt">podporu</Link></b>
                    </div>

                    <hr />

                    <p>Export na inzertní server Autobazar.eu je realizován prostřednictvím datového feedu.</p>

                    <SectionTitle title="Jak propojit s Autobazar.eu" className="mt-4" />
                    <p className="text-danger">Dodržte přesné pořadí kroků. V momentě propojení, musíte mít již doplněné potřebné údaje a zapnutý Autobazar.eu, aby se inzeráty již vyskytovaly ve feedu.</p>
                    <ol>
                        <li>Povolte export na této stránce</li>
                        <li>Povolte export u jednotlivých inzerátů v Inzerobotovi v prvním kroku "Marketingové kanály"</li>
                        <li>Vyberte podkategorii, doplňte všechny vyžadované pole. Uložte změny každého inzerátu po úpravě.</li>
                        <li>Kontaktujte Autobazar.eu a předejte jim níže uvedenou URL datového feedu (url se objeví až jakmile je export povolen)</li>
                        <li>Autobazar.eu si bude automaticky stahovat inzerci</li>
                    </ol>

                    {isEnabledServer &&
                        <div className="mt-3">
                            <InputText
                                label="URL feedu"
                                value={`${window.location.protocol}//${window.location.hostname}/api/autobazareu/export?userId=${user.id}`}
                                readonly
                            />
                        </div>}
                </div>
            </div>

            <PageTitleBox title="Nastavení" />
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <CheckBox
                                checked={enabled}
                                label="Povolit export"
                                onChange={(val) => setEnabled(val)}
                            />

                            <div className="mt-4">
                                <SmartSelectBox
                                    ref={countryRef}
                                    label="Místo prodeje - stát"
                                    options={countries}
                                    onChange={(val, key) => setCountry(parseInt(val))}
                                    uid=""
                                    value={country ? country.toString() : ''}
                                    required
                                />
                            </div>

                            <SmartSelectBox
                                ref={cityRef}
                                label="Místo prodeje - město"
                                options={filteredCities}
                                onChange={(val, key) => setCity(parseInt(val))}
                                uid=""
                                value={city ? city.toString() : ''}
                                required
                                placeholder="Začněte psát název města... (min. 2 znaky)"
                                onInputChange={(v) => setSearch(v)}
                            />

                            <button
                                className="btn btn-primary"
                                onClick={submit}
                            >
                                Uložit změny
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>)
}))