import CurrentUserStore from '../../Stores/CurrentUserStore';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import IOptionData from '../../../Interfaces/IOptionData';
import IProductCollection from '../../../Interfaces/IProductCollection';
import { BulkActionType } from '../../../Enums/BulkActionType';
import { find, } from 'lodash';
import { OnlineServer } from '../../../Enums/OnlineServer';
import SmartSelectBox from '../../UI/Input/SmartSelectBox';
import { Alert, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BooleanButton from '../../UI/Input/BooleanButton';
import ProductCollectionApiService from '../../../Services/ProductCollectionsApiService';
import Swal from 'sweetalert2';

interface IProps {
    show: boolean,
    onHide(): void,
    collection: IProductCollection,
    currentUserStore?: CurrentUserStore,
}

const productCollectionApiService = new ProductCollectionApiService();

export const BulkActionModal = inject('currentUserStore')(observer((props: IProps) => {

    const inputActionRef = useRef<SmartSelectBox>(null);
    const user = props.currentUserStore.user;

    const { collection, onHide } = props;
    const [action, setAction] = useState(null);
    const [selectedServers, setSelectedServers] = useState<OnlineServer[]>(user.uploaderServers);

    const onChangeServer = (serverChange: OnlineServer) => {
        setSelectedServers(prevServers => {
            if (prevServers.includes(serverChange)) {
                // If server is already selected, remove it
                return prevServers.filter(server => server !== serverChange);
            } else {
                // If server is not selected, add it
                return [...prevServers, serverChange];
            }
        });
    }

    useEffect(() => {
        return () => {
            productCollectionApiService.cancelRequests();
        }
    }, [])

    const onSubmit = () => {
        productCollectionApiService.bulkAction(collection, action, selectedServers)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: `Akce budou provedeny`,
                        html: `V závislosti na počtu inzerátů tato akce může trvat i pár minut`,
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: `OK`,
                    }).then((result) => {
                        if (result.value) {
                            onHide();
                        }
                    })
                }
            });
    }

    let actionOptions: IOptionData[] = [];

    if (user.activeUploader) {
        actionOptions.push({
            label: <><i className="bi bi-check2-square text-primary mr-1" />Provést kontrolu vystavení inzerátů</>,
            value: BulkActionType.Check
        })
        actionOptions.push({
            label: <><i className="fas fa-exclamation-triangle text-danger" /> <i className="fas fa-arrow-right"></i> <i className="fas fa-upload text-primary mr-1" />Nahrát odmítnuté inzeráty</>,
            value: BulkActionType.UploadError
        })
        //  actionOptions.push({ label: 'Zrušit všechny akce', value: BulkActionType.CancelAllActions })

        actionOptions.push({
            label: <><i className="fas fa-star text-warning mr-1"></i>Topovat</>,
            value: BulkActionType.Top
        })
        actionOptions.push({
            label: <><i className="fas fa-retweet text-primary mr-1" />Obnovit vystavené</>,
            value: BulkActionType.RenewOnline
        })
    }

    actionOptions.push({
        label: <><i className="fas fa-balance-scale text-warning mr-1"></i>Přepočítat ceny dle aktuálních kurzů</>,
        value: BulkActionType.UpdatePricesByExchangeRates
    })

    actionOptions.push({
        label: <><i className="fas fa-toggle-off text-danger mr-1"></i>Stáhnout z prodeje</>,
        value: BulkActionType.DisableAll
    })
    actionOptions.push({
        label: <><i className="fas fa-toggle-on text-success mr-1"></i>Vrátit do prodeje</>,
        value: BulkActionType.EnableAll
    })

    if (user.activeUploader) {
        actionOptions.push({
            label: <><i className="fa fa-circle text-secondary mr-1"></i> <i className="fas fa-arrow-right"></i> <i className="fas fa-upload text-primary mr-1" />Nahrát nevystavené</>,
            value: BulkActionType.UploadOffline
        })

        actionOptions.push({
            label: <>[Servisní akce] - Editovat - Při nekonzistenci dat</>, value: BulkActionType.Edit
        })
    }

    const selectedOption = find(actionOptions, { value: action });

    const actionsSupportedToSelectServer = [
        BulkActionType.Check,
        BulkActionType.UploadError,
        BulkActionType.Top,
        BulkActionType.UploadOffline,
        BulkActionType.RenewOnline,
        BulkActionType.Edit,
    ]

    return (
        <Modal centered={true} show={props.show} size="lg" onHide={() => props.onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>Hromadná akce</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <SmartSelectBox
                            ref={inputActionRef}
                            label="Vyberte akci, kterou chcete provést"
                            onChange={(ids, k) => setAction(ids)}
                            options={actionOptions}
                            uid="action"
                            value={action}
                            required
                            placeholder={"Vyberte"}
                        />

                        {action === BulkActionType.UploadError &&
                            <Alert variant="warning">
                                <Alert.Heading>Upozornění</Alert.Heading>
                                <p>
                                    Jestliže se chystáte nahrát inzeráty, které vám Bazoš úmyslně smazal, je potřeba je před dalším nahráním upravit. Upravte si texty a obrázky do jiné podoby.
                                </p>
                                <hr />
                                <p className="mb-0">
                                    <b>Opakované nahrání odmítnutých inzerátů povede k blokaci.</b>
                                </p>
                            </Alert>}

                        {action === BulkActionType.Edit &&
                            <Alert variant="warning">
                                <Alert.Heading>Upozornění</Alert.Heading>
                                <p>
                                    Provede se úprava inzerátů na straně inzertních serverů.
                                </p>
                                <hr />
                                <p className="mb-0">
                                    <b>Tato akce je vhodná, pokud došlo k nekonzistenci dat.</b>
                                </p>
                            </Alert>}

                        {action === BulkActionType.Top &&
                            <Alert variant="warning">
                                <Alert.Heading>Upozornění</Alert.Heading>
                                <p className="mb-0">
                                    <b>Ujistěte se, že máte dostatečný počet volných poukázek k topování.</b>
                                </p>
                            </Alert>}

                        {action === BulkActionType.RenewOnline &&
                            <>
                                <Alert variant="warning">
                                    <Alert.Heading>Upozornění</Alert.Heading>
                                    <p className="mb-0">
                                        <b>Opakované obnovování může vést k blokaci.</b>
                                    </p>
                                </Alert>

                                <Alert variant="info">
                                    <p className="mb-0">
                                        <b>Obnovené nebudou inzeráty, které byly nahrány v posledních 24 hodinách.</b>
                                    </p>
                                </Alert>
                            </>
                        }

                        {action === BulkActionType.UpdatePricesByExchangeRates &&
                            <Alert variant="info">
                                <p className="mb-0">
                                    <b>Přepočet se provede pouze tam, kde rozdíl přesahuje {user.priceExchangeRateDiffPercentageWarning} %. </b>
                                    Tuto hodnotu lze změnit v <Link to='/nastaveni/aplikace'>nastavení aplikace</Link>.
                                </p>
                            </Alert>}

                        {actionsSupportedToSelectServer.includes(action) && <div className="col-lg-12">
                            <div className="row">
                                {user.uploaderServers.includes(OnlineServer.BazosCz) && <div className="col-lg-3">
                                    <BooleanButton
                                        checked={selectedServers.includes(OnlineServer.BazosCz)}
                                        label='Bazoš.cz'
                                        onChange={() => onChangeServer(OnlineServer.BazosCz)}
                                    />
                                </div>}

                                {user.uploaderServers.includes(OnlineServer.BazosSk) && <div className="col-lg-3">
                                    <BooleanButton
                                        checked={selectedServers.includes(OnlineServer.BazosSk)}
                                        label='Bazoš.sk'
                                        onChange={() => onChangeServer(OnlineServer.BazosSk)}
                                    />
                                </div>}

                                {action !== BulkActionType.Top && <div className="col-lg-3">
                                    {user.uploaderServers.includes(OnlineServer.Sbazar) && <BooleanButton
                                        checked={selectedServers.includes(OnlineServer.Sbazar)}
                                        label='Sbazar'
                                        onChange={() => onChangeServer(OnlineServer.Sbazar)}
                                    />}
                                </div>}
                            </div>
                        </div>}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => props.onHide()}
                    variant="outline-secondary"
                >
                    Zrušit
                </Button>
                {selectedOption &&
                    <Button
                        variant="outline-primary"
                        onClick={onSubmit}
                    >
                        {selectedOption.label}
                    </Button>}
            </Modal.Footer>
        </Modal>
    );
}))