import { observable, action, makeAutoObservable } from 'mobx'
import { filter, findIndex, orderBy, remove } from 'lodash';
import IProductLog from '../../Interfaces/IProductLog';
import { OnlineServer } from '../../Enums/OnlineServer';

export default class ProductLogStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IProductLog[] = [];


    @action
    addRecords(data: any): any {
        data.forEach((record: IProductLog) => this.addRecord(record));
    }

    @action
    private addRecord(record: IProductLog) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    getByProductServer(productId: string, server: OnlineServer): IProductLog[] {
        const logs = orderBy(filter(this.list, (log: IProductLog) =>
        {
            if ((log.server === null || log.server === server) && productId === log.productId) {
                return true;
            }
            return false;
        }
        ), ['dateUtc'], ['desc']);

        return logs;
    }

    getByIds(ids: string[]): IProductLog[] {
        return filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
    }
}