import { OnlineServer } from "../Enums/OnlineServer";
import IProductCollection from "../Interfaces/IProductCollection";
import IResponseData from "../Interfaces/IResponseData";
import { ICollectionAdvancedSettingsRequest } from "../Interfaces/Requests/ProductCollections/ICollectionAdvancedSettingsRequest";
import BaseApiService from "./BaseApiService";

export default class ProductCollectionApiService extends BaseApiService {

    public postAdvancedSettings(id: string, settings: ICollectionAdvancedSettingsRequest): Promise<IResponseData> {
        return this.postRequest(`productcollections/${id}/advanced-settings`, settings, {})
            .then(response => {
                return response.data;
            })
    }

    public getCollection(id: string): Promise<IResponseData> {
        return this.getRequest(`productcollections/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public getCollections(): Promise<IResponseData> {
        return this.getRequest(`productcollections`, {})
            .then(response => {
                return response.data;
            })
    }

    public createCollection(collection: IProductCollection): Promise<IResponseData> {
        return this.postRequest(`productcollections`, collection, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteCollection(collection: IProductCollection): Promise<IResponseData> {
        return this.deleteRequest(`productcollections/${collection.id}`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public bulkAction(collection: IProductCollection, action: any, servers: OnlineServer[]): Promise<IResponseData> {
        return this.postRequest(`productcollections/${collection.id}/bulk-action`, { action: action, servers: servers }, {})
            .then(response => {
                return response.data;
            })
    }
}