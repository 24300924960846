import { AutoImporterErrorType } from "../../../Enums/AutoImporterErrorType";
import { AutoImporterState } from "../../../Enums/AutoImporterState"
import IAutoImporter from "../../../Interfaces/IAutoImporter"

interface IProps {
    autoImporter: IAutoImporter
}

export const AutoImporterStateBadge = (props: IProps) => {

    const autoImporter = props.autoImporter;

    if (!autoImporter.active) {
        return <span className="badge badge-secondary">Vypnuto</span>
    }
    else if (autoImporter.state === AutoImporterState.Queue) {
        return (
            <span className="badge badge-warning">Ve frontě</span>)
    }
    else if (autoImporter.state === AutoImporterState.Running) {
        return (
            <span className="badge badge-success">Probíhá import</span>)
    }
    else if (autoImporter.state === AutoImporterState.Cancelling) {
        return (
            <span className="badge badge-danger">Probíhá rušení</span>)
    }
    else if (autoImporter.error) {
        if (autoImporter.errorType === AutoImporterErrorType.Exception) {
            return (
                <span className="badge badge-danger">Probíhá údržba</span>)
        }
        else if (autoImporter.errorType === AutoImporterErrorType.InvalidSetup) {
            return (
                <span className="badge badge-danger">Chyba nastavení</span>)
        }
        else if (autoImporter.errorType === AutoImporterErrorType.Unauthorized) {
            return (
                <span className="badge badge-warning">Účet není přihlášen</span>)
        }
        else if (autoImporter.errorType === AutoImporterErrorType.InvalidInput) {
            return (
                <span className="badge badge-danger">Chyba vstupních dat</span>)
        }
    }
    else if (autoImporter.active) {
        return (
            <span className="badge badge-secondary">Aktivní</span>)
    }
 
    return null;
}