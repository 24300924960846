import { find } from 'lodash';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Currency } from '../../Enums/Currency';
import CurrencyHelper from '../../Helpers/CurrencyHelper';
import WebsiteHelper from '../../Helpers/WebsiteHelper';
import IInputField from '../../Interfaces/IInputField';
import { IWebsite } from '../../Interfaces/IWebsite';
import IWebsitePricingItem from '../../Interfaces/IWebsitePricingItem';
import { IOrderWebsiteRequest } from '../../Interfaces/Requests/IOrderWebsiteRequest';
import BaseComponent from '../BaseComponent';
import { SeparateThousands } from '../Others/SeparateThousands';
import InputText from '../UI/Input/InputText';
import SelectBox from '../UI/Input/SelectBox';

interface IState {
    model: IOrderWebsiteRequest,
}

interface IProps {
    show: boolean
    onHide: Function,
    website: IWebsite,
    currency: Currency
}

export default class WebsiteSetCustomDomainModal extends BaseComponent<IProps, IState> {

    private inputCustomName = React.createRef<InputText>();
    private inputProductsLimitCount = React.createRef<SelectBox>();
    private inputPaymentPeriod = React.createRef<SelectBox>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            model: {
                customDomain: '',
                paymentPeriodMonths: 6,
                productsLimitCount: 0,
            }
        }

        this.inputCustomName = React.createRef();
        this.inputProductsLimitCount = React.createRef();
        this.inputPaymentPeriod = React.createRef();
    }

    handleChange(key: any, value: any) {
        this.setState({
            model: {
                ...this.state.model,
                [key]: value,
            }
        });
    }

    getInputs(): React.RefObject<IInputField>[] {
        return [
            this.inputCustomName,
            this.inputProductsLimitCount,
            this.inputPaymentPeriod,
        ];
    }

    handleSubmit() {
        const model = this.state.model;

        const isValid = this.validateInputs(this.getInputs());

        if (isValid) {
            this.websitesApiService.orderWebsite(this.props.website, model)
                .then(data => {
                    if (data.success) {
                       
                        this.props.onHide();
                    }
                })
        }
    }

    render() {
        const model = this.state.model;
        const website = this.props.website;
        const pricingOptions = WebsiteHelper.getPricingOptions(this.props.currency);
        const pricingItems = WebsiteHelper.getPricing(this.props.currency);
        const selectedPricing: IWebsitePricingItem = find(pricingItems, { productMax: model.productsLimitCount });
        let sale = 0;

        if (selectedPricing && model.paymentPeriodMonths === 12) {
            sale = ((selectedPricing.pricePerMonth * 12) / 100) * 10;
        }

        let amountToPay: number = 0;

        if (selectedPricing) {
            amountToPay = (selectedPricing.pricePerMonth * model.paymentPeriodMonths) - sale;
        }
       
        return (
            <Modal centered={true} show={this.props.show} onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Nastavit vlastní doménu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Přesuňte váš web na vlastní doménu. </p>

                        <InputText
                            label="Dočasná doména"
                            value={website.demoDomain}
                            readonly
                        />

                        <InputText
                            ref={this.inputCustomName}
                            label="Vaše doména"
                            value={model.customDomain}
                            required={true}
                            onChange={this.handleChange.bind(this, 'customDomain')}
                            placeholder="vasedomena.cz"
                        />

                        <SelectBox
                            ref={this.inputProductsLimitCount}
                            label="Počet inzerátů na webu"
                            options={pricingOptions}
                            onChange={this.handleChange.bind(this, 'productsLimitCount')}
                            value={model.productsLimitCount}
                            defaultValue="Vyberte"
                            required
                        />

                        <SelectBox
                            ref={this.inputPaymentPeriod}
                            label="Fakturační období"
                            options={
                                [
                                    { label: '6 měsíců', value: 6 },
                                    { label: '12 měsíců (sleva 10 %)', value: 12 }
                                ]
                            }
                            onChange={this.handleChange.bind(this, 'paymentPeriodMonths')}
                            value={model.paymentPeriodMonths}
                            defaultValue="Vyberte"
                            required
                        />

                        {selectedPricing && model.paymentPeriodMonths && <p>Zašleme vám fakturu na částku <b><SeparateThousands number={amountToPay} /> {CurrencyHelper.getSymbol(this.props.currency)}</b> nejpozději do 24 hodin.</p>}

                        {sale > 0 && <h6 className="text-success">Ušetříte <SeparateThousands number={sale} /> {CurrencyHelper.getSymbol(this.props.currency)}</h6>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" size="lg" onClick={this.handleSubmit.bind(this)}>
                        Objednat prodejní web
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}