import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { observer, inject } from 'mobx-react';
import CurrentUserStore from '../Stores/CurrentUserStore';
import IUser from '../../Interfaces/IUser';
import { ToastContainer } from 'react-toastify';
import StoreUtil from '../Stores/StoreUtil';
import ReactGA from 'react-ga';
import Ably from 'ably';
import CustomDocumentTitle from '../Utils/CustomDocumentTitle';
import { Link } from 'react-router-dom';
import { Header } from '../UI/Header/Header';
import { Footer } from './Footer';
import UserApiService from '../../Services/UserApiService';

interface IProps {
    currentUserStore?: CurrentUserStore,
    children?: React.ReactNode,
    dontShowUI?: boolean,
}

const userApiService = new UserApiService();

export const DefaultLayout = inject('currentUserStore')(observer((props: IProps) => {

    const [loading, setLoading] = useState(true);
    const user: IUser = props.currentUserStore.user;

    useEffect(() => {
        userApiService.getCurrentUser()
            .then(user => {
                setLoading(false);

                const ablyClient = new Ably.Realtime({
                    authCallback: async (tokenParams, callback) => {
                        userApiService.ablyAuth()
                            .then(response => {
                                const tokenData = response.data as any;
                                callback(null, tokenData)
                            })
                            .catch((error: AxiosError) => {
                                if (error.response?.status &&
                                    error.response?.status !== 401 &&
                                    error.response?.status !== 503) {
                                    throw error;
                                }
                            })
                    },
                    closeOnUnload: true,
                });
                ablyClient.connection.on('connected', function () {
                    const channelName: string = 'private-user-' + user.id;
                    const subChannel = ablyClient.channels.get(channelName);

                    subChannel.subscribe((msg) => {
                        if (msg.name === "onStoreChange") {
                            StoreUtil.process(msg.data);
                        }
                    });
                });
            })

        if (process.env.NODE_ENV !== 'development') {
            ReactGA.initialize('UA-104443424-5');
            ReactGA.pageview(window.location.pathname + window.location.search);
        }

        return (() => {
            return userApiService.cancelRequests();
        })
    }, [])

    if (loading) {
        return <>
            <div style={{ marginTop: '10%' }}>
                <CustomDocumentTitle title="Načítání...">
                    <div className="py-3">
                        <div className="d-flex justify-content-center">
                            <div className="lds-dual-ring"></div>
                        </div>

                        <br />
                        <div className="text-center">
                            <h4>Aplikace se načítá...</h4>
                        </div>
                    </div>
                </CustomDocumentTitle>
            </div>
        </>
    }
    else if (!user) {
        return null;
    }
    else if (props.dontShowUI) {
        return <div className="wrapper">
            <div className="container-fluid">
                {props.children}
            </div>
            {/* end container-fluid */}
        </div>
    }
    else {
        return (
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnHover={true}
                    draggable
                />

                <Header user={user} />

                {(user.productsCount > user.productLimitMax && user.productLimitMax !== -1) &&
                    <div className="alert alert-danger text-center m-0 w-100">
                        Aktuálně překračujete limit zaplacené licence. Máte {user.productsCount} inzerátu, ačkoli licenci máte pouze na {user.productLimitMax} inzerátů.
                        Prosím, přejděte na vyšší licenci. Kontaktujte <Link to="/kontakt">podporu</Link> pro navýšení. Děkujeme
                    </div>
                }

                {user.productsCount === user.productLimitMax &&
                    <div className="alert alert-warning text-center m-0 w-100">
                        Dosáhli jste maximálního počtu inzerátů. Pro navýšení licence prosím kontaktujte <Link to="/kontakt">podporu</Link>
                    </div>
                }

                {/*
                <div className="alert alert-info text-primary text-center m-0 w-100">
                    <b>UPDATE 23.9.2023 - Nahrávání je opět v plném nasazení a nedochází k jakémukoli zpoždění 💪</b>
                </div>

                user.lockoutEnabled === true &&
                    <div className="alert alert-danger text-center m-0 w-100">
                        Účet je neaktivní a nelze jej dále používat, zřejmě z důvodu neuhrazené licence. Prosím kontaktujte nás na <b><InzerobotEmail /></b>
                    </div>
                */}

                {/*     
               <div className="alert alert-warning text-center m-0 w-100">
                   Během Velikonoc probíhá údržba aplikace. Zdánlivě <b>"seknuté nahrávání" nerušte, bude dokončeno později. </b>Děkujeme
                </div>
              */}
                {/* End Navigation Bar*/}
                {/* page wrapper start */}
                <div className="wrapper">
                    <div className="container-fluid">
                        {props.children}
                    </div>
                    {/* end container-fluid */}
                </div>
                {/* page wrapper end */}
                {/* Footer */}
                <Footer />
                {/* End Footer */}
            </>
        );
    }
}))