import { Link } from 'react-router-dom';
import CustomDocumentTitle from '../Utils/CustomDocumentTitle';

export const NotFound = () => {
    return (
        <CustomDocumentTitle title="Odkaz není funkční" >
            <div className="card mt-4">
                <div className="card-body">
                    <div className="py-3">
                        <div className="d-flex justify-content-center mb-3 mt-2">
                            <i className="far fa-frown tx-secondary fa-3x"></i>
                        </div>

                        <br />
                        <div className="text-center">
                            <h3>Jejda, tento odkaz není funkční...</h3>
                        </div>
                        <br />
                        <div className="text-center">
                            <p>Tento obsah již neexistuje. Zřejmě máte špatný odkaz.</p>
                        </div>

                        <div className="d-flex justify-content-center py-3">
                            <Link to='/'>
                                <button type="button" className="btn btn-primary">
                                    <i className="fas fa-arrow-right mr-2"></i> Přejít na nástěnku
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    )
}