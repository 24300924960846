import React from "react"
import { Currency } from "../../Enums/Currency"
import WebsiteHelper from "../../Helpers/WebsiteHelper"
import { WebsitePricingTable } from "./WebsitePricingTable"

interface IProps {
    currency: Currency
}

export const WebsitesPricing = (props: IProps) => {
    const items = WebsiteHelper.getPricing(props.currency);
    return <WebsitePricingTable items={items} currency={props.currency} />
}