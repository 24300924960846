import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class AutobazarEuApiService extends BaseApiService {

    readonly baseUrl: string = "autobazareu";
    
    public allowExport(): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/export/allow`, { }, {})
            .then(response => {
                return response.data;
            })
    }

    public disallowExport(): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/export/disallow`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public getCountries(): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/countries`, {})
            .then(response => {
                return response.data;
            })
    }

    public postSettings(active: boolean, cityId: number, countryId: number): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/settings`,
            {
                active: active,
                cityId: cityId,
                countryId: countryId
            },
            {})
            .then(response => {
                return response.data;
            })
    }
}