import IResponseData from "../Interfaces/IResponseData";
import ISbazarSettings from "../Interfaces/ISbazarSettings";
import { IServerAccount } from "../Interfaces/IServerAccount";
import { IAddSbazarAccountRequest } from "../Interfaces/Requests/SbazarAccounts/IAddSbazarAccountRequest";
import { ISbazarLoginRequest } from "../Interfaces/Requests/SbazarAccounts/ISbazarLoginRequest";
import { ISbazarSettingsRequest } from "../Interfaces/Requests/SbazarAccounts/ISbazarSettingsRequest";
import BaseApiService from "./BaseApiService";

export default class SbazarAccountsApiService extends BaseApiService {

    readonly baseUrl: string = "sbazar-accounts";

    public addAccount(addSbazarRequest: IAddSbazarAccountRequest): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}`, addSbazarRequest, {})
            .then(response => {
                return response.data;
            })
    }

    public verifyLogin(id: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/login/verify`, {}, {})
            .then(response => {
                return response.data;
            })
    }


    public deleteAccount(id: string): Promise<IResponseData> {
        return this.deleteRequest(`${this.baseUrl}/${id}`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public editAccount(id: string, account: IServerAccount): Promise<IResponseData> {
        return this.putRequest(`${this.baseUrl}/${id}`, account, {})
            .then(response => {
                return response.data;
            })
    }

    public postSettings(id: string, settings: ISbazarSettingsRequest): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/settings`, settings, {})
            .then(response => {
                return response.data;
            })
    }

    public login(id: string, form: ISbazarLoginRequest): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/login`, form, {})
            .then(response => {
                return response.data;
            })
    }
}