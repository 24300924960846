import { useEffect } from "react";
import IUser from "../../Interfaces/IUser"
import FacebookCatalogsApiService from "../../Services/FacebookCatalogsApiService";

interface IProps {
    user: IUser,
}

const facebookCatalogsApiService = new FacebookCatalogsApiService();

export const FacebookShopConnectCard = (props: IProps) => {

    useEffect(() => {
        return () => {
            facebookCatalogsApiService.cancelRequests();
        }
    }, [])

    const { user } = props;

    function handleReload() {
        facebookCatalogsApiService.reload();
    }

    return (
        <div className="card mb-0">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <p><b>Facebook eCommerce umožňuje prezentovat vaše produkty na Facebooku, Instagramu i Marketplace jednotným způsobem.</b> </p>
                        <p>Pokud máte obchod s přechodem k zaplacení, vaše produkty můžou být viditelné i kupujícím na Marketplace (<b>Marketplace zatím dostupný jen v USA - <a target="_blank" href="https://www.facebook.com/business/help/4223915744397229">nápověda</a></b>)</p>
                        {/*   <OAuth
                            provider="Facebook"
                            disabled={false}
                        >
                            <>
                                {user.facebookConnected ? <><i className="fa fa-check social-connect-success mr-2"></i><span>Facebook připojen</span></> :
                                    <>  <i className="fab fa-facebook-f m-r-10"></i><span>Připojit Facebook</span></>}
                            </>
                        </OAuth>

                        {user.facebookConnected &&
                            <button className="btn btn-secondary" onClick={() => handleReload()}>Načíst katalogy</button>}*/}
                    </div>
                </div>
            </div>
        </div>)
}