import { filter, find, first, indexOf } from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { AutoImporterSourceType } from '../../../Enums/AutoImporterSourceType';
import { AutoImporterState } from '../../../Enums/AutoImporterState';
import { OnlineServer } from '../../../Enums/OnlineServer';
import { VehicleType } from '../../../Enums/VehicleType';
import IAutoImporter from '../../../Interfaces/IAutoImporter';
import BaseComponent from '../../BaseComponent';
import { PageTitleBox } from '../../Others/PageTitleBox';
import LoadingScreen from '../../Screens/LoadingScreen';
import FacebookCatalogStore from '../../Stores/FacebookCatalogStore';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import WebsiteStore from '../../Stores/WebsiteStore';
import InputText from '../../UI/Input/InputText';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { IAutoImporterContent } from '../../../Interfaces/IAutoImporterContent';
import AutoImporterSettings from '../../Integrations/Autoimporters/AutoImporterSettings';
import AutoImporterTipcarsContentEditor from '../../Integrations/Autoimporters/Tipcars/AutoimporterTipcarsContentEditor';
import { AutoImporterStateBox } from '../../Integrations/Autoimporters/AutoImporterStateBox';
import { AutoImporterSubmit } from '../../Integrations/Autoimporters/AutoImporterSubmit';

interface IState {
    autoImporter: IAutoImporter,
    loading: boolean,
    validateErrors: boolean,
}

interface IProps {
    productCollectionStore?: ProductCollectionStore,
    facebookCatalogStore?: FacebookCatalogStore,
    websiteStore?: WebsiteStore,
}

@inject('productCollectionStore', 'facebookCatalogStore', 'websiteStore')
@observer
export default class AutoImporterTipcarsPage extends BaseComponent<IProps, IState> {

    private registrationCode = React.createRef<InputText>();
    private companyId = React.createRef<InputText>();
    private autoImporterSettings = React.createRef<AutoImporterSettings>();
    private contentEditor = React.createRef<AutoImporterTipcarsContentEditor>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            autoImporter: {
                id: null,
                active: true,
                collectionIds: [],
                sourceUrl: '',
                sourceType: AutoImporterSourceType.Tipcars,
                userId: '',
                errorUtc: null,
                lastImportUtc: null,
                defaultEnabledNewProduct: true,
                error: false,
                errorMessage: '',
                isSetuped: false,
                deleteProductAfterNotFoundInList: true,
                updateProductContent: true,
                doNotImportForbiddenKeywords: ['rezervace', 'prodano'],
                state: AutoImporterState.Inactive,
                vehicleTypesToImport: [
                    VehicleType.Car,
                    VehicleType.Commercial,
                    VehicleType.Truck,
                    VehicleType.Motorcycle,
                    VehicleType.QuadBike,
                    VehicleType.Trailers,
                    VehicleType.Caravan,
                    VehicleType.WorkingMachines,
                    VehicleType.Bus,
                ],
                tipcarsSettings: {
                    companyId: '',
                    registrationCode: '',
                },
                contents: [],
            },
            loading: true,
            validateErrors: false,
        }

        this.registrationCode = React.createRef();
        this.companyId = React.createRef();
        this.autoImporterSettings = React.createRef();
        this.contentEditor = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.autoimportersApiService.getList()
            .then(data => {
                const items: IAutoImporter[] = filter(data.records.autoImporters.items, { sourceType: AutoImporterSourceType.Tipcars })

                const autoimporter = first(items);

                if (autoimporter) {
                    this.setState({
                        autoImporter: autoimporter
                    })
                }

                this.setState({
                    loading: false
                })
            })

        this.websitesApiService.getWebsites();
        this.facebookCatalogsApiService.getCatalogs();
    }

    handleChange(key: any, value: any) {
        this.setState({
            autoImporter: {
                ...this.state.autoImporter,
                [key]: value
            }
        })
    }

    handleChangeContent(type: VehicleType, server: OnlineServer, value: any, key: keyof IAutoImporterContent) {
        let contents = this.state.autoImporter.contents;
        let content = find(contents, { onlineServer: server, vehicleType: type });

        const index = indexOf(contents, content);

        content = {
            ...content,
            [key]: value
        }

        contents[index] = content;

        this.setState({
            autoImporter: {
                ...this.state.autoImporter,
                contents: contents
            }
        })
    }

    handleSubmit() {
        const isValid = this.validate();

        this.setState({
            validateErrors: !isValid
        })

        if (isValid) {
            const autoimporter = this.state.autoImporter;

            if (autoimporter.id) {
                // edit

                this.autoimportersApiService.editAutoimporter(autoimporter.id, autoimporter)
                    .then(data => {
                        const items: IAutoImporter[] = data.records.autoImporters.items;

                        const autoimporter = first(items);

                        if (autoimporter) {
                            this.setState({
                                autoImporter: autoimporter
                            })
                        }
                    });
            }
            else {
                // post
                this.autoimportersApiService.postAutoimporter(autoimporter)
                    .then(data => {
                        const items: IAutoImporter[] = data.records.autoImporters.items;

                        const autoimporter = first(items);

                        if (autoimporter) {
                            this.setState({
                                autoImporter: autoimporter
                            })
                        }
                    });
            }
        }
    }

    getTipcarsInputs() {
        return [this.registrationCode, this.companyId];
    }

    validate() {
        const isValidTipcarsSettings = this.validateInputs(this.getTipcarsInputs());

        let isValidSettings = true;

        const autoImporter = this.state.autoImporter;

        if (autoImporter.id) {
            isValidSettings = this.autoImporterSettings.current.validate();
        }
        const isValidEditor = this.validateInputs([this.contentEditor]);

        if (isValidTipcarsSettings && isValidSettings && isValidEditor) {
            return true;
        }
        return false;
    }

    handleChangeTipcars(value: any, key: string) {
        this.setState({
            autoImporter: {
                ...this.state.autoImporter,
                tipcarsSettings: {
                    ...this.state.autoImporter.tipcarsSettings,
                    [key]: value
                }
            }
        })
    }

    renderSettings() {
        const autoImporter = this.state.autoImporter;

        return (
            <div className="card mb-2" >
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label className="section-title mt-0 mb-4">Nastavení importu</label>

                            <InputText
                                ref={this.registrationCode}
                                label="Registrační kód"
                                value={autoImporter.tipcarsSettings.registrationCode}
                                onChange={(value) => this.handleChangeTipcars(value, 'registrationCode')}
                                required
                            />

                            <InputText
                                ref={this.companyId}
                                label="Firma ID"
                                value={autoImporter.tipcarsSettings.companyId}
                                onChange={(value) => this.handleChangeTipcars(value, 'companyId')}
                                required
                            />

                            {autoImporter.isSetuped === false &&
                                <button
                                    className="btn btn-primary btn-lg float-right"
                                    onClick={this.handleSubmit.bind(this)}
                                >
                                    Pokračovat
                                </button>}
                        </div>

                        <div className="col-12 col-md-6 mt-3 mt-md-0">

                        </div>
                    </div>

                    {autoImporter.isSetuped &&
                        <>
                            <hr />
                            <AutoImporterSettings
                                ref={this.autoImporterSettings}
                                autoImporter={autoImporter}
                                handleChange={(value, key) => this.handleChange(key, value)}
                            />
                        </>}
                </div>
            </div>)
    }

    render() {
        const loading = this.state.loading;
        const autoImporter = this.state.autoImporter;

        return (
            <CustomDocumentTitle title="Tipcars.cz - importér">
                <div>
                    <PageTitleBox title="Tipcars.cz - importér" />

                    {loading ? <LoadingScreen /> :
                        <>
                            <AutoImporterStateBox
                                autoImporter={autoImporter}
                            />

                            {this.renderSettings()}

                            {autoImporter.isSetuped && <>
                                <PageTitleBox title="Obsah inzerátů" />

                                <AutoImporterTipcarsContentEditor
                                    ref={this.contentEditor}
                                    autoImporter={autoImporter}
                                    handleChange={(type, server, value, key) => this.handleChangeContent(type, server, value, key)}
                                />

                                <AutoImporterSubmit
                                    autoImporter={autoImporter}
                                    onChange={(value, key) => this.handleChange(key, value)}
                                    onSubmit={() => this.handleSubmit()}
                                    validateErrors={this.state.validateErrors}
                                />
                            </>}
                        </>}
                </div>
            </CustomDocumentTitle >
        )
    }
}