import { filter, find, isNil } from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import IProduct from "../../../../Interfaces/IProduct";
import { IValidationFormRef } from "../../../../Interfaces/IValidationFormRef";
import { ServerSettingAccordion } from "../../ServerSettingsAccordion";
import IOptionData from "../../../../Interfaces/IOptionData";
import IInputField from "../../../../Interfaces/IInputField";
import { ValidationHelper } from "../../../Utils/ValidationHelper";
import { IGoogleCategory } from "../../../../Interfaces/IGoogleCategory";
import SmartSelectBox from "../../../UI/Input/SmartSelectBox";

interface IProps {
    product: IProduct,
    onChange(value: any, key: any): void,
    categories: IGoogleCategory[],
}

export const ServerGoogleCategoriesAccordion = forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const { product, onChange, categories } = props;
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        checkIsValid();
    }, [])

    useEffect(() => {
        checkIsValid();
    }, [props.product]);

    const [isValidAndComplete, setIsValidAndComplete] = useState(false);
    
    const categoryRef = useRef<SmartSelectBox>(null);

    const checkIsValid = () => {
        const inputs = getInputs();
        const isValidAndComplete = ValidationHelper.isValid(inputs) &&
            !isNil(product.googleProductCategoryId) && product.googleProductCategoryId !== 0;

        setIsValidAndComplete(isValidAndComplete);
    };

    const getInputs = () => {
        const inputs: React.MutableRefObject<IInputField>[] = [];
        inputs.push(
            categoryRef
        );
        return inputs;
    };

    useImperativeHandle(ref, () => ({
        getInputs
    }));

    var options = categories.map(c => {
        var op: IOptionData = { label: c.label, value: c.id };
        return op;
    })

    const filteredOptions = filter(options, c => {
        if ((c.label.toLowerCase().includes(search) && search.toLowerCase().length > 1) ||
            product.googleProductCategoryId?.toString() === c.value?.toString()) {
            return true;
        }
        return false;
    })

    const selectedOption = find(options, { value: product.googleProductCategoryId })
    let categoryText : string = selectedOption?.label ?? "";

    const replaceDashes = (text: string): string => {
        return text.replace(/ - /g, " > ");
    };

    categoryText = replaceDashes(categoryText);
    return (
        <ServerSettingAccordion
            categoryText={categoryText}
            headingText="Google Merchant"
            isValid={isValidAndComplete}
            isComplete={isValidAndComplete}
        >
            <SmartSelectBox
                ref={categoryRef}
                label="Kategorie"
                options={filteredOptions}
                onChange={(value => {
                    onChange("googleProductCategoryId", value)
                })}
                uid=""
                value={product.googleProductCategoryId}
                required={product.googleMerchant}
                placeholder="Začněte psát název kategorie... (min. 2 znaky)"
                onInputChange={(value => setSearch(value))}
            />
        </ServerSettingAccordion>)
})