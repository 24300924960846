import React from "react";
import CurrencyHelper from "../../../Helpers/CurrencyHelper";
import IProformaInvoice from "../../../Interfaces/IProformaInvoice";
import ProformaInvoicesApiService from "../../../Services/ProformaInvoicesApiService";
import { SeparateThousands } from "../../Others/SeparateThousands";

interface IProps {
    proformaInvoices: IProformaInvoice[]
}

const proformaInvoicesApiService = new ProformaInvoicesApiService();

export const NotPaidInvoicesWidget = (props: IProps) => {
    const proformaInvoices = props.proformaInvoices;

    return (
        <div className="card mb-1">
            <div className="card-header">
                Neuhrazené faktury
            </div>
            <div className="card-body">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center align-items-center">
                        {proformaInvoices.length > 0 ?
                            <>
                                {proformaInvoices.map(proforma => {
                                    return (
                                        <div className="btn-block" key={proforma.id}>
                                            <a target="_blank" href={`/api/${proformaInvoicesApiService.getRelativeUrlForDownloadProforma(proforma.id)}`}>
                                                <button className="btn btn-warning text-center btn-block">
                                                    <i className="fas fa-file-invoice mr-1"></i><SeparateThousands number={proforma.totalPrice} /> {CurrencyHelper.getLabel(proforma.currency)} - Stáhnout
                                                </button>
                                            </a>
                                        </div>)

                                })}
                            </> :
                            <>
                                <h6 className="mt-1">Žádné faktury k úhradě :-)</h6>
                                <p className="m-0">
                                    <small>Máte vše uhrazené.</small>
                                </p>
                            </>}
                    </div>
                </div>
            </div>
        </div>)
}