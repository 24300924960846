import React from "react"
import { BenefitItem } from "../Others/BenefitItem"

export const WebmailBenefitsScreen = () => {

    return (
        <div className="card mb-1">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <BenefitItem
                            title="Profesionální"
                            description="Jak na vás působí někdo, kdo se tváří jako firma, ale píše z @seznam.cz, @volny.cz apod? Může to dokonce působit, že se za vás někdo vydává..."
                        />

                        <BenefitItem
                            title="Doménový koš"
                            description="Znamená to, že když vám někdo pošle email na cokoliv@vasedomena.cz, tak vám email dorazí! Tzn. neomezeně emailových adres bez nutnosti cokoli vytvářet."
                        />   
                    </div>

                    <div className="col-12 col-md-6">
                        <BenefitItem
                            title="Na vlastní doméně"
                            description="Vaše doména = váš brand. Komunikujte se zákazníky či obchodními partnery pod vaším brandem."
                        />

                        <BenefitItem
                            title="Důvěryhodný"
                            description="Z vaší komunikace uděláte rázem důvěryhodnou a seriózní. Také posílí hodnota vaší značky."
                        />
                    </div>
                </div>
            </div>
        </div>)
}