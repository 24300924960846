export enum BazosSection {
    Zvirata = 1,
    Auto,
    PC,
    Sport,
    Nabytek,
    Deti,
    Motorky,
    Mobily,
    Hudba,
    Obleceni,
    Reality,
    Stroje,
    Foto,
    Vstupenky,
    Sluzby,
    Prace,
    DumAZahrada,
    Elektro,
    Knihy,
    Ostatni
}