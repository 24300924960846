import { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OnlineServer } from "../../../Enums/OnlineServer";
import IProduct from "../../../Interfaces/IProduct";
import IProductServerInfo from "../../../Interfaces/IProductServerInfo";
import ProductApiService from "../../../Services/ProductApiService";
import Swal from "sweetalert2";

interface IProps {
    product: IProduct,
    info: IProductServerInfo,
}

const productApiService = new ProductApiService();

export const ServerActionButtons = (props: IProps) => {

    useEffect(() => {
        return () => {
            productApiService.cancelRequests();
        }
    });

    const { product, info } = props;
    const renewSupportedServers = [OnlineServer.Sbazar, OnlineServer.BazosCz, OnlineServer.BazosSk];
    const { productId, server } = info; 

    const handleTop = () => {
        if (info.isTop) {
            Swal.fire({
                icon: 'warning',
                title: 'Inzerát je již topován, chcete použít další topování?',
                confirmButtonText: 'Ano, topovat',
                showCancelButton: true,
                cancelButtonText: 'Ne, nechci topovat',

            }).then((result) => {
                if (result.isConfirmed) {
                    productApiService.topOffer(productId, server)
                }
            })
        }
        else {
            productApiService.topOffer(productId, server)
        }
    }

    return (
        <div className="mb-3 pb-1">
            {!info.isVisible && product.delete === false && info.enabled && (info.pendingUpload === false || info.error) &&
                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Inzerát se nahraje
                        </Tooltip>
                    }
                ><button
                    onClick={() => productApiService.uploadOffer(productId, server)}
                    className="btn btn-secondary btn-sm mr-1"
                >
                        <i className="fa fa-cloud-upload-alt mr-1"></i> Nahrát
                    </button>
                </OverlayTrigger>}

            {info.isVisible &&
                product.delete === false &&
                info.enabled &&
                info.pendingUpload === false &&
                info.pendingCheck === false &&
                renewSupportedServers.includes(server) &&

                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Inzerát se smaže a nahraje znovu
                        </Tooltip>
                    }
                >
                    <button
                        className="btn btn-secondary btn-sm mr-1"
                        onClick={() => productApiService.uploadOffer(productId, server)}
                        disabled={info.isTop && info.enableUploadTopped === false}
                    >
                        <i className="fa fa-cloud-upload-alt mr-1"></i> Obnovit
                    </button>
                </OverlayTrigger>}

            {info.isVisible &&
                product.delete === false &&
                info.enabled &&
                info.pendingCheck === false &&
                (info.pendingEdit === false || info.error) &&

                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Inzerát se upraví <br /> <small>provedené změny se aplikují</small>
                        </Tooltip>
                    }
                >
                    <button
                        onClick={() => productApiService.editOffer(productId, server)}
                        className="btn btn-secondary btn-sm mr-1"
                    >
                        <i className="far fa-edit mr-1"></i> Aktualizovat
                    </button>
                </OverlayTrigger>}

            {info.isVisible &&
                product.delete === false &&
                info.enabled &&
                info.error === false &&
                info.pendingCheck === false &&
                info.pendingUpload === false &&
                (info.server === OnlineServer.BazosCz || info.server === OnlineServer.BazosSk) &&

                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Inzerát se TOPUJE <br /> <small>je potřeba mít dostupné poukázky</small>
                        </Tooltip>
                    }
                >
                    <button
                        onClick={handleTop}
                        className="btn btn-secondary btn-sm mr-1"
                    >
                        <i className="fas fa-star mr-1"></i> Topovat
                    </button>
                </OverlayTrigger>}

            {(info.pendingDelete === false || info.error) && info.isVisible && product.delete &&
                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Inzerát se smaže
                        </Tooltip>
                    }
                >
                    <button
                        onClick={() => productApiService.deleteOffer(productId, server)}
                        className="btn btn-secondary btn-sm mr-1"
                    >
                        <i className="far fa-trash-alt mr-1"></i> Smazat
                    </button>
                </OverlayTrigger>}

            {info.pendingCheck === false && info.pendingDelete === false && info.pendingEdit === false && info.pendingUpload === false &&
                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Provede se kontrola vystavení
                        </Tooltip>
                    }
                >
                    <button
                        onClick={() => productApiService.checkOffer(productId, server)}
                        className="btn btn-secondary btn-sm mr-1"
                    >
                        <i className="bi bi-check2-square mr-1"></i> Zkontrolovat
                    </button>
                </OverlayTrigger>}
        </div>)
}