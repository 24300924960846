import { observable, action, makeAutoObservable } from 'mobx'
import IGlobalSettings from '../../Interfaces/IGlobalSettings';

export default class GlobalSettingsStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable settings: IGlobalSettings = {
        sbazarSettings: {
            enableCheckVisibility: true,
            enableDelete: true,
            enableEdit: true,
            enableUpload: true,
        },
        bazosSkSettings: {
            enableCheckVisibility: true,
            enableDelete: true,
            enableEdit: true,
            enableUpload: true,
        },
        bazosCzSettings: {
            enableCheckVisibility: true,
            enableDelete: true,
            enableEdit: true,
            enableUpload: true,
        },
        facebookMarketplaceSettings: {
            enabled: true,
        },
        bazosSpamList: [],
        frontendVersion: null,
        currencyConversions: {
            eurToCzkRate: 0,
            lastUpdatedUtc: null,
        },
        news: '',
    }

    @action
    set(settings: IGlobalSettings) {
        if (!settings) return;
        this.settings = settings;
    }
}