export enum OnlineServer {
    BazosCz = 1,
    BazosSk = 2,
    Sbazar = 3,
    AutoStranky = 4,
    FacebookShop = 5,
    Website = 6,
    TipMoto = 7,
    FacebookMarketplace = 8,
    Tipcars = 9,
    Sauto = 10,
    AutobazarEu = 11,
    GoogleMerchant = 12,
}