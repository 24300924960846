import { useState } from 'react';
import { Currency } from '../../Enums/Currency';
import CurrencyHelper from '../../Helpers/CurrencyHelper';
import SelectBox from '../UI/Input/SelectBox';
import Slider from 'rc-slider';
import { SectionTitle } from '../Others/SectionTitle';
import { PricingHelper } from '../Utils/PricingHelper';
import { find } from 'lodash';
import { SeparateThousands } from '../Others/SeparateThousands';
import { SubPrice } from './SubPrice';

interface IProps {
    currency: Currency,
    onChangeCurrency(currency: Currency): void,
    defaultProductLimit: number,
    defaultIpsCount: number,
}

export const StandardPricingCalc = (props: IProps) => {

    const { currency, onChangeCurrency, defaultIpsCount, defaultProductLimit } = props;

    const [ipsCount, setIpsCount] = useState(1);
    const [productLimit, setProductLimit] = useState(defaultProductLimit);
    const [fbmp, setFbmp] = useState(false);

    var pricing = PricingHelper.getStandardPricing(currency);

    const licenceItem = find(pricing, { productMax: productLimit });
    const licencePrice = licenceItem && licenceItem.pricePerMonth;

    let fbmpPrice = 0;

    if (fbmp) {
        fbmpPrice = PricingHelper.getFacebookMarketplacePricePerProduct(currency) * productLimit * 2;
    }

    const ipsPrice = PricingHelper.getIpPrice(currency) * ipsCount - PricingHelper.getIpPrice(currency);
    const finalPrice = licencePrice + fbmpPrice + ipsPrice;

    return <>
        <SelectBox
            options={CurrencyHelper.getOptions()}
            value={currency}
            label="Měna"
            onChange={(value) => onChangeCurrency(value)}
        />

        <div>
            <SectionTitle title="1. Počet inzerátů" />
            <hr />
            <p>Zvolte si kapacitu licence. Jedná se o maximální počet inzerátů, který můžete mít ve svém účtu.</p>

            <div className="position-relative my-3">
                <span className="badge badge-info">{productLimit} inzerátů</span>

                <SubPrice
                    price={licencePrice}
                    currency={currency}
                    style={{ position: 'absolute', right: 0, top: -10 }}
                />

                <div className="px-2 pt-2 pb-4">
                    <Slider
                        min={50}
                        max={2000}
                        marks={{ 50: 50, 100: 100, 250: 250, 500: 500, 1000: 1000, 2000: 2000 }}
                        step={null}
                        value={productLimit}
                        onChange={(value) => setProductLimit(value as number)}
                    />
                </div>
            </div>

            <SectionTitle title="2. EXPORTY" />
            <hr />

            <ul className="pricing-exports-list pl-2" style={{ listStyle: 'none' }}>
                <li>
                    <i className="fa fa-check text-primary"></i> Bazoš.cz
                </li>

                <li>
                    <i className="fa fa-check text-primary"></i> Bazoš.sk
                </li>

                <li>
                    <i className="fa fa-check text-primary"></i> Sbazar.cz
                </li>

                <li>
                    <i className="fa fa-check text-primary"></i> Facebook produktový feed
                </li>

                <li>
                    <i className="fa fa-check text-primary"></i> Exportní feed
                </li>
            </ul>
            {/*
            
            
            <SectionTitle title="3. Příplatkové exporty" />
            <hr />
            <div style={{ marginTop: '31px', marginBottom: '34px' }}>
                <div className="position-relative">
                    <SubPrice
                        prefix="+ "
                        price={fbmpPrice}
                        currency={currency}
                        style={{ position: 'absolute', right: 0, top: -10 }}
                    />
                    <ul className="pl-2" style={{ listStyle: 'none' }}>
                       <li>Aktuálně žádné</li>
                    </ul>
                </div>
            </div>
            */}
            <div className="my-2">
                <SectionTitle title="3. Počet IP" />
                <hr />
                <p>Zvolte si potřebný počet IP adres. Pro každý Bazoš profil je potřeba jiná IP adresa.</p>

                <div className="position-relative">
                    <span className="badge badge-info">{ipsCount} IP</span>

                    <SubPrice
                        prefix="+ "
                        price={ipsPrice}
                        currency={currency}
                        style={{ position: 'absolute', right: 0, top: -10 }}
                    />

                    <div className="px-2 py-2">
                        <Slider
                            min={1}
                            max={40}
                            step={1}
                            dots
                            value={ipsCount}
                            onChange={(value) => setIpsCount(value as number)}
                        />
                    </div>
                </div>
            </div>

            <hr />

            <div className="float-right d-flex mt-4 mb-5">
                <h3>Celková měsíční cena:<span className="badge badge-success ml-4"><SeparateThousands roundPrecision={2} number={finalPrice} /> {CurrencyHelper.getSymbol(currency)}</span></h3>
            </div>
        </div>
    </>
}