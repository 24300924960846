import React from "react"

export const DomainsPricing = () => {

    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th></th>

                            <th>Registrace</th>

                            <th>Prodloužení</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                <b>.cz</b>
                            </td>

                            <td>
                                249 Kč / rok
                            </td>

                            <td>
                                249 Kč / rok
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <b>.sk</b>
                            </td>

                            <td>
                                349 Kč / rok
                            </td>

                            <td>
                                349 Kč / rok
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <b>.eu</b>
                            </td>

                            <td>
                                199 Kč / rok
                            </td>

                            <td>
                                199 Kč / rok
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <b>.com</b>
                            </td>

                            <td>
                                349 Kč / rok
                            </td>

                            <td>
                                349 Kč / rok
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p>Nejsme plátci DPH.</p>
        </>)
}