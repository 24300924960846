import * as React from 'react';
import IInputField from '../../../Interfaces/IInputField';
import InputText from './InputText';

export default class InputUrl extends InputText implements IInputField {
    handleChange(e: any) {
        this.validate(e.target.value);
        this.props.onChange(e.target.value);
    }

    validateInput(value: string, errors: string[]) {
        super.validateInput(value, errors);

        if (!this.props.required && (value || '').length === 0) {
            return;
        }

        var pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

        if (!pattern.test(value)) {
            errors.push("Url není ve správném formátu")
        }
        if (value.includes("http://") === false && value.includes("https://") === false) {
            errors.push("Url adresa musí začínat protokolem http:// nebo https://");
        }
        
    }

    renderError() {
        if (this.state.errors.length == 0) { return; }
        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        return (
            <input
                ref={this.input}
                type="url"
                className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                placeholder={this.props.placeholder}
                value={this.props.value || ''}
                onChange={this.handleChange.bind(this)}
                data-clarity-unmask="True"
                disabled={this.props.readonly}
            />
        );
    }

    renderLabel() {
        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required ? <span className="tx-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }
}