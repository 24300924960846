import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import IInputField from '../../Interfaces/IInputField';

interface IProps {
    onChange(value: any): void,
    placeholder?: string,
    value: string,
    label?: string,
    required?: boolean,
    height?: number,
    maxLength?: number,
    toolbar?: string,
}

export const TinyMCEEditor = React.forwardRef<IInputField, IProps>((props, ref) => {

    const {
        onChange,
        placeholder,
        value,
        label,
        required,
        height,
        maxLength,
        toolbar
    } = props;

    const editorRef = useRef<Editor>(null);

    const content = editorRef?.current?.editor?.getContent({ format: 'text' });

    useEffect(() => {
        if (maxLength && content?.length > maxLength) {
            onChange(content.substring(0, maxLength));
        }
    }, [value])

    return (
        <div className="form-group">
            {label &&
                <label className="form-control-label">
                    {label} {required && label.length !== 0 ? <span className="text-danger">*</span> : null}
                </label>}

            <Editor
                ref={editorRef}
                tinymceScriptSrc='/tinymce/tinymce.min.js'
                value={value}
                init={{
                    height: height || 400,
                    menubar: false,
                    placeholder: placeholder,
                    toolbar: toolbar ||
                        'undo redo | formatselect | bold italic underline | ' +
                        'forecolor backcolor | fontsizeselect | fontsize |' +
                        'bullist numlist outdent indent | removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                    plugins: 'lists',
                }}
                onEditorChange={(c) => onChange(c)}

            />
            <small>
                Napsáno: {value != null ? content?.length : null} {maxLength && <>(max: {maxLength} znaků)</>}
            </small>
        </div>
    );
})