import { Link } from "react-router-dom"
import { Accordion } from "../UI/Elements/Accordion"

export const WebsitesFAQ = () => {

    return (
        <>
            <Accordion cardClassName="mb-2" headingText={"Můžete mi vytvořit web na míru?"}>
                <p>
                    Ano, je to možné. <Link to="/kontakt">Napište nám</Link>.
                </p>
            </Accordion>

            <Accordion cardClassName="mb-2" headingText={"Můžu mít vlastní šablonu?"}>
                <p>
                    Pokud máte již vytvořenou šablonu (HTML, JS, CSS) a bude vyhovovat k integraci, za poplatek vám ji zprovozníme na našem systému.  <Link to="/kontakt">Napište nám</Link>.
                </p>
            </Accordion>

            <Accordion cardClassName="mb-2" headingText={"Potřebuji nadstandardní úpravu webu"}>
                <p>
                     <Link to="/kontakt">Napište nám</Link>. Zhodnotíme možnosti úprav a pošleme vám cenovou kalkulaci.
                </p>
            </Accordion>


            <Accordion cardClassName="mb-2" headingText={"Jak rychle se projeví změny na webu?"}>
                <p>
                    V případě, že se změnil obsah vašich inzerátů, web bude aktualizován do 1 hodiny. Avšak změny můžete zaregistrovat později z důvodu cache na straně serveru.
                </p>
            </Accordion>

            <Accordion cardClassName="mb-2" headingText={"Je součástí webu doména?"}>
                <p>
                    Doména není součástí webu, tu je nutné obstarat zvlášť. Rádi vám s tím pomůžeme. <Link to="/kontakt">Napište nám</Link>.
                </p>
            </Accordion>

            <Accordion cardClassName="mb-2" headingText={"Je součástí webu webmail?"}>
                <p>
                    Ne. Webmail neposkytujeme, ale můžete využít zdarma službu od Seznamu Email Profi. Pokud máte zájem o nastavení této služby, <Link to="/kontakt">Napište nám</Link>.
                </p>
            </Accordion>
        </>)
}