import React from "react";
import { OnlineServer } from "../../../Enums/OnlineServer";
import { SbazarHelper } from "../../../Helpers/Servers/SbazarHelper";
import IProduct from "../../../Interfaces/IProduct";
import IProductCollection from "../../../Interfaces/IProductCollection";
import Product from "../../../Models/Product";
import BaseComponent from "../../BaseComponent";
import CheckBox from "../../UI/Input/CheckBox";
import InputText from "../../UI/Input/InputText";
import Textarea from "../../UI/Input/Textarea";
import { ContentRulesAlert } from "../../Uploader/Products/ContentRulesAlert";
import { OnlineServerHelper } from "../../Utils/OnlineServerHelper";

interface IState {

}

interface IProps {
    product: IProduct,
    collection: IProductCollection,
    onChange(value: any, key: string): void,
    validChanged?(isValid: boolean): void,
}

export default class SbazarEditor extends BaseComponent<IProps, IState>
{
    private inputSbazarName = React.createRef<InputText>();
    private inputSbazarDescription = React.createRef<Textarea>();

    constructor(props: IProps) {
        super(props);

        this.inputSbazarName = React.createRef();
        this.inputSbazarDescription = React.createRef();
    }

    componentDidMount() {
        this.checkValidate();
    }

    getInputs() {
        return [this.inputSbazarName, this.inputSbazarDescription];
    }

    checkValidate() {
        const inputs = this.getInputs();
        const isValid = this.isValid(inputs);

        this.props.validChanged(isValid);
    }

    checkIsValid() {
        const inputs = this.getInputs();
        return this.isValid(inputs);
    }

    validate(): boolean {
        const inputs = this.getInputs();
        const isValid = this.validateInputs(inputs);
        this.props.validChanged(isValid);
        return isValid;
    }

    render() {
        const { product, onChange, collection } = this.props;

        return (
            <>
                <div className="pb-3 pt-1">
                    <CheckBox
                        checked={product.sbazarUseDescription}
                        onChange={(value) => onChange(value, 'sbazarUseDescription')}
                        label="Nastavit jiný obsah"
                    />
                </div>

                <div className={product.sbazarUseDescription ? 'd-block' : 'd-none'}>
                    <InputText
                        ref={this.inputSbazarName}
                        label="Nadpis"
                        onChange={(value) => onChange(value, 'sbazarName')}
                        value={product.sbazarName}
                        required
                        maxLength={SbazarHelper.getMaxNameCharsCount()}
                        readonly={Product.isPendingCheck(product, collection)}
                        onBlur={this.checkValidate.bind(this)}
                    />

                    <Textarea
                        ref={this.inputSbazarDescription}
                        label="Popis"
                        onChange={(value) => onChange(value, 'sbazarDescription')}
                        required
                        value={product.sbazarDescription}
                        rows={13}
                        maxLength={SbazarHelper.getDescriptionMaxChars(collection)}
                        onBlur={this.checkValidate.bind(this)}
                    />

                    <ContentRulesAlert
                        text={product.sbazarDescription}
                        rules={OnlineServerHelper.getContentRules(OnlineServer.Sbazar)}
                    />
                </div>
            </>)
    }

}