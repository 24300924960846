import * as React from 'react';
import IInputField from '../../../Interfaces/IInputField';

interface IProps {
    required?: boolean;
    label: string;
    value: number[];
    onChange?(value: number[]): void;
    autoComplete?: string,
    minValue?: number,
    maxValue?: number,
    maxValueCount?: number,
    placeholder?: string,
}

interface IState {
    errors: string[],
}

export default class MultiNumberInput extends React.Component<IProps, IState> implements IInputField {

    protected input: React.RefObject<HTMLInputElement>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }

    focus() {
        this.input.current.focus();
    }

    getErrors(value = this.props.value) {
        let errors: string[] = [];

        this.validateInput(value, errors);
        return errors;
    }

    validateInput(value: number[], errors: string[]) {
        value = value || [];

        if (this.props.required === false && !value && value.length === 0) {
            return;
        }

        if (this.props.required && value.length === 0) {
            errors.push("Toto pole je povinné");
        }
        if (this.props.maxValueCount && value.length > this.props.maxValueCount) {
            errors.push(`Můžete vložit pouze ${this.props.maxValueCount} hodnot`);
        }

   /*     if (this.props.minValue && value.length < this.props.minValue) {
            errors.push("Minimální hodnota je " + this.props.minValue);
        }
        if (this.props.maxValue && value.length > this.props.maxValue) {
            errors.push("Maximální hodnota je " + this.props.maxValue);
        }*/
    }


    isValid() {
        return this.getErrors().length == 0;
    }

    validate(value = this.props.value) {
        this.setState({
            errors: this.getErrors(value),
        })
    }

    handleChange(e: any) {
        /*    let value: string = e.target.value.replace(/ /g, '');
            if (!Number(e.target.value) && e.target.value.length !== 0) {
               // this.props.onChange(value.substring(0, value.length - 1))
            }
            else {
               this.props.onChange(value);
           }*/

        const strValue = e.target.value;

        var value : number[] = strValue.split(',').map((item: string) => {

            const v = parseInt(item, 10);
            if (isNaN(v) === false) {
                return v;
            }
         
        });

        this.props.onChange(value);
        this.validate(value);
    }

    renderError() {
        if (this.state.errors.length == 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        return (
            <input
                ref={this.input}
                type="text"
                className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                value={(this.props.value || []).join(",")}
                onChange={this.handleChange.bind(this)}
                data-clarity-unmask="True"
                placeholder={this.props.placeholder || ''}
            />
        );
    }

    renderLabel() {
        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required ? <span className="tx-danger">*</span> : null}
            </label>
        );
    }

    render() {
      
        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }
}