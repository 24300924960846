import { inject, observer } from 'mobx-react';
import BaseComponent from '../../BaseComponent';
import { PageTitleBox } from '../../Others/PageTitleBox';
import LoadingScreen from '../../Screens/LoadingScreen';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { MyBusinessProfile } from '../../MyBusiness/MyBusinessProfile';

interface IState {
    loading: boolean,
}

interface IProps {
    currentUserStore?: CurrentUserStore,
    history?: any,
}

@inject('currentUserStore')
@observer
export default class MyBusinessProfilePage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.getData('/user')
            .then(data => {
                this.setState({
                    loading: false,
                })
            })
    }

    render() {    
        const { loading } = this.state;
        const user = this.props.currentUserStore.user;

        return (
            <CustomDocumentTitle title={`Nastavení - Moje firma`}>
                <PageTitleBox title="Nastavení - Moje firma" />
                {loading ? <LoadingScreen /> : <MyBusinessProfile user={user} />}
            </CustomDocumentTitle>
        )
    }
}