import { observable, action, makeAutoObservable } from 'mobx'
import { filter, findIndex, first, remove, orderBy } from 'lodash';
import IProductImage from '../../Interfaces/IProductImage';

export default class ProductImageStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IProductImage[] = [];

    @action
    addRecords(data: any): any {
        data.forEach((record: IProductImage) => this.addRecord(record));
    }

    @action
    private addRecord(record: IProductImage) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: string[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }

    getFirstImage(productId: string): IProductImage {
        return first(orderBy(filter(this.list, { productId: productId }), ['orderNumber'], ['asc']));
    }

    getByIds(ids: string[]): IProductImage[] {
        return filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
    }
}