import React from "react";
import { ProductHelper } from "../../../Helpers/ProductHelper";
import IProduct from "../../../Interfaces/IProduct";
import BaseComponent from "../../BaseComponent";
import CheckBox from "../../UI/Input/CheckBox";
import InputText from "../../UI/Input/InputText";
import Textarea from "../../UI/Input/Textarea";

interface IState {

}

interface IProps {
    product: IProduct,
    onChange(value: any, key: string): void,
    validChanged?(isValid: boolean): void,
}

export default class FacebookEditor extends BaseComponent<IProps, IState>
{
    private inputFacebookName = React.createRef<InputText>();
    private inputFacebookDescription = React.createRef<Textarea>();

    constructor(props: IProps) {
        super(props);

        this.inputFacebookName = React.createRef();
        this.inputFacebookDescription = React.createRef();
    }

    componentDidMount() {
        this.checkValidate();
    }

    getInputs() {
        return [this.inputFacebookName, this.inputFacebookDescription];
    }

    checkValidate() {
        const inputs = this.getInputs();
        const isValid = this.isValid(inputs);

        this.props.validChanged(isValid);
    }

    checkIsValid() {
        const inputs = this.getInputs();
        return this.isValid(inputs);
    }

    validate(): boolean {
        const inputs = this.getInputs();
        const isValid = this.validateInputs(inputs);
        this.props.validChanged(isValid);
        return isValid;
    }

    render() {
        const { product, onChange } = this.props;

        return (
            <>
                <div className="pb-3 pt-1">
                    <CheckBox
                        checked={product.facebookCustomContent}
                        onChange={(value) => onChange(value, 'facebookCustomContent')}
                        label="Nastavit jiný obsah"
                    />
                </div>
                
                <div className={product.facebookCustomContent ? 'd-block' : 'd-none'}>
                    <InputText
                        ref={this.inputFacebookName}
                        label="Nadpis"
                        onChange={(value) => onChange(value, 'facebookName')}
                        value={product.facebookName}
                        required
                        maxLength={ProductHelper.getMaxNameCharsCount()}
                        onBlur={this.checkValidate.bind(this)}
                    />

                    <Textarea
                        ref={this.inputFacebookDescription}
                        label="Popis"
                        onChange={(value) => onChange(value, 'facebookDescription')}
                        required
                        value={product.facebookDescription}
                        rows={13}
                        maxLength={3000}
                        onBlur={this.checkValidate.bind(this)}
                    />
                </div>
            </>)
    }
}