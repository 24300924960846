import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import CurrencyHelper from '../../../Helpers/CurrencyHelper';
import { PageTitleBox } from '../../Others/PageTitleBox';
import { Pricing } from '../../Pricing/Pricing';
import { WebsitesPricing } from '../../Pricing/WebsitesPricing';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import SelectBox from '../../UI/Input/SelectBox';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';

interface IProps {
    currentUserStore?: CurrentUserStore
}

export const PricingWebsitesPage = inject('currentUserStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;

    const [currency, setCurrency] = useState(user.accountCurrency);

    return (
        <CustomDocumentTitle title="Ceník - Prodejní weby">
            <div>
                <PageTitleBox title="Ceník - Prodejní weby" />

                <div className="card">
                    <div className="card-body">

                        <div className="float-right">
                            <SelectBox
                                options={CurrencyHelper.getOptions()}
                                value={currency}
                                label="Měna"
                                onChange={(value) => setCurrency(value)}
                            />
                        </div>

                        <p>Předmětem služby je 1 webová stránka umístěná na vaší doméně. V ceně je kompletní web propojený s Inzerobotem, zajištěný hosting společností Amazon. V ceně není doména.</p>
                        <Pricing>
                            <WebsitesPricing currency={currency} />
                        </Pricing>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    )
}))