import BaseComponent from './../../../BaseComponent';
import InputText from '../../../UI/Input/InputText';
import React from 'react';
import InputEmail from '../../../UI/Input/InputEmail';
import InputTelephone from '../../../UI/Input/InputTelephone';
import IProductCollection from '../../../../Interfaces/IProductCollection';
import IInputField from '../../../../Interfaces/IInputField';
import CheckBox from '../../../UI/Input/CheckBox';
import Swal from 'sweetalert2';
import SelectBox from '../../../UI/Input/SelectBox';
import IOptionData from '../../../../Interfaces/IOptionData';
import { find } from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import IAppIpAddress from '../../../../Interfaces/IAppIpAddress';
import { ISbazarLocation } from '../../../../Interfaces/ISbazarLocation';
import { SbazarHelper } from '../../../../Helpers/Servers/SbazarHelper';
import { HowToVerifySbazarContactAccordion } from '../../../Integrations/SbazarAccounts/HowToVerifySbazarContactAccordion';
import { SbazarErrorInfo } from '../SbazarErrorInfo';

interface IState {
    collection: IProductCollection,
    searchedLocation: string,
    locations: ISbazarLocation[]
}

interface IProps {
    collection: IProductCollection,
    collections: IProductCollection[],
    appIpAddresses: IAppIpAddress[],
    show: boolean
    onHide: Function
}

export default class SbazarSettingsModal extends BaseComponent<IProps, IState> {

    private inputLoginEmail = React.createRef<InputEmail>();
    private inputLoginPassword = React.createRef<InputText>();
    private inputSearchLocation = React.createRef<InputText>();
    private inputLocation = React.createRef<SelectBox>();
    private inputEmail = React.createRef<InputEmail>();
    private inputPhone = React.createRef<InputTelephone>();
    private inputShowPhone = React.createRef<CheckBox>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            collection: this.props.collection,
            searchedLocation: '',
            locations: [],
        }

        this.inputLoginEmail = React.createRef();
        this.inputLoginPassword = React.createRef();
        this.inputSearchLocation = React.createRef();
        this.inputLocation = React.createRef();
        this.inputEmail = React.createRef();
        this.inputPhone = React.createRef();
        this.inputShowPhone = React.createRef();
    }

    handleChangeLocation(value: any) {
        const location: ISbazarLocation = find(this.state.locations, { entityId: value });

        this.setState({
            collection: {
                ...this.state.collection,
                sbazarLocationEntityId: location.entityId,
                sbazarLocationEntityType: location.entityType,
                sbazarLocationName: location.suggestFirstRow + ' - ' + location.suggestSecondRow
            }
        })
    }

    validate() {
        const inputs: React.RefObject<IInputField>[] = [];

        if (!this.state.collection.sbazarLocationEntityId) {
            Swal.fire('Vyhledejte a vyberte lokalitu', '', 'warning');
            return false;
        }

        inputs.push(
            this.inputLoginEmail,
            this.inputLoginPassword,
        )

        if (!this.state.collection.sbazarLocationEntityId && this.state.locations.length > 0) {
            inputs.push(
                this.inputLocation
            )
        }

        if (!this.state.collection.sbazarLocationEntityId) {
            inputs.push(
                this.inputSearchLocation
            )
        }

        inputs.push(
            this.inputEmail,
            this.inputPhone,

        );
        return this.validateInputs(inputs);
    }

    getLocations() {
        if (!this.state.searchedLocation) {
            Swal.fire('Zadejte lokalitu',
                'Nejprve zadejte lokalitu, poté ji můžete vyhledat a následně vybrat ze seznamu níže.',
                'warning'
            );
            return;
        }

        Swal.fire({
            title: 'Hledám lokality...',
            didOpen: () => {
                Swal.showLoading();
            },
        })

        this.sbazarApiService.getLocations(this.state.searchedLocation)
            .then(data => {
                if (data.success) {
                    const locations = data.others.get("locations");

                    this.setState({
                        locations: locations,
                        collection: {
                            ...this.state.collection,
                            sbazarLocationEntityId: 0
                        }
                    })

                    if (locations.length === 0) {
                        Swal.fire('Nic nenalezeno', 'Zkuste zadat lokalitu jinak...', 'error')
                    }
                    else {
                        Swal.close();
                    }
                }
                else {
                    Swal.fire({
                        title: "Došlo k chybě",
                        text: "Opakujte akci později. Pokud problém přetrvává delší dobu, kontaktujte nás.",
                        icon: "error"
                    });
                }
            })
    }

    handleChangeCollection(key: any, value: any) {
        if (key === 'sbazarRenewMinIntervalDays' && value === null) {
            value = 0;
        }

        this.setState({
            collection: {
                ...this.state.collection,
                [key]: value
            }
        })
    };

    handleSubmitForm() {
        const collection = this.state.collection;

        const isValid = this.validate();

        if (isValid) {
            axios.put(`/user/product-collections/${collection.id}/sbazar`, collection)
                .then(res => {
                    this.props.onHide();
                })
        }
    }

    render() {
        let collection = this.state.collection;
        var locations = this.state.locations;

        const locationOptions: IOptionData[] = [];

        locations.forEach((location: ISbazarLocation) => {
            locationOptions.push({ label: location.suggestFirstRow + ' - ' + location.suggestSecondRow, value: location.entityId })
        })

        if (collection.sbazarLocationEntityId !== 0) {
            locationOptions.push({
                label: collection.sbazarLocationName,
                value: collection.sbazarLocationEntityId
            });
        }

        const selectBoxLocationsdefValue: string = SbazarHelper.getLocationDefValue(locations)
        return (
            <Modal size="lg" centered={true} show={this.props.show} onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Propojení Sbazar.cz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {collection.sbazarError &&
                            <SbazarErrorInfo
                                collection={collection}
                            />}

                        <label className="section-title mt-0 mb-4">PŘIHLAŠOVACÍ ÚDAJE</label>
                        <div className="border p-2 mt-3 bg-light">
                            <InputEmail
                                ref={this.inputLoginEmail}
                                label="Přihlašovací jméno (email) do sbazaru"
                                value={collection.sbazarLoginEmail}
                                required={true}
                                placeholder=""
                                onChange={this.handleChangeCollection.bind(this, 'sbazarLoginEmail')}
                            />

                            <InputText
                                ref={this.inputLoginPassword}
                                label="Heslo k účtu (do sbazaru)"
                                value={collection.sbazarLoginPassword}
                                required={true}
                                placeholder=""
                                onChange={this.handleChangeCollection.bind(this, 'sbazarLoginPassword')}
                            />
                        </div>

                        <div className="alert alert-warning mt-2 m-0 w-100">
                            <p>
                                Budeme se na váš účet přihlašovat za účelem správy inzerátů. <b>Účet nesmí mít zapnuté dvoufázové ověření.</b>
                            </p>
                        </div>

                        <div className="mt-3">
                            <div className="row">
                                <div className="col-lg-6">
                                    <SelectBox
                                        label='Obnovování'
                                        options={SbazarHelper.getAutoRenewOptions()}
                                        value={collection.sbazarRenewMinIntervalDays}
                                        defaultValue="Po expiraci"
                                        onChange={this.handleChangeCollection.bind(this, 'sbazarRenewMinIntervalDays')}
                                    />
                                </div>
                            </div>
                        </div>

                        <h6 className="text-warning">
                            Z důvodů blokací ze strany Sbazaru při obnovování inzerátů je tato funkce vypnutá od 26.6.2024. Chráníme tímto vaše účty, abyste mohli nadále prodávat.
                        </h6>

                        {(collection.sbazarRenewMinIntervalDays > 0) ?
                            <p className="text-danger text-center">Obnovováním před expirací inzerátu se vystavujete blokaci tel. čísla, emailu, sbazar účtu.</p> : ""}

                        <hr />

                        <label className="section-title mt-0 mb-4">PRODEJCE</label>
                        <label className="form-control-label">Vyhledejte svou lokalitu <span className="tx-danger">*</span></label>
                        <div className="form-inline mb-3">
                            <InputText
                                ref={this.inputSearchLocation}
                                required
                                label=""
                                placeholder="např. Praha"
                                value={this.state.searchedLocation}
                                onChange={(value: string) => this.setState({ searchedLocation: value })}
                            />
                            <button type="submit" onClick={this.getLocations.bind(this)} className="btn btn-primary ml-2">Vyhledat</button>
                        </div>

                        {this.state.locations.length > 0 || collection.sbazarLocationEntityId ?
                            <SelectBox
                                ref={this.inputLocation}
                                label="Vyberte lokalitu"
                                onChange={this.handleChangeLocation.bind(this)}
                                options={locationOptions}
                                value={collection.sbazarLocationEntityId}
                                required
                                disabled={locationOptions.length == 0}
                                defaultValue={selectBoxLocationsdefValue}
                            /> : null}

                        <hr />

                        <div className="alert alert-warning mt-2 m-0 w-100">
                            <h6 className="alert-heading">Máte email nebo tel. číslo, které jste dosud nepoužili na Sbazaru?</h6>
                            <hr />
                            <p>Pokračujte dle návodu níže <b>Jak ověřit tel. číslo/email</b></p>
                        </div>
                        <HowToVerifySbazarContactAccordion />

                        <div className="row mt-4">
                            <div className="col">
                                <InputEmail
                                    ref={this.inputEmail}
                                    label="Kontaktní email"
                                    value={collection.sbazarEmail}
                                    required={true}
                                    placeholder=""
                                    onChange={this.handleChangeCollection.bind(this, 'sbazarEmail')}
                                />
                            </div>
                            <div className="col">
                                <InputTelephone
                                    ref={this.inputPhone}
                                    label="Kontaktní tel. číslo"
                                    value={collection.sbazarPhone}
                                    required={true}
                                    onChange={this.handleChangeCollection.bind(this, 'sbazarPhone')}
                                />
                            </div>
                        </div>



                        <CheckBox
                            ref={this.inputShowPhone}
                            checked={collection.sbazarShowPhone}
                            label="Zobrazovat tel. číslo"
                            onChange={this.handleChangeCollection.bind(this, 'sbazarShowPhone')}
                        />

                        <CheckBox
                            checked={collection.sbazarCurrentDateDescriptionPrefix}
                            label="Před text inzerátu vložit aktuální datum"
                            onChange={this.handleChangeCollection.bind(this, 'sbazarCurrentDateDescriptionPrefix')}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide.bind(this)} variant="secondary" size="lg">
                        Zavřít
                    </Button>
                    <Button variant="primary" size="lg" onClick={this.handleSubmitForm.bind(this)}>
                        {collection.sbazar ? "Uložit změny" : "Dokončit propojení"}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}