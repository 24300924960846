import { inject, observer } from 'mobx-react';
import { OnlineServer } from '../../../../Enums/OnlineServer';
import BaseComponent from '../../../BaseComponent';
import AddNewFacebookAccount from '../../../Integrations/FacebookAccounts/AddNewFacebookAccount';
import { FacebookAccountsTable } from '../../../Integrations/FacebookAccounts/FacebookAccountsTable';
import FacebookAccountSubmitSmsCodeModal from '../../../Integrations/FacebookAccounts/FacebookAccountSubmitSmsCodeModal';
import { PageTitleBox } from '../../../Others/PageTitleBox';
import LoadingScreen from '../../../Screens/LoadingScreen';
import CurrentUserStore from '../../../Stores/CurrentUserStore';
import ServerAccountStore from '../../../Stores/ServerAccountStore';
import CustomDocumentTitle from '../../../Utils/CustomDocumentTitle';

interface IState {
    loading: boolean,
    showCreateNewModal: boolean,
    showSubmitCode: boolean,
    accountId: string,
}

interface IProps {
    currentUserStore?: CurrentUserStore,
    serverAccountStore?: ServerAccountStore,
}

@inject('serverAccountStore', 'currentUserStore')
@observer
export default class FacebookAccountsPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            showCreateNewModal: false,
            showSubmitCode: false,
            accountId: ''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.serverAccountsApiService.getList(OnlineServer.FacebookMarketplace)
            .then(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    handleShowCreateNewModal() {
        this.setState({
            showCreateNewModal: true
        })
    }

    renderContent() {
        const user = this.props.currentUserStore.user;
        const accounts = this.props.serverAccountStore.getByUserIdAndServer(user.id, OnlineServer.FacebookMarketplace);

        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <AddNewFacebookAccount
                            showAddAccountModal={this.state.showCreateNewModal}
                            onChangeShowAddModal={(value) => this.setState({ showCreateNewModal: value })}
                        />

                        {this.state.showSubmitCode &&
                            <FacebookAccountSubmitSmsCodeModal
                                accountId={this.state.accountId}
                                onHide={() => this.setState({ showSubmitCode: false, })}
                            />}

                        <button
                            onClick={this.handleShowCreateNewModal.bind(this)}
                            className="btn btn-primary mb-2"
                        >
                            Přidat účet
                        </button>

                        <FacebookAccountsTable
                            accounts={accounts}
                        />
                    </div>
                </div>
            </>
        )
    }

    render() {
        const { loading } = this.state;

        return (
            <CustomDocumentTitle title="Facebook účty">
                <div>
                    <PageTitleBox title={"Facebook účty"} />

                    {loading ? <LoadingScreen /> : this.renderContent()}
                </div>
            </CustomDocumentTitle>
        )
    }
}