import { inject, observer } from 'mobx-react';
import React from 'react';
import { ListType } from '../../../../Enums/ListType';
import IProduct from '../../../../Interfaces/IProduct';
import Product from '../../../../Models/Product';
import BaseComponent from '../../../BaseComponent';
import { LeavePageWarningPrompt } from '../../../Others/LeavePageWarningPrompt';
import ListsStore from '../../../Stores/ListsStore';
import SelectBox from '../../../UI/Input/SelectBox';
import SmartSelectBox from '../../../UI/Input/SmartSelectBox';
import { ValidationHelper } from '../../../Utils/ValidationHelper';

interface IState { }

interface IProps {
    onChange(key: string, value: any): void,
    product: IProduct,
    listsStore?: ListsStore,
    requiredFields: string[],
}

@inject('listsStore')
@observer
export default class ProductWizardTiresStep extends BaseComponent<IProps, IState> {

    private condition: React.RefObject<SelectBox>;
    private width: React.RefObject<SmartSelectBox>;
    private height: React.RefObject<SmartSelectBox>;
    private rimDiameter: React.RefObject<SmartSelectBox>;
    private loadIndex: React.RefObject<SmartSelectBox>;
    private speedRating: React.RefObject<SmartSelectBox>;
    private vehicleType: React.RefObject<SmartSelectBox>;
    private season: React.RefObject<SmartSelectBox>;
    private profile: React.RefObject<SmartSelectBox>;

    constructor(props: IProps) {
        super(props);

        this.condition = React.createRef();
        this.width = React.createRef();
        this.height = React.createRef();
        this.rimDiameter = React.createRef();
        this.loadIndex = React.createRef();
        this.speedRating = React.createRef();
        this.vehicleType = React.createRef();
        this.season = React.createRef();
        this.profile = React.createRef();
    }

    validate() : boolean {
        const inputs = [
            this.condition,
            this.width,
            this.height,
            this.profile,
            this.rimDiameter,
            this.loadIndex,
            this.speedRating,
            this.vehicleType,
            this.season,
        ];

        return ValidationHelper.validateInputs(inputs);
    }

    render() {
        const { product, listsStore, onChange, requiredFields } = this.props;

        return (
            <div>
                <div className="alert alert-secondary" role="alert">
                    Díky těmto údajům zařadíme váš inzerát do správné kategorie na inzertních serverech.
                </div>

                <LeavePageWarningPrompt when={true} />

                <div className="mx-2 my-3">
                    <div className="row lightForm">
                        <div className="col-12 col-lg-8">
                            <label className="section-title pt-3 pb-3 mb-3">Obecné</label>

                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4">
                                    <SelectBox
                                        ref={this.condition}
                                        label="Stav"
                                        onChange={(val) => onChange('condition', val)}
                                        options={Product.getConditionOptions()}
                                        required={requiredFields.includes("condition")}
                                        defaultValue="Vyberte"
                                        value={product.condition}
                                    />
                                </div>

                                {/*   <div className="col-12 col-sm-6 col-md-4">
                                <SmartSelectBox
                                    ref={this.inputBrand}
                                    label="Značka"
                                    options={Tire.getTireBrandOptions()}
                                    required
                                    value={product.brand}
                                    onChange={onChange.bind(this, 'brand')}
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-4">
                                <SelectBox
                                    ref={this.inputQuantity}
                                    label="Počet pneumatik"
                                    onChange={onChange.bind(this, 'quantity')}
                                    options={Tire.getTireQuantityOptions()}
                                    required
                                    defaultValue="Vyberte"
                                    value={product.quantity}
                                />
                            </div>*/}
                            </div>

                            <label className="section-title mt-3 pb-3 mb-3">Parametry</label>

                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4">
                                    <SmartSelectBox
                                        ref={this.width}
                                        label="Šířka"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.tireWidth || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.TireWidth)}
                                        uid="tireWidth"
                                        required={requiredFields.includes("tireWidth")}
                                    />
                                </div>

                                <div className="col-12 col-sm-6 col-md-4">
                                    <SmartSelectBox
                                        ref={this.height}
                                        label="Výška"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.tireHeight || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.TireHeight)}
                                        uid="tireHeight"
                                        required={requiredFields.includes("tireHeight")}
                                    />
                                </div>

                                <div className="col-12 col-sm-6 col-md-4">
                                    <SmartSelectBox
                                        ref={this.profile}
                                        label="Profil"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.tireProfile || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.TireProfile)}
                                        uid="tireProfile"
                                        required={requiredFields.includes("tireProfile")}
                                    />
                                </div>

                                <div className="col-12 col-sm-6 col-md-4">
                                    <SmartSelectBox
                                        ref={this.rimDiameter}
                                        label="Průměr"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.tireRimDiameter || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.TireRimDiameter)}
                                        uid="tireRimDiameter"
                                        required={requiredFields.includes("tireRimDiameter")}
                                    />
                                </div>

                                <div className="col-12 col-sm-6 col-md-4">
                                    <SmartSelectBox
                                        ref={this.loadIndex}
                                        label="Index zatížení"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.tireLoadIndex || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.TireLoadIndex)}
                                        uid="tireLoadIndex"
                                        required={requiredFields.includes("tireLoadIndex")}
                                    />
                                </div>

                                <div className="col-12 col-sm-6 col-md-4">
                                    <SmartSelectBox
                                        ref={this.speedRating}
                                        label="Rychlostní index"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.tireSpeedRating || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.TireSpeedRating)}
                                        uid="tireSpeedRating"
                                        required={requiredFields.includes("tireSpeedRating")}
                                    />
                                </div>
                            </div>

                            <label className="section-title mt-3 pb-3 mb-3">Určení</label>

                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <SmartSelectBox
                                        ref={this.vehicleType}
                                        label="Typ vozidla"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.tireVehicleType || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.TireVehicleType)}
                                        uid="tireVehicleType"
                                        required={requiredFields.includes("tireVehicleType")}
                                    />
                                </div>

                                <div className="col-12 col-sm-6">
                                    <SmartSelectBox
                                        ref={this.season}
                                        label="Roční období"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={(product.tireSeason || '').toString()}
                                        options={listsStore.getOptionsBy(ListType.TireSeason)}
                                        uid="tireSeason"
                                        required={requiredFields.includes("tireSeason")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    
}
 