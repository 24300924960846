import { filter } from "lodash";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { PageTitleBox } from "../../../Others/PageTitleBox";
import LoadingScreen from "../../../Screens/LoadingScreen";
import BazosCouponStore from "../../../Stores/BazosCouponStore";
import CurrentUserStore from "../../../Stores/CurrentUserStore";
import Textarea from "../../../UI/Input/Textarea";
import CustomDocumentTitle from "../../../Utils/CustomDocumentTitle";
import BazosApiService from "../../../../Services/BazosApiService";

interface IProps {
    bazosCouponStore?: BazosCouponStore,
    currentUserStore?: CurrentUserStore
}

const bazosApiService = new BazosApiService();

export const BazosPage = inject('bazosCouponStore', 'currentUserStore')(observer((props: IProps) => {

    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState<{
        newCodes: string[]
    }>({
        newCodes: [],
    })

    useEffect(() => {
       bazosApiService.getCoupons()
           .then(response => {
               setLoading(false);
        })

        return () => {
            bazosApiService.cancelRequests();
        }
    }, [])

    const handleSubmitAddCodes = () => {
        bazosApiService.postCoupons(form.newCodes)
            .then(response => {
                if (response.success) {
                    setForm({
                        newCodes: [],
                    })
                }              
            })
    }

    const handleChange = (value: any, key: string) => {
        setForm({
            ...form,
            [key]: value,
        })
    }

    const user = props.currentUserStore.user;
    const coupons = props.bazosCouponStore.getByUserId(user.id);
    const notUsedCoupons = filter(coupons, { used: false, error: false, });
    const notUsedStr = notUsedCoupons.map(s => s.code).join('\n');
    const errorsCoupons = filter(coupons, { error: true });
    const errorsCouponsStr = errorsCoupons.map(s => s.code).join('\n');
    const usedCoupons = filter(coupons, { used: true });
    const usedStr = usedCoupons.map(s => `${s.code} - ${s.topOfferUrl}`).join('\n');

    return( <>
            <CustomDocumentTitle title="Bazoš - EXPORT">
                <PageTitleBox title="Bazoš - EXPORT" />

            {loading ? <LoadingScreen /> : 
                <>
                    <div className="card mb-0">
                        <div className="card-body">
                            <p>Bazoš připojíte v nastavení kolekce.</p>
                        </div>
                    </div>

                    <PageTitleBox title="TOPOVÁNÍ - POUKÁZKY" />

                    <div className="card">
                        <div className="card-body">
                            {user.bazosOutOfCouponsError && <Alert variant='warning' className="">
                                <b>Doplňte poukázky!</b> V opačném případě nebudou topování vykonána či nahrávání inzerátu bude zrušeno.
                            </Alert>}

                            <p>Inzerobot umí za vás topovat inzeráty, avšak nejprve je potřeba si zakoupit na Bazoši poukázky a ty následně vložit do formuláře níže. Topování se nastavuje v detailu propojení v dané kolekci nebo v detailu inzerátu. Poukázky jsou shodné pro Bazoš.cz i Bazoš.sk.</p>

                            <div className="btn-group mb-4">
                                <a target="_blank" className="btn btn-secondary" href="https://www.bazos.cz/platba-topovani.php">Koupit poukázky (CZK)</a>
                                <a target="_blank" className="btn btn-secondary ml-2" href="https://www.bazos.sk/platba-topovanie.php">Koupit poukázky (EUR)</a>
                            </div>

                            <hr />

                            <div className="my-4">
                                <Textarea
                                    label="Vložte zakoupené poukázky"
                                    placeholder="1 kód = 1 řádek"
                                    value={(form.newCodes || []).map(code => code || '').join('\n').toString()}
                                    onChange={(value) => handleChange(value.split('\n'), 'newCodes')}
                                />

                                <button
                                    onClick={handleSubmitAddCodes}
                                    className="btn btn-primary"
                                >
                                    Uložit poukázky
                                </button>
                            </div>

                            <hr />

                            <p>V případě, že počet poukázek klesne pod {user.bazosCouponsAlertLimit}, budete informováni emailem.</p>

                            <div className="row mt-3">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <label className="section-title mt-0 mb-2">Dostupné ({notUsedCoupons.length})</label>
                                    <textarea value={notUsedStr} readOnly className="form-control" rows={5} />
                                </div>

                                <div className="col-12 col-md-6 col-lg-4">
                                    <label className="section-title mt-0 mb-2">Použité ({usedCoupons.length})</label>
                                    <textarea value={usedStr} readOnly className="form-control" rows={5} />
                                </div>

                                <div className="col-12 col-md-6 col-lg-4">
                                    <label className="section-title mt-0 mb-2">Chybové ({errorsCoupons.length})</label>
                                    <textarea value={errorsCouponsStr} readOnly className="form-control" rows={5} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </CustomDocumentTitle>
        </>)
}))