import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { IServerAccount } from "../../../Interfaces/IServerAccount";
import ServerAccountStore from "../../Stores/ServerAccountStore";
import { SbazarAccountSettingsModal } from "./SbazarAccountSettingsModal";
import { ServerAccountState } from "../ServerAccounts/ServerAccountState";
import SbazarAccountsApiService from "../../../Services/SbazarAccountsApiService";
import { SbazarAccountLoginModal } from "./SbazarAccountLoginModal";

interface IProps {
    account: IServerAccount,
    serverAccountStore?: ServerAccountStore,
}

const sbazarAccountsApiService = new SbazarAccountsApiService();

export const SbazarAccountRow = inject('serverAccountStore')(observer((props: IProps) => {
    const { account } = props;

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    useEffect(() => {
        return () => {
            sbazarAccountsApiService.cancelRequests();
        }
    }, []);

    function handleDelete() {
        Swal.fire({
            title: `Opravdu chcete smazat účet "${account.name}"?`,
            text: 'Tato akce je nevratná',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Ano, smazat',
            icon: 'warning',
            cancelButtonText: 'Zrušit',
        })
            .then(result => {
                if (result.value) {
                    sbazarAccountsApiService.deleteAccount(account.id)
                        .then(data => {
                            if (data.success) {
                                props.serverAccountStore.removeRecords([account.id]);
                            }
                        })
                }
            })
    }

    function handleValidateLogin() {
        sbazarAccountsApiService.verifyLogin(account.id)
            .then(data => {
                const account = data.records.serverAccounts.items[0];

                if (account.signInRequired) {
                    setShowLoginModal(true);
                }
            })
    }

    return (
        <tr key={account.id}>

            <td>
                {showSettingsModal && <SbazarAccountSettingsModal
                    accountServer={account}
                    onHide={() => setShowSettingsModal(false)}
                />}

                {showLoginModal && <SbazarAccountLoginModal
                    accountServer={account}
                    onHide={() => setShowLoginModal(false)}
                />}

            
                {account.name}

                {account.signInRequired === false && <>
                    <br />
                    <small>
                        <a target="blank" href={`https://www.sbazar.cz/${account.accountName}`}> Zobrazit profil</a>
                    </small>
                </>}      
            </td>
            <td>
                {account.userName}
            </td>

            <td>
                <ServerAccountState account={account} />
            </td>

            <td>
                {account.visibleCount}/{account.productsCount}
            </td>


            <td>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" data-boundary="window" className="btn-sm ml-1 h-100" id="dropdownProductOptions">
                        <span className="mr-2">Možnosti</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {account.signInRequired ?
                            <>
                                <Dropdown.Item onClick={() => setShowLoginModal(true)}>
                                    Znovu propojit
                                </Dropdown.Item>
                            </> :

                            <>
                                <Dropdown.Item onClick={() => handleValidateLogin()}>
                                    Ověřit přihlášení
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => setShowLoginModal(true)}>
                                    Změnit přihlášení
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => setShowSettingsModal(true)}>
                                    Nastavení
                                </Dropdown.Item>
                            
                            </>}

                        <Dropdown.Item onClick={() => handleDelete()}>
                            Smazat
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>)
}))