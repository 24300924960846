import * as React from 'react';
import BaseComponent from '../../BaseComponent';
import DictionaryTableRow from './DictionaryTableRow';
import InputText from './InputText';

interface IProps {
    uid: string,
    dictionary: Map<string, string>;
    onChange(value: Map<string, string>, key: string): void,
    onCreateNew(): void,
    keyLabel: string,
    valueLabel: string,
    isValueRequired: boolean,
}

interface IState {
    errors: string[],
    createNewProperty: boolean,
    form: {
        nameKey: string,
        value: string
    }
}

export default class DictionaryKeyValueInput extends BaseComponent<IProps, IState> {

    private inputNewKey = React.createRef<InputText>();
    private inputNewValue = React.createRef<InputText>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            errors: [],
            createNewProperty: false,
            form: {
                nameKey: '',
                value: ''
            }
        };

        this.inputNewKey = React.createRef();
        this.inputNewValue = React.createRef();
    }

    handleChangeValue(value: any, key: string) {
        let dictionary = this.props.dictionary;
        dictionary = {
            ...dictionary,
            [key]:value,
        }
        this.props.onChange(dictionary, this.props.uid);
    }

    handleDelete(nameKey: string) {
        let dictionary : any = this.props.dictionary;

        Object.keys(dictionary).forEach(function (key) {
            if (key === nameKey) delete dictionary[key];
        });
        this.props.onChange(dictionary, this.props.uid);
    }

    handleChangeNew(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    handleSubmitProperty() {
        const inputs = [
            this.inputNewKey,
            this.inputNewValue,
        ];

        const isValid = this.validateInputs(inputs);

        if (isValid) {
            const form = this.state.form;

            let dictionary = this.props.dictionary;
           
            dictionary = {
                ...dictionary,
                [form.nameKey]: form.value,
            }

            this.props.onChange(dictionary, this.props.uid);

            this.setState({
                form: {
                    nameKey: '',
                    value: '',
                },
                createNewProperty: false
            })
        }
    }

    render() {
        const dictionary = this.props.dictionary;
        const createNewProperty = this.state.createNewProperty;
        const form = this.state.form;

        return (
            <div>
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th>{this.props.keyLabel}</th>

                            <th>{this.props.valueLabel}</th>

                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {Array.from(Object.entries(dictionary)).map(([key, value]) => (
                            <DictionaryTableRow
                                key={key}
                                nameKey={key}
                                value={value}
                                onChange={this.handleChangeValue.bind(this)}
                                onDelete={this.handleDelete.bind(this)}
                            />
                        ))}

                        {!createNewProperty &&
                            <tr>
                                <td colSpan={3}>
                                    <button
                                        className="btn btn-light float-right"
                                        onClick={() => this.setState({ createNewProperty: true })}
                                    >
                                        <i className="fas fa-plus mr-1"></i> Přidat další
                                    </button>
                                </td>
                            </tr>}

                        {createNewProperty &&
                            <tr>
                                <td>
                                    <InputText
                                        ref={this.inputNewKey}
                                        value={form.nameKey}
                                        label=""
                                        placeholder={`${this.props.keyLabel}`}
                                        onChange={(value) => this.handleChangeNew(value, 'nameKey')}
                                        required
                                    />
                                </td>

                                <td>
                                    <InputText
                                    ref={this.inputNewValue}
                                    value={form.value}
                                    label=""
                                    placeholder={`${this.props.valueLabel}`}
                                    onChange={(value) => this.handleChangeNew(value, 'value')}
                                    required={this.props.isValueRequired}
                                    />
                                </td>

                                <td>
                                    <button
                                        className="btn btn-primary btn-block mb-3"
                                        onClick={this.handleSubmitProperty.bind(this)}
                                    >
                                        Vytvořit
                                    </button>
                                </td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        );
    }
}