import React from 'react';
import IProduct from '../../Interfaces/IProduct';
import { inject, observer } from 'mobx-react';
import { ShowDate } from '../Others/ShowDate';
import { ProductPrice } from '../Products/ProductPrice';
import ProductImageStore from '../Stores/ProductImageStore';
import CurrentUserStore from '../Stores/CurrentUserStore';
import { MainProductImg } from '../Products/MainProductImg';
import Product from '../../Models/Product';
import { VehicleActions } from './VehicleActions';

interface IProps {
    product: IProduct,
    productImageStore?: ProductImageStore,
    currentUserStore?: CurrentUserStore,
}

export const VehicleTableItem = inject('productImageStore', 'currentUserStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;
    const { product } = props;

    return (
        <>
            <tr key={product.id}>
                <td className="position-relative">
                    <MainProductImg
                        product={product}
                    />
                </td>

                <td style={{ width: '25%' }}>
                    <span className="">{product.name}</span> <br />
                    <small className='d-none d-md-flex text-secondary'>Vytvořeno&nbsp;<ShowDate date={product.dateCreated} /></small>
                </td>

                <td>
                    <small>{product.vehicleLicencePlate}</small>
                    <br />
                    <small>{product.vehicleVin}</small>
                </td>

                <td>{product.vehicleBrand && <>{Product.getVehicleBrandLabel(product.vehicleBrand)}</>}</td>

                <td>
                    {product.vehicleModel && <>{Product.getVehicleModelLabel(product.vehicleModel)}</>}
                </td>

                <td>
                    <ProductPrice
                        product={product}
                    />
                </td>

                <td className="text-center">
                    <VehicleActions product={product} />
                </td>
            </tr>
        </>
    )
}))