import React from "react"

interface IProps {
    text?: string,
    children?: React.ReactChild,
}

export const InfoAlert = (props: IProps) => {
    const { text, children } = props;

    return <div className="alert alert-primary" role="alert">
        {text && <><i className="fas fa-info-circle mr-2"></i>{text}</>}
        {children}
    </div>
} 