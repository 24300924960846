import { filter, find, isNil, join, map, slice, uniqBy } from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import IProduct from "../../../../Interfaces/IProduct";
import { IFacebookCategory } from "../../../../Interfaces/IFacebookCategory";
import SelectBox from "../../../UI/Input/SelectBox";
import { IValidationFormRef } from "../../../../Interfaces/IValidationFormRef";
import { ServerSettingAccordion } from "../../ServerSettingsAccordion";
import IOptionData from "../../../../Interfaces/IOptionData";
import IInputField from "../../../../Interfaces/IInputField";
import { ValidationHelper } from "../../../Utils/ValidationHelper";
import StringHelper from "../../../../Helpers/StringHelper";

interface IProps {
    product: IProduct,
    onChange(value: any, key: any): void,
    categories: IFacebookCategory[],
}

export const ServerFacebookCategoriesAccordion = forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const { product, onChange, categories } = props;
    const [isValidAndComplete, setIsValidAndComplete] = useState(false);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    useEffect(() => {
        const productFbCatId = product.facebookProductCategoryId;

        if (productFbCatId) {
            let category = find(categories, { id: productFbCatId });
            setSelectedValues(category.labels);
        }
    }, [])

    useEffect(() => {
        if (!product.sbazarCategoryId && selectedValues.length > 0) {
            setSelectedValues([]);
            setIsValidAndComplete(false);
        }

        checkIsValid();
    }, [props.product, selectedValues]);
    
    const cat1Ref = useRef<SelectBox>(null);
    const cat2Ref = useRef<SelectBox>(null);
    const cat3Ref = useRef<SelectBox>(null);
    const cat4Ref = useRef<SelectBox>(null);
 
    const handleChangeSelected = (value: string, index: number) => {
        let selectedValuesM = selectedValues;
        if (selectedValuesM.length >= index) {
            selectedValuesM = selectedValues.slice(0, index);
        }
        selectedValuesM[index] = value;
        setSelectedValues(selectedValuesM);
     
        let selectedLabel = join(selectedValuesM, " > ");

        var category = find(categories, { label: selectedLabel });

        if (category) {
            onChange('facebookProductCategoryId', category.id);
        }
        else if (index === 0) {
            onChange('facebookProductCategoryId', null);
            setSelectedValues([])
        }
    }

    const checkIsValid = () => {
        const inputs = getInputs();
        const isValidAndComplete = ValidationHelper.isValid(inputs) &&
            !isNil(product.facebookProductCategoryId) && product.facebookProductCategoryId !== 0;

        setIsValidAndComplete(isValidAndComplete);
    };

    const getInputs = () => {
        const inputs: React.MutableRefObject<IInputField>[] = [];

        inputs.push(
            cat1Ref,
            cat2Ref,
            cat3Ref,
            cat4Ref
        );

        return inputs;
    };

    useImperativeHandle(ref, () => ({
        getInputs
    }));

    const getCategoryLevels = (): IOptionData[][] => {
        const categories1 = getCategories(0);
        const categories2 = getCategories(1);
        const categories3 = getCategories(2);
        const categories4 = getCategories(3);

        var levels: IOptionData[][] = [];

        levels.push(categories1);
        levels.push(categories2);
        levels.push(categories3);
        levels.push(categories4);
        return levels;
    }

    const getCategories = (index: number): IOptionData[] => { 
        if (selectedValues.length < index) {
            return [];
        }

        let selectedValueForCategory = join(slice(selectedValues, 0, index), " > ");
        const categoriesFor: IFacebookCategory[] = filter(categories, category => {
            if (category.labels.length > index) {
                if (category.label.includes(selectedValueForCategory) && category.label) {
                    return true;
                }
            }
            return false;
        })

        const categoriesX: IOptionData[] = uniqBy(map(categoriesFor, category => {
            let option: IOptionData = { label: category.labels[index], value: category.labels[index] }
            return option;
        }), option => option.label);
        return categoriesX;
    }

    const isComplete = () : boolean => {  
        const level = selectedValues.length;
        const categories = getCategoryLevels()[level];

        if (!categories) {
            return true;
        }
        return categories.length === 0;
    }
    const categories1 = getCategories(0);
    const categories2 = getCategories(1);
    const categories3 = getCategories(2);
    const categories4 = getCategories(3);

    const categoryText = join(selectedValues.map(value => StringHelper.capitalizeFirstLetter(value)) || [], " > ");
    const isRequired = !isNil(product.facebookCatalogId);

    return (
        <ServerSettingAccordion
            categoryText={categoryText}
            headingText="Facebook"
            isValid={isValidAndComplete}
            isComplete={isComplete()}
        >
            <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                    <SelectBox
                        ref={cat1Ref}
                        defaultValue="Vyberte"
                        label="Kategorie 1"
                        onChange={(value => handleChangeSelected(value, 0))}
                        value={selectedValues[0]}
                        options={categories1}
                        required={isRequired}
                    />
                </div>

                {categories2.length > 0 &&
                    <div className="col-12 col-md-6 col-lg-3">
                        <SelectBox
                            ref={cat2Ref}
                            defaultValue="Vyberte"
                            label="Kategorie 2"
                            onChange={(value => handleChangeSelected(value, 1))}
                            value={selectedValues[1]}
                            options={categories2}
                            required={false}
                        />
                    </div>}


                {categories3.length > 0 &&
                    <div className="col-12 col-md-6 col-lg-3">
                        <SelectBox
                            ref={cat3Ref}
                            defaultValue="Vyberte"
                            label="Kategorie 3"
                            onChange={(value => handleChangeSelected(value, 2))}
                            value={selectedValues[2]}
                            options={categories3}
                            required={false}
                        />
                    </div>}

                {categories4.length > 0 &&
                    <div className="col-12 col-md-6 col-lg-3">
                        <SelectBox
                            ref={cat4Ref}
                            defaultValue="Vyberte"
                            label="Kategorie 4"
                            onChange={(value => handleChangeSelected(value, 3))}
                            value={selectedValues[3]}
                            options={categories4}
                            required={false}
                        />
                    </div>}
            </div>
        </ServerSettingAccordion>)
})