import { first, groupBy, join } from "lodash";
import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IProductCollection from "../../Interfaces/IProductCollection"

interface IProps {
    collections: IProductCollection[]
}

export const IpAddressBazosCzTd = (props: IProps) => {

    const { collections } = props;
    const groups = groupBy(collections, collection => collection.bazosCzVerificationPhone);
    const groupObjs = Object.entries(groups);

    return (
        <td>
            <ul className="list-unstyled">
                {groupObjs.map(([key, collections]) => {
                    return <IpAddressBazosCzContent key={key} collections={collections} />; // <li key={key}>{collections.map((coll: IProductCollection) => { return <>{coll.name}</> })}</li> // <IpAddressLi key={key} {...collections} />;
                })}

                {groupObjs.length === 0 && <p>Nepřiřazeno</p>}
            </ul>
        </td>)
}

interface IIpProps {
    collections: IProductCollection[]
}

const IpAddressBazosCzContent = (props: IIpProps) => {
    const { collections } = props;
    const firstCollection = first(collections);

    if (!firstCollection) return null;

    return (
        <OverlayTrigger
            placement={'bottom'}
            overlay={
                <Tooltip id={`tooltip-top`}>
                    Kolekce:
                   <br />

                    <small>{join(collections.map((collection: IProductCollection) => { return collection.name }), ', ')}</small>
                </Tooltip>
            }
        >
            <li>{firstCollection.bazosCzVerificationPhone}</li>
        </OverlayTrigger>)
}