import { Currency } from "../../Enums/Currency";
import IPricingItem from "../../Interfaces/IPricingItem";

export class PricingHelper {

    public static getAutoMotoPricing(currency: Currency): IPricingItem[] {
        if (currency === Currency.Czk) {
            return [
                { productMax: 50, pricePerMonth: 749 },
                { productMax: 100, pricePerMonth: 1299 },
                { productMax: 250, pricePerMonth: 1799 },
            ];
        }
        else if (currency === Currency.Eur) {
            return [
                { productMax: 50, pricePerMonth: 31 },
                { productMax: 100, pricePerMonth: 53, },
                { productMax: 250, pricePerMonth: 74, },
            ];
        }
        return [];
    }

    public static getStandardPricing(currency: Currency): IPricingItem[] {
        if (currency === Currency.Czk) {
            return [
                { productMax: 50, pricePerMonth: 499, },
                { productMax: 100, pricePerMonth: 999, },
                { productMax: 250, pricePerMonth: 1299, },
                { productMax: 500, pricePerMonth: 1499, },
                { productMax: 1000, pricePerMonth: 1999, },
                { productMax: 2000, pricePerMonth: 2999, },
            ];
        }
        else if (currency === Currency.Eur) {
            return [
                { productMax: 50, pricePerMonth: 20, },
                { productMax: 100, pricePerMonth: 41, },
                { productMax: 250, pricePerMonth: 54, },
                { productMax: 500, pricePerMonth: 62, },
                { productMax: 1000, pricePerMonth: 82, },
                { productMax: 2000, pricePerMonth: 124, },
            ];
        }
        return [];
    }

    public static getIpPrice(currency: Currency): number {
        switch (currency) {

            case Currency.Czk:
                return 40;

            case Currency.Eur:
                return 1.63;
        }
    }

    public static getFacebookMarketplacePricePerProduct(currency: Currency): number {
        switch (currency) {

            case Currency.Czk:
                return 1;

            case Currency.Eur:
                return 0.041;
        }
    }
}