import React from 'react';
import CustomDocumentTitle from '../../../../Utils/CustomDocumentTitle';
import { inject, observer } from 'mobx-react';
import BaseComponent from './../../../../BaseComponent';
import CurrentUserStore from '../../../../Stores/CurrentUserStore';
import ProductStore from '../../../../Stores/ProductStore';
import IProductCollection from '../../../../../Interfaces/IProductCollection';
import ProductImageStore from '../../../../Stores/ProductImageStore';
import ProductWizardStep1 from '../Steps/ProductWizardStep1';
import Product from '../../../../../Models/Product';
import GlobalSettingsStore from '../../../../Stores/GlobalSettingsStore';
import { PageTitleBox } from '../../../../Others/PageTitleBox';
import LoadingScreen from '../../../../Screens/LoadingScreen';
import ProductWizard from '../../ProductWizard';
import IWizardStep from '../../../../../Interfaces/IWizardStep';
import { WizardStepType } from '../../../../../Enums/WizardStepType';
import IProduct from '../../../../../Interfaces/IProduct';
import ProductWizardStepServers from '../Steps/ProductWizardStepServers';
import { ProductType } from '../../../../../Enums/ProductType';
import { ProductWizardStepImages } from '../Steps/ProductWizardStepImages';
import { OnlineServer } from '../../../../../Enums/OnlineServer';
import { Alert } from 'react-bootstrap';

interface IState {
    basicCompleted: boolean,
    currentStep: IWizardStep,
    productId: string,
    loading: boolean,
    product: IProduct,
}

interface IProps {
    collection: IProductCollection,
    currentUserStore?: CurrentUserStore,
    productStore?: ProductStore,
    productImageStore?: ProductImageStore,
    globalSettingsStore?: GlobalSettingsStore,
}

@inject('currentUserStore')
@inject('productStore')
@inject('productImageStore')
@inject('globalSettingsStore')
@observer
export default class CreateProductOthersPage extends BaseComponent<IProps, IState> {

    private step1: React.RefObject<ProductWizardStep1>;
    private stepServers: React.RefObject<ProductWizardStepServers>;

    constructor(props: IProps) {
        super(props);

        const collection = this.props.collection;
        const user = this.props.currentUserStore.user;

        this.state = {
            basicCompleted: false,
            currentStep: {
                title: '',
                type: WizardStepType.Basic,
                orderNumber: 1
            },
            productId: null,
            loading: true,
            product: {
                name: '',
                bazosCZ: collection.bazosCZ,
                bazosSK: collection.bazosSK,
                dateCreated: new Date(),
                description: '',
                enabled: true,
                id: '',
                priceCZK: null,
                priceEUR: null,
                userId: '',
                productCollectionId: null,
                bazosCzPriceOption: 1,
                bazosCzCategory: 0,
                bazosCzSection: 0,
                bazosSkCategory: 0,
                bazosSkPriceOption: 1,
                bazosSkSection: 0,
                section: null,
                type: ProductType.Others,
                delete: false,
                sbazar: collection.sbazar,
                sbazarCategoryId: 0,
                sbazarPriceByAgreement: false,
                collectionMigrationPending: false,
                vehicleVin: '',
                sbazarUseDescription: false,
                bazosCzUseDescription: false,
                bazosSkUseDescription: false,
                bazosCzDescription: '',
                bazosSkDescription: '',
                sbazarDescription: '',
                externalProductUrl: '',
                brand: '',
                sbazarName: '',
                bazosCzName: '',
                bazosSkName: '',
                facebookName: '',
                facebookDescription: '',
                websiteName: '',
                websiteDescription: '',
                websiteCustomContent: false,
                autobazarEu: false,
                vatRate: user.vatRate,
                dateOfPurchase: new Date(),
            }
        }

        this.step1 = React.createRef();
        this.stepServers = React.createRef();
    }

    componentDidMount() {
        this.facebookCatalogsApiService.getCatalogs()
            .then(data => {
                this.setState({
                    loading: false,
                })
            })

        const user = this.props.currentUserStore.user;
        if (user.uploaderServers.includes(OnlineServer.AutobazarEu)) {
            this.handleChange('autobazarEu', true);
        }
        if (user.uploaderServers.includes(OnlineServer.GoogleMerchant)) {
            this.handleChange('googleMerchant', true);
        }
    }

    handleChange(key: string, value: any) {
        let product = this.state.product;
        product.productCollectionId = this.props.collection.id;

        if (key === 'sbazarAccountId' && value) {
            product.sbazar = true;
        }
        else if (key === 'sbazarAccountId') {
            product.sbazar = false;
        }
        if (key === 'facebookCatalogId') {
            product.facebookCatalogEnabled = value > 0;
        }
        if (key === 'bazosCzSection' && !product.bazosSkSection) {
            product.bazosSkSection = value;
        }
        if (key === 'bazosCzCategory' && !product.bazosSkCategory && product.bazosCzSection === product.bazosSkSection) {
            product.bazosSkCategory = value;
        }
        if (key === 'bazosSkSection' && !product.bazosCzSection) {
            product.bazosCzSection = value;
        }
        if (key === 'bazosSkCategory' && !product.bazosCzCategory && product.bazosCzSection === product.bazosSkSection) {
            product.bazosCzCategory = value;
        }
        if (key === 'priceKc' || key === 'priceEur') {
            value = parseInt(value);
        }

        if (key === 'bazosCzSection') {
            product.bazosCzCategory = null;
        }
        if (key === 'bazosSkSection') {
            product.bazosSkCategory = null;
        }
        if (key === 'section') {
            value = parseInt(value);
        }
        product = {
            ...product,
            [key]: value
        };

        this.setState({
            product: product
        })
    }

    handleGoToStep(step: IWizardStep) {
        if (step) {
            this.setState({
                currentStep: step
            })
        }
    }

    handleGoToNextStep(product: IProduct) {
        const currentStep = this.state.currentStep;

        if (currentStep.type === WizardStepType.Basic) {
            this.step1.current.validateAsync()
                .then(value => {
                    if (value === true) {
                        this.goToNextStep(product);
                    }
                })
        }
        else if (currentStep.type === WizardStepType.Servers) {
            if (this.stepServers.current.validate()) {
                const product = this.state.product;

                this.productApiService.create(product)
                    .then((data) => {
                        this.setState({
                            currentStep: Product.getStepByNumber(product, currentStep.orderNumber + 1),
                            productId: data.records.products.items[0].id
                        })
                    })
            }
        }
    }

    goToNextStep(product: IProduct) {
        const nextStep = Product.getStepByNumber(product, this.state.currentStep.orderNumber + 1)

        if (nextStep) {
            this.setState({
                currentStep: nextStep
            })
        }
    }

    handleGoToLastStep(product: IProduct) {
        const newStep = Product.getStepByNumber(product, this.state.currentStep.orderNumber - 1);

        if (newStep) {
            this.setState({
                currentStep: newStep
            });
        }
    }

    render() {
        const { product, loading } = this.state;
        const collection = this.props.collection;

        return (
            <CustomDocumentTitle title={`Kolekce "${collection.name}" - Přidat inzerát`}>
                <PageTitleBox title={`Kolekce "${collection.name}" - Přidat inzerát`} />

                <Alert variant={"danger"} className="mt-2">
                    <b>Používáte staré rozhraní pro přidávání inzerátů. Toto rozhraní bude odstraněno 1.8.2024 !!!</b>
                </Alert>

                {loading ? <LoadingScreen /> :
                    <ProductWizard
                        enableGoToBack={false}
                        currentStep={this.state.currentStep}
                        goToStep={this.handleGoToStep.bind(this)}
                        goToLastStep={this.handleGoToLastStep.bind(this, product)}
                        goToNextStep={this.handleGoToNextStep.bind(this, product)}
                        product={product}
                    >
                        {this.state.currentStep.type === WizardStepType.Basic ?
                            <ProductWizardStep1
                                ref={this.step1}
                                product={product}
                                onChange={this.handleChange.bind(this)}
                                collection={collection}
                                sectionOptions={Product.getOthersSections()}
                                globalSettings={this.props.globalSettingsStore.settings}
                                createNew={true}
                                onChangeProduct={(product) => this.setState({ product: product })}
                            /> : null}

                        {this.state.currentStep.type === WizardStepType.Servers ?
                            <ProductWizardStepServers
                                ref={this.stepServers}
                                product={product}
                                collection={collection}
                                onChange={this.handleChange.bind(this)}
                                onChangeProduct={(product) => this.setState({ product: product })}
                            /> : null}

                        {this.state.currentStep.type === WizardStepType.Images ?
                            <ProductWizardStepImages
                                productId={this.state.productId}
                                collection={collection}
                            /> : null}
                    </ProductWizard>}
            </CustomDocumentTitle>
        )
    }
}