import React from "react"
import { IWebsite } from "../../Interfaces/IWebsite"
import { WebsiteTableRow } from "./WebsiteTableRow";

interface IProps {
    websites: IWebsite[],
    className?: string
}

export const WebsitesTable = (props: IProps) => {

    const websites = props.websites;

    return (
        <>
            <div className={`table-responsive ${props.className}`}>
                <table className="table table-stripped">
                    <thead>
                        <tr>
                            <th>Název</th>

                            <th>Typ</th>

                            <th>Stav</th>

                            <th>Doména</th>

                            <th>Počet inzerátů</th>

                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {websites.map(website => {
                            return <WebsiteTableRow key={website.id} website={website} />
                        })}
                    </tbody>
                </table>
            </div>
        </>)
}