import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import AppIpAddressStore from '../../Stores/AppIpAddressStore';
import { filter } from 'lodash';
import IAppIpAddress from '../../../Interfaces/IAppIpAddress';
import { inject, observer } from 'mobx-react';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import IProductCollection from '../../../Interfaces/IProductCollection';
import { IpAddressBazosCzTd } from '../../IpAddresses/IpAddressBazosCzTd';
import { IpAddressBazosSkTd } from '../../IpAddresses/IpAddressBazosSkTd';
import { PageTitleBox } from '../../Others/PageTitleBox';
import UserApiService from '../../../Services/UserApiService';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import CurrencyHelper from '../../../Helpers/CurrencyHelper';
import DateTimeHelper from '../../Utils/DateTimeHelper';
import { toast } from 'react-toastify';

interface IProps {
    currentUserStore?: CurrentUserStore,
    appIpAddressStore?: AppIpAddressStore,
    productCollectionStore?: ProductCollectionStore,
}

const userApiService = new UserApiService();

export const IpAddressesPage = inject('currentUserStore', 'appIpAddressStore', 'productCollectionStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;
    var ips = filter(props.appIpAddressStore.list, { userId: user.id });
    var collections = filter(props.productCollectionStore.list, { userId: user.id });

    useEffect(() => {
        return () => {
            userApiService.cancelRequests();
        }
    }, [])

    const orderNewIp = () => {
        userApiService.getPriceForNewIp()
            .then(data => {
                const price = data.others.get("price");

                Swal.fire({
                    title: `Přidání IP adresy vás bude stát ${price} ${CurrencyHelper.getSymbol(user.accountCurrency)}`,
                    html: `<p>
                    Zaplatíte za každý započatý měsíc do konce vaší licence (${DateTimeHelper.convertUTCDateToLocalDate(user.dateExpirationUploader).toLocaleDateString()}). </p>
                    <br>
                    <b>Celkově: ${price} ${CurrencyHelper.getSymbol(user.accountCurrency)}</b>
                    <br>
                    <p>Tato částka vám bude vyúčtována při další fakturaci.</p>`,
                    icon: 'warning',
                    confirmButtonText: `Souhlasím`,
                    showConfirmButton: true,
                    showCancelButton: true,
                    cancelButtonText: `Zrušit`,
                }).then(result => {
                    if (result.isConfirmed) {
                        userApiService.orderIP()
                            .then(response => {
                                if (response.success) {
                                    toast.success("IP přidána");
                                }
                            })
                    }
                })
            })
    }

    return (
        <CustomDocumentTitle title="IP adresy">
            <PageTitleBox title="IP adresy" />

            <div className="card">
                <div className="card-body">

                    <button
                        className="btn btn-primary mb-3"
                        onClick={orderNewIp}>
                        Objednat další IP adresu
                    </button>

                    <div className="table-responsive">
                        <table className="table table-striped mg-b-0">
                            <thead>
                                <tr>
                                    <th>IP adresa</th>

                                    <th>Bazoš.cz</th>

                                    <th>Bazoš.sk</th>

                                    <th className="text-center">Stav</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(ips).map((appIp: IAppIpAddress) => {
                                    /*  var assignedCollections: IProductCollection[] = filter(collections, coll => {
                                          if (coll.bazosCzAppIpAddressId === appIp.id || coll.bazosSkAppIpAddressId === appIp.id || coll.sbazarAppIpAddressId === appIp.id) {
                                              return true;
                                          }
                                          return false;
                                      });*/

                                    var assignedCollectionsBazosCz: IProductCollection[] = filter(collections, coll => {
                                        if (coll.bazosCzAppIpAddressId === appIp.id && coll.bazosCZ) {
                                            return true;
                                        }
                                        return false;
                                    });

                                    var assignedCollectionsBazosSk: IProductCollection[] = filter(collections, coll => {
                                        if (coll.bazosSkAppIpAddressId === appIp.id && coll.bazosSK) {
                                            return true;
                                        }
                                        return false;
                                    });


                                    /*   var assignedCollectionsSbazar: IProductCollection[] = filter(collections, coll => {
                                           if (coll.sbazarAppIpAddressId === appIp.id && coll.sbazar) {
                                               return true;
                                           }
                                           return false;
                                       });
                                       */
                                    return (
                                        <tr key={appIp.id}>
                                            <td>
                                                {appIp.ip}
                                            </td>

                                            <IpAddressBazosCzTd collections={assignedCollectionsBazosCz} />

                                            <IpAddressBazosSkTd collections={assignedCollectionsBazosSk} />

                                            <td className="text-center">
                                                {appIp.blocked === false ? <span><i className="fas fa-check mr-1" /></span> : <b className="text-danger">Zablokována</b>}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    )
}))