import IProductCollection from '../../../../Interfaces/IProductCollection';
import { AddProductButton } from '../../../Products/AddProductButton';

interface IProps {
    disabledNewProduct: boolean,
    collection: IProductCollection
}

export const NoProductsCollection = (props: IProps) => {

    const disabledNewProduct = props.disabledNewProduct;
    const collection = props.collection;

    return (
        <div className="card">
            <div className="card-body">
                <div className="py-5">
                    <div className="text-center">
                        <h3>Přidejte svůj první inzerát a začněte prodávat</h3>
                    </div>
                    <br />

                    <div className="d-flex justify-content-center py-3">
                        <AddProductButton
                            size="lg"
                            collectionId={collection.id}
                            disabled={disabledNewProduct}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}