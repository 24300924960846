import IProductImage from '../../../../Interfaces/IProductImage';
import Dropzone from 'react-dropzone';
import { orderBy } from 'lodash';
import IProduct from '../../../../Interfaces/IProduct';
import { ImagesSortableList } from '../../../Products/Images/ImagesSortableList';
import { uploadImages } from '../../../Products/Images/uploadImages';
import { WaitScreen } from '../../../Screens/WaitScreen';
import { useState } from 'react';

interface IProps {
    images: IProductImage[],
    product: IProduct
}

export const ProductImagesEditor = (props: IProps) => { 

    const product = props.product;
    const images = orderBy(props.images, ['orderNumber'], ['asc']);

    const [uploadImageLoading, setUploadImageLoading] = useState(false);

    const uploadImagesProcess = (images: File[], p: IProduct) => {
        setUploadImageLoading(true);
        uploadImages(images, p)
            .then(() => {
                setUploadImageLoading(false);
            })
    }

    return (
        <div>
            <Dropzone onDrop={(files) => uploadImagesProcess(files, product)}>
                {({ getRootProps, getInputProps, }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} accept="image/x-png,image/jpeg" />
                            <div className="dropzone-area my-4">
                                <h3>Vybrat obrázky</h3>
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>

            {uploadImageLoading &&
                <WaitScreen
                    title="Nahrávám obrázky..."
                />}

            <ImagesSortableList
                images={images}
                product={product}
            />
        </div>
    )
}