import { useEffect, useState } from "react"
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ProductType } from "../../Enums/ProductType";
import IProduct from "../../Interfaces/IProduct"
import ProductApiService from "../../Services/ProductApiService";
import { CarSalesCard } from "../Products/Cars/CarSalesCard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ProductDeleteModal } from "../Products/ProductDeleteModal";
interface IProps {
    product: IProduct,
}

const productApiService = new ProductApiService();

export const VehicleOptionsDropdown = (props: IProps) => {
    const { product } = props;

    useEffect(() => {
        return () => {
            productApiService.cancelRequests();
        }
    }, [])

    function handleMakeDisabled() {
        Swal.fire({
            title: `${product.name}`,
            html: `Inzerát smažeme z inzertních portálů, ale můžete jej znovu kdykoli vystavit`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ano, stáhnout z prodeje ',
            cancelButtonText: 'Zrušit'
        }).then((result) => {
            if (result.value) {
                productApiService.disable(product.id);
            }
        })
    }

    function handleMakeEnabled() {
        productApiService.enable(product.id);
    }

    function handleDownloadSalesCard() {
        toast.info('Karta byla stažena do vašeho počítače');
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <>
            {showDeleteModal && <ProductDeleteModal
                onHide={() => setShowDeleteModal(false)}
                product={product}
            />}

            <Dropdown>
                <Dropdown.Toggle variant="secondary" data-boundary="window" className="btn-sm ml-1 h-100" id="dropdownProductOptions">
                    <span className="mr-2">Možnosti</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {product.enabled ?
                        <OverlayTrigger
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Inzerát smažeme z inzertních serverů, ale můžete jej kdykoliv vystavit znovu
                                </Tooltip>
                            }
                        >
                            <Dropdown.Item onClick={() => handleMakeDisabled()}>
                                Stáhnout z prodeje
                            </Dropdown.Item>
                        </OverlayTrigger>
                        : null}

                    {!product.enabled ?
                        <Dropdown.Item onClick={() => handleMakeEnabled()}>
                            Vrátit do prodeje
                        </Dropdown.Item> : null}

                    {product.type === ProductType.Car &&
                        <div onClick={() => handleDownloadSalesCard()}>
                            <PDFDownloadLink
                                className="dropdown-item"
                                fileName={`Prodejní karta ${product.name}.pdf`}
                                document={<CarSalesCard product={product} />}
                            >
                                Stáhnout prodejní kartu
                            </PDFDownloadLink>
                        </div>
                    }
                    <OverlayTrigger
                        placement={'left'}
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Vozidlo uložíme do archívu, kde si jej budete moci kdykoli dohledat.
                            </Tooltip>
                        }
                    >
                        <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                            <span className="text-secondary"> <i className="bi bi-archive mr-2 d-none d-md-inline"></i>
                                Archivovat</span>
                        </Dropdown.Item>
                    </OverlayTrigger>
                </Dropdown.Menu>
            </Dropdown>
        </>)
}