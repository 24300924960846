import { observable, action, makeAutoObservable } from 'mobx'
import { findIndex, remove, find, filter, orderBy } from 'lodash';
import IProduct from '../../Interfaces/IProduct';
import { ProductType } from '../../Enums/ProductType';
import IProductCollection from '../../Interfaces/IProductCollection';
import IRecordsCount from '../../Interfaces/IRecordsCount';
import { ProductServerErrorType } from '../../Enums/ProductServerErrorType';

export default class ProductStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IProduct[] = [];

    @observable totalCounts: IRecordsCount[] = [];

    getCount(type: string): number {
        let count: IRecordsCount = find(this.totalCounts, { type: type });
        if (!count) {
            return 0;
        }
        return count.value;
    }

    @action
    addCounts(counts: IRecordsCount[]) {
        if (!counts) {
            return;
        }

        counts.forEach((count: IRecordsCount) => this.addCount(count));
    }

    @action
    private addCount(count: IRecordsCount) {
        if (!count) {
            return;
        }

        const index = findIndex(this.totalCounts, { type: count.type });
        if (index >= 0) {
            this.totalCounts.splice.apply(this.totalCounts, [index, 1, count]);
        } else {
            this.totalCounts.push(count);
        }
    }

    @action
    addRecords(data: any): any {
        data.forEach((record: IProduct) => this.addRecord(record));
    }

    @action
    private addRecord(record: IProduct) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: string[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }

    getPendingProcessing(collections: IProductCollection[]) {
        return filter(this.list, p => {
            var collection = find(collections, { id: p.productCollectionId });

            if (p.enabled === false) {
                return false;
            }

            if (collection.sbazar && collection.sbazarError === false && (p.sbazar && p.sbazarError === false && (p.sbazarPendingUpload || p.sbazarPendingEdit || p.sbazarPendingDelete || p.sbazarPendingCheck))) {
                return true;
            }
            if (collection.bazosCZ && collection.bazosCzError === false && collection.bazosCzRequiredSignIn === false && (p.bazosCZ && p.bazosCzError === false && (p.bazosCzPendingUpload || p.bazosCzPendingEdit || p.bazosCzPendingDelete || p.bazosCzPendingCheck))) {
                return true;
            }
            if (collection.bazosSK && collection.bazosSkError === false && collection.bazosSkRequiredSignIn === false && (p.bazosSK && p.bazosSkError === false && (p.bazosSkPendingUpload || p.bazosSkPendingEdit || p.bazosSkPendingDelete || p.bazosSkPendingCheck))) {
                return true;
            }
            return false;
        })
    }

    getWithError(collections: IProductCollection[]) {
        return filter(this.list, p => {
            var collection = find(collections, { id: p.productCollectionId });

            if (p.enabled && collection.sbazar && collection.sbazarError === false && p.sbazar && p.sbazarError) {
                return true;
            }
            if (p.enabled && collection.bazosCZ && collection.bazosCzError === false && p.bazosCZ && p.bazosCzError) {
                return true;
            }
            if (p.enabled && collection.bazosSK && collection.bazosSkError === false && p.bazosSK && p.bazosSkError) {
                return true;
            }
            return false;
        })
    }

    getWithFatalError(collections: IProductCollection[]) {
        return filter(this.getWithError(collections), p => {

            if (
                p.bazosCzErrorActionType === ProductServerErrorType.FatalErrorUpload ||
                p.bazosSkErrorActionType === ProductServerErrorType.FatalErrorUpload ||
                p.sbazarErrorActionType === ProductServerErrorType.FatalErrorUpload
            ) {
                return true;
            }
            return false;
        })
    }

    getById(id: string): IProduct {
        return find(this.list, {
            id: id
        });
    }

    getByCollection(collectionId: string): IProduct[] {
        let products = filter(this.list, { productCollectionId: collectionId });
        return orderBy(products, 'dateCreated', 'desc');
    }

    getByIds(ids: string[]): IProduct[] {
        return filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
    }
}