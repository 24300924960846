import React from 'react';
import { CountryHelper } from '../../Helpers/CountryHelper';
import { IBilling } from '../../Interfaces/IBilling';
import BaseComponent from '../BaseComponent';
import InputText from '../UI/Input/InputText';
import SelectBox from '../UI/Input/SelectBox';
import InputEmail from '../UI/Input/InputEmail';
import { SubmitButton } from '../UI/Input/SubmitButton';
import { Country } from '../../Enums/Country';
import { Magic } from 'react-bootstrap-icons';
import CheckBox from '../UI/Input/CheckBox';
import IUser from '../../Interfaces/IUser';

interface IState {

}

interface IProps {
    billing: IBilling,
    onChange(value: any, key: string): void,
    loadingGetByIc: boolean,
    onLoadByIc(): void,
    user: IUser,
}

export default class BillingForm extends BaseComponent<IProps, IState> {

    private companyName: React.RefObject<InputText>;
    private ic: React.RefObject<InputText>;
    private dic: React.RefObject<InputText>;
    private icDph: React.RefObject<InputText>;
    private street: React.RefObject<InputText>;
    private streetRegistryNumber: React.RefObject<InputText>;
    private city: React.RefObject<InputText>;
    private zip: React.RefObject<InputText>;
    private country: React.RefObject<SelectBox>;
    private email: React.RefObject<InputEmail>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loadingGetByIc: false,
            loadBillingErrors: [],
        }

        this.companyName = React.createRef();
        this.ic = React.createRef();
        this.dic = React.createRef();
        this.icDph = React.createRef();
        this.street = React.createRef();
        this.streetRegistryNumber = React.createRef();
        this.city = React.createRef();
        this.zip = React.createRef();
        this.country = React.createRef();
        this.email = React.createRef();
    }

    getInputs() {
        return [
            this.ic,
            this.country,
            this.companyName,
            this.dic,
            this.icDph,
            this.street,
            this.streetRegistryNumber,
            this.city,
            this.zip,
            this.email,
        ];
    }

    validate(): boolean {
        return this.validateInputs(this.getInputs());
    }

    render() {
        const { billing, onChange, user } = this.props;

        const countryOptions = CountryHelper.getCountryOptionsBilling();

        return (
            <>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <InputText
                            ref={this.ic}
                            label="IČ"
                            value={billing.ic}
                            onChange={(value) => onChange(value, 'ic')}
                            length={8}
                            allowOnlyDigits={true}
                            required={true}
                            name="ic"
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <SelectBox
                            ref={this.country}
                            label="Stát"
                            value={billing.country}
                            onChange={(value) => onChange(value, 'country')}
                            required
                            defaultValue="Vyberte"
                            options={countryOptions}
                        />
                    </div>
                </div>

                {billing.country === Country.Czechia &&
                    <>

                        <SubmitButton
                            loading={this.props.loadingGetByIc}
                            btnVariant="info"
                            onClick={this.props.onLoadByIc}
                        >
                            <>
                                <Magic className="mr-2" />Načíst dle IČ
                            </>
                        </SubmitButton>
                        <hr />
                    </>}

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <InputText
                            ref={this.companyName}
                            label="Oficiální název společnosti nebo OSVČ"
                            onChange={(value) => onChange(value, 'companyName')}
                            required
                            value={billing.companyName}
                            name="company"
                        />
                    </div>


                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <InputText
                            ref={this.dic}
                            label={"DIČ"}
                            value={billing.dic}
                            onChange={(value) => onChange(value, 'dic')}
                            name="vat_id"
                        />
                    </div>

                    {billing.country === Country.Slovakia &&
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <InputText
                                ref={this.icDph}
                                label={"IČ DPH"}
                                value={billing.icDph}
                                onChange={(value) => onChange(value, 'icDph')}
                                name="vat_id"
                            />
                        </div>}

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <InputText
                            ref={this.street}
                            label="Ulice"
                            value={billing.street}
                            onChange={(value) => onChange(value, 'street')}
                            required
                            name="street"
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <InputText
                            ref={this.streetRegistryNumber}
                            value={billing.streetRegistryNumber}
                            required={true}
                            label="Číslo popisné"
                            onChange={(value) => onChange(value, 'streetRegistryNumber')}
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <InputText
                            ref={this.city}
                            label="Město"
                            value={billing.city}
                            onChange={(value) => onChange(value, 'city')}
                            required
                            name="city"
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <InputText
                            ref={this.zip}
                            label="PSČ"
                            value={billing.zip}
                            onChange={(value) => onChange(value, 'zip')}
                            required
                            length={5}
                            name="postalcode"
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <InputEmail
                            ref={this.email}
                            label="Email (pro zasílání dokladů)"
                            value={billing.email}
                            onChange={(value) => onChange(value, 'email')}
                            required={false}
                        />
                    </div>
                </div>
            </>
        )
    }
}