import IOptionData from '../Interfaces/IOptionData';
import { find, first, orderBy } from 'lodash';
import IProduct from '../Interfaces/IProduct';
import { ProductType } from '../Enums/ProductType';
import { VehicleFuelType } from '../Enums/VehicleFuelType';
import { VehicleCondition } from '../Enums/VehicleCondition';
import { VehicleColor } from '../Enums/VehicleColor';
import { VehicleEmissionClass } from '../Enums/VehicleEmissionClass';
import { VehicleBrand } from '../Enums/VehicleBrand';
import { VehicleModel } from '../Enums/VehicleModel';
import { OnlineServer } from '../Enums/OnlineServer';
import IProductServerInfo from '../Interfaces/IProductServerInfo';
import IProductCollection from '../Interfaces/IProductCollection';
import { VehicleType } from '../Enums/VehicleType';
import { VehicleStroke } from '../Enums/VehicleStroke';
import IWizardStep from '../Interfaces/IWizardStep';
import { WizardStepType } from '../Enums/WizardStepType';
import { IProductServerAccount } from '../Interfaces/IProductServerAccount';
import IUser from '../Interfaces/IUser';
import { autoImporterStore } from './../App';
import { ProductSubcategory } from '../Enums/ProductSubcategory';
import { ProductCondition } from '../Enums/ProductCondition';
import { Currency } from '../Enums/Currency';

export default class Product {

    public static getEditLink(product: IProduct) {
        return `/inzeraty/${product.id}/upravit`;
    }

    public static getVehicleYearOptions(): IOptionData[] {
        let options: IOptionData[] = [];

        for (var i = new Date().getFullYear(); i >= 1960; i--) {
            options.push({ label: i.toString(), value: i });
        }

        return options;
    }

    public static getVehicleModelOptions(brand: VehicleBrand): IOptionData[] {
        let options: IOptionData[] = [];
        for (let modelString in VehicleModel) {
            if (!isNaN(Number(modelString))) {
                const model = (parseInt(modelString) as VehicleModel);

                if (brand === VehicleBrand.Abarth && model > 100 && model < 200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Acura && model > 200 && model < 300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Aixam && model > 300 && model < 400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.AlfaRomeo && model > 400 && model < 500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Alpina && model > 500 && model < 600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.ARO && model > 600 && model < 700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.AstonMartin && model > 700 && model < 800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Audi && model > 800 && model < 900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Austin && model > 900 && model < 1000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.AustroFiat && model > 1000 && model < 1100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.AustroDaimler && model > 1100 && model < 1200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Auverland && model > 1200 && model < 1300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Bellier && model > 1300 && model < 1400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Bentley && model > 1400 && model < 1500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Bitter && model > 1500 && model < 1600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.BMW && model > 1600 && model < 1700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Bugatti && model > 1700 && model < 1800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Buick && model > 1800 && model < 1900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Cadillac && model > 1900 && model < 2000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Casalini && model > 2000 && model < 2100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Caterham && model > 2100 && model < 2200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Citroen && model > 2200 && model < 2300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Cupra && model > 2300 && model < 2400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Dacia && model > 2400 && model < 2500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.DAELIM && model > 2500 && model < 2600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Daewoo && model > 2600 && model < 2700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Daihatsu && model > 2700 && model < 2800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Dodge && model > 2800 && model < 2900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.DR && model > 2900 && model < 3000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.DS && model > 3000 && model < 3100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Eagle && model > 3100 && model < 3200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Ferrari && model > 3200 && model < 3300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Fiat && model > 3300 && model < 3400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Fisker && model > 3400 && model < 3500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Ford && model > 3500 && model < 3600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.GMC && model > 3600 && model < 3700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Gonow && model > 3700 && model < 3800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Gordon && model > 3800 && model < 3900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.GreatWall && model > 3900 && model < 4000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Grecav && model > 4000 && model < 4100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Holden && model > 4100 && model < 4200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Honda && model > 4200 && model < 4300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Honker && model > 4300 && model < 4400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Hummer && model > 4400 && model < 4500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Hurtan && model > 4500 && model < 4600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Hyundai && model > 4600 && model < 4700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Changhe && model > 4700 && model < 4800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Chatenet && model > 4800 && model < 4900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Chevrolet && model > 4900 && model < 5000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Chrysler && model > 5000 && model < 5100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Infiniti && model > 5100 && model < 5200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Isuzu && model > 5200 && model < 5300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Italcar && model > 5300 && model < 5400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Iveco && model > 5400 && model < 5500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.JAC && model > 5500 && model < 5600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Jaguar && model > 5600 && model < 5700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.JDM && model > 5700 && model < 5800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Jeep && model > 5800 && model < 5900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Kaipan && model > 5900 && model < 6000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Kia && model > 6000 && model < 6100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Koenigsegg && model > 6100 && model < 6200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Lada && model > 6200 && model < 6300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Lamborghini && model > 6300 && model < 6400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Lancia && model > 6400 && model < 6500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.LandRover && model > 6500 && model < 6600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Lexus && model > 6600 && model < 6700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Ligier && model > 6700 && model < 6800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Lincoln && model > 6800 && model < 6900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Lotus && model > 6900 && model < 7000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Marcos && model > 7000 && model < 7100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.MartinMotors && model > 7100 && model < 7200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Maruti && model > 7200 && model < 7300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Maserati && model > 7300 && model < 7400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Mazda && model > 7400 && model < 7500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.MCC && model > 7500 && model < 7600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.McLaren && model > 7600 && model < 7700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.MercedesBenz && model > 7700 && model < 7800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Mercury && model > 7800 && model < 7900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.MG && model > 7900 && model < 8000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Microcar && model > 8000 && model < 8100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Mini && model > 8100 && model < 8200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Mitsubishi && model > 8200 && model < 8300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Moskvic && model > 8300 && model < 8400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.MTX && model > 8400 && model < 8500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Nissan && model > 8500 && model < 8600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Oldsmobile && model > 8600 && model < 8700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Oltcit && model > 8700 && model < 8800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Opel && model > 8800 && model < 8900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Pagani && model > 8900 && model < 9000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Peugeot && model > 9000 && model < 9100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Plymouth && model > 9100 && model < 9200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Polestar && model > 9200 && model < 9300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.PolskiFiat && model > 9300 && model < 9400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Pontiac && model > 9400 && model < 9500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Porsche && model > 9500 && model < 9600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Proton && model > 9600 && model < 9700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.RaytonFissore && model > 9700 && model < 9800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Renault && model > 9800 && model < 9900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.RollsRoyce && model > 9900 && model < 10000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Rover && model > 10000 && model < 10100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Saab && model > 10100 && model < 10200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Santana && model > 10200 && model < 10300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Saturn && model > 10300 && model < 10400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Scion && model > 10400 && model < 10500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Seat && model > 10500 && model < 10600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Shuanghuan && model > 10600 && model < 10700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Smart && model > 10700 && model < 10800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.SsangYong && model > 10800 && model < 10900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Subaru && model > 10900 && model < 11000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Suzuki && model > 11000 && model < 11100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Skoda && model > 11100 && model < 11200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Tata && model > 11200 && model < 11300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Tatra && model > 11300 && model < 11400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Tavria && model > 11400 && model < 11500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Tesla && model > 11500 && model < 11600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Toyota && model > 11600 && model < 11700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Trabant && model > 11700 && model < 11800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.TVR && model > 11800 && model < 11900) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.UAZ && model > 11900 && model < 12000) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Ultima && model > 12000 && model < 12100) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Volkswagen && model > 12100 && model < 12200) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Volvo && model > 12200 && model < 12300) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Wartburg && model > 12300 && model < 12400) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Yugo && model > 12400 && model < 12500) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Zastava && model > 12500 && model < 12600) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.ZhiDou && model > 12600 && model < 12700) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
                if (brand === VehicleBrand.Dongfeng && model > 12700 && model < 12800) {
                    options.push({ label: this.getVehicleModelLabel(model), value: model });
                }
            }
        }
        options.push({ value: VehicleModel.Ostatni, label: this.getVehicleModelLabel(VehicleModel.Ostatni) })
        return options;
    }

    public static getVehicleModelLabel(model: VehicleModel): string {
        switch (model) {
            case VehicleModel.Ostatni:
                return 'Ostatní';

            case VehicleModel.Abarth124Spider:
                return '124 Spider';

            case VehicleModel.Abarth500:
                return '500';

            case VehicleModel.Abarth500C:
                return '500 C';

            case VehicleModel.Abarth595:
                return '595';

            case VehicleModel.Abarth59550TH:
                return '595 50TH';

            case VehicleModel.Abarth595C:
                return '595 C';

            case VehicleModel.Abarth695:
                return '695';

            case VehicleModel.AbarthPunto:
                return 'Punto';

            case VehicleModel.AcuraCL:
                return 'CL';

            case VehicleModel.AcuraEL:
                return 'EL';

            case VehicleModel.AcuraIntegra:
                return 'Integra';

            case VehicleModel.AcuraMDX:
                return 'MDX';

            case VehicleModel.AcuraNSXT:
                return 'NSX-T';

            case VehicleModel.AcuraRDX:
                return 'RDX';

            case VehicleModel.AcuraRL:
                return 'RL';

            case VehicleModel.AcuraRSX:
                return 'RSX';

            case VehicleModel.AcuraTL:
                return 'TL';

            case VehicleModel.AcuraTSX:
                return 'TSX';

            case VehicleModel.AcuraZDX:
                return 'ZDX';

            case VehicleModel.AixamA400:
                return 'A400';

            case VehicleModel.AixamA500:
                return 'A500';

            case VehicleModel.AixamA721:
                return 'A721';

            case VehicleModel.AixamA741:
                return 'A741';

            case VehicleModel.AixamA751:
                return 'A751';

            case VehicleModel.AixamCITY:
                return 'CITY';

            case VehicleModel.AixamCoupe:
                return 'Coupé';

            case VehicleModel.AixamCROSSLINE:
                return 'CROSSLINE';

            case VehicleModel.AixamCROSSOVER:
                return 'CROSSOVER';

            case VehicleModel.AixameCity:
                return 'e-City';

            case VehicleModel.AixameCoupe:
                return 'e-Coupé';

            case VehicleModel.AixamGTO:
                return 'GTO';

            case VehicleModel.AixamMinauto:
                return 'Minauto';

            case VehicleModel.AixamROADLINE:
                return 'ROADLINE';

            case VehicleModel.AixamSCOUTYR:
                return 'SCOUTY R';

            case VehicleModel.AlfaRomeo145:
                return '145';

            case VehicleModel.AlfaRomeo146:
                return '146';

            case VehicleModel.AlfaRomeo147:
                return '147';

            case VehicleModel.AlfaRomeo155:
                return '155';

            case VehicleModel.AlfaRomeo156:
                return '156';

            case VehicleModel.AlfaRomeo159:
                return '159';

            case VehicleModel.AlfaRomeo164:
                return '164';

            case VehicleModel.AlfaRomeo166:
                return '166';

            case VehicleModel.AlfaRomeo33:
                return '33';

            case VehicleModel.AlfaRomeo4C:
                return '4C';

            case VehicleModel.AlfaRomeo75:
                return '75';

            case VehicleModel.AlfaRomeoBrera:
                return 'Brera';

            case VehicleModel.AlfaRomeoCrosswagonQ4:
                return 'Crosswagon Q4';

            case VehicleModel.AlfaRomeoGiulia:
                return 'Giulia';

            case VehicleModel.AlfaRomeoGiulietta:
                return 'Giulietta';

            case VehicleModel.AlfaRomeoGT:
                return 'GT';

            case VehicleModel.AlfaRomeoGTV:
                return 'GTV';

            case VehicleModel.AlfaRomeoMiTo:
                return 'MiTo';

            case VehicleModel.AlfaRomeoSpider:
                return 'Spider';

            case VehicleModel.AlfaRomeoSportwagonQ4:
                return 'Sportwagon Q4';

            case VehicleModel.AlfaRomeoStelvio:
                return 'Stelvio';

            case VehicleModel.AlpinaB3S:
                return 'B3 S';

            case VehicleModel.AlpinaB5:
                return 'B5';

            case VehicleModel.AlpinaB6:
                return 'B6';

            case VehicleModel.AlpinaB7:
                return 'B7';

            case VehicleModel.AlpinaD3:
                return 'D3';

            case VehicleModel.AlpinaD5:
                return 'D5';

            case VehicleModel.ARO4x4:
                return '4x4';

            case VehicleModel.AstonMartinDB7:
                return 'DB7';

            case VehicleModel.AstonMartinDB9:
                return 'DB9';

            case VehicleModel.AstonMartinDB9Volante:
                return 'DB9 Volante';

            case VehicleModel.AstonMartinDBS:
                return 'DBS';

            case VehicleModel.AstonMartinDBSVolante:
                return 'DBS Volante';

            case VehicleModel.AstonMartinRapide:
                return 'Rapide';

            case VehicleModel.AstonMartinV12:
                return 'V12';

            case VehicleModel.AstonMartinV12Vantage:
                return 'V12 Vantage';

            case VehicleModel.AstonMartinV8Vantage:
                return 'V8 Vantage';

            case VehicleModel.AstonMartinV8VantageRoadster:
                return 'V8 Vantage Roadster';

            case VehicleModel.AstonMartinValhalla:
                return 'Valhalla';

            case VehicleModel.AstonMartinValkyrie:
                return 'Valkyrie';

            case VehicleModel.AstonMartinVanquish:
                return 'Vanquish';

            case VehicleModel.Audi100:
                return '100';

            case VehicleModel.Audi200:
                return '200';

            case VehicleModel.Audi80:
                return '80';

            case VehicleModel.Audi90:
                return '90';

            case VehicleModel.AudiA1:
                return 'A1';

            case VehicleModel.AudiA2:
                return 'A2';

            case VehicleModel.AudiA3:
                return 'A3';

            case VehicleModel.AudiA4:
                return 'A4';

            case VehicleModel.AudiA4allroad:
                return 'A4 allroad';

            case VehicleModel.AudiA4Avant:
                return 'A4 Avant';

            case VehicleModel.AudiA5:
                return 'A5';

            case VehicleModel.AudiA6:
                return 'A6';

            case VehicleModel.AudiA6allroad:
                return 'A6 allroad';

            case VehicleModel.AudiA6Avant:
                return 'A6 Avant';

            case VehicleModel.AudiA7:
                return 'A7';

            case VehicleModel.AudiA8:
                return 'A8';

            case VehicleModel.Audietron:
                return 'e-tron';

            case VehicleModel.AudiQ2:
                return 'Q2';

            case VehicleModel.AudiQ3:
                return 'Q3';

            case VehicleModel.AudiQ5:
                return 'Q5';

            case VehicleModel.AudiQ7:
                return 'Q7';

            case VehicleModel.AudiQ8:
                return 'Q8';

            case VehicleModel.AudiR8:
                return 'R8';

            case VehicleModel.AudiRSQ8:
                return 'RS Q8';

            case VehicleModel.AudiRS2:
                return 'RS2';

            case VehicleModel.AudiRS3:
                return 'RS3';

            case VehicleModel.AudiRS4:
                return 'RS4';

            case VehicleModel.AudiRS4Avant:
                return 'RS4 Avant';

            case VehicleModel.AudiRS5:
                return 'RS5';

            case VehicleModel.AudiRS6:
                return 'RS6';

            case VehicleModel.AudiRS7:
                return 'RS7';

            case VehicleModel.AudiS3:
                return 'S3';

            case VehicleModel.AudiS4:
                return 'S4';

            case VehicleModel.AudiS5:
                return 'S5';

            case VehicleModel.AudiS6:
                return 'S6';

            case VehicleModel.AudiS7:
                return 'S7';

            case VehicleModel.AudiS8:
                return 'S8';

            case VehicleModel.AudiSQ5:
                return 'SQ5';

            case VehicleModel.AudiSQ7:
                return 'SQ7';

            case VehicleModel.AudiSQ8:
                return 'SQ8';

            case VehicleModel.AudiTT:
                return 'TT';

            case VehicleModel.AudiTTS:
                return 'TTS';

            case VehicleModel.AudiV8:
                return 'V8';

            case VehicleModel.AustinMaestro:
                return 'Maestro';

            case VehicleModel.AustinMetro:
                return 'Metro';

            case VehicleModel.AustinMini:
                return 'Mini';

            case VehicleModel.AustinMontego:
                return 'Montego';

            case VehicleModel.AustroFiatDolomitC3:
                return 'Dolomit C3';

            case VehicleModel.AustroDaimlerADR:
                return 'ADR';

            case VehicleModel.BellierDivane:
                return 'Divane';

            case VehicleModel.BellierOpale:
                return 'Opale';

            case VehicleModel.BellierVario:
                return 'Vario';

            case VehicleModel.BellierVeloto:
                return 'Veloto';

            case VehicleModel.BellierVX:
                return 'VX';

            case VehicleModel.BentleyArnage:
                return 'Arnage';

            case VehicleModel.BentleyAzureConvertible:
                return 'Azure Convertible';

            case VehicleModel.BentleyBentayga:
                return 'Bentayga';

            case VehicleModel.BentleyBrooklands:
                return 'Brooklands';

            case VehicleModel.BentleyContinentalFlyingSpur:
                return 'Continental Flying Spur';

            case VehicleModel.BentleyContinentalGT:
                return 'Continental GT';

            case VehicleModel.BentleyContinentalGTC:
                return 'Continental GTC';

            case VehicleModel.BentleyContinentalR:
                return 'Continental R';

            case VehicleModel.BentleyContinentalT:
                return 'Continental T';

            case VehicleModel.BentleyMulsanne:
                return 'Mulsanne';

            case VehicleModel.BentleyTurboR:
                return 'Turbo R';

            case VehicleModel.BitterDiplomatCD:
                return 'Diplomat CD';

            case VehicleModel.BMWAlpina:
                return 'Alpina';

            case VehicleModel.BMWi3:
                return 'i3';

            case VehicleModel.BMWi8:
                return 'i8';

            case VehicleModel.BMWM2:
                return 'M2';

            case VehicleModel.BMWM3:
                return 'M3';

            case VehicleModel.BMWM4:
                return 'M4';

            case VehicleModel.BMWM5:
                return 'M5';

            case VehicleModel.BMWM6:
                return 'M6';

            case VehicleModel.BMWM8:
                return 'M8';

            case VehicleModel.BMWRada1:
                return 'Řada 1';

            case VehicleModel.BMWRada2:
                return 'Řada 2';

            case VehicleModel.BMWRada3:
                return 'Řada 3';

            case VehicleModel.BMWRada4:
                return 'Řada 4';

            case VehicleModel.BMWRada5:
                return 'Řada 5';

            case VehicleModel.BMWRada6:
                return 'Řada 6';

            case VehicleModel.BMWRada7:
                return 'Řada 7';

            case VehicleModel.BMWRada8:
                return 'Řada 8';

            case VehicleModel.BMWX1:
                return 'X1';

            case VehicleModel.BMWX2:
                return 'X2';

            case VehicleModel.BMWX3:
                return 'X3';

            case VehicleModel.BMWX4:
                return 'X4';

            case VehicleModel.BMWX5:
                return 'X5';

            case VehicleModel.BMWX6:
                return 'X6';

            case VehicleModel.BMWX7:
                return 'X7';

            case VehicleModel.BMWZ1:
                return 'Z1';

            case VehicleModel.BMWZ3:
                return 'Z3';

            case VehicleModel.BMWZ4:
                return 'Z4';

            case VehicleModel.BMWZ8:
                return 'Z8';

            case VehicleModel.BMWXM:
                return 'XM';

            case VehicleModel.BugattiChiron:
                return 'Chiron';

            case VehicleModel.BugattiVeyron:
                return 'Veyron';

            case VehicleModel.BuickCentury:
                return 'Century';

            case VehicleModel.BuickElectra:
                return 'Electra';

            case VehicleModel.BuickEnclave:
                return 'Enclave';

            case VehicleModel.BuickLaCrosse:
                return 'LaCrosse';

            case VehicleModel.BuickLeSabre:
                return 'LeSabre';

            case VehicleModel.BuickParkAvenue:
                return 'Park Avenue';

            case VehicleModel.BuickRainer:
                return 'Rainer';

            case VehicleModel.BuickRainier:
                return 'Rainier';

            case VehicleModel.BuickRandezvous:
                return 'Randezvous';

            case VehicleModel.BuickRegal:
                return 'Regal';

            case VehicleModel.BuickRiviera:
                return 'Riviera';

            case VehicleModel.BuickSkylark:
                return 'Skylark';

            case VehicleModel.CadillacATS:
                return 'ATS';

            case VehicleModel.CadillacBLS:
                return 'BLS';

            case VehicleModel.CadillacCatera:
                return 'Catera';

            case VehicleModel.CadillacCT6:
                return 'CT6';

            case VehicleModel.CadillacCTS:
                return 'CTS';

            case VehicleModel.CadillacDeVille:
                return 'DeVille';

            case VehicleModel.CadillacEldorado:
                return 'Eldorado';

            case VehicleModel.CadillacEscalade:
                return 'Escalade';

            case VehicleModel.CadillacFleetwood:
                return 'Fleetwood';

            case VehicleModel.CadillacSeville:
                return 'Seville';

            case VehicleModel.CadillacSRX:
                return 'SRX';

            case VehicleModel.CadillacSTS:
                return 'STS';

            case VehicleModel.CadillacXLR:
                return 'XLR';

            case VehicleModel.CadillacXT4:
                return 'XT4';

            case VehicleModel.CadillacXT5:
                return 'XT5';

            case VehicleModel.CadillacXTS:
                return 'XTS';

            case VehicleModel.CasaliniSulky:
                return 'Sulky';

            case VehicleModel.CasaliniYdea:
                return 'Ydea';

            case VehicleModel.Caterham21:
                return '21';

            case VehicleModel.CaterhamSuperSeven:
                return 'Super Seven';

            case VehicleModel.CitroenAX:
                return 'AX';

            case VehicleModel.CitroenBerlingo:
                return 'Berlingo';

            case VehicleModel.CitroenBX:
                return 'BX';

            case VehicleModel.CitroenCCrosser:
                return 'C-Crosser';

            case VehicleModel.CitroenCElysee:
                return 'C-Elysée';

            case VehicleModel.CitroenCZero:
                return 'C-Zero';

            case VehicleModel.CitroenC1:
                return 'C1';

            case VehicleModel.CitroenC2:
                return 'C2';

            case VehicleModel.CitroenC3:
                return 'C3';

            case VehicleModel.CitroenC3Aircross:
                return 'C3 Aircross';

            case VehicleModel.CitroenC3Picasso:
                return 'C3 Picasso';

            case VehicleModel.CitroenC4:
                return 'C4';

            case VehicleModel.CitroenC4Aircross:
                return 'C4 Aircross';

            case VehicleModel.CitroenC4Cactus:
                return 'C4 Cactus';

            case VehicleModel.CitroenC4Picasso:
                return 'C4 Picasso';

            case VehicleModel.CitroenC4SpaceTourer:
                return 'C4 SpaceTourer';

            case VehicleModel.CitroenC5:
                return 'C5';

            case VehicleModel.CitroenC5Aircross:
                return 'C5 Aircross';

            case VehicleModel.CitroenC6:
                return 'C6';

            case VehicleModel.CitroenC8:
                return 'C8';

            case VehicleModel.CitroenCX:
                return 'CX';

            case VehicleModel.CitroenDS3:
                return 'DS3';

            case VehicleModel.CitroenDS3Cabrio:
                return 'DS3 Cabrio';

            case VehicleModel.CitroenDS4:
                return 'DS4';

            case VehicleModel.CitroenDS5:
                return 'DS5';

            case VehicleModel.CitroenEvasion:
                return 'Évasion';

            case VehicleModel.CitroenGrandC4Picasso:
                return 'Grand C4 Picasso';

            case VehicleModel.CitroenGrandC4SpaceTourer:
                return 'Grand C4 SpaceTourer';

            case VehicleModel.CitroenJumper:
                return 'Jumper';

            case VehicleModel.CitroenJumpy:
                return 'Jumpy';

            case VehicleModel.CitroenNemo:
                return 'Nemo';

            case VehicleModel.CitroenSaxo:
                return 'Saxo';

            case VehicleModel.CitroenSpaceTourer:
                return 'SpaceTourer';

            case VehicleModel.CitroenVisa:
                return 'Visa';

            case VehicleModel.CitroenXantia:
                return 'Xantia';

            case VehicleModel.CitroenXM:
                return 'XM';

            case VehicleModel.CitroenXsara:
                return 'Xsara';

            case VehicleModel.CitroenXsaraPicasso:
                return 'Xsara Picasso';

            case VehicleModel.CitroenZX:
                return 'ZX';

            case VehicleModel.CupraArona:
                return 'Arona';

            case VehicleModel.CupraAteca:
                return 'Ateca';

            case VehicleModel.CupraFormentor:
                return 'Formentor';

            case VehicleModel.CupraIbiza:
                return 'Ibiza';

            case VehicleModel.CupraLeon:
                return 'Leon';

            case VehicleModel.CupraTavascan:
                return 'Tavascan';

            case VehicleModel.Dacia1300:
                return '1300';

            case VehicleModel.Dacia1310:
                return '1310';

            case VehicleModel.Dacia1410:
                return '1410';

            case VehicleModel.DaciaDokker:
                return 'Dokker';

            case VehicleModel.DaciaDouble:
                return 'Double';

            case VehicleModel.DaciaDuster:
                return 'Duster';

            case VehicleModel.DaciaLodgy:
                return 'Lodgy';

            case VehicleModel.DaciaLogan:
                return 'Logan';

            case VehicleModel.DaciaPickUp:
                return 'Pick Up';

            case VehicleModel.DaciaSandero:
                return 'Sandero';

            case VehicleModel.DaciaSolenza:
                return 'Solenza';

            case VehicleModel.DAELIMXQ1125iABS:
                return 'XQ1 125i ABS';

            case VehicleModel.DAELIMXQ2250iABS:
                return 'XQ2 250i ABS';

            case VehicleModel.DaewooEspero:
                return 'Espero';

            case VehicleModel.DaewooEvanda:
                return 'Evanda';

            case VehicleModel.DaewooChairman:
                return 'Chairman';

            case VehicleModel.DaewooKalos:
                return 'Kalos';

            case VehicleModel.DaewooLanos:
                return 'Lanos';

            case VehicleModel.DaewooLeganza:
                return 'Leganza';

            case VehicleModel.DaewooMatiz:
                return 'Matiz';

            case VehicleModel.DaewooNexia:
                return 'Nexia';

            case VehicleModel.DaewooNubira:
                return 'Nubira';

            case VehicleModel.DaewooRacer:
                return 'Racer';

            case VehicleModel.DaewooTacuma:
                return 'Tacuma';

            case VehicleModel.DaewooTico:
                return 'Tico';

            case VehicleModel.DaihatsuApplause:
                return 'Applause';

            case VehicleModel.DaihatsuCopen:
                return 'Copen';

            case VehicleModel.DaihatsuCuore:
                return 'Cuore';

            case VehicleModel.DaihatsuFeroza:
                return 'Feroza';

            case VehicleModel.DaihatsuGrandMovie:
                return 'Grand Movie';

            case VehicleModel.DaihatsuCharade:
                return 'Charade';

            case VehicleModel.DaihatsuMateria:
                return 'Materia';

            case VehicleModel.DaihatsuMove:
                return 'Move';

            case VehicleModel.DaihatsuRocky:
                return 'Rocky';

            case VehicleModel.DaihatsuSirion:
                return 'Sirion';

            case VehicleModel.DaihatsuTerios:
                return 'Terios';

            case VehicleModel.DaihatsuTrevis:
                return 'Trevis';

            case VehicleModel.DodgeAvenger:
                return 'Avenger';

            case VehicleModel.DodgeCaliber:
                return 'Caliber';

            case VehicleModel.DodgeCaravan:
                return 'Caravan';

            case VehicleModel.DodgeDakota:
                return 'Dakota';

            case VehicleModel.DodgeDurango:
                return 'Durango';

            case VehicleModel.DodgeGrandCaravan:
                return 'Grand Caravan';

            case VehicleModel.DodgeChallenger:
                return 'Challenger';

            case VehicleModel.DodgeCharger:
                return 'Charger';

            case VehicleModel.DodgeIntrepid:
                return 'Intrepid';

            case VehicleModel.DodgeJourney:
                return 'Journey';

            case VehicleModel.DodgeMagnum:
                return 'Magnum';

            case VehicleModel.DodgeNeon:
                return 'Neon';

            case VehicleModel.DodgeNitro:
                return 'Nitro';

            case VehicleModel.DodgeRam:
                return 'Ram';

            case VehicleModel.DodgeRam1500:
                return 'Ram 1500';

            case VehicleModel.DodgeSpirit:
                return 'Spirit';

            case VehicleModel.DodgeSRT4:
                return 'SRT-4';

            case VehicleModel.DodgeStealth:
                return 'Stealth';

            case VehicleModel.DodgeStratus:
                return 'Stratus';

            case VehicleModel.DodgeSX:
                return 'SX';

            case VehicleModel.DodgeViper:
                return 'Viper';

            case VehicleModel.DRDR5:
                return 'DR5';

            case VehicleModel.DS3:
                return '3';

            case VehicleModel.DS3Crossback:
                return '3 Crossback';

            case VehicleModel.DS4:
                return '4';

            case VehicleModel.DS4Crossback:
                return '4 Crossback';

            case VehicleModel.DS5:
                return '5';

            case VehicleModel.DS7Crossback:
                return '7 Crossback';

            case VehicleModel.EaglePremier:
                return 'Premier';

            case VehicleModel.EagleSummit:
                return 'Summit';

            case VehicleModel.EagleTalon:
                return 'Talon';

            case VehicleModel.EagleVision:
                return 'Vision';

            case VehicleModel.EagleVista:
                return 'Vista';

            case VehicleModel.Ferrari246:
                return '246';

            case VehicleModel.Ferrari250:
                return '250';

            case VehicleModel.Ferrari288:
                return '288';

            case VehicleModel.Ferrari328:
                return '328';

            case VehicleModel.Ferrari330:
                return '330';

            case VehicleModel.Ferrari348:
                return '348';

            case VehicleModel.Ferrari360:
                return '360';

            case VehicleModel.Ferrari456:
                return '456';

            case VehicleModel.Ferrari458:
                return '458';

            case VehicleModel.Ferrari512:
                return '512';

            case VehicleModel.Ferrari550:
                return '550';

            case VehicleModel.Ferrari550Maranello:
                return '550 Maranello';

            case VehicleModel.Ferrari599:
                return '599';

            case VehicleModel.Ferrari612:
                return '612';

            case VehicleModel.Ferrari812:
                return '812';

            case VehicleModel.FerrariCalifornia:
                return 'California';

            case VehicleModel.FerrariEnzo:
                return 'Enzo';

            case VehicleModel.FerrariF355:
                return 'F 355';

            case VehicleModel.FerrariF430:
                return 'F 430';

            case VehicleModel.FerrariF12:
                return 'F12';

            case VehicleModel.FerrariF40:
                return 'F40';

            case VehicleModel.FerrariF50:
                return 'F50';

            case VehicleModel.FerrariF8:
                return 'F8';

            case VehicleModel.FerrariFF:
                return 'FF';

            case VehicleModel.FerrariGTC4Lusso:
                return 'GTC4 Lusso';

            case VehicleModel.FerrariLaFerrari:
                return 'LaFerrari';

            case VehicleModel.FerrariMondial:
                return 'Mondial';

            case VehicleModel.FerrariPortofino:
                return 'Portofino';

            case VehicleModel.FerrariSF90:
                return 'SF90';

            case VehicleModel.FerrariTestarossa:
                return 'Testarossa';

            case VehicleModel.Fiat126:
                return '126';

            case VehicleModel.Fiat127:
                return '127';

            case VehicleModel.Fiat128:
                return '128';

            case VehicleModel.Fiat131:
                return '131';

            case VehicleModel.Fiat500Abarth:
                return '500 Abarth';

            case VehicleModel.Fiat500E:
                return '500E';

            case VehicleModel.Fiat500L:
                return '500L';

            case VehicleModel.Fiat500X:
                return '500X';

            case VehicleModel.Fiat600:
                return '600';

            case VehicleModel.Fiat850:
                return '850';

            case VehicleModel.FiatBarchetta:
                return 'Barchetta';

            case VehicleModel.FiatBrava:
                return 'Brava';

            case VehicleModel.FiatBravo:
                return 'Bravo';

            case VehicleModel.FiatCinquecento:
                return 'Cinquecento';

            case VehicleModel.FiatCroma:
                return 'Croma';

            case VehicleModel.FiatDoblo:
                return 'Dobló';

            case VehicleModel.FiatDucato:
                return 'Ducato';

            case VehicleModel.FiatFiorino:
                return 'Fiorino';

            case VehicleModel.FiatFiorinoCombi:
                return 'Fiorino Combi';

            case VehicleModel.FiatFreemont:
                return 'Freemont';

            case VehicleModel.FiatFullback:
                return 'Fullback';

            case VehicleModel.FiatGrandePunto:
                return 'Grande Punto';

            case VehicleModel.FiatIdea:
                return 'Idea';

            case VehicleModel.FiatLinea:
                return 'Linea';

            case VehicleModel.FiatMarea:
                return 'Marea';

            case VehicleModel.FiatMultipla:
                return 'Multipla';

            case VehicleModel.FiatPalio:
                return 'Palio';

            case VehicleModel.FiatPanda:
                return 'Panda';

            case VehicleModel.FiatPuntoEVO:
                return 'Punto EVO';

            case VehicleModel.FiatQubo:
                return 'Qubo';

            case VehicleModel.FiatRegata:
                return 'Regata';

            case VehicleModel.FiatRitmo:
                return 'Ritmo';

            case VehicleModel.FiatScudo:
                return 'Scudo';

            case VehicleModel.FiatSedici:
                return 'Sedici';

            case VehicleModel.FiatSeicento:
                return 'Seicento';

            case VehicleModel.FiatSiena:
                return 'Siena';

            case VehicleModel.FiatStilo:
                return 'Stilo';

            case VehicleModel.FiatTalento:
                return 'Talento';

            case VehicleModel.FiatTempra:
                return 'Tempra';

            case VehicleModel.FiatTipo:
                return 'Tipo';

            case VehicleModel.FiatUlysse:
                return 'Ulysse';

            case VehicleModel.FiatUno:
                return 'Uno';

            case VehicleModel.FiatX19:
                return 'X 1/9';

            case VehicleModel.FiskerKarma:
                return 'Karma';

            case VehicleModel.FordBMAX:
                return 'B-MAX';

            case VehicleModel.FordCMAX:
                return 'C-MAX';

            case VehicleModel.FordCapri:
                return 'Capri';

            case VehicleModel.FordConnect:
                return 'Connect';

            case VehicleModel.FordContour:
                return 'Contour';

            case VehicleModel.FordCougar:
                return 'Cougar';

            case VehicleModel.FordCrownVictoria:
                return 'Crown Victoria';

            case VehicleModel.FordEcosport:
                return 'Ecosport';

            case VehicleModel.FordEdge:
                return 'Edge';

            case VehicleModel.FordEscape:
                return 'Escape';

            case VehicleModel.FordEscort:
                return 'Escort';

            case VehicleModel.FordExcursion:
                return 'Excursion';

            case VehicleModel.FordExpedition:
                return 'Expedition';

            case VehicleModel.FordExplorer:
                return 'Explorer';

            case VehicleModel.FordF150:
                return 'F-150';

            case VehicleModel.FordFiesta:
                return 'Fiesta';

            case VehicleModel.FordFlex:
                return 'Flex';

            case VehicleModel.FordFocus:
                return 'Focus';

            case VehicleModel.FordFreestar:
                return 'Freestar';

            case VehicleModel.FordFreestyle:
                return 'Freestyle';

            case VehicleModel.FordFusion:
                return 'Fusion';

            case VehicleModel.FordGalaxy:
                return 'Galaxy';

            case VehicleModel.FordGranada:
                return 'Granada';

            case VehicleModel.FordGrandCMAX:
                return 'Grand C-MAX';

            case VehicleModel.FordGrandMarquis:
                return 'Grand Marquis';

            case VehicleModel.FordKa:
                return 'Ka';

            case VehicleModel.FordKAplus:
                return 'KA+';

            case VehicleModel.FordKuga:
                return 'Kuga';

            case VehicleModel.FordMarauder:
                return 'Marauder';

            case VehicleModel.FordMaverick:
                return 'Maverick';

            case VehicleModel.FordMondeo:
                return 'Mondeo';

            case VehicleModel.FordMustang:
                return 'Mustang';

            case VehicleModel.FordOrion:
                return 'Orion';

            case VehicleModel.FordProbe:
                return 'Probe';

            case VehicleModel.FordPuma:
                return 'Puma';

            case VehicleModel.FordRanger:
                return 'Ranger';

            case VehicleModel.FordSMAX:
                return 'S-MAX';

            case VehicleModel.FordScorpio:
                return 'Scorpio';

            case VehicleModel.FordSierra:
                return 'Sierra';

            case VehicleModel.FordStreetka:
                return 'Streetka';

            case VehicleModel.FordTaunus:
                return 'Taunus';

            case VehicleModel.FordTaurus:
                return 'Taurus';

            case VehicleModel.FordThunderbird:
                return 'Thunderbird';

            case VehicleModel.FordTourneoConnect:
                return 'Tourneo Connect';

            case VehicleModel.FordTourneoCourier:
                return 'Tourneo Courier';

            case VehicleModel.FordTourneoCustom:
                return 'Tourneo Custom';

            case VehicleModel.FordTransit:
                return 'Transit';

            case VehicleModel.FordTransitConnect:
                return 'Transit Connect';

            case VehicleModel.FordTransitCustom:
                return 'Transit Custom';

            case VehicleModel.FordWindstar:
                return 'Windstar';

            case VehicleModel.GMCCanyon:
                return 'Canyon';

            case VehicleModel.GMCEnvoy:
                return 'Envoy';

            case VehicleModel.GMCJimmy:
                return 'Jimmy';

            case VehicleModel.GMCSafari:
                return 'Safari';

            case VehicleModel.GMCSavana:
                return 'Savana';

            case VehicleModel.GMCSonoma:
                return 'Sonoma';

            case VehicleModel.GMCSuburban:
                return 'Suburban';

            case VehicleModel.GMCYukon:
                return 'Yukon';

            case VehicleModel.GonowGS2:
                return 'GS2';

            case VehicleModel.GonowGX6:
                return 'GX6';

            case VehicleModel.GordonRoadster:
                return 'Roadster';

            case VehicleModel.GreatWallH5:
                return 'H5';

            case VehicleModel.GreatWallHoverH5:
                return 'Hover H5';

            case VehicleModel.GrecavEke:
                return 'Eke';

            case VehicleModel.GrecavSonique:
                return 'Sonique';

            case VehicleModel.HoldenFX:
                return 'FX';

            case VehicleModel.HondaAccord:
                return 'Accord';

            case VehicleModel.HondaCity:
                return 'City';

            case VehicleModel.HondaCivic:
                return 'Civic';

            case VehicleModel.HondaConcerto:
                return 'Concerto';

            case VehicleModel.HondaCRV:
                return 'CR-V';

            case VehicleModel.HondaCRZ:
                return 'CR-Z';

            case VehicleModel.HondaCRX:
                return 'CRX';

            case VehicleModel.Hondae:
                return 'e';

            case VehicleModel.HondaElement:
                return 'Element';

            case VehicleModel.HondaFRV:
                return 'FR-V';

            case VehicleModel.HondaHRV:
                return 'HR-V';

            case VehicleModel.HondaInsight:
                return 'Insight';

            case VehicleModel.HondaJazz:
                return 'Jazz';

            case VehicleModel.HondaLegend:
                return 'Legend';

            case VehicleModel.HondaNSX:
                return 'NSX';

            case VehicleModel.HondaOdyssey:
                return 'Odyssey';

            case VehicleModel.HondaPilot:
                return 'Pilot';

            case VehicleModel.HondaPrelude:
                return 'Prelude';

            case VehicleModel.HondaRidgeline:
                return 'Ridgeline';

            case VehicleModel.HondaS2000:
                return 'S2000';

            case VehicleModel.HondaShuttle:
                return 'Shuttle';

            case VehicleModel.HondaStream:
                return 'Stream';

            case VehicleModel.Honker42:
                return '42';

            case VehicleModel.HummerH1:
                return 'H1';

            case VehicleModel.HummerH1Alpha:
                return 'H1 Alpha';

            case VehicleModel.HummerH2:
                return 'H2';

            case VehicleModel.HummerH2SUT:
                return 'H2 SUT';

            case VehicleModel.HummerH3:
                return 'H3';

            case VehicleModel.HummerH3Alpha:
                return 'H3 Alpha';

            case VehicleModel.HummerH3T:
                return 'H3T';

            case VehicleModel.HummerH3x:
                return 'H3x';

            case VehicleModel.HurtanALBAYCIN:
                return 'ALBAYCIN';

            case VehicleModel.HyundaiAccent:
                return 'Accent';

            case VehicleModel.HyundaiAtos:
                return 'Atos';

            case VehicleModel.HyundaiElantra:
                return 'Elantra';

            case VehicleModel.HyundaiEquus:
                return 'Equus';

            case VehicleModel.HyundaiGalloper:
                return 'Galloper';

            case VehicleModel.HyundaiGenesis:
                return 'Genesis';

            case VehicleModel.HyundaiGetz:
                return 'Getz';

            case VehicleModel.HyundaiGrandeur:
                return 'Grandeur';

            case VehicleModel.Hyundaii10:
                return 'i10';

            case VehicleModel.Hyundaii20:
                return 'i20';

            case VehicleModel.Hyundaii30:
                return 'i30';

            case VehicleModel.Hyundaii40:
                return 'i40';

            case VehicleModel.HyundaiIONIQ:
                return 'IONIQ';

            case VehicleModel.Hyundaiix20:
                return 'ix20';

            case VehicleModel.Hyundaiix35:
                return 'ix35';

            case VehicleModel.Hyundaiix55:
                return 'ix55';

            case VehicleModel.HyundaiKona:
                return 'Kona';

            case VehicleModel.HyundaiLantra:
                return 'Lantra';

            case VehicleModel.HyundaiMatrix:
                return 'Matrix';

            case VehicleModel.HyundaiNexo:
                return 'Nexo';

            case VehicleModel.HyundaiPalisade:
                return 'Palisade';

            case VehicleModel.HyundaiPony:
                return 'Pony';

            case VehicleModel.HyundaiSantaFe:
                return 'Santa Fe';

            case VehicleModel.HyundaiSantamo:
                return 'Santamo';

            case VehicleModel.HyundaiScoupe:
                return 'Scoupé';

            case VehicleModel.HyundaiSonata:
                return 'Sonata';

            case VehicleModel.HyundaiTerracan:
                return 'Terracan';

            case VehicleModel.HyundaiTrajet:
                return 'Trajet';

            case VehicleModel.HyundaiTucson:
                return 'Tucson';

            case VehicleModel.HyundaiVeloster:
                return 'Veloster';

            case VehicleModel.HyundaiXG:
                return 'XG';

            case VehicleModel.ChangheCoolcar:
                return 'Coolcar';

            case VehicleModel.ChangheFreedom:
                return 'Freedom';

            case VehicleModel.ChatenetBarooder:
                return 'Barooder';

            case VehicleModel.ChatenetCH26:
                return 'CH26';

            case VehicleModel.ChatenetMedia:
                return 'Media';

            case VehicleModel.ChatenetSpeedino:
                return 'Speedino';

            case VehicleModel.ChatenetStella:
                return 'Stella';

            case VehicleModel.ChevroletAlero:
                return 'Alero';

            case VehicleModel.ChevroletAstro:
                return 'Astro';

            case VehicleModel.ChevroletAstroVan:
                return 'Astro Van';

            case VehicleModel.ChevroletAvalanche:
                return 'Avalanche';

            case VehicleModel.ChevroletAveo:
                return 'Aveo';

            case VehicleModel.ChevroletBeretta:
                return 'Beretta';

            case VehicleModel.ChevroletBlazer:
                return 'Blazer';

            case VehicleModel.ChevroletCamaro:
                return 'Camaro';

            case VehicleModel.ChevroletCaprice:
                return 'Caprice';

            case VehicleModel.ChevroletCaptiva:
                return 'Captiva';

            case VehicleModel.ChevroletCavalier:
                return 'Cavalier';

            case VehicleModel.ChevroletCobalt:
                return 'Cobalt';

            case VehicleModel.ChevroletColorado:
                return 'Colorado';

            case VehicleModel.ChevroletCorvette:
                return 'Corvette';

            case VehicleModel.ChevroletCruze:
                return 'Cruze';

            case VehicleModel.ChevroletElCamino:
                return 'El Camino';

            case VehicleModel.ChevroletEpica:
                return 'Epica';

            case VehicleModel.ChevroletEquinox:
                return 'Equinox';

            case VehicleModel.ChevroletHHR:
                return 'HHR';

            case VehicleModel.ChevroletImpala:
                return 'Impala';

            case VehicleModel.ChevroletLacetti:
                return 'Lacetti';

            case VehicleModel.ChevroletLumina:
                return 'Lumina';

            case VehicleModel.ChevroletMalibu:
                return 'Malibu';

            case VehicleModel.ChevroletMonteCarlo:
                return 'Monte Carlo';

            case VehicleModel.ChevroletNiva:
                return 'Niva';

            case VehicleModel.ChevroletOptra:
                return 'Optra';

            case VehicleModel.ChevroletOrlando:
                return 'Orlando';

            case VehicleModel.ChevroletPrizm:
                return 'Prizm';

            case VehicleModel.ChevroletRezzo:
                return 'Rezzo';

            case VehicleModel.ChevroletS10:
                return 'S10';

            case VehicleModel.ChevroletSilverado:
                return 'Silverado';

            case VehicleModel.ChevroletSpark:
                return 'Spark';

            case VehicleModel.ChevroletTahoe:
                return 'Tahoe';

            case VehicleModel.ChevroletTracker:
                return 'Tracker';

            case VehicleModel.ChevroletTrail:
                return 'Trail';

            case VehicleModel.ChevroletTrailblazer:
                return 'Trailblazer';

            case VehicleModel.ChevroletTransSport:
                return 'Trans Sport';

            case VehicleModel.ChevroletTrax:
                return 'Trax';

            case VehicleModel.ChevroletVenture:
                return 'Venture';

            case VehicleModel.Chrysler300C:
                return '300C';

            case VehicleModel.Chrysler300M:
                return '300M';

            case VehicleModel.ChryslerCirrus:
                return 'Cirrus';

            case VehicleModel.ChryslerConcorde:
                return 'Concorde';

            case VehicleModel.ChryslerCrossfire:
                return 'Crossfire';

            case VehicleModel.ChryslerES:
                return 'ES';

            case VehicleModel.ChryslerGrandVoyager:
                return 'Grand Voyager';

            case VehicleModel.ChryslerGTS:
                return 'GTS';

            case VehicleModel.ChryslerLeBaronCabrio:
                return 'Le Baron Cabrio';

            case VehicleModel.ChryslerLHS:
                return 'LHS';

            case VehicleModel.ChryslerNewYorker:
                return 'New Yorker';

            case VehicleModel.ChryslerPacifica:
                return 'Pacifica';

            case VehicleModel.ChryslerPTCruiser:
                return 'PT Cruiser';

            case VehicleModel.ChryslerSaratoga:
                return 'Saratoga';

            case VehicleModel.ChryslerSebring:
                return 'Sebring';

            case VehicleModel.ChryslerTownCountry:
                return 'Town & Country';

            case VehicleModel.ChryslerVoyager:
                return 'Voyager';

            case VehicleModel.InfinitiEX30:
                return 'EX30';

            case VehicleModel.InfinitiEX35:
                return 'EX35';

            case VehicleModel.InfinitiEX37:
                return 'EX37';

            case VehicleModel.InfinitiFX30:
                return 'FX30';

            case VehicleModel.InfinitiFX35:
                return 'FX35';

            case VehicleModel.InfinitiFX37:
                return 'FX37';

            case VehicleModel.InfinitiFX45:
                return 'FX45';

            case VehicleModel.InfinitiFX50:
                return 'FX50';

            case VehicleModel.InfinitiG:
                return 'G';

            case VehicleModel.InfinitiG20:
                return 'G20';

            case VehicleModel.InfinitiG35:
                return 'G35';

            case VehicleModel.InfinitiG37:
                return 'G37';

            case VehicleModel.InfinitiI30:
                return 'I30';

            case VehicleModel.InfinitiI35:
                return 'I35';

            case VehicleModel.InfinitiM30:
                return 'M30';

            case VehicleModel.InfinitiM35:
                return 'M35';

            case VehicleModel.InfinitiM37:
                return 'M37';

            case VehicleModel.InfinitiM45:
                return 'M45';

            case VehicleModel.InfinitiQ30:
                return 'Q30';

            case VehicleModel.InfinitiQ45:
                return 'Q45';

            case VehicleModel.InfinitiQ50:
                return 'Q50';

            case VehicleModel.InfinitiQ60:
                return 'Q60';

            case VehicleModel.InfinitiQ70:
                return 'Q70';

            case VehicleModel.InfinitiQX30:
                return 'QX30';

            case VehicleModel.InfinitiQX4:
                return 'QX4';

            case VehicleModel.InfinitiQX50:
                return 'QX50';

            case VehicleModel.InfinitiQX56:
                return 'QX56';

            case VehicleModel.InfinitiQX70:
                return 'QX70';

            case VehicleModel.InfinitiQX80:
                return 'QX80';

            case VehicleModel.IsuzuDMax:
                return 'D-Max';

            case VehicleModel.IsuzuGemini:
                return 'Gemini';

            case VehicleModel.IsuzuPiazza:
                return 'Piazza';

            case VehicleModel.IsuzuTrooper:
                return 'Trooper';

            case VehicleModel.ItalcarT3:
                return 'T3';

            case VehicleModel.IvecoMassif:
                return 'Massif';

            case VehicleModel.JACIEV7S:
                return 'IEV7S';

            case VehicleModel.JaguarDaimler:
                return 'Daimler';

            case VehicleModel.JaguarEPace:
                return 'E-Pace';

            case VehicleModel.JaguarFPace:
                return 'F-Pace';

            case VehicleModel.JaguarFType:
                return 'F-Type';

            case VehicleModel.JaguarIPace:
                return 'I-Pace';

            case VehicleModel.JaguarSType:
                return 'S-Type';

            case VehicleModel.JaguarXType:
                return 'X-Type';

            case VehicleModel.JaguarXE:
                return 'XE';

            case VehicleModel.JaguarXF:
                return 'XF';

            case VehicleModel.JaguarXJ:
                return 'XJ';

            case VehicleModel.JaguarXJ6:
                return 'XJ6';

            case VehicleModel.JaguarXJ8:
                return 'XJ8';

            case VehicleModel.JaguarXJR:
                return 'XJR';

            case VehicleModel.JaguarXJS:
                return 'XJS';

            case VehicleModel.JaguarXK:
                return 'XK';

            case VehicleModel.JaguarXK8:
                return 'XK8';

            case VehicleModel.JaguarXKR:
                return 'XKR';

            case VehicleModel.JDMAbaca:
                return 'Abaca';

            case VehicleModel.JDMAlbizia:
                return 'Albizia';

            case VehicleModel.JDMAloes:
                return 'Aloes';

            case VehicleModel.JDMOrane:
                return 'Orane';

            case VehicleModel.JDMTitane:
                return 'Titane';

            case VehicleModel.JeepCommander:
                return 'Commander';

            case VehicleModel.JeepCompass:
                return 'Compass';

            case VehicleModel.JeepGladiator:
                return 'Gladiator';

            case VehicleModel.JeepGrandCherokee:
                return 'Grand Cherokee';

            case VehicleModel.JeepCherokee:
                return 'Cherokee';

            case VehicleModel.JeepLiberty:
                return 'Liberty';

            case VehicleModel.JeepPatriot:
                return 'Patriot';

            case VehicleModel.JeepRenegade:
                return 'Renegade';

            case VehicleModel.JeepScrambler:
                return 'Scrambler';

            case VehicleModel.JeepWrangler:
                return 'Wrangler';

            case VehicleModel.JeepWranglerRubicon:
                return 'Wrangler Rubicon';

            case VehicleModel.JeepWranglerSahara:
                return 'Wrangler Sahara';

            case VehicleModel.Kaipan47:
                return '47';

            case VehicleModel.KiaCarens:
                return 'Carens';

            case VehicleModel.KiaCarnival:
                return 'Carnival';

            case VehicleModel.KiaCeed:
                return 'Cee´d';

            case VehicleModel.KiaCerato:
                return 'Cerato';

            case VehicleModel.KiaClarus:
                return 'Clarus';

            case VehicleModel.KiaeNiro:
                return 'e-Niro';

            case VehicleModel.KiaeSoul:
                return 'e-Soul';

            case VehicleModel.KiaJoice:
                return 'Joice';

            case VehicleModel.KiaMagentis:
                return 'Magentis';

            case VehicleModel.KiaNiro:
                return 'Niro';

            case VehicleModel.KiaOpirus:
                return 'Opirus';

            case VehicleModel.KiaOptima:
                return 'Optima';

            case VehicleModel.KiaPicanto:
                return 'Picanto';

            case VehicleModel.KiaPride:
                return 'Pride';

            case VehicleModel.KiaPro_ceed:
                return 'Pro_cee´d';

            case VehicleModel.KiaProCeed:
                return 'ProCeed';

            case VehicleModel.KiaRetona:
                return 'Retona';

            case VehicleModel.KiaRio:
                return 'Rio';

            case VehicleModel.KiaSephia:
                return 'Sephia';

            case VehicleModel.KiaShuma:
                return 'Shuma';

            case VehicleModel.KiaSorento:
                return 'Sorento';

            case VehicleModel.KiaSoul:
                return 'Soul';

            case VehicleModel.KiaSportage:
                return 'Sportage';

            case VehicleModel.KiaStinger:
                return 'Stinger';

            case VehicleModel.KiaStonic:
                return 'Stonic';

            case VehicleModel.KiaTelluride:
                return 'Telluride';

            case VehicleModel.KiaVenga:
                return 'Venga';

            case VehicleModel.KiaXCEED:
                return 'XCEED';

            case VehicleModel.KoenigseggJesko:
                return 'Jesko';

            case VehicleModel.KoenigseggRegera:
                return 'Regera';

            case VehicleModel.Lada1200:
                return '1200';

            case VehicleModel.Lada1500:
                return '1500';

            case VehicleModel.Lada2101:
                return '2101';

            case VehicleModel.Lada2102:
                return '2102';

            case VehicleModel.Lada2103:
                return '2103';

            case VehicleModel.Lada2104:
                return '2104';

            case VehicleModel.Lada21044:
                return '21044';

            case VehicleModel.Lada2105:
                return '2105';

            case VehicleModel.Lada2106:
                return '2106';

            case VehicleModel.Lada2107:
                return '2107';

            case VehicleModel.Lada2110:
                return '2110';

            case VehicleModel.Lada2111:
                return '2111';

            case VehicleModel.Lada2112:
                return '2112';

            case VehicleModel.LadaGranta:
                return 'Granta';

            case VehicleModel.LadaKalina:
                return 'Kalina';

            case VehicleModel.LadaLargus:
                return 'Largus';

            case VehicleModel.LadaNova:
                return 'Nova';

            case VehicleModel.LadaSamara:
                return 'Samara';

            case VehicleModel.LadaVesta:
                return 'Vesta';

            case VehicleModel.LadaXray:
                return 'Xray';

            case VehicleModel.LamborghiniAventador:
                return 'Aventador';

            case VehicleModel.LamborghiniCountach:
                return 'Countach';

            case VehicleModel.LamborghiniGallardo:
                return 'Gallardo';

            case VehicleModel.LamborghiniHuracan:
                return 'Huracán';

            case VehicleModel.LamborghiniMiura:
                return 'Miura';

            case VehicleModel.LamborghiniMurcielago:
                return 'Murciélago';

            case VehicleModel.LamborghiniUrus:
                return 'Urus';

            case VehicleModel.LanciaDedra:
                return 'Dedra';

            case VehicleModel.LanciaDelta:
                return 'Delta';

            case VehicleModel.LanciaFlavia:
                return 'Flavia';

            case VehicleModel.LanciaKappa:
                return 'Kappa';

            case VehicleModel.LanciaLybra:
                return 'Lybra';

            case VehicleModel.LanciaMusa:
                return 'Musa';

            case VehicleModel.LanciaPhedra:
                return 'Phedra';

            case VehicleModel.LanciaPrisma:
                return 'Prisma';

            case VehicleModel.LanciaThema:
                return 'Thema';

            case VehicleModel.LanciaThesis:
                return 'Thesis';

            case VehicleModel.LanciaY:
                return 'Y';

            case VehicleModel.LanciaZ:
                return 'Z';

            case VehicleModel.LandRoverDefender:
                return 'Defender';

            case VehicleModel.LandRoverDiscovery:
                return 'Discovery';

            case VehicleModel.LandRoverDiscoverySport:
                return 'Discovery Sport';

            case VehicleModel.LandRoverFreelander:
                return 'Freelander';

            case VehicleModel.LandRoverRangeRover:
                return 'Range Rover';

            case VehicleModel.LandRoverRangeRoverEvoque:
                return 'Range Rover Evoque';

            case VehicleModel.LandRoverRangeRoverSport:
                return 'Range Rover Sport';

            case VehicleModel.LandRoverRangeRoverVelar:
                return 'Range Rover Velar';

            case VehicleModel.LandRoverSeriesIII:
                return 'Series III';

            case VehicleModel.Lexus300:
                return '300';

            case VehicleModel.LexusCT200h:
                return 'CT 200h';

            case VehicleModel.LexusES300h:
                return 'ES 300h';

            case VehicleModel.LexusES330:
                return 'ES 330';

            case VehicleModel.LexusGS:
                return 'GS';

            case VehicleModel.LexusGS250:
                return 'GS 250';

            case VehicleModel.LexusGS300:
                return 'GS 300';

            case VehicleModel.LexusGS300h:
                return 'GS 300h';

            case VehicleModel.LexusGS430:
                return 'GS 430';

            case VehicleModel.LexusGS450:
                return 'GS 450';

            case VehicleModel.LexusGS450h:
                return 'GS 450h';

            case VehicleModel.LexusGS460:
                return 'GS 460';

            case VehicleModel.LexusGSF:
                return 'GS F';

            case VehicleModel.LexusGX460:
                return 'GX 460';

            case VehicleModel.LexusGX470:
                return 'GX 470';

            case VehicleModel.LexusIS:
                return 'IS';

            case VehicleModel.LexusIS200:
                return 'IS 200';

            case VehicleModel.LexusIS200t:
                return 'IS 200t';

            case VehicleModel.LexusIS220d:
                return 'IS 220d';

            case VehicleModel.LexusIS250:
                return 'IS 250';

            case VehicleModel.LexusIS250C:
                return 'IS 250C';

            case VehicleModel.LexusIS300:
                return 'IS 300';

            case VehicleModel.LexusIS300h:
                return 'IS 300h';

            case VehicleModel.LexusIS350:
                return 'IS 350';

            case VehicleModel.LexusISF:
                return 'IS F';

            case VehicleModel.LexusLC500:
                return 'LC 500';

            case VehicleModel.LexusLC500h:
                return 'LC 500h';

            case VehicleModel.LexusLS:
                return 'LS';

            case VehicleModel.LexusLS400:
                return 'LS 400';

            case VehicleModel.LexusLS430:
                return 'LS 430';

            case VehicleModel.LexusLS460:
                return 'LS 460';

            case VehicleModel.LexusLS500:
                return 'LS 500';

            case VehicleModel.LexusLS500h:
                return 'LS 500h';

            case VehicleModel.LexusLS600h:
                return 'LS 600h';

            case VehicleModel.LexusLS600hL:
                return 'LS 600h L';

            case VehicleModel.LexusLX460:
                return 'LX 460';

            case VehicleModel.LexusLX470:
                return 'LX 470';

            case VehicleModel.LexusLX570:
                return 'LX 570';

            case VehicleModel.LexusNX200t:
                return 'NX 200t';

            case VehicleModel.LexusNX300h:
                return 'NX 300h';

            case VehicleModel.LexusRC:
                return 'RC';

            case VehicleModel.LexusRC200t:
                return 'RC 200t';

            case VehicleModel.LexusRC300h:
                return 'RC 300h';

            case VehicleModel.LexusRCF:
                return 'RC F';

            case VehicleModel.LexusRX:
                return 'RX';

            case VehicleModel.LexusRX200t:
                return 'RX 200t';

            case VehicleModel.LexusRX300:
                return 'RX 300';

            case VehicleModel.LexusRX330:
                return 'RX 330';

            case VehicleModel.LexusRX350:
                return 'RX 350';

            case VehicleModel.LexusRX400:
                return 'RX 400';

            case VehicleModel.LexusRX400h:
                return 'RX 400h';

            case VehicleModel.LexusRX450h:
                return 'RX 450h';

            case VehicleModel.LexusRX450hL:
                return 'RX 450h L';

            case VehicleModel.LexusSC:
                return 'SC';

            case VehicleModel.LexusSC400:
                return 'SC 400';

            case VehicleModel.LexusSC430:
                return 'SC 430';

            case VehicleModel.LexusUX200:
                return 'UX 200';

            case VehicleModel.LexusUX250h:
                return 'UX 250h';

            case VehicleModel.LigierAmbra:
                return 'Ambra';

            case VehicleModel.LigierJS50:
                return 'JS 50';

            case VehicleModel.LigierJS50L:
                return 'JS 50 L';

            case VehicleModel.LigierJS60:
                return 'JS 60';

            case VehicleModel.LigierJSRC:
                return 'JS RC';

            case VehicleModel.LigierXTooMax:
                return 'X Too Max';

            case VehicleModel.LincolnAviator:
                return 'Aviator';

            case VehicleModel.LincolnContinental:
                return 'Continental';

            case VehicleModel.LincolnMKS:
                return 'MKS';

            case VehicleModel.LincolnMKT:
                return 'MKT';

            case VehicleModel.LincolnMKX:
                return 'MKX';

            case VehicleModel.LincolnMKZ:
                return 'MKZ';

            case VehicleModel.LincolnNavigator:
                return 'Navigator';

            case VehicleModel.LincolnTownCar:
                return 'Town Car';

            case VehicleModel.LotusElise:
                return 'Elise';

            case VehicleModel.LotusEsprit:
                return 'Esprit';

            case VehicleModel.MarcosLM:
                return 'LM';

            case VehicleModel.MarcosMantara:
                return 'Mantara';

            case VehicleModel.MarcosMantaray:
                return 'Mantaray';

            case VehicleModel.MarcosMantis:
                return 'Mantis';

            case VehicleModel.MartinMotors520:
                return '520';

            case VehicleModel.MartinMotors520i:
                return '520i';

            case VehicleModel.MartinMotorsCEO:
                return 'CEO';

            case VehicleModel.MartinMotorsCOOLCAR:
                return 'COOLCAR';

            case VehicleModel.MartinMotorsFREEDOM:
                return 'FREEDOM';

            case VehicleModel.MarutiMaruti800:
                return 'Maruti 800';

            case VehicleModel.Maserati3200:
                return '3200';

            case VehicleModel.MaseratiGhibli:
                return 'Ghibli';

            case VehicleModel.MaseratiGranCabrio:
                return 'GranCabrio';

            case VehicleModel.MaseratiGranTurismo:
                return 'GranTurismo';

            case VehicleModel.MaseratiLevante:
                return 'Levante';

            case VehicleModel.MaseratiQuattroporte:
                return 'Quattroporte';

            case VehicleModel.Mazda121:
                return '121';

            case VehicleModel.Mazda2:
                return '2';

            case VehicleModel.Mazda323:
                return '323';

            case VehicleModel.Mazda6:
                return '6';

            case VehicleModel.Mazda626:
                return '626';

            case VehicleModel.Mazda929:
                return '929';

            case VehicleModel.MazdaBT:
                return 'BT';

            case VehicleModel.MazdaBT50:
                return 'BT-50';

            case VehicleModel.MazdaCX3:
                return 'CX-3';

            case VehicleModel.MazdaCX30:
                return 'CX-30';

            case VehicleModel.MazdaCX5:
                return 'CX-5';

            case VehicleModel.MazdaCX7:
                return 'CX-7';

            case VehicleModel.MazdaCX9:
                return 'CX-9';

            case VehicleModel.MazdaDemio:
                return 'Demio';

            case VehicleModel.MazdaMPV:
                return 'MPV';

            case VehicleModel.MazdaMX:
                return 'MX';

            case VehicleModel.MazdaMX3:
                return 'MX-3';

            case VehicleModel.MazdaMX30:
                return 'MX-30';

            case VehicleModel.MazdaMX5:
                return 'MX-5';

            case VehicleModel.MazdaMX6:
                return 'MX-6';

            case VehicleModel.MazdaPremacy:
                return 'Premacy';

            case VehicleModel.MazdaRX7:
                return 'RX-7';

            case VehicleModel.MazdaRX8:
                return 'RX-8';

            case VehicleModel.MazdaTribute:
                return 'Tribute';

            case VehicleModel.MazdaXedos:
                return 'Xedos';

            case VehicleModel.MCCSmart:
                return 'Smart';

            case VehicleModel.McLaren12C:
                return '12C';

            case VehicleModel.McLaren540:
                return '540';

            case VehicleModel.McLaren570:
                return '570';

            case VehicleModel.McLaren600LT:
                return '600LT';

            case VehicleModel.McLaren650SCoupe:
                return '650S Coupé';

            case VehicleModel.McLaren650SSpider:
                return '650S Spider';

            case VehicleModel.McLaren675LT:
                return '675LT';

            case VehicleModel.McLaren720S:
                return '720S';

            case VehicleModel.McLarenElva:
                return 'Elva';

            case VehicleModel.McLarenF1:
                return 'F1';

            case VehicleModel.McLarenMP412C:
                return 'MP4-12C';

            case VehicleModel.McLarenP1:
                return 'P1';

            case VehicleModel.McLarenSenna:
                return 'Senna';

            case VehicleModel.MercedesBenz115:
                return '115';

            case VehicleModel.MercedesBenz116:
                return '116';

            case VehicleModel.MercedesBenz123:
                return '123';

            case VehicleModel.MercedesBenz124:
                return '124';

            case VehicleModel.MercedesBenz140:
                return '140';

            case VehicleModel.MercedesBenz170:
                return '170';

            case VehicleModel.MercedesBenz190:
                return '190';

            case VehicleModel.MercedesBenz20:
                return '20';

            case VehicleModel.MercedesBenz208:
                return '208';

            case VehicleModel.MercedesBenz209:
                return '209';

            case VehicleModel.MercedesBenz210:
                return '210';

            case VehicleModel.MercedesBenz211:
                return '211';

            case VehicleModel.MercedesBenz215:
                return '215';

            case VehicleModel.MercedesBenz220:
                return '220';

            case VehicleModel.MercedesBenzAMGGT:
                return 'AMG GT';

            case VehicleModel.MercedesBenzAMGOne:
                return 'AMG-One';

            case VehicleModel.MercedesBenzCitan:
                return 'Citan';

            case VehicleModel.MercedesBenzCLA:
                return 'CLA';

            case VehicleModel.MercedesBenzCLC:
                return 'CLC';

            case VehicleModel.MercedesBenzCLK:
                return 'CLK';

            case VehicleModel.MercedesBenzCLS:
                return 'CLS';

            case VehicleModel.MercedesBenzEQC:
                return 'EQC';

            case VehicleModel.MercedesBenzEQV:
                return 'EQV';

            case VehicleModel.MercedesBenzGL:
                return 'GL';

            case VehicleModel.MercedesBenzGL320:
                return 'GL 320';

            case VehicleModel.MercedesBenzGL350:
                return 'GL 350';

            case VehicleModel.MercedesBenzGLA:
                return 'GLA';

            case VehicleModel.MercedesBenzGLB:
                return 'GLB';

            case VehicleModel.MercedesBenzGLC:
                return 'GLC';

            case VehicleModel.MercedesBenzGLE:
                return 'GLE';

            case VehicleModel.MercedesBenzGLK:
                return 'GLK';

            case VehicleModel.MercedesBenzGLS:
                return 'GLS';

            case VehicleModel.MercedesBenzMaybach:
                return 'Maybach';

            case VehicleModel.MercedesBenzSL:
                return 'SL';

            case VehicleModel.MercedesBenzSLC:
                return 'SLC';

            case VehicleModel.MercedesBenzSLK:
                return 'SLK';

            case VehicleModel.MercedesBenzSLR:
                return 'SLR';

            case VehicleModel.MercedesBenzSLSAMG:
                return 'SLS AMG';

            case VehicleModel.MercedesBenzSprinter:
                return 'Sprinter';

            case VehicleModel.MercedesBenzTridyA:
                return 'Třídy A';

            case VehicleModel.MercedesBenzTridyB:
                return 'Třídy B';

            case VehicleModel.MercedesBenzTridyC:
                return 'Třídy C';

            case VehicleModel.MercedesBenzTridyE:
                return 'Třídy E';

            case VehicleModel.MercedesBenzTridyG:
                return 'Třídy G';

            case VehicleModel.MercedesBenzTridyM:
                return 'Třídy M';

            case VehicleModel.MercedesBenzTridyR:
                return 'Třídy R';

            case VehicleModel.MercedesBenzTridyS:
                return 'Třídy S';

            case VehicleModel.MercedesBenzTridyV:
                return 'Třídy V';

            case VehicleModel.MercedesBenzTridyX:
                return 'Třídy X';

            case VehicleModel.MercedesBenzVaneo:
                return 'Vaneo';

            case VehicleModel.MercedesBenzViano:
                return 'Viano';

            case VehicleModel.MercedesBenzVito:
                return 'Vito';

            case VehicleModel.MercuryMariner:
                return 'Mariner';

            case VehicleModel.MercuryMoutaineer:
                return 'Moutaineer';

            case VehicleModel.MercuryMystique:
                return 'Mystique';

            case VehicleModel.MercurySable:
                return 'Sable';

            case VehicleModel.MercuryVillager:
                return 'Villager';

            case VehicleModel.MGF:
                return 'F';

            case VehicleModel.MGTF:
                return 'TF';

            case VehicleModel.MGZR:
                return 'ZR';

            case VehicleModel.MGZS:
                return 'ZS';

            case VehicleModel.MGZT:
                return 'ZT';

            case VehicleModel.MicrocarDUE:
                return 'DUE';

            case VehicleModel.MicrocarLyra:
                return 'Lyra';

            case VehicleModel.MicrocarMGO:
                return 'M:GO';

            case VehicleModel.MicrocarMC1:
                return 'MC1';

            case VehicleModel.MicrocarMC2:
                return 'MC2';

            case VehicleModel.MicrocarVirgo:
                return 'Virgo';

            case VehicleModel.MiniClubman:
                return 'Clubman';

            case VehicleModel.MiniCooper:
                return 'Cooper';

            case VehicleModel.MiniCooperS:
                return 'Cooper S';

            case VehicleModel.MiniCountryman:
                return 'Countryman';

            case VehicleModel.MiniNewMini:
                return 'New Mini';

            case VehicleModel.MiniOne:
                return 'One';

            case VehicleModel.MiniPaceman:
                return 'Paceman';

            case VehicleModel.Mitsubishi3000GT:
                return '3000 GT';

            case VehicleModel.MitsubishiASX:
                return 'ASX';

            case VehicleModel.MitsubishiCarisma:
                return 'Carisma';

            case VehicleModel.MitsubishiColt:
                return 'Colt';

            case VehicleModel.MitsubishiEclipse:
                return 'Eclipse';

            case VehicleModel.MitsubishiEclipseCross:
                return 'Eclipse Cross';

            case VehicleModel.MitsubishiGalant:
                return 'Galant';

            case VehicleModel.MitsubishiGrandis:
                return 'Grandis';

            case VehicleModel.MitsubishiiMiEV:
                return 'i-MiEV';

            case VehicleModel.MitsubishiL200:
                return 'L200';

            case VehicleModel.MitsubishiLancer:
                return 'Lancer';

            case VehicleModel.MitsubishiOutlander:
                return 'Outlander';

            case VehicleModel.MitsubishiPajero:
                return 'Pajero';

            case VehicleModel.MitsubishiPajeroPinin:
                return 'Pajero Pinin';

            case VehicleModel.MitsubishiPajeroSport:
                return 'Pajero Sport';

            case VehicleModel.MitsubishiSigma:
                return 'Sigma';

            case VehicleModel.MitsubishiSpace:
                return 'Space';

            case VehicleModel.MitsubishiSpaceStar:
                return 'Space Star';

            case VehicleModel.MitsubishiSpaceWagon:
                return 'SpaceWagon';

            case VehicleModel.Moskvic2140:
                return '2140';

            case VehicleModel.Moskvic408:
                return '408';

            case VehicleModel.Moskvic412:
                return '412';

            case VehicleModel.MoskvicAleko:
                return 'Aleko';

            case VehicleModel.MTXCabrio:
                return 'Cabrio';

            case VehicleModel.Nissan100NX:
                return '100 NX';

            case VehicleModel.Nissan200SX:
                return '200 SX';

            case VehicleModel.Nissan240SX:
                return '240 SX';

            case VehicleModel.Nissan280ZX:
                return '280 ZX';

            case VehicleModel.Nissan300ZX:
                return '300 ZX';

            case VehicleModel.Nissan350Z:
                return '350 Z';

            case VehicleModel.Nissan370Z:
                return '370 Z';

            case VehicleModel.NissanAlmera:
                return 'Almera';

            case VehicleModel.NissanAlmeraTino:
                return 'Almera Tino';

            case VehicleModel.NissanAltima:
                return 'Altima';

            case VehicleModel.NissanArmada:
                return 'Armada';

            case VehicleModel.NissanBluebird:
                return 'Bluebird';

            case VehicleModel.NissanCube:
                return 'Cube';

            case VehicleModel.NissanDoublecab:
                return 'Double cab';

            case VehicleModel.NissaneNV200:
                return 'e-NV200 ';

            case VehicleModel.NissanEvalia:
                return 'Evalia';

            case VehicleModel.NissanFrontier:
                return 'Frontier';

            case VehicleModel.NissanGTR:
                return 'GT-R';

            case VehicleModel.NissanCherry:
                return 'Cherry';

            case VehicleModel.NissanJuke:
                return 'Juke';

            case VehicleModel.NissanKingCab:
                return 'King Cab';

            case VehicleModel.NissanLaurel:
                return 'Laurel';

            case VehicleModel.NissanLEAF:
                return 'LEAF';

            case VehicleModel.NissanMaxima:
                return 'Maxima';

            case VehicleModel.NissanMicra:
                return 'Micra';

            case VehicleModel.NissanMurano:
                return 'Murano';

            case VehicleModel.NissanNavara:
                return 'Navara';

            case VehicleModel.NissanNote:
                return 'Note';

            case VehicleModel.NissanNV200:
                return 'NV200';

            case VehicleModel.NissanNV300:
                return 'NV300';

            case VehicleModel.NissanPathfinder:
                return 'Pathfinder';

            case VehicleModel.NissanPathfinderArmada:
                return 'Pathfinder Armada';

            case VehicleModel.NissanPatrol:
                return 'Patrol';

            case VehicleModel.NissanPixo:
                return 'Pixo';

            case VehicleModel.NissanPrairie:
                return 'Prairie';

            case VehicleModel.NissanPrimastar:
                return 'Primastar';

            case VehicleModel.NissanPrimera:
                return 'Primera';

            case VehicleModel.NissanPulsar:
                return 'Pulsar';

            case VehicleModel.NissanQashqai:
                return 'Qashqai';

            case VehicleModel.NissanQuest:
                return 'Quest';

            case VehicleModel.NissanRogue:
                return 'Rogue';

            case VehicleModel.NissanSentra:
                return 'Sentra';

            case VehicleModel.NissanSerena:
                return 'Serena';

            case VehicleModel.NissanSilvia:
                return 'Silvia';

            case VehicleModel.NissanStanza:
                return 'Stanza';

            case VehicleModel.NissanSunny:
                return 'Sunny';

            case VehicleModel.NissanTerrano:
                return 'Terrano';

            case VehicleModel.NissanTerranoII:
                return 'Terrano II';

            case VehicleModel.NissanTiida:
                return 'Tiida';

            case VehicleModel.NissanTitan:
                return 'Titan';

            case VehicleModel.NissanXTrail:
                return 'X-Trail';

            case VehicleModel.NissanXterra:
                return 'Xterra';

            case VehicleModel.OldsmobileAurora:
                return 'Aurora';

            case VehicleModel.OldsmobileBravada:
                return 'Bravada';

            case VehicleModel.OldsmobileCutlass:
                return 'Cutlass';

            case VehicleModel.OldsmobileIntrique:
                return 'Intrique';

            case VehicleModel.OldsmobileSilhouette:
                return 'Silhouette';

            case VehicleModel.OltcitClub:
                return 'Club';

            case VehicleModel.OpelAdam:
                return 'Adam';

            case VehicleModel.OpelAgila:
                return 'Agila';

            case VehicleModel.OpelAmpera:
                return 'Ampera';

            case VehicleModel.OpelAntara:
                return 'Antara';

            case VehicleModel.OpelAscona:
                return 'Ascona';

            case VehicleModel.OpelAstra:
                return 'Astra';

            case VehicleModel.OpelCalibra:
                return 'Calibra';

            case VehicleModel.OpelCascada:
                return 'Cascada';

            case VehicleModel.OpelCombo:
                return 'Combo';

            case VehicleModel.OpelCorsa:
                return 'Corsa';

            case VehicleModel.OpelCrossland:
                return 'Crossland';

            case VehicleModel.OpelCrosslandX:
                return 'Crossland X';

            case VehicleModel.OpelFrontera:
                return 'Frontera';

            case VehicleModel.OpelGrandlandX:
                return 'Grandland X';

            case VehicleModel.OpelInsignia:
                return 'Insignia';

            case VehicleModel.OpelKadett:
                return 'Kadett';

            case VehicleModel.OpelManta:
                return 'Manta';

            case VehicleModel.OpelMeriva:
                return 'Meriva';

            case VehicleModel.OpelMokka:
                return 'Mokka';

            case VehicleModel.OpelMonterey:
                return 'Monterey';

            case VehicleModel.OpelMonza:
                return 'Monza';

            case VehicleModel.OpelOmega:
                return 'Omega';

            case VehicleModel.OpelRekord:
                return 'Rekord';

            case VehicleModel.OpelSenator:
                return 'Senator';

            case VehicleModel.OpelSignum:
                return 'Signum';

            case VehicleModel.OpelSintra:
                return 'Sintra';

            case VehicleModel.OpelSpeedster:
                return 'Speedster';

            case VehicleModel.OpelTigra:
                return 'Tigra';

            case VehicleModel.OpelVectra:
                return 'Vectra';

            case VehicleModel.OpelVivaro:
                return 'Vivaro';

            case VehicleModel.OpelZafira:
                return 'Zafira';

            case VehicleModel.PaganiHuayra:
                return 'Huayra';

            case VehicleModel.PaganiZonda:
                return 'Zonda';

            case VehicleModel.Peugeot1007:
                return '1007';

            case VehicleModel.Peugeot106:
                return '106';

            case VehicleModel.Peugeot107:
                return '107';

            case VehicleModel.Peugeot108:
                return '108';

            case VehicleModel.Peugeot2008:
                return '2008';

            case VehicleModel.Peugeot205:
                return '205';

            case VehicleModel.Peugeot206:
                return '206';

            case VehicleModel.Peugeot207:
                return '207';

            case VehicleModel.Peugeot3008:
                return '3008';

            case VehicleModel.Peugeot301:
                return '301';

            case VehicleModel.Peugeot305:
                return '305';

            case VehicleModel.Peugeot306:
                return '306';

            case VehicleModel.Peugeot307:
                return '307';

            case VehicleModel.Peugeot308:
                return '308';

            case VehicleModel.Peugeot309:
                return '309';

            case VehicleModel.Peugeot4007:
                return '4007';

            case VehicleModel.Peugeot4008:
                return '4008';

            case VehicleModel.Peugeot405:
                return '405';

            case VehicleModel.Peugeot406:
                return '406';

            case VehicleModel.Peugeot407:
                return '407';

            case VehicleModel.Peugeot5008:
                return '5008';

            case VehicleModel.Peugeot505:
                return '505';

            case VehicleModel.Peugeot508:
                return '508';

            case VehicleModel.Peugeot605:
                return '605';

            case VehicleModel.Peugeot607:
                return '607';

            case VehicleModel.Peugeot608:
                return '608';

            case VehicleModel.Peugeot806:
                return '806';

            case VehicleModel.Peugeot807:
                return '807';

            case VehicleModel.PeugeotBipperTepee:
                return 'Bipper Tepee';

            case VehicleModel.PeugeotBoxer:
                return 'Boxer';

            case VehicleModel.PeugeotExpert:
                return 'Expert';

            case VehicleModel.PeugeotExpertTepee:
                return 'Expert Tepee';

            case VehicleModel.PeugeotIon:
                return 'Ion';

            case VehicleModel.PeugeotPartner:
                return 'Partner';

            case VehicleModel.PeugeotPartnerTepee:
                return 'Partner Tepee';

            case VehicleModel.PeugeotRCZ:
                return 'RCZ';

            case VehicleModel.PeugeotRifter:
                return 'Rifter';

            case VehicleModel.PeugeotTraveller:
                return 'Traveller';

            case VehicleModel.PlymouthBreeze:
                return 'Breeze';

            case VehicleModel.PlymouthProwler:
                return 'Prowler';

            case VehicleModel.Polestar1:
                return '1';

            case VehicleModel.PolskiFiat125P:
                return '125P';

            case VehicleModel.PolskiFiat126P:
                return '126P';

            case VehicleModel.PolskiFiat650E:
                return '650E';

            case VehicleModel.PolskiFiatFSO1500:
                return 'FSO 1500';

            case VehicleModel.PolskiFiatPolonez:
                return 'Polonez';

            case VehicleModel.PontiacAztek:
                return 'Aztek';

            case VehicleModel.PontiacBonneville:
                return 'Bonneville';

            case VehicleModel.PontiacFirebird:
                return 'Firebird';

            case VehicleModel.PontiacFirefly:
                return 'Firefly';

            case VehicleModel.PontiacG6:
                return 'G6';

            case VehicleModel.PontiacGrandAm:
                return 'Grand Am';

            case VehicleModel.PontiacGrandPrix:
                return 'Grand Prix';

            case VehicleModel.PontiacMontana:
                return 'Montana';

            case VehicleModel.PontiacSunfire:
                return 'Sunfire';

            case VehicleModel.PontiacVibe:
                return 'Vibe';

            case VehicleModel.Porsche718BoxsterGTS:
                return '718 Boxster GTS';

            case VehicleModel.Porsche911:
                return '911';

            case VehicleModel.Porsche918Spyder:
                return '918 Spyder';

            case VehicleModel.Porsche924:
                return '924';

            case VehicleModel.Porsche928:
                return '928';

            case VehicleModel.Porsche944:
                return '944';

            case VehicleModel.Porsche968:
                return '968';

            case VehicleModel.Porsche981BoxsterS:
                return '981 Boxster S';

            case VehicleModel.Porsche986Boxster:
                return '986 Boxster';

            case VehicleModel.Porsche987Boxster:
                return '987 Boxster';

            case VehicleModel.PorscheBoxster:
                return 'Boxster';

            case VehicleModel.PorscheCarreraGT:
                return 'Carrera GT';

            case VehicleModel.PorscheCayenne:
                return 'Cayenne';

            case VehicleModel.PorscheCayman:
                return 'Cayman';

            case VehicleModel.PorscheMacan:
                return 'Macan';

            case VehicleModel.PorschePanamera:
                return 'Panamera';

            case VehicleModel.PorscheTaycan:
                return 'Taycan';

            case VehicleModel.Proton315:
                return '315';

            case VehicleModel.Proton400:
                return '400';

            case VehicleModel.Proton413:
                return '413';

            case VehicleModel.Proton415:
                return '415';

            case VehicleModel.Proton416:
                return '416';

            case VehicleModel.Proton418:
                return '418';

            case VehicleModel.Proton420:
                return '420';

            case VehicleModel.RenaultAlaskan:
                return 'Alaskan';

            case VehicleModel.RenaultAlpine:
                return 'Alpine';

            case VehicleModel.RenaultAvantime:
                return 'Avantime';

            case VehicleModel.RenaultCaptur:
                return 'Captur';

            case VehicleModel.RenaultClio:
                return 'Clio';

            case VehicleModel.RenaultEspace:
                return 'Espace';

            case VehicleModel.RenaultFluence:
                return 'Fluence';

            case VehicleModel.RenaultFuego:
                return 'Fuego';

            case VehicleModel.RenaultGrandEspace:
                return 'Grand Espace';

            case VehicleModel.RenaultGrandScenic:
                return 'Grand Scénic';

            case VehicleModel.RenaultKadjar:
                return 'Kadjar';

            case VehicleModel.RenaultKangoo:
                return 'Kangoo';

            case VehicleModel.RenaultKoleos:
                return 'Koleos';

            case VehicleModel.RenaultLaguna:
                return 'Laguna';

            case VehicleModel.RenaultLatitude:
                return 'Latitude';

            case VehicleModel.RenaultMaster:
                return 'Master';

            case VehicleModel.RenaultMegane:
                return 'Mégane';

            case VehicleModel.RenaultModus:
                return 'Modus';

            case VehicleModel.RenaultR11:
                return 'R11';

            case VehicleModel.RenaultR18:
                return 'R18';

            case VehicleModel.RenaultR19:
                return 'R19';

            case VehicleModel.RenaultR20:
                return 'R20';

            case VehicleModel.RenaultR21:
                return 'R21';

            case VehicleModel.RenaultR25:
                return 'R25';

            case VehicleModel.RenaultR4:
                return 'R4';

            case VehicleModel.RenaultR5:
                return 'R5';

            case VehicleModel.RenaultR9:
                return 'R9';

            case VehicleModel.RenaultSafrane:
                return 'Safrane';

            case VehicleModel.RenaultScenic:
                return 'Scénic';

            case VehicleModel.RenaultTalisman:
                return 'Talisman';

            case VehicleModel.RenaultThalia:
                return 'Thalia';

            case VehicleModel.RenaultTrafic:
                return 'Trafic';

            case VehicleModel.RenaultTwingo:
                return 'Twingo';

            case VehicleModel.RenaultTwizy:
                return 'Twizy';

            case VehicleModel.RenaultVelSatis:
                return 'Vel Satis';

            case VehicleModel.RenaultWind:
                return 'Wind';

            case VehicleModel.RenaultZOE:
                return 'ZOE';

            case VehicleModel.RollsRoyceCullinan:
                return 'Cullinan';

            case VehicleModel.RollsRoyceDawn:
                return 'Dawn';

            case VehicleModel.RollsRoyceGhost:
                return 'Ghost';

            case VehicleModel.RollsRoycePark:
                return 'Park';

            case VehicleModel.RollsRoycePhantom:
                return 'Phantom';

            case VehicleModel.RollsRoyceSilverDawn:
                return 'Silver Dawn';

            case VehicleModel.RollsRoyceSilverSpirit:
                return 'Silver Spirit';

            case VehicleModel.RollsRoyceTouringLimousine:
                return 'Touring Limousine';

            case VehicleModel.RollsRoyceWraith:
                return 'Wraith';

            case VehicleModel.Rover25:
                return '25';

            case VehicleModel.Rover45:
                return '45';

            case VehicleModel.Rover800:
                return '800';

            case VehicleModel.RoverStreetwise:
                return 'Streetwise';

            case VehicleModel.Saab93:
                return '9-3';

            case VehicleModel.Saab95:
                return '9-5';

            case VehicleModel.Saab900:
                return '900';

            case VehicleModel.Saab9000:
                return '9000';

            case VehicleModel.Saab99:
                return '99';

            case VehicleModel.SantanaPS10:
                return 'PS-10';

            case VehicleModel.SantanaPS300:
                return 'PS300';

            case VehicleModel.SantanaPS350:
                return 'PS350';

            case VehicleModel.SaturnL300:
                return 'L300';

            case VehicleModel.SaturnSC1:
                return 'SC1';

            case VehicleModel.SaturnSC2:
                return 'SC2';

            case VehicleModel.SaturnSL1:
                return 'SL1';

            case VehicleModel.SaturnSL2:
                return 'SL2';

            case VehicleModel.SaturnSW1:
                return 'SW1';

            case VehicleModel.SaturnSW2:
                return 'SW2';

            case VehicleModel.SaturnVue:
                return 'Vue';

            case VehicleModel.ScionbbX:
                return 'bbX';

            case VehicleModel.ScionccX:
                return 'ccX';

            case VehicleModel.ScioniQ:
                return 'iQ';

            case VehicleModel.SciontC:
                return 'tC';

            case VehicleModel.SeatAlhambra:
                return 'Alhambra';

            case VehicleModel.SeatAltea:
                return 'Altea';

            case VehicleModel.SeatArosa:
                return 'Arosa';

            case VehicleModel.SeatCordoba:
                return 'Cordoba';

            case VehicleModel.SeatExeo:
                return 'Exeo';

            case VehicleModel.SeatInca:
                return 'Inca';

            case VehicleModel.SeatMalaga:
                return 'Malaga';

            case VehicleModel.SeatMarbella:
                return 'Marbella';

            case VehicleModel.SeatMii:
                return 'Mii';

            case VehicleModel.SeatTarraco:
                return 'Tarraco';

            case VehicleModel.SeatToledo:
                return 'Toledo';

            case VehicleModel.SmartElectricDrive:
                return 'Electric Drive';

            case VehicleModel.SmartForfour:
                return 'Forfour';

            case VehicleModel.SmartFortwo:
                return 'Fortwo';

            case VehicleModel.SmartMicrocompact:
                return 'Micro compact';

            case VehicleModel.SsangYongActyon:
                return 'Actyon';

            case VehicleModel.SsangYongKorando:
                return 'Korando';

            case VehicleModel.SsangYongKyron:
                return 'Kyron';

            case VehicleModel.SsangYongMusso:
                return 'Musso';

            case VehicleModel.SsangYongRexton:
                return 'Rexton';

            case VehicleModel.SsangYongTivoli:
                return 'Tivoli';

            case VehicleModel.SsangYongXLV:
                return 'XLV';

            case VehicleModel.Subaru1800:
                return '1800';

            case VehicleModel.SubaruAscent:
                return 'Ascent';

            case VehicleModel.SubaruBaja:
                return 'Baja';

            case VehicleModel.SubaruBRZ:
                return 'BRZ';

            case VehicleModel.SubaruForester:
                return 'Forester';

            case VehicleModel.SubaruImpreza:
                return 'Impreza';

            case VehicleModel.SubaruJusty:
                return 'Justy';

            case VehicleModel.SubaruLegacy:
                return 'Legacy';

            case VehicleModel.SubaruLevorg:
                return 'Levorg';

            case VehicleModel.SubaruOutback:
                return 'Outback';

            case VehicleModel.SubaruStationwagon:
                return 'Stationwagon';

            case VehicleModel.SubaruSVX:
                return 'SVX';

            case VehicleModel.SubaruTrezia:
                return 'Trezia';

            case VehicleModel.SubaruTribeca:
                return 'Tribeca';

            case VehicleModel.SubaruVivio:
                return 'Vivio';

            case VehicleModel.SubaruWRXSTI:
                return 'WRX STI';

            case VehicleModel.SubaruXV:
                return 'XV';

            case VehicleModel.Suzuki750S:
                return '750 S';

            case VehicleModel.SuzukiAcross:
                return 'Across';

            case VehicleModel.SuzukiAlto:
                return 'Alto';

            case VehicleModel.SuzukiBaleno:
                return 'Baleno';

            case VehicleModel.SuzukiCarry:
                return 'Carry';

            case VehicleModel.SuzukiCelerio:
                return 'Celerio';

            case VehicleModel.SuzukiGrandVitara:
                return 'Grand Vitara';

            case VehicleModel.SuzukiIgnis:
                return 'Ignis';

            case VehicleModel.SuzukiJimny:
                return 'Jimny';

            case VehicleModel.SuzukiKizashi:
                return 'Kizashi';

            case VehicleModel.SuzukiLiana:
                return 'Liana';

            case VehicleModel.SuzukiSCross:
                return 'S-Cross';

            case VehicleModel.SuzukiSamurai:
                return 'Samurai';

            case VehicleModel.SuzukiSantana:
                return 'Santana';

            case VehicleModel.SuzukiSplash:
                return 'Splash';

            case VehicleModel.SuzukiSupperCarry:
                return 'Supper Carry';

            case VehicleModel.SuzukiSwace:
                return 'Swace';

            case VehicleModel.SuzukiSwift:
                return 'Swift';

            case VehicleModel.SuzukiSX4:
                return 'SX4';

            case VehicleModel.SuzukiSX4SCross:
                return 'SX4 S-Cross';

            case VehicleModel.SuzukiVitara:
                return 'Vitara';

            case VehicleModel.SuzukiWagonR:
                return 'Wagon R';

            case VehicleModel.Skoda1000MB:
                return '1000 MB';

            case VehicleModel.Skoda105:
                return '105';

            case VehicleModel.Skoda110:
                return '110';

            case VehicleModel.Skoda120:
                return '120';

            case VehicleModel.Skoda125:
                return '125';

            case VehicleModel.Skoda130:
                return '130';

            case VehicleModel.Skoda450Roadster:
                return '450 Roadster';

            case VehicleModel.Skoda860:
                return '860';

            case VehicleModel.SkodaCitigo:
                return 'Citigo';

            case VehicleModel.SkodaEnyaq:
                return 'Enyaq';

            case VehicleModel.SkodaFabia:
                return 'Fabia';

            case VehicleModel.SkodaFavorit:
                return 'Favorit';

            case VehicleModel.SkodaFelicia:
                return 'Felicia';

            case VehicleModel.SkodaForman:
                return 'Forman';

            case VehicleModel.SkodaKamiq:
                return 'Kamiq';

            case VehicleModel.SkodaKaroq:
                return 'Karoq';

            case VehicleModel.SkodaKodiaq:
                return 'Kodiaq';

            case VehicleModel.SkodaOctavia:
                return 'Octavia';

            case VehicleModel.SkodaRapid:
                return 'Rapid';

            case VehicleModel.SkodaRoomster:
                return 'Roomster';

            case VehicleModel.SkodaScala:
                return 'Scala';

            case VehicleModel.SkodaSuperb:
                return 'Superb';

            case VehicleModel.SkodaYeti:
                return 'Yeti';

            case VehicleModel.TatraT603:
                return 'T603';

            case VehicleModel.TatraT613:
                return 'T613';

            case VehicleModel.TatraT700:
                return 'T700';

            case VehicleModel.TavriaTavria:
                return 'Tavria';

            case VehicleModel.TeslaModel3:
                return 'Model 3';

            case VehicleModel.TeslaModelS:
                return 'Model S';

            case VehicleModel.TeslaModelX:
                return 'Model X';

            case VehicleModel.Toyota4Runner:
                return '4Runner';

            case VehicleModel.ToyotaAuris:
                return 'Auris';

            case VehicleModel.ToyotaAvalon:
                return 'Avalon';

            case VehicleModel.ToyotaAvensis:
                return 'Avensis';

            case VehicleModel.ToyotaAvensisVerso:
                return 'Avensis Verso';

            case VehicleModel.ToyotaAygo:
                return 'Aygo';

            case VehicleModel.ToyotaCHR:
                return 'C-HR';

            case VehicleModel.ToyotaCamry:
                return 'Camry';

            case VehicleModel.ToyotaCarina:
                return 'Carina';

            case VehicleModel.ToyotaCelica:
                return 'Celica';

            case VehicleModel.ToyotaCorolla:
                return 'Corolla';

            case VehicleModel.ToyotaCorollaVerso:
                return 'Corolla Verso';

            case VehicleModel.ToyotaEcho:
                return 'Echo';

            case VehicleModel.ToyotaFJCruiser:
                return 'FJ Cruiser';

            case VehicleModel.ToyotaFortuner:
                return 'Fortuner';

            case VehicleModel.ToyotaGT86:
                return 'GT86';

            case VehicleModel.ToyotaHighlander:
                return 'Highlander';

            case VehicleModel.ToyotaHilux:
                return 'Hilux';

            case VehicleModel.ToyotaLandCruiser:
                return 'Land Cruiser';

            case VehicleModel.ToyotaMR2:
                return 'MR2';

            case VehicleModel.ToyotaPaseo:
                return 'Paseo';

            case VehicleModel.ToyotaPicnic:
                return 'Picnic';

            case VehicleModel.ToyotaPrevia:
                return 'Previa';

            case VehicleModel.ToyotaPrius:
                return 'Prius';

            case VehicleModel.ToyotaProace:
                return 'Proace';

            case VehicleModel.ToyotaProaceCity:
                return 'Proace City';

            case VehicleModel.ToyotaProaceCityVerso:
                return 'Proace City Verso';

            case VehicleModel.ToyotaRav4:
                return 'Rav4';

            case VehicleModel.ToyotaRunner:
                return 'Runner';

            case VehicleModel.ToyotaSequoia:
                return 'Sequoia';

            case VehicleModel.ToyotaSienna:
                return 'Sienna';

            case VehicleModel.ToyotaSolara:
                return 'Solara';

            case VehicleModel.ToyotaStarlet:
                return 'Starlet';

            case VehicleModel.ToyotaSupra:
                return 'Supra';

            case VehicleModel.ToyotaTacoma:
                return 'Tacoma';

            case VehicleModel.ToyotaTundra:
                return 'Tundra';

            case VehicleModel.ToyotaUrbanCruiser:
                return 'Urban Cruiser';

            case VehicleModel.ToyotaVenza:
                return 'Venza';

            case VehicleModel.ToyotaVerso:
                return 'Verso';

            case VehicleModel.ToyotaYaris:
                return 'Yaris';

            case VehicleModel.Trabant601S:
                return '601S';

            case VehicleModel.TrabantKombi:
                return 'Kombi';

            case VehicleModel.TVRCerbera:
                return 'Cerbera';

            case VehicleModel.UAZ3160:
                return '3160';

            case VehicleModel.UltimaCanAm:
                return 'Can-Am';

            case VehicleModel.VolkswagenAmarok:
                return 'Amarok';

            case VehicleModel.VolkswagenArteon:
                return 'Arteon';

            case VehicleModel.VolkswagenAtlas:
                return 'Atlas';

            case VehicleModel.VolkswagenBeetle:
                return 'Beetle';

            case VehicleModel.VolkswagenBora:
                return 'Bora';

            case VehicleModel.VolkswagenCaddy:
                return 'Caddy';

            case VehicleModel.VolkswagenCaravelle:
                return 'Caravelle';

            case VehicleModel.VolkswagenCarmanGhia:
                return 'Carman Ghia';

            case VehicleModel.VolkswagenCC:
                return 'CC';

            case VehicleModel.VolkswagenCorrado:
                return 'Corrado';

            case VehicleModel.VolkswagenEos:
                return 'Eos';

            case VehicleModel.VolkswagenFox:
                return 'Fox';

            case VehicleModel.VolkswagenGolf:
                return 'Golf';

            case VehicleModel.VolkswagenGolfPlus:
                return 'Golf Plus';

            case VehicleModel.VolkswagenGolfSportsvan:
                return 'Golf Sportsvan';

            case VehicleModel.VolkswagenID3:
                return 'ID.3';

            case VehicleModel.VolkswagenID4:
                return 'ID.4';

            case VehicleModel.VolkswagenJetta:
                return 'Jetta';

            case VehicleModel.VolkswagenKarmannGhia:
                return 'Karmann-Ghia';

            case VehicleModel.VolkswagenLupo:
                return 'Lupo';

            case VehicleModel.VolkswagenMultivan:
                return 'Multivan';

            case VehicleModel.VolkswagenNewBeetle:
                return 'New Beetle';

            case VehicleModel.VolkswagenPassat:
                return 'Passat';

            case VehicleModel.VolkswagenPassatCC:
                return 'Passat CC';

            case VehicleModel.VolkswagenPhaeton:
                return 'Phaeton';

            case VehicleModel.VolkswagenPolo:
                return 'Polo';

            case VehicleModel.VolkswagenRoutan:
                return 'Routan';

            case VehicleModel.VolkswagenScirocco:
                return 'Scirocco';

            case VehicleModel.VolkswagenSharan:
                return 'Sharan';

            case VehicleModel.VolkswagenTCross:
                return 'T-Cross';

            case VehicleModel.VolkswagenTRoc:
                return 'T-Roc';

            case VehicleModel.VolkswagenTiguan:
                return 'Tiguan';

            case VehicleModel.VolkswagenTiguanAllspace:
                return 'Tiguan Allspace';

            case VehicleModel.VolkswagenTouareg:
                return 'Touareg';

            case VehicleModel.VolkswagenTouran:
                return 'Touran';

            case VehicleModel.VolkswagenTransporter:
                return 'Transporter';

            case VehicleModel.VolkswagenUp:
                return 'Up!';

            case VehicleModel.VolkswagenVento:
                return 'Vento';

            case VehicleModel.Volvo240:
                return '240';

            case VehicleModel.Volvo241:
                return '241';

            case VehicleModel.Volvo242:
                return '242';

            case VehicleModel.Volvo245:
                return '245';

            case VehicleModel.Volvo440:
                return '440';

            case VehicleModel.Volvo460:
                return '460';

            case VehicleModel.Volvo480:
                return '480';

            case VehicleModel.Volvo740:
                return '740';

            case VehicleModel.Volvo940:
                return '940';

            case VehicleModel.Volvo960:
                return '960';

            case VehicleModel.VolvoC30:
                return 'C30';

            case VehicleModel.VolvoC70:
                return 'C70';

            case VehicleModel.VolvoS40:
                return 'S40';

            case VehicleModel.VolvoS60:
                return 'S60';

            case VehicleModel.VolvoS70:
                return 'S70';

            case VehicleModel.VolvoS80:
                return 'S80';

            case VehicleModel.VolvoS90:
                return 'S90';

            case VehicleModel.VolvoV40:
                return 'V40';

            case VehicleModel.VolvoV50:
                return 'V50';

            case VehicleModel.VolvoV60:
                return 'V60';

            case VehicleModel.VolvoV70:
                return 'V70';

            case VehicleModel.VolvoV90:
                return 'V90';

            case VehicleModel.VolvoXC40:
                return 'XC40';

            case VehicleModel.VolvoXC60:
                return 'XC60';

            case VehicleModel.VolvoXC70:
                return 'XC70';

            case VehicleModel.VolvoXC90:
                return 'XC90';

            case VehicleModel.Wartburg353:
                return '353';

            case VehicleModel.Yugo55:
                return '55';

            case VehicleModel.YugoFlorida:
                return 'Florida';

            case VehicleModel.YugoKoral:
                return 'Koral';

            case VehicleModel.Zastava1100:
                return '1100';

            case VehicleModel.ZhiDouD1:
                return 'D1';

            case VehicleModel.ZhiDouD2:
                return 'D2';

            case VehicleModel.ZhiDouD2S:
                return 'D2S';

            case VehicleModel.ZhiDouD2:
                return 'D2';

            case VehicleModel.DongfengDF6:
                return 'DF 6';

            case VehicleModel.DongfengFengon5:
                return 'Fengon 5';

            case VehicleModel.DongfengGlory500:
                return 'Glory 500';

            case VehicleModel.DongfengShine:
                return 'Shine';

            case VehicleModel.DongfengT5EVO:
                return 'T5 EVO';

            case VehicleModel.DongfengT5EVOEV:
                return 'T5 EVO EV';

            case VehicleModel.DongfengUTour:
                return 'U-Tour';

            case VehicleModel.DongfengGlory580:
                return 'Glory 580';

            case VehicleModel.DongfengSeres3:
                return 'Seres 3';

            case VehicleModel.DongfengVohyahFree:
                return 'Vohyah Free';

        }
    }

    public static getVehicleBodyOptions(vehicleType: VehicleType): IOptionData[] {

        if (vehicleType === VehicleType.Car) {
            return [
                { label: "Hatchback", value: 2 },
                { label: "Kombi", value: 3 },
                { label: "Terénní", value: 21 },
                { label: "Sedan/Limuzína", value: 1 },
                { label: "Kupé", value: 4 },
                { label: "Kabriolet", value: 5 },
                { label: "Pick-up", value: 6 },
                { label: "Roadster", value: 7 },
                { label: "Liftback", value: 8 },
                { label: "SUV", value: 9 },
                { label: "MPV", value: 10 },
                { label: "VAN", value: 11 },
            ];
        }
        else if (vehicleType === VehicleType.Truck) {
            return [
                { label: "Tahač", value: 12 },
                { label: "Valník", value: 13 },
                { label: "Sklápěč", value: 14 },
                { label: "Cisterna", value: 15 },
                { label: "Chladící/Mrazící", value: 16 },
                { label: "Nosič kontejnerů", value: 17 },
                { label: "Přeprava aut", value: 18 },
                { label: "Speciální nástavba", value: 19 },
                { label: "Skříň", value: 20 },
                { label: "Autojeřáb", value: 23 },
                { label: "Přepravník dřeva", value: 24 },
                { label: "Přepravník zvířat", value: 25 },
            ];
        }
        else {
            return [];
        }
    }

    public static getEmissionClassOptions(): IOptionData[] {
        const options: IOptionData[] = [
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro1), value: VehicleEmissionClass.Euro1 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro2), value: VehicleEmissionClass.Euro2 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro3), value: VehicleEmissionClass.Euro3 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro4), value: VehicleEmissionClass.Euro4 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro5), value: VehicleEmissionClass.Euro5 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro6), value: VehicleEmissionClass.Euro6 },
        ]
        return options;
    }

    public static getEmissionClassLabel(emissionClass: VehicleEmissionClass): string {
        switch (emissionClass) {

            case VehicleEmissionClass.Euro1:
                return "EURO 1";

            case VehicleEmissionClass.Euro2:
                return "EURO 2";

            case VehicleEmissionClass.Euro3:
                return "EURO 3";

            case VehicleEmissionClass.Euro4:
                return "EURO 4";

            case VehicleEmissionClass.Euro5:
                return "EURO 5";

            case VehicleEmissionClass.Euro6:
                return "EURO 6";
        }
    }

    public static getFuelTypeOptions() {
        let options: IOptionData[] = [
            { label: this.getFuelTypeLabel(VehicleFuelType.Petrol), value: VehicleFuelType.Petrol },
            { label: this.getFuelTypeLabel(VehicleFuelType.Diesel), value: VehicleFuelType.Diesel },
            { label: this.getFuelTypeLabel(VehicleFuelType.LpgPetrol), value: VehicleFuelType.LpgPetrol },
            { label: this.getFuelTypeLabel(VehicleFuelType.Hybrid), value: VehicleFuelType.Hybrid },
            { label: this.getFuelTypeLabel(VehicleFuelType.CngPetrol), value: VehicleFuelType.CngPetrol },
            { label: this.getFuelTypeLabel(VehicleFuelType.Electric), value: VehicleFuelType.Electric },
            { label: this.getFuelTypeLabel(VehicleFuelType.Ethanol), value: VehicleFuelType.Ethanol },
            { label: this.getFuelTypeLabel(VehicleFuelType.Hydrogen), value: VehicleFuelType.Hydrogen },
            { label: this.getFuelTypeLabel(VehicleFuelType.Lng), value: VehicleFuelType.Lng },
        ];
        return options;
    }

    public static getFuelTypeLabel(fuelType: VehicleFuelType): string {
        switch (fuelType) {

            case VehicleFuelType.Petrol:
                return "Benzín";

            case VehicleFuelType.Diesel:
                return "Diesel";

            case VehicleFuelType.LpgPetrol:
                return "LPG + benzín";

            case VehicleFuelType.Electric:
                return "Elektro";

            case VehicleFuelType.Hybrid:
                return "Hybrid";

            case VehicleFuelType.CngPetrol:
                return "CNG + Benzín";

            case VehicleFuelType.Ethanol:
                return "Ethanol";

            case VehicleFuelType.Hydrogen:
                return "Vodík";

            case VehicleFuelType.Lng:
                return "LNG";
        }
    }

    public static getColorOptions() {
        let options: IOptionData[] = [
            { label: this.getColorLabel(VehicleColor.Beige), value: VehicleColor.Beige },
            { label: this.getColorLabel(VehicleColor.Black), value: VehicleColor.Black },
            { label: this.getColorLabel(VehicleColor.Blue), value: VehicleColor.Blue },
            { label: this.getColorLabel(VehicleColor.Brown), value: VehicleColor.Brown },
            { label: this.getColorLabel(VehicleColor.Gold), value: VehicleColor.Gold },
            { label: this.getColorLabel(VehicleColor.Gray), value: VehicleColor.Gray },
            { label: this.getColorLabel(VehicleColor.Green), value: VehicleColor.Green },
            { label: this.getColorLabel(VehicleColor.Orange), value: VehicleColor.Orange },
            { label: this.getColorLabel(VehicleColor.Pink), value: VehicleColor.Pink },
            { label: this.getColorLabel(VehicleColor.Purple), value: VehicleColor.Purple },
            { label: this.getColorLabel(VehicleColor.Red), value: VehicleColor.Red },
            { label: this.getColorLabel(VehicleColor.Silver), value: VehicleColor.Silver },
            { label: this.getColorLabel(VehicleColor.White), value: VehicleColor.White },
            { label: this.getColorLabel(VehicleColor.Yellow), value: VehicleColor.Yellow },
            { label: this.getColorLabel(VehicleColor.Other), value: VehicleColor.Other },
        ];
        return options;
    }

    public static getColorLabel(color: VehicleColor): string {
        switch (color) {

            case VehicleColor.Beige:
                return "Béžová";

            case VehicleColor.Black:
                return "Černá";

            case VehicleColor.Blue:
                return "Modrá";

            case VehicleColor.Brown:
                return "Hnědá";

            case VehicleColor.Gold:
                return "Zlatá";

            case VehicleColor.Gray:
                return "Šedá";

            case VehicleColor.Green:
                return "Zelená";

            case VehicleColor.Orange:
                return "Oranžová";

            case VehicleColor.Pink:
                return "Růžová";

            case VehicleColor.Purple:
                return "Fialová";

            case VehicleColor.Red:
                return "Červená";

            case VehicleColor.Silver:
                return "Stříbrná";

            case VehicleColor.White:
                return "Bílá";

            case VehicleColor.Yellow:
                return "Žlutá";

            case VehicleColor.Other:
                return "Jiná barva";
        }
    }

    public static getVehicleTransmissionOptions(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "Manuální", value: 1 },
            { label: "Automatická", value: 2 },
            { label: "Poloautomatická", value: 3 },
        ];
        return options;
    }

    public static getVehicleTypeOptions(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "Osobní auto", value: 1 },
            { label: "Užitkové auto", value: 2 },
            { label: "Čtyřkolka", value: 3 },
            { label: "Motorka", value: 4 },
            { label: "Náhradní díly", value: 6 },
            { label: "Obytné", value: 8 },
            { label: "Přívěsy a vozíky", value: 9 },
            { label: "Nákladní", value: 5 },
            { label: "Autobus", value: 10 },
            { label: "Pracovní stroje", value: 11 },
            { label: "Ostatní", value: 7 },
        ];
        // options = orderBy(options, opt => [opt.value], ['asc']);
        return options;
    }

    public static getVehicleConditionOptions(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "Nové", value: VehicleCondition.New },
            { label: "Ojeté", value: VehicleCondition.Used },
            { label: "Předváděcí", value: VehicleCondition.Demo },
            { label: "Havarované", value: VehicleCondition.Demaged },
        ];
        options = orderBy(options, opt => [opt.value], ['asc']);
        return options;
    }

    public static getVehicleBrandLabel(id: number) : string {
        const brand = find(this.getVehicleBrandOptions(), { value: id });

        if (brand) {
            return brand.label;
        }
        return "";
    }

    public static getVehicleBrandOptions(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "Alfa Romeo", value: 1 },
            { label: "Audi", value: 2 },
            { label: "Bmw", value: 3 },
            { label: "Citroen", value: 4 },
            { label: "Dacia", value: 5 },
            { label: "Daewoo", value: 6 },
            { label: "Daihatsu", value: 7 },
            { label: "Fiat", value: 8 },
            { label: "Ford", value: 9 },
            { label: "Honda", value: 10 },
            { label: "Hyundai", value: 11 },
            { label: "Chevrolet", value: 12 },
            { label: "Chrysler", value: 13 },
            { label: "Jaguar", value: 14 },
            { label: "Jeep", value: 15 },
            { label: "Kia", value: 16 },
            { label: "Lada", value: 17 },
            { label: "Lancia", value: 18 },
            { label: "Land Rover", value: 19 },
            { label: "Lexus", value: 20 },
            { label: "Mazda", value: 21 },
            { label: "Mercedes", value: 22 },
            { label: "Mini", value: 23 },
            { label: "Mitsubishi", value: 24 },
            { label: "Nissan", value: 25 },
            { label: "Opel", value: 26 },
            { label: "Peugeot", value: 27 },
            { label: "Porsche", value: 28 },
            { label: "Renault", value: 29 },
            { label: "Rover", value: 30 },
            { label: "Saab", value: 31 },
            { label: "Seat", value: 32 },
            { label: "Smart", value: 33 },
            { label: "SsangYong", value: 34 },
            { label: "Subaru", value: 35 },
            { label: "Suzuki", value: 36 },
            { label: "Škoda", value: 37 },
            { label: "Tatra", value: 38 },
            { label: "Toyota", value: 39 },
            { label: "Trabant", value: 40 },
            { label: "Volkswagen", value: 41 },
            { label: "Volvo", value: 42 },
            { label: "Abarth", value: 43 },
            { label: "Acura", value: 44 },
            { label: "Aixam", value: 45 },
            { label: "Alpina", value: 46 },
            { label: "Aston Martin", value: 47 },
            { label: "Austin", value: 48 },
            { label: "Bentley", value: 49 },
            { label: "Buick", value: 50 },
            { label: "Cadillac", value: 51 },
            { label: "Casalini", value: 52 },
            { label: "Cupra", value: 53 },
            { label: "Dodge", value: 54 },
            { label: "Ds", value: 55 },
            { label: "Ferrari", value: 56 },
            { label: "Gaz", value: 57 },
            { label: "Gmc", value: 58 },
            { label: "GreatWall", value: 59 },
            { label: "Hummer", value: 60 },
            { label: "Chatenet", value: 61 },
            { label: "Infiniti", value: 62 },
            { label: "Isuzo", value: 63 },
            { label: "Iveco", value: 64 },
            { label: "Jac", value: 65 },
            { label: "Lamborghini", value: 66 },
            { label: "Ligier", value: 67 },
            { label: "Lincoln", value: 68 },
            { label: "Maserati", value: 69 },
            { label: "Maybach", value: 70 },
            { label: "McLaren", value: 71 },
            { label: "Mg", value: 72 },
            { label: "Microcar", value: 73 },
            { label: "Morgan", value: 74 },
            { label: "Plymouth", value: 75 },
            { label: "Pontiac", value: 76 },
            { label: "RaytonFissore", value: 77 },
            { label: "Rolls-Royce", value: 78 },
            { label: "Tesla", value: 79 },
            { label: "Tvr", value: 80 },
            { label: "Wartburg", value: 81 },
            { label: "ZhiDou", value: 82 },

            { label: "MAN", value: 135 },
            { label: "DAF", value: 136 },
            { label: "Kögel", value: 137 },
            { label: "Avia", value: 138 },
            { label: "FUSO", value: 139 },
            { label: "Kamaz", value: 140 },
            { label: "Kenworth", value: 141 },
            { label: "Liaz", value: 142 },
            { label: "Praga", value: 143 },
            { label: "Ross", value: 144 },
            { label: "Scania", value: 145 },
            { label: "Steyr", value: 146 },
            { label: "Dongfeng", value: 507 },
            { label: "Ostatní", value: 147 }
        ];
        options = orderBy(options, opt => [opt.label], ['asc']);
        return options;
    }

    public static getOthersSections(): IOptionData[] {
        let options: IOptionData[] = [
            //     { label: "Auto", value: 1 },
            { label: "Děti", value: 2 },
            { label: "Stavba, dílna, zahrada", value: 3 },
            { label: "Zvířata, rostliny", value: 4 },
            { label: "Elektro", value: 5 },
            { label: "Služby a řemesla", value: 6 },
            { label: "Elektronika", value: 7 },
            { label: "Sport", value: 8 },
            { label: "PC a výpočetní technika", value: 9 },
            { label: "Nábytek, bytové doplňky", value: 10 },
            { label: "Práce", value: 11 },
            //  { label: "Reality", value: 12 },
            { label: "Stroje", value: 13 },
            { label: "Foto", value: 14 },
            { label: "Hudba", value: 15 },
            { label: "Vstupenky", value: 16 },
            { label: "Knihy", value: 17 },
            { label: "Oblečení", value: 18 },
            { label: "Reality", value: 20 },
            { label: "Ostatní", value: 19 },
        ];

        options = orderBy(options, opt => [opt.label], ['asc']);

        return options;
    }

    public static getProductServerInfo(
        product: IProduct,
        collection: IProductCollection,
        server: OnlineServer,
        user: IUser,
        productServerAccounts?: IProductServerAccount[]
    ): IProductServerInfo {

        switch (server) {
            case OnlineServer.Sauto:
                const importer = autoImporterStore.findById(product.autoImporterId);

                if (importer) {
                    return {
                        productId: product.id,
                        isVisible: true,
                        server: server,
                        enabled: true,
                        url: product.sautoOfferUrl,
                    };
                }
                break;

            case OnlineServer.BazosCz:
                return {
                    productId: product.id,
                    isVisible: product.bazosCzVisible,
                    url: product.bazosCzUrl,
                    visibleCheck: product.bazosCzVisibleCheck,
                    server: server,
                    enabled: product.bazosCZ,
                    pendingEdit: product.bazosCzPendingEdit,
                    pendingDelete: product.bazosCzPendingDelete,
                    pendingUpload: product.bazosCzPendingUpload,
                    pendingCheck: product.bazosCzPendingCheck,
                    nextRenewDateUtc: product.bazosCzNextRenewDateUtc,
                    error: product.bazosCzError,
                    serverRequiredAction: collection.bazosCzRequiredSignIn,
                    isTop: product.bazosCzIsTop,
                    enableUploadTopped: collection.bazosCzEnableUploadTopped,
                    errorType: product.bazosCzErrorActionType,
                    password: product.bazosCzPassword,
                }

            case OnlineServer.BazosSk:
                return {
                    productId: product.id,
                    isVisible: product.bazosSkVisible,
                    url: product.bazosSkUrl,
                    visibleCheck: product.bazosSkVisibleCheck,
                    server: server,
                    enabled: product.bazosSK,
                    pendingEdit: product.bazosSkPendingEdit,
                    pendingDelete: product.bazosSkPendingDelete,
                    pendingUpload: product.bazosSkPendingUpload,
                    pendingCheck: product.bazosSkPendingCheck,
                    nextRenewDateUtc: product.bazosSkNextRenewDateUtc,
                    error: product.bazosSkError,
                    serverRequiredAction: collection.bazosSkRequiredSignIn,
                    isTop: product.bazosSkIsTop,
                    enableUploadTopped: collection.bazosSkEnableUploadTopped,
                    errorType: product.bazosSkErrorActionType,
                    password: product.bazosSkPassword,
                }

            case OnlineServer.Sbazar:

                if (product.sbazarAccountId) {
                    const productServerAccount: IProductServerAccount =
                        find(productServerAccounts || [], { productId: product.id, serverAccountId: product.sbazarAccountId  });

                
                    if (productServerAccount) {
                        return {
                            productId: product.id,
                            isVisible: productServerAccount.offerIsVisible,
                            url: productServerAccount.offerUrl,
                            visibleCheck: productServerAccount.lastVisibleCheck,
                            server: server,
                            enabled: product.sbazar,
                            pendingEdit: productServerAccount.offerPendingEdit,
                            pendingDelete: productServerAccount.offerPendingDelete,
                            pendingUpload: productServerAccount.offerPendingUpload,
                            pendingCheck: productServerAccount.offerPendingCheck,
                            error: productServerAccount.offerError,
                            serverRequiredAction: productServerAccount.offerError,
                            isTop: false,
                            enableUploadTopped: false,
                            errorType: productServerAccount.offerErrorActionType,
                        };
                    }
                }

                return {
                    productId: product.id,
                    isVisible: product.sbazarVisible,
                    url: `https://www.sbazar.cz/${collection.sbazarShopName}/detail/${product.sbazarOfferId}`, // product.sbazarUrl,
                    visibleCheck: product.sbazarVisibleCheck,
                    server: server,
                    enabled: product.sbazar,
                    pendingEdit: product.sbazarPendingEdit,
                    pendingDelete: product.sbazarPendingDelete,
                    pendingUpload: product.sbazarPendingUpload,
                    pendingCheck: product.sbazarPendingCheck,
                    nextRenewDateUtc: product.sbazarNextRenewDateUtc,
                    error: product.sbazarError,
                    serverRequiredAction: collection.sbazarError,
                    isTop: false,
                    enableUploadTopped: false,
                    errorType: product.sbazarErrorActionType,
                }


            case OnlineServer.TipMoto: 
                return {
                    productId: product.id,
                    isVisible: product.tipMoto,
                    server: server,
                    enabled: product.tipMoto && user.uploaderServers.includes(server),
                }

            case OnlineServer.AutobazarEu:
                return {
                    productId: product.id,
                    isVisible: product.autobazarEu,
                    server: server,
                    enabled: product.autobazarEu && user.uploaderServers.includes(server),
                }

            case OnlineServer.GoogleMerchant:
                return {
                    productId: product.id,
                    isVisible: product.googleMerchant,
                    server: server,
                    enabled: product.googleMerchant && user.uploaderServers.includes(server),
                }

            case OnlineServer.FacebookShop:
                return {
                    productId: product.id,
                    isVisible: product.facebookCatalogEnabled,
                    server: server,
                    enabled: product.facebookCatalogEnabled,
                }

            case OnlineServer.Website:
                return {
                    productId: product.id,
                    isVisible: product.websiteId != null ? true : false,
                    server: server,
                    enabled: product.websiteId != null ? true : false,
                    url: product.externalProductUrl,
                }

            case OnlineServer.FacebookMarketplace:
                const productServerAccount: IProductServerAccount = find(productServerAccounts || [], { productId: product.id as any, serverAccountId: product.facebookAccountId as any });

                if (productServerAccount) {
                    return {
                        productId: product.id,
                        isVisible: productServerAccount.offerIsVisible,
                        url: productServerAccount.offerUrl,
                        visibleCheck: productServerAccount.lastVisibleCheck,
                        server: server,
                        enabled: product.facebookMarketplaceEnabled,
                        pendingEdit: productServerAccount.offerPendingEdit,
                        pendingDelete: productServerAccount.offerPendingDelete,
                        pendingUpload: productServerAccount.offerPendingUpload,
                        pendingCheck: productServerAccount.offerPendingCheck,
                        error: productServerAccount.offerError,
                        serverRequiredAction: productServerAccount.offerError,
                        isTop: false,
                        enableUploadTopped: false,
                        errorType: productServerAccount.offerErrorActionType,
                    };
                }
        }

        return {
            productId: product.id,
            isVisible: false,
            server: server,
            enabled: false,
        };
    }

    public static isPendingCheck(product: IProduct, collection: IProductCollection) {
        if (product.bazosCZ && collection.bazosCZ && product.bazosCzPendingCheck) {
            return true;
        }

        if (product.bazosSK && collection.bazosSK && product.bazosSkPendingCheck) {
            return true;
        }

        if (product.sbazar && collection.sbazar && product.sbazarPendingCheck) {
            return true;
        }
        return false;
    }

    public static isVisibleSomewhere(product: IProduct, collection: IProductCollection, productServerAccounts: IProductServerAccount[]) {
        if (product.bazosCZ && collection.bazosCZ && product.bazosCzVisible) {
            return true;
        }
        if (product.bazosSK && collection.bazosSK && product.bazosSkVisible) {
            return true;
        }
        if (product.sbazar && collection.sbazar && product.sbazarVisible) {
            return true;
        }

        if (product.facebookMarketplaceEnabled) {
            var productFbServerAccount = find(productServerAccounts, { productId: product.id as any, serverAccountId: product.facebookAccountId as any });

            if (productFbServerAccount && productFbServerAccount.offerIsVisible) {
                return true;
            }
        }

        if (product.sbazar && product.sbazarAccountId) {
            var productSbazarServerAccount = find(productServerAccounts, { productId: product.id, serverAccountId: product.sbazarAccountId });

            if (productSbazarServerAccount && productSbazarServerAccount.offerIsVisible) {
                return true;
            }
        }

        return false;
    }

    public static getVehicleStrokes(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "2T", value: VehicleStroke.TwoStroke },
            { label: "4T", value: VehicleStroke.FourStore },
        ];
        return options;
    }

    public static getSteps(product: IProduct): IWizardStep[] {
        const steps: IWizardStep[] = [];
        steps.push({ title: 'Základní údaje', type: WizardStepType.Basic })

        if (product.type === ProductType.Car) {
            if (product.vehicleType === VehicleType.Car || product.vehicleType === VehicleType.Commercial || product.vehicleType === VehicleType.Truck) {
                steps.push({ title: 'Údaje o vozidle', type: WizardStepType.VehicleDetails })
            }
            else if (product.vehicleType === VehicleType.Motorcycle || product.vehicleType === VehicleType.QuadBike) {
                steps.push({ title: "Údaje o vozidle", type: WizardStepType.VehicleMotoDetails });
            }
        }

        if (product.vehicleType === VehicleType.SpareParts && product.subcategory === ProductSubcategory.Tires) {
            steps.push({ title: 'Technické údaje', type: WizardStepType.Tires });
        }
        if (product.vehicleType === VehicleType.SpareParts &&
            (product.subcategory === ProductSubcategory.AluminumWheels || product.subcategory === ProductSubcategory.AluminumWheelsPlusTires)) {
            steps.push({ title: 'Technické údaje', type: WizardStepType.AluminiumWheels });
        }
      /* 
       * Not used, because there a not solved problem with validation and saving
       * 
       * const serversWithSettings = OnlineServerHelper.getSupportedServersCategory();
        const enabledServers = OnlineServerHelper.getEnabledServers(product);
        const containsServerWithSettings = enabledServers.some(server => serversWithSettings.includes(server));
        if (containsServerWithSettings) {
            steps.push({ title: 'Marketingové kanály', type: WizardStepType.Servers })
        }*/

        steps.push({ title: 'Zařazení a obchodní údaje', type: WizardStepType.Servers })
    
        steps.push({ title: 'Obrázky', type: WizardStepType.Images });

        for (var i = 0; i < steps.length; i++) {
            steps[i].orderNumber = i + 1;
        }
        return steps;
    }

    public static getStepByNumber(product: IProduct, orderNumber: number): IWizardStep {
        return find(Product.getSteps(product), { orderNumber: orderNumber });
    }

    public static getLastStep(steps: IWizardStep[]): IWizardStep {
        return first(orderBy(steps, 'orderNumber', ['desc']));
    }

    public static getVehicleMotYearOptions(): IOptionData[] {
        let options: IOptionData[] = [];

        for (var i = new Date().getFullYear(); i <= new Date().getFullYear() + 5; i++) {
            options.push({ label: i.toString(), value: i });
        }

        return options;
    }

    public static getVehicleMotMonthOptions(): IOptionData[] {
        let options: IOptionData[] = [];

        for (var i = 1; i <= 12; i++) {
            options.push({ label: i.toString(), value: i });
        }

        return options;
    }

    public static getConditionOptions(): IOptionData[]  {
        let options: IOptionData[] = [
            { label: this.getConditionLabel(ProductCondition.New), value: ProductCondition.New },
            { label: this.getConditionLabel(ProductCondition.Used), value: ProductCondition.Used },
        ];
        return options;
    }

    public static getConditionLabel(condition: ProductCondition): string {
        switch (condition) {

            case ProductCondition.New:
                return "Nové";

            case ProductCondition.Used:
                return "Použité";

            default:
        }
    }

    public static getPriceByCurrency(currency: Currency, product: IProduct): number {

        switch (currency) {
            case Currency.Czk:
                return product.priceCZK;

            case Currency.Eur:
                return product.priceEUR;
        }
    }

    public static onChangePriceByCurrency(currency: Currency,  value: number, onChange: (key: string, value: any) => void) {
        switch (currency) {
            case Currency.Czk:
                onChange('priceCZK', value);
                break;

            case Currency.Eur:
                onChange('priceEUR', value)
                break;

            case Currency.PLN:
                onChange('pricePLN', value)
                break;

            default:
        }
    }
}