import IOptionData from "../../Interfaces/IOptionData";
import { IServerAccount } from "../../Interfaces/IServerAccount";

export class ServerAccountHelper {

    public static getOptions(accounts: IServerAccount[]): IOptionData[] {
        const options: IOptionData[] = [];

        accounts.forEach(account => {
            options.push({ label: account.name, value: account.id });
        });
        return options;
    }
}