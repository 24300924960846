import axios from 'axios';
import React from 'react';
import { PathOperation } from '../../../../Enums/PathOperation';
import IAppIpAddress from '../../../../Interfaces/IAppIpAddress';
import IPatchObject from '../../../../Interfaces/IPatchObject';
import IProductCollection from '../../../../Interfaces/IProductCollection';
import BaseComponent from '../../../BaseComponent';
import BazosCzSettingsModal from '../Create/BazosCzSettingsModal';
import BazosSkSettingsModal from '../Create/BazosSkSettingsModal';
import SbazarSettingsModal from '../Create/SbazarSettingsModal';

interface IState {
    showSbazarSettings: boolean,
    showBazosCzSettings: boolean,
    showBazosSkSettings: boolean,
}

interface IProps {
    collectionServer: IProductCollection,
    appIps: IAppIpAddress[],
    collections: IProductCollection[]
}

export default class CollectionConnectServers extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            showSbazarSettings: false,
            showBazosCzSettings: false,
            showBazosSkSettings: false,
        }
    }

    handleUpdateCollection(value: any, key: any) {
        var object: IPatchObject[] = [];
        object.push({ op: PathOperation.replace, path: '/' + key, value: value });

        axios.patch(`/user/product-collections/${this.props.collectionServer.id}`, object);
    }

    handleShowSbazar(state: boolean) {
        this.setState({
            showSbazarSettings: state
        })
    }

    handleShowBazosCz(state: boolean) {
        this.setState({
            showBazosCzSettings: state
        })
    }

    handleShowBazosSk(state: boolean) {
        this.setState({
            showBazosSkSettings: state
        })
    }

    renderButtonBazosCz(collection: IProductCollection) {
        if (collection.bazosCZ) {
            return (
                <div>
                    <button
                        className="btn btn-block mb-1 collectionSelectServerBtn selected"
                        onClick={this.handleShowBazosCz.bind(this, true)}
                    >
                        {collection.bazosCzError ?
                            <i className="fas fa-exclamation-triangle mr-2 Blink"></i> :
                            <i className="fas fa-check mr-2" />}
                        {collection.bazosCzError && 'Opravit propojení'} Bazos.cz
                    </button>

                    <small className="text-center">
                        <p>
                            <span
                                className="link"
                                onClick={this.handleUpdateCollection.bind(this, false, 'bazosCZ')}
                            >
                                Odpojit
                            </span>
                            &nbsp;|&nbsp;
                            <span
                                className="link"
                                onClick={this.handleShowBazosCz.bind(this, true)}
                            >
                                Upravit
                            </span>
                        </p>
                    </small>
                </div>)
        }
        else {
            return (
                <button
                    className="btn btn-block collectionSelectServerBtn"
                    onClick={this.handleShowBazosCz.bind(this, true)}
                >
                    Připojit Bazos.cz
                </button>)
        }
    }

    renderButtonBazosSk(collection: IProductCollection) {
        if (collection.bazosSK) {
            return (
                <div>
                    <button
                        className="btn btn-block mb-1 collectionSelectServerBtn selected"
                        onClick={this.handleShowBazosSk.bind(this, true)}
                    >
                        {collection.bazosSkError ?
                            <i className="fas fa-exclamation-triangle mr-2 Blink"></i> :
                            <i className="fas fa-check mr-2" />}
                        {collection.bazosSkError && 'Opravit propojení'} Bazos.sk
                    </button>

                    <small className="text-center">
                        <p>
                            <span
                                className="link"
                                onClick={this.handleUpdateCollection.bind(this, false, 'bazosSK')}
                            >
                                Odpojit
                            </span>
                            &nbsp;|&nbsp;
                            <span
                                className="link"
                                onClick={this.handleShowBazosSk.bind(this, true)}

                            >
                                Upravit
                            </span>
                        </p>
                    </small>
                </div>)
        }
        else {
            return (
                <button
                    className="btn btn-block collectionSelectServerBtn"
                    onClick={this.handleShowBazosSk.bind(this, true)}
                >

                    Připojit Bazos.sk
                </button>)
        }
    }

    renderButtonSbazar(collectionServer: IProductCollection) {
        let classT = "collectionSelectServerBtn selected";

        if (collectionServer.sbazarError) {
            classT = "btn-outline-danger";
        }
        else if (collectionServer.sbazarPendingConnect) {
            classT = "btn-warning";
        }

        if (collectionServer.sbazarPendingConnect) {
            return (<button
                className={`btn btn-block mb-1 btn-warning`}
                onClick={this.handleShowSbazar.bind(this, true)}
            >
                <i className="fas fa-clock mr-2" />[Probíhá propojení]  Sbazar.cz
            </button>)
        } 

        if (collectionServer.sbazar) {
            return (
                <div>
                    <button
                        className={`btn btn-block mb-1 ${classT}`}
                        onClick={this.handleShowSbazar.bind(this, true)}
                    >
                        {collectionServer.sbazarError &&
                            <i className="fas fa-exclamation-triangle  mr-2 Blink"></i>}

                        {collectionServer.sbazarError === false && !collectionServer.sbazarPendingConnect &&
                            <i className="fas fa-check mr-2" />}
                            
                        {collectionServer.sbazarError && 'Opravit propojení'} {collectionServer.sbazarPendingConnect && '[Probíhá propojení]'}  Sbazar.cz
                    </button>

                    <small className="text-center">

                        <p>
                            <span
                                className="link"
                                onClick={this.handleUpdateCollection.bind(this, false, 'sbazar')}
                            >
                                Odpojit
                            </span>
                            {!collectionServer.sbazarError &&
                                <>
                                    &nbsp;|&nbsp;
                                    <span
                                        className="link"
                                        onClick={this.handleShowSbazar.bind(this, true)}
                                    >
                                        {collectionServer.sbazarError ? 'Opravit propojení ' : 'Upravit'}
                                    </span>
                                </>}
                        </p>
                    </small>
                </div>)
        }
        else {
            return (
                <button
                    className="btn btn-block collectionSelectServerBtn"
                    onClick={this.handleShowSbazar.bind(this, true)}
                >

                    Připojit Sbazar.cz
                </button>)
        }
    }

    render() {

        const { collectionServer, appIps, collections } = this.props;

        return (
            <>

                {this.state.showSbazarSettings ? <SbazarSettingsModal
                    collection={collectionServer}
                    onHide={this.handleShowSbazar.bind(this, false)}
                    show={this.state.showSbazarSettings}
                    appIpAddresses={appIps}
                    collections={collections}
                /> : null}

                {this.state.showBazosCzSettings ? <BazosCzSettingsModal
                    collectionServer={collectionServer}
                    onHide={this.handleShowBazosCz.bind(this, false)}
                    show={this.state.showBazosCzSettings}
                /> : null}

                {this.state.showBazosSkSettings ? <BazosSkSettingsModal
                    collectionServer={collectionServer}
                    onHide={this.handleShowBazosSk.bind(this, false)}
                    show={this.state.showBazosSkSettings}
                /> : null}


                <p>Připojte inzertní servery, na kterých plánujete inzerovat.</p>

                <div className="col-sm-12 col-md-9 col-lg-12" >
                    <div className="row">
                        <div className="col-lg-4 my-2">
                            {this.renderButtonBazosCz(collectionServer)}
                        </div>

                        <div className="col-lg-4 my-2">
                            {this.renderButtonBazosSk(collectionServer)}
                        </div>

                        <div className="col-lg-4 my-2">
                            {this.renderButtonSbazar(collectionServer)}
                        </div>
                        <div className="col-lg-4 my-2">
                            <a target="_blank" href='/integrations'>
                                <button
                                    className={`btn btn-info btn-block mb-1 }`}
                                >
                                    Přejít na další integrace
                                </button>
                            </a>              
                        </div>                 
                    </div>
                </div>
            </>
        )
    }
}