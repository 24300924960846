import React from "react"
import { VehicleType } from "../../../../Enums/VehicleType"
import IAutoImporter from "../../../../Interfaces/IAutoImporter"
import CheckBox from "../../../UI/Input/CheckBox"

interface IProps {
    autoImporter: IAutoImporter,
    onChange(value: any, type: VehicleType): void,
}

export const SautoCategories = (props: IProps) => {

    const { autoImporter, onChange } = props;

    return (
        <>
            <label className="section-title mt-0 mb-4">Kategorie k importu</label>

            <div className="mt-4">
                <div className="form-check-inline">
                    <CheckBox
                        label="Osobní"
                        checked={autoImporter.vehicleTypesToImport.includes(VehicleType.Car)}
                        onChange={(checked) => onChange(checked, VehicleType.Car)}
                    />
                </div>

                <div className="form-check-inline">
                    <CheckBox
                        label="Užitkové"
                        checked={autoImporter.vehicleTypesToImport.includes(VehicleType.Commercial)}
                        onChange={(checked) => onChange(checked, VehicleType.Commercial)}
                    />
                </div>

                <div className="form-check-inline">
                    <CheckBox
                        label="Nákladní"
                        checked={autoImporter.vehicleTypesToImport.includes(VehicleType.Truck)}
                        onChange={(checked) => onChange(checked, VehicleType.Truck)}
                    />
                </div>

                <div className="form-check-inline">
                    <CheckBox
                        label="Motorky"
                        checked={autoImporter.vehicleTypesToImport.includes(VehicleType.Motorcycle)}
                        onChange={(checked) => onChange(checked, VehicleType.Motorcycle)}
                    />
                </div>

                <div className="form-check-inline">
                    <CheckBox
                        label="Čtyřkolky"
                        checked={autoImporter.vehicleTypesToImport.includes(VehicleType.QuadBike)}
                        onChange={(checked) => onChange(checked, VehicleType.QuadBike)}
                    />
                </div>

                <div className="form-check-inline">
                    <CheckBox
                        label="Přívěsy"
                        checked={autoImporter.vehicleTypesToImport.includes(VehicleType.Trailers)}
                        onChange={(checked) => onChange(checked, VehicleType.Trailers)}
                    />
                </div>

                <div className="form-check-inline">
                    <CheckBox
                        label="Obytné"
                        checked={autoImporter.vehicleTypesToImport.includes(VehicleType.Caravan)}
                        onChange={(checked) => onChange(checked, VehicleType.Caravan)}
                    />
                </div>
            </div>
        </>)
}