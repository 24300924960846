import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import SelectBox from '../../../UI/Input/SelectBox';
import { ServerSettingAccordion } from '../../../Products/ServerSettingsAccordion';
import { find, isNil } from 'lodash';
import StringHelper from '../../../../Helpers/StringHelper';
import { BazosHelper } from '../../../../Helpers/Servers/BazosHelper';
import { IValidationFormRef } from '../../../../Interfaces/IValidationFormRef';
import IProduct from '../../../../Interfaces/IProduct';
import IInputField from '../../../../Interfaces/IInputField';
import IOptionData from '../../../../Interfaces/IOptionData';
import { ValidationHelper } from '../../../Utils/ValidationHelper';

interface IProps {
    product: IProduct,
    onChange(key: string, value: any): void,
}

export const ServerBazosCzSettingsAccordion = forwardRef<IValidationFormRef, IProps>((props, ref) => {
    const [isValidAndComplete, setIsValidAndComplete] = useState(false);

    const inputBazosCzPrice = useRef<SelectBox>(null);
    const inputBazosCzSection = useRef<SelectBox>(null);
    const inputBazosCzCategory = useRef<SelectBox>(null);

    useEffect(() => {
        checkIsValid();

        const product = props.product;
        const categories = BazosHelper.getCategories(product.bazosCzSection);

        const selectedCat = find(categories, { value: product.bazosCzCategory });
        if (!selectedCat) {
            props.onChange('bazosCzCategory', null);
        }
    }, []);

    useEffect(() => {
        checkIsValid();
    }, [props.product]);

    const checkIsValid = () => {
        const inputs = getInputs();
        const isValidAndComplete = ValidationHelper.isValid(inputs) &&
            !isNil(product.bazosCzPriceOption) && product.bazosCzPriceOption !== 0 && 
            !isNil(product.bazosCzSection) && product.bazosCzSection !== 0 &&
            !isNil(product.bazosCzCategory) && product.bazosCzCategory !== 0;

        setIsValidAndComplete(isValidAndComplete);
    };

    const getInputs = () => {
        const inputs: React.MutableRefObject<IInputField>[] = [];

        inputs.push(
            inputBazosCzPrice,
            inputBazosCzSection,
            inputBazosCzCategory
        );

        return inputs;
    };

    useImperativeHandle(ref, () => ({
        getInputs
    }));

    const product = props.product;
    const categoryText = BazosHelper.getFullCategoryText(product.bazosCzCategory, product.bazosCzSection);
    let bazosPriceOptions: IOptionData[] = [];

    if (product.priceCZK) {
        bazosPriceOptions.push({ label: `${StringHelper.separateThousands(product.priceCZK)} Kč`, value: 1 });
    }
    else {
        bazosPriceOptions.push({ label: `Pevná cena`, value: 1 });
    }

    const categories = BazosHelper.getCategories(product.bazosCzSection);

    return (
        <ServerSettingAccordion categoryText={categoryText} headingText="Bazoš.cz" isValid={isValidAndComplete}>
            <div className="row">
                <div className="col-12 col-md-4">
                    <SelectBox
                        ref={inputBazosCzPrice}
                        label="Cena"
                        options={bazosPriceOptions.concat(BazosHelper.getPriceOptions())}
                        defaultValue={"Vyberte"}
                        required={product.bazosCZ}
                        value={product.bazosCzPriceOption}
                        onChange={props.onChange.bind(this, 'bazosCzPriceOption')}
                        disabled={false}
                        onBlur={checkIsValid}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <SelectBox
                        ref={inputBazosCzSection}
                        label="Sekce"
                        options={BazosHelper.getSectionsByProduct(product)}
                        defaultValue={"Vyberte"}
                        required={product.bazosCZ}
                        value={product.bazosCzSection}
                        onChange={props.onChange.bind(this, 'bazosCzSection')}
                        disabled={product.bazosCzVisible}
                        onBlur={checkIsValid}
                    />
                </div>

                <div className="col-12 col-md-4">
                    <SelectBox
                        ref={inputBazosCzCategory}
                        label="Kategorie"
                        options={categories}
                        defaultValue={"Vyberte"}
                        required={product.bazosCZ}
                        value={product.bazosCzCategory}
                        onChange={props.onChange.bind(this, 'bazosCzCategory')}
                        disabled={product.bazosCzVisible}
                        onBlur={checkIsValid}
                    />
                </div>
            </div>

            {product.bazosCzVisible &&
                <p className="text-secondary text-center mt-2 mb-2">
                    <b>Jelikož je inzerát vystaven, zařazení nelze měnit.</b>
                </p>
            }
        </ServerSettingAccordion>
    );
});