import IFormError from '../../Interfaces/IFormError';

interface IProps {
    errors: IFormError[],
    className?: string,
}

export const FormErrorSummary = (props: IProps) => {

    if (props.errors.length === 0) {
        return null;
    }
    else {
        return <>
            <div className={`alert alert-danger mt-2 mb-0 ${props.className}`}>
                <ul className="list-unstyled m-0 p-0">
                    {props.errors.map((error: IFormError, index: number) => {
                        return (<li key={index}>Chyba: <b>{error.message}</b></li>)
                    })}
                </ul>
            </div>
        </>
    }
}