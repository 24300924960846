import React from "react";


interface IProps {
    title?: string,
    className?: string,
    children?: React.ReactChild,
}

export const SectionTitle = (props: IProps) => {
    return <label className={`section-title ${props.className || ''}`}>{props.title}{props.children}</label>;
}