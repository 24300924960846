import { orderBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import BaseComponent from '../../BaseComponent';
import { PageTitleBox } from '../../Others/PageTitleBox';
import LoadingScreen from '../../Screens/LoadingScreen';
import AccountTransactionStore from '../../Stores/AccountTransactionStore';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import { NoTransactionsCard } from '../../Transactions/NoTransactionsCard';
import { TransactionsTable } from '../../Transactions/TransactionsTable';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';

interface IState {
    loading: boolean,
    transactionIds: string[],
}

interface IProps {
    accountTransactionStore?: AccountTransactionStore,
    currentUserStore?: CurrentUserStore,
}

@inject('accountTransactionStore', 'currentUserStore')
@observer
export default class AccountTransactionsPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            transactionIds: [],
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.accountTransactionApiService.getAccountTransactions()
            .then(transactions => {
                this.setState({
                    loading: false,
                    transactionIds: transactions.map(s => s.id)
                });
            })
    }

    renderContent() {
        const user = this.props.currentUserStore.user;
        const transactions = orderBy(this.props.accountTransactionStore.getByUserId(user.id), ['date'], ['desc']);
      
        if (transactions.length === 0) {
            return <NoTransactionsCard />
        }

        return (
            <div className="card">
                <div className="card-body">
                    <TransactionsTable
                        transactions={transactions}
                    />
                </div>
            </div>)
    }

    render() {
        const loading = this.state.loading;

        return (
            <CustomDocumentTitle title="Transakce">
                <PageTitleBox title="Transakce" />

                {loading ? <LoadingScreen /> : this.renderContent()}
            </CustomDocumentTitle>
        )
    }
}