import React from 'react';
import StringHelper from '../../Helpers/StringHelper';

interface IProps {
    date: any
}

export const ShowDateTime = (props: IProps) => {

    return (
        <span dangerouslySetInnerHTML={{
            __html: StringHelper.replaceAllSpacesToNonBreakingSpace(new Date(props.date).toLocaleString())
        }}>
        </span>
    )
}
