import { IWebsite } from '../../Interfaces/IWebsite';
import { WebsiteImageControl } from './WebsiteImageControl';

interface IProps {
    website: IWebsite,
}

export const WebsiteImagesSettings = (props: IProps) => {

    const { website } = props;

    return (
        <>
            <div className="mt-3 p-3">
                <p>Vložte své logo, favicon a další obrázky, které vašemu webu dodají firemní identitu.</p>

                <div className="row mt-3">
                    <div className="col-lg-6">
                        <WebsiteImageControl
                            label="Logo (.png nebo .jpeg)"
                            accept="image/png,image/jpeg"
                            website={website}
                            clontyKey="logo"
                        />
                    </div>
             
                    <div className="col-lg-6">
                        <WebsiteImageControl
                            label="Hlavní obrázek (.png nebo .jpeg)"
                            accept="image/png,image/jpeg"
                            website={website}
                            clontyKey="mainimage"
                        />
                    </div>

                    <div className="col-lg-6 mt-3">
                        
                    </div>

                    <div className="col-lg-6 mt-3">
                        <WebsiteImageControl
                            label="Favicon (ikonka webu) .ico formát"
                            accept=".ico"
                            website={website}
                            clontyKey="favicon"
                        />
                        <br />
                        <small>Pokud máte favicon v jiném formátu, můžete jej zkonvertovat do ICO např. zde&nbsp;
                            <a target="_blank" href="https://favicon.io/favicon-converter/">https://favicon.io/favicon-converter/</a>
                        </small>
                    </div>
                </div>
            </div >
        </>
    )
}