import { inject, observer } from 'mobx-react';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { NotFound } from '../NotFound';
import ProductCollectionApiService from '../../../Services/ProductCollectionsApiService';
import IProductCollection from '../../../Interfaces/IProductCollection';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import LoadingScreen from '../../Screens/LoadingScreen';
import CreateProductCarsPage from '../../Uploader/Products/Create/Cars/CreateProductCarsPage';

interface IProps {
    productCollectionStore?: ProductCollectionStore,
}

const productCollectionApiService = new ProductCollectionApiService();

export const AddCarProductPage = inject('productCollectionStore')(observer((props: IProps) => {

    const [loading, setLoading] = useState(true);
    const [collection, setCollection] = useState<IProductCollection>(null);
    const [error, setError] = useState<AxiosError>();
    const { collectionId } = useParams();

    useEffect(() => {
        window.scroll(0, 0);

        productCollectionApiService.getCollection(collectionId)
            .then(response => {
                let collection = find(props.productCollectionStore.list, { id: collectionId });
                setCollection(collection);
            })
            .catch((err: AxiosError) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [])

    if (loading) {
        return <LoadingScreen />
    }

    if (error && error.response?.status === 404) {
        return <NotFound />
    }

    if (!collection) {
        return <NotFound />
    }

    return <CreateProductCarsPage
        collection={collection}
    />
}))