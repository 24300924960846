import React, { useState } from "react";
import IInputField from "../../../Interfaces/IInputField";
import { VehicleModel } from "../../../Enums/VehicleModel";
import { VehicleBrand } from "../../../Enums/VehicleBrand";
import SmartSelectBox from "./SmartSelectBox";
import Product from "../../../Models/Product";
import MultiSelectBox from "./MultiSelectBox";
import IOptionData from "../../../Interfaces/IOptionData";
import { SectionTitle } from "../../Others/SectionTitle";
import { find } from "lodash";

interface IProps {
    values: {
        [key: string]: number[];
    };
    onChange(data: Map<VehicleBrand, VehicleModel[]>): void,
}

export const InputCarBrandModels = React.forwardRef<IInputField, IProps>((props, ref) => {
    const { onChange } = props;

    const convertToMap = (data: { [key: string]: number[] }): Map<VehicleBrand, VehicleModel[]> => {
        const map = new Map<VehicleBrand, VehicleModel[]>();

        if (data) {
            for (const [key, value] of Object.entries(data)) {
                map.set(parseInt(key) as VehicleBrand, value as VehicleModel[]);
            }

        }
       
        return map;
    }


    let values = convertToMap(props.values);

    if (!(values instanceof Map)) {
        values = new Map<VehicleBrand, VehicleModel[]>;
    }
   

    const [form, setForm] = useState<Map<VehicleBrand, VehicleModel[]>>(new Map(values));
    const [newModels, setNewModels] = useState<VehicleModel[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<VehicleBrand | null>(null);
    let availableModelsForNew: IOptionData[] = [];

    if (selectedBrand) {
        availableModelsForNew = Product.getVehicleModelOptions(selectedBrand);
    }

    let selModels: IOptionData[] = [];
    newModels.map(model => {

        selModels.push({
            label: Product.getVehicleModelLabel(model),
            value: model,
        });
    })

    const handleBrandChange = (oldBrand: VehicleBrand, newBrand: VehicleBrand) => {

        if (!newBrand) {
            form.delete(oldBrand);
            setForm(new Map(form));
            onChange(new Map(form));
        }

        const models = form.get(oldBrand);
        if (models) {
            form.delete(oldBrand);
            form.set(newBrand, models);
            setForm(new Map(form));
            onChange(new Map(form));
        }
    };

    const handleModelChange = (brand: VehicleBrand, selectedModels: VehicleModel[]) => {

        form.set(brand, selectedModels);
        setForm(new Map(form));
        onChange(new Map(form));
    };

    const handleAdd = () => {
        if (selectedBrand) {
            const updatedData = new Map(form);
            updatedData.set(selectedBrand, newModels);
            setForm(updatedData);
            onChange(updatedData);
            // Clear the newModels state after adding
            setNewModels([]);
            // Optionally reset selectedBrand state if needed
            setSelectedBrand(null);
        }
    };

    return <>
        <div className="row">
            {Array.from(form.entries()).length > 0 && < div className="col-12 col-md-6">
                <SectionTitle title="Kompatibilní vozy" />
                <hr />

                {Array.from(form.entries()).map(([brand, models]) => {
                    const availableModels = Product.getVehicleModelOptions(brand);

                    let selModels: IOptionData[] = [];
                    models.map(model => {
                        selModels.push({
                            label: Product.getVehicleModelLabel(model),
                            value: model,
                        });
                    })

                    const brandOpt = find(Product.getVehicleBrandOptions(), { value: brand });

                    if (!brandOpt) {
                        return <></>
                    }

                    return <div key={brand} className="row">
                        <div className="col-12 col-sm-12 col-lg-4">
                            <SmartSelectBox
                                required
                                label="Značka"
                                onChange={(value) => handleBrandChange(brand, parseInt(value))}
                                value={brand}
                                options={[brandOpt]}
                                uid="vehicleBrand"
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-lg-8">
                            <MultiSelectBox
                                required
                                label="Modely"
                                onChange={(selectedOptions) =>
                                    handleModelChange(brand, selectedOptions.map(s => parseInt(s)))
                                }
                                selected={selModels}
                                options={availableModels}
                                uid="vehicleBrand"
                            />
                        </div>
                    </div>
                })}
            </div>}

            <div className="col-12 col-md-6">
                <SectionTitle title="Přidat nový komp. vůz" />
                <hr />

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <SmartSelectBox
                            label="Značka"
                            onChange={(value) => {
                                const n = parseInt(value);

                                if (Number.isNaN(n)) {
                                    setSelectedBrand(null);
                                }
                                else {
                                    setSelectedBrand(parseInt((value || null)) as VehicleBrand);
                                }
                                setNewModels([]);

                            }}
                            value={selectedBrand || ""}
                            options={Product.getVehicleBrandOptions()}
                            uid="selectBrandForModel"
                        />
                    </div>

                    {selectedBrand &&
                        <div className="col-12 col-sm-6 col-md-6 col-lg-8">
                            <MultiSelectBox
                                label="Modely"
                                selected={selModels}
                                options={availableModelsForNew}
                                uid="newVehicleModels"
                                onChange={(selectedOptions) => setNewModels(selectedOptions.map(s => parseInt(s)))}
                            />
                        </div>
                    }
                </div>

                <button
                    className="btn btn-secondary"
                    onClick={handleAdd}
                >
                    Přidat
                </button>
            </div>
        </div>
    </>
})