import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { IServerAccount } from "../../../Interfaces/IServerAccount";
import { FacebookAccountCookiesLoginModal } from "./FacebookAccountCookiesLoginModal";
import FacebookAccountLoginModal from "./FacebookAccountLoginModal";
import FacebookAccountSubmitSmsCodeModal from "./FacebookAccountSubmitSmsCodeModal";

interface IProps {
    onChangeShow(value: boolean): void,
    account: IServerAccount,
    showChooseType: boolean,
}

export const FacebookAccountChooseLoginType = (props: IProps) => {

    const { account, showChooseType, onChangeShow } = props;
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showSubmitCodeModal, setShowSubmitCodeModal] = useState(false);
    const [showCookiesLoginModal, setShowCookiesLoginModal] = useState(false);

    useEffect(() => {
        if (showCookiesLoginModal || showLoginModal) {
            onChangeShow(false);
        }
    }, [showCookiesLoginModal, showLoginModal]);
    return (
        <>
            {showLoginModal && <FacebookAccountLoginModal
                accountServer={account}
                onHide={() => setShowLoginModal(false)}
                showSubmitCode={() => setShowSubmitCodeModal(true)}
            />}

            {showSubmitCodeModal && <FacebookAccountSubmitSmsCodeModal
                accountId={account.id}
                onHide={() => setShowSubmitCodeModal(false)}
            />}

            {showCookiesLoginModal && <FacebookAccountCookiesLoginModal
                serverAccount={account}
                onHide={() => setShowCookiesLoginModal(false)}
            />}

            {showChooseType &&
                <Modal centered={true} show={true} onHide={() => onChangeShow(false)}>
                    <Modal.Header>
                        <Modal.Title>Zvolte způsob přihlášení</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Inzerobot se bude přihlašovat na váš účet za cílem správy inzerce.</p>
                        <div className="row">

                            <div className="col">
                                <button
                                    onClick={() => setShowCookiesLoginModal(true)}
                                    className="btn btn-outline-primary btn-block"
                                >
                                    Přihlášení pomocí COOKIES
                                    <br />
                                    <small>Doporučujeme</small>
                                </button>
                            </div>

                            <div className="col">
                                <button
                                    onClick={() => setShowLoginModal(true)}
                                    className="btn btn-outline-secondary btn-block"
                                >
                                    Přihlášení emailem a heslem
                                    <br />
                                    <small>(Nedoporučujeme)</small>
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>}
        </>
    )
}