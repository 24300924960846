import * as React from "react";
import { useState } from "react";

interface IProps {
    headingText: string,
    children: any,
    cardClassName?: string,
    defaultShow?: boolean,
    disableHide?: boolean,
}

export const Accordion = (props: IProps) => {

    const [show, setShow] = useState(props.defaultShow);

    return (
        <div id="accordion" className="accordion-one" role="tablist" aria-multiselectable="true">
            <div className={`card ${props.cardClassName}`}>
                <div onClick={() => setShow(!show)} className="card-header" role="tab" id="headingOne1">
                    <a className="tx-gray-800 transition collapsed">
                        {props.headingText}
                    </a>
                    {!props.disableHide && <i className="fas fa-angle-down float-right"></i>}
                </div>
                {(show || props.disableHide === true) &&
                    <div>
                        <div className="card-body">
                            {props.children}
                        </div>
                    </div>}

            </div>
        </div>)
}