import React from "react";
import IProductServerInfo from "../../../Interfaces/IProductServerInfo";
import { SectionTitle } from "../../Others/SectionTitle";
import { OnlineServerHelper } from "../../Utils/OnlineServerHelper";

interface IProps {
    info: IProductServerInfo,
}

export const ProductServerTitle = (props: IProps) => {

    const { info } = props;

    return (
        <>
            <SectionTitle
                className="mt-2"
            >
                <>
                    {OnlineServerHelper.getName(info.server)}{info.isTop && <> - <span className="text-danger">TOP</span></>}
                </>
            </SectionTitle>
        </>)
}