import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Link } from "react-router-dom";

interface IProps {
    collectionId: string;
    disabled?: boolean;
    size?: "sm" | "lg";
}

export const AddProductButton = (props: IProps) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    return (
        <DropdownButton
            id="dropdown-basic-button"
            title="Přidat inzerát"
            show={isOpen && !props.disabled}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            disabled={props.disabled}
            size={props.size}
        >
            <Dropdown.Item as={Link} to={`/kolekce/${props.collectionId}/pridat-inzerat/automotostroje`}>
                Auto, moto, stroje
            </Dropdown.Item>

            <DropdownItem as={Link} to={`/kolekce/${props.collectionId}/pridat-inzerat/ostatni`}>
                Ostatní
            </DropdownItem>
        </DropdownButton>
    );
}