import BaseComponent from './../../BaseComponent';
import React from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import IProductCollection from '../../../Interfaces/IProductCollection';
import InputTelephone from '../../UI/Input/InputTelephone';
import InputText from '../../UI/Input/InputText';
import { BazosServer } from '../../../Enums/BazosServer';

interface IState {
    formBazosSkVerify: {
        telephone: string,
        sent: boolean,
        code: string
    },
}

interface IProps {
    collection: IProductCollection,
    show: boolean
    onHide: Function
}

export default class BazosSkVerifyModal extends BaseComponent<IProps, IState> {

    private inputBazosSkTelephone = React.createRef<InputTelephone>();
    private inputBazosSkCode = React.createRef<InputText>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            formBazosSkVerify: {
                telephone: this.props.collection.bazosSkVerificationPhone,
                sent: false,
                code: ''
            },
        }

        this.inputBazosSkTelephone = React.createRef();
        this.inputBazosSkCode = React.createRef();
    }

    handleChangeBazosSkForm(key: any, value: any) {
        this.setState({
            formBazosSkVerify: {
                ...this.state.formBazosSkVerify,
                [key]: value
            }
        });
    }


    submitBazosSkVerify(collection: IProductCollection) {
        if (this.state.formBazosSkVerify.sent === false) {
            axios.post(`/user/product-collections/${collection.id}/bazos/sendverifycode`, {
                telephone: this.state.formBazosSkVerify.telephone,
                bazosServer: BazosServer.SK,
            }).then(response => {
                if (response.status === 200 && response.data.success) {
                    this.setState({
                        formBazosSkVerify: {
                            ...this.state.formBazosSkVerify,
                            sent: true
                        }
                    })
                }
            })
        }
        else {
            axios.post(`/user/product-collections/${collection.id}/bazos/submitcode`, {
                telephone: this.state.formBazosSkVerify.telephone,
                code: this.state.formBazosSkVerify.code,
                bazosServer: BazosServer.SK,
            }).then(response => {
                if (response.data.success === true) {
                    this.props.onHide();
                }
            })
        }

    }

    render() {
        const collection = this.props.collection;

        if (collection.bazosSkRequiredSignIn == false) {
            return null;
        }
        return (
            <Modal dialogClassName="modal-verify-phone" centered={true} show={this.props.show} onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Ověření čísla pro Bazoš.sk</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-md-2 mr-2 mt-2">
                        <InputTelephone
                            ref={this.inputBazosSkTelephone}
                            label="Telefonní číslo"
                            value={this.state.formBazosSkVerify.telephone}
                            required={!this.state.formBazosSkVerify.sent}
                            onChange={this.handleChangeBazosSkForm.bind(this, 'telephone')}
                            disabled={this.state.formBazosSkVerify.sent}
                        />
                    </div>
                    {this.state.formBazosSkVerify.sent === true && <div className="mb-md-2 mr-2 mt-2">
                        <InputText
                            ref={this.inputBazosSkCode}
                            label="Kód"
                            value={this.state.formBazosSkVerify.code}
                            required={this.state.formBazosSkVerify.sent}
                            onChange={this.handleChangeBazosSkForm.bind(this, 'code')}
                        />
                    </div>}

                    <button className="btn btn-primary" style={{ height: '35px' }}
                        onClick={this.submitBazosSkVerify.bind(this, collection)}>
                        {this.state.formBazosSkVerify.sent ? "Potvrdit kód" : "Poslat kód"}
                    </button>
                </Modal.Body>
            </Modal>)
    }
}