import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Currency } from '../../Enums/Currency';
import { Language } from '../../Enums/Language';
import { WebsiteType } from '../../Enums/WebsiteType';
import CurrencyHelper from '../../Helpers/CurrencyHelper';
import LanguageHelper from '../../Helpers/LanguageHelper';
import WebsiteHelper from '../../Helpers/WebsiteHelper';
import BaseComponent from '../BaseComponent';
import CheckBox from '../UI/Input/CheckBox';
import InputText from '../UI/Input/InputText';
import SelectBox from '../UI/Input/SelectBox';

interface IState {
    form: {
        name: string,
        type: WebsiteType,
        language: Language,
        currency: Currency
    },
    assignToProducts: boolean,
}

interface IProps {
    show: boolean
    onHide: Function,
}

export default class CreateWebsiteModal extends BaseComponent<IProps, IState> {

    private inputName = React.createRef<InputText>();
    private inputType = React.createRef<SelectBox>();
    private inputLanguage = React.createRef<SelectBox>();
    private inputCurrency = React.createRef<SelectBox>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            form: {
                name: '',
                type: null,
                language: null,
                currency: null
            },
            assignToProducts: true,
        }

        this.inputName = React.createRef();
        this.inputType = React.createRef();
        this.inputLanguage = React.createRef();
        this.inputCurrency = React.createRef();
    }

    handleSubmit() {
        const { name, type, language, currency } = this.state.form;
        const inputs = [this.inputName, this.inputType, this.inputLanguage, this.inputCurrency];

        const isValid = this.validateInputs(inputs);

        if (isValid) {
            this.websitesApiService.createWebsite(name, type, language, currency)
                .then(data => {
                    if (data.success) {
                        this.setState({
                            form: {
                                type: null,
                                name: '',
                                currency: null,
                                language: null,
                            },
                            assignToProducts: true,
                        })
                        this.props.onHide();
                    }
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value,
            }
        })
    }

    render() {
        const { name, type, currency, language } = this.state.form;
        const assignToProducts = this.state.assignToProducts;

        return (
            <Modal centered={true} show={this.props.show} onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Vytvoření nového webu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <InputText
                            ref={this.inputName}
                            label="Název webu"
                            maxLength={25}
                            value={name}
                            required={true}
                            onChange={(value: string) => this.handleChange(value, 'name')}
                        />

                        <SelectBox
                            ref={this.inputType}
                            label="Typ"
                            value={type}
                            defaultValue="Vyberte"
                            required
                            options={WebsiteHelper.getTypeOptions()}
                            onChange={(value: string) => this.handleChange(value, 'type')}
                        />

                        <SelectBox
                            ref={this.inputLanguage}
                            label="Jazyk"
                            value={language}
                            defaultValue="Vyberte"
                            required
                            options={LanguageHelper.getOptions()}
                            onChange={(value: string) => this.handleChange(value, 'language')}
                        />

                        <SelectBox
                            ref={this.inputCurrency}
                            label="Měna"
                            value={currency}
                            defaultValue="Vyberte"
                            required
                            options={CurrencyHelper.getOptions()}
                            onChange={(value: string) => this.handleChange(value, 'currency')}
                        />

                        <CheckBox
                            checked={assignToProducts}
                            onChange={(value: boolean) => this.setState({ assignToProducts: value })}
                            label="Přiřadit web k již vytvořeným inzerátům"
                            disabled
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" size="lg" onClick={this.handleSubmit.bind(this)}>
                        Vytvořit web
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}