import { filter, find, join, map, slice, uniqBy } from "lodash";
import React from "react";
import { IFacebookCategory } from "../../Interfaces/IFacebookCategory";
import IInputField from "../../Interfaces/IInputField";
import IOptionData from "../../Interfaces/IOptionData";
import IProduct from "../../Interfaces/IProduct";
import BaseComponent from "../BaseComponent";
import SelectBox from "../UI/Input/SelectBox";
import { ServerSettingAccordion } from "./ServerSettingsAccordion";

interface IState {
    categories: IFacebookCategory[],
    loading: boolean,
    isValid: boolean,
    selectedValues: string[],
}

interface IProps {
    product: IProduct,
    onChange(value: any, key: any): void,
}

export default class ProductFacebookCategoriesBox extends BaseComponent<IProps, IState> {

    private inputCategory1: React.RefObject<SelectBox>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            categories: [],
            loading: true,
            isValid: false,
            selectedValues: [],
        }

        this.inputCategory1 = React.createRef();
    }

    componentDidMount() {
        this.facebookApiService.getCategories()
            .then(categories => {
                const productFbCatId = this.props.product.facebookProductCategoryId;

                if (productFbCatId) {
                    let category = find(categories, { id: productFbCatId });

                    this.setState({
                        selectedValues: category.labels
                    })
                }

                this.setState({
                    categories: categories,
                    loading: false,
                })
                this.checkIsValid();
            })
    }

    componentDidUpdate() {
        this.checkIsValid();
    }

    checkIsValid() {
        const inputs = this.getInputs();
        const isValid = this.isValid(inputs);

        if (this.state.isValid !== isValid) {
            this.setState({
                isValid: isValid
            })
        }
    }

    validate() {
        const inputs = this.getInputs();
        return this.validateInputs(inputs);
    }

    getInputs() {
        const inputs: React.RefObject<IInputField>[] = [];
        inputs.push(this.inputCategory1);
        return inputs;
    }

    handleChangeSelected(value: string, index: number) {
        let selectedValues = this.state.selectedValues;

        if (selectedValues.length >= index) {
            selectedValues = selectedValues.slice(0, index);
        }
        selectedValues[index] = value;

        this.setState({
            selectedValues: selectedValues
        })

        let selectedLabel = join(selectedValues, " > ");

        var category = find(this.state.categories, { label: selectedLabel });

        if (category) {
            this.props.onChange('facebookProductCategoryId', category.id);
        }
        else if(index === 0){
            this.props.onChange('facebookProductCategoryId', null);
        }
    }

    getCategories(index: number): IOptionData[] {
        const { categories, selectedValues } = this.state;

        if (selectedValues.length < index) {
            return [];
        }

        let selectedValueForCategory = join(slice(selectedValues, 0, index), " > ");

        const categoriesFor: IFacebookCategory[] = filter(categories, category => {
            if (category.labels.length > index) {
                if (category.label.includes(selectedValueForCategory) && category.label) {
                    return true;
                }
            }
            return false;
        })

        const categoriesX: IOptionData[] = uniqBy(map(categoriesFor, category => {
            let option: IOptionData = { label: category.labels[index], value: category.labels[index] }
            return option;
        }), option => option.label);
        return categoriesX;
    }

    isComplete(): boolean {
        const { selectedValues } = this.state;

        const level = selectedValues.length;

        const categories = this.getCategoryLevels()[level];

        if (!categories) {
            return true;
        }
        return categories.length === 0;
    }

    getCategoryLevels(): IOptionData[][] {
        const categories1 = this.getCategories(0);
        const categories2 = this.getCategories(1);
        const categories3 = this.getCategories(2);
        const categories4 = this.getCategories(3);

        var levels: IOptionData[][] = [];

        levels.push(categories1);
        levels.push(categories2);
        levels.push(categories3);
        levels.push(categories4);
        return levels;
    }

    render() {
        const { isValid, selectedValues } = this.state;
        const { product } = this.props;

        const categories1 = this.getCategories(0);
        const categories2 = this.getCategories(1);
        const categories3 = this.getCategories(2);
        const categories4 = this.getCategories(3);
        const categoryText = join(selectedValues || [], " > ");

        return (
            <ServerSettingAccordion
                categoryText={categoryText}
                headingText="Facebook"
                isValid={isValid}
                isComplete={this.isComplete()}
            >
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                        <SelectBox
                            ref={this.inputCategory1}
                            defaultValue="Vyberte"
                            label="Kategorie 1"
                            onChange={(value => this.handleChangeSelected(value, 0))}
                            value={selectedValues[0]}
                            options={categories1}
                            required={!product.facebookProductCategoryId}
                        />
                    </div>

                    {categories2.length > 0 &&
                        <div className="col-12 col-md-6 col-lg-3">
                            <SelectBox
                                defaultValue="Vyberte"
                                label="Kategorie 2"
                                onChange={(value => this.handleChangeSelected(value, 1))}
                                value={selectedValues[1]}
                                options={categories2}
                                required={!product.facebookProductCategoryId}
                            />
                        </div>}


                    {categories3.length > 0 &&
                        <div className="col-12 col-md-6 col-lg-3">
                            <SelectBox
                                defaultValue="Vyberte"
                                label="Kategorie 3"
                                onChange={(value => this.handleChangeSelected(value, 2))}
                                value={selectedValues[2]}
                                options={categories3}
                                required={!product.facebookProductCategoryId}
                            />
                        </div>}

                    {categories4.length > 0 &&
                        <div className="col-12 col-md-6 col-lg-3">
                            <SelectBox
                                defaultValue="Vyberte"
                                label="Kategorie 4"
                                onChange={(value => this.handleChangeSelected(value, 3))}
                                value={selectedValues[3]}
                                options={categories4}
                                required={!product.facebookProductCategoryId}
                            />
                        </div>}
                </div>
            </ServerSettingAccordion>)
    }
}