import * as React from 'react';
import InputMask, { ReactInputMask } from 'react-input-mask';
import IInputField from '../../../Interfaces/IInputField';

interface IProps {
    required?: boolean;
    icon?: string;
    placeholder?: string;
    label: string;
    value: string;
    onChange(val: any): void,
    disabled?: boolean,
}

interface IState {
    errors: string[],
}

export default class InputTelephone extends React.Component<IProps, IState> implements IInputField{
    private input: HTMLInputElement;

    constructor(props: IProps) {
        super(props);

        this.input = null;
           
        this.state = {
            errors: [],
        };
    }
  
    focus() {
        this.input.focus();
    }

    isValid() {
        return this.getErrors().length == 0;
    }


    getErrors(value = this.props.value || '') {
        let errors = [];
        if (this.props.required && ((value || '').includes("_") || value.length==0)) {
            errors.push("Toto pole je povinné");
        }
        return errors;
    }

    validate(value = this.props.value || '') {
        this.setState({
            errors: this.getErrors(value),
        })
    }

    handleChange(e: any) {
        this.validate(e.target.value);
        this.props.onChange(e.target.value);  
    }

    renderError() {
        if (this.state.errors.length == 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        return (
            <InputMask
                inputRef={r => this.input = r}
                mask="+(999) 999 999 999"
                className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                placeholder={this.props.placeholder}
                value={this.props.value || ''}
                onChange={this.handleChange.bind(this)}
                disabled={this.props.disabled}
                data-clarity-unmask="True"
            />
        );
    }

    renderLabel() {
        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required ? <span className="tx-danger">*</span> : null}
            </label>
        );
    }

    render() {
        if (this.props.icon) {
            return this.renderWithIcon();
        }

        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }

    renderWithIcon() {
        return (
            <div>
                {this.renderLabel()}
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className={`icon ion-${this.props.icon} tx-16 lh-0 op-6`}></i></span>
                    </div>
                    {this.renderInput()}
                </div>
                {this.renderError()}
            </div>
        );
    }
}
