import React from 'react';
import { IOpeningHoursSpecification } from '../../Interfaces/OpeningHoursSpecification';
import BaseComponent from '../BaseComponent';
import SelectBox from '../UI/Input/SelectBox';
import InputTime from '../UI/Input/InputTime';
import StringHelper from '../../Helpers/StringHelper';

interface IState {

}

interface IProps {
    openingHoursSpecification: IOpeningHoursSpecification,
    onChange(value: IOpeningHoursSpecification): void,
    onDelete(): void,
}

export default class OpeningHoursRowEditor extends BaseComponent<IProps, IState> {

    private dayOfWeek: React.RefObject<SelectBox>;
    private opens: React.RefObject<InputTime>;
    private closes: React.RefObject<InputTime>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
        }

        this.dayOfWeek = React.createRef();
        this.opens = React.createRef();
        this.closes = React.createRef();
    }

    getInputs(): any {
        return [
            this.dayOfWeek,
            this.opens,
            this.closes
        ];
    }

    validate(): boolean {
        return this.validateInputs(this.getInputs());
    }

    handleChange(value: any, key: keyof IOpeningHoursSpecification) {
        const spec = this.props.openingHoursSpecification;
        spec[key] = value;
        this.props.onChange(spec);
    }

    render() {
        const { openingHoursSpecification } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-12 col-lg-3">
                        <SelectBox
                            ref={this.dayOfWeek}
                            defaultValue="Vyberte"
                            label="Den v týdnu"
                            onChange={(value) => this.handleChange(value, 'dayOfWeek')}
                            options={StringHelper.getDaysOfWeekOptions()}
                            value={openingHoursSpecification.dayOfWeek}
                            required
                        />
                    </div>

                    <div className="col-12 col-lg-3">
                        <InputTime
                            ref={this.opens}
                            label="Otevření"
                            value={openingHoursSpecification.opens}
                            onChange={(value) => this.handleChange(value, 'opens')}
                            required
                        />
                    </div>

                    <div className="col-12 col-lg-3">
                        <InputTime
                            ref={this.closes}
                            label="Zavření"
                            value={openingHoursSpecification.closes}
                            onChange={(value) => this.handleChange(value, 'closes')}
                            required
                        />
                    </div>

                    <div className="col-12 col-lg-3">
                        <button style={{ marginTop: '30px' }} onClick={this.props.onDelete.bind(this)} className="btn btn-danger">Smazat</button>
                    </div>
                </div>
            </>
        )
    }
}