import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class ContactApiService extends BaseApiService {

    readonly baseUrl: string = "contact";
    
    public send(subject: string, msg: string, email: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}`, { subject: subject, message: msg, email: email}, {})
            .then(response => {
                return response.data;
            })
    }
}