import { filter, find } from "lodash";
import React from "react";
import IInputField from "../../Interfaces/IInputField";
import IOptionData from "../../Interfaces/IOptionData";
import IProduct from "../../Interfaces/IProduct";
import BaseComponent from "../BaseComponent";
import { ServerSettingAccordion } from "./ServerSettingsAccordion";
import { IGoogleCategory } from "../../Interfaces/IGoogleCategory";
import SmartSelectBox from "../UI/Input/SmartSelectBox";

interface IState {
    categories: IGoogleCategory[],
    loading: boolean,
    isValid: boolean,
    search: string,
}

interface IProps {
    product: IProduct,
    onChange(value: any, key: any): void,
}

export default class ProductGoogleCategoriesBox extends BaseComponent<IProps, IState> {

    private inputCategory: React.RefObject<SmartSelectBox>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            categories: [],
            loading: true,
            isValid: false,
            search: ""
        }

        this.inputCategory = React.createRef();
    }

    componentDidMount() {
        this.googleApiService.getCategories()
            .then(categories => {
                const productGCatId = this.props.product.googleProductCategoryId;

                if (productGCatId) {
                    let category = find(categories, { id: productGCatId });

                  // this.props.onChange(category.label, "googleProductCategoryId")
                /*    this.setState({
                        selectedValue: category.label
                    })*/
                }

                this.setState({
                    categories: categories,
                    loading: false,
                })

                this.isValid(this.getInputs());
            })
    }

    componentDidUpdate() {
       this.checkIsValid();
    }

   
    checkIsValid() {
        const inputs = this.getInputs();
        const isValid = this.isValid(inputs);

        if (this.state.isValid !== isValid) {
            this.setState({
                isValid: isValid
            })
        }
    }

    validate() {
        const inputs = this.getInputs();
        return this.validateInputs(inputs);
    }

    getInputs() {
        const inputs: React.RefObject<IInputField>[] = [];
        inputs.push(this.inputCategory);
        return inputs;
    }

    render() {
        const { categories, search, isValid } = this.state;
        const { product } = this.props;

        var options = categories.map(c => {
            var op: IOptionData = { label: c.label, value: c.id };
            return op;
        })

        const filteredOptions = filter(options, c => {
            if ((c.label.toLowerCase().includes(search) && search.toLowerCase().length > 1) ||
                product.googleProductCategoryId?.toString() === c.value?.toString()) {
                return true;
            }
            return false;
        })

       
     //   console.log(product.googleProductCategoryId);
        const selectedOption = find(options, { value: product.googleProductCategoryId })
  

        return (
            <ServerSettingAccordion
                categoryText={selectedOption?.label}
                headingText="Google Merchant"
                isValid={isValid}
                isComplete={this.isValid(this.getInputs())}
            >
                <SmartSelectBox
                    ref={this.inputCategory}
                    label="Kategorie"
                    options={filteredOptions}
                    onChange={(value => {
                        this.props.onChange("googleProductCategoryId", value)
                    })}
                    uid=""
                    value={product.googleProductCategoryId}
                    required
                    placeholder="Začněte psát název kategorie... (min. 2 znaky)"
                    onInputChange={(value => this.setState({ search: value }))}
                />     
            </ServerSettingAccordion >)
    }
}