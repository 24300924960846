import { inject, observer } from "mobx-react"
import React from "react"
import { ProductImageHelper } from "../../Helpers/ProductImageHelper"
import IProduct from "../../Interfaces/IProduct"
import ProductImageStore from "../Stores/ProductImageStore"

interface IProps {
    product: IProduct,
    productImageStore?: ProductImageStore,
    onClick?(): void,
    className?: string,
    style?: React.CSSProperties,
}

export const MainProductImg = inject('productImageStore')(observer((props: IProps) => {

    const { product, onClick, className } = props;
    const productImage = props.productImageStore.getFirstImage(product.id);

    return (
        <img
            loading="lazy"
            className={`${props.onClick && 'clickable'} ${className}`}
            onClick={() => onClick ? onClick() : {}} src={productImage ? ProductImageHelper.getImagePath(productImage) : "https://images.inzerobot.cz/no-image.png"}
            draggable="false"
            style={{ width: '100px', opacity: productImage ? '1' : '0.1', maxHeight: 80, objectFit: 'contain' }}
        />)
}))