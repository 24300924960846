import { observable, action, makeAutoObservable } from 'mobx'
import { filter, find, findIndex, remove } from 'lodash';
import IAutoImporter from '../../Interfaces/IAutoImporter';
import { AutoImporterSourceType } from '../../Enums/AutoImporterSourceType';

export default class AutoImporterStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IAutoImporter[] = [];

    @action
    addRecords(data: any): any {
        data.forEach((record: IAutoImporter) => this.addRecord(record));
    }

    @action
    private addRecord(record: IAutoImporter) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: string[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }

    getByUserId(userId: string): IAutoImporter[] {
        return filter(this.list, importer => {
            if (importer.userId === userId) {
                return true;
            }
            return false;
        });
    }

    getBySourceType(userId: string, type: AutoImporterSourceType): IAutoImporter[] {
        return filter(this.getByUserId(userId), transaction => {
            if (transaction.sourceType === type) {
                return true;
            }
            return false;
        });
    }

    findById(id: string): IAutoImporter {
        return find(this.list, { id: id });
    }

    getByIds(ids: string[]): IAutoImporter[] {
        return filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
    }
}