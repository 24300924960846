import StringHelper from "../../Helpers/StringHelper";
import IProduct from "../../Interfaces/IProduct";
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import { MathHelper } from "../Utils/MathHelper";
import GlobalSettingsStore from "../Stores/GlobalSettingsStore";
import { inject, observer } from "mobx-react";
import { filter, round, sortBy } from "lodash";
import CurrentUserStore from "../Stores/CurrentUserStore";
import { Currency } from "../../Enums/Currency";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProductHelper } from "../../Helpers/ProductHelper";
import Product from "../../Models/Product";

interface IProps {
    product: IProduct,
    globalSettingsStore?: GlobalSettingsStore,
    currentUserStore?: CurrentUserStore,
}

export const ProductPrice = inject('globalSettingsStore', 'currentUserStore')(observer((props: IProps) => {
    const { product } = props;

    const user = props.currentUserStore.user;
    const currencyConversions = props.globalSettingsStore.settings.currencyConversions;
    const convertedPriceToBase: number = CurrencyHelper.convertForeignPriceToUserBase(user, product, currencyConversions);
    const pricePercentDiff =
        round(MathHelper.calculatePercentageDifference(ProductHelper.getPriceByCurrency(product, user.accountCurrency), convertedPriceToBase), 1);

    let prices = [
        { amount: product.priceCZK, currency: Currency.Czk },
        { amount: product.priceEUR, currency: Currency.Eur, }
    ];

    // Filter out elements with null or zero amounts
    prices = filter(prices, (item) => item.amount !== null && item.amount !== 0);

    // Sorting the prices array by the currency property, with user.accountCurrency coming first
    prices = sortBy(prices, [(obj) => obj.currency !== user.accountCurrency, 'currency']);

    return (
        <ul style={{ listStyle: 'none', fontSize: '11px' }}>
            {prices.map(p => {
                let newPrice = CurrencyHelper
                    .convertCurrency(currencyConversions, user.accountCurrency, p.currency, Product.getPriceByCurrency(user.accountCurrency, product))

                return <li
                    key={p.currency}
                    className="position-relative"
                >
                    {Math.abs(pricePercentDiff) > user.priceExchangeRateDiffPercentageWarning &&
                        p.currency !== user.accountCurrency &&
                        p.amount !== newPrice &&
                        <OverlayTrigger
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    {pricePercentDiff > 0 ?
                                        <span>Cena {CurrencyHelper.getSymbol(p.currency)} je nižší o {pricePercentDiff} %</span> :
                                        <span>
                                            Cena {CurrencyHelper.getSymbol(p.currency)} je vyšší o {Math.abs(pricePercentDiff)} %
                                        </span>
                                    }
                                    {/*  <br /><small>Kliknutím zobrazíte podrobnosti</small>*/}
                                </Tooltip>
                            }
                        >
                            <i style={{ left: '-18px', top: 3 }} className="fas fa-balance-scale position-absolute text-warning mr-2"></i>
                        </OverlayTrigger>}

                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${StringHelper.separateThousands(p.amount)}\u00A0${CurrencyHelper.getSymbol(p.currency)}`
                        }}>
                    </span>
                </li>
            })}
        </ul>
    );
}))