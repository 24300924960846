import { Currency } from "../Enums/Currency";
import BaseApiService from "./BaseApiService";

export default class CurrencyApiService extends BaseApiService {

    readonly baseUrl: string = "currency";

    public convert(from: Currency, to: Currency, amount: number): Promise<number> {
        return this.getRequest(`${this.baseUrl}`, { from: from.toString(), to: to.toString(), amount: amount.toString() })
            .then(response => {
                var result = response.data.others.get("result");
                return result;
            })
    }
}