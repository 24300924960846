import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { PageTitleBox } from '../../Others/PageTitleBox';
import LoadingScreen from '../../Screens/LoadingScreen';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import AutoimportersApiService from '../../../Services/AutoimportersApiService';
import AutoImporterStore from '../../Stores/AutoImporterStore';
import { filter } from 'lodash';
import IAutoImporter from '../../../Interfaces/IAutoImporter';
import { AutoImporterSourceType } from '../../../Enums/AutoImporterSourceType';
import { AutoImporterStateBadge } from '../../Integrations/Autoimporters/AutoImporterStateBadge';
import { Link } from 'react-router-dom';
import { ShowDateTime } from '../../Others/ShowDateTime';

interface IProps {
    autoImporterStore?: AutoImporterStore,
}

interface IState {
    loading: boolean,
}

const autoimportersApiService = new AutoimportersApiService();

export const AutoImporterCsvPage = inject('autoImporterStore')(observer((props: IProps) => {

    const [state, setState] = useState<IState>({
        loading: true,
    });

    useEffect(() => {
        const fetchData = async () => {
            window.scrollTo(0, 0);

            try {
                await autoimportersApiService.getList();
            } finally {
                setState((prev) => ({ ...prev, loading: false }));
            }
        };

        fetchData();
    }, [autoimportersApiService]);


    const loading = state.loading;
    const autoImporters = filter(props.autoImporterStore.list, (im: IAutoImporter) => {
        return im.sourceType === AutoImporterSourceType.Csv;
    });

    return (
        <CustomDocumentTitle title="CSV - importéry">
            <PageTitleBox title="CSV - importéry" />

            <div className="card mb-2">
                <div className="card-body py-2">
                    <div className="row">
                        <div className="col-6 col-sm-4 col-md-6 col-lg-8">
                            <Link to={`/autoimporter/csv/create`}>
                                <button className="btn btn-primary my-2"><i className="fas fa-plus mr-2"></i>Vytvořit importér</button>
                            </Link>
                        </div>
                        <div className="col-6 col-sm-8 col-md-6 col-lg-4">
                            <div className="btn-group float-right" role="group">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading ? (
                <LoadingScreen />
            ) : (
                <div className="card mb-2">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th>Název</th>

                                        <th>Stav importéru</th>

                                        <th>Poslední dokončený import</th>

                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {autoImporters.map(im => {

                                        return <tr key={im.id}>

                                            <td>{im.name}</td>

                                            <td>
                                                <AutoImporterStateBadge
                                                    autoImporter={im}
                                                />
                                            </td>

                                            <td>
                                                <ShowDateTime date={im.lastImportUtc} />
                                            </td>

                                            <td>
                                                <Link to={`/autoimporter/csv/${im.id}`}>
                                                    <button className={`btn btn-info btn-block`}><i className="fas fa-cog mr-1"></i>Spravovat</button>
                                                </Link>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </CustomDocumentTitle>
    );
}))