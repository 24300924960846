import { useEffect } from 'react';
import { IWebsite } from '../../Interfaces/IWebsite';
import WebsitesApiService from '../../Services/WebsitesApiService';
import Textarea from '../UI/Input/Textarea';

interface IProps {
    website: IWebsite,
    handleChange(key: string, value: any): void,
    handleChangeSettings(key: string, value: any): void,
}

const websitesApiService = new WebsitesApiService();

export const WebsiteAdvancedSettings = (props: IProps) => {

    const { website, handleChange, handleChangeSettings } = props;

    useEffect(() => {
        return () => {
            websitesApiService.cancelRequests();
        }
    }, [])


    return (
        <>
            <div className="mt-3 p-3">
                <p>Pokročilé nastavení pro ty, kteří potřebují vložit trackovací kody nebo změnit vzhled pomocí CSS.</p>

                <div className="row mt-3">
                    <div className="col-lg-6">
                        <Textarea
                            label="Hlavička HTML"
                            value={website.settings.headCode}
                            onChange={(val) => handleChangeSettings('headCode', val)}
                            rows={10}
                        />     
                    </div>

                    <div className="col-lg-6">
                        <Textarea
                            label="Patička HTML"
                            value={website.settings.footerCode}
                            onChange={(val) => handleChangeSettings('footerCode', val)}
                            rows={10}
                        />
                    </div>

                    <div className="col-lg-6">
                        <Textarea
                            label="Vlastní CSS"
                            value={website.settings.cssCode}
                            onChange={(val) => handleChangeSettings('cssCode', val)}
                            rows={10}
                        />
                    </div>
                </div>
            </div >
        </>
    )
}