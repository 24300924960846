import { filter, isNil, round, sortBy } from "lodash";
import { Currency } from "../../../Enums/Currency";
import IProduct from "../../../Interfaces/IProduct"
import Product from "../../../Models/Product";
import InputPrice from "../../UI/Input/InputPrice";
import SelectCheckBox from "../../UI/Input/SelectCheckBox";
import InputNumber from "../../UI/Input/InputNumber";
import { WarningAlert } from "../../Others/Alerts/WarningAlert";
import CurrencyHelper from "../../../Helpers/CurrencyHelper";
import IUser from "../../../Interfaces/IUser";
import { ProductHelper } from "../../../Helpers/ProductHelper";
import IGlobalSettings from "../../../Interfaces/IGlobalSettings";
import { MathHelper } from "../../Utils/MathHelper";
import InputText from "../../UI/Input/InputText";
import { CountryHelper } from "../../../Helpers/CountryHelper";
import SelectBox from "../../UI/Input/SelectBox";
import IProductCollection from "../../../Interfaces/IProductCollection";
import { useRef } from "react";
import React from "react";
import IInputField from "../../../Interfaces/IInputField";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import { SectionTitle } from "../../Others/SectionTitle";
import { AiAlert, IAiProductState } from "./AiAlert";

interface IProps {
    product: IProduct,
    user: IUser,
    settings: IGlobalSettings,
    collection: IProductCollection,
    onChange(key: string, value: any): void,
    requiredFields: string[],
    aiState: IAiProductState,
}

export const PricesFormCard = React.forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const {
        product,
        onChange,
        user,
        settings,
        collection,
        requiredFields,
        aiState } = props;

    const currencyConversions = settings.currencyConversions;
    const basePrice = ProductHelper.getPriceByCurrency(product, user.accountCurrency);
    const convertedPriceToBase: number = CurrencyHelper.convertForeignPriceToUserBase(user, product, currencyConversions);
    const pricePercentDiff = round(MathHelper
        .calculatePercentageDifference(basePrice, convertedPriceToBase), 1);

    let prices = [
        { amount: product.priceCZK, currency: Currency.Czk },
        { amount: product.priceEUR, currency: Currency.Eur, }
    ];

    // Sorting the prices array by the currency property, with user.accountCurrency coming first
    prices = sortBy(prices, [(obj) => obj.currency !== user.accountCurrency, 'currency']);

    const foreignPrices = filter(prices, p => p.currency !== user.accountCurrency);
    const isLeastOneForeignCurrencyIsNotFilled = filter(foreignPrices, p => !p.amount).length > 0;

    const priceCzkRef = useRef<InputPrice>(null);
    const priceEurRef = useRef<InputPrice>(null);
    const vatRateRef = useRef<InputNumber>(null);
    const countryRef = useRef<SelectBox>(null);
    const zipRef = useRef<InputText>(null);

    React.useImperativeHandle(ref, () => ({
        getInputs,
    }));

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        return [
            priceCzkRef,
            priceEurRef,
            vatRateRef,
            countryRef,
            zipRef,
        ]
    }

    const handleConvertCurrency = () => {
        let prices = [
            { amount: product.priceCZK, currency: Currency.Czk },
            { amount: product.priceEUR, currency: Currency.Eur, }
        ];

        let foreignPrices = filter(prices, p => p.currency !== user.accountCurrency);
        foreignPrices = filter(foreignPrices, p => !p.amount);

        foreignPrices.forEach(p => {
            let newPrice = CurrencyHelper
                .convertCurrency(settings.currencyConversions,
                    user.accountCurrency,
                    p.currency,
                    Product.getPriceByCurrency(user.accountCurrency, product)
                )

            if (user.allowSmartPriceRound) {
                newPrice = ProductHelper.roundPriceWithTolerance(newPrice, user.priceExchangeRateDiffPercentageWarning);
            }
            Product.onChangePriceByCurrency(p.currency, newPrice, onChange);
        });
    }

    return <>
        <div className="card mb-3">
            <div className="card-header">
                <SectionTitle title="CENA A MÍSTO" className="my-0" />
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-4">
                                <SelectCheckBox
                                    value={product.vatDeduction}
                                    label="Odpočet DPH"
                                    onChange={onChange.bind(this, 'vatDeduction')}
                                    labelFalse="Ne"
                                    labelTrue="Ano"
                                    defaultValue="Neuvedeno"
                                    required={requiredFields.includes("vatDeduction")}
                                    allowNull={true}
                                />
                            </div>

                            {prices.map(p => {
                                if (p.currency === Currency.Czk) {
                                    return <div key={p.currency} className="col-sm-6 col-md-6 col-lg-4">
                                        <InputPrice
                                            ref={priceCzkRef}
                                            label={`Prodejní cena${product.vatDeduction ? ' vč. DPH' : ''}`}
                                            value={product.priceCZK}
                                            required={requiredFields.includes("priceCZK")}
                                            placeholder=""
                                            onChange={onChange.bind(this, 'priceCZK')}
                                            minValue={1}
                                            readonly={Product.isPendingCheck(product, collection) && isNil(product.priceCZK) === false}
                                            onBlur={() => {
                                                if (p.currency === user.accountCurrency) {
                                                    handleConvertCurrency()
                                                }
                                            }}
                                            currency={Currency.Czk}
                                        />
                                    </div>
                                }
                                else if (p.currency === Currency.Eur) {
                                    return <div key={p.currency} className="col-sm-6 col-md-6 col-lg-4">
                                        <InputPrice
                                            ref={priceEurRef}
                                            label={`Prodejní cena${product.vatDeduction ? ' vč. DPH' : ''}`}
                                            currency={Currency.Eur}
                                            value={product.priceEUR}
                                            required={requiredFields.includes("priceEUR")}
                                            placeholder=""
                                            onChange={onChange.bind(this, 'priceEUR')}
                                            minValue={1}
                                            readonly={Product.isPendingCheck(product, collection) && isNil(product.priceEUR) === false}
                                            onBlur={() => {
                                                if (p.currency === user.accountCurrency) {
                                                    handleConvertCurrency()
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                return <></>
                            })}

                            {product.vatDeduction && <>
                                <div className="col-sm-4 col-md-4">
                                    <InputNumber
                                        ref={vatRateRef}
                                        label="Sazba DPH"
                                        value={product.vatRate}
                                        onChange={(val) => onChange('vatRate', val)}
                                        suffix="%"
                                        required
                                    />
                                </div>
                            </>}

                            {/*   <div className="col-sm-4 col-md-4">
                                <InputNumber
                                    ref={this.inputPricePLN}
                                    label="Cena zł"
                                    maxLength={60}
                                    value={product.pricePLN}
                                    placeholder=""
                                    onChange={onChange.bind(this, 'pricePLN')}
                                    minValue={1}
                                    onBlur={() => this.handleConvertCurrency()}
                                />
                            </div>*/}
                        </div>

                        {foreignPrices.map(p => {
                            let newPrice = CurrencyHelper
                                .convertCurrency(currencyConversions,
                                    user.accountCurrency,
                                    p.currency,
                                    Product.getPriceByCurrency(user.accountCurrency, product)
                                )

                            if (user.allowSmartPriceRound) {
                                newPrice = ProductHelper.roundPriceWithTolerance(newPrice, user.priceExchangeRateDiffPercentageWarning);
                            }

                            if (Math.abs(pricePercentDiff) > user.priceExchangeRateDiffPercentageWarning &&
                                p.currency !== user.accountCurrency &&
                                p.amount !== newPrice) {

                                return <WarningAlert key={p.currency}>
                                    <>
                                        <span style={{ lineHeight: '36px' }}>
                                            <i className="fas fa-exclamation-triangle text-warning mr-2"></i>
                                            {`\u00A0`}Cena {CurrencyHelper.getSymbol(p.currency)}{`\u00A0`}
                                            je {pricePercentDiff > 0 ? "nižší" : "vyšší"}
                                            {`\u00A0`}o <b className="mt-2">{Math.abs(pricePercentDiff)} %</b>
                                        </span>

                                        <button
                                            className="btn btn-outline-primary d-none d-sm-block float-sm-right mt-0"
                                            onClick={() => ProductHelper.convertFromBasePrice(user, currencyConversions, product, onChange)}
                                        >
                                            Přepočítat cenu
                                        </button>
                                        {`\u00A0`}
                                        <a
                                            className="clickable d-sm-none"
                                            style={{ color: 'rgb(0 82 255)', textDecoration: 'underline' }}
                                            onClick={() => ProductHelper.convertFromBasePrice(user, currencyConversions, product, onChange)}
                                        >
                                            Přepočítat cenu
                                        </a>
                                    </>
                                </WarningAlert>
                            }
                            else {
                                return null;
                            }
                        })}

                        {!isNaN(basePrice) && !isNil(basePrice) && basePrice !== 0 &&
                            isLeastOneForeignCurrencyIsNotFilled && <>
                                <div className="text-center">
                                    <input
                                        onClick={handleConvertCurrency}
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        value="Dopočítat ostatní"
                                    />
                                </div>
                            </>}

                        {/*!product.id && aiState !== null &&
                            <AiAlert
                                aiState={aiState}
                            />*/}
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <SelectBox
                                    ref={countryRef}
                                    label="Stát"
                                    options={CountryHelper.getCountryOptionsLocationSale()}
                                    onChange={(val) => onChange('country', val)}
                                    value={product.country}
                                    defaultValue="Vyberte"
                                    required={(product.zip || '').length > 0 || requiredFields.includes("country")}
                                />
                            </div>

                            <div className="col-12 col-sm-6">
                                <InputText
                                    ref={zipRef}
                                    label="PSČ"
                                    onChange={(val) => onChange('zip', val)}
                                    value={product.zip}
                                    allowOnlyDigits={true}
                                    length={5}
                                    required={requiredFields.includes("zip")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
})