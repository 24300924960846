import React from "react";
import { Alert } from "react-bootstrap";

interface IProps {

}

export const BazosAccountHelpAlert = (props: IProps) => {

    return (
        <Alert variant='warning'>
            <b>Pravidla:</b>
            <ul className="mt-3">
                <li>Každý bazoš profil může mít 50 inzerátů (všechny nadbytečné musí být topovány)</li>
                <li>Každý bazoš profil <b>musí mít jiný email, tel. čísla a IP adresu.</b></li>
                <li>V rámci jedné kolekce může být tel. číslo viditelné a k ověření stejné.</li>
                <li>Bazoš.cz a Bazoš.sk mohou mít stejné údaje (jedná se o oddělený inz. server)</li>
            </ul>
        </Alert>)
}