import { inject, observer } from 'mobx-react';
import { filter, find, isNil, map, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams, SetURLSearchParams } from 'react-router-dom';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import ProductStore from '../../Stores/ProductStore';
import ProductImageStore from '../../Stores/ProductImageStore';
import GlobalSettingsStore from '../../Stores/GlobalSettingsStore';
import ProductApiService from '../../../Services/ProductApiService';
import { NotFound } from '../NotFound';
import LoadingScreen from '../../Screens/LoadingScreen';
import { NotFoundScreen } from '../../Screens/NotFoundScreen';
import IProduct from '../../../Interfaces/IProduct';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { CollectionActionsBar } from '../../Uploader/Collections/Overview/CollectionActionsBar';
import BazosCzVerifyAlert from '../../Uploader/Collections/BazosCzVerifyAlert';
import BazosCzErrorAlert from '../../Uploader/Collections/BazosCzErrorAlert';
import BazosSkVerifyAlert from '../../Uploader/Collections/BazosSkVerifyAlert';
import BazosSkErrorAlert from '../../Uploader/Collections/BazosSkErrorAlert';
import SbazarErrorAlert from '../../Uploader/Collections/SbazarErrorAlert';
import { NoProductsCollection } from '../../Uploader/Collections/Overview/NoProductsCollection';
import Pagination from 'react-js-pagination';
import { PageTitleBox } from '../../Others/PageTitleBox';
import { ProductFilterTableItem } from '../../Products/ProductFilterTabIeItem';

interface IProps {
    currentUserStore?: CurrentUserStore,
    productCollectionStore?: ProductCollectionStore,
    productStore?: ProductStore,
    productImageStore?: ProductImageStore,
    globalSettingsStore?: GlobalSettingsStore,
}

const productApiService = new ProductApiService();


export const OverviewCollectionPage =
    inject('currentUserStore', 'productCollectionStore', 'productStore', 'productImageStore', 'globalSettingsStore')
        (observer((props: IProps) => {

            const { id } = useParams();

            const [searchParams, setSearchParams]: [URLSearchParams, SetURLSearchParams] = useSearchParams();
            const parsedPageId = parseInt(searchParams.get("page")) || 1;

            const [loading, setLoading] = useState(false);
            const [currentPage, setCurrentPage] = useState(parsedPageId);
            const [totalItems, setTotalItems] = useState(0);
            const [productIds, setProductIds] = useState<string[]>([]);

            const navigator = useNavigate();

            useEffect(() => {
                getPage(parsedPageId);
                return () => {
                    productApiService.cancelRequests();
                }
            }, [id, parsedPageId])

            const itemsPerPage = 50;

            const getPage = (pageNumber: number) => {
                setLoading(true);

                let skipCount = 0;

                if (pageNumber >= 2) {
                    skipCount = (pageNumber - 1) * itemsPerPage;
                }

                productApiService.getProducts('', id, null, null, null, itemsPerPage, skipCount)
                    .then(data => {
                        window.scroll(0, 0);
                        let products: IProduct[] = data.records.products.items;
                        const totalItems = data.others.get("totalCount")
                        setTotalItems(totalItems);
                        setProductIds(map(products, s => s.id));
                    })
                    .finally(() => {

                        setLoading(false);
                    })
            }

            useEffect(() => {
                getPage(currentPage);
            }, [currentPage])

            const user = props.currentUserStore.user;
            const collection = find(props.productCollectionStore.list, { id: id });

            if (!collection) {
                return <NotFound />
            }

            if (loading) {
                return <LoadingScreen />
            }
            if (isNil(collection)) {
                return <NotFoundScreen />
            }

            const products: IProduct[] = orderBy(filter(props.productStore.getByIds(productIds), p => {
                if (p.productCollectionId === collection.id) {
                    return true;
                }
                return false;
            }), ['dateCreated'], ['desc']);

            const disabledNewProduct = user.productLimitMax <= user.productsCount &&
                user.productLimitMax != -1 ||
                (collection.productsCount >= 50 && collection.unlimited === false);

            const goToPage = (n: number) => {
                if (n === currentPage) {
                    getPage(1);
                }
                else {
                    setCurrentPage(n);
                    navigator(`/nahravac/kolekce/${collection.id}/prehled?page=${n}`);
                }
            }
            return (
                <CustomDocumentTitle title={`Kolekce "${collection.name}"`}>
                    <PageTitleBox title={`Kolekce "${collection.name}"`} />

                    <CollectionActionsBar
                        collection={collection}
                        disabledNewProduct={disabledNewProduct}
                        refreshPage={() => goToPage(1)}
                    />

                    {collection.bazosCZ &&
                        <BazosCzVerifyAlert
                            collection={collection}
                        />}

                    {collection.bazosCZ &&
                        <BazosCzErrorAlert
                            collection={collection}
                        />}

                    {collection.bazosSK &&
                        <BazosSkVerifyAlert
                            collection={collection}
                        />}

                    {collection.bazosSK &&
                        <BazosSkErrorAlert
                            collection={collection}
                        />}

                    {collection.sbazar &&
                        <SbazarErrorAlert
                            collection={collection}
                        />}

                    {products.length > 0 && !loading &&
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mg-b-0">
                                        <tbody>
                                            {(products).map((product: IProduct) => {
                                                return (
                                                    <ProductFilterTableItem
                                                        key={product.id}
                                                        product={product}
                                                        //      checked={selectedProductIds.includes(product.id)}
                                                        checked={false}
                                                        onCheckedChange={(value) => { }}
                                                        showGoToCollectionButton={false}
                                                    />
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                {totalItems > itemsPerPage &&
                                    <Pagination
                                        pageRangeDisplayed={5}
                                        activePage={currentPage}
                                        itemsCountPerPage={itemsPerPage}
                                        totalItemsCount={totalItems}
                                        onChange={(n) => goToPage(n)}
                                        linkClass="page-link"
                                        itemClass="page-item"
                                        innerClass="pagination justify-content-center mt-3"
                                        hideDisabled={true}
                                        hideFirstLastPages={false}
                                    />}
                            </div>
                        </div>}

                    {products.length === 0 && !loading &&
                        <NoProductsCollection
                            disabledNewProduct={disabledNewProduct}
                            collection={collection}
                        />}
                </CustomDocumentTitle >
            )
        }))