export enum VehicleFuelType {
    Petrol = 1,
    Diesel = 2,
    LpgPetrol = 3,
    Electric = 4,
    Hybrid = 5,
    CngPetrol = 6,
    Ethanol = 7,
    Hydrogen = 8,
    Lng = 9,
}