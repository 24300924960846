import React from 'react';
import { IOpeningHoursSpecification } from '../../Interfaces/OpeningHoursSpecification';
import BaseComponent from '../BaseComponent';
import MultiRef from 'react-multi-ref';
import OpeningHoursRowEditor from './OpeningHoursRowEditor';

interface IState {

}

interface IProps {
    openingHoursSpecifications: IOpeningHoursSpecification[],
    onChange(value: any): void,
}

export default class OpeningHoursEditor extends BaseComponent<IProps, IState> {

    _records = new MultiRef();

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
        }
    }

    validate(): boolean {
        let isValid = true;

        this._records.map.forEach((item: any) => {
            const isRowValid = item.validate();

            if (isValid === true) {
                isValid = isRowValid;
            }
        });

        return isValid;
    }

    handleAdd() {
        var openingSpec: IOpeningHoursSpecification = { dayOfWeek: '', closes: '', opens: '' };
        let specs = this.props.openingHoursSpecifications;
        specs.push(openingSpec);
        this.props.onChange(specs);
    }

    handleChange(index: number, spec: IOpeningHoursSpecification) {
        let specs = this.props.openingHoursSpecifications;
        specs[index] = spec;
        this.props.onChange(specs);
    }

    handleDelete(index: number) {
        let specs = this.props.openingHoursSpecifications;
        specs.splice(index, 1);
        this.props.onChange(specs);
    }

    render() {
        const { openingHoursSpecifications } = this.props;

        return (
            <> 
                <div className="mt-4">
                    {openingHoursSpecifications.map((record, index) => {

                        return <OpeningHoursRowEditor
                            key={index}
                            ref={this._records.ref(index)}
                            onChange={(value) => this.handleChange(index, value)}
                            openingHoursSpecification={record}
                            onDelete={this.handleDelete.bind(this, index)}
                        />
                    })}
                </div>

                <button onClick={this.handleAdd.bind(this)} className="btn btn-primary mt-2 mb-4">Přidat otevírací hodiny</button>
            </>
        )
    }
}