import IAutoImporter from "../Interfaces/IAutoImporter";
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class AutoimportersApiService extends BaseApiService {

    readonly baseUrl: string = "autoimporters";

    public getAutoimporter(id: string): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public getList(): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}`, {})
            .then(response => {
                return response.data;
            })
    }

    public runImport(id: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/run`, {}, {})
            .then(response => {
                return response.data;
            })
    }


    public postAutoimporter(autoimporter: IAutoImporter): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}`, autoimporter, {})
            .then(response => {
                return response.data;
            })
    }

    public editAutoimporter(id: string, autoimporter: IAutoImporter): Promise<IResponseData> {
        return this.putRequest(`${this.baseUrl}/${id}`, autoimporter, {})
            .then(response => {
                return response.data;
            })
    }

    public getPreview(id: string, data: any): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/preview`, data, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteAutoImporter(autoImporter: IAutoImporter): Promise<IResponseData> {
        return this.deleteRequest(`${this.baseUrl}/${autoImporter.id}`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public cancelAutoImporter(autoImporter: IAutoImporter): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${autoImporter.id}/cancel`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public pauseAutoImporter(autoImporter: IAutoImporter): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${autoImporter.id}/pause`, {}, {})
            .then(response => {
                return response.data;
            })
    }
}