import { ChartData, ChartOptions } from 'chart.js';
import { Bar} from 'react-chartjs-2';
import { Currency } from '../../../Enums/Currency';
import CurrencyHelper from '../../../Helpers/CurrencyHelper';
import { IMonthlySalesReport } from '../../../Interfaces/IMonthlySalesReport';
import StringHelper from '../../../Helpers/StringHelper';

interface IProps {
    currency: Currency,
    sales: IMonthlySalesReport[],
}

export const SalesChart = (props: IProps) => {
    const { currency, sales } = props;

    const monthNames = [
        'Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen',
        'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'
    ];

    // Get the current date
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Get month returns 0-based index
    const currentYear = currentDate.getFullYear();
    // Create a Set of unique months and years from the sales data
    const salesMonths = new Set(sales.map((sale) => `${sale.year}-${sale.month}`));

    // Generate the labels and datasets based on the sales data
    const labels = [];
    const dataGrossMargin = [];
    const dataTurnover = [];

    let foundNonEmptyMonth = false; // Track if a non-empty month has been found

    for (let i = 11; i >= 0; i--) {
        let month = currentMonth - i;
        let year = currentYear;
        if (month <= 0) {
            month += 12;
            year -= 1;
        }
        const salesKey = `${year}-${month}`;

        if (salesMonths.has(salesKey)) {
            const matchingSale = sales.find((sale) => sale.month === month && sale.year === year);

            if (matchingSale.totalGrossMargin || matchingSale.totalTurnover) {
                // If either grossMargin or turnover is not empty, add the data to the chart
                labels.push(monthNames[month - 1] + ' ' + year);
                dataGrossMargin.push(matchingSale.totalGrossMargin);
                dataTurnover.push(matchingSale.totalTurnover);

                foundNonEmptyMonth = true; // Set the flag to indicate a non-empty month has been found
            } else if (foundNonEmptyMonth) {
                // If a non-empty month has been found previously, but the current month is empty,
                // add empty data to maintain the empty month spacing between non-empty months
                labels.push(monthNames[month - 1] + ' ' + year);
                dataGrossMargin.push(0);
                dataTurnover.push(0);
            }
        } else if (foundNonEmptyMonth) {
            // If a non-empty month has been found previously, but the current month is not in the sales data,
            // add empty data to maintain the empty month spacing between non-empty months
            labels.push(monthNames[month - 1] + ' ' + year);
            dataGrossMargin.push(0);
            dataTurnover.push(0);
        }
    }

    if (labels.length === 0) {
        labels.push(monthNames[currentMonth - 1] + ' ' + currentYear);
        dataGrossMargin.push(0);
        dataTurnover.push(0);
    }

    const chartData: ChartData<'bar'> = {
        labels: labels,
        datasets: [
            {
                label: 'Hrubá marže',
                data: dataGrossMargin,
                backgroundColor: ['rgba(109, 255, 99, 0.8)'],
                borderColor: ['rgba(109, 255, 99, 1)'],
                borderWidth: 1,
            },
            {
                label: 'Obrat',
                data: dataTurnover,
                backgroundColor: ['rgba(98, 110, 212, 0.8)'],
                borderColor: ['rgba(98, 110, 212, 1)'],
                borderWidth: 1,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += StringHelper.separateThousandsNoHtml(context.parsed.y)+ ` ${CurrencyHelper.getSymbol(currency)}`;
                        }
                        return label;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return StringHelper.separateThousandsNoHtml(value) + ` ${CurrencyHelper.getSymbol(currency)}`;
                    },
                },
            },
        },
    };

    return (
        <>
            <Bar data={chartData} options={options} />
        </>
    );
};