import { AxiosResponse } from "axios";
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class SbazarApiService extends BaseApiService {

    readonly baseUrl: string = "sbazar";

    public getLocations(phrase: string): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/locations`, { phrase: phrase })
            .then((response: AxiosResponse) => {
                return response.data;
            })
    }
}