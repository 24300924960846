import BaseComponent from './../../BaseComponent';
import React from 'react';
import IProductCollection from '../../../Interfaces/IProductCollection';
import BazosCzVerifyModal from './BazosCzVerifyModal';

interface IState {
    show: boolean
}

interface IProps {
    collection: IProductCollection
}

export default class BazosCzVerifyAlert extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            show: false
        }
    }

    handleShow(show: boolean) {
        this.setState({
            show: show
        })
    }

    render() {
        const collection = this.props.collection;

        if (collection.bazosCzRequiredSignIn == false) {
            return null;
        }
        return (
            <div>
                <BazosCzVerifyModal
                    collection={collection}
                    show={this.state.show}
                    onHide={this.handleShow.bind(this, false)}
                />

                <div className="alert alert-warning text-center my-2 w-100" style={{
                        border: '1px solid #ced4da'
                    }}>
                    <i className="fas fa-exclamation-triangle text-warning mr-2"></i> Bazoš.cz vyžaduje ověření tel. čísla pomocí sms
                    <button
                        className="btn btn-warning btn-sm ml-2"
                        onClick={this.handleShow.bind(this, true)}
                    >
                        Ověřit tel. číslo
                        </button>
                    </div>
            </div>
        )
    }
}