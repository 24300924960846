import * as React from 'react';
import IInputField from '../../../Interfaces/IInputField';

interface IProps {
    required?: boolean;
    label: string;
    value: boolean;
    onChange(value: boolean): void,
    labelTrue: string,
    labelFalse: string,
    defaultValue: string,
    allowNull: boolean,
}

interface IState {
    errors: string[],
}

export default class SelectCheckBox extends React.Component<IProps, IState> implements IInputField {
    private input: React.RefObject<HTMLSelectElement>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }

    focus() {
        this.input.current.focus();
    }

    getErrors(value = this.props.value) {
        let errors = [];
        /* if (this.props.validate) {
             return this.props.validate(value || '') || [];
             // errors.push(value)
         }*/
        if (this.props.required && (value === null || value === undefined)) {
            errors.push("Toto pole je povinné");
        }
        return errors;
    }

    isValid() {

        return this.getErrors().length === 0;
    }

    validate(value = this.props.value) {
        this.setState({
            errors: this.getErrors(value),
        })
    }


    handleChange(e: any) {
        let value: boolean = true;

        if (e.target.value === '' && this.props.allowNull) {
            value = null;
        }
        else if (e.target.value === '' && !this.props.allowNull) {
            return;
        }
        else if (e.target.value !== "true") {
            value = false;
        }

        this.validate(value);
        this.props.onChange(value);
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        return (
            <div>
                <select
                    ref={this.input}
                    onChange={this.handleChange.bind(this)}
                    value={this.props.value === null || this.props.value === undefined ? 'default' : this.props.value.toString()}
                    className={`form-control`}
                    data-clarity-unmask="True"
                >
                    <option
                        value={''}
                        disabled={this.props.required}>{this.props.defaultValue}
                    </option>

                    <option value="true">{this.props.labelTrue}</option>
                    <option value="false">{this.props.labelFalse}</option>
                </select>
            </div>
        );
    }

    renderLabel() {
        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required ? <span className="tx-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }
}