import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import IInputField from '../../../Interfaces/IInputField';
import AppIpAddressStore from '../../Stores/AppIpAddressStore';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import InputEmail from '../../UI/Input/InputEmail';
import InputText from '../../UI/Input/InputText';
import SelectBox from '../../UI/Input/SelectBox';
import CheckBox from '../../UI/Input/CheckBox';
import InputTelephone from '../../UI/Input/InputTelephone';
import { ValidationHelper } from '../../Utils/ValidationHelper';
import InputPassword from '../../UI/Input/InputPassword';
import { IAddSbazarAccountRequest } from '../../../Interfaces/Requests/SbazarAccounts/IAddSbazarAccountRequest';
import Swal from 'sweetalert2';
import IOptionData from '../../../Interfaces/IOptionData';
import { ISbazarLocation } from '../../../Interfaces/ISbazarLocation';
import SbazarApiService from '../../../Services/SbazarApiService';
import SbazarAccountsApiService from '../../../Services/SbazarAccountsApiService';
import { IServerAccount } from '../../../Interfaces/IServerAccount';
import { find } from 'lodash';
import { SbazarHelper } from '../../../Helpers/Servers/SbazarHelper';

interface IProps {
    showAddAccountModal: boolean,
    onAdded(account: IServerAccount): void,
    onCancel(): void,
    appIpAddressStore?: AppIpAddressStore,
    currentUserStore?: CurrentUserStore,
}

const sbazarAccountsApiService = new SbazarAccountsApiService();
const sbazarApiService = new SbazarApiService();

export const AddNewSbazarAccount = inject('appIpAddressStore', 'currentUserStore')(observer((props: IProps) => {

    const defForm: IAddSbazarAccountRequest = {
        email: '',
        name: '',
        password: '',
        minRenewIntervalDays: 0,
        contactEmail: '',
        contactPhone: '',
        showPhoneNumber: true,
        locationId: '',
        locationName: '',
        insertCurrentDateDescriptionPrefix: false,
        locationType:'',
    }

    const inputName = useRef<InputText>(null);
    const inputEmail = useRef<InputEmail>(null);
    const inputPass = useRef<InputPassword>(null);
    const inputSearchLocation = useRef<InputText>(null);
    const inputContactEmail = useRef<InputEmail>(null);
    const inputContactPhone = useRef<InputTelephone>(null);
    const inputLocation = useRef<SelectBox>(null);

    const [form, setForm] = useState<IAddSbazarAccountRequest>(defForm);

    useEffect(() => {
        return () => {
            sbazarApiService.cancelRequests();
            sbazarAccountsApiService.cancelRequests();
        }
    }, [])

    const validate = () => {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            inputName,
            inputEmail,
            inputPass,
        )

        if (!form.locationId && locations.length > 0) {
            inputs.push(
                inputLocation
            )
        }

        if (!form.locationId) {
            inputs.push(
                inputSearchLocation
            )
        }

        inputs.push(
            inputContactEmail,
            inputContactPhone,
        );

        return ValidationHelper.validateInputs(inputs);
    }

    const onChange = (key: any, value: any) => {
        setForm({
            ...form,
            [key]: value
        })
    }


    const onChangeLocation = (locationId: string) => {
        const location = find(locations, { entityId: parseInt(locationId) });

        setForm({
            ...form,
            locationId: locationId,
            locationName: location.seoName,
            locationType: location.entityType,
        })
    }

    const handleSubmitForm = () => {
        if (validate()) {
            sbazarAccountsApiService.addAccount(form)
                .then(data => {
                    if (data.success) {
                        const acc = data.records.serverAccounts.items[0];
                        props.onAdded(acc);
                        setForm(defForm);
                    }
                })  
        }
    }

    const { showAddAccountModal, onAdded, onCancel } = props;
    const [searchedLocation, setSearchedLocation] = useState<string>("");
    const [locations, setLocations] = useState<ISbazarLocation[]>([]);

    const getLocations = () => {
        if (!searchedLocation) {
            Swal.fire('Zadejte lokalitu',
                'Nejprve zadejte lokalitu, poté ji můžete vyhledat a následně vybrat ze seznamu níže.',
                'warning'
            );
            return;
        }

        Swal.fire({
            title: 'Hledám lokality...',
            didOpen: () => {
                Swal.showLoading();
            },
        })

        sbazarApiService.getLocations(searchedLocation)
            .then(data => {
                if (data.success) {
                    const locations = data.others.get("locations");
                     
                    setLocations(locations);
                    setForm({
                        ...form,
                        locationId: '',
                    })

                    if (locations.length === 0) {
                        Swal.fire('Nic nenalezeno', 'Zkuste zadat lokalitu jinak...', 'error')
                    }
                    else {
                        Swal.close();
                    }
                }
                else {
                    Swal.fire({
                        title: "Došlo k chybě",
                        text: "Opakujte akci později. Pokud problém přetrvává delší dobu, kontaktujte nás.",
                        icon: "error"
                    });
                }
                
            })
    }

    const locationOptions: IOptionData[] = [];
    locations.map((location: ISbazarLocation) => {
        locationOptions.push({ label: location.suggestFirstRow + ' - ' + location.suggestSecondRow, value: location.entityId })
    })

    if (form.locationId) {
        locationOptions.push({
            label: form.locationName,
            value: form.locationId,
        });
    }

    const selectBoxLocationsdefValue: string = SbazarHelper.getLocationDefValue(locations)

    return (
        <>
            {showAddAccountModal &&
                <Modal size="lg" centered={true} show={true} onHide={() => { }}>
                    <Modal.Header>
                        <Modal.Title>Přidání Sbazar účtu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="alert alert-info mt-2 mb-4 w-100">
                                <p>Pokud účet zatím nemáte, vytvořte si jej na <a href="https://sbazar.cz/" target="_blank">sbazar.cz</a>. Následně pokračujte dle <a target="_blank" href="/faq/sbazar">"Jak připojit nový účet"</a>.</p>
                            </div>

                            <InputText
                                ref={inputName}
                                label="Libovolné označení tohoto účtu (neveřejné)"
                                value={form.name}
                                required={true}
                                onChange={(val) => onChange('name', val)}
                            />

                            <label className="section-title mt-0 mb-4">PŘIHLAŠOVACÍ ÚDAJE</label>
                            <p>Pro nově založené účty nejprve postupujte dle <a target="_blank" href="/faq/sbazar">"Jak připojit nový účet"</a></p>
                            <div className="border p-2 mt-3 bg-light">
                                <InputEmail
                                    ref={inputEmail}
                                    label="Přihlašovací jméno (email) do sbazaru"
                                    value={form.email}
                                    required={true}
                                    placeholder=""
                                    onChange={(val) => onChange('email', val)}
                                />

                                <InputText
                                    ref={inputPass}
                                    label="Heslo k účtu (do sbazaru)"
                                    value={form.password}
                                    required={true}
                                    onChange={(val) => onChange('password', val)}
                                />
                            </div>

                            <div className="alert alert-warning mt-2 m-0 w-100">
                                <p>
                                    Budeme se na váš účet přihlašovat za účelem správy inzerátů. <b>Účet nesmí mít zapnuté dvoufázové ověření.</b>
                                </p>
                            </div>

                            <div className="mt-3">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <SelectBox
                                            label='Obnovování'
                                            options={SbazarHelper.getAutoRenewOptions()}
                                            value={form.minRenewIntervalDays}
                                            defaultValue="Po expiraci"
                                            onChange={(val) => onChange('minRenewIntervalDays', val)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {(form.minRenewIntervalDays > 0) &&
                                <p className="text-danger text-center">Obnovováním před expirací inzerátu se vystavujete blokaci tel. čísla, emailu, sbazar účtu.</p>}

                            <hr />

                            <label className="section-title mt-0 mb-4">PRODEJCE</label>
                        
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="mb-3">
                                        <InputText
                                            ref={inputSearchLocation}
                                            required={!form.locationId}
                                            label="Vyhledejte svou lokalitu "
                                            placeholder="např. Praha"
                                            value={searchedLocation}
                                            onChange={(value: string) => setSearchedLocation(value)}
                                        />

                                    </div>
                                    <button type="submit" onClick={getLocations} className="btn btn-secondary">Vyhledat</button>
                                </div>

                                <div className="col-12 col-md-6 mt-3 mt-md-0">
                                    {locations.length > 0 || form.locationId ?
                                        <SelectBox
                                            ref={inputLocation}
                                            label="Vyberte lokalitu"
                                            onChange={(val) => onChangeLocation(val)}
                                            options={locationOptions}
                                            value={form.locationId}
                                            required
                                            disabled={locationOptions.length == 0}
                                            defaultValue={selectBoxLocationsdefValue}
                                        /> : null}
                                </div>
                            </div>
                           
                            <hr />

                            <div className="row">
                                <div className="col">
                                    <InputEmail
                                        ref={inputContactEmail}
                                        label="Kontaktní email"
                                        value={form.contactEmail}
                                        required={true}
                                        placeholder=""
                                        onChange={(val) => onChange('contactEmail', val)}
                                    />
                                </div>
                                <div className="col">
                                    <InputTelephone
                                        ref={inputContactPhone}
                                        label="Kontaktní tel. číslo"
                                        value={form.contactPhone}
                                        required={true}
                                        onChange={(val) => onChange('contactPhone', val)}
                                    />
                                </div>
                            </div>

                            <CheckBox
                                checked={form.showPhoneNumber}
                                label="Zobrazovat tel. číslo"
                                onChange={(val) => onChange('showPhoneNumber', val)}
                            />

                            <CheckBox
                                checked={form.insertCurrentDateDescriptionPrefix}
                                label="Před text inzerátu vložit aktuální datum"
                                onChange={(val) => onChange('insertCurrentDateDescriptionPrefix', val)}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onCancel} variant="secondary" size="lg">
                            Zavřít
                        </Button>
                        <Button variant="primary" size="lg" onClick={handleSubmitForm}>
                            Přidat účet
                        </Button>
                    </Modal.Footer>
                </Modal>}
        </>
    )
}))