import { facebookCatalogStore } from "../App";
import IFacebookCatalog from "../Interfaces/IFacebookCatalog";
import IResponseData from "../Interfaces/IResponseData";
import ICreateFacebookCatalogRequest from "../Interfaces/Requests/FacebookCatalogs/ICreateFacebookCatalogRequest";
import BaseApiService from "./BaseApiService";

export default class FacebokCatalogsApiService extends BaseApiService {

    public getCatalogs(): Promise<IResponseData> {
        return this.getRequest('facebookcatalogs', {})
            .then(response => {
                return response.data;
            })
    }

    public reload(): Promise<IFacebookCatalog[]> {
        return this.postRequest('facebookcatalogs/reload', {}, {})
            .then(response => {
                return response.data.records.facebookCatalogs.items;
            })
    }

    public setupCron(facebookCatalogId: number): Promise<IResponseData> {
        return this.postRequest(`facebookcatalogs/${facebookCatalogId}/setup-cron`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public edit(facebookCatalogId: number, facebookCatalog: IFacebookCatalog): Promise<IResponseData> {
        return this.putRequest(`facebookcatalogs/${facebookCatalogId}`, facebookCatalog, {})
            .then(response => {
                return response.data;
            })
    }

    public createCatalog(createCatalog: ICreateFacebookCatalogRequest): Promise<IResponseData> {
        return this.postRequest('facebookcatalogs', createCatalog, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteCatalog(catalog: IFacebookCatalog): Promise<IResponseData> {
        return this.deleteRequest(`facebookcatalogs/${catalog.id}`, {}, {})
            .then(response => {
                if (response.data.success) {
                    facebookCatalogStore.removeRecords([catalog.id]);
                }
                return response.data;
            })
    }
}