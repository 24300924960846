import IProductCollection from "../../Interfaces/IProductCollection";

interface IProps {
    collection: IProductCollection
}

export const ProductCollectionWarning = (props: IProps) => {

    const { collection } = props;

    if (
        (collection.bazosCZ && (collection.bazosCzRequiredSignIn || collection.bazosCzError))
        ||
        (collection.bazosSK && (collection.bazosSkRequiredSignIn || collection.bazosSkError))
        ||
        (collection.sbazar && collection.sbazarError)
    ) {
        return <span><i className="fas fa-exclamation-triangle text-danger Blink"></i> </span>
    }
    return <></>
}