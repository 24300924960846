import { ProductLogAction } from "../../Enums/ProductLogAction";

export class ProductLogHelper {

    public static getActionLabel(action: ProductLogAction): string {

        switch (action) {

            case ProductLogAction.Check:
                return "Kontrola";

            case ProductLogAction.Delete:
                return "Smazat";

            case ProductLogAction.Upload:
                return "Nahrání";

            case ProductLogAction.Edit:
                return "Editace";

            case ProductLogAction.Renew:
                return "Prodloužení";

            case ProductLogAction.Top:
                return "Topování";
        }
    }
}