import React from "react";
import { ProformaInvoiceState } from "../../Enums/ProformaInvoiceState";
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import IProformaInvoice from "../../Interfaces/IProformaInvoice";
import ProformaInvoicesApiService from "../../Services/ProformaInvoicesApiService";
import { SeparateThousands } from "../Others/SeparateThousands";
import { ShowDate } from "../Others/ShowDate";
import { ProformaInvoiceStateBadge } from "./ProformaInvoiceStateBadge";

interface IProps {
    proforma: IProformaInvoice,
}

const proformaInvoicesApiService = new ProformaInvoicesApiService();

export const ProformaInvoiceTableRow = (props: IProps) => {
    const proforma = props.proforma;

    return (
        <tr>
            <td>
                <ShowDate date={proforma.dateCreated} />
            </td>

            <td>
                <ProformaInvoiceStateBadge proforma={proforma} />
            </td>

            <td>
                <SeparateThousands roundPrecision={2} number={proforma.totalPrice} /> {CurrencyHelper.getSymbol(proforma.currency)}
            </td>

            <td>
                <ShowDate date={proforma.dateDue} />
            </td>

            <td>
                {proforma.state !== ProformaInvoiceState.Cancelled && <a target="_blank" href={`/api/${proformaInvoicesApiService.getRelativeUrlForDownloadProforma(proforma.id)}`}>
                    <button className="btn btn-outline-primary"><i className="fas fa-file-invoice mr-1"></i> Zálohová faktura</button>
                </a>}

                {proforma.paid &&
                    <a target="_blank" href={`/api/${proformaInvoicesApiService.getRelativeUrlForDownloadInvoice(proforma.id)}`}>
                        <button className="btn btn-primary ml-2"><i className="fas fa-file-invoice-dollar mr-1"></i> Daňový doklad</button>
                    </a>}
            </td>
        </tr>
    )
}