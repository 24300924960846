import { AxiosError } from "axios";
import IProductImage from "../Interfaces/IProductImage";
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";
import { productImageStore } from './../App';

export default class ProductImageApiService extends BaseApiService {

    readonly baseUrl: string = "product-images";

    public getPreSignedUrlForUpload(): Promise<Map<string, any>> {
        return this.postRequest(`${this.baseUrl}/presigned-url-upload`, {}, {})
            .then(response => {
                return response.data.others;
            })
    }

    public createImageCallback(id: string, productId: string, width: number, height: number): Promise<IProductImage> {
        return this.postRequest(`${this.baseUrl}`, { id, productId, width, height }, {})
            .then(response => {
                return response.data.records.productImages.items[0];
            })
    }

    public delete(id: string): Promise<IResponseData> {
        return this.deleteRequest(`${this.baseUrl}/${id}`, {}, {})
            .then(response => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if (error.response && error.response.status === 404) {
                    productImageStore.removeRecords([id]);
                }
                throw error;
            })
    }

    public rotateLeft(id: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/rotate-left`, {}, {})
            .then(response => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if (error.response && error.response.status === 404) {
                    productImageStore.removeRecords([id]);
                }
                throw error;
            })
    }

    public rotateRight(id: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/rotate-right`, {}, {})
            .then(response => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if (error.response && error.response.status === 404) {
                    productImageStore.removeRecords([id]);
                }
                throw error;
            })
    }

    public changeOrder(productId: string, productImageId: string, newOrderNumber: number): Promise<IResponseData>{
        return this.postRequest(`${this.baseUrl}/change-order`, {}, { productId: productId, productImageId: productImageId.toString(), newOrderNumber: newOrderNumber.toString() })
            .then(response => {
                return response.data;
            })
    }
}