import { Link } from "react-router-dom";
import { AutoImporterSourceType } from "../../../Enums/AutoImporterSourceType";
import IAutoImporter from "../../../Interfaces/IAutoImporter"
import { AutoImporterWidgetContent } from "../../Integrations/Autoimporters/AutoImporterWidgetContent";

interface IProps {
    autoImporter: IAutoImporter
}

export const AutoImporterWidget = (props: IProps) => {
    const autoImporter = props.autoImporter;

    return (
        <div className="card mb-1">
            <div className="card-header position-relative">
                {AutoImporterWidgetHeaderContent(autoImporter)}
            </div>
            <div className="card-body">
                <div className="text-center">
                    <AutoImporterWidgetContent autoImporter={autoImporter} />
                </div>
            </div>
        </div>)
}

const AutoImporterWidgetHeaderContent = (autoImporter: IAutoImporter) => {

    switch (autoImporter.sourceType) {

        case AutoImporterSourceType.Sauto:
            return (
                <>
                    Sauto importér <Link to="/autoimporter/sauto">
                        <i className="fas fa-cog position-absolute" style={{ right: '10px', top: '15px', color: 'darkgray' }} />
                    </Link>
                </>)


        case AutoImporterSourceType.Tipcars:
            return (
                <>
                    Tipcars importér <Link to="/autoimporter/tipcars">
                        <i className="fas fa-cog position-absolute" style={{ right: '10px', top: '15px', color: 'darkgray' }} />
                    </Link>
                </>)

        case AutoImporterSourceType.AutobazarEu:
            return (
                <>
                    Autobazar.eu importér <Link to="/autoimporter/autobazareu">
                        <i className="fas fa-cog position-absolute" style={{ right: '10px', top: '15px', color: 'darkgray' }} />
                    </Link>
                </>)

        case AutoImporterSourceType.GoogleShopping:
            return (
                <>
                    Google Shopping importér <Link to="/autoimporter/google-shopping">
                        <i className="fas fa-cog position-absolute" style={{ right: '10px', top: '15px', color: 'darkgray' }} />
                    </Link>
                </>)


        case AutoImporterSourceType.Sbazar:
            return (
                <>
                    Sbazar importér <Link to={`/autoimporter/sbazar/${autoImporter.id}`}>
                        <i className="fas fa-cog position-absolute" style={{ right: '10px', top: '15px', color: 'darkgray' }} />
                    </Link>
                </>)

        case AutoImporterSourceType.Csv:
            return (
                <>
                    CSV importér <Link to={`/autoimporter/csv/${autoImporter.id}`}>
                        <i className="fas fa-cog position-absolute" style={{ right: '10px', top: '15px', color: 'darkgray' }} />
                    </Link>
                </>)
    }
}