import { ContactFormSubject } from "../Enums/ContactFormSubject";
import IOptionData from "../Interfaces/IOptionData";

export default class ContactHelper {

    public static getTypes(): IOptionData[] {
        return [
            { value: ContactFormSubject.Question, label: 'Dotaz' },
            { value: ContactFormSubject.AddIP, label: 'Dokoupení IP adresy' },
            { value: ContactFormSubject.UpgradeLicence, label: 'Navýšení licence' },
            { value: ContactFormSubject.ReportError, label: 'Nahlásit chybu' },
            { value: ContactFormSubject.OrderNewService, label: 'Objednání nové služby' },
            { value: ContactFormSubject.PaidService, label: 'Placená asistence, konzultace, doprogramování apod.' },
            { value: ContactFormSubject.Feedback, label: 'Zpětná vazba' },
        ];
    }

    public static getMessagePlaceholder(subject: ContactFormSubject): string {
        switch (subject) {

            case ContactFormSubject.Question:
                return 'Pokud se váš dotaz týká určité kolekce či inzerátu, uveďte ji.';

            case ContactFormSubject.AddIP:
                return 'Upřesněte počet IP adres. IP adresy jsou zpoplatněny dle ceníku.';

            case ContactFormSubject.UpgradeLicence:
                return 'Upřesněte výši licence na kterou chcete navýšit. Najdete ji v ceníku.';

            case ContactFormSubject.ReportError:
                return 'Popište problém, uveďte co nejvíce informací.';

            case ContactFormSubject.PaidService:
                return 'Popište, co potřebujete. Ozveme se s vám.';
        }
        return '';
    }
}