import { filter, isNil, uniq } from 'lodash';
import { OnlineServer } from '../Enums/OnlineServer';
import IAppIpAddress from '../Interfaces/IAppIpAddress';
import IOptionData from '../Interfaces/IOptionData';
import IProductCollection from '../Interfaces/IProductCollection';

export default class ProductCollectionHelper {

    public static getAssignedPhones(appIp: IAppIpAddress, collections: IProductCollection[], server: OnlineServer): string[] {
        let assignedPhones: string[] = [];

        if (isNil(appIp)) {
            return [];
        }

        let assignedCollections: IProductCollection[] = filter(collections, collection => {
            switch (server) {
                case OnlineServer.BazosCz:
                    if (collection.bazosCzAppIpAddressId === appIp.id && collection.bazosCZ)
                        return true;
                    else return false;

                case OnlineServer.BazosSk:
                    if (collection.bazosSkAppIpAddressId === appIp.id && collection.bazosSK)
                        return true;
                    else return false;

                case OnlineServer.Sbazar:
                    if (collection.sbazarAppIpAddressId === appIp.id && collection.sbazar)
                        return true;
                    else return false;
            }
            return false;
        });

        switch (server) {
            case OnlineServer.BazosCz:
                assignedPhones = uniq(assignedCollections.map(c => c.bazosCzVerificationPhone));
                break;

            case OnlineServer.BazosSk:
                assignedPhones = uniq(assignedCollections.map(c => c.bazosSkVerificationPhone));
                break;

            case OnlineServer.Sbazar:
                assignedPhones = uniq(assignedCollections.map(c => c.sbazarLoginEmail));
                break;
        }
        return assignedPhones;
    }

    public static getIpAddressOptions(appIps: IAppIpAddress[], collections: IProductCollection[], server: OnlineServer): IOptionData[] {
        let ipAddressOptions: IOptionData[] = [];
        for (var i = 0; i < appIps.length; i++) {
            let appIp: IAppIpAddress = appIps[i];
            var assignedPhones = this.getAssignedPhones(appIp, collections, server);

            ipAddressOptions.push({ label: `${appIp.ip} - ${assignedPhones.length > 0 ? 'obsazená' : 'volná'}`, value: appIp.id })
        }
        return ipAddressOptions;
    }
}