import React from "react";
import { IWebsite } from "../../Interfaces/IWebsite";

interface IProps {
    website: IWebsite
}

export const WebsiteState = (props: IProps) => {

    const website = props.website;

    if (website.pendingSetup) {
        return (<span className="text-warning"><i className="fas fa-clock mr-1"></i>Probíhá zřizování</span>);
    }
    else if (website.pendingDelete) {
        return (<span className="text-danger"><i className="fas fa-clock mr-1"></i>Čeká na smazání</span>);
    }
    else if (website.customDomainRequested) {
        return (<span className="text-warning"><i className="fas fa-clock mr-1"></i>Čeká na vyřízení</span>);
    }
    else {
        return <span className="text-success"><i className="fas fa-check mr-1"></i>Aktivní</span>
    }
}