import { Modal } from "react-bootstrap"
import InputText from "../../UI/Input/InputText";
import { useEffect, useRef, useState } from "react";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import LoadingScreen from "../../Screens/LoadingScreen";
import GoogleApiService from "../../../Services/GoogleApiService";
import { IGooglePlaceSearch } from "../../../Interfaces/Requests/GoogleMaps/IGooglePlaceSearch";
import { result } from "lodash";
import Swal from "sweetalert2";
import SelectBox from "../../UI/Input/SelectBox";
import CheckBox from "../../UI/Input/CheckBox";
import IOptionData from "../../../Interfaces/IOptionData";
import { toast } from "react-toastify";
import IUser from "../../../Interfaces/IUser";

interface IProps {
    onHide(): void,
    onSave(user: IUser): void,
}

const googleApiService = new GoogleApiService();

export const GooglePlacesFindModal = (props: IProps) => {

    useEffect(() => {
        return () => {
            googleApiService.cancelRequests();
        }
    })

    const { onHide, onSave } = props;

    const [query, setQuery] = useState("");
    const [loadDetails, setLoadDetails] = useState(true);

    const [loading, setLoading] = useState(false);
    const queryRef = useRef<InputText>(null);

    const [results, setResults] = useState<IGooglePlaceSearch[]>([]);

    const [showSearch, setShowSearch] = useState(true);

    const [placeId, setPlaceId] = useState("");
    const placeIdRef = useRef<SelectBox>();

    const resultOptions: IOptionData[] = results.map(r => {
        return { label: r.name, value: r.placeId }
    });

    const submitSearch = () => {
        const inputs = [queryRef];

        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            setLoading(true);

            googleApiService.searchPlace(query)
                .then(data => {
                    const results: IGooglePlaceSearch[] = data.others.get("results");
                    setResults(results);

                    if (result.length > 0) {
                        setShowSearch(false);
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Žádné výsledky',
                            text: 'Žádné firmy jsme nenašli. Zkontrolujte zadání',
                        });
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const submitSave = () => {
        const inputs = [placeIdRef];

        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {

            googleApiService.savePlace(placeId, loadDetails)
                .then(data => {
                    if (data.success) {
                        toast.success("Změny uloženy");
                        onSave(data.records.currentUser);
                        onHide();
                    }
                    else {
                        toast.error("Došlo k chybě. Zkuste to znovu");
                    }
                })
        }
    }

    return (
        <>
            <Modal animation={false} size="lg" centered={true} show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Google Places - Vyhledání firmy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && <LoadingScreen withCard={false} />}

                    {showSearch && !loading &&
                        <>
                            <InputText
                                ref={queryRef}
                                required
                                label="Zadejte název firmy s adresou"
                                value={query}
                                onChange={(v) => setQuery(v)}
                                placeholder="např. MojeFirma Vinohradská 23, Praha"
                            />

                            <button
                                className="btn btn-primary"
                                onClick={submitSearch}
                            >
                                Hledat firmu
                            </button>
                        </>}

                    {!showSearch && !loading &&
                        <>
                            <SelectBox
                                ref={placeIdRef}
                                label="Vyberte vaší firmu"
                                options={resultOptions}
                                onChange={(val) => setPlaceId(val)}
                                value={placeId}
                                required
                                defaultValue="Vyberte"
                            />

                            <CheckBox
                                label="Načíst informace o firmě"
                                checked={loadDetails}
                                onChange={(val) => setLoadDetails(val)}
                            />

                            <div className="btn-group mt-3">
                                <button
                                    className="btn btn-primary"
                                    onClick={submitSave}
                                >
                                    Potvrdit výběr
                                </button>

                                <button
                                    className="btn btn-outline-secondary ml-2"
                                    onClick={() => setShowSearch(true)}
                                >
                                    Hledat znovu
                                </button>
                            </div>
                        </>}
                </Modal.Body>
            </Modal>
        </>
    )
}