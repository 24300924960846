import { orderBy } from "lodash";
import React from "react"
import IProformaInvoice from "../../Interfaces/IProformaInvoice";
import { ProformaInvoiceTableRow } from "./ProformaInvoiceTableRow";

interface IProps {
    proformaInvoices: IProformaInvoice[],
    className?: string
}

export const ProformaInvoicesTable = (props: IProps) => {

    const proformaInvoices = orderBy(props.proformaInvoices, ['dateCreated'], ['desc']);

    return (
        <>
            <div className={`table-responsive ${props.className}`}>
                <table className="table table-stripped">
                    <thead>
                        <tr>
                            <th>Vystaveno</th>

                            <th>Stav</th>

                            <th>Částka</th>

                            <th>Splatnost</th>

                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {proformaInvoices.map(proforma => {
                            return <ProformaInvoiceTableRow key={proforma.id} proforma={proforma} />
                        })}
                    </tbody>
                </table>
            </div>
        </>)
}