import { inject, observer } from 'mobx-react';
import { OnlineServer } from '../../../../Enums/OnlineServer';
import { PageTitleBox } from '../../../Others/PageTitleBox';
import LoadingScreen from '../../../Screens/LoadingScreen';
import CurrentUserStore from '../../../Stores/CurrentUserStore';
import ServerAccountStore from '../../../Stores/ServerAccountStore';
import CustomDocumentTitle from '../../../Utils/CustomDocumentTitle';
import { SbazarAccountsTable } from '../../../Integrations/SbazarAccounts/SbazarAccountsTable';
import { AddNewSbazarAccount } from '../../../Integrations/SbazarAccounts/AddNewSbazarAccount';
import { useEffect, useState } from 'react';
import ServerAccountsApiService from '../../../../Services/ServerAccountsApiService';
import { IServerAccount } from '../../../../Interfaces/IServerAccount';
import { Alert } from 'react-bootstrap';

interface IProps {
    currentUserStore?: CurrentUserStore,
    serverAccountStore?: ServerAccountStore,
}

const serverAccountsApiService = new ServerAccountsApiService();

export const SbazarAccountsPage = inject('serverAccountStore', 'currentUserStore')(observer((props: IProps) => {
 
    const user = props.currentUserStore.user;
    const accounts = props.serverAccountStore.getByUserIdAndServer(user.id, OnlineServer.Sbazar);

    const [loading, setLoading] = useState(true);
    const [showCreateNewModal, setShowCreateNewModal] = useState(false);

    useEffect(() => {
        serverAccountsApiService.getList(OnlineServer.Sbazar)
            .finally(() => {
                setLoading(false);
            })

        return () => {
            serverAccountsApiService.cancelRequests();
        }
    }, [])

    const onAdded = (server: IServerAccount) => {
        setShowCreateNewModal(false);
    }

    return (
        <CustomDocumentTitle title="Sbazar účty">
            <div>
                <PageTitleBox title={"Sbazar účty"} />

                <Alert variant={`warning`}>
                    <b>Sbazar se u běžných kolekcí připojuje v nastavení kolekce. </b>Sbazar účty níže jsou použitelné pouze s typem "Neomezená kolekce".
                </Alert>

                {loading ? <LoadingScreen /> : <div className="card">
                    <div className="card-body">
                        <AddNewSbazarAccount
                            showAddAccountModal={showCreateNewModal}
                            onAdded={onAdded}
                            onCancel={() => setShowCreateNewModal(false)}
                        />

                        <button
                            onClick={() => setShowCreateNewModal(true)}
                            className="btn btn-primary mb-2"
                        >
                            Přidat účet
                        </button>

                        <SbazarAccountsTable
                            accounts={accounts}
                        />
                    </div>
                </div>}
            </div>
        </CustomDocumentTitle>
    )
}))