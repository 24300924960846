import React from "react";

interface IProps {
    enabled: boolean,
    title: string,
    isValid: boolean
}

export const DescriptionTabTitle = (props: IProps) => {

    const { enabled, isValid, title } = props;

    if (isValid == null && enabled)
        return <div style={{ whiteSpace: 'nowrap' }}><i className="bi bi-check-circle text-success mr-2"></i> <span>{title}</span></div>

    return (
        <div style={{ whiteSpace: 'nowrap' }}>
            {enabled && isValid && <i className="bi bi-check-circle text-success mr-2"></i>}
            {enabled && !isValid && <i className="bi bi-exclamation-circle text-danger mr-2"></i>}
            <span className={`${enabled && !isValid ? 'text-danger' : ''}`}>{title}</span>
            </div>)
}