import Rollbar from "rollbar";
import { currentFrontEndVersion } from '../../App';
import IUser from '../../Interfaces/IUser';

var rollbarConfig: Rollbar.Configuration = {
    accessToken: '7deec7c5039d412887804a1d665622c2',
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureIp: true,
    captureUsername: true,
    captureEmail: true,
    environment: process.env.NODE_ENV,
    ignoredMessages: ["Cannot read property 'attempts' of undefined", "aa[e] is undefined", "ra[e] is undefined", "AxiosError: Network Error"], //ignore fucking clarity errors, ignore network error axios
    enabled: process.env.NODE_ENV && process.env.NODE_ENV !== 'development',
};

var rollbar: Rollbar;

class ErrorReporter {

    constructor() {
        rollbar = new Rollbar(rollbarConfig);
    }

    setUser(user: IUser) {
        rollbar.configure({
            payload: {
                person: {
                    id: user.id,
                    email: user.email
                }
            }
        })
    }

    info(message: string) {
        var obj = { frontendVersion: currentFrontEndVersion };
        rollbar.info(message, obj);
    }

    critical(message: string) {
        var obj = { frontendVersion: currentFrontEndVersion };
        rollbar.critical(message, obj);
    }

    error(message: string) {
        var obj = { frontendVersion: currentFrontEndVersion };
        rollbar.error(message, obj);
    }

    warning(message: string) {
        var obj = { frontendVersion: currentFrontEndVersion };
        rollbar.warning(message, obj);
    }
}

export default new ErrorReporter();