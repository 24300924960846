import { observable, action, makeAutoObservable  } from 'mobx'
import { filter, find, findIndex, remove } from 'lodash';
import IFacebookCatalog from '../../Interfaces/IFacebookCatalog';

export default class FacebookCatalogStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IFacebookCatalog[] = [];

    @action
    addRecords(data: IFacebookCatalog[]) {
        data.forEach((record: IFacebookCatalog) => this.addRecord(record));
    }

    @action
    private addRecord(record: IFacebookCatalog) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: number[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }

    getById(id: number): IFacebookCatalog {
        return find(this.list, { id: id });
    }

    getByUserId(userId: string): IFacebookCatalog[] {
        return filter(this.list, catalog => {
            if (catalog.userId === userId) {
                return true;
            }
            return false;
        });
    }
}