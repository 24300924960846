import IProduct from '../../../../Interfaces/IProduct';
import BaseComponent from './../../../BaseComponent';
import React from 'react';
import SelectBox from '../../../UI/Input/SelectBox';
import IInputField from '../../../../Interfaces/IInputField';
import IProductCollection from '../../../../Interfaces/IProductCollection';
import IOptionData from '../../../../Interfaces/IOptionData';
import { filter, find, join, last, uniq } from 'lodash';
import LoadingScreen from '../../../Screens/LoadingScreen';
import sbazarCategories from '../../../../sbazarcategories.json'
import { ServerSettingAccordion } from '../../../Products/ServerSettingsAccordion';
import { ProductType } from '../../../../Enums/ProductType';
import StringHelper from '../../../../Helpers/StringHelper';

interface IState {
    categories: ISbazarCategory[],
    loading: boolean,
    selectedCategories: number[],
    categoryCount: number,
    isValid: boolean,
}

interface ISbazarCategory {
    id: number,
    name: string,
    parentId?: number,
}

interface IProps {
    product: IProduct,
    collection: IProductCollection,
    onChange: Function,
}

export default class ProductSbazarSettings extends BaseComponent<IProps, IState> {

    private inputSbazarPrice: React.RefObject<SelectBox>;
    private inputSbazarCategory: React.RefObject<SelectBox>;

    constructor(props: IProps) {
        super(props);

        this.state = ({
            categories: [],
            loading: true,
            selectedCategories: [],
            categoryCount: 1,
            isValid: false,
        })

        this.inputSbazarPrice = React.createRef();
        this.inputSbazarCategory = React.createRef();
    }

    componentDidMount() {

        let categories = sbazarCategories as ISbazarCategory[];

        if (this.props.product.type === ProductType.Car) {
            categories = filter(categories, cat => {
                if (cat.parentId || (!cat.parentId && cat.id === 1)) {
                    return true;
                }
                return false;
            });
        }
        else {
            categories = filter(categories, cat => {
                if (cat.id !== 1) {
                    return true;
                }
                return false;
            });
        }

        const product = this.props.product;
        if (product && categories.length > 0 && product.sbazarCategoryId > 0) {
            //uprava kategorie. Tzn. potreba zpetne zjistit nadkategorie
            let categoryById: ISbazarCategory = find(categories, { id: product.sbazarCategoryId });
            let selCategories = this.state.selectedCategories;
            selCategories.push(product.sbazarCategoryId);
            selCategories.push(categoryById.parentId);
            selCategories = uniq(selCategories);


            while (true) {
                let categoryById2: ISbazarCategory = find(categories, { id: last(selCategories) });

                if (categoryById2) {
                    if (categoryById2.parentId) {
                        if (selCategories.includes(categoryById2.parentId)) {
                            break;
                        }
                        selCategories.push(categoryById2.parentId);
                    }
                    else {
                        break;
                    }
                }
                else {
                    break;
                }
            }

            this.setState({
                selectedCategories: selCategories.reverse(),
                loading: false,
                categoryCount: selCategories.length,
                categories: categories,
            })
        }
        else {
            this.setState({
                loading: false,
                categories: categories,
            })
        }
        this.checkIsValid();
    }

    componentDidUpdate() {
        this.checkIsValid();
    }

    checkIsValid() {
        const inputs = this.getInputs();

        const isValid = this.isValid(inputs);

        if (this.state.isValid !== isValid) {
            this.setState({
                isValid: isValid
            })
        }
    }

    validate() {
        const inputs = this.getInputs();
        return this.validateInputs(inputs);
    }

    getInputs() {
        const inputs: React.RefObject<IInputField>[] = [];
        let collection = this.props.collection;
        let product = this.props.product;

        if (product.sbazar && (product.sbazarAccountId || collection.sbazar)) {
            inputs.push(
                this.inputSbazarPrice,
                this.inputSbazarCategory
            )
        }
        return inputs;
    }

    handleChangePrice(value: number) {
        if (value === 2) {
            this.props.onChange('sbazarPriceByAgreement', true)
        }
        else {
            this.props.onChange('sbazarPriceByAgreement', false)
        }
    }

    handleChangeCategory(index: number, value: number) {
        let selectedCategories = this.state.selectedCategories;
        var childCategory = filter(this.state.categories, { parentId: value });

        let categoryCount = this.state.categoryCount;

        if (childCategory.length > 0 && selectedCategories[index] == null) {
            categoryCount++;
        }
        else if (childCategory.length === 0) {
            this.props.onChange('sbazarCategoryId', value);
        }

        if (selectedCategories[index] != null) {
            selectedCategories = selectedCategories.splice(0, index);

            if (childCategory.length > 0) {
                categoryCount = index + 2;
            }
            else {
                categoryCount = index + 1;
            }
        }
        selectedCategories[index] = value;

        this.setState({
            selectedCategories: selectedCategories,
            categoryCount: categoryCount
        })
    }

    getCategoryOptions(parentId: number): IOptionData[] {
        let options: IOptionData[] = [];
        if (parentId) {
            filter(this.state.categories, { parentId: parentId }).forEach((category: ISbazarCategory) => {
                options.push({ label: category.name, value: category.id });
            });
        }
        else {
            filter(this.state.categories, { parentId: null }).forEach((category: ISbazarCategory) => {
                options.push({ label: category.name, value: category.id });
            });
        }
        return options;
    }

    getFullCategoryText() {
        let result: string[] = [];

        var catDatas = this.getCategoriesData();

        catDatas.forEach((data: any) => {
            var option = find(this.getCategoryOptions(data.parentId), { value: data.id });

            if(option)
            result.push(option.label);
        })

        return join(result, " > ");
    }

    getCategoriesData():any {
        let result : any = [];

        for (var i = 1; i <= this.state.categoryCount; i++) {
            let id = this.state.selectedCategories[i - 1]; //pokud tam nic neni, jeste jsem nevybral
            let categoryById = find(this.state.categories, { id: id });

            let valId = 0;
            let valParentId = 0;

            if (categoryById) {
                valId = categoryById.id;
                valParentId = categoryById.parentId;
            }

            if (!id && i > 1) { //pokud jsem jeste nevybral, co je predchozi
                let catPrev = find(this.state.categories, { id: this.state.selectedCategories[i - 2] });
                if (catPrev) {
                    valParentId = catPrev.id;
                }
            }

            result.push({ id: valId, parentId: valParentId });
        }
        return result;
    }

    renderFields() {
        const product = this.props.product;

        let sbazarPriceOptions: IOptionData[] = [
            { label: `${StringHelper.separateThousands(product.priceCZK)} Kč`, value: 1 },
            { label: "Dohodou", value: 2 }
        ]

        let sbazarPriceValue = 1;

        if (product.sbazarPriceByAgreement) {
            sbazarPriceValue = 2;
        }

        if (this.state.loading) {
            return <LoadingScreen withCard={false} />;
        }

        var categoriesBoxes: any = [];

        let i = 0;
        this.getCategoriesData().forEach((data: any) => {
            i++;
            categoriesBoxes.push(
                <div className="col-12 col-sm-6 col-md-3" key={i}>
                    <SelectBox
                        ref={this.inputSbazarCategory}
                        label={`Kategorie ${i}`}
                        options={this.getCategoryOptions(data.parentId)}
                        defaultValue={"Vyberte"}
                        required
                        value={data.id}
                        onChange={this.handleChangeCategory.bind(this, i - 1)}
                    />
                </div>
            )
        });

        return (
            <div className="row" >
                <div className="col-12 col-sm-6 col-md-3">
                    <SelectBox
                        ref={this.inputSbazarPrice}
                        label="Cena"
                        options={sbazarPriceOptions}
                        defaultValue={"Vyberte"}
                        required
                        value={sbazarPriceValue}
                        onChange={this.handleChangePrice.bind(this)}
                    />
                </div>

                {categoriesBoxes}
            </div>)
    }

    render() {
        const product = this.props.product;
        const categoryText = this.getFullCategoryText();

        return (
            <ServerSettingAccordion categoryText={categoryText} headingText="Sbazar.cz" isValid={this.state.isValid}>
                {product.sbazar ?
                    this.renderFields() : null}
            </ServerSettingAccordion>)
    }
}