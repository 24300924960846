import { IAccountTransaction } from "../Interfaces/IAccountTransaction";
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class AccountTransactionsApiService extends BaseApiService {

    public getAccountTransactions(): Promise<IAccountTransaction[]> {
        return this.getRequest('accounttransactions', {})
            .then(response => {
                return response.data.records.accountTransactions.items;
            });
    }

    public getAccountTransaction(id: string): Promise<IResponseData> {
        return this.getRequest(`accounttransactions/${id}`, {})
            .then(response => {
                return response.data;
            });
    }
}