import IInputField from '../../../Interfaces/IInputField';
import React from 'react';

interface IProps {
    label: string,
    onChange(files: FileList): void,
    multiple?: boolean,
    name?: string,
    accept?: string,
}

export const InputFile = React.forwardRef<IInputField, IProps>((props, ref) => {
    const { onChange, label, multiple, name, accept } = props;

    React.useImperativeHandle(ref, () => ({
        getInputs,
        isValid,
    }));

    const isValid = (): boolean => {
        return true;
    }

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        return [

        ]
    }

    return (
        <div>
            <label>{label}</label>
           
            <input
                type="file"
                id="file-upload"
                name={`${name || 'files'}`}
                multiple={multiple}
                className="form-control"
                onChange={(e) => {
                    var files = e.target.files;
                    onChange(files);
                }}
                accept={accept}
            />
        </div>
    );
});