export enum VehicleBrand {
    AlfaRomeo = 1,
    Audi = 2,
    BMW = 3,
    Citroen = 4,
    Dacia = 5,
    Daewoo = 6,
    Daihatsu = 7,
    Fiat = 8,
    Ford = 9,
    Honda = 10,
    Hyundai = 11,
    Chevrolet = 12,
    Chrysler = 13,
    Jaguar = 14,
    Jeep = 15,
    Kia = 16,
    Lada = 17,
    Lancia = 18,
    LandRover = 19,
    Lexus = 20,
    Mazda = 21,
    MercedesBenz = 22,
    Mini = 23,
    Mitsubishi = 24,
    Nissan = 25,
    Opel = 26,
    Peugeot = 27,
    Porsche = 28,
    Renault = 29,
    Rover = 30,
    Saab = 31,
    Seat = 32,
    Smart = 33,
    SsangYong = 34,
    Subaru = 35,
    Suzuki = 36,
    Skoda = 37,
    Tatra = 38,
    Toyota = 39,
    Trabant = 40,
    Volkswagen = 41,
    Volvo = 42,
    Abarth = 43,
    Acura = 44,
    Aixam = 45,
    Alpina = 46,
    AstonMartin = 47,
    Austin = 48,
    Bentley = 49,
    Buick = 50,
    Cadillac = 51,
    Casalini = 52,
    Cupra = 53,
    Dodge = 54,
    Ds = 55,
    Ferrari = 56,
    Gaz = 57,
    Gmc = 58,
    GreatWall = 59,
    Hummer = 60,
    Chatenet = 61,
    Infiniti = 62,
    Isuzu = 63,
    Iveco = 64,
    Jac = 65,
    Lamborghini = 66,
    Ligier = 67,
    Lincoln = 68,
    Maserati = 69,
    Maybach = 70,
    McLaren = 71,
    Mg = 72,
    Microcar = 73,
    Morgan = 74,
    Plymouth = 75,
    Pontiac = 76,
    RaytonFissore = 77,
    RollsRoyce = 78,
    Tesla = 79,
    Tvr = 80,
    Wartburg = 81,
    ZhiDou = 82,
    TVR = 83,
    UAZ = 84,
    Ultima = 85,
    Yugo = 86,
    Zastava = 87,
    Tavria = 88,
    Tata = 89,
    Santana = 90,
    Saturn = 91,
    Scion = 92,
    Shuanghuan = 93,
    Proton = 94,
    PolskiFiat = 95,
    Polestar = 96,
    Pagani = 97,
    Oltcit = 98,
    Oldsmobile = 99,
    MTX = 100,
    Moskvic = 101,
    MG = 102,
    Mercury = 103,
    MCC = 104,
    Maruti = 105,
    MartinMotors = 106,
    Marcos = 107,
    Lotus = 108,
    Koenigsegg = 109,
    Kaipan = 110,
    JDM = 111,
    JAC = 112,
    Italcar = 113,
    Changhe = 114,
    Hurtan = 115,
    Honker = 116,
    Holden = 117,
    Grecav = 118,
    Gordon = 119,
    Gonow = 120,
    GMC = 121,
    Fisker = 122,
    Eagle = 123,
    DS = 124,
    DR = 125,
    DAELIM = 126,
    Caterham = 127,
    Bugatti = 128,
    Bitter = 129,
    Bellier = 130,
    Auverland = 131,
    AustroDaimler = 132,
    AustroFiat = 133,
    ARO = 134,
    Dongfeng = 507,
}