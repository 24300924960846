import { observer } from "mobx-react";
import React from "react"
import { IServerAccount } from "../../../Interfaces/IServerAccount";
import { FacebookAccountRow } from "./FacebookAccountTableRow";

interface IProps {
    accounts: IServerAccount[],
}

export const FacebookAccountsTable = observer((props: IProps) => {

    const accounts = props.accounts;

    return (
        <div className="table-responsive">
            <table className="table table-stripped">
                <thead>
                    <tr>
                        <th>Název</th>

                        <th>E-mail</th>

                        <th>Stav</th>

                        <th>Počet inzerátů</th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {accounts.map(account => {
                        return <FacebookAccountRow key={account.id} account={account} />
                    })}
                </tbody>
            </table>
        </div>)
})