import { ProformaInvoiceState } from "../../Enums/ProformaInvoiceState";
import IProformaInvoice from "../../Interfaces/IProformaInvoice"

interface IProps {
    proforma: IProformaInvoice,
}

export const ProformaInvoiceStateBadge = (props: IProps) => {

    const proforma = props.proforma;

    if (proforma.paid) {
        return <span className="badge badge-success">Zaplaceno</span>
    }
    else if (proforma.state === ProformaInvoiceState.ToPay) {
        return <span className="badge badge-warning">Čekající k úhradě</span>
    }
    else if (proforma.state === ProformaInvoiceState.Cancelled) {
        return <span className="badge badge-danger">Stornována</span>
    }
    return null; 
}