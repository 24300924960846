import IAutoImporter from "../../../../Interfaces/IAutoImporter"
import { AutoImporterVariables } from "../AutoImporterVariables"

interface IProps {
    children?: any,
    autoImporter: IAutoImporter
}

export const AutoImporterVehicleTypeCard = (props: IProps) => {

    return (
        <div className="card mb-2">
            <div className="card-body">
                <p>Nadefinujte, jakým způsobem se budou vytvářet popisky na různé inz. servery. Obsah můžete také nastavit dle typu vozidla.</p>

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-7">
                        {props.children}
                    </div>

                    <div className="col-12 col-md-6 col-lg-5 mt-4 mt-md-0">
                        <label className="section-title mt-0 mb-4">Proměnné</label>

                        <p>
                            <b>V nadpisu a popisu můžete použít následující proměnné.</b>
                            &nbsp;Pro zobrazení ilustrační hodnoty najeďte na proměnnou. Kliknutím ji zkopírujete.
                        </p>

                        <hr />
                        <AutoImporterVariables sourceType={props.autoImporter.sourceType} />
                    </div>
                </div>
            </div>
        </div>
    )
}