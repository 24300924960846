import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { PageTitleBox } from '../../Others/PageTitleBox';
import { SectionTitle } from '../../Others/SectionTitle';
import { ExtraChargePricing } from '../../Pricing/ExtraChargePricing';
import { StandardPricing } from '../../Pricing/StandardPricing';
import { StandardPricingCalc } from '../../Pricing/StandardPricingCalc';
import { TermsAccordion } from '../../Pricing/TermsAccordion';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import { Accordion } from '../../UI/Elements/Accordion';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';

interface IProps {
    currentUserStore?: CurrentUserStore
}

export const PricingStandardPage = inject('currentUserStore')(observer((props: IProps) => {
    const user = props.currentUserStore.user;
    const [currency, setCurrency] = useState(user.accountCurrency);

    return (
        <CustomDocumentTitle title="Ceník - Ostatní prodejci">
            <div>
                <PageTitleBox title={`Ceník - Ostatní prodejci`} />

                <div className="card">
                    <div className="card-body">
                       
                        <Accordion
                            cardClassName="my-1"
                            defaultShow={true}
                            headingText="Kalkulačka"
                            disableHide
                        >
                            <StandardPricingCalc
                                currency={currency}
                                onChangeCurrency={(currency) => setCurrency(currency)}
                                defaultIpsCount={1}
                                defaultProductLimit={user.productLimitMax}
                            />
                        </Accordion>

                        <Accordion
                            cardClassName="my-1"
                            defaultShow={false}
                            headingText="Ceník licence"
                        >
                            <StandardPricing currency={currency} />
                        </Accordion>

                        <Accordion
                            cardClassName="my-1"
                            defaultShow={false}
                            headingText="Co je předmětem licence"
                        >
                            <ul>
                                <li>1x IP</li>
                            </ul>

                            <SectionTitle title="Exporty" />

                            <ul>
                                <li>Facebook produktový feed</li>
                                <li>Bazoš.cz</li>
                                <li>Bazoš.sk</li>
                                <li>Sbazar.cz</li>
                                <li>Exportní feed</li>
                            </ul>
                        </Accordion>

                        <Accordion
                            defaultShow={false}
                            headingText="Příplatkové služby"
                            cardClassName="my-1"
                        >
                            <ExtraChargePricing
                                currency={currency}
                            />
                        </Accordion>

                        <TermsAccordion />
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    )
}))