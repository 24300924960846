import React from "react"
import IProduct from "../../../Interfaces/IProduct"
import { InfoAlert } from "../../Others/Alerts/InfoAlert";
import { SuccessAlert } from "../../Others/Alerts/SuccessAlert";
import { NotSetupedAlert } from "./NotSetupedAlert";

interface IProps {
    product: IProduct,
}

export const FbShopTabContent = (props: IProps) => {

    const { product } = props;

    return (
        <>
            {product.facebookCatalogEnabled && <SuccessAlert text="Nastaveno" />}
            {!product.facebookCatalogEnabled && <NotSetupedAlert />}

            <InfoAlert text="Export je realizován prostřednictvím datového feedu. Facebook si stahuje aktualizace dle nastavení na straně Facebooku." />
        </>)
}