import React from "react";
import { ProductLogType } from "../../../Enums/ProductLogType";
import IProductLog from "../../../Interfaces/IProductLog"

interface IProps {
    productLog: IProductLog,
}

export const ProductLogIcon = (props: IProps) => {

    const { productLog } = props;

    if (productLog.type === ProductLogType.Info && !productLog.success) {
        return <>
            <i className="fas fa-info-circle fa-2x text-info"></i>
        </>
    }
    else if (productLog.type === ProductLogType.Warning) {
        return <i className="fas fa-exclamation-triangle fa-2x text-warning"></i>
    }
    else if (productLog.success === true) {
        return <>
            <i className="far fa-check-circle fa-2x text-success"></i>
        </>
    }
    else {
        return <i className="fas fa-exclamation-circle fa-2x text-danger"></i>
    }
}