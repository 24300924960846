import { Currency } from "../../Enums/Currency";
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import IUser from "../../Interfaces/IUser";
import { SectionTitle } from "../Others/SectionTitle";

interface IProps {
    user: IUser
}

export const StandingOrderInstructions = (props: IProps) => {
    const { user } = props;

    let x = (new Date(user.dateExpirationUploader));
    x.setDate(x.getDate() - 7);

    return <>
        <div className="alert alert-info">
            <SectionTitle title="Trvalý příkaz" className="mt-2" />

            <div className="alert-body">
                <hr />
                <p>Instrukce pro nastavení trvalého příkazu.</p>

                <ul>
                    <li>Částka: <b>{user.monthlyLicencePrice + user.monthlyIpsPrice} {CurrencyHelper.getLabel(user.accountCurrency)}</b></li>
                    <li>Datum platby: <b>{x.getDate()}. den v měsíci</b></li>

                    {user.accountCurrency === Currency.Czk &&
                        <>
                            <li>Účet: <b>2901056991/2010</b></li>
                        </>
                    }

                    {user.accountCurrency === Currency.Eur &&
                        <>
                            <li>Účet: <b>2001807243 / 2010</b></li>
                            <li>IBAN: <b>CZ80 2010 0000 0020 0180 7243</b></li>
                            <li>BIC/SWIFT: <b>FIOBCZPPXXX</b></li>
                        </>
                    }

                    <li>Měna: <b>{CurrencyHelper.getLabel(user.accountCurrency)}</b></li>
                    <li>VS: <b>{user.vs}</b></li>
                </ul>
            </div>
        </div>
    </>
}