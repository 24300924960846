import { useEffect } from "react";
import IAutoImporter from "../../../Interfaces/IAutoImporter"
import AutoimportersApiService from "../../../Services/AutoimportersApiService";
import CheckBox from "../../UI/Input/CheckBox"

interface IProps {
    autoImporter: IAutoImporter,
    onChange(value: any, key: string): void,
    onSubmit(): void,
    validateErrors: boolean,
    disabled?: boolean,
}

const autoimportersApiService = new AutoimportersApiService();

export const AutoImporterSubmit = (props: IProps) => {
    const { autoImporter, onSubmit, onChange, validateErrors, disabled } = props;

    useEffect(() => {
        return () => {
            autoimportersApiService.cancelRequests();
        }
    },[])

    const onRun = () => {
        autoimportersApiService.runImport(autoImporter.id);
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="row mb-3">
                    <div className="col-6 col-sm-4 col-md-6 col-lg-8 mt-2">
                        <CheckBox
                            label="Aktivní import"
                            checked={autoImporter.active}
                            onChange={(value) => onChange(value, 'active')}
                        />

                        {validateErrors && <p className="text-danger my-2">Formulář vykazuje chyby. Zkontrolujte vyplněná pole.</p>}

                        <div className="btn-group mt-3" role="group">
                            <button
                                className="btn btn-primary"
                                onClick={() => onSubmit()}
                                disabled={disabled}
                            >
                                Uložit změny {autoImporter.active && 'a spustit import'}
                            </button>

                            {autoImporter.active &&
                                <button
                                    className="btn btn-outline-primary ml-2"
                                    onClick={() => onRun()}
                                >
                                    Spustit import
                                </button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}