import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class BazosApiService extends BaseApiService {

    readonly baseUrl: string = "bazos";

    public getCoupons(): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/coupons`, {})
            .then(response => {
                return response.data;
            })
    }

    public postCoupons(codes: string[]): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/coupons`, { codes }, {})
            .then(response => {
                return response.data;
            })
    }
}