import { useRef, useState, useEffect } from 'react';
import { filter, find, first, forEach } from 'lodash';
import { inject, observer } from 'mobx-react';
import { AutoImporterSourceType } from '../../../Enums/AutoImporterSourceType';
import { AutoImporterState } from '../../../Enums/AutoImporterState';
import IAutoImporter from '../../../Interfaces/IAutoImporter';
import { PageTitleBox } from '../../Others/PageTitleBox';
import LoadingScreen from '../../Screens/LoadingScreen';
import FacebookCatalogStore from '../../Stores/FacebookCatalogStore';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import WebsiteStore from '../../Stores/WebsiteStore';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import IResponseData from '../../../Interfaces/IResponseData';
import AutoImporterSettings from '../../Integrations/Autoimporters/AutoImporterSettings';
import { AutoImporterStateBox } from '../../Integrations/Autoimporters/AutoImporterStateBox';
import { AutoImporterSubmit } from '../../Integrations/Autoimporters/AutoImporterSubmit';
import WebsitesApiService from '../../../Services/WebsitesApiService';
import AutoimportersApiService from '../../../Services/AutoimportersApiService';
import FacebookCatalogsApiService from '../../../Services/FacebookCatalogsApiService';
import { ValidationHelper } from '../../Utils/ValidationHelper';
import { SectionTitle } from '../../Others/SectionTitle';
import IOptionData from '../../../Interfaces/IOptionData';
import ServerAccountStore from '../../Stores/ServerAccountStore';
import { IServerAccount } from '../../../Interfaces/IServerAccount';
import { OnlineServer } from '../../../Enums/OnlineServer';
import SelectBox from '../../UI/Input/SelectBox';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

interface IProps {
    productCollectionStore?: ProductCollectionStore,
    facebookCatalogStore?: FacebookCatalogStore,
    websiteStore?: WebsiteStore,
    serverAccountStore?: ServerAccountStore,
}

interface IState {
    autoImporter: IAutoImporter,
    loading: boolean,
    validateErrors: boolean,
}

const websitesApiService = new WebsitesApiService();
const autoimportersApiService = new AutoimportersApiService();
const facebookCatalogsApiService = new FacebookCatalogsApiService();

export const AutoImporterSbazarDetailPage = inject('productCollectionStore', 'facebookCatalogStore', 'websiteStore', 'serverAccountStore')(observer((props: IProps) => {

    const contentEditor = useRef();
    const autoImporterSettings = useRef<AutoImporterSettings>(null);

    const [state, setState] = useState<IState>({
        autoImporter: {
            id: null,
            active: true,
            collectionIds: [],
            sourceUrl: '',
            sourceType: AutoImporterSourceType.Sbazar,
            userId: '',
            errorUtc: null,
            lastImportUtc: null,
            defaultEnabledNewProduct: true,
            error: false,
            errorMessage: '',
            isSetuped: false,
            deleteProductAfterNotFoundInList: true,
            updateProductContent: true,
            doNotImportForbiddenKeywords: ['rezervace', 'prodano'],
            state: AutoImporterState.Inactive,
            contents: [],
            importServerAccountId: null,
        },
        loading: true,
        validateErrors: false,
    });

    let { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            window.scrollTo(0, 0);

            try {
                const data = await autoimportersApiService.getList();
                setAutoImporter(data);
            } finally {
                setState((prev) => ({ ...prev, loading: false }));
            }

            websitesApiService.getWebsites();
            facebookCatalogsApiService.getCatalogs();
        };

        fetchData();
    }, [autoimportersApiService, websitesApiService, facebookCatalogsApiService]);

    const handleChange = (key: string, value: any) => {
        setState((prev) => ({
            ...prev,
            autoImporter: {
                ...prev.autoImporter,
                [key]: value,
            },
        }));
    };

    const handleSubmit = () => {
        const isValid = validate();

        setState((prev) => ({
            ...prev,
            validateErrors: !isValid,
        }));

        if (isValid) {
            const autoimporter = state.autoImporter;

            if (autoimporter.id) {
                // edit
                autoimportersApiService.editAutoimporter(autoimporter.id, autoimporter).then((data) => setAutoImporter(data));
            } else {
                // post
                autoimportersApiService.postAutoimporter(autoimporter)
                    .then((data) => {
                        if (data.success) {
                            const items = filter(data.records.autoImporters.items, { sourceType: AutoImporterSourceType.Sbazar });
                            const autoimporter = first(items);

                            if (autoimporter) {
                                id = autoimporter.id;
                                navigate(`/autoimporter/sbazar/${autoimporter.id}`);
                                setAutoImporter(data);
                            }
                        }                       
                    })
            }
        }
    };

    const setAutoImporter = (data: IResponseData) => {
        const items = filter(data.records.autoImporters.items, { sourceType: AutoImporterSourceType.Sbazar });
        const autoimporter = find(items, { id: id });

        if (autoimporter) {
            setState((prev) => ({ ...prev, autoImporter: autoimporter }));
        }
    };

    const validate = () => {
        const isValidFeedUrl = ValidationHelper.validateInputs([inputServerAccountId]);
        let isValidSettings = true;

        const autoImporter = state.autoImporter;

        if (autoImporter.id) {
            isValidSettings = autoImporterSettings.current.validate();
        }

        const isValidEditor = ValidationHelper.validateInputs([contentEditor]);

        return isValidFeedUrl && isValidSettings && isValidEditor;
    };

    const inputServerAccountId = useRef<SelectBox>(null);

    let options: IOptionData[] = [];

    forEach(props.serverAccountStore.list, (s: IServerAccount) => {
        if (s.onlineServer === OnlineServer.Sbazar) {
            options.push({
                label: s.name,
                value: s.id,
            });
        }
    });

    const onChangeAccountId = (id: string) => {
        setState({
            ...state,
            autoImporter: {

                ...state.autoImporter,
                importServerAccountId: id
            },

        })
    }

    const autoImporter = state.autoImporter;
    const edit: boolean = (autoImporter.id || "").length > 0;
    const navigate = useNavigate();

    const handleDelete = () => {
        Swal.fire({
            title: `Opravdu chcete smazat tento importér"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Ano, smazat importér`,
            cancelButtonText: 'Zrušit'
        }).then((result) => {
            if (result.value) {
                autoimportersApiService.deleteAutoImporter(autoImporter)
                    .then(data => {
                        if (data.success) {
                            navigate('/autoimporter/sbazar');
                        }
                    });
            }
        })
    }

    const renderSettings = () => {

        return (
            <div className="card mb-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <SectionTitle title="Nastavení importu" />

                            <div className="mt-4">
                                <SelectBox
                                    ref={inputServerAccountId}
                                    label="Sbazar účet k importu"
                                    options={options}
                                    onChange={(id: string) => onChangeAccountId(id)}
                                    value={autoImporter.importServerAccountId}
                                    required
                                    defaultValue="Vyberte účet"
                                    disabled={edit}
                                />
                            </div>

                            {!autoImporter.isSetuped && (
                                <button className="btn btn-primary btn-lg float-right" onClick={handleSubmit}>
                                    Pokračovat
                                </button>
                            )}
                        </div>

                        <div className="col-12 col-md-6 mt-3 mt-md-0"></div>
                    </div>

                    {autoImporter.isSetuped && (
                        <>
                            <hr />
                            <AutoImporterSettings
                                ref={autoImporterSettings}
                                autoImporter={autoImporter}
                                handleChange={(value, key) => handleChange(key, value)}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    };


    const render = () => {
        const loading = state.loading;

        return (
            <CustomDocumentTitle title="Sbazar - importér">
                <PageTitleBox title="Sbazar - importér" />

                {loading ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <AutoImporterStateBox autoImporter={autoImporter} />

                        {renderSettings()}

                        {autoImporter.isSetuped && (
                            <>
                                <AutoImporterSubmit
                                    autoImporter={autoImporter}
                                    onChange={(value, key) => handleChange(key, value)}
                                    onSubmit={handleSubmit}
                                    validateErrors={state.validateErrors}
                                />
                            </>
                        )}

                        {edit && < a
                            onClick={handleDelete}
                            className="text-danger clickable float-right"
                        >
                            Smazat
                        </a>}
                    </>
                )}
            </CustomDocumentTitle>
        );
    };

    return render();
}))