import React from "react"

interface IProps {
    title: string,
    description?: string,
    icon?: string,
}

export const BenefitItem = (props: IProps) => {

    return (
        <div style={{ paddingLeft: '15px' }} className="my-2">
            <i style={{ fontSize: '18px' }} className={`${(props.icon || 'fa fa-check')} text-primary`}></i>  <span style={{ fontSize: '20px', paddingLeft: '8px' }} className="benefit-title text-primary">{props.title}</span>
            {props.description && <p style={{ paddingLeft: '25px', paddingTop: '5px', fontSize:'15px' }}>{props.description}</p>}
        </div>)
}