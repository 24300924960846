import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CurrencyHelper from '../../../Helpers/CurrencyHelper';
import IInputField from '../../../Interfaces/IInputField';
import { IServerAccount } from '../../../Interfaces/IServerAccount';
import { IFbSettingsRequest } from '../../../Interfaces/Requests/FacebookAccounts/IFbSettingsRequest';
import BaseComponent from '../../BaseComponent';
import AppIpAddressStore from '../../Stores/AppIpAddressStore';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import InputEmail from '../../UI/Input/InputEmail';
import InputText from '../../UI/Input/InputText';
import SelectBox from '../../UI/Input/SelectBox';

interface IState {
    settings: IFbSettingsRequest,
}

interface IProps {
    onHide(): void,
    accountServer: IServerAccount,
    appIpAddressStore?: AppIpAddressStore,
    currentUserStore?: CurrentUserStore,
}

@inject('appIpAddressStore', 'currentUserStore')
@observer
export default class FacebookAccountSettingsModal extends BaseComponent<IProps, IState> {

    private inputName = React.createRef<InputText>();
    private inputAppIp = React.createRef<SelectBox>();
    private inputCurrency = React.createRef<SelectBox>();

    constructor(props: IProps) {
        super(props);

        const account = this.props.accountServer;

        this.state = {
            settings: {
                name: account.name,
                appIpAddressId: account.appIpAddressId,
                currency: account.currency,
            }
        }

        this.inputName = React.createRef();
        this.inputAppIp = React.createRef();
        this.inputCurrency = React.createRef();
    }

    validate() {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            this.inputName,
            this.inputCurrency,
            this.inputAppIp,
        )
        return this.validateInputs(inputs);
    }

    handleChange(key: any, value: any) {
        this.setState({
            settings: {
                ...this.state.settings,
                [key]: value
            }
        })
    }

    handleSubmitForm() {
        const accountId = this.props.accountServer.id;

        if (this.validate()) {
            this.facebookAccountsApiService.postSettings(accountId, this.state.settings)
                .then(data => {
                    if (data.success) {
                        this.props.onHide();
                    }
                });
        }
    }

    render() {
        const user = this.props.currentUserStore.user;
        const form = this.state.settings;
        var ipOptions = this.props.appIpAddressStore.getOptions(user.id);
        const accountServer = this.props.accountServer;

        return (
            <Modal centered={true} show={true} onHide={() => { }}>
                <Modal.Header>
                    <Modal.Title>Nastavení "{accountServer.userName}" FB účtu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                
                        <div className="row">
                            <div className="col-lg-12">
                                <InputText
                                    ref={this.inputName}
                                    label="Libovolné označení tohoto účtu"
                                    value={form.name}
                                    required={true}
                                    onChange={this.handleChange.bind(this, 'name')}
                                />

                                <InputEmail
                                    label="E-mail"
                                    value={accountServer.userName}
                                    placeholder=""
                                    readonly
                                />

                                <SelectBox
                                    ref={this.inputCurrency}
                                    label="Měna"
                                    onChange={this.handleChange.bind(this, 'currency')}
                                    options={CurrencyHelper.getOptions()}
                                    value={form.currency}
                                    defaultValue="Vyberte"
                                    required
                                />

                                <SelectBox
                                    ref={this.inputAppIp}
                                    label="IP adresa"
                                    onChange={this.handleChange.bind(this, 'appIpAddressId')}
                                    options={ipOptions}
                                    value={form.appIpAddressId}
                                    defaultValue="Vyberte"
                                    required
                                />

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide.bind(this)} variant="secondary" size="lg">
                        Zavřít
                    </Button>
                    <Button variant="primary" size="lg" onClick={this.handleSubmitForm.bind(this)}>
                       Uložit změny
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}