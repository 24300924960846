import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import IInputField from '../../../Interfaces/IInputField';
import BaseComponent from '../../BaseComponent';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import ServerAccountStore from '../../Stores/ServerAccountStore';
import InputText from '../../UI/Input/InputText';

interface IState {
     code: string,
}

interface IProps {
    onHide(): void,
    accountId: string,
    currentUserStore?: CurrentUserStore,
    serverAccountStore?: ServerAccountStore,
}

@inject('currentUserStore', 'serverAccountStore')
@observer
export default class FacebookAccountSubmitSmsCodeModal extends BaseComponent<IProps, IState> {

    private inputCode = React.createRef<InputText>();
 
    constructor(props: IProps) {
        super(props);

        this.state = {
            code: '',
        }

        this.inputCode = React.createRef();
    }

    validate() {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            this.inputCode,
        )
        return this.validateInputs(inputs);
    }

    handleChange(code :string) {
        this.setState({
            code: code,
        })
    }

    handleSubmitForm() {
        const account = this.props.serverAccountStore.getById(this.props.accountId);
        const code = this.state.code;

        if (this.validate()) {
            this.facebookAccountsApiService.submitVerificationSmsCode(account.id, code)
                .then(data => {
                    if (data.success) {
                        this.props.onHide();
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Kód nebyl přijat',
                            text: 'Zkontrolujte kód a zkuste to znovu.'
                        })
                    }
                });
        }
    }

    render() {
        const code = this.state.code;
        const account = this.props.serverAccountStore.getById(this.props.accountId);

        return (
            <Modal centered={true} show={true} onHide={() => { }}>
                <Modal.Header>
                    <Modal.Title>Ověření účtu "{account.userName}" pomocí SMS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Na vaše tel. číslo byl odeslán ověřovací SMS kód. Kód by měl mít 6 znaků.</p>
                        <div className="row">
                            <div className="col-lg-12">
                                <InputText
                                    ref={this.inputCode}
                                    label="Zadejte kód"
                                    value={code}
                                    required={true}
                                    onChange={this.handleChange.bind(this)}
                                    length={6}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide.bind(this)} variant="secondary" size="lg">
                        Zavřít
                    </Button>
                    <Button variant="primary" size="lg" onClick={this.handleSubmitForm.bind(this)}>
                        Potvrdit kód
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}