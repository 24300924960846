import { observable, action, makeAutoObservable } from 'mobx'
import { filter, findIndex, remove } from 'lodash';
import IAppIpAddress from '../../Interfaces/IAppIpAddress';
import IOptionData from '../../Interfaces/IOptionData';

export default class AppIpAddressStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IAppIpAddress[] = [];

    @action
    addRecords(data: any): any {
        data.forEach((record: IAppIpAddress) => this.addRecord(record));
    }

    @action
    private addRecord(record: IAppIpAddress) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: string[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }

    getByUserId(userId: string): IAppIpAddress[] {
        return filter(this.list, { userId: userId });
    }

    getOptions(userId: string): IOptionData[] {
        var ips = this.getByUserId(userId);

        let options: IOptionData[] = [];
        ips.forEach(ip => {
            options.push({ label: ip.ip, value: ip.id });
        })
        return options;
    }

    getByIds(ids: string[]): IAppIpAddress[] {
        return filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
    }
}