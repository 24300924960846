import { Language } from "../Enums/Language";
import IOptionData from "../Interfaces/IOptionData";

export default class LanguageHelper {

    public static getOptions(): IOptionData[] {

        let options: IOptionData[] = [];
        options.push({ label: this.getLabel(Language.Czech), value: Language.Czech });
        options.push({ label: this.getLabel(Language.Slovak), value: Language.Slovak });
        return options;
    }

    public static getLabel(language: Language): string {
        switch (language) {

            case Language.Czech:
                return 'Česky';

            case Language.Slovak:
                return 'Slovensky';
        }
    }
}