import React from "react";
import { Link } from "react-router-dom";
import { OnlineServer } from "../../Enums/OnlineServer";
import { ProductServerErrorType } from "../../Enums/ProductServerErrorType";
import { ProductHelper } from "../../Helpers/ProductHelper";
import IProductCollection from "../../Interfaces/IProductCollection";
import IProductServerInfo from "../../Interfaces/IProductServerInfo";
import BazosCzVerifyAlert from "../Uploader/Collections/BazosCzVerifyAlert";
import BazosSkVerifyAlert from "../Uploader/Collections/BazosSkVerifyAlert";
import { NotSetupedAlert } from "./ProductServerModal/NotSetupedAlert";
import { RejectedErrorAlertServer } from "./ProductServerModal/RejectedErrorAlertServer";

interface IProps {
    info: IProductServerInfo,
    collection: IProductCollection,
}

export const ProductServerStateAlert = (props: IProps) => {

    const { info, collection } = props;

    if (!info.enabled) {
        return <NotSetupedAlert />;
    }
    if (info.error && info.errorType === ProductServerErrorType.OutOfCoupons) {
        if (info.server === OnlineServer.BazosCz || info.server == OnlineServer.BazosSk) {
            return <div className="alert alert-warning" role="alert">
                <i className="fa fa-exclamation-triangle mr-2" /> Došly poukázky k topování. Vložte další poukázky <Link to='/bazos'>zde</Link>
            </div>
        }
    }
    if (info.error && ProductHelper.getFatalErrorTypes().includes(info.errorType)) {
        return <div className="alert alert-secondary" role="alert">
            <i className="fa fa-exclamation-triangle mr-2" />Došlo k technické chybě. Chyba byla předána správci serveru. Z vaší strany není vyžadována akce.
        </div>
    }
    if (info.pendingCheck) {
        return <div className="alert alert-primary" role="alert">
            <i className="bi bi-check2-square Blink mr-2" />Bude provedena kontrola
        </div>
    }
    if (info.error === true && info.errorType === ProductServerErrorType.CheckVisibilityNotFound) {
        return <RejectedErrorAlertServer />;
    }
    if (info.serverRequiredAction) {
        if (info.server === OnlineServer.BazosCz) {
            return <BazosCzVerifyAlert collection={collection} />
        }
        else if (info.server === OnlineServer.BazosSk) {
            return <BazosSkVerifyAlert collection={collection} />
        }
    }
    if (info.error) {
        return <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-triangle mr-2" />Došlo k chybě
        </div>
    }
    if (info.pendingEdit) {
        return <div className="alert alert-primary" role="alert">
            <i className="far fa-edit Blink mr-2" />Inzerát bude upraven
        </div>
    }
    if (info.pendingDelete) {
        return <div className="alert alert-primary" role="alert">
            <i className="far fa-trash-alt Blink mr-2" />Inzerát bude smazán
        </div>
    }
    if (info.pendingUpload) {
        if (info.isVisible) {
            return <div className="alert alert-primary" role="alert">
                <i className="fas fa-retweet Blink mr-2" />Inzerát bude obnoven
            </div>
        }

        return <div className="alert alert-primary" role="alert">
            <i className="fas fa-upload Blink mr-2" />Inzerát bude nahrán
        </div>
    }
    else if (!info.isVisible) {
        return <div className="alert alert-secondary" role="alert">
            <i className="fa fa-circle mr-2" />Inzerát není vystaven
        </div>
    }
    return null;
}