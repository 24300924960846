import { inject, observer } from "mobx-react";
import { PageTitleBox } from "../../../Others/PageTitleBox";
import { SectionTitle } from "../../../Others/SectionTitle";
import CurrentUserStore from "../../../Stores/CurrentUserStore";
import CustomDocumentTitle from "../../../Utils/CustomDocumentTitle";
import { Tab, Tabs } from "react-bootstrap";
import { PaidSupport } from "../../../Contact/PaidSupport";
import { WebembedAllowAlert } from "../../../Integrations/Webembed/WebembedAllowAlert";
import { useEffect } from "react";

interface IProps {
    currentUserStore?: CurrentUserStore,
}

export const WebEmbedPage = inject('currentUserStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;
    const carsHtml = `<div id="inzerobot-cars" user-id="${user.id}" primary-color="#4f97e7"></div>`;
    const productsHtml = `<div id="inzerobot-products" user-id="${user.id}" primary-color="#4f97e7"></div>`;
    const jsImport = '<script async src="https://embed.inzerobot.cz/prod/web/app.js"></script>';

    useEffect(() => {
        const script = document.createElement('script');
        if (user.enableWebEmbed) {
            script.src = "https://embed.inzerobot.cz/prod/web/app.js";
            script.async = true;
            document.body.appendChild(script);
        }

        return () => {
            if (user.enableWebEmbed) {
                document.body.removeChild(script);
            }
        }
    }, [user.enableWebEmbed]);

    return (
        <>
            <CustomDocumentTitle title="EXPORT NA WEB - Aktuální nabídka">
                <PageTitleBox title="EXPORT NA WEB - Aktuální nabídka" />

                <div className="card mb-1">
                    <div className="card-body">

                        {!user.enableWebEmbed &&
                            <WebembedAllowAlert />}

                        <SectionTitle title="POPIS EXPORTU" />
                        <hr />
                        <p>Jestliže máte již svůj web a chcete v rámci něj zobrazit vaší aktuální nabídku inzerce,
                            povolte tento export a pokračujte podle návodu.</p>

                        <SectionTitle title="1. Následující script vložte do hlavičky vašeho webu" />
                        <hr />

                        <textarea
                            readOnly
                            className="form-control"
                            value={jsImport}
                        >

                        </textarea>

                        <SectionTitle title="2. Následující html vložte na místo zobrazení nabídky" />
                        <p>Vložte jej do HTML kódu vašeho webu tam, kde má být nabídka vyobrazena. Vyberte si typ nabídky.</p>

                        <Tabs
                            id="uncontrolled-tab-example"
                        >
                            <Tab
                                eventKey="cars" title="Nabídka aut"
                                className="bg-light p-2"
                            >
                                <p>Tento html kód zobrazuje pouze nabídku vozidel.</p>
                                <textarea
                                    readOnly
                                    className="form-control"
                                    value={carsHtml}
                                >
                                </textarea>

                                <PaidSupport />

                                <SectionTitle title="Ukázka" />
                                {!user.enableWebEmbed ?
                                    <p>Ukázku je možné načíst jakmile povolíte tento export.</p>
                                    :
                                    <p>Následující ukázka zobrazuje vaši nabídku vozidel. </p>}

                                <div id="inzerobot-cars" user-id={user.id}></div>
                            </Tab>
                            <Tab
                                eventKey="all" title="Obecné inzeráty"
                                className="bg-light p-2"
                            >
                                <textarea
                                    readOnly
                                    className="form-control"
                                    value={productsHtml}
                                >
                                </textarea>

                                <PaidSupport />

                                <SectionTitle title="Ukázka" />
                                {!user.enableWebEmbed ?
                                    <p>Ukázku je možné načíst jakmile povolíte tento export.</p>
                                    :
                                    <p>Následující ukázka zobrazuje vaši nabídku. </p>}

                                <div id="inzerobot-products" user-id={user.id}></div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </CustomDocumentTitle>
        </>
    );
}));