import IResponseData from "../Interfaces/IResponseData";
import { IServerAccount } from "../Interfaces/IServerAccount";
import { IAddFbAccountRequest } from "../Interfaces/Requests/FacebookAccounts/IAddFbAccountRequest";
import { IFbSettingsRequest } from "../Interfaces/Requests/FacebookAccounts/IFbSettingsRequest";
import BaseApiService from "./BaseApiService";

export default class FacebookAccountsApiService extends BaseApiService {

    readonly baseUrl: string = "facebook-accounts";

    public getAccounts(): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}`, {})
            .then(response => {
                return response.data;
            })
    }

    public addAccount(addFbRequest: IAddFbAccountRequest): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}`, addFbRequest, {})
            .then(response => {
                return response.data;
            })
    }

    public verifyLogin(id: string): Promise<IResponseData>  {
        return this.postRequest(`${this.baseUrl}/${id}/login/verify`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public sendVerificationSms(id: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/login/verify/send-sms`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public submitVerificationSmsCode(id: string, code: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/login/verify/submit-code`, { code: code }, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteAccount(id: string): Promise<IResponseData> {
        return this.deleteRequest(`${this.baseUrl}/${id}`, {  }, {})
            .then(response => {
                return response.data;
            })
    }

    public editAccount(id: string, account: IServerAccount): Promise<IResponseData> {
        return this.putRequest(`${this.baseUrl}/${id}`, account , {})
            .then(response => {
                return response.data;
            })
    }

    public postSettings(id: string, settings: IFbSettingsRequest): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/settings`, settings, {})
            .then(response => {
                return response.data;
            })
    }
}