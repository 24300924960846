import { Link, useNavigate } from 'react-router-dom';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import ProductCollectionApiService from '../../../Services/ProductCollectionsApiService';
import { inject, observer } from 'mobx-react';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import LoadingScreen from '../../Screens/LoadingScreen';
import { PageTitleBox } from '../../Others/PageTitleBox';
import { CollectionCard } from '../../ProductCollections/CollectionCard';
import useCookie from 'react-use-cookie';
import { ProductCollectionsTable } from '../../ProductCollections/ProductCollectionsTable';
import { GridFill, List } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IProps {
    currentUserStore?: CurrentUserStore,
    productCollectionStore?: ProductCollectionStore
}

const productCollectionApiService = new ProductCollectionApiService();

export const CollectionsPage = inject('currentUserStore', 'productCollectionStore')(observer((props: IProps) => {

    const { currentUserStore, productCollectionStore } = props;
    const user = currentUserStore.user;
    const [collectionsView, setCollectionsView] = useCookie('collectionsView', 'grid');

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        productCollectionApiService.getCollections()
            .finally(() => {
                setLoading(false);
            })

        return () => {
            productCollectionApiService.cancelRequests();
        }
    }, [])

    const collections = orderBy(productCollectionStore.getByUserId(user.id), col => col.name);
    const navigate = useNavigate();

    return (
        <CustomDocumentTitle title="Kolekce">
            <>
                <PageTitleBox title="Kolekce" />

                {loading && <LoadingScreen />}

                {loading === false &&
                    <>
                        <div className="card">
                            <div className="card-body py-2">
                                <div className="row">
                                    <div className="col-12 col-sm-4 col-md-6 col-lg-8">

                                        <button
                                            onClick={() => navigate('/nahravac/kolekce/pridat')}
                                        className="btn btn-primary my-2"
                                        >
                                            <i className="fas fa-plus mr-2"></i>
                                            Přidat kolekci
                                        </button>
                                    </div>
                                    <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                        <div className="float-sm-right">

                                            <button
                                                onClick={() => navigate('/inzeraty')}
                                                className="btn btn-outline-purple my-2"
                                            >
                                                <i className="fas fa-search mr-2"></i>
                                                Vyhledat inzerát
                                            </button>

                                            {collectionsView === "grid" &&
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            Zobrazit v tabulce
                                                        </Tooltip>
                                                    }
                                                >
                                                    <List
                                                        className="ml-2 clickable text-primary"
                                                        style={{ fontSize: 23 }}
                                                        onClick={() => setCollectionsView('list')}
                                                    />
                                                </OverlayTrigger>
                                            }

                                            {collectionsView === "list" &&
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            Zobrazit jako mřížku
                                                        </Tooltip>
                                                    }
                                                >
                                                    <GridFill
                                                        className="ml-2 clickable text-primary"
                                                        style={{ fontSize: 20 }}
                                                        onClick={() => setCollectionsView('grid')}
                                                    />
                                                </OverlayTrigger>}
                                        </div>
                                    </div>
                                </div>

                                {collections.length === 0 && loading === false && <div className="py-5">
                                    <div className="text-center">
                                        <h3>Vytvořte svou první kolekci inzerátů</h3>
                                    </div>
                                    <br />

                                    <div className="d-flex justify-content-center py-3">
                                        <Link to={`/nahravac/kolekce/pridat`}>
                                            <button className="btn btn-primary btn-lg">
                                                Vytvořit kolekci
                                            </button>
                                        </Link>
                                    </div>
                                </div>}
                            </div>
                        </div>

                        {collections.length > 0 &&
                            <div>
                                {collectionsView === 'list' &&
                                    <div className="card">
                                        <div className="card-body">
                                            <ProductCollectionsTable
                                                collections={collections}
                                            />
                                        </div>
                                    </div>}

                                {collectionsView === 'grid' &&
                                <div className="row">
                                        {collections.map(collection => {
                                            return <div key={collection.id} className="col-12 col-sm-12 col-md-6 col-xl-4 py-0 my-0">
                                                <CollectionCard collection={collection} />
                                            </div>
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </>}
            </>
        </CustomDocumentTitle>
    )
}))