import React from "react"
import { Link } from "react-router-dom";
import { OnlineServer } from "../../../Enums/OnlineServer";
import IProduct from "../../../Interfaces/IProduct"
import IUser from "../../../Interfaces/IUser";
import { SuccessAlert } from "../../Others/Alerts/SuccessAlert";
import { WarningAlert } from "../../Others/Alerts/WarningAlert";
import { NotSetupedAlert } from "./NotSetupedAlert";

interface IProps {
    product: IProduct,
    user: IUser,
}

export const TipMotoTabContent = (props: IProps) => {

    const { product, user } = props;

    return (
        <>
            {user.uploaderServers.includes(OnlineServer.TipMoto) === false ?
                <WarningAlert text="Export není povolen.">
                    <span> Nastavíte jej <Link to="/tipmoto/export">zde</Link>.</span>
                </WarningAlert>
                :
                <>
                    {product.tipMoto ?
                        <SuccessAlert text="Nastaveno" /> : <NotSetupedAlert />}
                </>}
        </>)
}