import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { IServerAccount } from "../../../Interfaces/IServerAccount";
import FacebookAccountsApiService from "../../../Services/FacebookAccountsApiService";
import ServerAccountStore from "../../Stores/ServerAccountStore";
import { FacebookAccountChooseLoginType } from "./FacebookAccountChooseLoginType";
import FacebookAccountSettingsModal from "./FacebookAccountSettingsModal";
import { FacebookAccountState } from "./FacebookAccountState";

interface IProps {
    account: IServerAccount,
    serverAccountStore?: ServerAccountStore,
}

const facebookAccountsApiService = new FacebookAccountsApiService();

export const FacebookAccountRow = inject('serverAccountStore')(observer((props: IProps) => {
    const { account } = props;

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showChooseLoginModal, setShowChooseLoginModal] = useState(false);

    useEffect(() => {
        return () => {
            facebookAccountsApiService.cancelRequests();
        }
    }, []);

    function handleDelete() {
        Swal.fire({
            title: 'Opravdu chcete smazat účet?',
            text: 'Tato akce je nevratná',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Ano, smazat',
            icon: 'warning',
            cancelButtonText: 'Zrušit',
        })
            .then(result => {
                if (result.value) {
                    facebookAccountsApiService.deleteAccount(account.id)
                        .then(data => {
                            if (data.success) {
                                props.serverAccountStore.removeRecords([account.id]);
                            }
                        })
                }
            })
    }

    function handleValidateLogin() {
        facebookAccountsApiService.verifyLogin(account.id)
            .then(data => {
                const account = data.records.serverAccounts.items[0];

                if (account.signInRequired) {
                    setShowChooseLoginModal(true);
                }
            })
    }

    return (
        <tr key={account.id}>

            <td> 
                {showSettingsModal && <FacebookAccountSettingsModal
                    accountServer={account}
                    onHide={() => setShowSettingsModal(false)}
                />}

               <FacebookAccountChooseLoginType
                    showChooseType={showChooseLoginModal}
                    onChangeShow={(value) => setShowChooseLoginModal(value)}
                    account={account}
                />

                {account.name}</td>
            <td>
                {account.userName}
            </td>

            <td>
                <FacebookAccountState account={account} />
            </td>

            <td>
                {account.productsCount}
            </td>

            <td>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" data-boundary="window" className="btn-sm ml-1 h-100" id="dropdownProductOptions">
                        <span className="mr-2">Možnosti</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {account.signInRequired ?
                            <>
                                <Dropdown.Item onClick={() => setShowChooseLoginModal(true)}>
                                    Znovu propojit
                                </Dropdown.Item>
                            </> :

                            <>
                                <Dropdown.Item onClick={() => handleValidateLogin()}>
                                    Ověřit přihlášení
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => setShowChooseLoginModal(true)}>
                                    Změnit přihlášení
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => setShowSettingsModal(true)}>
                                    Nastavení
                                </Dropdown.Item>
                            </>}

                        <Dropdown.Item onClick={() => handleDelete()}>
                            Smazat
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>)
}))