import { observable, action, makeAutoObservable } from 'mobx'
import { filter, find, findIndex, remove } from 'lodash';
import { IServerAccount } from '../../Interfaces/IServerAccount';
import { OnlineServer } from '../../Enums/OnlineServer';

export default class ServerAccountStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IServerAccount[] = [];

    @action
    addRecords(data: any): any {
        data.forEach((record: IServerAccount) => this.addRecord(record));
    }

    @action
    private addRecord(record: IServerAccount) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: string[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }

    getByUserId(userId: string): IServerAccount[] {
        return filter(this.list, server => {
            if (server.userId === userId) {
                return true;
            }
            return false;
        });
    }

    getByUserIdAndServer(userId: string, server: OnlineServer): IServerAccount[] {
        return filter(this.list, accountServer => {
            if (accountServer.userId === userId && accountServer.onlineServer === server) {
                return true;
            }
            return false;
        });
    }

    getById(id: string): IServerAccount {
        return find(this.list, { id: id });
    }

    getByIds(ids: string[]): IServerAccount[] {
        return filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
    }

    getByServer(server: OnlineServer): IServerAccount[] {
        return filter(this.list, (item: IServerAccount) => {
            return item.onlineServer === server;
        })
    }
}