import { Button, Modal } from "react-bootstrap";
import IProduct from "../../Interfaces/IProduct";
import { useEffect, useRef, useState } from "react";
import CheckBox from "../UI/Input/CheckBox";
import CurrentUserStore from "../Stores/CurrentUserStore";
import { inject, observer } from "mobx-react";
import InputPrice from "../UI/Input/InputPrice";
import ProductApiService from "../../Services/ProductApiService";
import InputDatePicker from "../UI/Input/InputDatePicker";
import { ValidationHelper } from "../Utils/ValidationHelper";
import { round } from "lodash";
import { ProductHelper } from "../../Helpers/ProductHelper";

interface IProps {
    onHide(): void,
    product: IProduct,
    currentUserStore?: CurrentUserStore,
}

const productApiService = new ProductApiService();

export const ProductDeleteModal = inject('currentUserStore')(observer((props: IProps) => {

    useEffect(() => {

        return () => {
            productApiService.cancelRequests();
        }
    }, [])

    const currentUser = props.currentUserStore.user;
    const { onHide, product } = props;
    const [salePrice, setSalePrice] = useState(product.priceWithoutVat || ProductHelper.getPriceByCurrency(product, currentUser.accountCurrency));
    const [dateOfSale, setDateOfSale] = useState(new Date());
    const [costPrice, setCostPrice] = useState(product.costPrice);
    const [isSold, setIsSold] = useState(!currentUser.doNotStoreBusinessData);
    const salePriceRef = useRef<InputPrice>(null);
    const dateOfSaleRef = useRef<InputDatePicker>(null);

    const onSubmit = () => {
        const inputs = [salePriceRef, dateOfSaleRef];

        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            productApiService.deleteProduct(product.id, isSold, salePrice, costPrice, dateOfSale)
                .then(data => {
                    onHide();
                })
        }
    }

    return (
        <Modal centered={true} show={true} onHide={() => { }}>
            <Modal.Header className="bg-danger">
                <Modal.Title style={{ fontSize: 20 }}>Chcete smazat tento inzerát?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h6>{product.name}</h6>

                {!currentUser.doNotStoreBusinessData &&
                    <>
                        <hr />
                        <CheckBox
                            checked={isSold}
                            label="Označit jako prodané"
                            onChange={(val) => setIsSold(val)}
                        />
                    </>
                }

                {isSold &&
                    <div className="row mt-3">
                        <>
                            <div className="col-12 col-md-6">
                                <InputPrice
                                    label="Nákupní cena"
                                    value={costPrice}
                                    onChange={(val) => setCostPrice(val)}
                                    currency={currentUser.accountCurrency}
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <InputPrice
                                    ref={salePriceRef}
                                    label={`Prodáno za ${product.vatDeduction === true ? '(bez DPH)' : ''}`}
                                    value={salePrice}
                                    onChange={(val) => setSalePrice(val)}
                                    currency={currentUser.accountCurrency}
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <InputPrice
                                    label="Hrubá marže"
                                    value={round(salePrice - costPrice, 2)}
                                    currency={currentUser.accountCurrency}
                                    readonly
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <InputDatePicker
                                    ref={dateOfSaleRef}
                                    label="Datum prodeje"
                                    value={dateOfSale}
                                    onChange={(val) => setDateOfSale(val)}
                                    required
                                />
                            </div>
                        </>
                    </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmit} variant="danger">
                    Ano, smazat inzerát
                </Button>

                <Button onClick={onHide} variant="secondary">
                    Ne
                </Button>
            </Modal.Footer>
        </Modal>)
}))