import { orderBy } from "lodash"
import React from "react"
import { Currency } from "../../Enums/Currency"
import IPricingItem from "../../Interfaces/IPricingItem"
import { PricingHelper } from "../Utils/PricingHelper"
import { PricingTable } from "./PricingTable"

interface IProps {
    currency: Currency
}

export const AutomotoPricing = (props: IProps) => {
    const currency = props.currency;

    let items: IPricingItem[] = PricingHelper.getAutoMotoPricing(currency);

    items = orderBy(items, ['productMax'], ['desc']);

    return <PricingTable items={items} currency={currency} />
}