import { Link } from 'react-router-dom';
import StringHelper from '../../../Helpers/StringHelper';
import IUser from '../../../Interfaces/IUser';
import { ShowDate } from '../../Others/ShowDate';
import moment from 'moment';

interface IProps {
    user: IUser,
}

export const DashboardWidgets = (props: IProps) => {

    const { user } = props;

    var dateExp = moment(new Date(user.dateExpirationUploader));
    var today = moment(new Date());
    const daysLeft = dateExp.diff(today, 'days')

    return (
        <div id="main-wrapper">
            <div className="row no-gutters">
                <div className="col-lg-3 col-md-6 pr-1">
                    <div className="card" style={{ marginBottom: '5px' }}>
                        <div className="card-body">
                            <div className="float-left">
                                <span className="widget-title" dangerouslySetInnerHTML={{ __html: StringHelper.separateThousands(user.productsCount) }}></span>
                                <p className="subtitle">Inzerátů</p>
                            </div>
                            <div className="float-right">
                                <i className="bi bi-newspaper widget-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 pr-1">
                    <div className="card" style={{ marginBottom: '5px' }}>
                        <div className="card-body">
                            <div className="float-left">
                                <span className="widget-title" dangerouslySetInnerHTML={{ __html: StringHelper.separateThousands(user.productsVisibleCount) }}></span>
                                <p className="subtitle">Vystaveno</p>
                            </div>
                            <div className="float-right">
                                <i className="bi bi-eye widget-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 pr-1">
                    <Link to="inzeraty/chybove">
                        <div className="card clickable" style={{ marginBottom: '5px' }}>
                            <div className="card-body">
                                <div className="float-left">
                                    <span className="widget-title" dangerouslySetInnerHTML={{ __html: StringHelper.separateThousands(user.productsWithErrorsCount) }}></span>
                                    <p style={{ color: "#868ba1" }} className="subtitle">Odmítnutých</p>
                                </div>
                                <div className="float-right">
                                    <i className="bi bi-exclamation-triangle widget-icon"></i>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                {user.activeUploader &&
                    <div className="col-lg-3 col-md-6 pr-1">
                        <div className="card" style={{ marginBottom: '5px' }}>
                            <div className="card-body">
                                <div className="float-left">
                                    <span className={`widget-title ${daysLeft < 7 ? 'text-danger Blink' : ''}`}>
                                        <ShowDate date={user.dateExpirationUploader} />
                                    </span>
                                    <p className="subtitle">Expirace licence</p>
                                </div>
                                <div className="float-right">
                                    <i className={`bi bi-clock widget-icon ${daysLeft < 7 ? 'text-danger' : ''}`}></i>
                                </div>
                            </div>
                        </div>
                    </div>}
                {/*  <div className="col-lg-3 col-md-6 pr-1">
                    <div className="card" style={{ marginBottom: '5px' }}>
                        <div className="card-body">
                            <div className="float-left">
                                <span className={`widget-title`}>
                                    {props.productsPendingCount}
                                </span>
                                <p className="subtitle">K synchronizaci</p>
                            </div>
                            <div className="float-right">
                                <i className={`bi bi-arrow-repeat widget-icon`}></i>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}