import { Link } from "react-router-dom"
import { InzerobotEmail } from "../Others/InzerobotEmail"

export const PaidSupport = () => {

    return <>
        <div className="alert alert-primary mt-2 pb-4">
            <h4>Potřebujete asistenci, konzultaci, doprogramování či jiné úkony?</h4>
            <hr />
            <b>Na dotazy ohledně Inzerobota odpovídáme zdarma, avšak potřebujete-li asistenci, konzultaci, doprogramování či jiné úkony, je možné taktéž využít našich služeb. <Link to="/kontakt">Napište nám</Link> a my vám zašleme kalkulaci. Kontaktní email <InzerobotEmail />.</b>
        </div>
    </>
}