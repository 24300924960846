import { IGoogleCategory } from "../Interfaces/IGoogleCategory";
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class GoogleApiService extends BaseApiService {

    readonly baseUrl: string = "google";

    public searchPlace(query: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/places/search`, { query: query }, {})
            .then(response => {
                return response.data;
            })
    }

    public savePlace(placeId: string, loadDetails: boolean): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/places`, { placeId: placeId, loadDetails: loadDetails }, {})
            .then(response => {
                return response.data;
            })
    }

    public deletePlace(): Promise<IResponseData> {
        return this.deleteRequest(`${this.baseUrl}/places`, { }, {})
            .then(response => {
                return response.data;
            })
    }

    public getCategories(query?: string): Promise<IGoogleCategory[]> {
        return this.getRequest(`${this.baseUrl}/categories`, { query: query })
            .then(response => {
                return response.data as unknown as IGoogleCategory[];
            })
    }

    public saveSettings(active: boolean): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/merchant/settings`, { active: active }, {})
            .then(response => {
                return response.data;
            })

    }
}