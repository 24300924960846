import { concat } from "lodash";
import React from "react";
import { ProductHelper } from "../../../Helpers/ProductHelper";
import { BazosHelper } from "../../../Helpers/Servers/BazosHelper";
import IGlobalSettings from "../../../Interfaces/IGlobalSettings";
import IProduct from "../../../Interfaces/IProduct";
import IProductCollection from "../../../Interfaces/IProductCollection";
import Product from "../../../Models/Product";
import BaseComponent from "../../BaseComponent";
import InputText2 from "../../UI/Input/InputText2";
import Textarea from "../../UI/Input/Textarea";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GenerateDescriptionAiModal } from "../GenerateDescriptionAiModal";
import { toast } from "react-toastify";

interface IState {
    showGenerateDescModal: boolean,
}

interface IProps {
    product: IProduct,
    collection: IProductCollection,
    globalSettings: IGlobalSettings,
    onChange(value: any, key: string): void,
    validChanged?(isValid: boolean): void,
}

export default class GeneralEditor extends BaseComponent<IProps, IState>
{
    private inputName = React.createRef<InputText2>();
    private inputDescription = React.createRef<Textarea>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            showGenerateDescModal: false,
        }

        this.inputName = React.createRef();
        this.inputDescription = React.createRef();
    }

    validateGeneralDescription(description: string, errors: string[], validateInput: Function) {
        if (this.props.product.bazosCZ || this.props.product.bazosSK) {
            concat(errors, BazosHelper.getDescriptionErrors(this.props.globalSettings, description));
        }
        validateInput(description, errors);
    }

    getInputs() {
        return [this.inputName, this.inputDescription];
    }

    async checkValidateAsync() {
        const inputs = this.getInputs();
        const isValid = await this.isValid(inputs);
        this.props.validChanged(isValid);
    }

    async onBlurCheckValidateAsync() {
        const inputs = this.getInputs();
        const isValid = await this.validateInputsAsync(inputs, false);
        this.props.validChanged(isValid);
    }

    async validateAsync(): Promise<boolean> {
        const inputs = this.getInputs();
        const isValid = await this.validateInputsAsync(inputs);

        this.props.validChanged(isValid);
        return isValid;
    }

    async validateNameOnServerAsync(name: string) {
        const product = this.props.product;

        if ((name || '').length > 0) {
            let errors: string[] = [];
            return this.productApiService.validateName(product.id, name, null)
                .then(success => {
                    if (success === false) {
                        errors.push(`Název "${name}" již existuje. Zvolte jiný.`);
                    }
                    return errors;
                })
        }
    }

    render() {
        const { product, onChange, collection } = this.props;

        return (
            <>
                <InputText2
                    ref={this.inputName}
                    label="Nadpis"
                    onChange={(value) => onChange(value, 'name')}
                    value={product.name}
                    required
                    forbidSpecialChars={true}
                    maxLength={ProductHelper.getMaxUniversalNameCharsCount(product)}
                    readonly={Product.isPendingCheck(product, collection)}
                    onBlur={this.onBlurCheckValidateAsync.bind(this)}
                    validateAsync={this.validateNameOnServerAsync.bind(this)}
                />

                <div className="float-right">
                    {this.state.showGenerateDescModal && <GenerateDescriptionAiModal
                        onHide={() => this.setState({ showGenerateDescModal: false })}
                        title={product.name}
                        onGenerated={(des) => {
                            let fullDesc = product.description;
                            if (fullDesc.length > 1) {
                                fullDesc += "\n\n" + des;
                            }
                            else {
                                fullDesc += des;
                            }
   
                            onChange(fullDesc, "description");
                            this.setState({ showGenerateDescModal: false });
                            toast.info("Popis byl generován");
                        }}
                    />}

                    {/*product.name.length > 5 && <>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="ai">
                                    Umělá inteligence je tady!
                                </Tooltip>
                            }
                        >
                            <button
                                onClick={() => this.setState({ showGenerateDescModal: true })}
                                type="button"
                                className="btn btn-outline-secondary mb-1"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cpu" viewBox="0 0 16 16">
                                    <path d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                                </svg>
                                <span className="ml-1">Vygenerovat popis pomocí AI</span>
                            </button>

                        </OverlayTrigger>
                    </>*/}
                </div>

                <Textarea
                    ref={this.inputDescription}
                    label="Popis"
                    onChange={(value) => onChange(value, 'description')}
                    required
                    value={product.description}
                    rows={13}
                    maxLength={ProductHelper.getMaxDescriptionChars(product, collection)}
                    validate={this.validateGeneralDescription.bind(this)}
                    onBlur={this.onBlurCheckValidateAsync.bind(this)}
                />
            </>)
    }
}