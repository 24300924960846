import * as React from 'react';

interface IProps {
    label: string;
    checked: boolean;
    disabled?: boolean;
    onChange?: Function,
}

interface IState {

}

export default class BooleanButton extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    handleChange(e: any) {
        this.props.onChange(!this.props.checked);
    }

    render() {
        return (
            <div
                className={`${this.props.checked ? 'btn-server-checked' : 'btn-server'}`}
                onClick={this.handleChange.bind(this)}
                style={{ zoom: 0.8 }}
            >
                <div>
                    <div className="my-2">
                        <i className={`fa fa-check m-r-10 ${this.props.checked ? '' : 'invisible'}`} />
                        {this.props.label}
                    </div>
                </div>
            </div>
        );
    }
}