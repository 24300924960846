import React from "react";
import { IServerAccount } from "../../../Interfaces/IServerAccount"

interface IProps {
    account: IServerAccount
}

export const FacebookAccountState = (props: IProps) => {

    const { account } = props;

    if (account.error) {
        return <span className="badge badge-danger">Chyba</span>
    }

    if (account.signInRequired) {
        return <span className="badge badge-warning">Vyžaduje přihlášení</span>
    }
    else
        return <span className="badge badge-success">Přihlášeno</span>
}