import { filter } from "lodash";
import React, { RefObject } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { OnlineServer } from "../../../../Enums/OnlineServer";
import { VehicleType } from "../../../../Enums/VehicleType";
import IAutoImporter from "../../../../Interfaces/IAutoImporter";
import IInputField from "../../../../Interfaces/IInputField";
import BaseComponent from "../../../BaseComponent";
import { AutoImporterVariables } from "../AutoImporterVariables";
import AutoImporterVehicleTypeContentEditor from "../Vehicles/AutoImporterVehicleTypeContentEditor";
import { AutoImporterVehicleTypeCard } from "../Vehicles/AutoImporterContentVehicleTypeCard";

interface IState {
    activeTabKey: string
}

interface IProps {
    autoImporter: IAutoImporter,
    handleChange(type: VehicleType, server: OnlineServer, value: any, key: any): void,
}

export default class AutoImporterSautoContentEditor extends BaseComponent<IProps, IState> implements IInputField {

    private carsEditor = React.createRef<AutoImporterVehicleTypeContentEditor>();
    private commercialsEditor = React.createRef<AutoImporterVehicleTypeContentEditor>();
    private trucksEditor = React.createRef<AutoImporterVehicleTypeContentEditor>();
    private motorcyclesEditor = React.createRef<AutoImporterVehicleTypeContentEditor>();
    private quadbikesEditor = React.createRef<AutoImporterVehicleTypeContentEditor>();
    private trailersEditor = React.createRef<AutoImporterVehicleTypeContentEditor>();
    private caravansEditor = React.createRef<AutoImporterVehicleTypeContentEditor>();

    constructor(props: IProps) {
        super(props);

        const toImport = this.props.autoImporter.vehicleTypesToImport || [];
        let sorter = [
            VehicleType.Car,
            VehicleType.Commercial,
            VehicleType.Truck,
            VehicleType.Motorcycle,
            VehicleType.QuadBike,
            VehicleType.Trailers,
            VehicleType.Caravan
        ];

        sorter = filter(sorter, x => {
            if (toImport.includes(x)) {
                return true;
            }
            return false;
        })

        let mySortedArray = sorter.map(x => (toImport).find(y => y === x))

        this.state = {
            activeTabKey: (mySortedArray[0] || '').toString()
        }

        this.carsEditor = React.createRef();
        this.commercialsEditor = React.createRef();
        this.trucksEditor = React.createRef();
        this.motorcyclesEditor = React.createRef();
        this.quadbikesEditor = React.createRef();
        this.trailersEditor = React.createRef();
        this.caravansEditor = React.createRef();
    }

    validate(value?: any) {
        const autoImporter = this.props.autoImporter;

        if (this.isValidEditor(VehicleType.Car, this.carsEditor) === false) {
            return false;
        }
        if (this.isValidEditor(VehicleType.Commercial, this.commercialsEditor) === false) {
            return false;
        }
        if (this.isValidEditor(VehicleType.Truck, this.trucksEditor) === false) {
            return false;
        }
        if (this.isValidEditor(VehicleType.Motorcycle, this.motorcyclesEditor) === false) {
            return false;
        }
        if (this.isValidEditor(VehicleType.QuadBike, this.quadbikesEditor) === false) {
            return false;
        }
        if (this.isValidEditor(VehicleType.Trailers, this.trailersEditor) === false) {
            return false;
        }
        if (this.isValidEditor(VehicleType.Caravan, this.caravansEditor) === false) {
            return false;
        }
        return true;
    }

    isValidEditor(type: VehicleType, input: RefObject<IInputField>): boolean {

        if (this.props.autoImporter.vehicleTypesToImport.includes(type)) {
            let isValid = this.validateInputs([input]);

            if (isValid === false) {
                this.setState({
                    activeTabKey: type.toString()
                })
                return false;
            }
        }
        return true;
    }

    isValid(): boolean {
        return this.validate();
    }

    focus() { }

    render() {
        const { autoImporter } = this.props;
        const { activeTabKey } = this.state;

        return (
            <>
                <div className="bg-white">
                    <Tabs
                        activeKey={activeTabKey}
                        defaultActiveKey="cars"
                        id="uncontrolled-tab-example"
                        className="nav nav-justified"
                        onSelect={(key: string, e: any) => this.setState({ activeTabKey: key })}
                    >
                        {autoImporter.vehicleTypesToImport.includes(VehicleType.Car) &&
                            <Tab
                                eventKey={'1'}
                                title={"Osobní"}
                                tabClassName="nav-item vehicleType"
                            >
                                <div className="card mb-2">
                                    <div className="card-body">
                                        <p>Nadefinujte, jakým způsobem se budou vytvářet popisky na různé inz. servery. Obsah můžete také nastavit dle typu vozidla.</p>

                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-7">
                                                <AutoImporterVehicleTypeContentEditor
                                                    ref={this.carsEditor}
                                                    autoImporter={autoImporter}
                                                    handleChange={(type, server, value, key) => this.props.handleChange(type, server, value, key)}
                                                    type={VehicleType.Car}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-5 mt-4 mt-md-0">
                                                <label className="section-title mt-0 mb-4">Proměnné</label>

                                                <p>
                                                    <b>V nadpisu a popisu můžete použít následující proměnné.</b>
                                                    &nbsp;Pro zobrazení ilustrační hodnoty najeďte na proměnnou. Kliknutím ji zkopírujete.
                                                </p>

                                                <hr />
                                                <AutoImporterVariables sourceType={autoImporter.sourceType} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        }

                        {autoImporter.vehicleTypesToImport.includes(VehicleType.Commercial) &&
                            <Tab
                                eventKey={'2'}
                                title={"Užitkové"}
                                tabClassName="nav-item vehicleType"
                            >
                                <AutoImporterVehicleTypeCard autoImporter={autoImporter}>
                                    <AutoImporterVehicleTypeContentEditor
                                        ref={this.commercialsEditor}
                                        autoImporter={autoImporter}
                                        handleChange={(type, server, value, key) => this.props.handleChange(type, server, value, key)}
                                        type={VehicleType.Commercial}
                                    />
                                </AutoImporterVehicleTypeCard>
                            </Tab>}

                        {autoImporter.vehicleTypesToImport.includes(VehicleType.Truck) &&
                            <Tab
                                eventKey={'5'}
                                title={"Nákladní"}
                                tabClassName="nav-item vehicleType"
                            >
                                <AutoImporterVehicleTypeCard autoImporter={autoImporter}>
                                    <AutoImporterVehicleTypeContentEditor
                                        ref={this.trucksEditor}
                                        autoImporter={autoImporter}
                                        handleChange={(type, server, value, key) => this.props.handleChange(type, server, value, key)}
                                        type={VehicleType.Truck}
                                    />
                                </AutoImporterVehicleTypeCard>
                            </Tab>}

                        {autoImporter.vehicleTypesToImport.includes(VehicleType.Motorcycle) &&
                            <Tab
                                eventKey={'4'}
                                title={"Motorky"}
                                tabClassName="nav-item vehicleType"
                            >
                                <AutoImporterVehicleTypeCard autoImporter={autoImporter}>
                                    <AutoImporterVehicleTypeContentEditor
                                        ref={this.motorcyclesEditor}
                                        autoImporter={autoImporter}
                                        handleChange={(type, server, value, key) => this.props.handleChange(type, server, value, key)}
                                        type={VehicleType.Motorcycle}
                                    />
                                </AutoImporterVehicleTypeCard>
                            </Tab>}

                        {autoImporter.vehicleTypesToImport.includes(VehicleType.QuadBike) &&
                            <Tab
                                eventKey={'3'}
                                title={"Čtyřkolky"}
                                tabClassName="nav-item vehicleType"
                            >
                                <AutoImporterVehicleTypeCard autoImporter={autoImporter}>
                                    <AutoImporterVehicleTypeContentEditor
                                        ref={this.quadbikesEditor}
                                        autoImporter={autoImporter}
                                        handleChange={(type, server, value, key) => this.props.handleChange(type, server, value, key)}
                                        type={VehicleType.QuadBike}
                                    />
                                </AutoImporterVehicleTypeCard>
                            </Tab>}

                        {autoImporter.vehicleTypesToImport.includes(VehicleType.Trailers) &&
                            <Tab
                                eventKey={'9'}
                                title={"Přívěsy"}
                                tabClassName="nav-item vehicleType"
                            >
                                <AutoImporterVehicleTypeCard autoImporter={autoImporter}>
                                    <AutoImporterVehicleTypeContentEditor
                                        ref={this.trailersEditor}
                                        autoImporter={autoImporter}
                                        handleChange={(type, server, value, key) => this.props.handleChange(type, server, value, key)}
                                        type={VehicleType.Trailers}
                                    />
                                </AutoImporterVehicleTypeCard>
                            </Tab>}

                        {autoImporter.vehicleTypesToImport.includes(VehicleType.Caravan) &&
                            <Tab
                                eventKey={'8'}
                                title={"Obytné"}
                                tabClassName="nav-item vehicleType"
                            >
                                <AutoImporterVehicleTypeCard autoImporter={autoImporter}>
                                    <AutoImporterVehicleTypeContentEditor
                                        ref={this.caravansEditor}
                                        autoImporter={autoImporter}
                                        handleChange={(type, server, value, key) => this.props.handleChange(type, server, value, key)}
                                        type={VehicleType.Caravan}
                                    />
                                </AutoImporterVehicleTypeCard>
                            </Tab>}
                    </Tabs>
                </div>
            </>)
    }
}