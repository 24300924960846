import { Link } from "react-router-dom";
import IProduct from "../../Interfaces/IProduct";
import Product from "../../Models/Product";
import { ProductOptionsDropdown } from "../Products/ProductOptionsDropdown";

interface IProps {
    product: IProduct,
    showGoToCollectionButton: boolean,
}

export const ProductFilterActions = (props: IProps) => {
    const { product, showGoToCollectionButton } = props;

    if (product.pendingDelete) {
        return (<b className="text-danger">Inzerát čeká na smazání</b>)
    }
    else if (product.collectionMigrationPending === true) {
        return (<b className="text-danger">Probíhá změna kolekce</b>)
    }
    else {
        return (
            <div className="btn-group float-right" role="group">
                <Link to={Product.getEditLink(product)}>
                    <button className="btn btn-warning btn-sm  ">
                        <i className="far fa-edit mr-2 d-none d-md-inline"></i>
                        Upravit
                    </button>
                </Link>

                <ProductOptionsDropdown
                    product={product}
                    showDeleteButton={true}
                    showGoToCollectionButton={showGoToCollectionButton}
                    showChangeCollection={true}
                />
            </div>
        );
    }
}