import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { inject, observer } from 'mobx-react';
import { filter, orderBy, map, isEqual, ceil } from 'lodash';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import ProductStore from '../../Stores/ProductStore';
import IProduct from '../../../Interfaces/IProduct';
import LoadingScreen from '../../Screens/LoadingScreen';
import LoadingErrorScreen from '../../Screens/LoadingErrorScreen';
import ProductCollectionStore from '../../Stores/ProductCollectionStore';
import IOptionData from '../../../Interfaces/IOptionData';
import Pagination from 'react-js-pagination';
import { ProductsFilter } from '../../Products/ProductsFilter';
import { PageTitleBox } from '../../Others/PageTitleBox';
import CheckBox from '../../UI/Input/CheckBox';
import { NoProductsFound } from '../../Products/NoProductsFound';
import { ProductFilterTableItem } from '../../Products/ProductFilterTabIeItem';
import { useEffect, useState } from 'react';
import ProductApiService from '../../../Services/ProductApiService';

interface IProps {
    currentUserStore?: CurrentUserStore,
    productStore?: ProductStore
    productCollectionStore?: ProductCollectionStore,
}

const countToShow = 25;

const productApiService = new ProductApiService();

export const ProductsPage =
    inject('currentUserStore', 'productStore', 'productCollectionStore')
        (observer((props: IProps) => {

            const { currentUserStore, productCollectionStore, productStore } = props;
            const [loading, setLoading] = useState(true);
            const [loadingError, setLoadingError] = useState(false);
            const [productFilter, setProductFilter] = useState(props.currentUserStore.searchFilter);
            const [currentPage, setCurrentPage] = useState(1);
            const [productIds, setProductIds] = useState([]);
            const [totalItems, setTotalItems] = useState(0);
            const [selectedProductIds, setSelectedProductIds] = useState([]);
            const user = currentUserStore.user;

            useEffect(() => {
                handleOnChangePage(1);

                return () => {
                    if (productIds.length === 0) {
                        currentUserStore
                            .setSearchFilter(
                                {
                                    onlineServer: null,
                                    onlineServerState: null,
                                    productCollectionId: null,
                                    search: '',
                                    enabled: null
                                });
                    }
                    productApiService.cancelRequests();
                }
            }, [])


            const onFilterChange = (key: string, value: any) => {
                setProductFilter({
                    ...productFilter,
                    [key]: value,
                })
            }

            const handleOnSearchSubmit = () => {
                currentUserStore.setSearchFilter(productFilter);
                handleOnChangePage(1);
            }

            const handleOnChangePage = (pageNumber: number) => {
                setCurrentPage(pageNumber);
                setLoading(true);

                let skipCount = 0;

                if (pageNumber >= 2) {
                    skipCount = (pageNumber - 1) * countToShow;
                }

                productApiService
                    .getProducts(productFilter.search,
                        productFilter.productCollectionId,
                        productFilter.enabled,
                        productFilter.onlineServerState,
                        productFilter.onlineServer,
                        countToShow,
                        skipCount,
                        productFilter.serverAccountId)
                    .then(data => {
                        let products: IProduct[] = data.records.products.items;
                        const totalItems = data.others.get("totalCount")

                        window.scroll(0, 0);

                        setProductIds(map(products, s => s.id));
                        setTotalItems(totalItems);
                        setSelectedProductIds([]);
                    })
                    .catch(response => {
                        setLoadingError(true);
                    }).finally(() => {
                        setLoading(false);
                    })
            }


            const handleSelectProduct = (value: boolean, productId: string) => {
                let selectedProductIdsM = selectedProductIds;

                if (selectedProductIdsM.includes(productId)) {
                    if (value === false) {
                        selectedProductIdsM = filter(selectedProductIdsM, id => {
                            if (id !== productId) {
                                return true;
                            }
                            return false;
                        })
                    }
                }
                else if (value === true) {
                    selectedProductIdsM.push(productId);
                }

                setSelectedProductIds(selectedProductIdsM);
            }

            const handleCheckUncheckAll = () => {
                if (isEqual(selectedProductIds, productIds)) {
                    // uncheck all
                    setSelectedProductIds([]);
                }
                else {
                    // check all
                    setSelectedProductIds(productIds);
                }
            }

            const renderPagination = () => {
                if ((totalItems) > countToShow) {
                    return (
                        <Pagination
                            pageRangeDisplayed={countToShow}
                            activePage={currentPage}
                            itemsCountPerPage={countToShow}
                            totalItemsCount={totalItems}
                            onChange={handleOnChangePage.bind(this)}
                            linkClass="page-link"
                            itemClass="page-item"
                            innerClass="pagination justify-content-center mt-3"
                            hideDisabled={true}
                            hideFirstLastPages={true}
                        />
                    );
                }
            }

            const renderContent = () => {

                if (loading) {
                    return <LoadingScreen />
                }
                if (loadingError) {
                    return <LoadingErrorScreen />
                }

                const collections = productCollectionStore.getByUserId(user.id);

                const collectionsOptions: IOptionData[] = [];
                collectionsOptions.push({ label: 'Nezáleží', value: '' })
                collections.forEach(s => collectionsOptions.push({ label: s.name, value: s.id }));

                const products = orderBy(filter(productStore.list, product => {
                    if (product.delete === false && productIds.includes(product.id)) {
                        return true;
                    }
                    return false;

                }), prod => [prod.dateCreated], ['desc']);

                return (
                    <>
                        <ProductsFilter
                            filter={productFilter}
                            onChange={(key, value) => onFilterChange(key, value)}
                            collectionsOptions={collectionsOptions}
                            onSubmit={() => handleOnSearchSubmit()}
                        />

                        {productIds.length === 0 ?
                            <NoProductsFound />
                            :
                            <div className="card mt-1">
                                <div className="card-body">
                                    <div className="row d-none">
                                        <div className="col">
                                            <div className="ml-1 pl-2">
                                                <CheckBox
                                                    checked={isEqual(productIds, selectedProductIds)}
                                                    label=""
                                                    onChange={() => handleCheckUncheckAll()}
                                                />
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="float-right">
                                                <button
                                                    className="btn btn-outline-purple mr-1 mb-3"
                                                >
                                                    <i className="fas fa-bolt px-1"></i>  Hromadná akce
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-striped mg-b-0">
                                            <tbody>
                                                {(products).map((product: IProduct) => {
                                                    return (
                                                        <ProductFilterTableItem
                                                            key={product.id}
                                                            product={product}
                                                            onlineServer={productFilter.onlineServer}
                                                            checked={selectedProductIds.includes(product.id)}
                                                            onCheckedChange={(value) => handleSelectProduct(value, product.id)}
                                                            showGoToCollectionButton
                                                        />
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    {renderPagination()}

                                    <p className="text-center">Procházíte stranu {currentPage} z {ceil(totalItems / countToShow)}. Nalezeno {totalItems} inzerátů.</p>
                                </div>
                            </div>}
                    </>)
            }

            return (
                <CustomDocumentTitle title="Inzeráty">
                    <PageTitleBox title="Inzeráty" />

                    {renderContent()}
                </CustomDocumentTitle>
            )
        }))