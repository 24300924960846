import React from "react";
import IInputField from "../../../Interfaces/IInputField";
import BaseComponent from "../../BaseComponent";
import { InputLabel } from "./InputLabel";
import TimeField from 'react-simple-timefield';
import { InputErrors } from "./InputErrors";

interface IState {
    errors: string[],
}

interface IProps {
    required?: boolean;
    placeholder?: string;
    label?: string;
    value: string;
    onChange?(value: string): void,
    validate?: Function;
    disabled?: boolean,
    onBlur?(): void,
}


export default class InputTime extends BaseComponent<IProps, IState> implements IInputField {

    private input: React.RefObject<HTMLInputElement>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            errors: []
        }
    }

    focus?(): void {
        if (this.input)
            this.input.current.focus();
    }

    isValid() {
        return (this.getErrors() || []).length == 0;
    }

    validateInput(value: string, errors: string[]) {
        if (this.props.required && (value || '').length == 0) {
            errors.push("Toto pole je povinné");
        }
    }

    validate(value = this.props.value) {
        const errors = this.getErrors(value);
        this.setState({
            errors: errors,
        })
    }

    getErrors(value = this.props.value || '') {
        let errors: string[] = [];

        if (this.props.validate) {
            this.props.validate(
                value || '',
                errors,
                () => this.validateInput(value, errors),
            );
            if (errors.length != 0) {
                return errors;
            }
        }

        this.validateInput(value, errors);
        return errors;
    }

    handleOnBlur() {
        this.validate();
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    render() {
        const props = this.props;
        const state = this.state;

        return (
            <>
                <InputLabel label={props.label} required={props.required} />

                <TimeField
                    value={props.value}
                    onChange={(event, value) => this.props.onChange(value)}
                    input={<input
                        ref={this.input}
                        onBlur={this.handleOnBlur.bind(this)}
                        disabled={props.disabled}
                        placeholder={props.placeholder}
                        className="form-control"
                        type="text"
                    />}
                    colon=":"
                />

                <InputErrors errors={state.errors} />
            </>)
    }
}