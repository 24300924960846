import React from "react";
import { Link } from "react-router-dom";
import { IWebsite } from "../../../Interfaces/IWebsite";

interface IProps {
    websites: IWebsite[],
}

export const WebsitesWidget = (props: IProps) => {
    const websites = props.websites;

    return (
        <div className="card mb-1">
            <div className="card-header">
                Webové stránky
            </div>
            <div className="card-body">
                <div>
                    {websites.length > 0 ?
                        <div>
                            {websites.map(web => {
                                const domain = web.isCustomDomain ? web.customDomain : web.demoDomain;
                                return (
                                    <div className="my-2" key={web.id}>
                                        <i style={{ fontSize: '13px' }}
                                            className="fa fa-globe text-primary"></i>
                                            <span style={{ fontSize: '13px', paddingLeft: '8px' }} className="benefit-title text-primary">
                                                {web.name} <a target="_blank" href={`https://${domain}`}>
                                                    - {domain}
                                                </a>
                                            </span>
                                    </div>)
                            })}
                        </div> :
                        <>
                            <div>
                                <i style={{ fontSize: '13px' }}
                                    className="fa fa-check text-primary"></i>
                                <span style={{ fontSize: '13px', paddingLeft: '8px' }} className="benefit-title text-primary">
                                    Zcela automatický web s vaší nabídkou
                                </span>
                            </div>

                            <div className="mt-2 mb-3">
                                <i style={{ fontSize: '13px' }}
                                    className="fa fa-check text-primary"></i>
                                <span style={{ fontSize: '13px', paddingLeft: '8px' }} className="benefit-title text-primary">
                                    od 499 Kč / měsíc
                                </span>
                            </div>

                            <Link to='/webove-stranky'>
                                <button className="btn btn-primary btn-block">Zjistit více</button>
                            </Link>
                        </>}
                </div>
            </div>
        </div>)
}