import IProduct from "../../Interfaces/IProduct"
import { ProductShopTableItem } from "./ProductShopTableItem";

interface IProps {
    products: IProduct[],
}

export const ProductsShopTable = (props: IProps) => {

    const { products } = props;

    return (
        <div className="table-responsive">
            <table className="table mg-b-0">
                <tbody>
                    {(products).map((product: IProduct, index) => {
                        return (

                            <ProductShopTableItem
                                key={product.id}
                                product={product}
                                style={{ backgroundColor: index % 2 ? 'white' : '#f8f9fa' }}
                            />

                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}