import React from 'react';
import { CountryHelper } from '../../Helpers/CountryHelper';
import { ILocation } from '../../Interfaces/ILocation';
import BaseComponent from '../BaseComponent';
import InputText from '../UI/Input/InputText';
import SelectBox from '../UI/Input/SelectBox';
import Textarea from '../UI/Input/Textarea';
import OpeningHoursEditor from './OpeningHoursEditor';

interface IState {

}

interface IProps {
    location: ILocation,
    onChange(value: any, key: string): void,
}

export default class LocationForm extends BaseComponent<IProps, IState> {

    private name: React.RefObject<InputText>;
    private street: React.RefObject<InputText>;
    private streetRegistryNumber: React.RefObject<InputText>;
    private city: React.RefObject<InputText>;
    private zip: React.RefObject<InputText>;
    private country: React.RefObject<SelectBox>;
    private contactDetails: React.RefObject<Textarea>;
    private openingHours: React.RefObject<Textarea>;

    private openingsHoursEditor: React.RefObject<OpeningHoursEditor>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
        }

        this.name = React.createRef();
        this.street = React.createRef();
        this.streetRegistryNumber = React.createRef();
        this.city = React.createRef();
        this.zip = React.createRef();
        this.country = React.createRef();
        this.contactDetails = React.createRef();
        this.openingHours = React.createRef();
        this.openingsHoursEditor = React.createRef();
    }

    getInputs() {
        return [
            this.name,
            this.street,
            this.streetRegistryNumber,
            this.city,
            this.zip,
            this.country,
            this.contactDetails,
            this.openingHours
        ];
    }

    validate(): boolean {
        const isValid = this.validateInputs(this.getInputs());
        const isOpeningsEditorValid = this.openingsHoursEditor.current.validate();
        return isValid && isOpeningsEditorValid;
    }

    render() {
        const { location, onChange } = this.props;

        const countryOptions = CountryHelper.getCountryOptions();

        return (
            <>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <InputText
                            ref={this.name}
                            label="Název"
                            onChange={(value) => onChange(value, 'name')}
                            required={false}
                            value={location.name}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <InputText
                            ref={this.street}
                            label="Ulice"
                            value={location.street}
                            onChange={(value) => onChange(value, 'street')}
                            required={false}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <InputText
                            ref={this.streetRegistryNumber}
                            value={location.streetRegistryNumber}
                            required={false}
                            label="Číslo popisné"
                            onChange={(value) => onChange(value, 'streetRegistryNumber')}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <InputText
                            ref={this.city}
                            label="Město"
                            value={location.city}
                            onChange={(value) => onChange(value, 'city')}
                            required={false}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <InputText
                            ref={this.zip}
                            label="PSČ"
                            value={location.zip}
                            onChange={(value) => onChange(value, 'zip')}
                            required={false}
                            length={5}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <SelectBox
                            ref={this.country}
                            label="Stát"
                            value={location.country}
                            onChange={(value) => onChange(value, 'country')}
                            required={false}
                            defaultValue="Vyberte"
                            options={countryOptions}
                        />
                    </div>
                </div>

                <Textarea
                    ref={this.contactDetails}
                    label="Kontaktní informace"
                    value={location.contactDetails}
                    onChange={(value) => onChange(value, 'contactDetails')}
                    placeholder="např. telefon, email atd."
                />

                <label className="section-title pb-1">Otevírací doba</label>
                <p>Přidejte otevírací dobu pro každý den, kdy budete mít otevřeno.</p>

                <OpeningHoursEditor
                    ref={this.openingsHoursEditor}
                    onChange={(value) => onChange(value, 'openingHoursSpecifications')}
                    openingHoursSpecifications={location.openingHoursSpecifications}
                />

                <Textarea
                    ref={this.openingHours}
                    label="Otevírací doba (upřesnění)"
                    value={location.openingHours}
                    onChange={(value) => onChange(value, 'openingHours')}
                />
            </>
        )
    }
}