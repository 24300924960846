import BaseComponent from './../../BaseComponent';
import React from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import IProductCollection from '../../../Interfaces/IProductCollection';
import InputTelephone from '../../UI/Input/InputTelephone';
import InputText from '../../UI/Input/InputText';
import { BazosServer } from '../../../Enums/BazosServer';

interface IState {
    formBazosCzVerify: {
        telephone: string,
        sent: boolean,
        code: string
    },
}

interface IProps {
    collection: IProductCollection,
    show: boolean
    onHide: Function
}

export default class BazosCzVerifyModal extends BaseComponent<IProps, IState> {

    private inputBazosCzTelephone = React.createRef<InputTelephone>();
    private inputBazosCzCode = React.createRef<InputText>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            formBazosCzVerify: {
                telephone: this.props.collection.bazosCzVerificationPhone,
                sent: false,
                code: ''
            },
        }

        this.inputBazosCzTelephone = React.createRef();
        this.inputBazosCzCode = React.createRef();
    }

    submitBazosCzVerify(collection: IProductCollection) {
        if (this.state.formBazosCzVerify.sent === false) {
            axios.post(`/user/product-collections/${collection.id}/bazos/sendverifycode`, {
                telephone: this.state.formBazosCzVerify.telephone,
                bazosServer: BazosServer.CZ,
            }).then(response => {
                if (response.status === 200 && response.data.success) {
                    this.setState({
                        formBazosCzVerify: {
                            ...this.state.formBazosCzVerify,
                            sent: true
                        }
                    })
                }
            })
        }
        else {
            axios.post(`/user/product-collections/${collection.id}/bazos/submitcode`, {
                telephone: this.state.formBazosCzVerify.telephone,
                code: this.state.formBazosCzVerify.code,
                bazosServer: BazosServer.CZ,
            }).then(response => {
                if (response.data.success === true) {
                    this.props.onHide();
                }
            })
        }
    }

    handleChangeBazosCzForm(key: any, value: any) {
        this.setState({
            formBazosCzVerify: {
                ...this.state.formBazosCzVerify,
                [key]: value
            }
        });
    }

    render() {
        const collection = this.props.collection;

        if (collection.bazosCzRequiredSignIn == false) {
            return null;
        }
        return (
            <Modal dialogClassName="modal-verify-phone" centered={true} show={this.props.show} onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Ověření čísla pro Bazoš.cz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-md-2 mr-2 mt-2">
                        <InputTelephone
                            ref={this.inputBazosCzTelephone}
                            label="Telefonní číslo"
                            value={this.state.formBazosCzVerify.telephone}
                            required={!this.state.formBazosCzVerify.sent}
                            onChange={this.handleChangeBazosCzForm.bind(this, 'telephone')}
                            disabled={this.state.formBazosCzVerify.sent}
                        />
                    </div>
                    <div className="mb-md-2 mr-2 mt-2">
                        {this.state.formBazosCzVerify.sent === true && <InputText
                            ref={this.inputBazosCzCode}
                            label="Kód"
                            value={this.state.formBazosCzVerify.code}
                            required={this.state.formBazosCzVerify.sent}
                            onChange={this.handleChangeBazosCzForm.bind(this, 'code')}
                        />}
                    </div>

                    <button className="btn btn-primary" style={{ height: '35px' }}
                        onClick={this.submitBazosCzVerify.bind(this, collection)}>
                        {this.state.formBazosCzVerify.sent ? "Potvrdit kód" : "Poslat kód"}
                    </button>
                </Modal.Body>
            </Modal>
        )
    }
}