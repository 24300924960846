import { isNil } from "lodash";
import { OnlineServer } from "../Enums/OnlineServer";
import { OnlineServerState } from "../Enums/OnlineServerState";
import IProduct from "../Interfaces/IProduct";
import { IProductCategories } from "../Interfaces/IProductCategories";
import IResponseData from "../Interfaces/IResponseData";
import { IGetProductsShopRequest } from "../Interfaces/Requests/Products/IGetProductsShopRequest";
import BaseApiService from "./BaseApiService";
import IPatchObject from "../Interfaces/IPatchObject";

export default class ProductApiService extends BaseApiService {

    readonly baseUrl: string = "products";

    public validateName(productId: string, name: string, onlineServer?: OnlineServer): Promise<boolean> {
        return this.postRequest(`${this.baseUrl}/validate/name`, { name: name, onlineServer: onlineServer, productId: productId }, {})
            .then(response => {
                return response.data.success;
            })
    }

    public validateBasic(product: IProduct): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/validate/basic`, { ...product }, {})
            .then(response => {
                return response.data;
            })
    }

    public lock(productId: string): Promise<boolean> {
        return this.postRequest(`${this.baseUrl}/${productId}/lock`, {}, {})
            .then(response => {
                return response.data.success;
            })
    }

    public unlock(productId: string): Promise<boolean> {
        return this.postRequest(`${this.baseUrl}/${productId}/unlock`, {}, {})
            .then(response => {
                return response.data.success;
            })
    }

    public enable(productId: string): Promise<boolean> {
        return this.postRequest(`${this.baseUrl}/${productId}/enable`, {}, {})
            .then(response => {
                return response.data.success;
            })
    }


    public disable(productId: string): Promise<boolean> {
        return this.postRequest(`${this.baseUrl}/${productId}/disable`, {}, {})
            .then(response => {
                return response.data.success;
            })
    }

    public getErrors(): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/errors`, {})
            .then(response => {
                return response.data;
            })
    }

    public getCategories(product: IProduct): Promise<IProductCategories> {
        return this.postRequest(`${this.baseUrl}/categories`, product, {})
            .then(response => {
                const sbazarCategoryId = response.data.others.get("sbazarCategoryId");
                const bazosCategoryId = response.data.others.get("bazosCategoryId");
                const bazosSectionId = response.data.others.get("bazosSectionId");
                const googleCategoryId = response.data.others.get("googleCategoryId");

                return {
                    sbazarCategoryId: sbazarCategoryId,
                    bazosCategoryId: bazosCategoryId,
                    bazosSectionId: bazosSectionId,
                    googleCategoryId: googleCategoryId,
                };
            })
    }

    public getProducts(
        search?: string,
        productCollectionId?: string,
        enabled?: boolean,
        onlineServerState?: OnlineServerState,
        onlineServer?: OnlineServer,
        takeCount?: number,
        skipCount?: number,
        serverAccountId?: string): Promise<IResponseData> {

        return this.getRequest(`${this.baseUrl}`, {
            search,
            productCollectionId,
            enabled: (enabled === null ? '' : enabled).toString(),
            onlineServerState: (onlineServerState || '').toString(),
            onlineServer: (onlineServer || '').toString(),
            takeCount: (takeCount || 50).toString(),
            skipCount: (skipCount || 0).toString(),
            delete: 'false',
            serverAccountId: serverAccountId,
        })
            .then(response => {
                return response.data;
            })
    }

    public getShopProducts(request: IGetProductsShopRequest): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/shop`, {
            search: request.search,
            productCollectionId: request.productCollectionId || '',
            enabled: (isNil(request.enabled) ? '' : request.enabled).toString(),
            takeCount: (request.takeCount || 50).toString(),
            skipCount: (request.skipCount || 0).toString(),
            deleted: (isNil(request.deleted) ? '' : request.deleted).toString(),
        })
            .then(response => {
                return response.data;
            })
    }

    public getTrashProducts(takeCount: number, skipCount: number): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/trash`, {
            takeCount: takeCount.toString(),
            skipCount: skipCount.toString(),
        })
            .then(response => {
                return response.data;
            })
    }

    public create(product: IProduct, enable?: boolean): Promise<IResponseData> {
        if (product.customData?.brandsWithModels && product.customData?.brandsWithModels instanceof Map) {
            product.customData.brandsWithModels = this.convertMapsToPlainObjects(product.customData.brandsWithModels);
        }

        enable = enable ?? false;

        return this.postRequest(`${this.baseUrl}/`, product, { enable: enable.toString() })
            .then(response => {
                return response.data;
            })
    }

    private convertMapsToPlainObjects(obj: any): any {
        if (obj instanceof Map) {
            return Object.fromEntries(Array.from(obj.entries()).map(([key, value]) => [key, this.convertMapsToPlainObjects(value)]));
        } else if (Array.isArray(obj)) {
            return obj.map(item => this.convertMapsToPlainObjects(item));
        } else if (obj !== null && typeof obj === 'object') {
            return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, this.convertMapsToPlainObjects(value)]));
        } else {
            return obj;
        }
    }

    public edit(id: string, product: IProduct, enable: boolean): Promise<IResponseData> {
        if (product.customData?.brandsWithModels && product.customData?.brandsWithModels instanceof Map) {
            product.customData.brandsWithModels = this.convertMapsToPlainObjects(product.customData.brandsWithModels);
        }

        return this.putRequest(`${this.baseUrl}/${id}`, product, { enable: enable.toString() })
            .then(response => {
                return response.data;
            })
    }

    public getProduct(id: string): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public getLogs(id: string, server?: OnlineServer): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/${id}/logs`, { server: server ? server.toString() : null })
            .then(response => {
                return response.data;
            })
    }

    public uploadOffer(id: string, server?: OnlineServer): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/upload`, { onlineServer: server.toString() }, {})
            .then(response => {
                return response.data;
            })
    }

    public editOffer(id: string, server?: OnlineServer): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/edit`, { onlineServer: server.toString() }, {})
            .then(response => {
                return response.data;
            })
    }

    public topOffer(id: string, server?: OnlineServer): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/top`, { onlineServer: server.toString() }, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteOffer(id: string, server?: OnlineServer): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/delete`, { onlineServer: server.toString() }, {})
            .then(response => {
                return response.data;
            })
    }

    public checkOffer(id: string, server?: OnlineServer): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/check`, { onlineServer: server.toString() }, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteProduct(id: string, isSold: boolean, salePrice: number, costPrice: number, dateOfSale?: Date): Promise<IResponseData> {
        return this.deleteRequest(`${this.baseUrl}/${id}`, {
            isSold: isSold,
            salePrice: salePrice,
            costPrice: costPrice,
            dateOfSale: dateOfSale
        }, {})
            .then(response => {
                return response.data;
            })
    }

    public generateDescription(title: string, info: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/generate-description`, {
            title: title,
            info: info,
        }, {})
            .then(response => {
                return response.data;
            })
    }

    public getRequiredFields(product: IProduct): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/required-fields`, product, {})
            .then(response => {
                return response.data;
            })
    }

    public getRequiredFieldsById(id: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${id}/required-fields`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public restore(productId: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${productId}/restore`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public changeCollection(product: IProduct, newCollectionId: string): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/${product.id}/change-collection`, { newCollectionId: newCollectionId }, {})
            .then(response => {
                return response.data;
            })
    }

    public patchProduct(product: IProduct, objects: IPatchObject[]): Promise<IResponseData> {
        return this.patchRequest(`${this.baseUrl}/${product.id}`, objects)
            .then(response => {
                return response.data;
            })
    }

    public getMissingFields(product: IProduct, useAi: boolean): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/missing-fields`, product, { useAi: useAi.toString() })
            .then(response => {
                return response.data;
            })
    }

    public deleteAllImages(id: string): Promise<IResponseData> {
        return this.deleteRequest(`${this.baseUrl}/${id}/images`, {}, {})
            .then(response => {
                return response.data;
            })
    }
}