import CustomDocumentTitle from '../Utils/CustomDocumentTitle';

export const NotFoundScreen = () => {
    return (
        <CustomDocumentTitle title="Stránka neexistuje">
            <div className="card">
                <div className="card-body">
                    <div className="py-3">
                        <div className="d-flex justify-content-center mb-3 mt-2">
                            <i className="far fa-sad-tear fa-3x"></i>
                        </div>

                        <br />
                        <div className="text-center">
                            <h3>Stránka neexistuje</h3>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    )
}