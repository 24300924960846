import { orderBy } from "lodash";
import { Currency } from "../Enums/Currency";
import { WebsiteType } from "../Enums/WebsiteType";
import IOptionData from "../Interfaces/IOptionData";
import { IWebsite } from "../Interfaces/IWebsite";
import IWebsitePricingItem from "../Interfaces/IWebsitePricingItem";
import CurrencyHelper from "./CurrencyHelper";
import StringHelper from "./StringHelper";

export default class WebsiteHelper {

    public static getOptions(websites: IWebsite[]): IOptionData[] {

        let options: IOptionData[] = [];
        websites.map(website => options.push({label: `${website.name} - ${website.customDomain || website.demoDomain}`, value: website.id }));
        return options;
    }

    public static getPricing(currency: Currency): IWebsitePricingItem[]  {
        let items: IWebsitePricingItem[] = [];

        if (currency === Currency.Czk) {
            items = [
                { productMax: 50, pricePerMonth: 499 },
                { productMax: 100, pricePerMonth: 749 },
                { productMax: 250, pricePerMonth: 999 },
                { productMax: 500, pricePerMonth: 1499 },
              /*  { productMax: 1000, pricePerMonth: 1999 },
                { productMax: 2000, pricePerMonth: 2499 },*/
            ];
        }
        else if (currency === Currency.Eur) {
            items = [
                { productMax: 50, pricePerMonth: 20 },
                { productMax: 100, pricePerMonth: 30 },
                { productMax: 250, pricePerMonth: 40 },
                { productMax: 500, pricePerMonth: 60 },
             /*   { productMax: 1000, pricePerMonth: 80 },
                { productMax: 2000, pricePerMonth: 100 },*/
            ];
        }

        items = orderBy(items, ['productMax'], ['desc']);
        return items;
    }

    public static getPricingOptions(currency: Currency): IOptionData[] {
        let options: IOptionData[] = [];

        const items = this.getPricing(currency);

        items.map(item => {
            options.push({ label: `${item.productMax} inzerátů, ${StringHelper.separateThousands(item.pricePerMonth)} ${CurrencyHelper.getSymbol(currency)} / měsíc`, value: item.productMax });
        })

        return options;
    }

    public static getTypeOptions(): IOptionData[] {
        let options: IOptionData[] = [
            { label: this.getTypeLabel(WebsiteType.Cars), value: WebsiteType.Cars },
            { label: this.getTypeLabel(WebsiteType.Products), value: WebsiteType.Products },
        ];
        return options;
    }

    public static getTypeLabel(type: WebsiteType) : string {
        switch (type) {

            case WebsiteType.Cars:
                return 'Autobazar';

            case WebsiteType.Products:
                return 'Ostatní produkty';
        }
    }
}