import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FacebookAccountLoginErrorType } from '../../../Enums/FacebookAccountLoginErrorType';
import CurrencyHelper from '../../../Helpers/CurrencyHelper';
import IInputField from '../../../Interfaces/IInputField';
import { IServerAccount } from '../../../Interfaces/IServerAccount';
import BaseComponent from '../../BaseComponent';
import AppIpAddressStore from '../../Stores/AppIpAddressStore';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import InputEmail from '../../UI/Input/InputEmail';
import InputText from '../../UI/Input/InputText';
import SelectBox from '../../UI/Input/SelectBox';

interface IState {
    accountForm: IServerAccount,
}

interface IProps {
    onHide(): void,
    accountServer: IServerAccount,
    appIpAddressStore?: AppIpAddressStore,
    currentUserStore?: CurrentUserStore,
    showSubmitCode(accountId: string): void,
}

@inject('appIpAddressStore', 'currentUserStore')
@observer
export default class FacebookAccountLoginModal extends BaseComponent<IProps, IState> {

    private inputName = React.createRef<InputText>();
    private inputEmail = React.createRef<InputEmail>();
    private inputPass = React.createRef<InputText>();
    private inputAppIp = React.createRef<SelectBox>();
    private inputCurrency = React.createRef<SelectBox>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            accountForm: this.props.accountServer,
        }

        this.inputName = React.createRef();
        this.inputEmail = React.createRef();
        this.inputPass = React.createRef();
        this.inputAppIp = React.createRef();
        this.inputCurrency = React.createRef();
    }

    validate() {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            this.inputName,
            this.inputEmail,
            this.inputPass,
            this.inputCurrency,
            this.inputAppIp,
        )
        return this.validateInputs(inputs);
    }

    handleChange(key: any, value: any) {
        this.setState({
            accountForm: {
                ...this.state.accountForm,
                [key]: value
            }
        })
    }

    handleSubmitForm() {
        const accountId = this.props.accountServer.id;

        if (this.validate()) {
            this.facebookAccountsApiService.editAccount(accountId, this.state.accountForm)
                .then(data => {
                    var loginErrorType = data.others.get("loginErrorType") as FacebookAccountLoginErrorType;

                    if (!loginErrorType) {
                        this.props.onHide();
                    }
                    else if (loginErrorType === FacebookAccountLoginErrorType.AccountNotExists) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Účet zřejmě neexistuje',
                            text: 'Nenašli jsme účet se zadanými údaji. Zkontrolujte správnost přihlašovacích údajů a zkuste to znovu.',
                        });
                    }
                    else if (loginErrorType === FacebookAccountLoginErrorType.IncorrectCredentials) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Špatné přihlašovací údaje',
                            text: 'Nepodařilo se nám přihlásit na váš účet. Zkontrolujte správnost přihlašovacích údajů a zkuste to znovu.',
                        });
                    }
                    else if (loginErrorType === FacebookAccountLoginErrorType.TwoFactorSignInRequired) {
                        // send sms
                        this.facebookAccountsApiService.sendVerificationSms(accountId)
                            .then(sendSmsResultData => {
                                this.props.showSubmitCode(accountId);
                                this.props.onHide();
                            });
                    }
                });
        }
    }

    render() {
        const user = this.props.currentUserStore.user;
        const form = this.state.accountForm;
        var ipOptions = this.props.appIpAddressStore.getOptions(user.id);
        const accountServer = this.props.accountServer;

        return (
            <Modal centered={true} show={true} onHide={() => { }}>
                <Modal.Header>
                    <Modal.Title>Propojení "{accountServer.userName}" FB účtu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Na váš účet se bude Inzerobot přihlašovat za účelem správy inzerce.</p>
                        <div className="row">
                            <div className="col-lg-12">
                                <InputText
                                    ref={this.inputName}
                                    label="Libovolné označení tohoto účtu"
                                    value={form.name}
                                    required={true}
                                    onChange={this.handleChange.bind(this, 'name')}
                                />

                                <label className="section-title mt-0 mb-4">PŘIHLAŠOVACÍ ÚDAJE</label>
                                <hr />

                                <InputEmail
                                    ref={this.inputEmail}
                                    label="E-mail"
                                    value={form.userName}
                                    required={true}
                                    placeholder=""
                                    onChange={this.handleChange.bind(this, 'userName')}
                                />

                                <InputText
                                    ref={this.inputPass}
                                    label="Heslo"
                                    value={form.password}
                                    required={true}
                                    onChange={this.handleChange.bind(this, 'password')}
                                />

                                <SelectBox
                                    ref={this.inputCurrency}
                                    label="Měna"
                                    onChange={this.handleChange.bind(this, 'currency')}
                                    options={CurrencyHelper.getOptions()}
                                    value={form.currency}
                                    defaultValue="Vyberte"
                                    required
                                />

                                <SelectBox
                                    ref={this.inputAppIp}
                                    label="IP adresa"
                                    onChange={this.handleChange.bind(this, 'appIpAddressId')}
                                    options={ipOptions}
                                    value={form.appIpAddressId}
                                    defaultValue="Vyberte"
                                    required
                                />

                                <label className="section-title mt-0 mb-2">PŘED PROPOJENÍM, PROSÍM ZKONTROLUJTE:</label>
                                <ol>
                                    <li>Rozhraní Facebooku musíte mít nastaveno na Češtinu</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide.bind(this)} variant="secondary" size="lg">
                        Zavřít
                    </Button>
                    <Button variant="primary" size="lg" onClick={this.handleSubmitForm.bind(this)}>
                        Propojit
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}