import IFacebookCatalog from "../Interfaces/IFacebookCatalog";
import IOptionData from "../Interfaces/IOptionData";

export default class FacebookCatalogHelper {

    public static getOptions(catalogs: IFacebookCatalog[]): IOptionData[] {

        let options: IOptionData[] = [];
        catalogs.map(catalog => options.push({ label: catalog.name, value: catalog.id }));
        return options;
    }
}