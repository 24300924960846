import IProduct from '../../../../../Interfaces/IProduct';
import IProductCollection from '../../../../../Interfaces/IProductCollection';
import ProductStore from '../../../../Stores/ProductStore';
import { inject, observer } from 'mobx-react';
import { filter } from 'lodash';
import ProductImageStore from '../../../../Stores/ProductImageStore';
import Swal from 'sweetalert2';
import { LeavePageWarningPrompt } from '../../../../Others/LeavePageWarningPrompt';
import IResponseData from '../../../../../Interfaces/IResponseData';
import { useEffect, useState } from 'react';
import ProductApiService from '../../../../../Services/ProductApiService';
import { useNavigate } from 'react-router-dom';
import { InfoAlert } from '../../../../Others/Alerts/InfoAlert';
import { ProductImagesEditor } from '../ProductImagesEditor';
import { NotFound } from '../../../../Pages/NotFound';

interface IProps {
    collection: IProductCollection,
    productId: string,
    productStore?: ProductStore,
    productImageStore?: ProductImageStore,
    onSaveChanges?(): Promise<IResponseData>,
}

const productApiService = new ProductApiService();

export const ProductWizardStepImages = inject('productImageStore', 'productStore')(observer((props: IProps) => {

    const [blockLeave, setBlockLeave] = useState(true);
    const [redirectToCollection, setRedirectToCollection] = useState(false);
    const navigate = useNavigate();

    const { collection, productId, productStore, productImageStore, onSaveChanges } = props;


    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])

    const handleEnable = (product: IProduct) => {
        productApiService.enable(product.id)
            .then(result => {
                pushToCollection();
            })
    }

    const pushToCollection = () => {
        setBlockLeave(false);
        setRedirectToCollection(true);
    }

    useEffect(() => {
        if (redirectToCollection) {
            navigate(`/nahravac/kolekce/${collection.id}/prehled`);
        }
    }, [redirectToCollection])


    const handleDoNotSave = () => {
        Swal.fire({
            icon: 'warning',
            title: "Chcete uložit změny?",
            text: "Pokud jste provedli změny v inzerátu, je potřeba je uložit.",
            confirmButtonText: "Uložit změny",
            cancelButtonText: "Neukládat",
            showCancelButton: true,
        }).then(response => {
            if (response.value) {
                // save
                onSaveChanges()
                    .then(() => {
                        pushToCollection();
                    })
            }
            else {
                pushToCollection();
            }
        });
    }

    const handleSaveChanges = () => {
        onSaveChanges()
            .then(() => {
                pushToCollection();
            })
    }

    const product = productStore.getById(productId);

    if (!product) {
        return <NotFound />
    }

    const images = filter(productImageStore.list, { productId: product.id });

    return (
        <div>
            {onSaveChanges && <InfoAlert text={"Nezapomeňte změny uložit"} />}

            <LeavePageWarningPrompt when={blockLeave} />

            <div className="row justify-content-center">
                <div className="w-75">
                    <ProductImagesEditor
                        images={images}
                        product={product}
                    />
                </div>
            </div>

            <div className="mg-t-30 text-center">
                <div className="mt-4">
                    <div className="wizardStepNavigation form-layout-footer">
                        {onSaveChanges &&
                            <>
                                <button
                                    className="btn btn-primary px-sm-4"
                                    onClick={() => handleSaveChanges()}
                                >
                                    <i className="fas fa-save mr-2"></i>
                                    Uložit změny
                                </button>

                                <button
                                    onClick={() => handleDoNotSave()}
                                    className="btn btn-secondary px-sm-4"
                                >
                                    <i className="fas fa-arrow-right mr-2" />
                                    Přejít do kolekce
                                </button>
                            </>}

                        {product.enabled === false && !onSaveChanges &&
                            <>
                                <button
                                    className="btn btn-primary px-sm-4"
                                    onClick={() => handleEnable(product)}
                                >
                                    <i className="fa fa-upload mr-2"></i>
                                    Zveřejnit inzerát</button>

                                <button
                                    onClick={() => pushToCollection()}
                                    className="btn btn-secondary px-sm-4">
                                    <i className="fas fa-arrow-right mr-2" />
                                    Přejít do kolekce
                                </button>
                            </>}
                    </div>
                </div>
            </div>
        </div>)
}))