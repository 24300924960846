import { inject, observer } from "mobx-react";
import { Tab, Tabs } from "react-bootstrap";
import { OnlineServer } from "../../../Enums/OnlineServer";
import { ProductHelper } from "../../../Helpers/ProductHelper";
import IProduct from "../../../Interfaces/IProduct";
import Product from "../../../Models/Product";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import ProductCollectionStore from "../../Stores/ProductCollectionStore";
import ProductLogStore from "../../Stores/ProductLogStore";
import ProductServerAccountStore from "../../Stores/ProductServerAccountStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import { ProductServerLabelContent } from "../ProductServerLabelContent";
import { AutobazarEuTabContent } from "./AutobazarEuTabContent";
import { FbShopTabContent } from "./FbShopTabContent";
import { ManagedServerTabContent } from "./ManagedServerTabContent";
import { ProductServerTitle } from "./ProductServerTitle";
import { SautoTabContent } from "./SautoTabContent";
import { TipMotoTabContent } from "./TipMotoTabContent";
import { WebTabContent } from "./WebTabContent";

interface IProps {
    product: IProduct,
    activeKey?: string,
    onKeyChange(key: string): void,
    currentUserStore?: CurrentUserStore,
    productServerAccountStore?: ProductServerAccountStore,
    productCollectionStore?: ProductCollectionStore,
    websiteStore?: WebsiteStore,
    productLogStore?: ProductLogStore,
}

export const ProductServerModalTabs = inject('currentUserStore', 'productServerAccountStore', 'productCollectionStore', 'websiteStore', 'productLogStore')(observer((props: IProps) => {
    const { product, activeKey, onKeyChange } = props;

    var user = props.currentUserStore.user;
    const collection = props.productCollectionStore.getById(product.productCollectionId);

    const servers = ProductHelper.getOnlineServers(product, collection, user);

    const productServerAccounts = props.productServerAccountStore.getByProductId(product.id);

    const serverTabs = servers.map(server => {
        const productLogs = props.productLogStore.getByProductServer(product.id, server);
        const info = Product.getProductServerInfo(product, collection, server, user, productServerAccounts);

        switch (server) {

            case OnlineServer.Website:
                const website = props.websiteStore.getById(product.websiteId);

                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.Website.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    <WebTabContent info={info} website={website} />
                </Tab>

            case OnlineServer.BazosCz:
                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.BazosCz.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    <ManagedServerTabContent
                        info={info}
                        logs={productLogs}
                        product={product}
                        collection={collection}
                    />
                </Tab>

            case OnlineServer.BazosSk:
                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.BazosSk.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    <ManagedServerTabContent
                        info={info}
                        logs={productLogs}
                        product={product}
                        collection={collection}
                    />
                </Tab>

            case OnlineServer.Sbazar:
                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.Sbazar.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    <ManagedServerTabContent
                        info={info}
                        logs={productLogs}
                        product={product}
                        collection={collection}
                    />
                </Tab>

            case OnlineServer.FacebookShop:
                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.FacebookShop.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    <FbShopTabContent product={product} />
                </Tab>

            case OnlineServer.FacebookMarketplace:
                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.FacebookMarketplace.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    <ManagedServerTabContent
                        info={info}
                        logs={productLogs}
                        product={product}
                        collection={collection}
                    />
                </Tab>;

            case OnlineServer.TipMoto:
                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.TipMoto.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    <TipMotoTabContent product={product} user={user} />
                </Tab>

            case OnlineServer.Sauto:
                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.Sauto.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    <SautoTabContent
                        info={info}
                        product={product}
                        user={user}
                    />
                </Tab>

            case OnlineServer.AutobazarEu:
                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.AutobazarEu.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    <AutobazarEuTabContent product={product} user={user} />
                </Tab>

            case OnlineServer.GoogleMerchant:
                return <Tab
                    key={server.toString()}
                    eventKey={OnlineServer.GoogleMerchant.toString()}
                    title={<ProductServerLabelContent
                        info={info}
                        onClick={() => { }}
                    />}
                    tabClassName="nav-item nav-item-servers"
                >
                    <ProductServerTitle info={info} />
                    <hr />
                    {/* <AutobazarEuTabContent product={product} user={user} />*/}
                </Tab>

            default:
                return null;
        }
    });

    return (
        <div className="tabs-servers">
            <Tabs
                activeKey={activeKey}
                id="uncontrolled-tab-example"
                className="nav nav-justified"
                onSelect={(key: string, e: any) => onKeyChange(key)}
            >
                {serverTabs}
            </Tabs>
        </div>
    );
}))