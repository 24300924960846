import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CurrencyHelper from '../../../Helpers/CurrencyHelper';
import IInputField from '../../../Interfaces/IInputField';
import { IServerAccount } from '../../../Interfaces/IServerAccount';
import { IAddFbAccountRequest } from '../../../Interfaces/Requests/FacebookAccounts/IAddFbAccountRequest';
import BaseComponent from '../../BaseComponent';
import AppIpAddressStore from '../../Stores/AppIpAddressStore';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import InputEmail from '../../UI/Input/InputEmail';
import InputText from '../../UI/Input/InputText';
import SelectBox from '../../UI/Input/SelectBox';
import { FacebookAccountChooseLoginType } from './FacebookAccountChooseLoginType';

interface IState {
    addFbAccountForm: IAddFbAccountRequest,
    account: IServerAccount,
    showChooseLoginType: boolean,
}

interface IProps {
    appIpAddressStore?: AppIpAddressStore,
    currentUserStore?: CurrentUserStore,
    showAddAccountModal: boolean,
    onChangeShowAddModal(value: boolean): void,
}

@inject('appIpAddressStore', 'currentUserStore')
@observer
export default class AddNewFacebookAccount extends BaseComponent<IProps, IState> {

    private inputName = React.createRef<InputText>();
    private inputEmail = React.createRef<InputEmail>();
    private inputAppIp = React.createRef<SelectBox>();
    private inputCurrency = React.createRef<SelectBox>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            addFbAccountForm: {
                appIpAddressId: '',
                email: '',
                name: '',
                password: '',
                currency: null,
            },
            account: null,
            showChooseLoginType: false,
        }

        this.inputName = React.createRef();
        this.inputEmail = React.createRef();
        this.inputAppIp = React.createRef();
        this.inputCurrency = React.createRef();
    }

    validate() {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            this.inputName,
            this.inputCurrency,
            this.inputAppIp,
            this.inputEmail,
        )
        return this.validateInputs(inputs);
    }

    handleChange(key: any, value: any) {
        this.setState({
            addFbAccountForm: {
                ...this.state.addFbAccountForm,
                [key]: value
            }
        })
    }

    handleSubmitForm() {
        if (this.validate()) {
            this.facebookAccountsApiService.addAccount(this.state.addFbAccountForm)
                .then(data => {
                    const acc = data.records.serverAccounts.items[0];

                    this.props.onChangeShowAddModal(false);

                    this.setState({
                        showChooseLoginType: true,
                        account: acc,
                    })
                });
        }
    }

    render() {
        const user = this.props.currentUserStore.user;
        const form = this.state.addFbAccountForm;
        const ipOptions = this.props.appIpAddressStore.getOptions(user.id);
        const { account, showChooseLoginType } = this.state;
        const { showAddAccountModal, onChangeShowAddModal } = this.props;

        return (
            <>
                <FacebookAccountChooseLoginType
                    showChooseType={showChooseLoginType}
                    onChangeShow={(value) => this.setState({ showChooseLoginType: value })}
                    account={account}
                />

                {showAddAccountModal &&
                    <Modal centered={true} show={true} onHide={() => { }}>
                        <Modal.Header>
                            <Modal.Title>Přidání FB účtu</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <InputText
                                            ref={this.inputName}
                                            label="Libovolné označení tohoto účtu"
                                            value={form.name}
                                            required={true}
                                            onChange={this.handleChange.bind(this, 'name')}
                                        />

                                        <SelectBox
                                            ref={this.inputCurrency}
                                            label="Měna"
                                            onChange={this.handleChange.bind(this, 'currency')}
                                            options={CurrencyHelper.getOptions()}
                                            value={form.currency}
                                            defaultValue="Vyberte"
                                            required
                                        />

                                        <SelectBox
                                            ref={this.inputAppIp}
                                            label="IP adresa"
                                            onChange={this.handleChange.bind(this, 'appIpAddressId')}
                                            options={ipOptions}
                                            value={form.appIpAddressId}
                                            defaultValue="Vyberte"
                                            required
                                        />

                                        <InputEmail
                                            ref={this.inputEmail}
                                            label="Přihlašovací e-mail"
                                            value={form.email}
                                            required={true}
                                            placeholder=""
                                            onChange={this.handleChange.bind(this, 'email')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => onChangeShowAddModal(false)} variant="secondary" size="lg">
                                Zavřít
                            </Button>
                            <Button variant="primary" size="lg" onClick={this.handleSubmitForm.bind(this)}>
                                Přidat účet
                            </Button>
                        </Modal.Footer>
                    </Modal>}
            </>
        )
    }
}