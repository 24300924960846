import React from 'react';
import IProduct from '../../../../../Interfaces/IProduct';
import IProductCollection from '../../../../../Interfaces/IProductCollection';
import { observer } from 'mobx-react';
import SelectBox from '../../../../UI/Input/SelectBox';
import Product from '../../../../../Models/Product';
import InputNumber from '../../../../UI/Input/InputNumber';
import InputText from '../../../../UI/Input/InputText';
import IInputField from '../../../../../Interfaces/IInputField';
import InputDouble from '../../../../UI/Input/InputDouble';
import { CountryHelper } from '../../../../../Helpers/CountryHelper';
import { LeavePageWarningPrompt } from '../../../../Others/LeavePageWarningPrompt';
import { ValidationHelper } from '../../../../Utils/ValidationHelper';

interface IState {

}

interface IProps {
    onChange: Function,
    product: IProduct,
    collection: IProductCollection,
}

@observer
export default class ProductWizardStepVehicleDetails extends React.Component<IProps, IState> {

    private vehicleVin: React.RefObject<InputText>;
    private vehicleBrand: React.RefObject<SelectBox>;
    private vehicleBody: React.RefObject<SelectBox>;
    private vehicleModel: React.RefObject<SelectBox>;
    private vehicleCondition: React.RefObject<SelectBox>;
    private vehicleMiliage: React.RefObject<InputNumber>;
    private vehicleYear: React.RefObject<SelectBox>;
    private vehicleTransmission: React.RefObject<SelectBox>;
    private vehicleFuelType: React.RefObject<SelectBox>;
    private vehicleFuelConsumptionCombinated: React.RefObject<InputDouble>;
    private vehicleCubicCapacity: React.RefObject<InputNumber>;
    private vehiclePower: React.RefObject<InputNumber>;
    private vehicleEmissionClass: React.RefObject<SelectBox>;
    private vehicleDoorCount: React.RefObject<InputNumber>;
    private vehicleNumberOfSeats: React.RefObject<InputNumber>;
    private vehicleNumberOfVehicleOwners: React.RefObject<InputNumber>;
    private vehicleCountryOrigin: React.RefObject<SelectBox>;
    private vehicleColor: React.RefObject<SelectBox>;
    private vehicleWeight: React.RefObject<InputNumber>;

    constructor(props: IProps) {
        super(props);

        this.vehicleVin = React.createRef();
        this.vehicleBrand = React.createRef();
        this.vehicleBody = React.createRef();
        this.vehicleModel = React.createRef();
        this.vehicleCondition = React.createRef();
        this.vehicleMiliage = React.createRef();
        this.vehicleYear = React.createRef();
        this.vehicleTransmission = React.createRef();
        this.vehicleFuelType = React.createRef();
        this.vehicleFuelConsumptionCombinated = React.createRef();
        this.vehicleCubicCapacity = React.createRef();
        this.vehiclePower = React.createRef();
        this.vehicleEmissionClass = React.createRef();
        this.vehicleDoorCount = React.createRef();
        this.vehicleNumberOfSeats = React.createRef();
        this.vehicleNumberOfVehicleOwners = React.createRef();
        this.vehicleCountryOrigin = React.createRef();
        this.vehicleColor = React.createRef();
        this.vehicleWeight = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    validate() {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            this.vehicleBrand,
            this.vehicleModel,
            this.vehicleVin,
            this.vehicleBody,
            this.vehicleCondition,
            this.vehicleMiliage,
            this.vehicleYear,
            this.vehicleTransmission,
            this.vehicleEmissionClass,

            this.vehicleFuelType,
            this.vehicleFuelConsumptionCombinated,
            this.vehicleCubicCapacity,
            this.vehiclePower,


            this.vehicleDoorCount,
            this.vehicleNumberOfSeats,
            this.vehicleColor,
            this.vehicleWeight,

            this.vehicleNumberOfVehicleOwners,
            this.vehicleCountryOrigin,
        )
        return ValidationHelper.validateInputs(inputs);
    }

    render() {
        const product = this.props.product;

        return (
            <div>
                <div className="alert alert-secondary" role="alert">
                    Díky těmto údajům zařadíme váš inzerát do správné kategorie na inzertních serverech v dalším kroku.
                </div>

                <LeavePageWarningPrompt when={true} />

                <div className="mx-2 my-3">
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="section-title pb-2">Údaje o vozidle</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <SelectBox
                                        ref={this.vehicleBrand}
                                        defaultValue="- nevybráno -"
                                        required={true}
                                        label="Značka"
                                        onChange={this.props.onChange.bind(this, 'vehicleBrand')}
                                        value={product.vehicleBrand}
                                        options={Product.getVehicleBrandOptions()}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBox
                                        ref={this.vehicleModel}
                                        required
                                        defaultValue="- nevybráno -"
                                        label="Model"
                                        onChange={this.props.onChange.bind(this, 'vehicleModel')}
                                        value={product.vehicleModel}
                                        options={Product.getVehicleModelOptions(product.vehicleBrand)}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <InputText
                                        ref={this.vehicleVin}
                                        label="VIN"
                                        onChange={this.props.onChange.bind(this, 'vehicleVin')}
                                        value={product.vehicleVin}
                                        length={17}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBox
                                        ref={this.vehicleBody}
                                        defaultValue="- nevybráno -"
                                        label="Karoserie"
                                        onChange={this.props.onChange.bind(this, 'vehicleBody')}
                                        value={product.vehicleBody}
                                        options={Product.getVehicleBodyOptions(product.vehicleType)}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBox
                                        required
                                        ref={this.vehicleCondition}
                                        defaultValue="- nevybráno -"
                                        label="Stav"
                                        onChange={this.props.onChange.bind(this, 'vehicleCondition')}
                                        value={product.vehicleCondition}
                                        options={Product.getVehicleConditionOptions()}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <InputNumber
                                        ref={this.vehicleMiliage}
                                        label="Tachometr (km)"
                                        onChange={this.props.onChange.bind(this, 'vehicleMileage')}
                                        value={product.vehicleMileage}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBox
                                        ref={this.vehicleYear}
                                        defaultValue="- nevybráno -"
                                        label="Rok první registrace"
                                        onChange={this.props.onChange.bind(this, 'vehicleYear')}
                                        value={product.vehicleYear}
                                        options={Product.getVehicleYearOptions()}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label>Platnost STK</label>
                                    <div className="row no-gutters">
                                        <div className="col">
                                            <SelectBox
                                                defaultValue="Měsíc"
                                                label=""
                                                onChange={this.props.onChange.bind(this, 'vehicleMOTMonth')}
                                                value={product.vehicleMOTMonth}
                                                options={Product.getVehicleMotMonthOptions()}
                                            />
                                        </div>
                                        <div className="col">
                                            <SelectBox
                                                defaultValue="Rok"
                                                label=""
                                                onChange={this.props.onChange.bind(this, 'vehicleMOTYear')}
                                                value={product.vehicleMOTYear}
                                                options={Product.getVehicleMotYearOptions()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <label className="section-title pb-2">Motorizace</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <SelectBox
                                        ref={this.vehicleTransmission}
                                        defaultValue="- nevybráno -"
                                        label="Převodovka"
                                        onChange={this.props.onChange.bind(this, 'vehicleTransmission')}
                                        value={product.vehicleTransmission}
                                        options={Product.getVehicleTransmissionOptions()}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBox
                                        ref={this.vehicleEmissionClass}
                                        defaultValue="- nevybráno -"
                                        label="Plní EURO"
                                        onChange={this.props.onChange.bind(this, 'vehicleEmissionClass')}
                                        value={product.vehicleEmissionClass}
                                        options={Product.getEmissionClassOptions()}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBox
                                        ref={this.vehicleFuelType}
                                        defaultValue="- nevybráno -"
                                        label="Palivo"
                                        onChange={this.props.onChange.bind(this, 'vehicleFuelType')}
                                        value={product.vehicleFuelType}
                                        options={Product.getFuelTypeOptions()}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <InputDouble
                                        ref={this.vehicleFuelConsumptionCombinated}
                                        label="Kombinovaná spotřeba (l/100 km)"
                                        onChange={this.props.onChange.bind(this, 'vehicleFuelConsumptionCombinated')}
                                        value={product.vehicleFuelConsumptionCombinated}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <InputNumber
                                        ref={this.vehicleCubicCapacity}
                                        label="Objem válců (ccm)"
                                        onChange={this.props.onChange.bind(this, 'vehicleCubicCapacity')}
                                        value={product.vehicleCubicCapacity}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <InputNumber
                                        ref={this.vehiclePower}
                                        label="Výkon (kw)"
                                        onChange={this.props.onChange.bind(this, 'vehiclePower')}
                                        value={product.vehiclePower}
                                    />
                                </div>
                            </div>
                            <label className="section-title pb-2">Karoserie</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <InputNumber
                                        ref={this.vehicleDoorCount}
                                        label="Počet dveří"
                                        onChange={this.props.onChange.bind(this, 'vehicleDoorCount')}
                                        value={product.vehicleDoorCount}
                                        maxValue={100}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <InputNumber
                                        ref={this.vehicleNumberOfSeats}
                                        label="Počet sedadel"
                                        onChange={this.props.onChange.bind(this, 'vehicleNumberOfSeats')}
                                        value={product.vehicleNumberOfSeats}
                                        maxValue={100}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBox
                                        ref={this.vehicleColor}
                                        defaultValue="- nevybráno -"
                                        label="Barva"
                                        onChange={this.props.onChange.bind(this, 'vehicleColor')}
                                        value={product.vehicleColor}
                                        options={Product.getColorOptions()}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <InputNumber
                                        ref={this.vehicleWeight}
                                        label="Hmotnost (kg)"
                                        onChange={this.props.onChange.bind(this, 'vehicleWeight')}
                                        value={product.vehicleWeight}
                                    />
                                </div>
                            </div>

                            <label className="section-title pb-2">Doplňující údaje</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <InputNumber
                                        ref={this.vehicleNumberOfVehicleOwners}
                                        label="Počet majitelů"
                                        onChange={this.props.onChange.bind(this, 'vehicleNumberOfVehicleOwners')}
                                        value={product.vehicleNumberOfVehicleOwners}
                                        maxValue={10}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBox
                                        ref={this.vehicleCountryOrigin}
                                        defaultValue="- nevybráno -"
                                        label="Země původu"
                                        onChange={this.props.onChange.bind(this, 'vehicleCountryOrigin')}
                                        value={product.vehicleCountryOrigin}
                                        options={CountryHelper.getCountryOptions()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}