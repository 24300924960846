import React from 'react';
import axios, { AxiosResponse } from 'axios';
import IInputField from '../Interfaces/IInputField';
import FacebookPagesApiService from '../Services/FacebookPagesApiService';
import FacebookCatalogsApiService from '../Services/FacebookCatalogsApiService';
import FacebookApiService from '../Services/FacebookApiService';
import WebsitesApiService from '../Services/WebsitesApiService';
import UserApiService from '../Services/UserApiService';
import { toast } from 'react-toastify';
import ProductApiService from '../Services/ProductApiService';
import AccountTransactionsApiService from '../Services/AccounTransactionsApiService';
import ProductCollectionApiService from '../Services/ProductCollectionsApiService';
import ProductImageApiService from '../Services/ProductImagesApiService';
import ProformaInvoicesApiService from '../Services/ProformaInvoicesApiService';
import AutoimportersApiService from '../Services/AutoimportersApiService';
import BazosApiService from '../Services/BazosApiService';
import ContactApiService from '../Services/ContactApiService';
import ServerAccountsApiService from '../Services/ServerAccountsApiService';
import TipMotoApiService from '../Services/TipMotoApiService';
import FacebookAccountsApiService from '../Services/FacebookAccountsApiService';
import CurrencyApiService from '../Services/CurrencyApiService';
import GoogleApiService from '../Services/GoogleApiService';
import { ValidationHelper } from './Utils/ValidationHelper';
import SbazarApiService from '../Services/SbazarApiService';

export default class Base<P, S> extends React.Component<P, S> {

    protected facebookPagesApiService: FacebookPagesApiService;
    protected facebookCatalogsApiService: FacebookCatalogsApiService;
    protected facebookApiService: FacebookApiService;
    protected websitesApiService: WebsitesApiService;
    protected userApiService: UserApiService;
    protected productApiService: ProductApiService;
    protected accountTransactionApiService: AccountTransactionsApiService;
    protected productCollectionsApiService: ProductCollectionApiService;
    protected productImageApiService: ProductImageApiService;
    protected proformaInvoicesApiService: ProformaInvoicesApiService;
    protected autoimportersApiService: AutoimportersApiService;
    protected bazosApiService: BazosApiService;
    protected contactApiService: ContactApiService;
    protected serverAccountsApiService: ServerAccountsApiService;
    protected tipMotoApiService: TipMotoApiService;
    protected facebookAccountsApiService: FacebookAccountsApiService;
    protected currencyApiService: CurrencyApiService;
    protected googleApiService: GoogleApiService;
    protected sbazarApiService: SbazarApiService;

    protected cancellations: Array<Function>;

    constructor(props: P) {
        super(props);

        this.facebookPagesApiService = new FacebookPagesApiService();
        this.facebookCatalogsApiService = new FacebookCatalogsApiService();
        this.facebookApiService = new FacebookApiService();
        this.websitesApiService = new WebsitesApiService();
        this.userApiService = new UserApiService();
        this.productApiService = new ProductApiService();
        this.accountTransactionApiService = new AccountTransactionsApiService();
        this.productCollectionsApiService = new ProductCollectionApiService();
        this.productImageApiService = new ProductImageApiService();
        this.proformaInvoicesApiService = new ProformaInvoicesApiService();
        this.autoimportersApiService = new AutoimportersApiService();
        this.bazosApiService = new BazosApiService();
        this.contactApiService = new ContactApiService();
        this.serverAccountsApiService = new ServerAccountsApiService();
        this.tipMotoApiService = new TipMotoApiService();
        this.facebookAccountsApiService = new FacebookAccountsApiService();
        this.currencyApiService = new CurrencyApiService();
        this.googleApiService = new GoogleApiService();
        this.sbazarApiService = new SbazarApiService();
        this.cancellations = [];
    }

    // Note: make sure to call super.componentWillUnmount() from child components
    componentWillUnmount() {
        this.facebookPagesApiService.cancelRequests();
        this.facebookCatalogsApiService.cancelRequests();
        this.facebookApiService.cancelRequests();
        this.websitesApiService.cancelRequests();
        this.userApiService.cancelRequests();
        this.productApiService.cancelRequests();
        this.accountTransactionApiService.cancelRequests();
        this.productCollectionsApiService.cancelRequests();
        this.productImageApiService.cancelRequests();
        this.proformaInvoicesApiService.cancelRequests();
        this.autoimportersApiService.cancelRequests();
        this.bazosApiService.cancelRequests();
        this.contactApiService.cancelRequests();
        this.tipMotoApiService.cancelRequests();
        this.serverAccountsApiService.cancelRequests();
        this.facebookAccountsApiService.cancelRequests();
        this.currencyApiService.cancelRequests();
        this.googleApiService.cancelRequests();
        this.sbazarApiService.cancelRequests();

        while (this.cancellations.length > 0) {
            const cancel = this.cancellations.pop()
           
            if (cancel) {
                // Check if the cancellation has already been performed
            } else {
                cancel();
            }
        }
    }

    // returns Promise, response is ...
    protected getData(url: string): Promise<AxiosResponse<any>> {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                cancelToken: new axios.CancelToken((c) => this.cancellations.push(c)),
            }).then(resolve).catch(reject);
        });
    }

    protected getWwwData(url: string) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                baseURL: '',
                cancelToken: new axios.CancelToken((c) => this.cancellations.push(c)),
            }).then(resolve).catch(reject);
        });
    }

    validateInputs(inputs: React.RefObject<IInputField>[]) {
       return ValidationHelper.validateInputs(inputs);
    }

    async validateInputsAsync(inputs: React.RefObject<IInputField>[], focus?: boolean) {
        let firstInvalid: IInputField;
        for (var i = 0; i < inputs.length; i++) {
            const input: IInputField = inputs[i].current;

            if (input) {

                if (input.validateAsync != null) {
                    await input.validateAsync();
                }
                else if (input.validate != null) {
                    input.validate();
                }

                if (input.isValid() === false && !firstInvalid) {
                    firstInvalid = input;
                    if (focus !== false) {
                        input.focus();
                    }
                    
                }
            }
        }

        if (firstInvalid) {
            return false;
        }
        return true;
    }

    isValid(inputs: React.RefObject<IInputField>[]) {
        return ValidationHelper.isValid(inputs);
    }

    toast(message: string) {
        toast.success(message);
    }
}