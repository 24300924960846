import { round } from "lodash";
import React from "react";
import { Currency } from "../../Enums/Currency";
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import StringHelper from "../../Helpers/StringHelper";
import IPricingItem from "../../Interfaces/IPricingItem";

interface IProps {
    items: IPricingItem[],
    currency: Currency
}

export const PricingTable = (props: IProps) => {
    const { items } = props;
    const symbol = CurrencyHelper.getSymbol(props.currency);

    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead className="text-center">
                        <tr>
                            <th>Počet inzerátů</th>

                            <th>Cena za inzerát</th>

                            <th>Cena za měsíc</th>
                        </tr>
                    </thead>

                    <tbody className="text-center">
                        {items.map(item => {
                            return (
                                <tr key={item.productMax}>
                                    <td dangerouslySetInnerHTML={{ __html: StringHelper.separateThousands(item.productMax) }}></td>

                                    <td>{round(item.pricePerMonth / item.productMax, 2)} {symbol}</td>

                                    <td>
                                        <b dangerouslySetInnerHTML={{
                                            __html: `${StringHelper.separateThousands(item.pricePerMonth)} ${symbol}`
                                        }}></b> <br />
                                       
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </>)
}