import React from "react"
import { Link } from "react-router-dom"
import { SectionTitle } from "../Others/SectionTitle"

interface IProps {

}

export const IntegrationContactCard = (props: IProps) => {

    return (
        <div className="card mb-2">
            <div className="card-body">
                <SectionTitle className="mt-0" title="Poptáváte něco jiného?" />
                <hr />
                <p>Na vaší zpětné vazbě nám záleží</p>

                <Link to='/kontakt'>
                    <button className="btn btn-secondary btn-block">Napište nám!</button>
                </Link>
            </div>
        </div>)
}