import { Button, Modal, } from 'react-bootstrap';
import InputText from '../../UI/Input/InputText';
import Textarea from '../../UI/Input/Textarea';

interface IProps {
    modalTitle: string,
    name: string,
    description: string,
    onHide(): void,
    showOther(): void,
}

export const AutoImporterContentPreviewModal = (props: IProps) => {

    return (
        <Modal size="xl" centered={true} show={true} onHide={() => props.onHide()}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>{props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <InputText
                    label="Nadpis"
                    value={props.name}
                    readonly
                />

                <Textarea
                    label="Popis"
                    value={props.description}
                    disabled={true}
                    rows={13}
                />

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.onHide()} variant="secondary">
                    Zavřít náhled
                </Button>
                <Button onClick={() => props.showOther()} variant="outline-primary">
                    Zobrazit jiný inzerát
                </Button>
            </Modal.Footer>
        </Modal>
    )
}