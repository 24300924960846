import axios, { AxiosResponse, Canceler, Method } from "axios";
import buildUrl from "build-url";
import IResponseData from "../Interfaces/IResponseData";

export default class BaseApiService {

    private cancellations: Array<Canceler> = [];

    public cancelRequests() {
        this.cancellations.forEach(cancel => {
          
            if (cancel) {
                // Check if the cancellation has already been performed
            } else {
                this.cancellations.pop();
                cancel();
            }         
        })
    }

    public addToCancellationObject(cancellation: Canceler) {
        this.cancellations.push(cancellation)
    }

    public makeRequest(url: string, method: Method, data?: object): Promise<AxiosResponse<IResponseData>> {      
        return new Promise((resolve, reject): any => {
            axios({
                method: method,
                url,
                data: data,
                cancelToken: new axios.CancelToken((c) => this.addToCancellationObject(c)),
            })
                .then(resolve).catch(reject).finally(() => {
                    // handleResponse(key, option, res, res.data);                
                })
                .catch((err) => {
                    // error handling logic
                });
        });
    }

    public getRequest(path: string, queryParams: { [name: string]: string | string[]  }): Promise<AxiosResponse<IResponseData>> {
        const url = buildUrl(path, {
            queryParams: queryParams,
            disableCSV: true,
        });
        return this.makeRequest(url, 'get');
    }

    public postRequest(path: string, data: object, queryParams: { [name: string]: string | string[] }): Promise<AxiosResponse<IResponseData>> {
        const url = buildUrl(path, {
            queryParams: queryParams,
        });
        return this.makeRequest(url, 'post', data).finally(() => {

        });
    }

    public deleteRequest(path: string, data: object, queryParams: { [name: string]: string | string[] }): Promise<AxiosResponse<IResponseData>> {
        const url = buildUrl(path, {
            queryParams: queryParams,
        });
        return this.makeRequest(url, 'delete', data).finally(() => {

        });
    }

    public putRequest(path: string, data: object, queryParams: { [name: string]: string | string[] }): Promise<AxiosResponse<IResponseData>> {
        const url = buildUrl(path, {
            queryParams: queryParams,
        });
        return this.makeRequest(url, 'put', data).finally(() => {

        });
    }

    public patchRequest(path: string, data: object): Promise<AxiosResponse<IResponseData>> {
        const url = buildUrl(path, {

        });
        return this.makeRequest(url, 'patch', data).finally(() => {

        });
    }

    public postFile(url: string, queryParams: { [name: string]: string | string[] }, data?: FormData): Promise<AxiosResponse<IResponseData>> {
        return new Promise((resolve, reject): any => {
            axios({
                method: 'post',
                url,
                data: data,
                cancelToken: new axios.CancelToken((c) => this.addToCancellationObject(c)),
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: queryParams,
            })
                .then(resolve).catch(reject).finally(() => {
                    // handleResponse(key, option, res, res.data);
                })
                .catch((err) => {
                    // error handling logic
                });
        });
    }

};