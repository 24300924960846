import React from 'react';

interface IState {

}

interface IProps {

}

export default class ErrorPage extends React.Component<IProps, IState> {

    render() {
        return (
            <div className="card mt-4">
                <div className="card-body">
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    renderContent() {
        return (
            <div className="py-3">
                <div className="d-flex justify-content-center mb-3 mt-2">
                    <i className="far fa-frown tx-secondary fa-3x"></i>
                </div>

                <br />
                <div className="text-center">
                    <h3>Jejda, něco se pokazilo :-(</h3>
                </div>
                <br />
                <div className="text-center">
                    <p>Naši programátoři už byli o chybě informováni a brzy chybu prověří.</p>
                </div>

                <div className="d-flex justify-content-center py-3">
                    <a href="/">
                        <button type="button" className="btn btn-primary">
                            <i className="fas fa-arrow-right mr-2"></i> Přejít na nástěnku
                        </button>
                    </a>
                </div>
            </div>
        )
    }
}