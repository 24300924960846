import IProduct from '../../Interfaces/IProduct';
import { inject, observer } from 'mobx-react';
import { ShowDate } from '../Others/ShowDate';
import CurrentUserStore from '../Stores/CurrentUserStore';
import { MainProductImg } from '../Products/MainProductImg';
import { ProductHelper } from '../../Helpers/ProductHelper';
import CurrencyHelper from '../../Helpers/CurrencyHelper';
import { ProductSaleState } from './ProductSaleState';
import { CSSProperties } from 'react';
import { SeparateThousands } from '../Others/SeparateThousands';
import { ProductOptionsDropdown } from '../Products/ProductOptionsDropdown';
import { Link } from 'react-router-dom';
import Product from '../../Models/Product';

interface IProps {
    product: IProduct,
    currentUserStore?: CurrentUserStore,
    style?: CSSProperties,
}

export const ProductShopTableItem =
    inject('currentUserStore')
        (observer((props: IProps) => {

            const { product } = props;
            const user = props.currentUserStore.user;

            return (
                <>
                    <tr key={product.id} style={props.style}>
                        <td rowSpan={product.note ? 2 : 1}>
                            <MainProductImg
                                product={product}
                            />
                        </td>

                        <td style={{ width: '25%' }}>
                            <span className="">{product.name}</span> <br />
                            <small className='d-none d-md-flex text-secondary'>
                                <ShowDate date={product.dateCreated} />
                            </small>
                        </td>

                        <td className="text-center" style={{ width: '20%' }}>
                            <ProductSaleState product={product} />
                        </td>

                        <td style={{ fontSize: '13px', width: '30%' }}>
                            <ul className="list-unstyled mb-0">
                                <li><b><SeparateThousands number={ProductHelper.getPriceByCurrency(product, user.accountCurrency)} />
                                    &nbsp;{CurrencyHelper.getSymbol(user.accountCurrency)}</b>
                                </li>
                                <li><small>prodejní cena</small></li>

                                {product.costPrice &&
                                    <>
                                        <li className="mt-2">
                                            <b><SeparateThousands number={product.costPrice} />
                                                &nbsp;{CurrencyHelper.getSymbol(user.accountCurrency)}</b>
                                        </li>

                                        <li className="mb-0"><small>nákupní cena</small></li>
                                    </>}

                                {/*product.grossMargin &&
                                    <li>oček. marže: <SeparateThousands number={product.grossMargin} />
                                        &nbsp;{CurrencyHelper.getSymbol(user.accountCurrency)}</li>
                                */}
                            </ul>
                        </td>

                        <td style={{ width: '25%' }}>
                            <div className="btn-group float-right" role="group">
                                {/*     <button className="btn btn-primary btn-sm mr-2 ">
                                     <i className="fas fa-briefcase mr-1 d-none d-md-inline"></i> Obchod
                                </button>*/}

                                <Link to={Product.getEditLink(product)}>
                                    <button className="btn btn-warning btn-sm ">
                                        <i className="far fa-edit mr-1 d-none d-md-inline"></i>
                                        Upravit
                                    </button>
                                </Link>
                               
                                <ProductOptionsDropdown
                                    product={product}
                                    showDeleteButton={true}
                                    showGoToCollectionButton={true}
                                    showChangeCollection={false}
                                />
                            </div>
                        </td>
                    </tr>

                    {product.note &&
                        <tr style={props.style}>
                            <td colSpan={4} className="m-0 py-0">
                                <small> poznámka: {product.note}</small>
                            </td>
                        </tr>}
                </>
            )
        }));