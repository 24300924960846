import { ServerAccountErrorType } from "../../../Enums/ServerAccountErrorType";
import { IServerAccount } from "../../../Interfaces/IServerAccount"

interface IProps {
    account: IServerAccount
}

export const ServerAccountState = (props: IProps) => {
    const { account } = props;

    if (account.pendingConnect) {
        return <span className="badge badge-success p-2"><i className="fas fa-clock mr-1"/> Ověřování propojení</span>
    }

    if (account.error) {
        if (account.errorType === ServerAccountErrorType.IncorrectCredentials) {
            return <span className="badge badge-danger p-2"><i className="fas fa-times mr-1" />Chyba - Přihlašovací údaje</span>
        }
        else if (account.errorType === ServerAccountErrorType.FatalError) {
            return <span className="badge badge-warning p-2">
                <i className="fas fa-clock mr-1" />
                Neznámá chyba - předáno správci serveru
            </span>
        }
        else if (account.errorType === ServerAccountErrorType.BlockedDueToTermsViolation) {
            return <span className="badge badge-dark p-2">
                <i className="fas fa-times mr-1" />
                Blokace - porušení podmínek
            </span>
        }
        return <span className="badge badge-danger p-2"><i className="fas fa-times mr-1" />Chyba</span>
    }

    if (account.signInRequired) {
        return <span className="badge badge-warning p-2">Vyžaduje přihlášení</span>
    }
    else
        return <span className="badge badge-success p-2"><i className="fas fa-check mr-1" /> Přihlášeno</span>
}