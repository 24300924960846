import { Tab, Tabs } from "react-bootstrap"
import MultiRef from "react-multi-ref"
import { OnlineServer } from "../../../Enums/OnlineServer"
import IAutoImporter from "../../../Interfaces/IAutoImporter"
import IInputField from "../../../Interfaces/IInputField"
import BaseComponent from "../../BaseComponent"
import InputText from "../../UI/Input/InputText"
import MultiNumberInput from "../../UI/Input/MultiNumberInput"
import Textarea from "../../UI/Input/Textarea"
import { OnlineServerHelper } from "../../Utils/OnlineServerHelper"
import { ContentRulesAlert } from "../../Uploader/Products/ContentRulesAlert"

interface IState {
    activeTabKey: string,
}

interface IProps {
    autoImporter: IAutoImporter,
    handleChange(server: OnlineServer, value: any, key: any): void,
}

export default class AutoImporterProductTypeContentEditor extends BaseComponent<IProps, IState> implements IInputField {
    _records = new MultiRef();

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeTabKey: OnlineServer.BazosCz.toString(),
        }
    }

    handleChange(server: OnlineServer, value: any, key: string) {
        this.props.handleChange(server, value, key);
    }

    validate() {
        let isValid = true;

        this._records.map.forEach((item: any, key: any) => {
            item.validate();
            const isRowValid = item.isValid();

            if (isValid === true) {
                isValid = isRowValid;

                if (isValid === false) {
                    const server = key[0];

                    this.setState({
                        activeTabKey: server
                    })
                }
            }
        });
        return isValid;
    }

    isValid(): boolean {
        return this.validate();
    }

    focus() {

    }

    render() {
        const { autoImporter } = this.props;
        const { activeTabKey } = this.state;
        const contents = autoImporter.contents;

        return (
            <div className="nav-tabs-sauto-editor">
                <Tabs
                    activeKey={activeTabKey}
                    id="uncontrolled-tab-example"
                    className="nav nav-justified"
                    onSelect={(key: string, e: any) => this.setState({ activeTabKey: key })}
                >
                    {contents.map(content => {
                        const key = content.onlineServer.toString();
                        const server = content.onlineServer;
                        const showImagesSequence = server === OnlineServer.BazosCz || server === OnlineServer.BazosSk || server === OnlineServer.Sbazar;
                        return (
                            <Tab
                                key={key}
                                eventKey={key}
                                title={OnlineServerHelper.getName(server)}
                                tabClassName="nav-item"
                            >
                                <InputText
                                    ref={this._records.ref(`${key}-name`)}
                                    label="Nadpis"
                                    onChange={(value) => this.handleChange(server, value, 'name')}
                                    value={content.name}
                                    required
                                />

                                <Textarea
                                    ref={this._records.ref(`${key}-description`)}
                                    label="Popis"
                                    onChange={(value) => this.handleChange(server, value, 'description')}
                                    required
                                    value={content.description}
                                    rows={13}
                                />

                                <ContentRulesAlert
                                    text={content.description}
                                    rules={OnlineServerHelper.getContentRules(server)}
                                />

                                <div className="mt-5">
                                    {showImagesSequence &&
                                        <MultiNumberInput
                                            ref={this._records.ref(`${key}-sequence`)}
                                            onChange={(value) => this.handleChange(server, value, 'imageSequence')}
                                            value={content.imageSequence}
                                            label="Pořadí obrázků"
                                            maxValueCount={OnlineServerHelper.getMaxImagesCount(server)}
                                            placeholder="1,2,3,4,5,6..."
                                        />
                                    }
                                </div>
                            </Tab>
                        )
                    })}
                </Tabs>
            </div>)
    }
}