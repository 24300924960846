import { inject, observer } from "mobx-react";
import React from "react";
import { OnlineServer } from "../../../../Enums/OnlineServer";
import IUser from "../../../../Interfaces/IUser";
import BaseComponent from "../../../BaseComponent";
import { PageTitleBox } from "../../../Others/PageTitleBox";
import { SectionTitle } from "../../../Others/SectionTitle";
import LoadingScreen from "../../../Screens/LoadingScreen";
import CurrentUserStore from "../../../Stores/CurrentUserStore";
import CheckBox from "../../../UI/Input/CheckBox";
import InputText from "../../../UI/Input/InputText";
import CustomDocumentTitle from "../../../Utils/CustomDocumentTitle";

interface IState {
    loading: boolean,
}

interface IProps {
    currentUserStore?: CurrentUserStore
}

@inject('currentUserStore')
@observer
export default class TipMotoExportPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    handleAllow(user: IUser) {
        if (user.uploaderServers.includes(OnlineServer.TipMoto)) {
            this.tipMotoApiService.disallowExport();
        }
        else {
            this.tipMotoApiService.allowExport();
        }
    }

    renderContent() {
        const user = this.props.currentUserStore.user;

        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <p>Export na inzertní server TipMoto je realizován prostřednictvím datového feedu.</p>


                        <CheckBox
                            checked={user.uploaderServers.includes(OnlineServer.TipMoto)}
                            label="Povolit export"
                            onChange={() => this.handleAllow(user)}
                        />

                        <InputText
                            label="URL feedu"
                            value={`${window.location.protocol}//${window.location.hostname}/api/tipmoto/export?userId=${user.id}`}
                            readonly
                        />

                        <SectionTitle title="Jak propojit s TipMoto.com" className="mt-4" />
                        <ol>
                            <li>Povolte export na této stránce</li>
                            <li>Povolte export u jednotlivých inzerátů v Inzerobotovi v prvním kroku "Marketingové kanály"</li>
                            <li>Kontaktujte TipMoto a předejte jim výše uvedenou URL datového feedu</li>
                            <li>TipMoto si bude automaticky stahovat inzerci</li>
                        </ol>

                        <SectionTitle title="Dodatečné informace" />
                        <ul>
                            <li>Nepodporuje náhradní díly</li>
                        </ul>
                    </div>
                </div>
            </>)
    }

    render() {
        const loading = this.state.loading;

        return (
            <>
                <CustomDocumentTitle title="TipMoto.com - EXPORT">
                    <PageTitleBox title="TipMoto.com - EXPORT" />

                    {loading ? <LoadingScreen /> : this.renderContent()}
                </CustomDocumentTitle>
            </>)
    }
}