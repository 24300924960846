import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import IProduct from '../../../Interfaces/IProduct';
import StringHelper from '../../../Helpers/StringHelper';
import Product from '../../../Models/Product';

// Create styles
const styles = StyleSheet.create({
    page: {
       // flexDirection: 'row',
        backgroundColor: 'white',
        color: 'black',
        fontFamily: 'Roboto'
    },
    section: {
        margin: 5,
        padding: 10,
        fontSize: 22,
       // flexGrow: 1
    },
    heading: {
        fontSize: 25,
        textAlign: 'center',
        borderBottom: '1px solid black',
        fontWeight: 'bold',
        marginTop: 8,
        paddingBottom: 8
    },
    parameter: {
        marginTop: '12px',
        fontSize: '20px'
    },
    parameterValue: {
        marginTop: '8px',
        fontSize: '22px',
        fontWeight: 'bold'
    },
    parametersView: {
        flexDirection: 'row',
        margin: 5,
        padding: 5,
        fontSize: 20,
    }
});

interface IProps {
    product: IProduct
}

// Register Font
Font.register({
    family: "Roboto",
    src: // "/Templates/Fonts/Aller_Rg.ttf"
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf"
});

// Create Document Component
export const CarSalesCard = (props: IProps) => {
    const product = props.product;

    return (
        <Document>
            <Page orientation={"landscape"} size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.heading}>{product.name.toUpperCase()}</Text>

                    <View style={styles.parametersView}>
                        <View style={{ flexGrow: 1 }}>
                            <Text style={styles.parameter}>Rok výroby:</Text>
                            <Text style={styles.parameter}>Tachometr:</Text>
                            <Text style={styles.parameter}>Motor:</Text>
                            <Text style={styles.parameter}>Palivo:</Text>
                            <Text style={styles.parameter}>Cena:</Text>
                        </View>

                        <View style={{ flexGrow: 1 }}>
                            <Text style={styles.parameterValue}>{product.vehicleYear || '-'}</Text>
                            <Text style={styles.parameterValue}>{product.vehicleMileage ? `${StringHelper.separateThousandsNoHtml(product.vehicleMileage)} km` : '-'}</Text>
                            <Text style={styles.parameterValue}>{product.vehicleCubicCapacity} ccm / {product.vehiclePower} kW</Text>
                            <Text style={styles.parameterValue}>{product.vehicleFuelType ? Product.getFuelTypeLabel(product.vehicleFuelType) : '-'}</Text>
                            <Text style={{ fontSize: '36', marginTop: 20 }}>{StringHelper.separateThousandsNoHtml(product.priceCZK)} Kč</Text>
                        </View>
                   
                    </View>

                    <Text style={{ marginTop: 10, fontSize: 18, textAlign: 'justify' }}>
                        {StringHelper.TruncateLongString(product.salesCardDescription,660)}
                    </Text>
                </View>
            </Page>
        </Document>
    );
}