import { IContacts } from '../../Interfaces/IContacts';
import DictionaryKeyValueInput from '../UI/Input/DictionaryKeyValueInput';
import InputTelephone from '../UI/Input/InputTelephone';

interface IProps {
    contacts: IContacts,
    onChange(value: any, key: string): void,
}

export const ContactsForm = (props: IProps) => {
    const { contacts, onChange } = props;

    return (
        <>
            <label className="section-title">Kontaktní tel. čísla</label>
            <p>Přidejte tel. čísla, na které vám mohou volat zákazníci.</p>

            <DictionaryKeyValueInput
                uid="phones"
                dictionary={contacts.phones}
                onChange={(value, key) => onChange(value, key)}
                onCreateNew={() => { } }
                keyLabel="Tel. číslo"
                valueLabel="Veřejná poznámka"
                isValueRequired={false}
            />

            <div className="row">
                <div className="col col-md-4 col-md-lg-3">
                    <InputTelephone
                        label="WhatsApp tel. číslo"
                        onChange={(val) => onChange(val, 'whatsAppPhone')}
                        value={contacts.whatsAppPhone}
                    />

                    <small>Toto tel. číslo se například zobrazí na vašem webu (podporuje-li to šablona)</small>
                </div>
            </div>

            <label className="section-title">Kontaktní e-maily</label>
            <p>Přidejte e-maily, na které vám mohou zákazníci napsat.</p>

            <DictionaryKeyValueInput
                uid="emails"
                dictionary={contacts.emails}
                onChange={(value, key) => onChange(value, key)}
                onCreateNew={() => { } }
                keyLabel="E-mail"
                valueLabel="Veřejná poznámka"
                isValueRequired={false}
            />
        </>
    )
}