import { round } from "lodash";
import { Currency } from "../../Enums/Currency";
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import StringHelper from "../../Helpers/StringHelper";
import IWebsitePricingItem from "../../Interfaces/IWebsitePricingItem";

interface IProps {
    items: IWebsitePricingItem[],
    currency: Currency
}

export const WebsitePricingTable = (props: IProps) => {
    const { items } = props;
    const currency = props.currency;

    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead className="text-center">
                    <tr>
                        <th>Počet inzerátů na webu</th>

                        <th>Cena</th>
                    </tr>
                </thead>

                <tbody className="text-center">
                    {items.map(item => {
                        return (
                            <tr key={item.productMax}>  
                                {item.productMax === 500 ?
                                    <td>Neomezeně</td>
                                :
                                    <td dangerouslySetInnerHTML={{ __html: StringHelper.separateThousands(item.productMax) }}></td>}
                                <td>
                                    <b dangerouslySetInnerHTML={{
                                        __html: `${StringHelper.separateThousands(item.pricePerMonth)} ${CurrencyHelper.getSymbol(currency)}`
                                    }}></b> <br />
                                    {item.productMax !== 500 &&
                                        <small>
                                            {round(item.pricePerMonth / item.productMax, 2)} {CurrencyHelper.getSymbol(currency)} / inzerát
                                        </small>
                                    }
                                </td>
                            </tr>)
                    })}
                </tbody>
            </table>
        </div>)
}