import { HowToVerifySbazarContactAccordion } from "../../Integrations/SbazarAccounts/HowToVerifySbazarContactAccordion"
import { PageTitleBox } from "../../Others/PageTitleBox"
import { SectionTitle } from "../../Others/SectionTitle"
import { Accordion } from "../../UI/Elements/Accordion"
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle"

export const FaqSbazarPage = () => {
    return (
        <>
            <CustomDocumentTitle title="Nápověda - Sbazar">
                <PageTitleBox title="Nápověda - Sbazar" />

                <div className="card">
                    <div className="card-body">

                        <HowToVerifySbazarContactAccordion />

                        <Accordion cardClassName="mb-2" headingText={"Maximální počet inzerátů"}>
                            <p>
                               V jednom účtu je možné mít 500 inzerátů.
                            </p>
                        </Accordion>

                        <Accordion cardClassName="mb-2" headingText={"Předčasné obnovování inzerátů"}>
                            <p>
                               V aplikaci lze nastavit obnovení inzerátu před expirací, ovšem v takovém momentě budete čelit možnosti blokace ze strany inzertního serveru. Což může způsobit blokaci vašeho Sbazar účtu.
                            </p>
                        </Accordion>

                        <Accordion cardClassName="mb-2" headingText={"Topování"}>
                            <p>
                                Inzeráty můžete topovat přímo přes Sbazar, ovšem nesmíte mít v kolekci nastavené předčasné obnování. Je potřeba mít nastaveno "Po expiraci". V opačném případě se vám topovené inzeráty smažou.
                            </p>
                        </Accordion>
             
                    </div>
                </div>
            </CustomDocumentTitle>
        </>)
}