import { inject, observer } from "mobx-react";
import { PageTitleBox } from "../../../Others/PageTitleBox";
import { SectionTitle } from "../../../Others/SectionTitle";
import CurrentUserStore from "../../../Stores/CurrentUserStore";
import CustomDocumentTitle from "../../../Utils/CustomDocumentTitle";
import { Tab, Tabs } from "react-bootstrap";
import { PaidSupport } from "../../../Contact/PaidSupport";
import { WebembedAllowAlert } from "../../../Integrations/Webembed/WebembedAllowAlert";
import { MyCompanyGooglePlacesCard } from "../../../Integrations/GooglePlaces/MyCompanyGooglePlacesCard";

interface IProps {
    currentUserStore?: CurrentUserStore,
}

export const WebEmbedReviewsPage = inject('currentUserStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;

    const carsHtml = `<div id="inzerobot-reviews" user-id="${user.id}" primary-color="#4f97e7"></div>`;
    const jsImport = '<script async src="https://embed.inzerobot.cz/prod/web/app.js"></script>';

    return (
        <>
            <CustomDocumentTitle title="Google hodnocení">
                <PageTitleBox title="EXPORT NA WEB - Google hodnocení" />

                <MyCompanyGooglePlacesCard
                    user={user}
                    onUserUpdated={() => { }}
                />

                <div className="card mb-1">
                    <div className="card-body">
                        {!user.enableWebEmbed && <WebembedAllowAlert />}

                        <SectionTitle title="POPIS" />
                        <hr />
                        <p>Jestliže máte již svůj web a chcete v rámci něj zobrazit poslední hodnocení z Googlu, vložte si následující widget. Z posledních 5 hodnocení zobrazí pouze se 4 či více hvězdičkami.</p>

                        <SectionTitle title="1. Povolte webové widgety" />
                        {user.enableWebEmbed && <p className="text-success"><i className="fa fa-check"></i> Widgety již máte povoleny</p>}
                        <SectionTitle title="2. Propojte s vaší firmou na Google Mapách viz. výše" />
                        {user.googlePlaceSetuped && <p className="text-success"><i className="fa fa-check"></i> Firmu máte již propojenou</p>}
                        <SectionTitle title="3. Následující script vložte do hlavičky vašeho webu" />
                        <hr />
                        <p>Případně zkontrolujte, zda už tam není. Vložen může být pouze jednou v rámci stránky.</p>

                        <textarea
                            readOnly
                            className="form-control"
                            value={jsImport}
                        >
                        </textarea>

                        <SectionTitle title="4. Následující html vložte na místo zobrazení hodnocení" />
                        <p>Vložte jej do HTML kódu vašeho webu tam, kde má být hodnocení zobrazeno.</p>

                        <Tabs
                            id="uncontrolled-tab-example"
                        >
                            <Tab
                                eventKey="cars" title="Google hodnocení"
                                className="bg-light p-2"
                            >
                                <textarea
                                    readOnly
                                    className="form-control"
                                    value={carsHtml}
                                >

                                </textarea>

                                <SectionTitle title="Ukázka" />
                                <p>Živou ukázku zobrazíte na tomto odkazu =&gt;&nbsp;
                                    <a target="_blank" rel="noreferrer" href="https://embed.inzerobot.cz/prod/web/demo/reviews.html">
                                        https://embed.inzerobot.cz/prod/web/demo/reviews.html
                                    </a></p>

                                <p><b>Ukázkový screenshot</b></p>
                                <img src="https://embed.inzerobot.cz/prod/web/demo/reviews-screen.png" className="img-fluid" alt="" />
                            </Tab>
                        </Tabs>

                        <PaidSupport />
                    </div>
                </div>
            </CustomDocumentTitle>
        </>
    );
}));