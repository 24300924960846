import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import IInputField from '../../../Interfaces/IInputField';
import { IServerAccount } from '../../../Interfaces/IServerAccount';
import AppIpAddressStore from '../../Stores/AppIpAddressStore';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import InputEmail from '../../UI/Input/InputEmail';
import InputText from '../../UI/Input/InputText';
import SelectBox from '../../UI/Input/SelectBox';
import Swal from 'sweetalert2';
import SbazarApiService from '../../../Services/SbazarApiService';
import { ISbazarLocation } from '../../../Interfaces/ISbazarLocation';
import IOptionData from '../../../Interfaces/IOptionData';
import { ValidationHelper } from '../../Utils/ValidationHelper';
import InputTelephone from '../../UI/Input/InputTelephone';
import CheckBox from '../../UI/Input/CheckBox';
import SbazarAccountsApiService from '../../../Services/SbazarAccountsApiService';
import { ISbazarSettingsRequest } from '../../../Interfaces/Requests/SbazarAccounts/ISbazarSettingsRequest';
import { toast } from 'react-toastify';
import { find } from 'lodash';
import { SbazarHelper } from '../../../Helpers/Servers/SbazarHelper';

interface IProps {
    onHide(): void,
    accountServer: IServerAccount,
    appIpAddressStore?: AppIpAddressStore,
    currentUserStore?: CurrentUserStore,
}

const sbazarApiService = new SbazarApiService();
const sbazarAccountsApiService = new SbazarAccountsApiService();

export const SbazarAccountSettingsModal = inject('appIpAddressStore', 'currentUserStore')(observer((props: IProps) => {

    const { accountServer } = props;

    const [form, setForm] = useState<ISbazarSettingsRequest>({
        contactEmail: accountServer.contactEmail,
        contactPhone: accountServer.contactPhone,
        insertCurrentDateDescriptionPrefix: accountServer.insertCurrentDateDescriptionPrefix,
        showPhoneNumber: accountServer.showPhoneNumber,
        locationId: accountServer.locationId,
        locationName: accountServer.locationName,
        name: accountServer.name,
        minRenewIntervalDays: accountServer.minRenewIntervalDays,
        locationType: accountServer.locationType,
        autoRenew: accountServer.autoRenew,
    });

    const inputName = useRef<InputText>(null);
    const inputSearchLocation = useRef<InputText>(null);
    const inputContactEmail = useRef<InputEmail>(null);
    const inputContactPhone = useRef<InputTelephone>(null);
    const inputLocation = useRef<SelectBox>(null);

    const [searchedLocation, setSearchedLocation] = useState<string>("");
    const [locations, setLocations] = useState<ISbazarLocation[]>([]);

    useEffect(() => {
        return () => {
            sbazarApiService.cancelRequests();
            sbazarAccountsApiService.cancelRequests();
        }
    }, [])

    const getLocations = () => {
        if (!searchedLocation) {
            Swal.fire('Zadejte lokalitu',
                'Nejprve zadejte lokalitu, poté ji můžete vyhledat a následně vybrat ze seznamu níže.',
                'warning'
            );
            return;
        }

        Swal.fire({
            title: 'Hledám lokality...',
            didOpen: () => {
                Swal.showLoading();
            },
        })

        sbazarApiService.getLocations(searchedLocation)
            .then(data => {
                if (data.success) {
                    const locations = data.others.get("locations");

                    setLocations(locations);
                    setForm({
                        ...form,
                        locationId: '',
                    })

                    if (locations.length === 0) {
                        Swal.fire('Nic nenalezeno', 'Zkuste zadat lokalitu jinak...', 'error')
                    }
                    else {
                        Swal.close();
                    }
                }
                else {
                    Swal.fire({
                        title: "Došlo k chybě",
                        text: "Opakujte akci později. Pokud problém přetrvává delší dobu, kontaktujte nás.",
                        icon: "error"
                    });
                }
            })
    }

    const locationOptions: IOptionData[] = [];
    locations.map((location: ISbazarLocation) => {
        locationOptions.push({ label: location.suggestFirstRow + ' - ' + location.suggestSecondRow, value: location.entityId })
    })

    if (form.locationId) {
        locationOptions.push({
            label: form.locationName,
            value: form.locationId,
        });
    }

    const selectBoxLocationsdefValue: string = SbazarHelper.getLocationDefValue(locations)

    const validate = () => {
        const inputs: React.RefObject<IInputField>[] = [];

        inputs.push(
            inputName,

        )
        return ValidationHelper.validateInputs(inputs);
    }

    const onChange = (key: any, value: any) => {
        setForm({
            ...form,
            [key]: value
        })
    }

    const onChangeLocation = (locationId: string) => {
        const location = find(locations, { entityId: parseInt(locationId) });

        setForm({
            ...form,
            locationId: locationId,
            locationName: location.seoName,
            locationType: location.entityType,
        })
    }

    const handleSubmitForm = () => {
        if (validate()) {
            sbazarAccountsApiService.postSettings(accountServer.id, form)
                .then(data => {
                    if (data.success) {
                        toast.success("Změny uloženy");
                        const acc = data.records.serverAccounts.items[0];
                        props.onHide();
                    }
                    else {
                        toast.error("Změny nebyly uloženy");
                    }
                })
        }
    }

    return (
        <Modal size="lg" centered={true} show={true} onHide={() => { }}>
            <Modal.Header>
                <Modal.Title>Nastavení Sbazar účtu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="mt-3">
                        <InputText
                            label="Účet"
                            value={accountServer.userName}
                            readonly
                        />

                        <div className="row">
                            <div className="col-lg-6">
                                <InputText
                                    ref={inputName}
                                    label="Libovolné označení tohoto účtu (neveřejné)"
                                    value={form.name}
                                    required={true}
                                    onChange={(val) => onChange('name', val)}
                                />
                            </div>

                            <div className="col-lg-6">
                                <SelectBox
                                    label='Obnovování'
                                    options={SbazarHelper.getAutoRenewOptions()}
                                    value={form.minRenewIntervalDays}
                                    defaultValue="Po expiraci"
                                    onChange={(val) => onChange('minRenewIntervalDays', val)}
                                />
                            </div>
                        </div>
                    </div>

                    {(form.minRenewIntervalDays > 0) &&
                        <p className="text-danger text-center">Obnovováním před expirací inzerátu se vystavujete blokaci tel. čísla, emailu, sbazar účtu.</p>}

                    <hr />

                    <label className="section-title mt-0 mb-4">PRODEJCE</label>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="mb-3">
                                <InputText
                                    ref={inputSearchLocation}
                                    required={!form.locationId}
                                    label="Vyhledejte svou lokalitu "
                                    placeholder="např. Praha"
                                    value={searchedLocation}
                                    onChange={(value: string) => setSearchedLocation(value)}
                                />

                            </div>
                            <button type="submit" onClick={getLocations} className="btn btn-secondary">Vyhledat</button>
                        </div>

                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                            {locations.length > 0 || form.locationId ?
                                <SelectBox
                                    ref={inputLocation}
                                    label="Vyberte lokalitu"
                                    onChange={(val) => onChangeLocation(val)}
                                    options={locationOptions}
                                    value={form.locationId}
                                    required
                                    disabled={locationOptions.length == 0}
                                    defaultValue={selectBoxLocationsdefValue}
                                /> : null}
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col">
                            <InputEmail
                                ref={inputContactEmail}
                                label="Kontaktní email"
                                value={form.contactEmail}
                                required={true}
                                placeholder=""
                                onChange={(val) => onChange('contactEmail', val)}
                            />
                        </div>
                        <div className="col">
                            <InputTelephone
                                ref={inputContactPhone}
                                label="Kontaktní tel. číslo"
                                value={form.contactPhone}
                                required={true}
                                onChange={(val) => onChange('contactPhone', val)}
                            />
                        </div>
                    </div>

                    <CheckBox
                        checked={form.showPhoneNumber}
                        label="Zobrazovat tel. číslo"
                        onChange={(val) => onChange('showPhoneNumber', val)}
                    />

                    <CheckBox
                        checked={form.insertCurrentDateDescriptionPrefix}
                        label="Před text inzerátu vložit aktuální datum"
                        onChange={(val) => onChange('insertCurrentDateDescriptionPrefix', val)}
                    />

                    <CheckBox
                        checked={form.autoRenew}
                        label="Automaticky obnovit inzeráty po expiraci"
                        onChange={(val) => onChange('autoRenew', val)}
                    />

                    <div className="alert alert-secondary mt-4">
                        <i className="fas fa-info-circle mr-2"></i>Nastavení neovlivní již zveřejněné inzeráty
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="secondary" size="lg">
                    Zavřít
                </Button>
                <Button variant="primary" size="lg" onClick={handleSubmitForm}>
                    Uložit změny
                </Button>
            </Modal.Footer>
        </Modal>
    )
}))