import { useSortable } from '@dnd-kit/sortable';
import IProductImage from '../../../Interfaces/IProductImage';
import { CSS } from '@dnd-kit/utilities';
import { ProductImageHelper } from '../../../Helpers/ProductImageHelper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProductImageApiService from '../../../Services/ProductImagesApiService';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

interface IProps {
    image: IProductImage,
}

const productImageApiService = new ProductImageApiService();

export const ImageSortableItem = (props: IProps) => {

    const [imageUpdateHash, setImageUpdateHash] = useState<any>();

    useEffect(() => {
        return () => {
            productImageApiService.cancelRequests();
        }
    }, [])

    const productImage = props.image;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,

    } = useSortable({
        id: productImage.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const handleImageRotateRight = () => {
        productImageApiService.rotateRight(productImage.id)
            .then(data => {
                setImageUpdateHash(new Date());
            })
            .catch((error: AxiosError) => {
                if (error?.response?.status === 404) {
                    toast.error("Obrázek již neexistuje");
                }
                else {
                    toast.error("Došlo k chybě");
                }
            });
    }

    const handleImageRotateLeft = () => {
        productImageApiService.rotateLeft(productImage.id)
            .then(data => {
                setImageUpdateHash(new Date());
            })
            .catch((error: AxiosError) => {
                if (error?.response?.status === 404) {
                    toast.error("Obrázek již neexistuje");
                }
                else {
                    toast.error("Došlo k chybě");
                }
            });
    }

    const handleRemoveServerImage = () => {
        productImageApiService.delete(productImage.id)
            .catch((error: AxiosError) => {
                if (error?.response?.status !== 404) {
                    toast.error("Došlo k chybě");
                }
            })
    }


    return (
        <div style={{ ...style, touchAction: 'none' }}>
            <div className="position-relative d-inline-block inline-block">
                <img className="img-fluid image-order-move"
                    loading="lazy"
                    width="125"
                    style={{ maxWidth: '125px', cursor: 'move' }}
                    src={ProductImageHelper.getImagePathS3NoCache(productImage)}
                    ref={setNodeRef}  {...attributes} {...listeners}
                />
                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Smazat
                        </Tooltip>
                    }
                >
                    <span
                        style={{ right: 0, top: 0, backgroundColor: 'rgb(255 255 255 / 83%)', padding: 5, borderRadius: '0px 0px 0px 3px' }}
                        className="position-absolute text-danger clickable"
                        onClick={handleRemoveServerImage}
                    >
                        <i style={{ paddingRight: '2px' }} className="far fa-trash-alt"></i>
                    </span>
                </OverlayTrigger>
            </div>

            <div key={productImage.id} className="row ml-0">
                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Otočit doprava
                        </Tooltip>
                    }
                >
                    <button
                        onClick={handleImageRotateRight}
                        style={{ height: '35px', width: '100%', maxWidth: '62.5px', border: '3px' }}
                        className="btn btn-secondary"
                    >
                        <i className="fas fa-redo"></i>
                    </button>
                </OverlayTrigger>

                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Otočit doleva
                        </Tooltip>
                    }
                >
                    <button
                        onClick={handleImageRotateLeft}
                        style={{ height: '35px', width: '100%', maxWidth: '62.5px', border: '3px', borderLeft: '1px solid #545454', }}
                        className="btn btn-secondary"
                    >
                        <i className="fas fa-redo icon-flipped"></i>
                    </button>
                </OverlayTrigger>
            </div>
        </div>)
}