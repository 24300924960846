import { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import IFacebookCatalog from "../../Interfaces/IFacebookCatalog"
import FacebookCatalogsApiService from "../../Services/FacebookCatalogsApiService";
import { FacebookCatalogEditModal } from "./FacebookCatalogEditModal";

interface IProps {
    facebookCatalog: IFacebookCatalog,
}

const facebookCatalogsApiService = new FacebookCatalogsApiService();

export const FacebookCatalogRow = (props: IProps) => {

    const { facebookCatalog } = props;

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        return () => {
            facebookCatalogsApiService.cancelRequests();
        }
    }, [])

    function handleDelete() {
        Swal.fire({
            title: `Opravdu chcete smazat zdroj "${facebookCatalog.name}"?`,
            text: "Tato akce je nevratná",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ano, smazat',
            cancelButtonText: 'Zrušit'
        }).then((result) => {
            if (result.value) {
                facebookCatalogsApiService.deleteCatalog(facebookCatalog);
            }
        })
    }

    return (
        <tr key={facebookCatalog.id}>
            <td>{facebookCatalog.name}</td>

            <td>
                <OverlayTrigger
                    placement={'left-end'}
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Přepočet může být zpožděný.
                        </Tooltip>
                    }
                >
                    <div>
                        <i className="fas fa-info-circle text-info"></i> {facebookCatalog.productCount}
                    </div>

                </OverlayTrigger>

            </td>

            <td>{CurrencyHelper.getLabel(facebookCatalog.currency)}
                <i onClick={() => setShowModal(true)} className="far fa-edit text-warning clickable ml-1"></i>

                {showModal &&
                    <FacebookCatalogEditModal
                        onHide={() => setShowModal(false)}
                        facebookCatalog={facebookCatalog}
                    />}
            </td>

            <td>
                <a target="_blank" href={`https://app.inzerobot.cz/api/facebookcatalogs/user/${facebookCatalog.userId}/${facebookCatalog.id}/rss`}>
                    https://app.inzerobot.cz/api/facebookcatalogs/user/{facebookCatalog.userId}/{facebookCatalog.id}/rss
                </a>
            </td>

            <td>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" data-boundary="window" className="btn-sm ml-1 h-100" id="dropdownProductOptions">
                        <span className="mr-2">Možnosti</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleDelete()}>
                            <span className="text-danger"> <i className="far fa-trash-alt mr-2 d-none d-md-inline"></i>
                                Smazat</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>)
}