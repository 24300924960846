import React from "react"
import IProduct from "../../../Interfaces/IProduct"
import IProductServerInfo from "../../../Interfaces/IProductServerInfo";
import IUser from "../../../Interfaces/IUser";
import { InfoAlert } from "../../Others/Alerts/InfoAlert";
import { SuccessServerLinkAlert } from "./SuccessServerLinkAlert";

interface IProps {
    product: IProduct,
    user: IUser,
    info: IProductServerInfo,
}

export const SautoTabContent = (props: IProps) => {

    const { info, product } = props;

    return (
        <>
            {info.isVisible && !product.delete && <SuccessServerLinkAlert url={info.url} />}

            <InfoAlert text="Inzerát byl importován z tohoto portálu" />
        </>)
}