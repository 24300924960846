import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { CustomContentType } from "../../../Enums/CustomContentType";
import { ProductType } from "../../../Enums/ProductType";
import LanguageHelper from "../../../Helpers/LanguageHelper";
import IGlobalSettings from "../../../Interfaces/IGlobalSettings";
import IOptionData from "../../../Interfaces/IOptionData";
import IProduct from "../../../Interfaces/IProduct";
import IProductCollection from "../../../Interfaces/IProductCollection";
import IUser from "../../../Interfaces/IUser";
import BaseComponent from "../../BaseComponent";
import SelectBox from "../../UI/Input/SelectBox";
import { DescriptionTabTitle } from "../DescriptionEditor/DescriptionTabTitle";
import BazosCzEditor from "./BazosCzEditor";
import BazosSkEditor from "./BazosSkEditor";
import FacebookEditor from "./FacebookEditor";
import FacebookMarketplaceEditor from "./FacebookMarketplaceEditor";
import GeneralEditor from "./GeneralEditor";
import SalesCardEditor from "./SalesCardEditor";
import SbazarEditor from "./SbazarEditor";
import TipMotoEditor from "./TipMotoEditor";
import WebsiteEditor from "./WebsiteEditor";
import { OnlineServer } from "../../../Enums/OnlineServer";

interface IState {
    activeTabKey: string | CustomContentType,
    tabs: {
        bazosCzValid: boolean,
        bazosSkValid: boolean,
        sbazarValid: boolean,
        facebookValid: boolean,
        websiteValid: boolean,
        generalValid: boolean,
        tipMotoValid: boolean,
        facebookMarketplaceValid: boolean,
    }
}

interface IProps {
    product: IProduct,
    onChange(value: any, key: string): void,
    globalSettings: IGlobalSettings,
    collection: IProductCollection,
    user: IUser,
}

export default class ProductContentEditor extends BaseComponent<IProps, IState> {

    private generalEditor = React.createRef<GeneralEditor>();
    private bazosCzEditor = React.createRef<BazosCzEditor>();
    private bazosSkEditor = React.createRef<BazosSkEditor>();
    private sbazarEditor = React.createRef<SbazarEditor>();
    private facebookEditor = React.createRef<FacebookEditor>();
    private websiteEditor = React.createRef<WebsiteEditor>();
    private tipMotoEditor = React.createRef<TipMotoEditor>();
    private facebookMarketplaceEditor = React.createRef<FacebookMarketplaceEditor>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeTabKey: 'general',
            tabs: {
                bazosCzValid: null,
                bazosSkValid: null,
                sbazarValid: null,
                facebookValid: null,
                websiteValid: null,
                generalValid: null,
                tipMotoValid: null,
                facebookMarketplaceValid: null,
            }
        }

        this.generalEditor = React.createRef();
        this.bazosCzEditor = React.createRef();
        this.bazosSkEditor = React.createRef();
        this.sbazarEditor = React.createRef();
        this.facebookEditor = React.createRef();
        this.websiteEditor = React.createRef();
        this.tipMotoEditor = React.createRef();
        this.facebookMarketplaceEditor = React.createRef();
    }

    handleChangeValid(key: string, valid: boolean) {
        this.setState({
            tabs: {
                ...this.state.tabs,
                [key]: valid
            }
        })
    }

    async isContentValidAsync(): Promise<boolean> {
        const product = this.props.product;

        let isValid = true;

        const generalValid = await this.generalEditor.current.validateAsync();

        let bazosCzValid = true;
        if (this.bazosCzEditor.current && product.bazosCzUseDescription)
            bazosCzValid = await this.bazosCzEditor.current.validateAsync();

        let bazosSkValid = true;
        if (this.bazosSkEditor.current && product.bazosSkUseDescription)
            bazosSkValid = await this.bazosSkEditor.current.validateAsync();

        let sbazarValid = true;
        if (this.sbazarEditor.current)
            sbazarValid = this.sbazarEditor.current.validate();

        let facebookValid = true;
        if (this.facebookEditor.current)
            facebookValid = this.facebookEditor.current.validate();

        let websiteValid = true;
        if (this.websiteEditor.current)
            websiteValid = this.websiteEditor.current.validate();

        let tipMotoValid = true;
        if (this.tipMotoEditor.current)
            tipMotoValid = this.tipMotoEditor.current.validate();

        let facebookMarketplaceValid = true;
        if (this.facebookMarketplaceEditor.current)
            facebookMarketplaceValid = this.facebookMarketplaceEditor.current.validate();

        if (!generalValid) {
            isValid = false;
            this.switchTab('general');
        }
        else if (!bazosCzValid && product.bazosCzUseDescription) {
            isValid = false;
            this.switchTab(CustomContentType.BazosCz);
        }
        else if (!bazosSkValid && product.bazosSkUseDescription) {
            isValid = false;
            this.switchTab(CustomContentType.BazosSk);
        }
        else if (!sbazarValid && product.sbazarUseDescription) {
            isValid = false;
            this.switchTab(CustomContentType.Sbazar);
        }
        else if (!facebookValid && product.facebookCustomContent) {
            isValid = false;
            this.switchTab(CustomContentType.FacebookShop);
        }
        else if (!websiteValid && product.websiteCustomContent) {
            isValid = false;
            this.switchTab(CustomContentType.Website);
        }
        else if (!tipMotoValid && product.tipMotoCustomContent) {
            isValid = false;
            this.switchTab(CustomContentType.Tipmoto);
        }
        else if (!facebookMarketplaceValid && product.facebookMarketplaceCustomContent) {
            isValid = false;
            this.switchTab(CustomContentType.FacebookMarketplace);
        }
        return isValid;
    }

    switchTab(key: string | CustomContentType) {
        this.setState({
            activeTabKey: key
        })
    }

    handleCustomContentChange(value: CustomContentType) {
        switch (value) {

            case CustomContentType.Website:
                this.props.onChange(true, 'websiteCustomContent')        
                break;

            case CustomContentType.FacebookMarketplace:
                this.props.onChange(true, 'facebookMarketplaceCustomContent')
                break;

            case CustomContentType.BazosCz:
                this.props.onChange(true, 'bazosCzUseDescription')
                break;

            case CustomContentType.BazosSk:
                this.props.onChange(true, 'bazosSkUseDescription')
                break;

            case CustomContentType.Sbazar:
                this.props.onChange(true, 'sbazarUseDescription')
                break;

            case CustomContentType.FacebookShop:
                this.props.onChange(true, 'facebookCustomContent')
                break;

            case CustomContentType.Tipmoto:
                this.props.onChange(true, 'tipMotoCustomContent')
                break;

            default:
        }

        this.switchTab(value);
    }

    getCustomContentOptions(): IOptionData[] {
        const { product, user } = this.props;

        let options: IOptionData[] = [
           // { label: 'Prodejní karta', value: CustomContentType.SalesCard },
        ];

        if (!product.tipMotoCustomContent && user.uploaderServers.includes(OnlineServer.TipMoto)) {
            options.push({ label: 'TipMoto.com', value: CustomContentType.Tipmoto });
        }
        if (!product.websiteCustomContent) {
            options.push({ label: 'Web', value: CustomContentType.Website });
        }
    /*    if (!product.facebookMarketplaceCustomContent) {
            options.push({ label: 'Facebook Marketplace', value: CustomContentType.FacebookMarketplace });
        }*/
        if (!product.facebookCustomContent && user.uploaderServers.includes(OnlineServer.FacebookShop)) {
            options.push({ label: 'Facebook Obchod', value: CustomContentType.FacebookShop });
        }
        if (!product.bazosCzUseDescription && user.uploaderServers.includes(OnlineServer.BazosCz))
            options.push({ label: 'Bazoš.cz', value: CustomContentType.BazosCz });

        if (!product.bazosSkUseDescription && user.uploaderServers.includes(OnlineServer.BazosSk)) {
            options.push({ label: 'Bazoš.Sk', value: CustomContentType.BazosSk });
        }

        if (!product.sbazarUseDescription && user.uploaderServers.includes(OnlineServer.Sbazar)) {
            options.push({ label: 'Sbazar.cz', value: CustomContentType.Sbazar });
        }
        return options;
    }

    render() {
        const { activeTabKey, tabs } = this.state;
        const { product, onChange, collection, globalSettings, user } = this.props;

        return (
            <div className="nav-tabs-product-editor pt-2 pb-4">
                <Tabs
                    activeKey={activeTabKey}
                    id="uncontrolled-tab-example"
                    className="nav nav-justified"
                    onSelect={(key: string, e: any) => this.setState({ activeTabKey: key })}
                >
                    <Tab
                        eventKey="general"
                        title={<DescriptionTabTitle enabled={true} title={`Obsah [${LanguageHelper.getLabel(user.language)}]`} isValid={tabs.generalValid} />}
                        tabClassName="nav-item"
                    >
                        {/*     <p>Výchozí jazyk zadávání můžete nastavit <Link to="/nastaveni/prodejce">zde</Link>.</p>*/}
                        <GeneralEditor
                            ref={this.generalEditor}
                            globalSettings={globalSettings}
                            product={product}
                            collection={collection}
                            onChange={onChange}
                            validChanged={(valid) => this.handleChangeValid('generalValid', valid)}
                        />
                    </Tab>

                    {product.bazosCZ && product.bazosCzUseDescription &&
                        <Tab
                            eventKey={CustomContentType.BazosCz}
                            title={<DescriptionTabTitle enabled={product.bazosCzUseDescription} title="Bazos.cz" isValid={tabs.bazosCzValid} />}
                            tabClassName="nav-item"
                        >
                            <BazosCzEditor
                                ref={this.bazosCzEditor}
                                globalSettings={globalSettings}
                                product={product}
                                collection={collection}
                                onChange={onChange}
                                validChanged={(valid) => this.handleChangeValid('bazosCzValid', valid)}
                            />
                        </Tab>}

                    {product.bazosSK && product.bazosSkUseDescription &&
                        <Tab
                            eventKey={CustomContentType.BazosSk}
                            title={<DescriptionTabTitle enabled={product.bazosSkUseDescription} title="Bazos.sk" isValid={tabs.bazosSkValid} />}
                            tabClassName="nav-item"
                        >
                            <BazosSkEditor
                                ref={this.bazosSkEditor}
                                globalSettings={globalSettings}
                                product={product}
                                collection={collection}
                                onChange={onChange}
                                validChanged={(valid) => this.handleChangeValid('bazosSkValid', valid)}
                            />
                        </Tab>}

                    {product.sbazar && product.sbazarUseDescription &&
                        <Tab
                            eventKey={CustomContentType.Sbazar}
                            title={<DescriptionTabTitle enabled={product.sbazarUseDescription} title="Sbazar.cz" isValid={tabs.sbazarValid} />}
                            tabClassName="nav-item"
                        >
                            <SbazarEditor
                                ref={this.sbazarEditor}
                                product={product}
                                collection={collection}
                                onChange={onChange}
                                validChanged={(valid) => this.handleChangeValid('sbazarValid', valid)}
                            />
                        </Tab>}

                    {product.tipMoto && product.tipMotoCustomContent &&
                        <Tab
                            eventKey={CustomContentType.Tipmoto}
                            title={<DescriptionTabTitle enabled={product.tipMotoCustomContent} title="TipMoto.com" isValid={tabs.tipMotoValid} />}
                            tabClassName="nav-item"
                        >
                            <TipMotoEditor
                                ref={this.tipMotoEditor}
                                product={product}
                                onChange={onChange}
                                validChanged={(valid) => this.handleChangeValid('tipMotoValid', valid)}
                            />
                        </Tab>}

                    {product.facebookCustomContent &&
                        <Tab
                            eventKey={CustomContentType.FacebookShop}
                            title={<DescriptionTabTitle enabled={product.facebookCustomContent} title="FB Obchod" isValid={tabs.facebookValid} />}
                            tabClassName="nav-item"
                        >
                            <FacebookEditor
                                ref={this.facebookEditor}
                                product={product}
                                onChange={onChange}
                                validChanged={(valid) => this.handleChangeValid('facebookValid', valid)}
                            />
                        </Tab>}

                    {product.facebookMarketplaceCustomContent &&
                        <Tab
                            eventKey={CustomContentType.FacebookMarketplace}
                            title={<DescriptionTabTitle enabled={product.facebookMarketplaceCustomContent} title="FB Marketplace" isValid={tabs.facebookMarketplaceValid} />}
                            tabClassName="nav-item"
                        >
                            <FacebookMarketplaceEditor
                                ref={this.facebookMarketplaceEditor}
                                product={product}
                                onChange={onChange}
                                validChanged={(valid) => this.handleChangeValid('facebookMarketplaceValid', valid)}
                            />
                        </Tab>}

                    {product.websiteCustomContent &&
                        <Tab
                            eventKey={CustomContentType.Website}
                            title={<DescriptionTabTitle enabled={product.websiteCustomContent} title="Web" isValid={tabs.websiteValid} />}
                            tabClassName="nav-item"
                        >
                            <WebsiteEditor
                                ref={this.websiteEditor}
                                product={product}
                                onChange={onChange}
                                validChanged={(valid) => this.handleChangeValid('websiteValid', valid)}
                            />
                        </Tab>}

                    {product.type === ProductType.Car &&
                        <Tab
                            eventKey={CustomContentType.SalesCard}
                            title={<DescriptionTabTitle enabled={(product.salesCardDescription || '').length > 0} title="Prodejní karta" isValid={true} />}
                            tabClassName="nav-item"
                        >
                            <SalesCardEditor
                                product={product}
                                onChange={onChange}
                            />
                        </Tab>}

                    <Tab
                        as="div"
                        title={
                            <>
                                <SelectBox
                                    label={""}
                                    value=""
                                    className="form-control setCustomContent"
                                    formGroupClassName="m-0"
                                    defaultValue="Jiný obsah"
                                    options={this.getCustomContentOptions()}
                                    onChange={(value) => this.handleCustomContentChange(value)}
                                />
                            </>}
                        tabClassName="nav-item p-0"
                    />
                </Tabs>
            </div>)
    }
}