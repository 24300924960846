import React from 'react';
import IProduct from '../../../../../Interfaces/IProduct';
import IProductCollection from '../../../../../Interfaces/IProductCollection';
import ProductBazosSkSettings from '../ProductBazosSkSettings';
import ProductBazosCzSettings from '../ProductBazosCzSettings';
import ProductSbazarSettings from '../ProductSbazarSettings';
import { inject, observer } from 'mobx-react';
import ProductFacebookCategoriesBox from '../../../../Products/ProductFacebookCategoriesBox';
import BaseComponent from '../../../../BaseComponent';
import LoadingScreen from '../../../../Screens/LoadingScreen';
import ProductStore from '../../../../Stores/ProductStore';
import InputText from '../../../../UI/Input/InputText';
import { ProductHelper } from '../../../../../Helpers/ProductHelper';
import { LeavePageWarningPrompt } from '../../../../Others/LeavePageWarningPrompt';
import { SectionTitle } from '../../../../Others/SectionTitle';
import InputPrice from '../../../../UI/Input/InputPrice';
import InputDatePicker from '../../../../UI/Input/InputDatePicker';
import CurrentUserStore from '../../../../Stores/CurrentUserStore';
import ProductGoogleCategoriesBox from '../../../../Products/ProductGoogleCategoriesBox';

interface IState {
    loading: boolean,
}

interface IProps {
    onChange(key: string, value: any): void,
    product: IProduct,
    collection: IProductCollection,
    productStore?: ProductStore,
    onChangeProduct(product: IProduct): void,
    currentUserStore?: CurrentUserStore,
}

@inject('productStore', 'currentUserStore')
@observer
export default class ProductWizardStepServers extends BaseComponent<IProps, IState> {
    private productBazosCzSettings: React.RefObject<ProductBazosCzSettings>;
    private productBazosSkSettings: React.RefObject<ProductBazosSkSettings>;
    private productSbazarSettings: React.RefObject<ProductSbazarSettings>;
    private productFacebookCategoriesBox: React.RefObject<ProductFacebookCategoriesBox>;
    private productGoogleCategoriesBox: React.RefObject<ProductGoogleCategoriesBox>;
    private inputBrand: React.RefObject<InputText>;
    private inputCostPrice: React.RefObject<InputPrice>;
    private inputDatePicker: React.RefObject<InputDatePicker>;
    private inputNote: React.RefObject<InputText>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
        }

        this.productBazosCzSettings = React.createRef();
        this.productBazosSkSettings = React.createRef();
        this.productSbazarSettings = React.createRef();
        this.productFacebookCategoriesBox = React.createRef();
        this.productGoogleCategoriesBox = React.createRef();
        this.inputBrand = React.createRef();
        this.inputCostPrice = React.createRef();
        this.inputDatePicker = React.createRef();
        this.inputNote = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const product = this.props.product;

        this.productApiService.getCategories(product)
            .then(categories => {
                if (categories.bazosSectionId) {
                    if (!product.bazosCzSection)
                        product.bazosCzSection = categories.bazosSectionId;

                    if (!product.bazosSkSection)
                        product.bazosSkSection = categories.bazosSectionId;
                }

                if (categories.bazosCategoryId) {
                    if (!product.bazosCzCategory)
                        product.bazosCzCategory = categories.bazosCategoryId;

                    if (!product.bazosSkCategory)
                        product.bazosSkCategory = categories.bazosCategoryId;
                }

                if (categories.sbazarCategoryId) {
                    if (!product.sbazarCategoryId)
                        product.sbazarCategoryId = categories.sbazarCategoryId;
                }

                if (categories.googleCategoryId) {
                    if (!product.googleProductCategoryId)
                        product.googleProductCategoryId = categories.googleCategoryId;
                }

                this.props.onChangeProduct(product);

                this.setState({
                    loading: false,
                })
            });
    }

    validate(): boolean {
        const { product } = this.props;
        let fieldsValid = true;

        if (ProductHelper.isBrandRequired(product)) {
            fieldsValid = this.validateInputs([this.inputBrand]);
        }

        const validations = [
            this.productBazosCzSettings?.current?.validate() ?? true,
            this.productBazosSkSettings?.current?.validate() ?? true,
            this.productSbazarSettings?.current?.validate() ?? true,
            this.productFacebookCategoriesBox?.current?.validate() ?? true,
            this.productGoogleCategoriesBox?.current?.validate() ?? true,
        ];
        return fieldsValid && validations.every(validation => validation);
    }

    render() {
        const product = this.props.product;
        const collection = this.props.collection;
        const user = this.props.currentUserStore.user;

        if (this.state.loading) {
            return <LoadingScreen />
        }

        return (
            <div className="pb-2">
                <LeavePageWarningPrompt when={true} />

                <div className="row mt-4">
                    {ProductHelper.isBrandRequired(product) &&
                        <div className="col-12 col-md-6 col-lg-4">
                            <InputText
                                ref={this.inputBrand}
                                label="Značka produktu"
                                value={product.brand}
                                required={product.facebookCatalogEnabled}
                                placeholder="např. Adidas"
                                onChange={this.props.onChange.bind(this, 'brand')}
                                forbidSpecialChars={true}
                            />
                            <hr />
                        </div>}
                </div>

                <div className="row">
                    {product.bazosCZ &&
                        <div className="col-12 col-md-6">
                            <ProductBazosCzSettings
                                ref={this.productBazosCzSettings}
                                collection={collection}
                                onChange={(key, value) => this.props.onChange(key, value)}
                                product={product}
                                disabled={product.bazosCzVisible === true}
                            />
                        </div>}

                    {product.bazosSK &&
                        <div className="col-12 col-md-6">
                            <ProductBazosSkSettings
                                ref={this.productBazosSkSettings}
                                collection={collection}
                                onChange={(key, value) => this.props.onChange(key, value)}
                                product={product}
                                disabled={product.bazosSkVisible === true}
                            />
                        </div>}

                    {product.sbazar &&
                        <div className="col-12">
                            <ProductSbazarSettings
                                ref={this.productSbazarSettings}
                                collection={collection}
                                onChange={this.props.onChange.bind(this)}
                                product={product}
                            />
                        </div>}
                </div>

                {product.facebookCatalogEnabled &&
                    <ProductFacebookCategoriesBox
                        ref={this.productFacebookCategoriesBox}
                        product={product}
                        onChange={this.props.onChange.bind(this)}
                    />}

                {product.googleMerchant &&
                    <ProductGoogleCategoriesBox
                        ref={this.productGoogleCategoriesBox}
                        product={product}
                        onChange={this.props.onChange.bind(this)}
                    />}

                <SectionTitle className="mt-3" title="Obchodní údaje" />

                <hr />
                <div className="row mt-2">
                    <div className="col-12 col-md-6">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <InputPrice
                                    ref={this.inputCostPrice}
                                    label="Pořizovací cena"
                                    value={product.costPrice}
                                    currency={user.accountCurrency}
                                    onChange={(val) => this.props.onChange('costPrice', val)}
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <InputDatePicker
                                    ref={this.inputDatePicker}
                                    label="Datum pořízení"
                                    value={product.dateOfPurchase ? new Date(product.dateOfPurchase) : null}
                                    onChange={(val) => this.props.onChange('dateOfPurchase', val)}
                                    inputUtcShowLocalOutputUtc={true}
                                />
                            </div>

                            <div className="col-12">
                                <InputText
                                    ref={this.inputNote}
                                    label="Interní poznámka"
                                    value={product.note}
                                    onChange={(val) => this.props.onChange('note', val)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}