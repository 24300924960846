import React from 'react';
import CustomDocumentTitle from '../Utils/CustomDocumentTitle';

interface IState {

}

interface IProps {
    show?: boolean
    withCard?: boolean
}

export default class LoadingScreen extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    render() {
        if (this.props.show === false) {
            return null;
        }

        if (this.props.withCard === false) {
            return this.renderContent();
        }

        return (
            <div className="card">
                <div className="card-body">
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    renderContent() {
        return (
            <CustomDocumentTitle title="Načítání...">
                <div className="py-3">
                    <div className="d-flex justify-content-center">
                        <div className="lds-dual-ring"></div>
                    </div>

                    <br />
                    <div className="text-center">
                        <h4>Načítám...</h4>
                    </div>
                </div>
            </CustomDocumentTitle>
        )
    }

}