import { drop, take } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { AutoImporterSourceType } from "../../../Enums/AutoImporterSourceType";
import AutoimporterHelper from "../../Utils/AutoimporterHelper";

interface IProps {
    sourceType: AutoImporterSourceType,
}

export const AutoImporterVariables = (props: IProps) => {

    const variables = AutoimporterHelper.getVariables(props.sourceType);

    return (
        <>
            <div className="row variables">
                <div className="col">
                    <ul style={{ listStyle: 'none' }}>
                        {take(variables, variables.length / 2).map(variable => {
                            return (
                                <li key={variable.phrase}>
                                    <OverlayTrigger

                                        placement={'right'}
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                {variable.tooltip}
                                                <br />
                                                <small>Ilustrační hodnota</small>
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={variable.phrase} onCopy={() => toast.info('Zkopírováno')}>
                                            <span style={{ cursor: 'pointer' }}>{variable.phrase}</span>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="col">
                    <ul style={{ listStyle: 'none' }}>
                        {drop(variables, variables.length / 2).map(variable => {
                            return (
                                <li key={variable.phrase}>
                                    <OverlayTrigger

                                        placement={'right'}
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                {variable.tooltip}
                                                <br />
                                                <small>Ilustrační hodnota</small>
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={variable.phrase} onCopy={() => toast.info('Zkopírováno')}>
                                            <span style={{ cursor: 'pointer' }}>{variable.phrase}</span>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}