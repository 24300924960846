import React from 'react';
import { WebmailPricing } from '../../Pricing/WebmailPricing';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';

export const WebmailPricingPage = () => {
        return (
            <CustomDocumentTitle title="Ceník - Webmail">
                <div>
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">Ceník - Emailové schránky</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <WebmailPricing />
                        </div>
                    </div>
                </div>
            </CustomDocumentTitle>
        )
  
}