import axios from 'axios';
import Swal from 'sweetalert2';
import StoreUtil from './components/Stores/StoreUtil';

axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';// 'application/x-www-form-urlencoded'; 

function showLoadingDialog(url: string): boolean {
    var forbiddemReq: RegExp[] = [
        new RegExp("https:\/\/rest.ably.io[/]keys\/.*\/requestToken"),
        new RegExp("products/missing-fields(\\?.*)?$"),
        new RegExp("products/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/required-fields(\\?.*)?$"),
        new RegExp("^https:\/\/s3.eu-central-1.amazonaws.com\/images.inzerobot.cz\/.*$"), // Added new RegExp for new URL
        new RegExp("products/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/images(\\?.*)?$"),
    ];
   
    let show: boolean = true;

    forbiddemReq.map((x: RegExp) => {
        if (x.test(url.replace(/ /g, ''))) {
            show = false;
        }
    });
    return show;
}

var numberOfCAllPendingPutPostDelete: number = 0;

axios.interceptors.request.use(request => {
    var forbiddenRequests: string[] = [
        "/user/product-collections/validate/sbazar",
        "/user/product-collections/validate/sbazar?",
        "products/validate/name",
        "products/validate/name?",
        "/user/ably/auth",
        "/user/ably/auth?",
        "products/generate-description?",
        "products/required-fields?",
        "product-images?",
        "product-images/presigned-url-upload?" 
    ];

    numberOfCAllPendingPutPostDelete++;
    if ((request.method === "put" || request.method === "post" || request.method === "delete")
        && (forbiddenRequests.includes(request.url) === false)
        && showLoadingDialog(request.url)) {

        Swal.fire({
            title: 'Načítám',
            didOpen: () => {
                Swal.showLoading();
            },
        })
    }

    // Edit request config
    return request;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {

    numberOfCAllPendingPutPostDelete = numberOfCAllPendingPutPostDelete - 1;

    if (numberOfCAllPendingPutPostDelete === 0) {
        Swal.close();
    }

    if (response.data.format === 'universal') {
        if (response.data.others) {
            response.data.others = new Map(Object.entries(response.data.others || []));
        }
        
        StoreUtil.process(response.data);       
    }
   
    return response;
}, error => {
    numberOfCAllPendingPutPostDelete--;
    if (numberOfCAllPendingPutPostDelete === 0) {
        Swal.close();
    }

    if (axios.isCancel(error) === false) {
        if (error.response) {
            if (error.response.status === 403) {
                Swal.fire(
                    'K této akci nemáš patřičná oprávnění',
                    error.response.data.error,
                    'error')
            }
            if (error.response.status === 503) {
                Swal.fire(
                    'Aplikace je dočasně nedostupná',
                     'Zřejmě probíhá údržba aplikace. Zkuste to později.',
                    'error')
            }
            if (error.response.status === 401 && window.location.pathname !== '/account/login') {
                window.location.assign('/ucet/prihlaseni');
                return Promise.resolve(error.response);
            }
            if (error.response.data.format === 'universal') {
                StoreUtil.process(error.response.data);
            }
        }     
    }
    return Promise.reject(error);
});