import { OnlineServer } from "../Enums/OnlineServer";
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class ServerAccountsApiService extends BaseApiService {

    readonly baseUrl: string = "serveraccounts";

    public getServerAccount(id: string): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public getList(server: OnlineServer): Promise<IResponseData> {
        const serverStr = server.toString();
        return this.getRequest(`${this.baseUrl}`, { server: serverStr })
            .then(response => {
                return response.data;
            })
    }

    public uploadCookies(accountId: string, file: File) {
        const formData = new FormData();
        formData.append("file", file);

        return this.postFile(`${this.baseUrl}/${accountId}/cookies`, {}, formData)
            .then(response => {
                return response.data;
            })
    }
}