import { ProductHelper } from "../../Helpers/ProductHelper";
import IProductServerInfo from "../../Interfaces/IProductServerInfo";
import { OnlineServerHelper } from "../Utils/OnlineServerHelper";

interface IProps {
    info: IProductServerInfo,
    onClick(): void,
}

export const ProductServerLabelContent = (props: IProps) => {

    const { info } = props;

    if (!info.enabled) {
        return <span onClick={() => props.onClick()} className="text-secondary productServerLabel">
            <i className="fas fa-times"></i>&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
    if (info.error && ProductHelper.getFatalErrorTypes().includes(info.errorType)) {
        return <span onClick={() => props.onClick()} className="text-secondary productServerLabel">
            <i className="fa fa-exclamation-triangle" />&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
    if (info.pendingCheck) {
        return <span onClick={() => props.onClick()} className="text-primary productServerLabel">
            <i className="bi bi-check2-square Blink" />&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
    if (info.error) {
        return <span onClick={() => props.onClick()} className="text-danger productServerLabel">
            <i className="fas fa-exclamation-triangle" />&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
    if (info.serverRequiredAction) {
        return <span onClick={() => props.onClick()} className="text-warning productServerLabel">
            <i className="bi bi-exclamation-triangle" />&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
    else if (info.pendingEdit) {
        return <span onClick={() => props.onClick()} className="text-primary productServerLabel">
            <i className="far fa-edit Blink" />&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
    else if (info.pendingDelete) {
        return <span onClick={() => props.onClick()} className="text-primary productServerLabel">
            <i className="far fa-trash-alt Blink" />&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
    if (info.pendingUpload) {
        if (info.isVisible) {
            return <span onClick={() => props.onClick()} className="text-primary productServerLabel">
                <i className="fas fa-retweet Blink" />&nbsp;{OnlineServerHelper.getName(info.server)}
            </span>
        }
        return <span onClick={() => props.onClick()} className="text-primary productServerLabel">
            <i className="fas fa-upload Blink" />&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
    if (info.isVisible) {
        if (info.isTop) {
            return <span onClick={() => props.onClick()} className="text-success productServerLabel">
                <i className="bi bi-star-fill" />&nbsp;{OnlineServerHelper.getName(info.server)}
            </span>
        }
        return <span onClick={() => props.onClick()} className="text-success productServerLabel">
            <i className="fas fa-check" />&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
    if (!info.isVisible) {
        return <span onClick={() => props.onClick()} className="text-secondary productServerLabel">
            <i className="fa fa-circle" />&nbsp;{OnlineServerHelper.getName(info.server)}
        </span>
    }
}