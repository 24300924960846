import React from "react";
import { ProductHelper } from "../../../Helpers/ProductHelper";
import IProduct from "../../../Interfaces/IProduct";
import BaseComponent from "../../BaseComponent";
import CheckBox from "../../UI/Input/CheckBox";
import InputText from "../../UI/Input/InputText";
import Textarea from "../../UI/Input/Textarea";
import { TinyMCEEditor } from "../../UI/TinyMCEEditor";

interface IState {

}

interface IProps {
    product: IProduct,
    onChange(value: any, key: string): void,
    validChanged?(isValid: boolean): void,
}

export default class WebsiteEditor extends BaseComponent<IProps, IState>
{
    private inputWebsiteName = React.createRef<InputText>();
    private inputWebsiteDescription = React.createRef<Textarea>();

    constructor(props: IProps) {
        super(props);

        this.inputWebsiteName = React.createRef();
        this.inputWebsiteDescription = React.createRef();
    }

    componentDidMount() {
        this.checkValidate();
    }

    getInputs() {
        return [this.inputWebsiteName, this.inputWebsiteDescription];
    }

    checkValidate() {
        const inputs = this.getInputs();
        const isValid = this.isValid(inputs);

        this.props.validChanged(isValid);
    }

    checkIsValid() {
        const inputs = this.getInputs();
        return this.isValid(inputs);
    }

    validate(): boolean {
        const inputs = this.getInputs();
        const isValid = this.validateInputs(inputs);
        this.props.validChanged(isValid);
        return isValid;
    }

    render() {
        const { product, onChange } = this.props;

        return (
            <>
                <div className="pb-3 pt-1">
                    <CheckBox
                        checked={product.websiteCustomContent}
                        onChange={(value) => onChange(value, 'websiteCustomContent')}
                        label="Nastavit jiný obsah"
                    />
                </div>
                
                <div className={product.websiteCustomContent ? 'd-block' : 'd-none'}>
                    <InputText
                        ref={this.inputWebsiteName}
                        label="Nadpis"
                        onChange={(value) => onChange(value, 'websiteName')}
                        value={product.websiteName}
                        required
                        maxLength={ProductHelper.getMaxNameCharsCount()}
                        onBlur={this.checkValidate.bind(this)}
                    />

                    <TinyMCEEditor
                        label="Popis"
                        onChange={(value) => onChange(value, 'websiteDescription')}
                        required
                        value={product.websiteDescription}
                        height={500}
                        toolbar={'undo redo | formatselect | bold italic underline | ' +
                            'forecolor backcolor | fontsizeselect | fontsize |' +
                            'bullist numlist outdent indent | removeformat | ' +
                            'h2 h3 h4 h5 h6'}
                    />
                </div>
            </>)
    }
}