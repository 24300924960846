import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import IProduct from "../../Interfaces/IProduct"
import IProductCollection from "../../Interfaces/IProductCollection";
import ProductApiService from "../../Services/ProductApiService";
import { ShowDate } from "../Others/ShowDate";
import LoadingScreen from "../Screens/LoadingScreen";
import { MainProductImg } from "./MainProductImg";
import { ProductServerModalTabs } from "./ProductServerModal/ProductServerModalTabs";

interface IProps {
    product: IProduct,
    collection: IProductCollection,
    onHide(): void,
    defaultServer?: string,
}

const productApiService = new ProductApiService();

export const ProductServersModal = (props: IProps) => {

    const { product, onHide, defaultServer, collection } = props;

    const [loading, setLoading] = useState(true);
    const [activeServer, setActiveServer] = useState(defaultServer);

    useEffect(() => {
        productApiService.getLogs(product.id)
            .then(data => {
                setLoading(false);
            });

        return () => {
            productApiService.cancelRequests();
        }
    }, [])

    return (
        <Modal dialogClassName="modal-server-details" size="xl" centered={true} show={true} onHide={() => onHide()}>
            <Modal.Body className="position-relative">
                <div className="clearfix">
                    <MainProductImg
                        product={product}
                        className="float-left mr-3 d-none d-lg-block"
                    />

                    <div className="mb-0 text-black"> 
                        <b>{product.name}</b>

                        <ul className="list-unstyled text-secondary mt-2">
                            <li><small>Vytvořeno: <ShowDate date={product.dateCreated} /></small></li>
                            <li><small>Kolekce: <Link to={`/nahravac/kolekce/${collection.id}/prehled`}>{collection.name}</Link></small></li>
                        </ul>
                        <p className="text-secondary"></p>
                    </div>
                </div>
                <hr />

                {loading ? <LoadingScreen withCard={false} /> : <ProductServerModalTabs
                    product={product}
                    onKeyChange={(key) => setActiveServer(key)}
                    activeKey={activeServer}
                />}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onHide()} variant="secondary">
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>
    )
}