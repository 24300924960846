import { observable, action, makeAutoObservable } from 'mobx'
import { filter, findIndex } from 'lodash';
import { IProductServerAccount } from '../../Interfaces/IProductServerAccount';

export default class ProductServerAccountStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IProductServerAccount[] = [];

    @action
    addRecords(data: any): any {
        data.forEach((record: IProductServerAccount) => this.addRecord(record));
    }

    @action
    private addRecord(record: IProductServerAccount) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { productId: record.productId, serverAccountId: record.serverAccountId });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    getBy(productId: string, accountId: string): IProductServerAccount[] {
        return filter(this.list, item => {
            if (item.productId === productId && item.serverAccountId === accountId) {
                return true;
            }
            return false;
        });
    }

    getByProductId(productId: string): IProductServerAccount[]{
        return filter(this.list, item => {
            if (item.productId === productId) {
                return true;
            }
            return false;
        });
    }
}