import { unstable_useBlocker as useBlocker } from 'react-router-dom'

interface IProps {
    when: boolean,
    message: string,
}

export function Prompt(props: IProps) {
    const block = props.when

    useBlocker(() => {
        if (block) {
            return !window.confirm(props.message)
        }
        return false
    })
    return <></>
}

export default Prompt