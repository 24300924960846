import { find } from "lodash";
import { inject, observer } from "mobx-react";
import React from "react";
import FacebookCatalogHelper from "../../../Helpers/FacebookCatalogHelper";
import WebsiteHelper from "../../../Helpers/WebsiteHelper";
import IAutoImporter from "../../../Interfaces/IAutoImporter";
import IOptionData from "../../../Interfaces/IOptionData";
import BaseComponent from "../../BaseComponent";
import FacebookCatalogStore from "../../Stores/FacebookCatalogStore";
import ProductCollectionStore from "../../Stores/ProductCollectionStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import CheckBox from "../../UI/Input/CheckBox";
import MultiSelectBox from "../../UI/Input/MultiSelectBox";
import SelectBox from "../../UI/Input/SelectBox";
import Textarea from "../../UI/Input/Textarea";
import InputNumber from "../../UI/Input/InputNumber";
import ServerAccountStore from "../../Stores/ServerAccountStore";
import { OnlineServer } from "../../../Enums/OnlineServer";
import { SectionTitle } from "../../Others/SectionTitle";

interface IState {

}

interface IProps {
    autoImporter: IAutoImporter,
    productCollectionStore?: ProductCollectionStore,
    facebookCatalogStore?: FacebookCatalogStore,
    websiteStore?: WebsiteStore,
    serverAccountStore?: ServerAccountStore,
    handleChange(value: any, key: string): void
}

@inject('productCollectionStore', 'facebookCatalogStore', 'websiteStore','serverAccountStore')
@observer
export default class AutoImporterSettings extends BaseComponent<IProps, IState> {

    private inputCollections = React.createRef<MultiSelectBox>();
    private inputFacebookCatalogId = React.createRef<SelectBox>();
    private inputWebsiteId = React.createRef<SelectBox>();
    private inputUpdateForeignPricePercent = React.createRef<InputNumber>();
    private inputServerAccounts = React.createRef<MultiSelectBox>();

    constructor(props: IProps) {
        super(props);

        this.inputCollections = React.createRef();
        this.inputFacebookCatalogId = React.createRef();
        this.inputWebsiteId = React.createRef();
        this.inputUpdateForeignPricePercent = React.createRef();
        this.inputServerAccounts = React.createRef();
    }

    validate(): boolean {
        return this.validateInputs(this.getInputs());
    }

    getInputs() {
        return [
            this.inputCollections,
            this.inputFacebookCatalogId,
            this.inputWebsiteId,
            this.inputUpdateForeignPricePercent,
        ];
    }

    render() {
        let collectionOptions: IOptionData[] = [];

        var collections = this.props.productCollectionStore.list;
        collections.map(collection => {
            collectionOptions.push({
                label: collection.name,
                value: collection.id,
            });
        })

        const autoImporter = this.props.autoImporter;
        let collectionSelectedOptions: IOptionData[] = [];
        autoImporter.collectionIds.map(id => {
            const collection = find(collections, { id: id });

            collectionSelectedOptions.push({
                label: collection.name,
                value: collection.id,
            });
        })

        let sbazarAccountOptions: IOptionData[] = [];
        var sbazarAccounts = this.props.serverAccountStore.getByServer(OnlineServer.Sbazar);
        sbazarAccounts.map(acc => {
            sbazarAccountOptions.push({
                label: acc.name,
                value: acc.id,
            });
        })
        
        let sbazarAccountSelectedOptions: IOptionData[] = [];
        autoImporter.exportServerAccountIds.map(id => {
            const sbazarAccount = find(sbazarAccounts, { id: id });

            sbazarAccountSelectedOptions.push({
                label: sbazarAccount.name,
                value: sbazarAccount.id,
            });
        })

        const catalogs = this.props.facebookCatalogStore.getByUserId(autoImporter.userId);
        const fbCatalogOptions: IOptionData[] = FacebookCatalogHelper.getOptions(catalogs);

        const websites = this.props.websiteStore.getByUserId(autoImporter.userId);
        const onChange = this.props.handleChange;

        return (
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="mt-4">
                        <MultiSelectBox
                            ref={this.inputCollections}
                            uid="collectionIds"
                            label="Kolekce do kterých importovat inzeráty"
                            options={collectionOptions}
                            onChange={(ids: string[], key: string) => onChange(ids, key)}
                            selected={collectionSelectedOptions}
                            required
                        />
                    </div>

                    <SectionTitle title="Marketingové kanály" className="mt-5 mb-0" />
                    <hr />

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="mt-4">
                                <SelectBox
                                    ref={this.inputFacebookCatalogId}
                                    label="Facebook Katalog"
                                    options={fbCatalogOptions}
                                    onChange={(value) => onChange(value, 'facebookCatalogId')}
                                    value={autoImporter.facebookCatalogId}
                                    defaultValue="Nevybráno"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="mt-4">
                                <SelectBox
                                    ref={this.inputWebsiteId}
                                    label="Webová stránka"
                                    options={WebsiteHelper.getOptions(websites)}
                                    onChange={(value) => onChange(value, 'websiteId')}
                                    value={autoImporter.websiteId}
                                    defaultValue="Nevybráno"
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mt-4">
                                <MultiSelectBox
                                    ref={this.inputServerAccounts}
                                    uid="exportServerAccountIds"
                                    label="Sbazar účty (týká se neomezených kolekcí)"
                                    options={sbazarAccountOptions}
                                    onChange={(ids: string[], key: string) => onChange(ids, key)}
                                    selected={sbazarAccountSelectedOptions}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <Textarea
                            label="Neimportovat inzeráty, které obsahují: (na každý řádek jedno slovo)"
                            placeholder="klíčové slova pište na řádky"
                            value={(autoImporter.doNotImportForbiddenKeywords || []).map(word => word || '').join('\n').toString()}
                            onChange={(value) => onChange(value.split('\n'), 'doNotImportForbiddenKeywords')}
                        />
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="alert alert-secondary mt-5" role="alert">
                        <CheckBox
                            label="Po importu zařadit inzeráty do prodeje"
                            checked={autoImporter.defaultEnabledNewProduct}
                            onChange={(value) => onChange(value, 'defaultEnabledNewProduct')}
                        />

                        <small><i className="far fa-lightbulb mr-1"></i>
                            {autoImporter.defaultEnabledNewProduct === false && <> Inzeráty budou v aplikaci, ale bude nutné je manuálně zveřejnit.</>}
                            {autoImporter.defaultEnabledNewProduct === true && <>Importované inzeráty budou okamžitě zveřejněny na inzertních serverech.</>}
                        </small>
                    </div>

                    <div className="alert alert-secondary" role="alert">
                        <CheckBox
                            label="Automaticky mazat inzeráty"
                            checked={autoImporter.deleteProductAfterNotFoundInList}
                            onChange={(value) => onChange(value, 'deleteProductAfterNotFoundInList')}
                        />

                        <small><i className="far fa-lightbulb mr-1"></i>
                            {autoImporter.deleteProductAfterNotFoundInList === false && <> Inzeráty bude nutné smazat manuálně v aplikaci.</>}
                            {autoImporter.deleteProductAfterNotFoundInList === true && <>Jestliže inzerát vyprší v platnosti, tak jej smažeme z Inzerobota a zároveň ze všech inzertních serverů.</>}
                        </small>
                    </div>

                    <div className="alert alert-secondary" role="alert">
                        <CheckBox
                            label="Aktualizovat obsah inzerátů"
                            checked={autoImporter.updateProductContent}
                            onChange={(value) => onChange(value, 'updateProductContent')}
                        />

                        <small><i className="far fa-lightbulb mr-1"></i>
                            {autoImporter.updateProductContent === false && <> Obsah inzerátů se nebude aktualizovat s výjimkou ceny.</>}
                            {autoImporter.updateProductContent === true && <>Při každé aktualizaci se použijí aktuální parametry z feedu. Nadpis a popis se přegenerují.</>}
                        </small>
                    </div>
                </div>
            </div>)
    }
}