import { useState } from "react";
import IUser from "../../../Interfaces/IUser"
import { GeoAlt } from "react-bootstrap-icons";
import { GooglePlacesFindModal } from "./GooglePlacesFindModal";
import InputText from "../../UI/Input/InputText";
import GoogleApiService from "../../../Services/GoogleApiService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

interface IProps {
    user: IUser,
    onUserUpdated(user: IUser): void,
}

const googleApiService = new GoogleApiService();

export const MyCompanyGooglePlacesCard = (props: IProps) => {

    const [user, setUser] = useState(props.user);
    const [showFindGooglePlace, setShowFindGooglePlace] = useState(false);

    const onDelete = () => {

        Swal.fire({
            icon: 'warning',
            title: 'Opravdu chcete odpojit firmu?',
            text: 'Některé funkce nemusí být následně dostupné',
            confirmButtonText: "Ano, odpojit",
            cancelButtonText: "Ne, zrušit",
            focusCancel: true,
            showCancelButton: true,
            showConfirmButton: true,
        })
            .then(result => {
                if (result.isConfirmed) {
                    googleApiService.deletePlace()
                        .then(data => {
                            if (data.success) {
                                setUser({
                                    ...user,
                                    googlePlaceSetuped: false,
                                })

                                toast.success("Odpojeno");
                            }
                        })
                }
            })
    }

    return (
        <div className="card">
            <div className="card-body mb-3">
                <h3 className="text-dark"><GeoAlt />  Propojení s firmou na Google Mapách</h3>
                <hr />
                <p>Načtěte informace a hodnocení z vaší firmy na Google Mapách.</p>

                {showFindGooglePlace &&
                    <GooglePlacesFindModal
                        onHide={() => setShowFindGooglePlace(false)}
                        onSave={(user) => {
                            props.onUserUpdated(user)
                            setUser(user);
                        }}
                    />}

                {!user.googlePlaceSetuped &&
                    <button
                        className="btn btn-primary"
                        onClick={() => setShowFindGooglePlace(true)}
                    >
                        <GeoAlt /> Propojit s firmou na Google Mapách
                    </button>}

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        {user.googlePlaceSetuped && <>
                            <InputText
                                label="Propojená firma"
                                readonly
                                value={user.googlePlaceName}
                            />

                            <button
                                className="btn btn-outline-danger"
                                onClick={onDelete}
                            >
                                Odpojit
                            </button>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    )
}