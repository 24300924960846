import IProduct from '../../../../Interfaces/IProduct';
import BaseComponent from './../../../BaseComponent';
import React from 'react';
import SelectBox from '../../../UI/Input/SelectBox';
import IInputField from '../../../../Interfaces/IInputField';
import IProductCollection from '../../../../Interfaces/IProductCollection';
import IOptionData from '../../../../Interfaces/IOptionData';
import { ServerSettingAccordion } from '../../../Products/ServerSettingsAccordion';
import { find } from 'lodash';
import StringHelper from '../../../../Helpers/StringHelper';
import { BazosHelper } from '../../../../Helpers/Servers/BazosHelper';

interface IState {
    isValid: boolean,
}

interface IProps {
    product: IProduct,
    collection: IProductCollection,
    onChange(key: string, value: any): void,
    disabled: boolean,
}

export default class ProductBazosCzSettings extends BaseComponent<IProps, IState> {

    private inputBazosCzPrice: React.RefObject<SelectBox>;
    private inputBazosCzSection: React.RefObject<SelectBox>;
    private inputBazosCzCategory: React.RefObject<SelectBox>;

    constructor(props: IProps) {
        super(props);

        this.state = ({
            isValid: false,
        })

        this.inputBazosCzPrice = React.createRef();
        this.inputBazosCzSection = React.createRef();
        this.inputBazosCzCategory = React.createRef();
    }

    componentDidMount() {
        this.checkIsValid();

        const product = this.props.product;
        const categories = BazosHelper.getCategories(product.bazosCzSection);

        const selectedCat = find(categories, { value: product.bazosCzCategory });
        if (!selectedCat) {
            this.props.onChange('bazosCzCategory', 0);
        }
    }

    componentDidUpdate() {
        this.checkIsValid();
    }

    checkIsValid() {    
        const inputs = this.getInputs();
        const isValid = this.isValid(inputs);

        if (this.state.isValid !== isValid) {
            this.setState({
                isValid: isValid
            })
        }
    }

    getInputs() {
        const inputs: React.RefObject<IInputField>[] = [];

        let collection = this.props.collection;
        let product = this.props.product;

        if (collection.bazosCZ && product.bazosCZ) {
            inputs.push(
                this.inputBazosCzPrice,
                this.inputBazosCzSection,
                this.inputBazosCzCategory
            )
        }
        return inputs;
    }

    validate() {
        const inputs = this.getInputs();
        return this.validateInputs(inputs);
    }

    renderFields() {
        const product = this.props.product;
        let bazosPriceOptions: IOptionData[] = [
            { label: `${StringHelper.separateThousands(product.priceCZK)} Kč`, value: 1 }
        ]

        const categories = BazosHelper.getCategories(product.bazosCzSection);

        return (
            <div className="row">
                <div className="col-12 col-md-4">
                    <SelectBox
                        ref={this.inputBazosCzPrice}
                        label="Cena"
                        options={bazosPriceOptions.concat(BazosHelper.getPriceOptions())}
                        defaultValue={"Vyberte"}
                        required
                        value={product.bazosCzPriceOption}
                        onChange={this.props.onChange.bind(this, 'bazosCzPriceOption')}
                        disabled={false}
                        onBlur={this.checkIsValid.bind(this)}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <SelectBox
                        ref={this.inputBazosCzSection}
                        label="Sekce"
                        options={BazosHelper.getSectionsByProduct(product)}
                        defaultValue={"Vyberte"}
                        required
                        value={product.bazosCzSection}
                        onChange={this.props.onChange.bind(this, 'bazosCzSection')}
                        disabled={this.props.disabled}
                        onBlur={this.checkIsValid.bind(this)}
                    />
                </div>

                <div className="col-12 col-md-4">
                    <SelectBox
                        ref={this.inputBazosCzCategory}
                        label="Kategorie"
                        options={categories}
                        defaultValue={"Vyberte"}
                        required
                        value={product.bazosCzCategory}
                        onChange={this.props.onChange.bind(this, 'bazosCzCategory')}
                        disabled={this.props.disabled}
                        onBlur={this.checkIsValid.bind(this)}
                    />
                </div>
            </div>)
    }

    render() {
        const product = this.props.product;
        const categoryText = BazosHelper.getFullCategoryText(product.bazosCzCategory, product.bazosCzSection);

        return (
            <ServerSettingAccordion categoryText={categoryText} headingText="Bazoš.cz" isValid={this.state.isValid}>
                {product.bazosCZ ?
                    this.renderFields() : null}

                {this.props.disabled &&
                    <p className="text-secondary text-center mt-2 mb-2">
                        <b>Jelikož je inzerát vystaven, zařazení nelze měnit.</b>
                    </p>
                }
            </ServerSettingAccordion>)
    }
}