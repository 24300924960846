import { VehicleBrand } from "../Enums/VehicleBrand";
import { VehicleModel } from "../Enums/VehicleModel";
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class VehiclesApiService extends BaseApiService {

    readonly baseUrl: string = "vehicles";

    public getVehicles(search: string, licencePlateVin: string, brand?: VehicleBrand, model?: VehicleModel, takeCount?: number, skipCount?: number): Promise<IResponseData> {
        return this.getRequest(`${this.baseUrl}`, {
            search,
            licencePlateVin: licencePlateVin,
            brand: (brand || '').toString(),
            model: (model || '').toString(),
            takeCount: (takeCount || 50).toString(),
            skipCount: (skipCount || 0).toString(),
        })
            .then(response => {
                return response.data;
            })
    }
}