import React from "react"

export const NoProductsFound = () => {

    return (
        <div className="card">
            <div className="card-body">
                <div className="py-5">
                    <div className="text-center">
                        <h3>Nebyly nalezeny žádné výsledky</h3>
                    </div>
                    <div className="text-center">
                        <p>Zkuste změnit parametry vyhledávání.</p>
                    </div>
                </div>
            </div>
        </div>)
}