import React from "react"
import { PageTitleBox } from "../../Others/PageTitleBox"
import { WebmailPricing } from "../../Pricing/WebmailPricing"
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle"
import { WebmailBenefitsScreen } from "../../Webmails/WebmailBenefitsScreen"

export const WebmailsPage = () => {
    return <>
        <CustomDocumentTitle title="Webmail hosting">
            <PageTitleBox title="Webmail hosting" />

            <WebmailBenefitsScreen />

            <div className="card">
                <div className="card-body">
                    <WebmailPricing />
                </div>
            </div>     
        </CustomDocumentTitle>
    </>
}