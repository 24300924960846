import Prompt from "../../Helpers/Prompt";

interface IProps {
    when: boolean,
}

export const LeavePageWarningPrompt = (props: IProps) => {
    return (
        <Prompt
            when={props.when}
            message={`Změny nebudou uloženy. Opravdu chcete opustit stránku?`}
        />
    )
}