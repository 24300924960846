import React from "react"
import { SuccessAlert } from "../../Others/Alerts/SuccessAlert"

interface IProps {
    url: string
}

export const SuccessServerLinkAlert = (props: IProps) => {
    const { url } = props;

    return (<SuccessAlert>
        <span><i className="fas fa-link mr-2"></i><a href={url} target="_blank">{url}</a></span>
    </SuccessAlert>)
}