import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import IProductCollection from '../../../../Interfaces/IProductCollection';
import ProductCollectionApiService from '../../../../Services/ProductCollectionsApiService';
import { AddProductButton } from '../../../Products/AddProductButton';
import { BulkActionModal } from '../BulkActionModal';

interface IProps {
    collection: IProductCollection,
    disabledNewProduct: boolean,
    refreshPage(): void,
}

const productCollectionApiService = new ProductCollectionApiService();

export const CollectionActionsBar = (props: IProps) => {
    const [showBulkActionModal, setShowBulkActionModal] = useState<boolean>(false);
    const { collection, disabledNewProduct, refreshPage } = props;
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            productCollectionApiService.cancelRequests();
        }
    },[])

    const handleDelete = () => {
        Swal.fire({
            title: `Opravdu smazat "${collection.name}"?`,
            html: `<b class='text-danger'>Kolekci smažete současně s inzeráty (${collection.productsCount}). Akce je nevratná.</b>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Ano, smazat inzeráty (${collection.productsCount}) s kolekcí`,
            cancelButtonText: 'Zrušit'
        }).then((result) => {
            if (result.value) {
                productCollectionApiService.deleteCollection(collection)
                    .then(data => {
                        if (data.success) {
                            navigate('/nahravac/kolekce');
                        }
                    });
            }
        })
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                <BulkActionModal
                    show={showBulkActionModal}
                    onHide={() => {
                        setShowBulkActionModal(false);
                        refreshPage();
                    }}
                    collection={collection}
                />

                <div className="row">
                    <div className="col-6 col-sm-4 col-md-6 col-lg-8">
                        <AddProductButton
                            collectionId={collection.id}
                            disabled={disabledNewProduct}
                        />
                    </div>
                    <div className="col-6 col-sm-8 col-md-6 col-lg-4">
                        <div className="btn-group float-right" role="group">
                            <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        Hromadná akce
                                    </Tooltip>
                                }
                            >
                                <button
                                    className="btn btn-primary mr-1"
                                    onClick={() => setShowBulkActionModal(true)}
                                >
                                    <i className="fas fa-bolt px-1"></i>
                                </button>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        Nastavení kolekce
                                    </Tooltip>
                                }>
                                <Link to={`/nahravac/kolekce/${collection.id}/upravit`}>
                                    <button className="btn btn-primary mr-1">
                                        <i className="fas fa-cog"></i>
                                    </button>
                                </Link>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        Smazat kolekci
                                    </Tooltip>
                                }>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleDelete}
                                >
                                    <i className="far fa-trash-alt"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}