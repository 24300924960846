import React from "react"
import { Currency } from "../../Enums/Currency"
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import { SeparateThousands } from "../Others/SeparateThousands"

interface IProps {
    price: number,
    currency: Currency,
    prefix?: string,
    className?: string,
    style?: React.CSSProperties,
}

export const SubPrice = (props: IProps) => {

    const { price, currency, prefix, className,style } = props;

    return (
        <div className={className} style={style}>
            <h5><span className="badge badge-blue" >{prefix}<SeparateThousands roundPrecision={2} number={price} /> {CurrencyHelper.getSymbol(currency)}</span > </h5>
        </div>)

}