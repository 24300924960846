
interface IProps {
    title: string
}

export const WaitScreen = (props: IProps) => {

    const { title } = props;

    return <div className="py-3">
        <div className="d-flex justify-content-center">
            <div className="lds-dual-ring"></div>
        </div>

        <br />
        <div className="text-center">
            <h4>{title}</h4>
        </div>
    </div>
}