import React from 'react';
import { DomainsPricing } from '../../Pricing/DomainsPricing';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';

export const DomainsPricingPage = () => {
        return (
            <CustomDocumentTitle title="Ceník - Domény">
                <div>
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">Ceník - Domény</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <DomainsPricing />
                        </div>
                    </div>
                </div>
            </CustomDocumentTitle>
        )
  
}