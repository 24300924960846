import ErrorReporter from './ErrorReporter';
import ErrorPage from '../Pages/ErrorPage';
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { NotFound } from '../Pages/NotFound';


export const ErrorBoundary = () => {
    let error : any = useRouteError();
   
    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
           return <NotFound />
        }
    }
    console.error(error);
    ErrorReporter.error(error);
    return <ErrorPage />
}