import { AutoImporterErrorType } from "../../../Enums/AutoImporterErrorType";
import { AutoImporterState } from "../../../Enums/AutoImporterState";
import IAutoImporter from "../../../Interfaces/IAutoImporter"
import { ShowDateTime } from "../../Others/ShowDateTime";

interface IProps {
    autoImporter: IAutoImporter
}

export const AutoImporterWidgetContent = (props: IProps) => {

    const autoImporter = props.autoImporter;

    if (autoImporter.state === AutoImporterState.Running) {
        return <>
            <h6 className="text-success mt-1">Právě probíhá import</h6>
            <p className="m-0">
                <small>Import může trvat desítky minut.</small>
            </p>
        </>
    }
    else if (autoImporter.state === AutoImporterState.Queue) {
        return <>
            <h6 className="text-warning mt-1">Import je ve frontě</h6>
            <p className="m-0">
                <small>Import se spustí během pár desítek minut.</small>
            </p>
        </>
    }
    else if (autoImporter.pause) {
        return <>
            <h6 className="text-warning mt-1">Import je pozastaven</h6>
        </>
    }
    else if (autoImporter.state === AutoImporterState.Cancelling) {
        return <>
            <h6 className="text-danger mt-1">Probíhá rušení</h6>          
        </>
    }
    else if (autoImporter.active === false) {
        return <>
            <h6 className="text-danger mt-1">Import je vypnutý</h6>
            <p className="m-0">
                <small>Zapnout jej můžete v nastavení.</small>
            </p>
        </>
    }
    else if (autoImporter.error) {

        if (autoImporter.errorType === AutoImporterErrorType.Exception) {
            return <>
                <h6 className="text-warning mt-1">Probíhá údržba importéru</h6>
                <p className="m-0">
                    <small>Funkce bude dostupná později.</small>
                </p>
            </>
        }
        else if (autoImporter.errorType === AutoImporterErrorType.InvalidSetup) {
            return <>
                <h6 className="text-warning mt-1">Chyba nastavení</h6>
                <p className="m-0">
                    <small>Zkontrolujte nastavení.</small>
                </p>
            </>
        }
        else if (autoImporter.errorType === AutoImporterErrorType.Unauthorized) {
            return <>
                <h6 className="text-warning mt-1">Účet k importu není přihlášen</h6>
                <p className="m-0">
                    <small>Zkontrolujte přihlášení daného účtu</small>
                </p>
            </>
        }
        else if (autoImporter.errorType === AutoImporterErrorType.InvalidInput) {
            return <>
                <h6 className="text-danger mt-1">Chybné vstupní data</h6>
                <p className="m-0">
                    <small>Opravte vstupní data a spusťte import</small>
                </p>
            </>
        }
    }
    else if (autoImporter.state === AutoImporterState.Inactive && autoImporter.active) {
        return <>
            <h6 className="mt-1">Poslední import <ShowDateTime date={autoImporter.lastImportUtc} /></h6>
            <p className="m-0">
                <small>Frekvence importu jednou denně.</small>
            </p>
        </>
    }
    return null;
}