import { inject, observer } from "mobx-react";
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle";
import { PageTitleBox } from "../../Others/PageTitleBox";
import { ProductsShopTable } from "../../Shop/ProductsShopTable";
import ProductStore from "../../Stores/ProductStore";
import { useEffect, useState } from "react";
import ProductApiService from "../../../Services/ProductApiService";
import { ceil, map } from "lodash";
import Pagination from "react-js-pagination";
import LoadingScreen from "../../Screens/LoadingScreen";
import { ConeStriped } from "react-bootstrap-icons";

interface IProps {
    productStore?: ProductStore,
}

const productApiService = new ProductApiService();
const countToShow = 20;

export const ShopPage = inject('currentUserStore','productStore')(observer((props: IProps) => {

    const { productStore } = props;

    useEffect(() => {
        getProducts(1);

        return () => {
            productApiService.cancelRequests();
        }
    },[])

    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [productIds, setProductIds] = useState<string[]>([])
    const [totalItems, setTotalItems] = useState(0);
    const products = productStore.getByIds(productIds);

    const getProducts = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        setLoading(true);
     
        let skipCount = 0;
        if (pageNumber >= 2) {
            skipCount = (pageNumber - 1) * countToShow;
        }

        productApiService.getProducts(null, null, null, null, null, countToShow, skipCount)
            .then(data => {
                let products = data.records.products.items;
                const totalItems = data.others.get("totalCount")
                window.scroll(0, 0);
                setProductIds(map(products, s => s.id));
                setTotalItems(totalItems);
            }).finally(() => {
                setLoading(false);
            })
    }

    return (
        <CustomDocumentTitle title="Obchod">
            <PageTitleBox title="Obchod" />

           
            {loading && <LoadingScreen />}

            {!loading && <>
                <div className="card">
                    <div className="card-body">
                        <div className="alert alert-warning">
                            <ConeStriped className="text-warning" fontSize={20} /> <b>Sekce Obchod je stále ve vývoji, tudíž některé funkce mohou chybět.</b>
                        </div>


                        <ProductsShopTable
                            products={products}
                        />

                        {totalItems > countToShow && <>
                            <Pagination
                                pageRangeDisplayed={countToShow}
                                activePage={currentPage}
                                itemsCountPerPage={countToShow}
                                totalItemsCount={totalItems}
                                onChange={(pageNumber) => getProducts(pageNumber)}
                                linkClass="page-link"
                                itemClass="page-item"
                                innerClass="pagination justify-content-center mt-3"
                                hideDisabled={true}
                                hideFirstLastPages={true}
                            />
                        </>}

                        <p className="text-center">
                            Procházíte stranu {currentPage} z {ceil(totalItems / countToShow)}. Nalezeno {totalItems} inzerátů.
                        </p>
                    </div>
                </div>
            </>}
        </CustomDocumentTitle>
    )
}))