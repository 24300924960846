import React from "react"

interface IProps {
    text?: string,
    children?: React.ReactChild,
}

export const SuccessAlert = (props: IProps) => {
    const { text, children } = props;

    return <div className="alert alert-success" role="alert">
        {text && <><i className="fas fa-check mr-2"></i>{text}</>}
        {children}
    </div>
} 