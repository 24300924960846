import React from 'react';
import CustomDocumentTitle from '../../../../Utils/CustomDocumentTitle';
import { inject, observer } from 'mobx-react';
import BaseComponent from './../../../../BaseComponent';
import { find, isNil } from 'lodash';
import CurrentUserStore from '../../../../Stores/CurrentUserStore';
import ProductStore from '../../../../Stores/ProductStore';
import ProductCollectionStore from '../../../../Stores/ProductCollectionStore';
import LoadingScreen from '../../../../Screens/LoadingScreen';
import IProductCollection from '../../../../../Interfaces/IProductCollection';
import IProduct from '../../../../../Interfaces/IProduct';
import ProductImageStore from '../../../../Stores/ProductImageStore';
import ProductWizardStep1 from '../../Create/Steps/ProductWizardStep1';
import Product from '../../../../../Models/Product';
import NoRecordsScreen from '../../../../Screens/NoRecordsScreen';
import IWizardStep from '../../../../../Interfaces/IWizardStep';
import { WizardStepType } from '../../../../../Enums/WizardStepType';
import ProductWizardStepVehicleDetails from '../../Create/Cars/ProductWizardStepVehicleDetails';
import Swal from 'sweetalert2';
import { NotFoundScreen } from '../../../../Screens/NotFoundScreen';
import GlobalSettingsStore from '../../../../Stores/GlobalSettingsStore';
import AutoImporterStore from '../../../../Stores/AutoImporterStore';
import ProductWizardStepMotoDetails from '../../Create/Cars/ProductWizardStepMotoDetails';
import ProductWizard from '../../ProductWizard';
import ProductWizardStepServers from '../../Create/Steps/ProductWizardStepServers';
import { PageTitleBox } from '../../../../Others/PageTitleBox';
import { AxiosError } from 'axios';
import IResponseData from '../../../../../Interfaces/IResponseData';
import ProductWizardTiresStep from '../../Create/ProductWizardTiresStep';
import ProductWizardAluminiumWheelsStep from '../../Create/ProductWizardAluminiumWheelsStep';
import { ProductWizardStepImages } from '../../Create/Steps/ProductWizardStepImages';
import { Params, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

interface IState {
    loading: boolean,
    currentStep: IWizardStep,
    product: IProduct,
    images: File[],
    notFound: boolean,
    requiredFields: string[],
}

interface IProps {
    currentUserStore?: CurrentUserStore,
    productStore?: ProductStore,
    productCollectionStore?: ProductCollectionStore,
    productImageStore?: ProductImageStore,
    globalSettingsStore?: GlobalSettingsStore,
    autoImporterStore?: AutoImporterStore,
    params: Readonly<Params<string>>
}

@inject('currentUserStore')
@inject('productStore')
@inject('productCollectionStore')
@inject('productImageStore')
@inject('globalSettingsStore', 'autoImporterStore')
@observer
class EditProductCarsPage extends BaseComponent<IProps, IState> {

    private stepBasic: React.RefObject<ProductWizardStep1>;
    private stepVehicle: React.RefObject<ProductWizardStepVehicleDetails>;
    private stepServers: React.RefObject<ProductWizardStepServers>;
    private stepMotoVehicle: React.RefObject<ProductWizardStepMotoDetails>;
    private stepTires: React.RefObject<ProductWizardTiresStep>;
    private stepAluminiumWheels: React.RefObject<ProductWizardAluminiumWheelsStep>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            currentStep: {
                title: '',
                orderNumber: 1,
                type: WizardStepType.Basic
            },
            product: null,
            images: [],
            notFound: false,
            requiredFields: [],
        }

        this.stepBasic = React.createRef();
        this.stepVehicle = React.createRef();
        this.stepServers = React.createRef();
        this.stepMotoVehicle = React.createRef();
        this.stepTires = React.createRef();
        this.stepAluminiumWheels = React.createRef();
    }

    componentDidMount() {
        const { id } = this.props.params;

        const promises = [
            this.productApiService.getProduct(id),
            this.facebookCatalogsApiService.getCatalogs()
        ];

        Promise.all(promises)
            .then(() => {
                let product = find(this.props.productStore.list, { id: id });
                this.setState({
                    product: product,
                    loading: false,
                })
            })
            .catch((error: AxiosError) => {
                {
                    if (error.response && error.response.status === 404) {
                        this.setState({
                            notFound: true,
                            loading: false,
                        })
                        return Promise.resolve();
                    }
                }
            })

        window.scroll(0, 0);
    }

    handleChange(key: string, value: any) {
        let product = this.state.product;

        if ((key === 'bazosCZ' && product.bazosCzVisible && value === false) ||
            (key === 'bazosSK' && product.bazosSkVisible && value === false) ||
            (key === 'sbazar' && product.sbazarVisible && value === false) ||
            (key === 'sbazarAccountId' && !value)
        ) {
            Swal.fire({
                title: 'Inzerát bude mimo kontrolu',
                icon: 'warning',
                text: 'Vypnutím inzertního serveru u inzerátu nedojde k jeho odstranění. Inzerobot nebude tento inzerát na daném inzertním serveru nadále sledovat.',
                confirmButtonText: 'Rozumím',
                showCancelButton: false,
            })
        }

        if (key === 'sbazarAccountId' && value) {
            product.sbazar = true;
        }
        else if (key === 'sbazarAccountId') {
            product.sbazar = false;
        }
        if (key === 'facebookCatalogId') {
            product.facebookCatalogEnabled = value > 0;
        }
        if (key === 'vehicleBrand') {
            product.vehicleModel = null;
        }
        if (key === 'priceKc' || key === 'priceEur') {
            value = parseInt(value);
        }

        if (key === 'productCollectionId') {
            var collection: IProductCollection = find(this.props.productCollectionStore.list, { id: value });
            product.bazosCZ = collection.bazosCZ;
            product.bazosSK = collection.bazosSK;
        }
        if (key === 'bazosCzSection') {
            product.bazosCzCategory = null;
        }
        if (key === 'bazosSkSection') {
            product.bazosSkCategory = null;
        }
        if (key === 'section') {
            value = parseInt(value);
        }
        product = {
            ...product,
            [key]: value
        };

        this.setState({
            product: product
        })
    }

    handleGoToStep(step: IWizardStep) {
        if (step) {
            this.setState({
                currentStep: step
            })
        }
    }

    handleGoToNextStep(product: IProduct) {
        const currentStep = this.state.currentStep;

        if (product.bazosSK && product.bazosSkPriceOption === 0) {
            this.handleChange('bazosSkPriceOption', 1);
        }
        if (product.bazosCZ && product.bazosCzPriceOption === 0) {
            this.handleChange('bazosCzPriceOption', 1);
        }
        if (currentStep.type === WizardStepType.Basic) {
            this.stepBasic.current.validateAsync()
                .then(value => {
                    if (value === true) {
                        // fetch required fields

                        this.productApiService.getRequiredFields(this.state.product)
                            .then(data => {
                                this.setState({
                                    requiredFields: data.others.get("requiredFields"),
                                    currentStep: Product.getStepByNumber(product, currentStep.orderNumber + 1)
                                });
                            })
                    }
                })
        }
        else if (currentStep.type === WizardStepType.VehicleDetails) {
            if (this.stepVehicle.current.validate()) {
                this.goToNextStep(product);
            }
        }
        else if (currentStep.type === WizardStepType.VehicleMotoDetails) {
            if (this.stepMotoVehicle.current.validate()) {
                this.goToNextStep(product);
            }
        }
        else if (currentStep.type === WizardStepType.Tires) {
            if (this.stepTires.current.validate()) {
                this.goToNextStep(product);
            }
        }
        else if (currentStep.type === WizardStepType.AluminiumWheels) {
            if (this.stepAluminiumWheels.current.validate()) {
                this.goToNextStep(product);
            }
        }
        else if (currentStep.type ===
            WizardStepType.Servers) {
            if (this.stepServers.current.validate()) {

                this.setState({
                    currentStep: Product.getStepByNumber(product, currentStep.orderNumber + 1),
                })
            }
        }
    }

    handleSaveChanges(): Promise<IResponseData> {
        const { id } = this.props.params;
        const product = this.state.product;

        return this.productApiService.edit(id, product,false);
    }

    goToNextStep(product: IProduct) {
        const nextStep = Product.getStepByNumber(product, this.state.currentStep.orderNumber + 1)

        if (nextStep) {
            this.setState({
                currentStep: nextStep
            })
        }
    }

    handleGoToLastStep(product: IProduct) {
        const newStep = Product.getStepByNumber(product, this.state.currentStep.orderNumber - 1);

        if (newStep) {
            this.setState({
                currentStep: newStep
            });
        }
    }

    render() {
        const { loading, notFound, requiredFields } = this.state;
        const { id } = this.props.params;

        if (loading) {
            return <LoadingScreen />
        }
        if (notFound) {
            return (
                <div className="mt-4" >
                    <NoRecordsScreen
                        show={true}
                        title='Inzerát neexistuje'
                        message='Zřejmě jste jej smazali nebo máte špatný odkaz...'
                    />
                </div>)
        }

        const product = this.state.product;
        const collection = find(this.props.productCollectionStore.list, { id: product.productCollectionId });
        const productOld = find(this.props.productStore.list, { id: id });

        if (isNil(productOld)) {
            return <NotFoundScreen />
        }
        return (
            <CustomDocumentTitle title={`Úprava inzerátu "${productOld.name}"`}>
                <PageTitleBox title={`Úprava inzerátu "${productOld.name}"`} />

                <Alert variant={"danger"} className="mt-2">
                    <b>Používáte staré rozhraní pro úpravu inzerátů. Toto rozhraní bude odstraněno 1.8.2024 !!!</b>
                </Alert>

                <ProductWizard
                    enableGoToBack={true}
                    currentStep={this.state.currentStep}
                    goToStep={this.handleGoToStep.bind(this)}
                    goToLastStep={this.handleGoToLastStep.bind(this, product)}
                    goToNextStep={this.handleGoToNextStep.bind(this, product)}
                    product={product}
                >
                    {this.state.currentStep.type === WizardStepType.Basic ?
                        <ProductWizardStep1
                            ref={this.stepBasic}
                            product={product}
                            onChange={this.handleChange.bind(this)}
                            collection={collection}
                            sectionOptions={[]}
                            globalSettings={this.props.globalSettingsStore.settings}
                            createNew={false}
                            onChangeProduct={(product) => this.setState({ product: product })}
                        /> : null}

                    {this.state.currentStep.type === WizardStepType.VehicleDetails ?
                        <ProductWizardStepVehicleDetails
                            ref={this.stepVehicle}
                            product={product}
                            collection={collection}
                            onChange={this.handleChange.bind(this)}
                        /> : null}

                    {this.state.currentStep.type === WizardStepType.VehicleMotoDetails ?
                        <ProductWizardStepMotoDetails
                            ref={this.stepMotoVehicle}
                            product={product}
                            onChange={this.handleChange.bind(this)}
                        /> : null}

                    {this.state.currentStep.type === WizardStepType.Tires &&
                        <ProductWizardTiresStep
                            ref={this.stepTires}
                            product={product}
                            onChange={(key, val) => this.handleChange(key, val)}
                            requiredFields={requiredFields}
                        />}

                    {this.state.currentStep.type === WizardStepType.AluminiumWheels &&
                        <ProductWizardAluminiumWheelsStep
                            ref={this.stepAluminiumWheels}
                            product={product}
                            onChange={(key, val) => this.handleChange(key, val)}
                            requiredFields={requiredFields}
                        />}

                    {this.state.currentStep.type === WizardStepType.Servers ?
                        <ProductWizardStepServers
                            ref={this.stepServers}
                            product={product}
                            collection={collection}
                            onChange={this.handleChange.bind(this)}
                            onChangeProduct={(product) => this.setState({ product: product })}
                        /> : null}

                    {this.state.currentStep.type === WizardStepType.Images ?
                        <ProductWizardStepImages
                            collection={collection}
                            productId={product.id}
                            onSaveChanges={() => this.handleSaveChanges()}
                        /> : null}
                </ProductWizard>
            </CustomDocumentTitle >
        )
    }
}

const EditProductCarsPageWrapper = () => {
    const params = useParams();
    return <EditProductCarsPage params={params} />;
};

export default EditProductCarsPageWrapper;