import { SortableContext, rectSortingStrategy, } from "@dnd-kit/sortable";
import IProductImage from "../../../Interfaces/IProductImage"
import { DndContext, closestCenter } from '@dnd-kit/core';
import { ImageSortableItem } from "./ImageSortableItem";
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { find } from "lodash";
import ProductImageApiService from "../../../Services/ProductImagesApiService";
import IProduct from "../../../Interfaces/IProduct";

interface IProps {
    images: IProductImage[],
    product: IProduct,
}

const productImageApiService = new ProductImageApiService();

export const ImagesSortableList = (props: IProps) => {

    const { images, product } = props;

    const onDragEnd = (e: DragEndEvent) => {

        const { active, over } = e;

        if (active && over) {
            if (active.id !== over.id) {

                const activeImage = find(images, { id: active.id.toString() });
                const overImage = find(images, { id: over.id.toString() });
                const overIndex = images.indexOf(overImage);
                if (activeImage && overImage) {
                    productImageApiService.changeOrder(product.id, activeImage.id, overIndex + 1);
                }
            }
        }
    }

    return <>
        <DndContext
            collisionDetection={closestCenter}
            onDragEnd={(e) => onDragEnd(e)}
        >
            <SortableContext
                items={images}
                strategy={rectSortingStrategy}
            >
                <div className="row">
                    {images.map(im => {
                        return (
                            <div
                                key={im.id}
                                className="col col-sm-4 col-md-3 col-lg-2 col-xl-2 my-2"
                                style={{ paddingTop: 50 }}
                            >
                                <ImageSortableItem
                                    image={im}
                                />
                            </div>
                        )
                    })}
                </div>
            </SortableContext>
        </DndContext>
    </>
}