import { PageTitleBox } from "../../Others/PageTitleBox"
import { Accordion } from "../../UI/Elements/Accordion"
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle"

export const FaqBazosPage = () => {
    return (
        <>
            <CustomDocumentTitle title="Nápověda - Bazoš">
                <PageTitleBox title="Nápověda - Bazoš" />

                <div className="card">
                    <div className="card-body">
                        <Accordion cardClassName="mt-4 mb-2" headingText={"Maximální počet inzerátů"}>
                            <p>
                               <b> Na jedno tel. číslo, email je možné mít 50 inzerátů.</b> Nad 50 inzerátů je potřeba inzeráty topovat nebo si založit novou kolekci s novým tel. číslem, emailem a jinou IP adresou.
                            </p>
                        </Accordion>

                        <Accordion cardClassName="mb-2" headingText={"Předčasné obnovování inzerátů"}>
                            <p>
                               V aplikaci lze nastavit obnovení inzerátu před expirací, ovšem v takovém momentě budete čelit možnosti blokace ze strany inzertního serveru. Což může způsobit blokaci tel. čísla, IP adresy, emailu...
                            </p>
                        </Accordion>

                        <Accordion cardClassName="mb-2" headingText={"Obsah inzerátu"}>
                            <p>Na bazoši je možné prodávat pouze použité věci a vždy jen 1 kus. </p>

                            <ul>
                                <li>Nepoužívejte slova jako "nové, nová atd"</li>
                                <li>Nepoužívejte produktové fotografie s bílým pozadím (typicky katalogové)</li>
                                <li>Neuvádějte webové stránky vaší firmy</li>
                            </ul>
                        </Accordion>

                        <Accordion cardClassName="mb-2" headingText={"Postup při blokaci"}>
                            <h5>Postup při blokaci</h5>
                            <p>V případě, že jste zablokování bazošem, porušili jste některé z jejich pravidel. <b className="text-danger">Blokace je vždy důsledkem předčasného obnovování nebo nevhodného obsahu.</b></p>

                            <ol>
                                <li>Změňte tel. číslo</li>
                                <li>Změňte emailovou adresu</li>
                                <li>Zkontrolujte obsah inzerátů</li>
                                <li>Přečtěte si znovu podmínky bazoše</li>
                                <li>Neobnovujte inzeráty před expirací</li>
                                <li>Zvažte topování inzerátů (bazoš tyto inzeráty více toleruje)</li>
                            </ol>
                        </Accordion>

                        <Accordion cardClassName="mb-2" headingText={"Smazali mi inzerát. Jak postupovat"}>
                            <p>Pokud vám smazali inzerát, inzerát v aplikaci uvidíte s chybou. <b className="text-danger">Smazání je vždy důsledkem předčasného obnovování, duplikace nebo nevhodného obsahu.</b></p>
                            <p><b className="text-dark">Smazání inzerátu je varování, že něco děláte proti podmínkám. Další takové porušení může znamenat blokaci.</b></p>
                            <ol>
                                <li>Zkontrolujte obsah inzerátu (ideálně jej upravte)</li>
                                <li>Inzerát nahrajte přes tlačítko "Nahrát" po rozkliknutí detailu u inzerátu (ideálně hned TOPOVAT)</li>
                            </ol>
                        </Accordion>
                    </div>
                </div>
            </CustomDocumentTitle>
        </>)
}